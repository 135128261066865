import jsPDF from 'jspdf'
import Canvg from 'canvg'
import moment from 'moment'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting.js'
import { camelize } from 'humps'
import { dateFormatter } from '../utils/date'
import { renderReportType } from '../utils/textFormatters'
import { getReading, getSensorReading } from '../utils/sensorData'
import { toTitleCase } from '../utils/textFormatters'
import {
  REPORT_CHART_COLORS,
  DEFAULT_CHART_THEME,
  LOCATION_TYPES,
  COLORS,
} from '../constants'
import logoMd from '../assets/logo-md.png'

const DOC_WIDTH = 1123
const DOC_HEIGHT = 794

const ORIENTATION = {
  PORTRAIT: 'p',
  LANDSCAPE: 'l',
}

exporting(Highcharts)

const asyncForEach = async (list, callback) => {
  for (let index = 0; index < list.length; index++) {
    await callback(list[index], index, list)
  }
}

const generateChartSVG = ({
  items,
  reading,
  width,
  height,
  omittedLocations,
}) => {
  const chartOptions = {
    credits: { enabled: false },
    colors: REPORT_CHART_COLORS[reading] || DEFAULT_CHART_THEME,
    chart: {
      width,
      height,
      style: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
      },
    },
    plotOptions: {
      series: {
        type: 'line',
      },
      line: {
        animation: false,
        marker: {
          enabled: false,
        },
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'longdash',
      title: {
        text: null,
      },
      labels: {
        format: `{value} ${getSensorReading(reading).unit}`,
        style: {
          color: COLORS.BLACK_80,
        },
      },
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'longdash',
      type: 'datetime',
      title: {
        text: null,
      },
    },
    legend: {
      verticalAlign: 'top',
    },
    series: items
      .filter(x => omittedLocations.indexOf(x.label + reading) === -1)
      .map(x => {
        const isFloorAvg = x.type === 'AVERAGE'
        const isLastAvg = x.type === 'LAST_AVERAGE'

        return {
          name: isLastAvg ? 'Previous Period Avg.' : x.label,
          data: x.data.map(y => [
            new Date(y.time).valueOf(),
            y[camelize(reading)],
          ]),
          opacity: 0.8,
          lineWidth: isFloorAvg || isLastAvg ? 3 : 2,
          dashStyle: isFloorAvg || isLastAvg ? 'ShortDash' : 'Solid',
          zIndex: isFloorAvg || isLastAvg ? 1000 : undefined,
        }
      }),
  }

  const chart = Highcharts.chart(document.createElement('div'), chartOptions)

  return chart.getSVG()
}

const generatePDF = async ({
  columns,
  pages,
  siteInfo,
  reportType,
  fromTimestamp,
  toTimestamp,
  omittedLocations,
}) => {
  const isPortraitOrientation = columns.length > 2
  const docWidth = isPortraitOrientation ? DOC_HEIGHT : DOC_WIDTH
  const docHeight = isPortraitOrientation ? DOC_WIDTH : DOC_HEIGHT

  const { name, address, city, state } = siteInfo

  const startMoment = moment(fromTimestamp * 1000)
  const endMoment = moment(toTimestamp * 1000)
  const reportTypeFormatted = renderReportType(reportType)

  const currentYear = moment().year()

  const insertPage = async (doc, page, reading, columnIndex) => {
    const width = docWidth - 64
    const height = 600 / columns.length
    const x = 32
    const y = 128 + columnIndex * (columns.length > 3 ? 250 : 330)
    const compression = 'NONE'
    const rotation = 0

    const pageContainsSensorData =
      page.items.filter(x => x.data.length > 0).length > 0

    if (pageContainsSensorData) {
      const svg = generateChartSVG({
        items: page.items,
        reading,
        width,
        height,
        omittedLocations,
      })

      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      // ctx.fillStyle = '#fff' /// set white fill style
      // ctx.fillRect(0, 0, canvas.width, canvas.height)

      const canvasOptions = {
        ignoreMouse: true,
        ignoreAnimation: true,
        ignoreDimensions: true,
      }

      const instance = await Canvg.fromString(ctx, svg, canvasOptions)
      await instance.render(canvasOptions)

      doc
        .setFontSize(12)
        .text(
          getReading(reading),
          docWidth / 2,
          120 + columnIndex * (columns.length > 3 ? 250 : 330),
          {
            align: 'center',
            lineHeightFactor: 1.5,
          }
        )

      doc.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        x,
        y,
        width,
        height,
        null, // alias,
        compression,
        rotation
      )
    } else {
      insertNoDataMessage(doc)
    }

    return doc
  }

  const insertFooter = doc =>
    doc
      .setFontSize(8)
      .setTextColor(125)
      .text(`${name} - ${reportTypeFormatted}`, 16, docHeight - 16, {
        align: 'left',
      })
      .text(
        `Copyright ${currentYear} Pillar Technologies`,
        docWidth - 16,
        docHeight - 16,
        { align: 'right' }
      )
      .setTextColor(0)
      .setFontSize(16)

  const insertNoDataMessage = doc =>
    doc.text(
      'No data available for selected time period',
      docWidth / 2,
      docHeight / 2.5,
      { align: 'center', lineHeightFactor: 1.5 }
    )

  const doc = new jsPDF({
    orientation: isPortraitOrientation
      ? ORIENTATION.PORTRAIT
      : ORIENTATION.LANDSCAPE,
    unit: 'px',
    hotfixes: ['px_scaling'],
  })

  doc.addImage(logoMd, 'PNG', docWidth / 2 - 140, 180, 280, 62, null, 'NONE')

  doc.text(
    [
      name,
      ` ${address}, ${city}, ${state}`,
      `${dateFormatter(startMoment, false, true)} - ${dateFormatter(
        endMoment,
        false,
        true
      )}`,
    ],
    docWidth / 2,
    docHeight / 2.125,
    { align: 'center', lineHeightFactor: 1.5 }
  )

  insertFooter(doc)

  const addPages = async () => {
    await asyncForEach(pages, async (page, pageIndex) => {
      doc.addPage()

      const pageName = `${page.buildingName ? `${page.buildingName} - ` : ''}${
        page.name
      }${
        page.locationType
          ? ` (${toTitleCase(page.locationType)}${
              page.items.length > 1 ? 's' : ''
            })`
          : ''
      }`

      doc.text(pageName, docWidth / 2, 64, {
        align: 'center',
        lineHeightFactor: 1.5,
      })

      if (page.items.length > 0) {
        if (page.locationType === LOCATION_TYPES.FLOW_MONITOR) {
          await insertPage(doc, page, 'flowValue', 0)
        } else {
          await asyncForEach(columns, async (reading, columnIndex) => {
            await insertPage(doc, page, reading, columnIndex)
          })
        }
      } else {
        insertNoDataMessage(doc)
      }

      insertFooter(doc)
    })

    doc.save(`${name} — ${reportTypeFormatted} — ${moment().toISOString()}.pdf`)
  }

  await addPages()
}

export default generatePDF
