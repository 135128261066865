import {
  fork,
  call,
  take,
  takeLatest,
  put,
  all,
  select,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllBuildingsAction,
  getBuildingAction,
  createBuildingAction,
  updateBuildingAction,
  deleteBuildingAction,
  evacuateBuildingAction,
  addThresholdToBuildingAction,
  deleteThresholdFromBuildingAction,
  getAllBuildingThresholdUserMappingsAction,
  updateBuildingThresholdUserMappingAction,
} from '../actions/buildingsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { getPathSnippetAtIndex, pickErrorMessage } from '../utils/helpers'
import {
  getAllSiteAggregateThresholdUserMappingsAction,
  getAllAggregateThresholdsBySiteAction,
} from '../actions/sitesActions'
import { createQueryString } from '../utils/queryParams'
// selectors

const getSiteSlug = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 1)
const getBuildingId = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 3)

// handlers

function* handleGetAllBuildingsSaga({ siteSlug, qs }) {
  try {
    const payload = yield call(api.getAllBuildings, siteSlug, qs)
    yield put(getAllBuildingsAction.success(payload))
  } catch (error) {
    yield put(getAllBuildingsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetBuildingSaga({ siteSlug, id }) {
  try {
    const payload = yield call(api.getBuildingById, siteSlug, id)
    yield put(getBuildingAction.success(payload))
  } catch (error) {
    yield put(getBuildingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateBuildingSaga(building) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const inFloorPlan = building['inFloorPlan'] ? true : false
    const payload = yield call(api.createBuilding, siteSlug, building)
    yield put(createBuildingAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    if (inFloorPlan) {
      yield put(getAllBuildingsAction.request({ siteSlug: siteSlug }))
    } else {
      yield put(push(`/sites/${siteSlug}`))
    }
  } catch (error) {
    yield put(createBuildingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateBuildingSaga(building) {
  try {
    const siteSlug = yield select(getSiteSlug)

    const payload = yield call(api.updateBuilding, siteSlug, building)
    yield put(updateBuildingAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/sites/${siteSlug}`))
  } catch (error) {
    yield put(updateBuildingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleEvacuateBuildingSaga(building) {
    try {
      const siteSlug = yield select(getSiteSlug)
  
      const payload = yield call(api.evacuateBuilding, siteSlug, building)
      yield put(evacuateBuildingAction.success(payload))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
      yield put(push(`/sites/${siteSlug}`))
    } catch (error) {
      yield put(evacuateBuildingAction.failure(error))
      yield put(showErrorMessageAction(pickErrorMessage(error)))
  
      if (error.status === 401) {
        yield put(unauthorizedAction())
      }
    }
  }

function* handleDeleteBuildingSaga({ siteSlug, buildingId }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteBuilding, siteSlug, buildingId)
      yield fork(handleGetAllBuildingsSaga, { siteSlug })
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteBuildingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddThresholdToBuildingSaga({ payload }) {
  try {
    const isConfirmed = yield call(global.confirm, MESSAGES.CONFIRM)

    if (isConfirmed) {
      const { siteSlug, buildingId, thresholdId } = payload

      yield call(api.addThresholdToBuilding, siteSlug, buildingId, thresholdId)
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ADDED_THRESHOLD))
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(addThresholdToBuildingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteThresholdFromBuildingSaga({ payload }) {
  try {
    const { siteSlug, buildingId, thresholdId } = payload

    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(
        api.deleteThresholdFromBuilding,
        siteSlug,
        buildingId,
        thresholdId
      )
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(
        showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED_THRESHOLD)
      )
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(deleteThresholdFromBuildingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllBuildingThresholdUserMappingsSaga({ payload }) {
  try {
    const { siteSlug, buildingId } = payload
    const result = yield call(
      api.getAllBuildingThresholdUserMappings,
      siteSlug,
      buildingId
    )
    yield put(getAllBuildingThresholdUserMappingsAction.success(result))
  } catch (error) {
    yield put(getAllBuildingThresholdUserMappingsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateBuildingThresholdUserMappingSaga({ payload }) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const buildingId =
      payload && payload.buildingId
        ? payload.buildingId
        : yield select(getBuildingId)
    const result = yield call(api.updateBuildingThresholdUserMapping, {
      ...payload,
      siteSlug,
      buildingId,
    })
    yield put(getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug))
    yield put(updateBuildingThresholdUserMappingAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield fork(handleGetAllBuildingThresholdUserMappingsSaga, {
      payload: { siteSlug, buildingId },
    })
  } catch (error) {
    yield put(updateBuildingThresholdUserMappingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// function* sendReconMessageEvacuateBuilding({ payload }) {
//     try {
//         const buildingId =
//         payload && payload.buildingId
//         ? payload.buildingId
//         : yield select(getBuildingId)

//         yield call(api.sendEvacuationMsg, { buildingId })
//     } catch (error) {
//     // yield put(sendReconMessageEvacuateBuildingMappingAction.failure(error))
//     yield put(showErrorMessageAction(pickErrorMessage(error)))

//     if (error.status === 401) {
//       yield put(unauthorizedAction())
//     }
//   }
// }

// watchers

function* watchGetAllBuildingsSaga() {
  while (true) {
    const { payload } = yield take(getAllBuildingsAction.REQUEST)
    yield fork(handleGetAllBuildingsSaga, payload)
  }
}

function* watchGetBuildingSaga() {
  while (true) {
    const { payload } = yield take(getBuildingAction.REQUEST)
    yield fork(handleGetBuildingSaga, payload)
  }
}

function* watchCreateBuildingSaga() {
  while (true) {
    const { payload } = yield take(createBuildingAction.REQUEST)
    yield fork(handleCreateBuildingSaga, payload)
  }
}

function* watchUpdateBuildingSaga() {
  while (true) {
    const { payload } = yield take(updateBuildingAction.REQUEST)
    yield fork(handleUpdateBuildingSaga, payload)
  }
}

function* watchEvacuateBuildingSaga() {
    while (true) {
      const { payload } = yield take(evacuateBuildingAction.REQUEST)
      yield fork(handleEvacuateBuildingSaga, payload)
    }
  }

function* watchDeleteBuildingSaga() {
  while (true) {
    const { payload } = yield take(deleteBuildingAction.REQUEST)
    yield fork(handleDeleteBuildingSaga, payload)
  }
}

function* watchAddThresholdToBuildingSaga() {
  yield takeLatest(
    addThresholdToBuildingAction.REQUEST,
    handleAddThresholdToBuildingSaga
  )
}

function* watchDeleteThresholdFromBuildingSaga() {
  yield takeLatest(
    deleteThresholdFromBuildingAction.REQUEST,
    handleDeleteThresholdFromBuildingSaga
  )
}

function* watchGetAllBuildingThresholdUserMappingsSaga() {
  yield takeLatest(
    getAllBuildingThresholdUserMappingsAction.REQUEST,
    handleGetAllBuildingThresholdUserMappingsSaga
  )
}

function* watchUpdateBuildingThresholdUserMappingSaga() {
  yield takeLatest(
    updateBuildingThresholdUserMappingAction.REQUEST,
    handleUpdateBuildingThresholdUserMappingSaga
  )
}

function* buildingsSaga() {
  yield all([
    fork(watchGetAllBuildingsSaga),
    fork(watchGetBuildingSaga),
    fork(watchCreateBuildingSaga),
    fork(watchUpdateBuildingSaga),
    fork(watchDeleteBuildingSaga),
    fork(watchAddThresholdToBuildingSaga),
    fork(watchDeleteThresholdFromBuildingSaga),
    fork(watchGetAllBuildingThresholdUserMappingsSaga),
    fork(watchUpdateBuildingThresholdUserMappingSaga),
  ])
}

export {
  buildingsSaga as default,
  watchGetAllBuildingsSaga,
  watchGetBuildingSaga,
  watchCreateBuildingSaga,
  watchUpdateBuildingSaga,
  watchDeleteBuildingSaga,
  watchAddThresholdToBuildingSaga,
  watchDeleteThresholdFromBuildingSaga,
  watchGetAllBuildingThresholdUserMappingsSaga,
  watchUpdateBuildingThresholdUserMappingSaga,
  watchEvacuateBuildingSaga,
  handleGetAllBuildingsSaga,
  handleGetBuildingSaga,
  handleCreateBuildingSaga,
  handleUpdateBuildingSaga,
  handleDeleteBuildingSaga,
  handleEvacuateBuildingSaga,
  handleAddThresholdToBuildingSaga,
  handleDeleteThresholdFromBuildingSaga,
  handleUpdateBuildingThresholdUserMappingSaga,
  getSiteSlug,
  getBuildingId,
}
