import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ActionAnchor } from './common/Anchor'
import Divider from './common/Divider'
import { dateFormatter } from '../utils/date'

const AllSitesCSVDownload = ({ sites }) => {
  const handleDownload = () => {
    let csv =
      'Name,Address,City,State,Zipcode,Contract Start Date,Contract End Date,Deployment Date,Completion Date,Completion Percentage,Primary Contact Name,Primary Contact Email, Primary Contact Phone, Secondary Contact Name, Secondary Contact Email, Secondary Contact Phone\n'

    const scrubText = text => text.replace(/#*/g, '').replace(',', ' ')

    sites.forEach(x => {
      const {
        name,
        address,
        city,
        state,
        zipCode,
        contractStartDate,
        contractEndDate,
        deploymentDate,
        completionDate,
        primaryContactUser = {},
        secondaryContactUser = {},
      } = x

      let percentComplete = 0

      if (contractStartDate && contractEndDate) {
        const now = moment()
        const startDate = moment(contractStartDate)
        const endDate = moment(contractEndDate)
        const totalContractDays = endDate.diff(startDate, 'days')
        if (startDate.isBefore(now) && endDate.isAfter(now)) {
          const daysCompleted = now.diff(startDate, 'days')
          percentComplete = parseInt((daysCompleted * 100) / totalContractDays)
        } else if (endDate.isBefore(now)) {
          percentComplete = 100
        }
      }

      csv += `${name ? scrubText(name) : ''},${
        address ? scrubText(address) : ''
      },${city},${state},${zipCode},${dateFormatter(
        contractStartDate
      )},${dateFormatter(contractEndDate)},${dateFormatter(
        deploymentDate
      )},${dateFormatter(completionDate)},${percentComplete},${
        primaryContactUser.firstName
      } ${primaryContactUser.lastName},${primaryContactUser.email},${
        primaryContactUser.phoneNumber
      },${secondaryContactUser.firstName} ${secondaryContactUser.lastName},${
        secondaryContactUser.email
      },${secondaryContactUser.phoneNumber}\n`
    })

    const currentDate = dateFormatter(Date.now())
      .split('/')
      .join('-')
    let fileName = `ALL-SITES--${currentDate}`

    const encodedCsv = global.encodeURI(csv)

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodedCsv
    hiddenElement.target = '_blank'
    hiddenElement.download = `${fileName}.csv`
    hiddenElement.click()
  }

  return (
    <section className="AllSitesCSVDownload">
      <Divider vertical />
      <span>
        <ActionAnchor onClick={handleDownload}>Download Site Data</ActionAnchor>
      </span>
    </section>
  )
}

AllSitesCSVDownload.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AllSitesCSVDownload
