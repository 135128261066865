import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from './common/Button'
import Form from './common/Form'
import Label from './common/Label'
import { Modal } from './common/Ant'
import Select, { Option } from './common/Select'
import { toTitleCase } from '../utils/textFormatters'
import { SITE_DOCUMENT_TYPES } from '../constants'
import { ActionAnchor } from './common/Anchor'

const InvoiceUpload = ({
  siteSlug,
  invoiceSlug,
  uploadDocument,
  documentType,
  isBillingType,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentType, setCurrentType] = useState(documentType)
  // const [src, setSrc] = useState(null)
  const [formData, setFormData] = useState(null)

  const handleFileSelection = event => {
    const files = Array.from(event.target.files)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('name', file.name)
      formData.append('type', file.type)
      formData.append('files[]', file)
      formData.append('document', currentType)
      formData.append('slug', invoiceSlug)
      // TODO seems unnecessary?
      // const src = global.URL.createObjectURL(file)

      // setSrc(src)
      setFormData(formData)
    })
  }

  const handleSubmit = () => {
    uploadDocument({ siteSlug, formData })
    setIsModalVisible(false)
  }

  return (
    <div className="InvoiceUpload">
      <span>
        <ActionAnchor onClick={() => setIsModalVisible(true)}>
          {currentType === SITE_DOCUMENT_TYPES.INVOICE
            ? 'Upload Invoice'
            : 'Upload Document'}
        </ActionAnchor>
      </span>
      <Modal
        visible={isModalVisible}
        className="Modal"
        title="Upload Document"
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form onSubmit={handleSubmit}>
          <Select
            label="Document type"
            placeholder="Select a document type"
            input={{
              value: currentType,
              onChange: value => setCurrentType(value),
            }}
            filterable
            isRequired={!isBillingType}
            disabled={isBillingType}
          >
            {isBillingType
              ? Object.keys(SITE_DOCUMENT_TYPES).map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(SITE_DOCUMENT_TYPES[x])}
                  </Option>
                ))
              : Object.keys(SITE_DOCUMENT_TYPES)
                  .filter(
                    dt =>
                      dt === SITE_DOCUMENT_TYPES.SCOPE_OF_WORK ||
                      dt === SITE_DOCUMENT_TYPES.PROPOSALS
                  )
                  .map(x => (
                    <Option value={x} key={x}>
                      {toTitleCase(SITE_DOCUMENT_TYPES[x])}
                    </Option>
                  ))}
          </Select>
          <Label>Select a file to upload</Label>
          <input
            type="file"
            accept="image/*,.pdf"
            onChange={handleFileSelection}
            className="mb3 w-100"
          />
          <Button onClick={handleSubmit} disabled={!currentType} />
        </Form>
      </Modal>
    </div>
  )
}

InvoiceUpload.propTypes = {
  siteSlug: PropTypes.string.isRequired,
  invoiceSlug: PropTypes.string,
  uploadDocument: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  isBillingType: PropTypes.bool.isRequired,
}

export default InvoiceUpload
