import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllPartsAction,
  getAllAvailablePartsAction,
  getPartAction,
} from '../actions/partsActions'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllPartsAction.SUCCESS:
        payload.items.forEach(part => {
          draft[part.id] = part
        })
        break
    }
  })

const availableById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAvailablePartsAction.SUCCESS:
        payload.forEach(part => {
          draft[part.id] = part
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllPartsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(part => part.id)
        )
        break
    }
  })

const availableVisibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailablePartsAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.map(part => part.id))
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllPartsAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const current = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getPartAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const partsReducer = combineReducers({
  byId,
  visibleIds,
  availableById,
  availableVisibleIds,
  current,
  meta,
})

const getPart = (state, id) => state.byId[id]
const getAvailablePart = (state, id) => state.availableById[id]
const getCurrentPart = state => state.current
const getVisibleParts = state => state.visibleIds.map(id => getPart(state, id))
const getPartsMeta = state => state.meta
const getAvailableVisibleParts = state =>
  state.availableVisibleIds.map(id => getAvailablePart(state, id))

export {
  partsReducer as default,
  getCurrentPart,
  getVisibleParts,
  getAvailableVisibleParts,
  getPartsMeta,
}
