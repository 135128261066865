import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditSiteForm from './forms/EditSiteForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateSiteAction } from '../actions/sitesActions'
import { hasPermission } from '../utils/hasPermission'
import { createQueryString } from '../utils/queryParams'
import { USER_PERMISSIONS } from '../constants'

class EditSite extends Component {
  static propTypes = {
    site: PropTypes.object.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    getSite: PropTypes.func.isRequired,
    match: PropTypes.object,
    getAllCompanies: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    accountProfile: PropTypes.object.isRequired,
    getAllNetworks: PropTypes.func.isRequired,
    networks: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  componentDidMount() {
    const { match, getSite, getAllCompanies, getAllNetworks } = this.props
    const { siteSlug } = match.params

    getSite(siteSlug)
    hasPermission(USER_PERMISSIONS.MANAGE_NETWORK) &&
      getAllNetworks(createQueryString({ perPage: 'all' }))

    if (hasPermission(USER_PERMISSIONS.VIEW_COMPANY)) {
      const qs = createQueryString({ perPage: 'all' })

      getAllCompanies(qs)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { accountProfile, getAllCompanies, getAllNetworks } = this.props

    // TODO
    if (!accountProfile.role && nextProps.accountProfile.role) {
      if (hasPermission(USER_PERMISSIONS.VIEW_COMPANY)) {
        const qs = createQueryString({ perPage: 'all' })

        getAllCompanies(qs)
        hasPermission(USER_PERMISSIONS.MANAGE_NETWORK) &&
          getAllNetworks(createQueryString({ perPage: 'all' }))
      }
    }
  }

  render() {
    const {
      match: {
        params: { siteSlug },
      },
      site,
      companies,
      formValues,
      networks,
    } = this.props
    const crumbs = site.name
      ? [
          <Anchor to="/sites">Sites</Anchor>,
          <Anchor to={`/sites/${siteSlug}`}>{site.name}</Anchor>,
          'Edit Site',
        ]
      : [<Anchor to="/sites">Sites</Anchor>, 'Edit Site']

    return (
      <section className="EditSite">
        <FormHeader crumbs={crumbs} />
        <EditSiteForm
          submitAction={updateSiteAction}
          initialValues={site}
          enableReinitialize
          companies={companies}
          formValues={formValues}
          networks={networks}
        />
      </section>
    )
  }
}

export default EditSite
