import { connect } from 'react-redux'
import PublicReport from '../components/PublicReport'
import { getPublicReportAction } from '../actions/reportsActions'
import { getCurrentReport } from '../reducers/reportsReducer'
import { isReportLoading } from '../reducers/uiReducer'

const mapStateToProps = ({ reports, ui }) => ({
  report: getCurrentReport(reports),
  isLoading: isReportLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getReport: reportSlug => dispatch(getPublicReportAction.request(reportSlug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicReport)
