import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Anchor, ActionAnchor } from './common/Anchor'
import { Table, Column, Spin, Modal } from './common/Ant'
import SelectContainer from '../containers/common/SelectContainer'
import { H3 } from './common/Headers'
import Checkbox from './common/Checkbox'
import Divider from './common/Divider'
import SiteThresholdMappings from './SiteThresholdMappings'
import { Option } from './common/Select'
import AggregateThresholdUserMappings from './AggregateThresholdUserMappings'
import EditSiteThreshold from './EditSiteThreshold'
import { createQueryString } from '../utils/queryParams'
import { hasPermission } from '../utils/hasPermission'
import { ALL, USER_PERMISSIONS } from '../constants'

import {
  getAllLocationGroupsAction,
  addThresholdToLocationGroupAction,
  deleteThresholdFromLocationGroupAction,
} from '../actions/locationGroupActions'
import { getVisibleLocationGroups } from '../reducers/locationGroupsReducer'

const mapStateToProps = ({ locationGroups }) => ({
  locationGroups: getVisibleLocationGroups(locationGroups),
})

const mapDispatchToProps = dispatch => ({
  getAllLocationGroups: payload =>
    dispatch(getAllLocationGroupsAction.request(payload)),
  addThresholdToLocationGroup: payload =>
    dispatch(addThresholdToLocationGroupAction.request(payload)),
  deleteThresholdFromLocationGroup: payload =>
    dispatch(deleteThresholdFromLocationGroupAction.request(payload)),
})

class SiteThresholds extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    siteSlug: PropTypes.string.isRequired,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    floors: PropTypes.arrayOf(PropTypes.object).isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllLocationsBySite: PropTypes.func.isRequired,
    addThresholdToSite: PropTypes.func.isRequired,
    deleteThresholdFromSite: PropTypes.func.isRequired,
    addThresholdToBuilding: PropTypes.func.isRequired,
    deleteThresholdFromBuilding: PropTypes.func.isRequired,
    addThresholdToFloor: PropTypes.func.isRequired,
    deleteThresholdFromFloor: PropTypes.func.isRequired,
    addThresholdToLocation: PropTypes.func.isRequired,
    deleteThresholdFromLocation: PropTypes.func.isRequired,
    currentSite: PropTypes.object.isRequired,
    getMappings: PropTypes.func.isRequired,
    mappings: PropTypes.arrayOf(PropTypes.object).isRequired,
    getSiteAggregateThresholdUserMappings: PropTypes.func.isRequired,
    updateSiteThresholdUserMapping: PropTypes.func.isRequired,
    siteAggregateThresholdUserMappings: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    updateBuildingThresholdUserMapping: PropTypes.func.isRequired,
    updateFloorThresholdUserMapping: PropTypes.func.isRequired,
    updateLocationThresholdUserMapping: PropTypes.func.isRequired,
    getAllLocationGroups: PropTypes.func.isRequired,
    addThresholdToLocationGroup: PropTypes.func.isRequired,
    deleteThresholdFromLocationGroup: PropTypes.func.isRequired,
    locationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllThresholds: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedThresholdId: '',
      hideTable: false,
      selectedThresholdName: '',
      isModalOpen: false,
      isSiteThreshold: false,
    }
  }

  static defaultProps = {
    locations: [],
    floors: [],
    buildings: [],
    locationGroups: [],
  }

  componentDidMount() {
    const {
      getAllLocationsBySite,
      siteSlug,
      getMappings,
      getAllLocationGroups,
      getAllThresholds,
    } = this.props

    const qs = createQueryString({ perPage: ALL })

    getAllLocationsBySite(siteSlug)
    getMappings()
    getAllLocationGroups({
      siteSlug,
      qs,
    })
    getAllThresholds(qs)
  }

  componentWillUnmount() {
    if (this.state.isModalOpen) {
      this.handleClose()
    }
  }

  handleModalOpen = () => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false })
  }

  handleTableShowChange = value => {
    this.setState({ hideTable: value })
  }

  handleAddThreshold = thresholdId => {
    const threshold = this.props.thresholds.filter(x => x.id === thresholdId)[0]

    this.setState({
      selectedThresholdId: thresholdId ? thresholdId : '',
      selectedThresholdName: thresholdId ? threshold.name : '',
      isSiteThreshold: !!threshold.siteSlug,
    })
  }

  handleAddThresholdToSite = tresholdId => {
    this.props.addThresholdToSite(this.props.siteSlug, tresholdId)
  }

  handleDeleteThresholdFromSite = thresholdId => {
    this.props.deleteThresholdFromSite(this.props.siteSlug, thresholdId)
  }

  handleAddThresholdToBuilding = (buildingId, thresholdId) => {
    this.props.addThresholdToBuilding(
      this.props.siteSlug,
      buildingId,
      thresholdId
    )
  }

  handleDeleteThresholdFromBuilding = (buildingId, thresholdId) => {
    this.props.deleteThresholdFromBuilding(
      this.props.siteSlug,
      buildingId,
      thresholdId
    )
  }

  handleAddThresholdToFloor = (floorSlug, floorId, thresholdId) => {
    this.props.addThresholdToFloor(
      this.props.siteSlug,
      floorSlug,
      thresholdId,
      floorId
    )
  }

  handleDeleteThresholdFromFloor = (floorSlug, floorId, thresholdId) => {
    this.props.deleteThresholdFromFloor(
      this.props.siteSlug,
      floorSlug,
      thresholdId,
      floorId
    )
  }

  handleAddThresholdToLocationGroup = (locationGroupSlug, thresholdId) => {
    const { addThresholdToLocationGroup, siteSlug } = this.props
    addThresholdToLocationGroup({
      siteSlug,
      locationGroupSlug,
      thresholdId,
    })
  }

  handleDeleteThresholdFromLocationGroup = (
    locationGroupSlug,
    locationGroupThresholdId
  ) => {
    const { deleteThresholdFromLocationGroup, siteSlug } = this.props
    deleteThresholdFromLocationGroup({
      siteSlug,
      locationGroupSlug,
      locationGroupThresholdId,
    })
  }

  handleAddThresholdToLocation = (floorId, id, thresholdId) => {
    this.props.addThresholdToLocation(
      this.props.siteSlug,
      floorId,
      id,
      thresholdId
    )
  }

  handleDeleteThresholdFromLocation = (floorId, id, thresholdId) => {
    this.props.deleteThresholdFromLocation(
      this.props.siteSlug,
      floorId,
      id,
      thresholdId
    )
  }

  handleFilterChange = (keyName, keyValue) => value => {
    const { selectedThresholdId } = this.state

    if (keyName === 'site') {
      if (value) {
        this.handleAddThresholdToSite(selectedThresholdId)
      } else {
        this.handleDeleteThresholdFromSite(keyValue.activeThresholdMappingId)
      }
    } else if (keyName === 'building') {
      if (value) {
        this.handleAddThresholdToBuilding(keyValue.id, selectedThresholdId)
      } else {
        this.handleDeleteThresholdFromBuilding(
          keyValue.id,
          keyValue.activeThresholdMappingId
        )
      }
    } else if (keyName === 'floor') {
      if (value) {
        this.handleAddThresholdToFloor(
          keyValue.slug,
          keyValue.id,
          selectedThresholdId
        )
      } else {
        this.handleDeleteThresholdFromFloor(
          keyValue.slug,
          keyValue.id,
          keyValue.activeThresholdMappingId
        )
      }
    } else if (keyName === 'locationGroup') {
      if (value) {
        this.handleAddThresholdToLocationGroup(
          keyValue.slug,
          selectedThresholdId
        )
      } else {
        this.handleDeleteThresholdFromLocationGroup(
          keyValue.slug,
          keyValue.activeThresholdMappingId
        )
      }
    } else if (keyName === 'location') {
      if (value) {
        this.handleAddThresholdToLocation(
          keyValue.floorId,
          keyValue.id,
          selectedThresholdId
        )
      } else {
        this.handleDeleteThresholdFromLocation(
          keyValue.floorId,
          keyValue.id,
          keyValue.activeThresholdMappingId
        )
      }
    }
  }

  render() {
    const {
      isLoading,
      thresholds,
      siteSlug,
      mappings,
      getMappings,
      buildings,
      floors,
      locations,
      locationGroups,
      currentSite,
      getSiteAggregateThresholdUserMappings,
      updateSiteThresholdUserMapping,
      siteAggregateThresholdUserMappings,
      updateBuildingThresholdUserMapping,
      updateFloorThresholdUserMapping,
      updateLocationThresholdUserMapping,
      windowWidth,
    } = this.props
    const {
      selectedThresholdId,
      selectedThresholdName,
      isSiteThreshold,
    } = this.state

    const expandedLocationRowRender = floor => {
      const groups = locationGroups.filter(
        x =>
          !!x.locations.length && x.locations[0].floorId === parseInt(floor.id)
      )

      const threshold = mappings.filter(x => x.id === selectedThresholdId)

      groups.forEach(x => {
        let activeThreshold = threshold.length
          ? threshold[0].locationGroup.filter(y => y.slug === x.slug)
          : []
        x.activeThreshold = activeThreshold.length ? true : false
        x.activeThresholdId = activeThreshold.length ? selectedThresholdId : ''
        x.activeThresholdMappingId = activeThreshold.length
          ? activeThreshold[0].thresholdMappingId
          : ''
      })

      return (
        <>
          {groups.length > 0 && (
            <div className="mt1">
              <Table
                dataSource={groups}
                rowKey="slug"
                bordered
                pagination={false}
                tableLayout="auto"
              >
                <Column
                  title="Room Name"
                  width={200}
                  dataIndex="name"
                  render={(text, record) =>
                    text ? (
                      <Anchor
                        to={`/sites/${siteSlug}/floors/${floor.id}/rooms`}
                      >
                        {text}
                      </Anchor>
                    ) : (
                      '--'
                    )
                  }
                />
                <Column
                  title="Add to Room"
                  width={200}
                  dataIndex="activeThreshold"
                  render={(text, record) => {
                    return (
                      <Checkbox
                        type="checkbox"
                        input={{
                          checked: record.activeThreshold,
                          onChange: this.handleFilterChange(
                            'locationGroup',
                            record
                          ),
                        }}
                        noMargin
                      />
                    )
                  }}
                />
              </Table>
            </div>
          )}
          <div className="mt1">
            <Table
              dataSource={floor.locations}
              rowKey="id"
              bordered
              pagination={false}
              tableLayout="auto"
            >
              <Column
                title="Location Name"
                width={200}
                dataIndex="name"
                render={(text, record) =>
                  text ? (
                    <Anchor
                      to={`/sites/${siteSlug}/floors/${floor.id}/locations/${record.id}`}
                    >
                      {text}
                    </Anchor>
                  ) : (
                    '--'
                  )
                }
              />
              <Column
                title="Add to Location"
                width={200}
                dataIndex="activeThreshold"
                render={(text, record) => {
                  return (
                    <Checkbox
                      type="checkbox"
                      input={{
                        checked: record.activeThreshold,
                        onChange: this.handleFilterChange('location', record),
                      }}
                      noMargin
                    />
                  )
                }}
              />
            </Table>
          </div>
        </>
      )
    }

    const expandedFloorRowRender = building => (
      <div className="mt1">
        <Table
          className="nested-table"
          expandedRowRender={expandedLocationRowRender}
          dataSource={building.floors}
          rowKey="id"
          bordered
          tableLayout="auto"
          pagination={false}
        >
          <Column
            title="Floor Name"
            width={200}
            dataIndex="name"
            render={(text, record) =>
              text ? (
                <Anchor to={`/sites/${siteSlug}/floors/${record.id}`}>
                  {text}
                </Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Add to floor"
            width={200}
            dataIndex="activeThreshold"
            render={(text, record) => {
              return (
                <Checkbox
                  type="checkbox"
                  input={{
                    checked: record.activeThreshold,
                    onChange: this.handleFilterChange('floor', record),
                  }}
                  noMargin
                />
              )
            }}
          />
        </Table>
      </div>
    )

    const expandedRowRender = site => (
      <div className="mt1">
        <Table
          className="nested-table"
          expandedRowRender={expandedFloorRowRender}
          dataSource={site.buildings}
          rowKey="id"
          bordered
          tableLayout="auto"
          pagination={false}
        >
          <Column
            title="Building Name"
            width={200}
            dataIndex="name"
            render={(text, record) =>
              text ? (
                <Anchor to={`/sites/${siteSlug}/buildings/${record.id}`}>
                  {text}
                </Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Add to building"
            width={200}
            dataIndex="activeThreshold"
            render={(text, record) => {
              return (
                <Checkbox
                  type="checkbox"
                  input={{
                    checked: record.activeThreshold,
                    onChange: this.handleFilterChange('building', record),
                  }}
                  noMargin
                />
              )
            }}
          />
        </Table>
      </div>
    )

    const threshold = mappings.filter(x => x.id === selectedThresholdId)

    buildings.forEach(building => {
      let activeThreshold = threshold.length
        ? threshold[0].building.filter(x => x.id === building.id)
        : []
      building.activeThreshold = activeThreshold.length ? true : false
      building.activeThresholdId = activeThreshold.length
        ? selectedThresholdId
        : ''
      building.activeThresholdMappingId = activeThreshold.length
        ? activeThreshold[0].thresholdMappingId
        : ''

      const floorData = []
      floors.forEach(floor => {
        activeThreshold = threshold.length
          ? threshold[0].floor.filter(x => x.id === floor.id)
          : []
        floor.activeThreshold = activeThreshold.length ? true : false
        floor.activeThresholdId = activeThreshold.length
          ? selectedThresholdId
          : ''
        floor.activeThresholdMappingId = activeThreshold.length
          ? activeThreshold[0].thresholdMappingId
          : ''

        const locationData = []
        locations.forEach(location => {
          activeThreshold = threshold.length
            ? threshold[0].location.filter(x => x.id === location.id)
            : []
          location.activeThreshold = activeThreshold.length ? true : false
          location.activeThresholdId = activeThreshold.length
            ? selectedThresholdId
            : ''
          location.activeThresholdMappingId = activeThreshold.length
            ? activeThreshold[0].thresholdMappingId
            : ''

          if (floor.id === location.floorId) {
            locationData.push(location)
          }
        })
        floor.locations = locationData

        if (building.id === floor.buildingId) {
          floorData.push(floor)
        }
      })
      building.floors = floorData
    })

    const siteThreshold = threshold.length ? threshold[0].site : []
    const site = [
      {
        slug: currentSite ? currentSite.slug : '',
        name: currentSite ? currentSite.name : '',
        activeThreshold: siteThreshold.length ? true : false,
        activeThresholdMappingId: siteThreshold.length
          ? siteThreshold[0].thresholdMappingId
          : '',
        buildings: buildings,
      },
    ]

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : (
      <Fragment>
        {selectedThresholdId ? (
          <H3>
            Threshold /{' '}
            {hasPermission(USER_PERMISSIONS.EDIT_THRESHOLD) ? (
              <Anchor to={`/rules/thresholds/${selectedThresholdId}`}>
                {selectedThresholdName}
              </Anchor>
            ) : (
              selectedThresholdName
            )}
          </H3>
        ) : (
          <H3 className="red">
            Please select a threshold before mapping to site.
          </H3>
        )}

        <div className="flex justify-between items-center">
          <div className="w-50-ns w-100">
            <SelectContainer
              placeholder="Select a threshold"
              input={{
                onChange: this.handleAddThreshold,
              }}
              filterable
            >
              {[{ name: '-- None --', id: 0 }, ...thresholds].map(x => (
                <Option value={x.id} key={x.id}>
                  {x.name}
                </Option>
              ))}
            </SelectContainer>
          </div>

          {hasPermission(USER_PERMISSIONS.ADD_SITE_THRESHOLD) && (
            <div className="w-50-ns w-100 mb3 ml3">
              {!!selectedThresholdId && isSiteThreshold && (
                <>
                  <ActionAnchor onClick={this.handleModalOpen}>
                    Edit Site Threshold
                  </ActionAnchor>
                  <Divider vertical />
                </>
              )}
              <ActionAnchor onClick={this.handleModalOpen}>
                Create New Threshold
              </ActionAnchor>

              <Modal
                visible={this.state.isModalOpen}
                className="Modal"
                title="Create New Threshold"
                onCancel={this.handleModalClose}
                width={windowWidth < 1200 ? windowWidth - 32 : 1200}
                footer={null}
                maskClosable={false}
                destroyOnClose
              >
                <EditSiteThreshold
                  siteSlug={siteSlug}
                  handleModalClose={this.handleModalClose}
                  selectedThresholdId={selectedThresholdId}
                />
              </Modal>
            </div>
          )}
        </div>

        {selectedThresholdId && (
          <>
            <Table
              className="nested-table"
              dataSource={site}
              rowKey="id"
              expandedRowRender={expandedRowRender}
              pagination={false}
              tableLayout="auto"
              bordered
            >
              <Column
                title="Site Name"
                width={200}
                dataIndex="name"
                render={(text, record) =>
                  text ? (
                    <Anchor to={`/sites/${siteSlug}`}>{text}</Anchor>
                  ) : (
                    '--'
                  )
                }
              />
              <Column
                title="Add to site"
                width={200}
                dataIndex="activeThreshold"
                render={(text, record) => {
                  return (
                    <Checkbox
                      type="checkbox"
                      input={{
                        checked: record.activeThreshold,
                        onChange: this.handleFilterChange('site', record),
                      }}
                      noMargin
                    />
                  )
                }}
              />
            </Table>
            <div className="w-100-ns flex">
              <span className="w-90-ns">
                <H3>Threshold User Mappings</H3>
              </span>
              <span className="flex">
                <span className="mr2-ns">hide</span>
                <span>
                  <Checkbox
                    type="checkbox"
                    input={{
                      checked: this.state.hideTable,
                      onChange: this.handleTableShowChange,
                    }}
                    noMargin
                  />
                </span>
              </span>
            </div>
            <AggregateThresholdUserMappings
              getMappings={() =>
                getSiteAggregateThresholdUserMappings(siteSlug)
              }
              updateMapping={updateSiteThresholdUserMapping}
              mappings={siteAggregateThresholdUserMappings}
              selectedThresholdId={selectedThresholdId}
              hideTable={this.state.hideTable}
              siteSlug={siteSlug}
              siteName={currentSite.name}
              updateBuildingThresholdUserMapping={
                updateBuildingThresholdUserMapping
              }
              updateFloorThresholdUserMapping={updateFloorThresholdUserMapping}
              updateLocationThresholdUserMapping={
                updateLocationThresholdUserMapping
              }
              buildings={buildings}
            />
          </>
        )}

        <H3>Threshold Mappings</H3>
        <SiteThresholdMappings getMappings={getMappings} mappings={mappings} />
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteThresholds)
