import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllActionReportCausesAction,
  getActionReportCauseAction,
  createActionReportCauseAction,
  updateActionReportCauseAction,
  deleteActionReportCauseAction,
} from '../actions/actionReportsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE, MESSAGES } from '../constants'
import { parseQueryParams, createQueryString } from '../utils/queryParams'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllActionReportCausesSaga({ payload }) {
  try {
    const result = yield call(api.getAllActionReportCauses, payload)
    yield put(getAllActionReportCausesAction.success(result))
  } catch (error) {
    yield put(getAllActionReportCausesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetActionReportCauseSaga({ payload }) {
  try {
    const result = yield call(api.getActionReportCauseBySlug, payload)
    yield put(getActionReportCauseAction.success(result))
  } catch (error) {
    yield put(getActionReportCauseAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateActionReportCauseSaga({ payload }) {
  try {
    const result = yield call(api.createActionReportCause, payload)

    yield put(createActionReportCauseAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push('/metadata/all/action-report-causes'))
  } catch (error) {
    yield put(createActionReportCauseAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateActionReportCauseSaga({ payload }) {
  try {
    const result = yield call(api.updateActionReportCause, payload)
    yield put(updateActionReportCauseAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push('/metadata/all/action-report-causes'))
  } catch (error) {
    yield put(updateActionReportCauseAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteActionReportCauseSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteActionReportCause, payload)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))

      const { page, perPage } = parseQueryParams(global.location)

      const query = {
        page: page ? parseInt(page, 10) : DEFAULT_PAGE,
        perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      }

      const qs = createQueryString(query)

      yield fork(handleGetAllActionReportCausesSaga, qs)
    }
  } catch (error) {
    yield put(deleteActionReportCauseAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllActionReportCausesSaga() {
  yield takeLatest(
    getAllActionReportCausesAction.REQUEST,
    handleGetAllActionReportCausesSaga
  )
}

function* watchGetActionReportCauseSaga() {
  yield takeLatest(
    getActionReportCauseAction.REQUEST,
    handleGetActionReportCauseSaga
  )
}

function* watchUpdateActionReportCauseSaga() {
  yield takeLatest(
    updateActionReportCauseAction.REQUEST,
    handleUpdateActionReportCauseSaga
  )
}

function* watchDeleteActionReportCauseSaga() {
  yield takeLatest(
    deleteActionReportCauseAction.REQUEST,
    handleDeleteActionReportCauseSaga
  )
}

function* watchCreateActionReportCauseSaga() {
  yield takeLatest(
    createActionReportCauseAction.REQUEST,
    handleCreateActionReportCauseSaga
  )
}

function* actionReportsSaga() {
  yield all([
    fork(watchCreateActionReportCauseSaga),
    fork(watchGetAllActionReportCausesSaga),
    fork(watchDeleteActionReportCauseSaga),
    fork(watchUpdateActionReportCauseSaga),
    fork(watchGetActionReportCauseSaga),
  ])
}

export {
  actionReportsSaga as default,
  watchGetAllActionReportCausesSaga,
  watchCreateActionReportCauseSaga,
  watchDeleteActionReportCauseSaga,
  watchUpdateActionReportCauseSaga,
  watchGetActionReportCauseSaga,
  handleGetActionReportCauseSaga,
  handleDeleteActionReportCauseSaga,
  handleUpdateActionReportCauseSaga,
  handleCreateActionReportCauseSaga,
  handleGetAllActionReportCausesSaga,
}
