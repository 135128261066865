import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { H2 } from './common/Headers'
import { Anchor, ActionAnchor } from './common/Anchor'
import InputContainer from '../containers/common/InputContainer'
import { hasPermission } from '../utils/hasPermission'
import { createQueryString } from '../utils/queryParams'
import { configurePagination, escapeRegExp } from '../utils/helpers'
import { USER_PERMISSIONS } from '../constants'

class AllEscalationPolicies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.escalationPolicies,
      searchText: '',
      pagination: {
        current: 1,
      },
      filtered: false,
    }
  }

  static propTypes = {
    escalationPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.object,
    getAllEscalationPolicies: PropTypes.func,
    deleteEscalationPolicy: PropTypes.func.isRequired,
    isEscalationPolicyLoading: PropTypes.bool.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.escalationPolicies })
  }

  componentDidMount() {
    this.props.getAllEscalationPolicies && this.props.getAllEscalationPolicies()
  }

  handleChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination, current: pagination.current }

    if (this.state.pagination.current !== pagination.current) {
      const query = { page: pagination.current }
      const qs = createQueryString(query)

      this.props.getAllEscalationPolicies(qs)
    }

    this.setState({
      pagination: pager,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  onInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () => this.onSearch())
  }

  onSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState({
      filtered: !!searchText,
      data: this.props.escalationPolicies
        .map(record => {
          const match =
            // TODO what are correct matchers?
            record.name.match(regex) || record.id.toString().match(regex)

          return match ? record : null
        })
        .filter(record => !!record),
    })
  }

  render() {
    const {
      isEscalationPolicyLoading,
      deleteEscalationPolicy,
      meta,
    } = this.props
    const { data, sortedInfo } = this.state

    return (
      <section className="mb5 AllEscalationPolicies">
        <Fragment>
          <H2 inline>Escalation Policies</H2>
          {hasPermission(USER_PERMISSIONS.ADD_ESCALATION_POLICY) && (
            <Anchor to="/rules/escalation-policies/create" button>
              Create New
            </Anchor>
          )}
        </Fragment>
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: this.state.searchText,
              onChange: this.onInputChange,
            }}
          />
        </div>
        {isEscalationPolicyLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            className="nested-table"
            dataSource={data}
            onChange={this.handleChange}
            bordered
            rowKey="slug"
            tableLayout="auto"
            pagination={configurePagination(meta)}
          >
            <Column
              title="Name"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              render={(text, record) => (
                <Anchor to={`/rules/escalation-policies/${record.slug}`}>
                  {text}
                </Anchor>
              )}
            />
            <Column
              title="Groups"
              dataIndex="policyGroupCounts"
              sorter={sortBy('policyGroupCounts')}
              sortOrder={
                sortedInfo.field === 'policyGroupCounts' && sortedInfo.order
              }
            />
            {hasPermission(USER_PERMISSIONS.EDIT_ESCALATION_POLICY) && (
              <Column
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-escalation-policy">
                        <Anchor
                          to={`/rules/escalation-policies/${record.slug}/edit`}
                        >
                          Edit
                        </Anchor>
                      </Menu.Item>
                      <Menu.Item key="delete-escalation-policy">
                        <ActionAnchor
                          onClick={() => deleteEscalationPolicy(record.slug)}
                        >
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )

                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllEscalationPolicies
