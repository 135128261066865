import React from 'react'
import EditCompanyForm from '../components/forms/EditCompanyForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createCompanyAction } from '../actions/companiesActions'

const CreateCompany = props => {
  const crumbs = [<Anchor to="/companies">Companies</Anchor>, 'Create Company']

  return (
    <section className="CreateCompany">
      <FormHeader crumbs={crumbs} />
      <EditCompanyForm submitAction={createCompanyAction} />
    </section>
  )
}
export default CreateCompany
