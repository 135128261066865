import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { AntDivider } from './Ant'

const Divider = ({ vertical = false, className = '' }) => {
  const dividerClassNames = cx(
    {
      'mb3 mt0': !vertical,
      mh3: vertical,
    },
    className
  )

  return vertical ? (
    <AntDivider type="vertical" className={dividerClassNames} />
  ) : (
    <AntDivider className={dividerClassNames} />
  )
}

Divider.propTypes = {
  className: PropTypes.string,
  vertical: PropTypes.bool,
}

export default Divider
