import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditGatewayForm from '../components/forms/EditGatewayForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createGatewayAction } from '../actions/gatewaysActions'
import { createQueryString } from '../utils/queryParams'

class CreateGateway extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllTags: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  componentDidMount() {
    const { getAllAssetLocations, getAllTags } = this.props
    const qs = createQueryString({ perPage: 'all' })
    getAllAssetLocations(qs)
    getAllTags(
      createQueryString({
        perPage: 'all',
      })
    )
  }

  render() {
    const { tags, assetLocations } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/gateways">Network Gateways</Anchor>,
      'Create Network Gateway',
    ]

    return (
      <section className="CreateGateway">
        <FormHeader crumbs={crumbs} />
        <EditGatewayForm
          assetLocations={assetLocations}
          submitAction={createGatewayAction}
          tags={tags}
          initialValues={{ tagIds: [] }}
        />
      </section>
    )
  }
}

export default CreateGateway
