import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditFloorForm from '../components/forms/EditFloorForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createFloorAction } from '../actions/floorsActions'

class CreateFloor extends Component {
  static propTypes = {
    getAllBuildings: PropTypes.func.isRequired,
    getSite: PropTypes.func.isRequired,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object,
    siteName: PropTypes.string,
  }

  componentDidMount() {
    const { match, siteName, getSite } = this.props
    const { siteSlug } = match.params

    this.props.getAllBuildings(siteSlug)

    if (!siteName) {
      getSite(siteSlug)
    }
  }

  render() {
    const { buildings, match, siteName } = this.props

    const crumbs = [
      <Anchor to="/sites">Sites</Anchor>,
      siteName && (
        <Anchor to={`/sites/${match.params.siteSlug}`}>{siteName}</Anchor>
      ),
      'Create Floor',
    ]

    return (
      <section className="CreateFloor">
        <FormHeader crumbs={crumbs} />
        <EditFloorForm
          submitAction={createFloorAction}
          buildings={buildings}
          siteSlug={match.params.siteSlug}
        />
      </section>
    )
  }
}

export default CreateFloor
