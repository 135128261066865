import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Clipboard from 'react-clipboard.js'
import { dateTimeFormatter } from '../utils/date'
import { message, Table, Column, Spin } from './common/Ant'
import { toTitleCase } from '../utils/textFormatters'
import { MESSAGES } from '../constants'

class EditHistory extends Component {
  componentDidMount() {
    const { resourceId, getEditHistory } = this.props
    getEditHistory(resourceId)
  }

  isJSONField = type => {
    switch (type) {
      case 'rules':
        return true
      default:
        return false
    }
  }

  render() {
    const { editHistory, isLoading } = this.props

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : editHistory.length ? (
      <Table
        dataSource={editHistory}
        rowKey="tableId"
        scroll={{ x: 450 }}
        pagination={false}
        tableLayout="auto"
        bordered
      >
        <Column
          title="Date"
          width={100}
          dataIndex="date"
          render={(text, record) => (text ? dateTimeFormatter(text) : '--')}
        />
        <Column title="Updated By" width={100} dataIndex="updatedBy" />
        <Column
          title="Updated Field"
          width={100}
          dataIndex="field"
          className="b"
          render={(text, record) => toTitleCase(text)}
        />
        <Column
          title="Previous Value"
          width={100}
          dataIndex="from"
          className="red bg-washed-red i"
          render={(text, record) =>
            this.isJSONField(record.field) ? (
              <Clipboard
                component="a"
                data-clipboard-text={text}
                onSuccess={() => message.success(MESSAGES.COPIED)}
                className="pt1 br3 fs-normal"
              >
                Copy JSON To Clipboard
              </Clipboard>
            ) : text ? (
              text
            ) : (
              '--'
            )
          }
        />
        <Column
          title="Updated Value"
          width={100}
          dataIndex="to"
          className="green bg-washed-green b"
          render={(text, record) =>
            this.isJSONField(record.field) ? (
              <Clipboard
                component="a"
                data-clipboard-text={text}
                onSuccess={() => message.success(MESSAGES.COPIED)}
                className="pt1 br3 normal"
              >
                Copy JSON To Clipboard
              </Clipboard>
            ) : text ? (
              text
            ) : (
              '--'
            )
          }
        />
      </Table>
    ) : (
      'No Edit History'
    )
  }
}

EditHistory.propTypes = {
  editHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  getEditHistory: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default EditHistory
