import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { reduxForm, Field } from 'redux-form'
import MainContent from '../common/MainContent'
import { H2 } from '../common/Headers'
import InputContainer from '../../containers/common/InputContainer'
import Button from '../common/Button'
import FormError from '../common/FormError'
import { Anchor } from '../common/Anchor'
import { resetPasswordAction } from '../../actions/authActions'
import { required, maxLength, match, minLength } from '../../utils/validators'
import { ERROR_MESSAGES } from '../../constants'

const maxLength30 = maxLength(30)
const minLength6 = minLength(6)

class ResetPasswordForm extends Component {
  static propTypes = {
    setPasswordResetToken: PropTypes.func.isRequired,
    verifyResetPasswordToken: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    isValidToken: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    submitSucceeded: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      matchPassword: match(''),
    }
  }

  componentDidMount() {
    const { token } = this.props
    if (token) {
      this.props.setPasswordResetToken(token)
      this.props.verifyResetPasswordToken(token)
    }
  }

  handleChange = password => {
    const value = password.target.value
    const matchPassword = match(value)
    this.setState({ matchPassword })
  }

  render() {
    const { isValidToken, handleSubmit, error, submitSucceeded } = this.props

    const submit = handleSubmit(resetPasswordAction)
    const { matchPassword } = this.state

    return (
      <MainContent>
        <section className="ResetPasswordForm">
          <Helmet>
            <title>Reset Password</title>
          </Helmet>
          <form
            className="measure-narrow center mb2"
            onSubmit={submit}
            noValidate
          >
            {isValidToken ? (
              <Fragment>
                <H2>Reset Password</H2>
                <Field
                  name="password"
                  type="password"
                  component={InputContainer}
                  label="New Password"
                  onChange={this.handleChange}
                  validate={[required, minLength6, maxLength30]}
                />
                <Field
                  name="confirmPassword"
                  type="password"
                  component={InputContainer}
                  label="Confirm Password"
                  validate={[required, matchPassword]}
                />
                <FormError error={error} />
                {submitSucceeded ? (
                  <p className="mb3">
                    Success! Please <Anchor to="/">log in</Anchor> with your new
                    password.
                  </p>
                ) : (
                  <Button
                    className="w-100"
                    text="Reset Password"
                    type="submit"
                    submitting={this.props.submitting}
                  />
                )}
              </Fragment>
            ) : (
              <p className="mb3">{ERROR_MESSAGES.TOKEN}</p>
            )}
          </form>
        </section>
      </MainContent>
    )
  }
}

export default reduxForm({ form: 'ResetPasswordForm' })(ResetPasswordForm)
