import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { createQueryString } from '../utils/queryParams'
import { getPathSnippetAtIndex } from '../utils/helpers'

import {
  getAllLocationsByFloorAction,
  getLocationTimeSeriesDataByLocationIdsAction,
} from '../actions/locationsActions'
import {
  getVisibleLocations,
  getVisibleTimeSeriesData,
} from '../reducers/locationsReducer'
import { isLocationLoading } from '../reducers/uiReducer'

const chartOptions = {
  chart: {
    backgroundColor: null,
    borderWidth: 0,
    type: 'area',
    margin: [2, 0, 2, 0],
    width: 120,
    height: 30,
    style: {
      overflow: 'visible',
    },
    // small optimalization, saves 1-2 ms each sparkline
    skipClone: true,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  exporting: false,
  xAxis: {
    type: 'datetime',
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tickPositions: [0],
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    hideDelay: 0,
    outside: true,
    shared: true,
    valueDecimals: 2,
  },
  plotOptions: {
    series: {
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
      fillOpacity: 0.25,
    },
    column: {
      negativeColor: '#910000',
      borderColor: 'silver',
    },
  },
}

const LocationsSparklines = ({
  siteSlug,
  floorId,
  locations,
  timeSeriesData,
  getAllLocationsByFloor,
  getLocationsTimeSeriesData,
}) => {
  useEffect(() => {
    getAllLocationsByFloor(siteSlug, floorId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      if (locations.length > 0) {
        const query = {
          locationIds: locations.map(x => x.id).join(','),
          columns: 'temp,hum,pres,dust,carbonMonoxide', // TODO multi columns?
          startTime: moment(new Date())
            .subtract(1, 'w')
            .unix(),
          endTime: moment(new Date()).unix(),
        }

        getLocationsTimeSeriesData(siteSlug, createQueryString(query))
      }
    },
    [locations.length] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div>
      <div className="flex items-center bt b--light-gray pv1">
        <div className="w-30">Location</div>
        <div className="w-70">
          <div className="flex justify-between">
            <div className="tc" style={{ width: '120px' }}>
              Temperature
            </div>
            <div className="tc" style={{ width: '120px' }}>
              Humidity
            </div>
            <div className="tc" style={{ width: '120px' }}>
              Dust
            </div>
            <div className="tc" style={{ width: '120px' }}>
              Carbon Monoxide
            </div>
            <div className="tc" style={{ width: '120px' }}>
              Pressure
            </div>
          </div>
        </div>
      </div>
      {timeSeriesData.map(x => {
        return (
          <div
            className="flex items-center bt b--light-gray pv1"
            key={x.info.slug}
          >
            <div className="w-30 f4" style={{ height: '30px' }}>
              {x.info.name}
            </div>
            <div className="w-70">
              {x.data.length > 0 ? (
                <div className="flex justify-between">
                  <div className="w-25 flex justify-center">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        ...chartOptions,
                        series: [
                          {
                            name: 'Temperature',
                            data: x.data.map(y => [
                              moment(y.time).valueOf(),
                              y.temp,
                            ]),
                            color: '#7cb5ec',
                          },
                        ],
                      }}
                    />
                  </div>

                  <div className="w-25 flex justify-center">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        ...chartOptions,
                        series: [
                          {
                            name: 'Humidity',
                            data: x.data.map(y => [
                              moment(y.time).valueOf(),
                              y.hum,
                            ]),
                            color: '#434348',
                          },
                        ],
                      }}
                    />
                  </div>

                  <div className="w-25 flex justify-center">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        ...chartOptions,
                        series: [
                          {
                            name: 'Dust',
                            data: x.data.map(y => [
                              moment(y.time).valueOf(),
                              y.dust,
                            ]),
                            color: '#f7a35c',
                          },
                        ],
                      }}
                    />
                  </div>

                  <div className="w-25 flex justify-center">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        ...chartOptions,
                        series: [
                          {
                            name: 'Carbon Monoxide',
                            data: x.data.map(y => [
                              moment(y.time).valueOf(),
                              y.carbonMonoxide,
                            ]),
                            color: '#777777',
                          },
                        ],
                      }}
                    />
                  </div>

                  <div className="w-25 flex justify-center">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        ...chartOptions,
                        series: [
                          {
                            name: 'Pressure',
                            data: x.data.map(y => [
                              moment(y.time).valueOf(),
                              y.pres,
                            ]),
                            color: '#90ed7d',
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="tc">NO DATA</div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

LocationsSparklines.propTypes = {
  siteSlug: PropTypes.string.isRequired,
  floorId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeSeriesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getAllLocationsByFloor: PropTypes.func.isRequired,
  getLocationsTimeSeriesData: PropTypes.func.isRequired,
}

const mapStateToProps = ({ locations, router, ui }) => ({
  siteSlug: getPathSnippetAtIndex(router.location.pathname, 1),
  floorId: getPathSnippetAtIndex(router.location.pathname, 3),
  locations: getVisibleLocations(locations),
  timeSeriesData: getVisibleTimeSeriesData(locations),
  isLoading: isLocationLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllLocationsByFloor: (siteSlug, floorId) =>
    dispatch(getAllLocationsByFloorAction.request({ siteSlug, floorId })),
  getLocationsTimeSeriesData: (siteSlug, qs) =>
    dispatch(
      getLocationTimeSeriesDataByLocationIdsAction.request({ siteSlug, qs })
    ),
})

const LocationsSparklinesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsSparklines)

export default LocationsSparklinesContainer
