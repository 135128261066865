import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Form from './common/Form'
import FormError from './common/FormError'
import { H3 } from './common/Headers'
import Select, { Option } from './common/Select'
import Divider from './common/Divider'
import Button from './common/Button'
import Input from './common/Input'
// import { ActionAnchor } from './common/Anchor'
import InlineInput from './common/InlineInput'
import InlineSelect from './common/InlineSelect'
import { toTitleCase, renderThresholdType } from '../utils/textFormatters'
import { getSensorReading } from '../utils/sensorData'
import {
  ROLES,
  THRESHOLD_ALERT_TYPES,
  THRESHOLD_DATA_SOURCES,
  THRESHOLD_TYPES,
  THRESHOLD_LEVELS,
} from '../constants'
import {
  getThresholdAction,
  createSiteThresholdAction,
  updateSiteThresholdAction,
} from '../actions/thresholdsActions'
import { getCurrentThreshold } from '../reducers/thresholdsReducer'

const mapStateToProps = ({ thresholds }) => ({
  threshold: getCurrentThreshold(thresholds),
})

const mapDispatchToProps = dispatch => ({
  getThreshold: id => dispatch(getThresholdAction.request(id)),
  createThreshold: payload =>
    dispatch(createSiteThresholdAction.request(payload)),
  updateThreshold: payload =>
    dispatch(updateSiteThresholdAction.request(payload)),
})

const thresholdTypes = Object.keys(THRESHOLD_TYPES)
  .map(x => ({
    value: THRESHOLD_TYPES[x],
    label: renderThresholdType(THRESHOLD_TYPES[x]),
  }))
  .filter(
    x =>
      x.value === THRESHOLD_TYPES.LEVEL ||
      x.value === THRESHOLD_TYPES.LEVEL_OVER_DURATION ||
      x.value === THRESHOLD_TYPES.EQUAL
  )

const generateMessage = rule => {
  const { source, low, high /*duration, target, thresholdType*/ } = rule
  const readingData = getSensorReading(source)
  const highLow = !!high ? 'above' : 'below'
  const value = !!high ? high : low

  return `Alert - ${readingData.name} ${highLow} ${value}${readingData.unit} detected at {siteName} from Sensor {locationName}, located on Floor {flrNumber}. Current ${readingData.name} is {${source}}${readingData.unit}. Visit your dashboard for further information.`
}

const Rule = ({ rule, index, handleUpdateRule /*, handleRemoveRule*/ }) => {
  const { source, low, high, duration, target, thresholdType } = rule

  return (
    <div className="Rule mb3 ba br3 b--light-gray bg-near-white pa2">
      <div className="w-100 w-25-ns">
        <Select
          placeholder="Select a Rule type"
          input={{
            value: thresholdType,
            onChange: x => handleUpdateRule(index, 'thresholdType', x),
          }}
          filterable
        >
          {thresholdTypes.map(x => (
            <Option value={x.value} key={x.value}>
              {x.label}
            </Option>
          ))}
        </Select>
      </div>
      <div className="f4 flex flex-wrap items-center">
        <div className="mb2">
          {/* TODO this is maybe confusing since these are "AND" statements */}
          Trigger an alert when the
          <InlineSelect
            value={source}
            setValue={x => handleUpdateRule(index, 'source', x)}
            label="Sensor"
            options={Object.keys(THRESHOLD_DATA_SOURCES).map(x => ({
              label: toTitleCase(x),
              value: THRESHOLD_DATA_SOURCES[x],
            }))}
          />
        </div>

        {(thresholdType === THRESHOLD_TYPES.LEVEL ||
          thresholdType === THRESHOLD_TYPES.LEVEL_OVER_DURATION) && [
          <div className="mb2">
            goes
            <span
              style={{
                color: !!high ? 'black' : 'lightgray',
                marginLeft: '0.5rem',
                textDecoration: !high && 'line-through',
                fontWeight: !!high && '700',
              }}
            >
              above
            </span>
            <InlineInput
              value={high}
              setValue={x => handleUpdateRule(index, 'high', x)}
              label="High, eg 135"
              type="number"
            />
          </div>,
          <div className="mb2">
            <span
              style={{
                color: !!low ? 'black' : 'lightgray',
                textDecoration: !low && 'line-through',
              }}
            >
              {!!high || !low ? 'or' : ''}{' '}
              <span style={{ fontWeight: !!low && '700' }}>below</span>
            </span>
            <InlineInput
              value={low}
              setValue={x => handleUpdateRule(index, 'low', x)}
              label="Low, eg 30"
              type="number"
            />
            {thresholdType === THRESHOLD_TYPES.LEVEL && '.'}
          </div>,
        ]}
        {thresholdType === THRESHOLD_TYPES.LEVEL_OVER_DURATION && (
          <div className="mb2">
            for longer than
            <InlineInput
              value={duration}
              setValue={x => handleUpdateRule(index, 'duration', x)}
              label="Duration, eg 60"
              type="number"
              required
            />
            seconds.
          </div>
        )}
        {thresholdType === THRESHOLD_TYPES.EQUAL && (
          <div className="mb2">
            equals
            <InlineInput
              value={target}
              setValue={x => handleUpdateRule(index, 'target', x)}
              label="Target, eg 1"
              type="number"
              required
            />
            .
          </div>
        )}
      </div>
      {/* TODO */}
      {/* <ActionAnchor onClick={() => handleRemoveRule(index)}> */}
      {/*   Remove Rule */}
      {/* </ActionAnchor> */}
    </div>
  )
}

Rule.propTypes = {
  rule: PropTypes.shape({
    source: PropTypes.string.isRequired,
    low: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    high: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  index: PropTypes.number.isRequired,
  handleUpdateRule: PropTypes.func.isRequired,
  // handleRemoveRule: PropTypes.func.isRequired,
}

const EditSiteThreshold = ({
  createThreshold,
  handleModalClose,
  updateThreshold,
  getThreshold,
  threshold,
  selectedThresholdId,
  siteSlug,
}) => {
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [thresholdAlertType, setThresholdAlertType] = useState(
    THRESHOLD_ALERT_TYPES.ACTIONABLE
  )
  const [rules, setRules] = useState([])

  useEffect(() => {
    if (!!selectedThresholdId) {
      getThreshold(selectedThresholdId)
    }
  }, [getThreshold, selectedThresholdId])

  useEffect(() => {
    if (!!selectedThresholdId && !!threshold.name) {
      const { name, thresholdAlertType, rulesJson } = threshold

      setName(name)
      setThresholdAlertType(thresholdAlertType)
      setRules(rulesJson)
    }
  }, [selectedThresholdId, threshold])

  const handleSubmit = () => {
    if (!name) {
      return setError('Name is required.')
    } else if (rules.length < 1) {
      return setError('At least one Rule is required.')
    } else {
      setError('')
    }

    const payload = {
      name,
      visibleToUserRoles: [
        ROLES.SUPER_ADMIN,
        ROLES.PILLAR_ADMIN,
        ROLES.ORGANIZATION_ADMIN,
        ROLES.JOBSITE_ADMIN,
        ROLES.JOBSITE_USER,
      ],
      thresholdAlertType,
      rulesJson: rules,
      level: THRESHOLD_LEVELS.HIGH,
      // TODO only looks at first rule
      message: generateMessage(rules[0]),
      siteSlug,
    }

    if (!!selectedThresholdId && !!threshold.id) {
      updateThreshold({ ...payload, id: threshold.id })
    } else {
      createThreshold(payload)
    }

    handleModalClose()
  }

  const handleCreateRule = useCallback(
    () =>
      setRules([
        ...rules,
        {
          source: THRESHOLD_DATA_SOURCES.TEMPERATURE,
          low: '',
          high: '',
          duration: '',
          thresholdType: THRESHOLD_TYPES.LEVEL,
        },
      ]),
    [rules]
  )

  useEffect(() => {
    if (rules.length === 0) {
      handleCreateRule()
    }
  }, [rules.length, handleCreateRule])

  const handleUpdateRule = (index, keyName, value) => {
    const rule = { ...rules[index], [keyName]: value }
    setRules(rules.map((x, i) => (i === index ? rule : x)))
  }

  const handleRemoveRule = index => {
    const nextRules = rules.filter((_, i) => i !== index)
    setRules(nextRules)
  }

  return (
    <div className="EditSiteThreshold">
      <Form onSubmit={handleSubmit}>
        <Input
          label="Name"
          className="w-100"
          placeholder="Name"
          input={{
            value: name,
            onChange: ev => setName(ev.currentTarget.value),
          }}
        />

        <Select
          className="w-100"
          label="Threshold Type"
          placeholder="Select a Threshold type"
          input={{
            value: thresholdAlertType,
            onChange: setThresholdAlertType,
          }}
          filterable
        >
          {Object.keys(THRESHOLD_ALERT_TYPES).map(x => (
            <Option value={THRESHOLD_ALERT_TYPES[x]} key={x}>
              {toTitleCase(x)}
            </Option>
          ))}
        </Select>

        <Divider />

        <div>
          <H3>Rules</H3>
          {/* TODO */}
          {/* <div className="mb3"> */}
          {/*   <ActionAnchor onClick={handleCreateRule}>Add Rule</ActionAnchor> */}
          {/* </div> */}

          {rules.map((x, i) => (
            <Rule
              key={`${x.source}--${i}`}
              rule={x}
              index={i}
              handleUpdateRule={handleUpdateRule}
              handleRemoveRule={handleRemoveRule}
            />
          ))}
        </div>
      </Form>
      <FormError error={error} />
      <Button text="Submit" type="submit" onClick={handleSubmit} />
    </div>
  )
}

EditSiteThreshold.propTypes = {
  getThreshold: PropTypes.func.isRequired,
  createThreshold: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  updateThreshold: PropTypes.func.isRequired,
  threshold: PropTypes.object.isRequired,
  siteSlug: PropTypes.string.isRequired,
  selectedThresholdId: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSiteThreshold)
