import { connect } from 'react-redux'
import Root from '../components/Root'
import {
  hasAuthTokenAction,
  unauthorizedAction,
  setInitialPathnameAction,
} from '../actions/authActions'
import {
  updateWindowWidthAction,
  hidePhoneVerificationModalAction,
} from '../actions/uiActions'
import { requestPhoneVerificationTokenAction } from '../actions/accountActions'
import { isPhoneVerificationModalVisible } from '../reducers/uiReducer'
import { getAccountProfile } from '../reducers/accountReducer'
import { isSocketConnected } from '../reducers/socketReducer'

const mapStateToProps = ({ auth, ui, account, socket }) => ({
  isAuthorized: auth.isAuthorized,
  isModalVisible: isPhoneVerificationModalVisible(ui),
  accountProfile: getAccountProfile(account),
  isSocketConnected: isSocketConnected(socket),
})

const mapDispatchToProps = dispatch => ({
  hasAuthToken: location => dispatch(hasAuthTokenAction(location)),
  unauthorized: () => dispatch(unauthorizedAction()),
  updateWindowWidth: width => dispatch(updateWindowWidthAction(width)),
  hideModal: () => dispatch(hidePhoneVerificationModalAction()),
  requestPhoneVerificationToken: () =>
    dispatch(requestPhoneVerificationTokenAction.request()),
  setInitialPathname: pathname => dispatch(setInitialPathnameAction(pathname)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Root)
