import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { dateTimeFormatter } from '../utils/date'
import { Anchor } from './common/Anchor'
import { Table, Column, Spin } from './common/Ant'

class AssignmentHistory extends Component {
  componentDidMount() {
    const {
      resourceId,
      getAssignmentHistory,
      siteSlug,
      floorId,
      locationId,
      isByLocation,
      isBySite,
    } = this.props
    if (isByLocation) {
      getAssignmentHistory(siteSlug, floorId, locationId)
    } else if (isBySite) {
      getAssignmentHistory(siteSlug)
    } else {
      getAssignmentHistory(resourceId)
    }
  }

  render() {
    const {
      assignmentHistory,
      isLoading,
      isByLocation,
      isByGateway,
      isBySite,
    } = this.props

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : assignmentHistory.length ? (
      <Table
        dataSource={assignmentHistory}
        rowKey="id"
        scroll={{ x: 350 }}
        pagination={false}
        bordered
        tableLayout="auto"
      >
        {isBySite && (
          <Column
            title="Smart Pod"
            width={100}
            dataIndex="pillarId"
            render={(text, { pillarId }) =>
              text ? (
                <Anchor to={`/inventory/pods/${pillarId}`}>{pillarId}</Anchor>
              ) : (
                '--'
              )
            }
          />
        )}
        {isByLocation && (
          <Column
            title="Smart Pod"
            width={100}
            dataIndex="podPillarId"
            render={(text, { podPillarId }) =>
              text ? (
                <Anchor to={`/inventory/pods/${podPillarId}`}>
                  {podPillarId}
                </Anchor>
              ) : (
                '--'
              )
            }
          />
        )}
        {!isByLocation && (
          <Column
            title="Site"
            width={100}
            dataIndex="siteName"
            render={(text, record) =>
              record.siteSlug ? (
                <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
              ) : (
                text
              )
            }
          />
        )}
        {!isByLocation && !isByGateway && (
          <Column
            title="Location"
            width={100}
            dataIndex="locationName"
            render={(text, r) =>
              r.siteSlug ? (
                <Anchor
                  to={`/sites/${r.siteSlug}/floors/${r.floorId}/locations/${r.locationId}`}
                >
                  {text}
                </Anchor>
              ) : (
                text
              )
            }
          />
        )}
        <Column
          title="Date Assigned"
          width={100}
          dataIndex="assignedAt"
          render={(text, record) => (text ? dateTimeFormatter(text) : '--')}
        />
        <Column
          title="Assigned By"
          width={100}
          dataIndex="assignedBy"
          render={(text, record) => (text ? text : '--')}
        />
        <Column
          title="Date Unassigned"
          width={100}
          dataIndex="unassignedAt"
          render={(text, record) => (text ? dateTimeFormatter(text) : '--')}
        />
        <Column
          title="Unassigned By"
          width={100}
          dataIndex="unassignedBy"
          render={(text, record) => (text ? text : '--')}
        />
      </Table>
    ) : (
      'No Assignment History'
    )
  }
}

AssignmentHistory.propTypes = {
  assignmentHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAssignmentHistory: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isByLocation: PropTypes.bool,
  isByGateway: PropTypes.bool,
  isBySite: PropTypes.bool,
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  locationId: PropTypes.string,
  floorId: PropTypes.string,
  siteSlug: PropTypes.string,
}

export default AssignmentHistory
