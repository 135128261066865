import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Divider from './common/Divider'
import { ActionAnchor } from './common/Anchor'
import { dateFormatter, dateTimeFormatter } from '../utils/date'
import { createQueryString } from '../utils/queryParams'
import sortBy from 'sort-by'

class SiteFloorsCSVDownload extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
    }
  }

  static propTypes = {
    siteName: PropTypes.string.isRequired,
    siteSlug: PropTypes.string.isRequired,
    getAllLocationsBySite: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLocationLoading: PropTypes.bool.isRequired,
  }

  getLocations = () => {
    const { siteSlug, getAllLocationsBySite } = this.props

    this.setState({ isFetching: true })
    const qs = createQueryString({ csvDownload: true })
    getAllLocationsBySite(siteSlug, qs)
  }

  handleDownload = () => {
    const { siteName, locations } = this.props

    let csv =
      'Building,Floor,Location Name,POD ID,Description,Active Alert,Latest Reading DateTime,Signal Strength,Location Confirm (Y or N),Notes\n'

    locations
      .sort(sortBy('buildingName', 'floorName', 'name'))
      .forEach(location => {
        let lastRead = location['lastReadingTime']
          ? dateTimeFormatter(location['lastReadingTime'], true)
          : ''
        lastRead = lastRead.replace(',', '')
        csv += `${location['buildingName'] ? location['buildingName'] : ''},${
          location['floorName'] ? location['floorName'] : ''
        },${location['name'] ? location['name'] : ''},${
          location['podPillarId'] ? location['podPillarId'] : ''
        },${location['description'] ? location['description'] : ''},${
          location['alertActive']
            ? location['alertType']
              ? location['alertType']
              : ''
            : ''
        },${lastRead},${
          location['signalStrength']
            ? parseFloat(location['signalStrength']).toFixed(2)
            : ''
        }`
        csv += '\n'
      })

    let fileName = siteName.split(' ').join('-')
    fileName = fileName.split('---').join('-') + '--Floors--'
    fileName += dateFormatter(Date.now())
      .split('/')
      .join('-')

    const encodedCsv = global.encodeURI(csv)

    let hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodedCsv
    hiddenElement.target = '_blank'
    hiddenElement.download = `${fileName}.csv`
    hiddenElement.click()
    this.setState({ isFetching: false })
  }

  render() {
    const { isLocationLoading } = this.props
    const { isFetching } = this.state

    return (
      <section className="SiteFloorsCSVDownload">
        <Divider vertical />
        {isFetching && !isLocationLoading ? (
          this.handleDownload()
        ) : (
          <span>
            <ActionAnchor onClick={this.getLocations}>
              Download Floor Data
            </ActionAnchor>
          </span>
        )}
      </section>
    )
  }
}

export default SiteFloorsCSVDownload
