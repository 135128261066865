import produce from 'immer'
import { combineReducers } from 'redux'
import sortBy from 'sort-by'
import {
  getAllBuildingsAction,
  getBuildingAction,
  getAllBuildingThresholdUserMappingsAction,
} from '../actions/buildingsActions'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllBuildingsAction.SUCCESS:
        payload.forEach(building => {
          draft[building.id] = building
        })
        break
      case getBuildingAction.SUCCESS:
        draft[payload.id] = payload
        break
    }
  })

const visibleIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllBuildingsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.map(building => building.id))
        break
    }
  })

const current = (state = { thresholds: [] }, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getBuildingAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const thresholdUserMappings = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllBuildingThresholdUserMappingsAction.SUCCESS:
        const result = payload.users
          .map(user => {
            const thresholds = payload.userThresholdMapping
              .filter(x => x.user === user.slug)
              .map(x => {
                const index = payload.thresholds
                  .map(y => y.thresholdSlug)
                  .indexOf(x.threshold)

                const active =
                  x.settings.smsNotifications || x.settings.emailNotifications
                const mappingId = x.settings.id

                return { ...payload.thresholds[index], active, mappingId }
              })

            return Object.assign(user, { thresholds })
          })
          .sort(sortBy('role'))

        draft.splice(0, draft.length, ...result)
        break
    }
  })

const buildingsReducer = combineReducers({
  byId,
  visibleIds,
  current,
  thresholdUserMappings,
})

const createBuildingSort = x => ({
  ...x,
  buildingId: isNaN(parseInt(x.building, 10))
    ? x.building
    : parseInt(x.building, 10),
})

const getBuilding = (state, building) => state.byId[building]
const getCurrentBuilding = state => state.current
const getVisibleBuildings = state =>
  state.visibleIds
    .map(building => createBuildingSort(getBuilding(state, building)))
    .sort(sortBy('-buildingId'))
const getBuildingThresholdUserMappings = ({ thresholdUserMappings }) =>
  thresholdUserMappings

export {
  buildingsReducer as default,
  getCurrentBuilding,
  getVisibleBuildings,
  getBuildingThresholdUserMappings,
}
