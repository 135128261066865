import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import EditSupportPhoneNumberForm from '../components/forms/EditSupportPhoneNumberForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createSupportPhoneNumbersAction } from '../actions/communicationsActions'

const CreateSupportPhoneNumber = ({ users, getUsers }) => {
  useEffect(
    () => {
      getUsers()
    },
    [getUsers]
  )

  const crumbs = [
    <Anchor to="/communications">Communications</Anchor>,
    <Anchor to="/communications/phone-numbers">Support Phone Numbers</Anchor>,
    'Create Support Phone Number',
  ]

  return (
    <section className="CreateSupportPhoneNumber">
      <FormHeader crumbs={crumbs} />
      <EditSupportPhoneNumberForm
        users={users}
        submitAction={createSupportPhoneNumbersAction}
      />
    </section>
  )
}

CreateSupportPhoneNumber.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  getUsers: PropTypes.func.isRequired,
}

export default CreateSupportPhoneNumber
