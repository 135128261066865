import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Select from '../../components/common/Select'
import { getRequiredFields } from '../../reducers/formReducer'

const SelectContainer = ({ requiredFields, ...rest }) => (
  <Select requiredFields={requiredFields} {...rest} />
)

SelectContainer.propTypes = {
  requiredFields: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = ({ form }) => ({
  requiredFields: getRequiredFields(form),
})

export default connect(mapStateToProps)(SelectContainer)
