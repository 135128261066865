import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getInventoryProjectionsAction,
  getInventoryProposalAction,
} from '../actions/inventoryActions'

const proposal = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getInventoryProposalAction.SUCCESS:
        Object.assign(draft, payload)
    }
  })

const projections = (
  state = {
    availablePods: 0,
    monthlyProjections: [],
    proposedInventory: [],
    upcomingInstalls: [],
    upcomingUninstalls: [],
    meta: {
      pods: {
        countedStatuses: [],
        productVersions: [],
        firmwareVersions: [],
      },
      gateways: {
        countedStatuses: [],
      },
      flowMonitors: {
        countedStatuses: [],
      },
    },
  },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getInventoryProjectionsAction.SUCCESS:
        const monthlyProjections = payload.monthlyProjections.map(
          ({
            timestamp,
            balancePods,
            // TODO add
            // balanceLeakPods
            balanceGateways,
            balanceFlowMonitors,
            installDeployments,
            // uninstallDeployments,
          }) => {
            const [year, month] = timestamp.split('/')

            return {
              timestamp: new Date(year, parseInt(month) - 1, 15).valueOf(),
              balancePods,
              balanceGateways,
              balanceFlowMonitors,
              totalPods: installDeployments.pods,
              totalGateways: installDeployments.gateways,
              totalFlowMonitors: installDeployments.flowMonitors,
            }
          }
        )

        Object.assign(draft, payload, { monthlyProjections })
        break
    }
  })

const inventoryReducer = combineReducers({ projections, proposal })

const getInventoryProjections = ({ projections }) => projections
const getProposal = ({ proposal }) => proposal

export { inventoryReducer as default, getInventoryProjections, getProposal }
