import { fork, call, takeLatest, put, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import sortBy from 'sort-by'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllSupportPhoneNumbersAction,
  createSupportPhoneNumbersAction,
  getSupportPhoneNumberAction,
  updateSupportPhoneNumberAction,
  deleteSupportPhoneNumberAction,
  getAllConversationsAction,
  getConversationAction,
  createConversationAction,
  createBridgedPhoneCallAction,
  closeConversationAction,
} from '../actions/communicationsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { pickErrorMessage } from '../utils/helpers'
import { formatPhoneNumber } from '../utils/forms'
import { MESSAGES, DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'

// selectors

const getAccountProfileSlug = state => state.account.profile.slug

// handlers

function* handleGetAllSupportPhoneNumbersSaga({ payload }) {
  try {
    const result = yield call(api.getAllSupportPhoneNumbers, payload)
    yield put(getAllSupportPhoneNumbersAction.success(result))
  } catch (error) {
    yield put(getAllSupportPhoneNumbersAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateSupportPhoneNumbersSaga({ payload }) {
  try {
    const result = yield call(api.createSupportPhoneNumber, payload)

    yield put(createSupportPhoneNumbersAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push('/communications/phone-numbers'))
  } catch (error) {
    yield put(createSupportPhoneNumbersAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSupportPhoneNumberSaga({ payload }) {
  try {
    const result = yield call(api.getSupportPhoneNumberBySlug, payload)

    yield put(getSupportPhoneNumberAction.success(result))
  } catch (error) {
    yield put(getSupportPhoneNumberAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateSupportPhoneNumberSaga({ payload }) {
  try {
    const result = yield call(api.updateSupportPhoneNumber, payload)

    yield put(updateSupportPhoneNumberAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push('/communications/phone-numbers'))
  } catch (error) {
    yield put(updateSupportPhoneNumberAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteSupportPhoneNumberSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteSupportPhoneNumber, payload)

      const { page, perPage } = parseQueryParams(global.location)
      const query = {
        page: page ? parseInt(page, 10) : DEFAULT_PAGE,
        perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      }

      yield fork(handleGetAllSupportPhoneNumbersSaga, {
        payload: createQueryString(query),
      })
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteSupportPhoneNumberAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateConversationSaga({ payload }) {
  try {
    const { userSlug, message } = payload
    const accountProfileSlug = yield select(getAccountProfileSlug)

    const { messages, participants } = yield call(
      api.createConversation,
      [userSlug, accountProfileSlug],
      message
    )

    yield put(
      createConversationAction.success({
        messages: messages.sort(sortBy('createdAt')),
        participants,
        slug: userSlug,
      })
    )
    yield fork(handleGetAllConversationsSaga, {})
  } catch (error) {
    yield put(createConversationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllConversationsSaga({ payload }) {
  try {
    const { items, meta } = yield call(api.getAllConversations, payload)
    const transformed = items
      .map(x => {
        const sender = x.participants[0]
        const recipient = x.participants[1] ? x.participants[1] : {}

        return {
          ...x,
          from: sender.firstName
            ? `${sender.firstName} ${sender.lastName}`
            : formatPhoneNumber(sender.phoneNumber),
          to: recipient.firstName
            ? `${recipient.firstName} ${recipient.lastName}`
            : formatPhoneNumber(recipient.phoneNumber),
        }
      })
      .sort(sortBy('-lastMessageTimestamp'))
    yield put(
      getAllConversationsAction.success({
        items: transformed,
        meta,
      })
    )
  } catch (error) {
    yield put(getAllConversationsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetConversationSaga({ payload }) {
  try {
    const { slug, qs } = payload
    const { messages, participants } = yield call(
      api.getConversationBySlug,
      slug,
      qs
    )

    yield put(
      getConversationAction.success({
        messages: messages.sort(sortBy('createdAt')),
        slug,
        participants,
      })
    )
  } catch (error) {
    yield put(getConversationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateBridgedPhoneCallSaga({ payload }) {
  try {
    yield call(api.createBridgedPhoneCall, payload)
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_BRIDGED_PHONE_CALL)
    )
    yield put(createBridgedPhoneCallAction.success())
  } catch (error) {
    yield put(createBridgedPhoneCallAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCloseConversationSaga({ payload }) {
  try {
    const result = yield call(api.closeConversation, payload)

    const sender = result.participants[0]
    const recipient = result.participants[1] ? result.participants[1] : {}

    yield put(
      closeConversationAction.success({
        ...result,
        from: sender.firstName
          ? `${sender.firstName} ${sender.lastName}`
          : formatPhoneNumber(sender.phoneNumber),
        to: recipient.firstName
          ? `${recipient.firstName} ${recipient.lastName}`
          : formatPhoneNumber(recipient.phoneNumber),
      })
    )
  } catch (error) {
    yield put(closeConversationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllSupportPhoneNumbersSaga() {
  yield takeLatest(
    getAllSupportPhoneNumbersAction.REQUEST,
    handleGetAllSupportPhoneNumbersSaga
  )
}

function* watchCreateSupportPhoneNumbersSaga() {
  yield takeLatest(
    createSupportPhoneNumbersAction.REQUEST,
    handleCreateSupportPhoneNumbersSaga
  )
}

function* watchGetSupportPhoneNumberSaga() {
  yield takeLatest(
    getSupportPhoneNumberAction.REQUEST,
    handleGetSupportPhoneNumberSaga
  )
}

function* watchUpdateSupportPhoneNumberSaga() {
  yield takeLatest(
    updateSupportPhoneNumberAction.REQUEST,
    handleUpdateSupportPhoneNumberSaga
  )
}

function* watchDeleteSupportPhoneNumberSaga() {
  yield takeLatest(
    deleteSupportPhoneNumberAction.REQUEST,
    handleDeleteSupportPhoneNumberSaga
  )
}

function* watchCreateConversationSaga() {
  yield takeLatest(
    createConversationAction.REQUEST,
    handleCreateConversationSaga
  )
}

function* watchGetAllConversationsSaga() {
  yield takeLatest(
    getAllConversationsAction.REQUEST,
    handleGetAllConversationsSaga
  )
}

function* watchGetConversationSaga() {
  yield takeLatest(getConversationAction.REQUEST, handleGetConversationSaga)
}

function* watchCreateBridgedPhoneCallSaga() {
  yield takeLatest(
    createBridgedPhoneCallAction.REQUEST,
    handleCreateBridgedPhoneCallSaga
  )
}

function* watchCloseConversationSaga() {
  yield takeLatest(closeConversationAction.REQUEST, handleCloseConversationSaga)
}

function* communicationsSaga() {
  yield all([
    fork(watchGetAllSupportPhoneNumbersSaga),
    fork(watchCreateSupportPhoneNumbersSaga),
    fork(watchGetSupportPhoneNumberSaga),
    fork(watchUpdateSupportPhoneNumberSaga),
    fork(watchDeleteSupportPhoneNumberSaga),
    fork(watchCreateConversationSaga),
    fork(watchGetAllConversationsSaga),
    fork(watchGetConversationSaga),
    fork(watchCreateBridgedPhoneCallSaga),
    fork(watchCloseConversationSaga),
  ])
}

export { communicationsSaga as default }
