import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import Button from './common/Button'
// import { Link } from 'react-router-dom'
import CancelButton from './common/CancelButton'
import Label from './common/Label'
import api from '../services/api'
// import cx from 'classnames'


class EvacuateBuilding extends Component {
  static propTypes = {
    building: PropTypes.object.isRequired,
    getBuilding: PropTypes.func.isRequired,
    getSite: PropTypes.func.isRequired,
    siteName: PropTypes.string,
    match: PropTypes.object
  }

  componentDidMount() {
    const { match, siteName, getSite, getBuilding } = this.props
    const { id, siteSlug } = match.params

    getBuilding(siteSlug, id)

    if (!siteName) {
      getSite(match.params.siteSlug)
    }
  }

  render() {
    const { building, match, siteName } = this.props
    const { siteSlug, id } = match.params

    const handleEvacuate = () => {
        api.evacuateBuilding(id, true)
    }

    const crumbs = [
      <Anchor to="/sites">Sites</Anchor>,
      siteName && <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>,
      building && (
        <Anchor to={`/sites/${siteSlug}/buildings/${id}`}>
          {building.name}
        </Anchor>
      ),
      "Evacuate Building",
    ]

    return (
      <section className="EvacuateBuilding">
        <FormHeader crumbs={crumbs} />

        <div className='flex justify-center'>
            <Label>Are you sure you want to evacuate the building: {building.name}?</Label>
        </div>
        <div className="flex justify-center">
            <div style={{padding: 20}}>
                <CancelButton defaultLocation={`/sites/${siteSlug}/buildings/${id}`} />
            </div>
            <div style={{padding: 20}}>
                <Button text="Evacuate" variant="danger" onClick={handleEvacuate} to={`/sites/${siteSlug}/buildings/${id}`} />
            </div>
        </div>
      </section>
    )
  }
}

export default EvacuateBuilding
