import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Anchor } from './common/Anchor'
import Divider from './common/Divider'
import { Bread, Crumb } from './common/Bread'
import Block from './common/Block'
import { createQueryString } from '../utils/queryParams'

const AssetLocation = ({ getAssetLocation, assetLocation, match }) => {
  const { pillarId, description, pods, gateways, flowMonitors } = assetLocation

  const [qs, setQs] = useState('')

  useEffect(() => {
    getAssetLocation(match.params.pillarId)
  }, [getAssetLocation, match.params.pillarId])

  useEffect(() => {
    if (pods.length > 0) {
      setQs(
        createQueryString({
          pillarIds: pods.map(x => x.pillarId).join(','),
          columns: ['temp'],
        })
      )
    }
  }, [pods])

  return (
    <section className="AssetLocation">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>
              <Anchor to="/inventory/all">Inventory</Anchor>
            </Crumb>
            <Crumb>
              <Anchor to={`/inventory/all/asset-locations`}>
                Asset Locations
              </Anchor>
            </Crumb>
            <Crumb>{pillarId}</Crumb>
          </Bread>
        </div>
        <div className="flex justify-center mb3">
          <Anchor to={`${match.url}/edit`}>Edit</Anchor>
        </div>
      </div>
      <Divider />
      <div className="flex flex-wrap">
        <Block label="Pillar ID">{pillarId}</Block>
        <Block label="Description">{description}</Block>
        {pods && pods.length > 0 && (
          <Block label="Smart Pods">
            <ul className="pl4 mb3">
              {pods.map(({ pillarId }) => (
                <li key={pillarId}>
                  <Anchor to={`/inventory/pods/${pillarId}`}>{pillarId}</Anchor>
                </li>
              ))}
            </ul>
            <Divider />
            <Anchor to={`/inventory/pods/compare${qs}`}>
              Compare All Smart Pods
            </Anchor>
          </Block>
        )}
        <Block label="Network Gateways">
          <ul className="pl4 mb0">
            {gateways &&
              gateways.map(({ pillarId }) => (
                <li key={pillarId}>
                  <Anchor to={`/inventory/gateways/${pillarId}`}>
                    {pillarId}
                  </Anchor>
                </li>
              ))}
          </ul>
        </Block>
        <Block label="Water Monitors">
          <ul className="pl4 mb0">
            {flowMonitors &&
              flowMonitors.map(({ pillarId }) => (
                <li key={pillarId}>
                  <Anchor to={`/inventory/flow-monitors/${pillarId}/details`}>
                    {pillarId}
                  </Anchor>
                </li>
              ))}
          </ul>
        </Block>
      </div>
    </section>
  )
}

AssetLocation.propTypes = {
  assetLocation: PropTypes.object.isRequired,
  getAssetLocation: PropTypes.func.isRequired,
  match: PropTypes.object,
}

export default AssetLocation
