import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AuthForm from '../components/forms/AuthForm'
import { getUnconfirmedUsername } from '../reducers/authReducer'
import { isAccountLoading } from '../reducers/uiReducer'

const AuthFormContainer = ({ username, ...props }) => (
  <AuthForm initialValues={{ username }} enableReinitialize {...props} />
)

AuthFormContainer.propTypes = {
  username: PropTypes.string,
}

const mapStateToProps = ({ auth, ui }) => ({
  username: getUnconfirmedUsername(auth),
  isLoading: isAccountLoading(ui),
})

export default connect(mapStateToProps)(AuthFormContainer)
