import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from './common/Ant'
import { toTitleCase } from '../utils/textFormatters'
import { CHAT_MESSAGE_STATUS } from '../constants'

const MessageStatus = ({ messagesStatus }) => {
  if (messagesStatus.length > 0) {
    const { status } = messagesStatus[0]

    let icon

    switch (status) {
      case CHAT_MESSAGE_STATUS.SENT:
      case CHAT_MESSAGE_STATUS.PROCESSED:
        icon = <Icon type="sync" spin className="blue" />
        break
      case CHAT_MESSAGE_STATUS.FAILED:
      case CHAT_MESSAGE_STATUS.UNDELIVERED:
      case CHAT_MESSAGE_STATUS.DROPPED:
      case CHAT_MESSAGE_STATUS.DEFERRED:
      case CHAT_MESSAGE_STATUS.BOUNCE:
      case CHAT_MESSAGE_STATUS.BLOCKED:
      case CHAT_MESSAGE_STATUS.SPAM_REPORT:
      case CHAT_MESSAGE_STATUS.UNSUBSCRIBE:
        icon = <Icon type="warning" className="red" />
        break
      case CHAT_MESSAGE_STATUS.DELIVERED:
      case CHAT_MESSAGE_STATUS.OPEN:
      case CHAT_MESSAGE_STATUS.CLICK:
        icon = <Icon className="green" type="check-circle" />
        break
    }

    return (
      <div className="MessageStatus ml1" title={toTitleCase(status)}>
        {icon}
      </div>
    )
  }

  return null
}

MessageStatus.propTypes = {
  messagesStatus: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default MessageStatus
