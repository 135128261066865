import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { H2, H3 } from './common/Headers'
import Block from './common/Block'
import Divider from './common/Divider'
import { Table, Column } from './common/Ant'
import { Anchor } from './common/Anchor'
import { dateTimeFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'

class OTADetail extends Component {
  static propTypes = {
    currentOtaLog: PropTypes.object.isRequired,
    getOTALog: PropTypes.func.isRequired,
    otaFormValues: PropTypes.object,
    match: PropTypes.object,
  }

  componentDidMount() {
    this.props.getOTALog(this.props.match.params.slug)
  }

  render() {
    const {
      slug,
      startTime,
      endTime,
      firmwareVersion,
      successful,
      active,
      requestedPodDetails,
      updatedPodDetails,
      userFirstName,
      userLastName,
    } = this.props.currentOtaLog

    return (
      <div>
        <H2>OTA Detail</H2>
        <div className="flex-ns flex-wrap">
          <Block label="Slug">{slug}</Block>
          <Block label="Status">
            {active ? 'Active' : successful ? 'Successful' : 'Failed'}
          </Block>
          <Block label="Start Time">{dateTimeFormatter(startTime)}</Block>
          <Block label="End Time">{dateTimeFormatter(endTime)}</Block>
          <Block label="Firmware Version">{firmwareVersion}</Block>
          <Block label="Triggered by User">
            {`${userFirstName} ${userLastName}`}
          </Block>
        </div>
        <Divider />
        <div>
          <H3>Smart Pods Updated</H3>
          <Table
            dataSource={updatedPodDetails}
            rowKey="slug"
            pagination={false}
            size="small"
            tableLayout="auto"
          >
            <Column
              title="Pillar ID"
              width={100}
              dataIndex="pillarId"
              render={(text, record) => (
                <Anchor
                  to={`/inventory/pods/${record.pillarId}`}
                  className="pl2"
                >
                  {text}
                </Anchor>
              )}
            />
            <Column
              title="Status"
              width={100}
              dataIndex="status"
              render={(text, record) => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Description"
              width={100}
              dataIndex="description"
              render={(text, record) => (text ? text : '--')}
            />
            <Column
              title="Module ID"
              dataIndex="moduleId"
              width={100}
              render={(text, record) => (
                <Anchor
                  to={`https://conductor.link-labs.com/symphony/${record.moduleId}`}
                >
                  {text}
                </Anchor>
              )}
            />
            <Column
              title="Firmware Version"
              dataIndex="firmwareVersion"
              width={100}
              render={(text, record) => (text ? text : '--')}
            />
            <Column
              title="Product Version"
              dataIndex="productVersion"
              width={100}
              render={(text, record) => (text ? text : '--')}
            />
          </Table>
        </div>
        <div>
          <H3>Smart Pods Requested</H3>
          <Table
            dataSource={requestedPodDetails}
            rowKey="slug"
            pagination={false}
            size="small"
            tableLayout="auto"
          >
            <Column
              title="Pillar ID"
              width={100}
              dataIndex="pillarId"
              render={(text, record) => (
                <Anchor
                  to={`/inventory/pods/${record.pillarId}`}
                  className="pl2"
                >
                  {text}
                </Anchor>
              )}
            />
            <Column
              title="Status"
              width={100}
              dataIndex="status"
              render={(text, record) => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Description"
              width={100}
              dataIndex="description"
              render={(text, record) => (text ? text : '--')}
            />
            <Column
              title="Module ID"
              dataIndex="moduleId"
              width={100}
              render={(text, record) => (
                <Anchor
                  to={`https://conductor.link-labs.com/symphony/${record.moduleId}`}
                >
                  {text}
                </Anchor>
              )}
            />
            <Column
              title="Firmware Version"
              dataIndex="firmwareVersion"
              width={100}
              render={(text, record) => (text ? text : '--')}
            />
            <Column
              title="Product Version"
              dataIndex="productVersion"
              width={100}
              render={(text, record) => (text ? text : '--')}
            />
          </Table>
        </div>
      </div>
    )
  }
}

export default OTADetail
