import { connect } from 'react-redux'
import AccountPage from '../components/pages/AccountPage'
import { toggleAccountOptionAction } from '../actions/accountActions'
import { getAccountProfile } from '../reducers/accountReducer'

const mapStateToProps = ({ account }) => ({
  profile: getAccountProfile(account),
})

const mapDispatchToProps = dispatch => ({
  toggleAccountOption: profile =>
    dispatch(toggleAccountOptionAction.request(profile)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage)
