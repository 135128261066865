import { connect } from 'react-redux'
import TwoFactorAuthVerificationForm from '../components/forms/TwoFactorAuthVerificationForm'
import { resendTwoFactorAuthTokenAction } from '../actions/authActions'

const mapDispatchToProps = dispatch => ({
  resendTwoFactorAuthToken: () =>
    dispatch(resendTwoFactorAuthTokenAction.request()),
})

export default connect(null, mapDispatchToProps)(TwoFactorAuthVerificationForm)
