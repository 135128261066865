import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import AllAssetLocations from './AllAssetLocations'
import AllPods from './AllPods'
import AllGateways from './AllGateways'
import AllSIMCards from './AllSIMCards'
import AllGatewaySupplies from './AllGatewaySupplies'
import AllFlowMonitors from './AllFlowMonitors'
import { Bread, Crumb } from './common/Bread'
import { Tabs, TabPane } from './common/Ant'
import { Anchor } from './common/Anchor'
import { getPathSnippets } from '../utils/helpers'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'
import { flagEnabled } from '../utils/config'
import AllWaterMeters from './AllWaterMeters'
import AllWaterValves from './AllWaterValves'

const isFlowMonitorEnabled = flagEnabled('REACT_APP_ENABLE_FLOW_MONITOR')

class AllInventory extends Component {
  static propTypes = {
    getAllAssetLocations: PropTypes.func.isRequired,
    deleteAssetLocation: PropTypes.func.isRequired,
    getAllGateways: PropTypes.func.isRequired,
    deleteGateway: PropTypes.func.isRequired,
    getAllPods: PropTypes.func.isRequired,
    deletePod: PropTypes.func.isRequired,
    getPodMetadata: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    isSnapshotLoading: PropTypes.bool.isRequired,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    pods: PropTypes.arrayOf(PropTypes.object).isRequired,
    gateways: PropTypes.arrayOf(PropTypes.object).isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    podMetadata: PropTypes.object.isRequired,
    gatewaysMeta: PropTypes.object.isRequired,
    podsMeta: PropTypes.object.isRequired,
    assetLocationsMeta: PropTypes.object.isRequired,
    simCards: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllSIMCards: PropTypes.func.isRequired,
    deleteSIMCard: PropTypes.func.isRequired,
    simCardsMeta: PropTypes.object.isRequired,
    parts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllParts: PropTypes.func.isRequired,
    deletePart: PropTypes.func.isRequired,
    partsMeta: PropTypes.object.isRequired,
    purchases: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllPurchases: PropTypes.func.isRequired,
    deletePurchase: PropTypes.func.isRequired,
    purchasesMeta: PropTypes.object.isRequired,
    flowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllFlowMonitors: PropTypes.func.isRequired,
    deleteFlowMonitor: PropTypes.func.isRequired,
    waterMeters: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllWaterMeters: PropTypes.func.isRequired,
    deleteWaterMeter: PropTypes.func.isRequired,
    waterValves: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllWaterValves: PropTypes.func.isRequired,
    deleteWaterValve: PropTypes.func.isRequired,
    flowMonitorsMeta: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    isGatewayLoading: PropTypes.bool.isRequired,
    isSIMCardLoading: PropTypes.bool.isRequired,
    isPartLoading: PropTypes.bool.isRequired,
    isPurchaseLoading: PropTypes.bool.isRequired,
    isFlowMonitorLoading: PropTypes.bool.isRequired,
    isPodLoading: PropTypes.bool.isRequired,
    isAssetLocationLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    batchUpdatePods: PropTypes.func.isRequired,
    uploadPodsCSV: PropTypes.func.isRequired,
    uploadCalibrationCSV: PropTypes.func.isRequired,
    csvUploadResults: PropTypes.object.isRequired,
    getAllGatewaySupplies: PropTypes.func.isRequired,
    gatewaySupplies: PropTypes.arrayOf(PropTypes.object).isRequired,
    deleteGatewaySupply: PropTypes.func.isRequired,
    isGatewaySupplyLoading: PropTypes.bool.isRequired,
  }

  state = {
    activeTab: undefined,
  }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.handleTabSelection(lastSnippet)
  }

  handleTabSelection = entity => {
    const {
      updateQueryParams,
      match: { url },
    } = this.props
    const { activeTab } = this.state

    this.setState({ activeTab: entity })
    updateQueryParams({
      pathname: `${url}/${entity}`,
      search: !activeTab || entity === activeTab ? global.location.search : '',
    })
  }

  // TODO add is*Loading props for PCs & ALs
  render() {
    const {
      getAllAssetLocations,
      deleteAssetLocation,
      getAllGateways,
      deleteGateway,
      getAllPods,
      deletePod,
      getPodMetadata,
      getAllSites,
      isSnapshotLoading,
      snapshotData,
      getLocationSnapshot,
      pods,
      gateways,
      sites,
      assetLocations,
      podMetadata,
      gatewaysMeta,
      podsMeta,
      assetLocationsMeta,
      getAllSIMCards,
      deleteSIMCard,
      simCards,
      simCardsMeta,
      flowMonitors,
      getAllFlowMonitors,
      deleteFlowMonitor,
      waterMeters,
      getAllWaterMeters,
      deleteWaterMeter,
      waterValves,
      getAllWaterValves,
      deleteWaterValve,
      flowMonitorsMeta,
      windowWidth,
      match: { url },
      isGatewayLoading,
      isSIMCardLoading,
      isFlowMonitorLoading,
      isPodLoading,
      isAssetLocationLoading,
      updateQueryParams,
      batchUpdatePods,
      csvUploadResults,
      uploadPodsCSV,
      uploadCalibrationCSV,
      getAllGatewaySupplies,
      gatewaySupplies,
      deleteGatewaySupply,
      isGatewaySupplyLoading,
    } = this.props

    return (
      <div className="AllInventory">
        <Helmet>
          <title>Inventory</title>
        </Helmet>
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>Inventory</Crumb>
            </Bread>
          </div>
          {hasPermission(USER_PERMISSIONS.VIEW_INVENTORY_PROJECTIONS) && (
            <div className="flex flex-wrap justify-center mb3">
              <Anchor to={'/inventory/forecasts'}>Forecasts</Anchor>
            </div>
          )}
        </div>
        <Switch>
          <Route
            path={`${url}/`}
            exact
            render={props => <Redirect to={`${url}/pods`} />}
          />
          <Tabs
            type="card"
            defaultActiveKey="pods"
            activeKey={this.state.activeTab}
            onTabClick={this.handleTabSelection}
          >
            {hasPermission(USER_PERMISSIONS.VIEW_POD) && (
              <TabPane tab="Smart Pods" key="pods">
                <Route
                  path={`${url}/pods`}
                  exact
                  render={props => (
                    <AllPods
                      isSnapshotLoading={isSnapshotLoading}
                      snapshotData={snapshotData}
                      getLocationSnapshot={getLocationSnapshot}
                      getAllPods={getAllPods}
                      getPodMetadata={getPodMetadata}
                      getAllSites={getAllSites}
                      getAllAssetLocations={getAllAssetLocations}
                      uploadPodsCSV={uploadPodsCSV}
                      uploadCalibrationCSV={uploadCalibrationCSV}
                      csvUploadResults={csvUploadResults}
                      batchUpdatePods={batchUpdatePods}
                      deletePod={deletePod}
                      pods={pods}
                      assetLocations={assetLocations}
                      sites={sites}
                      podMetadata={podMetadata}
                      meta={podsMeta}
                      updateQueryParams={updateQueryParams}
                      windowWidth={windowWidth}
                      isPodLoading={isPodLoading}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.VIEW_GATEWAY) && (
              <TabPane tab="Network Gateways" key="gateways">
                <Route
                  path={`${url}/gateways`}
                  exact
                  render={props => (
                    <AllGateways
                      getAllGateways={getAllGateways}
                      deleteGateway={deleteGateway}
                      gateways={gateways}
                      meta={gatewaysMeta}
                      windowWidth={windowWidth}
                      isGatewayLoading={isGatewayLoading}
                      updateQueryParams={updateQueryParams}
                      sites={sites}
                      getAllSites={getAllSites}
                    />
                  )}
                />
              </TabPane>
            )}
            {isFlowMonitorEnabled &&
              hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
                <TabPane tab="Water Monitors" key="flow-monitors">
                  <Route
                    path={`${url}/flow-monitors`}
                    exact
                    render={props => (
                      <AllFlowMonitors
                        isSnapshotLoading={isSnapshotLoading}
                        snapshotData={snapshotData}
                        getLocationSnapshot={getLocationSnapshot}
                        getAllFlowMonitors={getAllFlowMonitors}
                        deleteFlowMonitor={deleteFlowMonitor}
                        isFlowMonitorLoading={isFlowMonitorLoading}
                        flowMonitors={flowMonitors}
                        meta={flowMonitorsMeta}
                        getAllSites={getAllSites}
                        getAllAssetLocations={getAllAssetLocations}
                        assetLocations={assetLocations}
                        sites={sites}
                        updateQueryParams={updateQueryParams}
                        windowWidth={windowWidth}
                      />
                    )}
                  />
                </TabPane>
              )}
            {isFlowMonitorEnabled &&
              hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
                <TabPane tab="Water Meters" key="water-meters">
                  <Route
                    path={`${url}/water-meters`}
                    exact
                    render={props => (
                      <AllWaterMeters
                        getAllWaterMeters={getAllWaterMeters}
                        deleteWaterMeter={deleteWaterMeter}
                        isWaterMeterLoading={isFlowMonitorLoading}
                        waterMeters={waterMeters}
                        meta={flowMonitorsMeta}
                        updateQueryParams={updateQueryParams}
                        windowWidth={windowWidth}
                      />
                    )}
                  />
                </TabPane>
              )}
            {isFlowMonitorEnabled &&
              hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
                <TabPane tab="Water Valves" key="water-valves">
                  <Route
                    path={`${url}/water-valves`}
                    exact
                    render={props => (
                      <AllWaterValves
                        getAllWaterValves={getAllWaterValves}
                        deleteWaterValve={deleteWaterValve}
                        isWaterValveLoading={isFlowMonitorLoading}
                        waterValves={waterValves}
                        meta={flowMonitorsMeta}
                        updateQueryParams={updateQueryParams}
                        windowWidth={windowWidth}
                      />
                    )}
                  />
                </TabPane>
              )}
            {hasPermission(USER_PERMISSIONS.VIEW_ASSET_LOCATION) && (
              <TabPane tab="Asset Locations" key="asset-locations">
                <Route
                  path={`${url}/asset-locations`}
                  exact
                  render={props => (
                    <AllAssetLocations
                      getAllAssetLocations={getAllAssetLocations}
                      deleteAssetLocation={deleteAssetLocation}
                      assetLocations={assetLocations}
                      meta={assetLocationsMeta}
                      updateQueryParams={updateQueryParams}
                      windowWidth={windowWidth}
                      isAssetLocationLoading={isAssetLocationLoading}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.VIEW_SIM_CARD) && (
              <TabPane tab="SIM Cards" key="sim-cards">
                <Route
                  path={`${url}/sim-cards`}
                  exact
                  render={props => (
                    <AllSIMCards
                      getAllSIMCards={getAllSIMCards}
                      deleteSIMCard={deleteSIMCard}
                      isSIMCardLoading={isSIMCardLoading}
                      simCards={simCards}
                      meta={simCardsMeta}
                      updateQueryParams={updateQueryParams}
                      windowWidth={windowWidth}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.VIEW_GATEWAY_SUPPLY) && (
              <TabPane tab="Network Gateway Supplies" key="gateway-supplies">
                <Route
                  path={`${url}/gateway-supplies`}
                  exact
                  render={props => (
                    <AllGatewaySupplies
                      getAllGatewaySupplies={getAllGatewaySupplies}
                      gatewaySupplies={gatewaySupplies}
                      isGatewaySupplyLoading={isGatewaySupplyLoading}
                      deleteGatewaySupply={deleteGatewaySupply}
                      updateQueryParams={updateQueryParams}
                      windowWidth={windowWidth}
                    />
                  )}
                />
              </TabPane>
            )}
          </Tabs>
        </Switch>
      </div>
    )
  }
}

export default AllInventory
