import {
  fork,
  call,
  take,
  takeEvery,
  takeLatest,
  put,
  all,
  select,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllDeploymentsAction,
  getAllDeploymentsBySiteAction,
  getAllShipmentsAction,
  getDeploymentAction,
  createDeploymentAction,
  updateDeploymentAction,
  updateDeploymentEstimatesAction,
  deleteDeploymentAction,
  getShipmentAction,
  updateShipmentAction,
  regeneratePackingListAction,
  getDeploymentEditHistoryAction,
} from '../actions/deploymentsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
  showErrorMessageWithDurationAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { getPathSnippetAtIndex, pickErrorMessage } from '../utils/helpers'

// selectors

const getSiteSlug = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 1)
const getDeploymentSlug = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 3)

// handlers

function* handleGetAllDeploymentsSaga({ payload }) {
  try {
    const [{ items, meta }, counts] = yield all([
      call(api.getAllDeployments, payload),
      call(api.getDeploymentCounts),
    ])

    yield put(getAllDeploymentsAction.success({ items, meta, counts }))
  } catch (error) {
    yield put(getAllDeploymentsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllDeploymentsBySiteSaga(siteSlug) {
  try {
    const payload = yield call(api.getAllDeploymentsBySite, siteSlug)
    yield put(getAllDeploymentsBySiteAction.success(payload))
  } catch (error) {
    yield put(getAllDeploymentsBySiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetDeploymentBySlugSaga(deploymentInfo) {
  try {
    const payload = yield call(
      api.getDeployment,
      deploymentInfo.siteSlug,
      deploymentInfo.deploymentSlug
    )
    yield put(getDeploymentAction.success(payload))
  } catch (error) {
    yield put(getDeploymentAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateDeploymentSaga(deployment) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const result = yield call(api.createDeployment, siteSlug, deployment)
    yield put(createDeploymentAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/sites/${siteSlug}/deployments`))
  } catch (error) {
    yield put(createDeploymentAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateDeploymentSaga(body) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const deploymentSlug = yield select(getDeploymentSlug)
    const result = yield call(
      api.updateDeployment,
      siteSlug,
      deploymentSlug,
      body
    )
    yield put(updateDeploymentAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/sites/${siteSlug}/deployments/${deploymentSlug}`))
    yield fork(handleGetDeploymentBySlugSaga, { siteSlug, deploymentSlug })
  } catch (error) {
    yield put(updateDeploymentAction.failure(error))
    yield put(showErrorMessageWithDurationAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteDeploymentSaga({ siteSlug, deploymentSlug }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteDeployment, siteSlug, deploymentSlug)
      yield fork(handleGetAllDeploymentsBySiteSaga, siteSlug)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteDeploymentAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleRegeneratePackingListSaga({ payload }) {
  try {
    const confirmRegenerate = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmRegenerate) {
      const result = yield call(
        api.regeneratePackingList,
        payload.siteSlug,
        payload.deploymentSlug
      )
      yield put(regeneratePackingListAction.success(result))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
      yield fork(handleGetDeploymentBySlugSaga, payload)
    }
  } catch (error) {
    yield put(regeneratePackingListAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateDeploymentEstimatesSaga({ payload }) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const deploymentSlug = yield select(getDeploymentSlug)
    const result = yield call(
      api.updateDeploymentEstimates,
      siteSlug,
      deploymentSlug,
      payload
    )
    yield put(updateDeploymentEstimatesAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/sites/${siteSlug}/deployments/${result.slug}`))
    yield fork(handleGetDeploymentBySlugSaga, {
      siteSlug,
      deploymentSlug: result.slug,
    })
  } catch (error) {
    yield put(updateDeploymentAction.failure(error))
    yield put(showErrorMessageWithDurationAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetDeploymentEditHistorySaga({ payload }) {
  try {
    const { siteSlug, deploymentSlug } = payload
    const result = yield call(
      api.getDeploymentEditHistory,
      siteSlug,
      deploymentSlug
    )
    yield put(getDeploymentEditHistoryAction.success(result))
  } catch (error) {
    yield put(getDeploymentEditHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// shipments

function* handleGetAllShipmentsSaga({ siteSlug, qs }) {
  try {
    const result = yield call(api.getAllShipments, siteSlug, qs)
    yield put(getAllShipmentsAction.success(result))
  } catch (error) {
    yield put(getAllShipmentsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetShipmentSaga(shipmentId) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const deploymentSlug = yield select(getDeploymentSlug)
    const result = yield call(
      api.getShipment,
      siteSlug,
      deploymentSlug,
      shipmentId
    )
    yield put(getShipmentAction.success(result))
  } catch (error) {
    yield put(getShipmentAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateShipmentSaga(shipment) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const deploymentSlug = yield select(getDeploymentSlug)
    const result = yield call(
      api.updateShipment,
      siteSlug,
      deploymentSlug,
      shipment
    )
    yield put(updateShipmentAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/sites/${siteSlug}/deployments/${deploymentSlug}`))
  } catch (error) {
    yield put(updateShipmentAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllDeploymentsSaga() {
  yield takeEvery(getAllDeploymentsAction.REQUEST, handleGetAllDeploymentsSaga)
}

function* watchRegeneratePackingListSaga() {
  yield takeEvery(
    regeneratePackingListAction.REQUEST,
    handleRegeneratePackingListSaga
  )
}

function* watchGetAllDeploymentsBySiteSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllDeploymentsBySiteAction.REQUEST)
    yield fork(handleGetAllDeploymentsBySiteSaga, qs)
  }
}

function* watchGetDeploymentSaga() {
  while (true) {
    const { payload: qs } = yield take(getDeploymentAction.REQUEST)
    yield fork(handleGetDeploymentBySlugSaga, qs)
  }
}

function* watchCreateDeploymentSaga() {
  while (true) {
    const { payload } = yield take(createDeploymentAction.REQUEST)
    yield fork(handleCreateDeploymentSaga, payload)
  }
}

function* watchUpdateDeploymentSaga() {
  while (true) {
    const { payload } = yield take(updateDeploymentAction.REQUEST)
    yield fork(handleUpdateDeploymentSaga, payload)
  }
}

function* watchDeleteDeploymentSaga() {
  while (true) {
    const { payload } = yield take(deleteDeploymentAction.REQUEST)
    yield fork(handleDeleteDeploymentSaga, payload)
  }
}

function* watchGetDeploymentEditHistorySaga() {
  yield takeLatest(
    getDeploymentEditHistoryAction.REQUEST,
    handleGetDeploymentEditHistorySaga
  )
}

function* watchUpdateDeploymentEstimatesSaga() {
  yield takeLatest(
    updateDeploymentEstimatesAction.REQUEST,
    handleUpdateDeploymentEstimatesSaga
  )
}

// shipment watchers

function* watchUpdateShipmentSaga() {
  while (true) {
    const { payload } = yield take(updateShipmentAction.REQUEST)
    yield fork(handleUpdateShipmentSaga, payload)
  }
}

function* watchGetShipmentSaga() {
  while (true) {
    const { payload } = yield take(getShipmentAction.REQUEST)
    yield fork(handleGetShipmentSaga, payload)
  }
}

function* watchGetAllShipmentsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllShipmentsAction.REQUEST)
    yield fork(handleGetAllShipmentsSaga, qs)
  }
}

function* deploymentsSaga() {
  yield all([
    fork(watchGetAllDeploymentsSaga),
    fork(watchGetAllDeploymentsBySiteSaga),
    fork(watchGetAllShipmentsSaga),
    fork(watchGetDeploymentSaga),
    fork(watchCreateDeploymentSaga),
    fork(watchUpdateDeploymentSaga),
    fork(watchDeleteDeploymentSaga),
    fork(watchUpdateShipmentSaga),
    fork(watchGetShipmentSaga),
    fork(watchRegeneratePackingListSaga),
    fork(watchUpdateDeploymentEstimatesSaga),
    fork(watchGetDeploymentEditHistorySaga),
  ])
}

export {
  deploymentsSaga as default,
  watchGetAllDeploymentsSaga,
  watchGetAllDeploymentsBySiteSaga,
  watchGetDeploymentSaga,
  watchCreateDeploymentSaga,
  watchUpdateDeploymentSaga,
  watchDeleteDeploymentSaga,
  watchUpdateShipmentSaga,
  watchGetShipmentSaga,
  watchGetAllShipmentsSaga,
  watchRegeneratePackingListSaga,
  watchUpdateDeploymentEstimatesSaga,
  watchGetDeploymentEditHistorySaga,
  handleRegeneratePackingListSaga,
  handleGetShipmentSaga,
  handleUpdateShipmentSaga,
  handleDeleteDeploymentSaga,
  handleUpdateDeploymentSaga,
  handleCreateDeploymentSaga,
  handleGetAllShipmentsSaga,
  handleGetAllDeploymentsBySiteSaga,
  handleGetDeploymentBySlugSaga,
  getSiteSlug,
  getDeploymentSlug,
}
