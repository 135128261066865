import React from 'react'
import PropTypes from 'prop-types'
import { Table, Column } from './common/Ant'
import { Anchor } from './common/Anchor'
import { dateTimeFormatter } from '../utils/date'

const AllPodRefurbResponses = ({ workflowResponses, podPillarId }) => (
  <div className="AllPodRefurbResponses">
    <Table
      dataSource={workflowResponses}
      rowKey="slug"
      bordered
      pagination={false}
      tableLayout="auto"
    >
      <Column
        title="Completed At"
        dataIndex="addedAt"
        width={150}
        render={(text, record) => (
          <Anchor
            to={`/inventory/pods/${podPillarId}/refurb-responses/${record.slug}`}
          >
            {dateTimeFormatter(text)}
          </Anchor>
        )}
      />
      <Column title="Completed By" dataIndex="addedBy" width={200} />
    </Table>
  </div>
)

AllPodRefurbResponses.propTypes = {
  workflowResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
  podPillarId: PropTypes.string.isRequired,
}

export default AllPodRefurbResponses
