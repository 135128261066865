import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { H3 } from './common/Headers'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import { getNetworkAction } from '../actions/networksActions'
import { getCurrentNetwork } from '../reducers/networksReducer'
import { SIZES } from '../constants'

const mapStateToProps = ({ networks }) => ({
  network: getCurrentNetwork(networks),
})

const mapDispatchToProps = dispatch => ({
  getNetwork: payload => dispatch(getNetworkAction.request(payload)),
})

const Network = ({
  getNetwork,
  slug,
  name,
  authorityName,
  timingMode,
  grantKeys,
  network,
  match: { params },
}) => {
  useEffect(() => {
    const maybeSlug = params.slug || slug
    if (maybeSlug) {
      getNetwork(maybeSlug)
    }
  }, [getNetwork, slug, params.slug])

  return (
    <div className="Network">
      <H3>Network Details</H3>
      <div className="mb3">
        <div className="mb1">
          <span className="b">Network:</span> {name || network.name}
        </div>
        <div className="mb1">
          <span className="b">Authority:</span>{' '}
          {authorityName || network.authorityName}
        </div>
        <div className="mb1">
          <span className="b">Site:</span>{' '}
          {network.siteSlug ? (
            <Anchor to={`/sites/${network.siteSlug}`}>
              {network.siteName}
            </Anchor>
          ) : (
            '--'
          )}
        </div>
        <div className="mb1">
          <span className="b">Timing Mode:</span>{' '}
          {timingMode || network.timingMode}
        </div>
        <div className="mb1">
          <span className="b">Grant Keys:</span>{' '}
          {grantKeys || network.grantKeys ? 'Yes' : 'No'}
        </div>
      </div>
      <div className="flex flex-wrap">
        <Block label="Smart Pods" size={SIZES.LARGE}>
          <ul className="pl4 mb0">
            {network.pods.map(({ pillarId }) => (
              <li key={pillarId}>
                <Anchor to={`/inventory/pods/${pillarId}`}>{pillarId}</Anchor>
              </li>
            ))}
          </ul>
        </Block>
        <Block label="Network Gateways" size={SIZES.LARGE}>
          <ul className="pl4 mb0">
            {network.gateways.map(({ pillarId }) => (
              <li key={pillarId}>
                <Anchor to={`/inventory/gateways/${pillarId}`}>
                  {pillarId}
                </Anchor>
              </li>
            ))}
          </ul>
        </Block>
      </div>
    </div>
  )
}

Network.propTypes = {
  getNetwork: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  authorityName: PropTypes.string.isRequired,
  timingMode: PropTypes.number.isRequired,
  grantKeys: PropTypes.bool.isRequired,
  network: PropTypes.shape({
    pods: PropTypes.arrayOf(PropTypes.object).isRequired,
    gateways: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string.isRequired,
    authorityName: PropTypes.string.isRequired,
    timingMode: PropTypes.number.isRequired,
    grantKeys: PropTypes.bool.isRequired,
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Network)
