import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column } from './common/Ant'
import { H4 } from './common/Headers'
import { Anchor } from './common/Anchor'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'

class SiteThresholdMappings extends Component {
  static propTypes = {
    getMappings: PropTypes.func.isRequired,
    mappings: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  componentDidMount() {
    this.props.getMappings()
  }

  state = {
    sortedInfo: {},
  }

  handleSortChange = (pagination, filters, sorter) =>
    this.setState({ sortedInfo: sorter })

  render() {
    const { mappings } = this.props
    const { sortedInfo } = this.state

    return mappings.length ? (
      <Table
        dataSource={mappings}
        onChange={this.handleSortChange}
        rowKey="id"
        pagination={false}
        scroll={{ x: 650 }}
        tableLayout="auto"
        bordered
      >
        <Column
          title="Threshold"
          dataIndex="name"
          sorter={sortBy('name')}
          sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
          render={(text, record) =>
            hasPermission(USER_PERMISSIONS.EDIT_THRESHOLD) ? (
              <Anchor to={`/rules/thresholds/${record.id}`}>{text}</Anchor>
            ) : (
              text
            )
          }
        />
        <Column
          title="Site"
          dataIndex="site"
          render={(text, record) => (record.site.length ? 'Active' : '--')}
        />
        <Column
          title="Buildings"
          dataIndex="building"
          render={(text, record) =>
            record.building.length ? (
              <div className="flex flex-wrap">
                {record.building.map((x, i) => (
                  <div key={x.id}>
                    <Anchor
                      key={x.id}
                      to={`/sites/${x.siteSlug}/buildings/${x.id}`}
                    >
                      {x.name}
                    </Anchor>
                    <span className="mr1">
                      {record.building.length - 1 > i && ','}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              '--'
            )
          }
        />
        <Column
          title="Floors"
          dataIndex="floor"
          render={(text, record) =>
            record.floor.length ? (
              <div className="flex flex-wrap">
                {record.floor.map((x, i) => (
                  <div key={x.id}>
                    <Anchor to={`/sites/${x.siteSlug}/floors/${x.id}`}>
                      {x.name}
                    </Anchor>
                    <span className="mr1">
                      {record.floor.length - 1 > i && ', '}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              '--'
            )
          }
        />
        <Column
          title="Rooms"
          dataIndex="locationGroup"
          render={(text, record) =>
            record.locationGroup.length ? (
              <div className="flex flex-wrap">
                {record.locationGroup.map((x, i) => (
                  <div key={x.id}>
                    {x.name}
                    <span className="mr1">
                      {record.locationGroup.length - 1 > i && ', '}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              '--'
            )
          }
        />
        <Column
          title="Locations"
          dataIndex="location"
          render={(text, record) =>
            record.location.length ? (
              <div className="flex flex-wrap">
                {record.location.map((x, i) => (
                  <div key={x.id}>
                    <Anchor
                      to={`/sites/${x.siteSlug}/floors/${x.floorId}/locations/${x.id}`}
                    >
                      {x.name}
                    </Anchor>
                    <span className="mr1">
                      {record.location.length - 1 > i && ', '}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              '--'
            )
          }
        />
        }
      </Table>
    ) : (
      <H4 className="tc">No Thresholds Used on Site</H4>
    )
  }
}

export default SiteThresholdMappings
