import { LOCATION_CHANGE } from 'connected-react-router'

const routerLocationHistoryReducer = (state = [], { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE:
      return [...state, payload]
    default:
      return state
  }
}

const getPreviousRouterLocation = state =>
  state.length - 2 > 0 ? state[state.length - 2] : null

export { routerLocationHistoryReducer as default, getPreviousRouterLocation }
