import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { H2 } from './common/Headers'
import { dateFormatter } from '../utils/date'

class Weather extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    const { daily, currently } = this.props.data

    return (
      <div className="Weather">
        {currently && (
          <div className="mb3">
            <H2 className="tc-ns">
              Currently: {Math.round(currently.temperature)}ºF{' '}
              {currently.summary}
            </H2>
            <ul className="list flex-ns justify-center-ns pl0 mb3">
              <li className="mh2-ns">
                Precipitation: {Math.round(currently.precipProbability * 100)}%
                chance
              </li>
              <li className="mh2-ns">
                Humidity: {Math.round(currently.humidity * 100)}%
              </li>
              <li className="mh2-ns">
                Pressure: {Math.floor(currently.pressure)} mb
              </li>
            </ul>
          </div>
        )}
        {daily ? (
          <div className="lh-copy ba b--light-gray br2 pa3">
            <div className="tc-ns f5 mb3">
              <span className="b">Weekly Summary:</span> {daily.summary}
            </div>
            {daily.data.map((x, i) => {
              const prefix = cx('flex-ns justify-between', {
                'bb b--light-gray mb3 pb3': i < daily.data.length - 1,
              })

              return (
                <div key={x.time} className={prefix}>
                  <div className="b w-20-ns mr2-ns">
                    {dateFormatter(x.time * 1000)}
                  </div>
                  <div className="w-30-ns mr2-ns">{x.summary}</div>
                  <div className="w-30-ns mr2-ns">
                    <span className="red">
                      High {Math.round(x.temperatureHigh)}ºF
                    </span>{' '}
                    /{' '}
                    <span className="dark-blue">
                      Low {Math.round(x.temperatureLow)}ºF
                    </span>
                  </div>
                  <div className="w-20-ns mr2-ns">
                    {Math.round(x.precipProbability * 100)}% chance of
                    precipitation
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    )
  }
}

export default Weather
