import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Tabs, TabPane, Spin } from './common/Ant'
import SiteHeader from './SiteHeader'
import SiteContractors from './SiteContractors'
import AllSiteDeployments from './AllSiteDeployments'
import SiteDocuments from './SiteDocuments'
import Buildings from './Buildings'
import Weather from './Weather'
import ActivityLog from './ActivityLog'
import RiMetrics from './RiMetrics'
import AssignmentHistory from './AssignmentHistory'
import EditSiteFloorPlan from './EditSiteFloorPlan'
import AssignThresholdGroups from './AssignThresholdGroups'
import SiteUsers from './SiteUsers'
import AllComments from './AllComments'
import SiteThresholds from './SiteThresholds'
import SiteEquipment from './SiteEquipment'
import Invoices from './Invoices'
import Divider from './common/Divider'
import Button from './common/Button'
import { Option } from './common/Select'
import Legend from './common/Legend'
import SelectContainer from '../containers/common/SelectContainer'
import Block from './common/Block'
import { dateFormatter } from '../utils/date'
import { formatPhoneNumber } from '../utils/forms'
import { unique } from '../utils/helpers'
import { createQueryString } from '../utils/queryParams'
import { getPathSnippets } from '../utils/helpers'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { toTitleCase } from '../utils/textFormatters'
import { USER_PERMISSIONS, ROLES, RESOURCE_TYPES } from '../constants'

class Site extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: undefined,
      userId: undefined,
    }
  }

  static propTypes = {
    currentSite: PropTypes.object.isRequired,
    getAdminSite: PropTypes.func.isRequired,
    getSite: PropTypes.func.isRequired,
    addUserToSite: PropTypes.func.isRequired,
    deleteUserFromSite: PropTypes.func.isRequired,
    addGatewayToSite: PropTypes.func.isRequired,
    deleteGatewayFromSite: PropTypes.func.isRequired,
    deleteDeployment: PropTypes.func.isRequired,
    getAllDeploymentsBySite: PropTypes.func.isRequired,
    getAllLocationsByFloor: PropTypes.func.isRequired,
    getAllLocationsBySite: PropTypes.func.isRequired,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    deleteBuilding: PropTypes.func,
    getAllPodsAssignmentHistory: PropTypes.func.isRequired,
    allPodsAssignmentHistory: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.object,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    floors: PropTypes.arrayOf(PropTypes.object).isRequired,
    deployments: PropTypes.arrayOf(PropTypes.object).isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllFloors: PropTypes.func.isRequired,
    deleteFloor: PropTypes.func.isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    addThresholdToSite: PropTypes.func.isRequired,
    deleteThresholdFromSite: PropTypes.func.isRequired,
    addThresholdToBuilding: PropTypes.func.isRequired,
    deleteThresholdFromBuilding: PropTypes.func.isRequired,
    addThresholdToFloor: PropTypes.func.isRequired,
    deleteThresholdFromFloor: PropTypes.func.isRequired,
    addThresholdToLocation: PropTypes.func.isRequired,
    deleteThresholdFromLocation: PropTypes.func.isRequired,
    currentBuilding: PropTypes.object.isRequired,
    currentFloor: PropTypes.object.isRequired,
    currentLocation: PropTypes.object.isRequired,
    accountProfile: PropTypes.object.isRequired,
    allAvailableGateways: PropTypes.arrayOf(PropTypes.object).isRequired,
    uploadUsersCSV: PropTypes.func.isRequired,
    csvUploadResults: PropTypes.object.isRequired,
    isSiteLoading: PropTypes.bool.isRequired,
    isDeploymentLoading: PropTypes.bool.isRequired,
    isFloorLoading: PropTypes.bool.isRequired,
    isLocationLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    windowWidth: PropTypes.number.isRequired,
    getAllThresholds: PropTypes.func.isRequired,
    getAllAvailableGateways: PropTypes.func.isRequired,
    generateGrafanaConfig: PropTypes.func.isRequired,
    getAllEscalationPolicies: PropTypes.func.isRequired,
    getAllFloorSummaries: PropTypes.func.isRequired,
    escalationPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
    getWeather: PropTypes.func.isRequired,
    clearWeather: PropTypes.func.isRequired,
    currentSiteWeather: PropTypes.object.isRequired,
    updateSiteThresholdUserMapping: PropTypes.func.isRequired,
    getSiteAggregateThresholdUserMappings: PropTypes.func.isRequired,
    siteAggregateThresholdUserMappings: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    getAggregateThresholdsBySite: PropTypes.func.isRequired,
    siteThresholdMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateBuildingThresholdUserMapping: PropTypes.func.isRequired,
    updateFloorThresholdUserMapping: PropTypes.func.isRequired,
    updateLocationThresholdUserMapping: PropTypes.func.isRequired,
    getActivityLog: PropTypes.func.isRequired,
    activityLog: PropTypes.arrayOf(PropTypes.object).isRequired,
    activityLogMeta: PropTypes.object.isRequired,
    getRiMetrics: PropTypes.func.isRequired,
    riMetrics: PropTypes.arrayOf(PropTypes.object).isRequired,
    getSiteContractors: PropTypes.func.isRequired,
    siteContractors: PropTypes.arrayOf(PropTypes.object).isRequired,
    addSiteContractor: PropTypes.func.isRequired,
    deleteSiteContractor: PropTypes.func.isRequired,
    isCompanyLoading: PropTypes.bool.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    updateSortOrder: PropTypes.func.isRequired,
    uploadFloorPlanPdf: PropTypes.func.isRequired,
    handleModalOpen: PropTypes.func.isRequired,
    handleModalCancel: PropTypes.func.isRequired,
    getAllComments: PropTypes.func,
    deleteComment: PropTypes.func,
    createComment: PropTypes.func,
    editComment: PropTypes.func,
    getAllTags: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.object),
    comments: PropTypes.arrayOf(PropTypes.object),
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    createBuilding: PropTypes.func.isRequired,
    getFloorPlanSnaps: PropTypes.func.isRequired,
    siteFloorPlanSnaps: PropTypes.arrayOf(PropTypes.object).isRequired,
    uploadDocument: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      match,
      accountProfile,
      currentSite,
      getSite,
      getAdminSite,
      getAllAvailableGateways,
      getAllThresholds,
      getAllEscalationPolicies,
      getAllFloors,
      getAllFloorSummaries,
      getWeather,
    } = this.props
    const { siteSlug } = match.params

    if (accountProfile.role) {
      const getSiteFn = hasRole(
        ROLES.ORGANIZATION_ADMIN,
        ROLES.PILLAR_CONTRACTOR,
        ROLES.PILLAR_ADMIN,
        ROLES.SUPER_ADMIN
      )
        ? getAdminSite
        : getSite

      getSiteFn(siteSlug)
      getAllFloors(siteSlug)

      if (
        hasRole(
          ROLES.JOBSITE_ADMIN,
          ROLES.ORGANIZATION_ADMIN,
          ROLES.PILLAR_CONTRACTOR,
          ROLES.PILLAR_ADMIN,
          ROLES.SUPER_ADMIN
        )
      ) {
        getAllAvailableGateways(siteSlug)
        getAllFloorSummaries(siteSlug)
      }

      if (hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD)) {
        const qs = createQueryString({ perPage: 'all' })
        getAllThresholds(qs)
      }

      if (hasPermission(USER_PERMISSIONS.VIEW_ESCALATION_POLICY)) {
        getAllEscalationPolicies()
      }
    }

    if (!!currentSite.lat && !!currentSite.lng) {
      getWeather({
        lat: currentSite.lat,
        lng: currentSite.lng,
      })
    }

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet)
  }

  componentWillReceiveProps(nextProps) {
    const {
      match,
      accountProfile,
      currentSite,
      getSite,
      getAdminSite,
      getAllAvailableGateways,
      getAllThresholds,
      getAllEscalationPolicies,
      getAllFloors,
      getAllFloorSummaries,
      getWeather,
    } = this.props
    const { siteSlug } = match.params
    const { lat, lng } = currentSite

    if (!accountProfile.role && nextProps.accountProfile.role) {
      const getSiteFn = hasRole(
        ROLES.ORGANIZATION_ADMIN,
        ROLES.PILLAR_CONTRACTOR,
        ROLES.PILLAR_ADMIN,
        ROLES.SUPER_ADMIN
      )
        ? getAdminSite
        : getSite

      getSiteFn(siteSlug)
      getAllFloors(siteSlug)

      if (
        hasRole(
          ROLES.JOBSITE_ADMIN,
          ROLES.ORGANIZATION_ADMIN,
          ROLES.PILLAR_CONTRACTOR,
          ROLES.PILLAR_ADMIN,
          ROLES.SUPER_ADMIN
        )
      ) {
        getAllAvailableGateways(siteSlug)
        getAllFloorSummaries(siteSlug)
      }

      if (hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD)) {
        const qs = createQueryString({ perPage: 'all' })
        getAllThresholds(qs)
      }

      if (hasPermission(USER_PERMISSIONS.VIEW_ESCALATION_POLICY)) {
        getAllEscalationPolicies()
      }
    }

    // TODO make nicer
    if (
      (!!nextProps.currentSite.lat && nextProps.currentSite.lat !== lat) ||
      (!!nextProps.currentSite.lng && nextProps.currentSite.lng !== lng)
    ) {
      getWeather({
        lat: nextProps.currentSite.lat,
        lng: nextProps.currentSite.lng,
      })
    }
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentWillUnmount() {
    this.props.clearWeather()

    this.props.handleModalCancel()
  }

  handleTabSelection = entity => {
    const { updateQueryParams, match } = this.props
    const { activeTab } = this.state

    if (entity !== match.params.siteSlug) {
      this.setState({ activeTab: entity })
      updateQueryParams({
        pathname: `${match.url}/${entity}`,
        search: entity === activeTab ? global.location.search : '',
      })
    } else {
      this.setState({ activeTab: 'buildings' })
    }
  }

  handleAddUserToSite = userId => {
    const { addUserToSite, match } = this.props

    addUserToSite(match.params.siteSlug, userId)
    this.setState({ userId: undefined })
  }

  handleDeleteUserFromSite = userId => {
    const { deleteUserFromSite, match } = this.props

    deleteUserFromSite(match.params.siteSlug, userId)
  }

  handleAddGatewayToSite = value => {
    this.props.addGatewayToSite(this.props.match.params.siteSlug, value)
  }

  handleDeleteGatewayFromSite = id => {
    this.props.deleteGatewayFromSite(this.props.match.params.siteSlug, id)
  }

  handleAddThresholdToSite = value => {
    this.props.addThresholdToSite(this.props.match.params.siteSlug, value)
  }

  handleDeleteThresholdFromSite = thresholdId => {
    this.props.deleteThresholdFromSite(
      this.props.match.params.siteSlug,
      thresholdId
    )
  }

  render() {
    const {
      currentSite,
      allAvailableGateways,
      thresholds = [],
      floors,
      buildings,
      deployments,
      deleteFloor,
      deleteDeployment,
      getAllDeploymentsBySite,
      deleteBuilding,
      locations,
      getAllLocationsByFloor,
      getAllLocationsBySite,
      snapshotData,
      getLocationSnapshot,
      getAllPodsAssignmentHistory,
      allPodsAssignmentHistory,
      match,
      updateQueryParams,
      updateSortOrder,
      uploadUsersCSV,
      csvUploadResults,
      isSiteLoading,
      isFloorLoading,
      isLocationLoading,
      isDeploymentLoading,
      isHistoryLoading,
      generateGrafanaConfig,
      windowWidth,
      currentSiteWeather,
      addThresholdToSite,
      addThresholdToBuilding,
      addThresholdToFloor,
      deleteThresholdFromSite,
      deleteThresholdFromBuilding,
      deleteThresholdFromFloor,
      addThresholdToLocation,
      deleteThresholdFromLocation,
      currentBuilding,
      currentFloor,
      currentLocation,
      updateSiteThresholdUserMapping,
      getSiteAggregateThresholdUserMappings,
      siteAggregateThresholdUserMappings,
      getAggregateThresholdsBySite,
      siteThresholdMappings,
      updateBuildingThresholdUserMapping,
      updateFloorThresholdUserMapping,
      updateLocationThresholdUserMapping,
      activityLog,
      getActivityLog,
      activityLogMeta,
      riMetrics,
      getRiMetrics,
      siteContractors,
      getSiteContractors,
      addSiteContractor,
      deleteSiteContractor,
      isCompanyLoading,
      companies,
      getAllCompanies,
      uploadFloorPlanPdf,
      handleModalOpen,
      getAllComments,
      deleteComment,
      createComment,
      editComment,
      getAllTags,
      tags,
      comments,
      getAllUsersBySite,
      siteUsers,
      getAllThresholds,
      createBuilding,
      getFloorPlanSnaps,
      siteFloorPlanSnaps,
      uploadDocument,
    } = this.props

    const {
      description,
      deploymentDate,
      completionDate,
      timezoneName,
      primaryContactUser,
      secondaryContactUser,
      grafanaConfig,
      coEnabled,
      vocEnabled,
      soundEnabled,
      ambientLightEnabled,
      dewPointEnabled,
      signalInfoEnabled,
      tempEnabled,
      humEnabled,
      presEnabled,
      dustEnabled,
      enableActionReport,
      notificationMuted,
      systemNotificationMuted,
      alertLeadMinutes,
      alertLagMinutes,
      supportsLora,
      test,
      address,
      city,
      state,
      zipCode,
      shippingAddress,
      shippingCity,
      shippingState,
      shippingZipCode,
      contractStartDate,
      contractEndDate,
      kastleBuildingId,
      unitPressure,
      unitTemperature,
      unitAmbientLight,
      // insuranceCarrierId,
      insuranceCarrierName,
      siteInstallType,
      squareFootage,
      sowSignatureDate,
      trainingCompleteDate,
      equipmentReturnedDate,
      debriefCompleteDate,
      customerContactInfo = [],
      shippingContactInfo = [],
      billingContactInfo = [],
    } = currentSite

    const { siteSlug } = match.params

    const hasBuildings =
      floors
        .map(({ buildingId }) => buildingId)
        .filter(x => x)
        .filter(unique).length > 0 || buildings.length > 0

    var grafanaConfigJSON = grafanaConfig
      ? `text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(grafanaConfig)
        )}`
      : null

    const isPillarAdmin = hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN)

    return (
      <section className="Site">
        {isSiteLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <>
            <Switch>
              <Route
                path={`${match.url}/`}
                exact
                render={props => <Redirect to={`${match.url}/buildings`} />}
              />
              <>
                <SiteHeader
                  isLocationLoading={isLocationLoading}
                  getAllLocationsBySite={getAllLocationsBySite}
                  locations={locations}
                  site={currentSite}
                  uploadUsersCSV={uploadUsersCSV}
                  csvUploadResults={csvUploadResults}
                  match={match}
                />
                <Tabs
                  type="card"
                  defaultActiveKey="buildings"
                  activeKey={this.state.activeTab}
                  onTabClick={this.handleTabSelection}
                >
                  <TabPane
                    tab={hasBuildings ? 'Buildings' : 'Floors'}
                    key="buildings"
                  >
                    <Route
                      path={`${match.url}/buildings`}
                      render={props => (
                        <Buildings
                          buildings={buildings}
                          floors={floors}
                          siteSlug={siteSlug}
                          handleModalOpen={handleModalOpen}
                          deleteBuilding={deleteBuilding}
                          deleteFloor={deleteFloor}
                          isFloorLoading={isFloorLoading}
                          hasBuildings={hasBuildings}
                          isLocationLoading={isLocationLoading}
                          getAllLocationsByFloor={getAllLocationsByFloor}
                          snapshotData={snapshotData}
                          getLocationSnapshot={getLocationSnapshot}
                          locations={locations}
                          match={match}
                          updateQueryParams={updateQueryParams}
                          updateSortOrder={updateSortOrder}
                        />
                      )}
                    />
                  </TabPane>
                  {hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD_GROUP) && (
                    <TabPane tab="Threshold Groups" key="threshold-groups">
                      <Route
                        path={`${match.url}/threshold-groups`}
                        exact
                        render={props => (
                          <>
                            <AssignThresholdGroups
                              siteSlug={siteSlug}
                              assignedThresholds={currentSite.thresholds}
                            />
                          </>
                        )}
                      />
                    </TabPane>
                  )}
                  {hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) && (
                    <TabPane tab="Thresholds" key="thresholds">
                      <Route
                        path={`${match.url}/thresholds`}
                        exact
                        render={props => (
                          <>
                            <SiteThresholds
                              siteSlug={siteSlug}
                              isLoading={isSiteLoading}
                              buildings={buildings}
                              floors={floors}
                              locations={locations}
                              getAllLocationsBySite={getAllLocationsBySite}
                              thresholds={thresholds}
                              addThresholdToSite={addThresholdToSite}
                              deleteThresholdFromSite={deleteThresholdFromSite}
                              addThresholdToFloor={addThresholdToFloor}
                              deleteThresholdFromFloor={
                                deleteThresholdFromFloor
                              }
                              addThresholdToBuilding={addThresholdToBuilding}
                              deleteThresholdFromBuilding={
                                deleteThresholdFromBuilding
                              }
                              addThresholdToLocation={addThresholdToLocation}
                              deleteThresholdFromLocation={
                                deleteThresholdFromLocation
                              }
                              currentBuilding={currentBuilding}
                              currentFloor={currentFloor}
                              currentLocation={currentLocation}
                              currentSite={currentSite}
                              getMappings={() =>
                                getAggregateThresholdsBySite(siteSlug)
                              }
                              mappings={siteThresholdMappings}
                              getSiteAggregateThresholdUserMappings={
                                getSiteAggregateThresholdUserMappings
                              }
                              updateSiteThresholdUserMapping={
                                updateSiteThresholdUserMapping
                              }
                              siteAggregateThresholdUserMappings={
                                siteAggregateThresholdUserMappings
                              }
                              updateBuildingThresholdUserMapping={
                                updateBuildingThresholdUserMapping
                              }
                              updateFloorThresholdUserMapping={
                                updateFloorThresholdUserMapping
                              }
                              updateLocationThresholdUserMapping={
                                updateLocationThresholdUserMapping
                              }
                              getAllThresholds={getAllThresholds}
                              windowWidth={windowWidth}
                            />
                          </>
                        )}
                      />
                    </TabPane>
                  )}
                  {(hasPermission(USER_PERMISSIONS.VIEW_INSTALL_DEPLOYMENT) ||
                    hasPermission(
                      USER_PERMISSIONS.VIEW_UNINSTALL_DEPLOYMENT
                    )) && (
                    <TabPane tab="Deployments" key="deployments">
                      <Route
                        path={`${match.url}/deployments`}
                        exact
                        render={props => (
                          <AllSiteDeployments
                            siteSlug={siteSlug}
                            deployments={deployments}
                            windowWidth={windowWidth}
                            deleteDeployment={deleteDeployment}
                            getAllDeploymentsBySite={getAllDeploymentsBySite}
                            isDeploymentLoading={isDeploymentLoading}
                            isRenderingSiteView
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {hasRole(ROLES.JOBSITE_ADMIN) && (
                    <TabPane tab="Users" key="users">
                      <Route
                        path={`${match.url}/users`}
                        exact
                        render={props => (
                          <SiteUsers
                            users={siteUsers}
                            getAllUsersBySite={() =>
                              getAllUsersBySite(siteSlug)
                            }
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {hasPermission(USER_PERMISSIONS.ADD_SITE_USER) && (
                    <TabPane tab="Users" key="users">
                      <Route
                        path={`${match.url}/users`}
                        exact
                        render={props => (
                          <>
                            <div className="mb2">
                              <SelectContainer
                                label="Add User to Site"
                                placeholder="Select a user"
                                input={{
                                  onChange: this.handleAddUserToSite,
                                  value: this.state.userId,
                                }}
                                filterable
                              >
                                {currentSite.availableUsers.map(x => (
                                  <Option value={x.id} key={x.id}>
                                    {x.firstName} {x.lastName}
                                  </Option>
                                ))}
                              </SelectContainer>
                            </div>
                            <SiteUsers
                              users={siteUsers}
                              getAllUsersBySite={() =>
                                getAllUsersBySite(siteSlug)
                              }
                              deleteUserFromSite={this.handleDeleteUserFromSite}
                            />
                          </>
                        )}
                      />
                    </TabPane>
                  )}

                  {isPillarAdmin && (
                    <TabPane tab="Project Details" key="project-details">
                      <Route
                        path={`${match.url}/project-details`}
                        exact
                        render={() => (
                          <>
                            <div className="flex flex-wrap mb3">
                              <Legend>Project Details</Legend>
                              <Block label="Description">{description}</Block>
                              <Block label="Insurance Carrier">
                                {insuranceCarrierName}
                              </Block>
                              <Block label="Install Type">
                                {toTitleCase(siteInstallType)}
                              </Block>
                              <Block label="Square Footage">
                                {squareFootage}
                              </Block>

                              <Legend>Project Address</Legend>
                              {(address || city || state) && (
                                <Block label="Address">
                                  {address && <div>{address}</div>}
                                  {city && (
                                    <div>
                                      {city}, {state} {zipCode}
                                    </div>
                                  )}
                                  {address && city && state && (
                                    <a
                                      className="link dark-blue"
                                      href={`https://www.google.com/maps/place/${address}+${city}+${state}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View on Google Maps
                                    </a>
                                  )}
                                </Block>
                              )}
                              <Block label="Time Zone">{timezoneName}</Block>

                              <Legend>Shipping Address</Legend>
                              {(shippingAddress ||
                                shippingCity ||
                                shippingState) && (
                                <Block label="Shipping Address">
                                  {shippingAddress && (
                                    <div>{shippingAddress}</div>
                                  )}
                                  {shippingCity && (
                                    <div>
                                      {shippingCity}, {shippingState}{' '}
                                      {shippingZipCode}
                                    </div>
                                  )}
                                  {shippingAddress &&
                                    shippingCity &&
                                    shippingState && (
                                      <a
                                        className="link dark-blue"
                                        href={`https://www.google.com/maps/place/${shippingAddress}+${shippingCity}+${shippingState}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View on Google Maps
                                      </a>
                                    )}
                                </Block>
                              )}

                              <Legend>Contacts</Legend>
                              <Block label="Primary Contact">
                                {primaryContactUser.id && (
                                  <div>
                                    <div>
                                      {primaryContactUser.firstName}{' '}
                                      {primaryContactUser.lastName}
                                    </div>
                                    <div>
                                      {primaryContactUser.phoneNumber && (
                                        <a
                                          className="dark-blue link"
                                          href={`tel:${primaryContactUser.phoneNumber}`}
                                        >
                                          {formatPhoneNumber(
                                            primaryContactUser.phoneNumber
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <div>
                                      {primaryContactUser.email && (
                                        <a
                                          className="dark-blue link"
                                          href={`mailto:${primaryContactUser.email}`}
                                        >
                                          {primaryContactUser.email}
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Block>
                              <Block label="Secondary Contact">
                                {secondaryContactUser.id && (
                                  <div>
                                    <div>
                                      {secondaryContactUser.firstName}{' '}
                                      {secondaryContactUser.lastName}
                                    </div>
                                    <div>
                                      {secondaryContactUser.phoneNumber && (
                                        <a
                                          className="dark-blue link"
                                          href={`tel:${secondaryContactUser.phoneNumber}`}
                                        >
                                          {formatPhoneNumber(
                                            secondaryContactUser.phoneNumber
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <div>
                                      {secondaryContactUser.email && (
                                        <a
                                          className="dark-blue link"
                                          href={`mailto:${secondaryContactUser.email}`}
                                        >
                                          {secondaryContactUser.email}
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Block>

                              <Block label="Customer Contact">
                                {customerContactInfo.length > 0 &&
                                  customerContactInfo.map(x => (
                                    <div>
                                      <div>{x.name}</div>
                                      <div>{formatPhoneNumber(x.phone)}</div>
                                      <div>{x.email}</div>
                                    </div>
                                  ))}
                              </Block>
                              <Block label="Shipping Contact">
                                {shippingContactInfo.length > 0 &&
                                  customerContactInfo.map(x => (
                                    <div>
                                      <div>{x.name}</div>
                                      <div>{formatPhoneNumber(x.phone)}</div>
                                      <div>{x.email}</div>
                                    </div>
                                  ))}
                              </Block>
                              <Block label="Billing Contact">
                                {billingContactInfo.length > 0 &&
                                  customerContactInfo.map(x => (
                                    <div>
                                      <div>{x.name}</div>
                                      <div>{formatPhoneNumber(x.phone)}</div>
                                      <div>{x.email}</div>
                                    </div>
                                  ))}
                              </Block>

                              <Legend>Project Dates</Legend>
                              <Block label="Installation Date">
                                {deploymentDate &&
                                  dateFormatter(deploymentDate)}
                              </Block>
                              <Block label="Completion Date">
                                {completionDate &&
                                  dateFormatter(completionDate)}
                              </Block>
                              <Block label="Approx. Contract Start Date">
                                {contractStartDate &&
                                  dateFormatter(contractStartDate)}
                              </Block>
                              <Block label="Approx. Contract End Date">
                                {contractEndDate &&
                                  dateFormatter(contractEndDate)}
                              </Block>
                              <Block label="SOW Signature Date">
                                {sowSignatureDate &&
                                  dateFormatter(sowSignatureDate)}
                              </Block>
                              <Block label="Training Complete Date">
                                {trainingCompleteDate &&
                                  dateFormatter(trainingCompleteDate)}
                              </Block>
                              <Block label="Equipment Returned Date">
                                {equipmentReturnedDate &&
                                  dateFormatter(equipmentReturnedDate)}
                              </Block>
                              <Block label="Debrief Complete Date">
                                {debriefCompleteDate &&
                                  dateFormatter(debriefCompleteDate)}
                              </Block>
                            </div>
                            <Divider />
                            <div>
                              <SiteDocuments siteSlug={siteSlug} />
                            </div>
                            <div className="flex flex-wrap mb3">
                              <Legend>Admin Configurations</Legend>
                              <Block label="Action Reporting">
                                {enableActionReport ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              {alertLagMinutes > -1 && (
                                <Block label="Alert Lag Minutes">
                                  {alertLagMinutes.toString()}
                                </Block>
                              )}
                              {alertLeadMinutes > -1 && (
                                <Block label="Alert Lead Minutes">
                                  {alertLeadMinutes.toString()}
                                </Block>
                              )}
                              <Block label="LoRa Enabled">
                                {supportsLora ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Test Site">
                                {test ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>

                              <Legend>Smart Pod Sensors</Legend>
                              <Block label="Temperature">
                                {tempEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Humidity">
                                {humEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Pressure">
                                {presEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Dust">
                                {dustEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="VOC (Alpha)">
                                {vocEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Sound">
                                {soundEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Ambient Light">
                                {ambientLightEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Carbon Monoxide">
                                {coEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Dew Point">
                                {dewPointEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Signal">
                                {signalInfoEnabled ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>

                              <Legend>Units</Legend>
                              <Block label="Temperature">
                                {toTitleCase(unitTemperature)}
                              </Block>
                              <Block label="Ambient Light">
                                {toTitleCase(unitAmbientLight)}
                              </Block>
                              <Block label="Pressure">
                                {toTitleCase(unitPressure)}
                              </Block>

                              {kastleBuildingId && (
                                <>
                                  <Legend>Third-Party Integrations</Legend>
                                  <Block label="Kastle Building ID">
                                    {kastleBuildingId}
                                  </Block>
                                </>
                              )}

                              <Legend>Notification Preferences</Legend>
                              <Block label="Alert Notifications">
                                {!notificationMuted ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                              <Block label="Offline Notifications">
                                {!systemNotificationMuted ? (
                                  <span className="b">Enabled</span>
                                ) : (
                                  'Disabled'
                                )}
                              </Block>
                            </div>
                            <Divider />
                            {grafanaConfigJSON ? (
                              <a
                                className="link dark-blue"
                                href={`data:${grafanaConfigJSON}`}
                                download={`${siteSlug}-grafana.json`}
                              >
                                Download Grafana Config
                              </a>
                            ) : (
                              <Button
                                onClick={() => generateGrafanaConfig(siteSlug)}
                                text="Generate Grafana Config"
                              />
                            )}
                          </>
                        )}
                      />
                    </TabPane>
                  )}
                  {hasPermission(USER_PERMISSIONS.VIEW_COMMENT) && (
                    <TabPane tab="Comments" key="comments">
                      <Route
                        path={`${match.url}/comments`}
                        exact
                        render={props => (
                          <AllComments
                            resourceType={RESOURCE_TYPES.SITE}
                            resourceSlug={match.params.siteSlug}
                            getAllComments={getAllComments}
                            deleteComment={deleteComment}
                            createComment={createComment}
                            editComment={editComment}
                            getAllTags={getAllTags}
                            tags={tags}
                            comments={comments}
                            {...props}
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {currentSiteWeather.currently && (
                    <TabPane tab="Weather" key="weather">
                      <Route
                        path={`${match.url}/weather`}
                        exact
                        render={props => <Weather data={currentSiteWeather} />}
                      />
                    </TabPane>
                  )}
                  {isPillarAdmin && (
                    <TabPane tab="Activity Log" key="activity-log">
                      <Route
                        path={`${match.url}/activity-log`}
                        exact
                        render={props => (
                          <ActivityLog
                            getActivityLog={getActivityLog}
                            activityLog={activityLog}
                            siteSlug={siteSlug}
                            isLoading={isHistoryLoading}
                            meta={activityLogMeta}
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {isPillarAdmin && (
                    <TabPane tab="Contractors" key="contractors">
                      <Route
                        path={`${match.url}/contractors`}
                        exact
                        render={props => (
                          <SiteContractors
                            getAllCompanies={getAllCompanies}
                            companies={companies}
                            siteContractors={siteContractors}
                            getSiteContractors={getSiteContractors}
                            addSiteContractor={addSiteContractor}
                            deleteSiteContractor={deleteSiteContractor}
                            siteSlug={siteSlug}
                            isLoading={isCompanyLoading}
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {isPillarAdmin && (
                    <TabPane
                      tab="Smart Pod Assignment History"
                      key="pods-history"
                    >
                      <Route
                        path={`${match.url}/pods-history`}
                        exact
                        render={props => (
                          <AssignmentHistory
                            assignmentHistory={allPodsAssignmentHistory}
                            getAssignmentHistory={getAllPodsAssignmentHistory}
                            siteSlug={siteSlug}
                            isLoading={isHistoryLoading}
                            isBySite={true}
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {isPillarAdmin && (
                    <TabPane tab="Site Equipment" key="site-equipment">
                      <Route
                        path={`${match.url}/site-equipment`}
                        exact
                        render={props => (
                          <SiteEquipment
                            siteSlug={siteSlug}
                            currentSite={currentSite}
                            allAvailableGateways={allAvailableGateways}
                            windowWidth={windowWidth}
                            handleAddGatewayToSite={this.handleAddGatewayToSite}
                            handleDeleteGatewayFromSite={
                              this.handleDeleteGatewayFromSite
                            }
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {isPillarAdmin && (
                    <TabPane tab="Reliability Index Metrics" key="ri-metrics">
                      <Route
                        path={`${match.url}/ri-metrics`}
                        exact
                        render={props => (
                          <RiMetrics
                            getRiMetrics={getRiMetrics}
                            riMetrics={riMetrics}
                            siteSlug={siteSlug}
                            isLoading={isHistoryLoading}
                            updateQueryParams={updateQueryParams}
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {isPillarAdmin && (
                    <TabPane tab="Billing" key="billing">
                      <Route
                        path={`${match.url}/billing`}
                        exact
                        render={props => (
                          <Invoices
                            uploadDocument={uploadDocument}
                            siteSlug={siteSlug}
                            currentSite={currentSite}
                            getAllCompanies={getAllCompanies}
                            companies={companies}
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  {isPillarAdmin && (
                    <TabPane tab="Floor Plan" key="floorplan">
                      <Route
                        path={`${match.url}/floorplan`}
                        exact
                        render={props => (
                          <EditSiteFloorPlan
                            isFloorPlanTab
                            siteFloorPlanSnaps={siteFloorPlanSnaps}
                            getFloorPlanSnaps={getFloorPlanSnaps}
                            createBuilding={createBuilding}
                            isSiteLoading={isHistoryLoading}
                            match={match}
                            uploadFloorPlanPdf={uploadFloorPlanPdf}
                          />
                        )}
                      />
                    </TabPane>
                  )}
                  <TabPane tab="Documents" key="documents">
                    <Route
                      path={`${match.url}/documents`}
                      exact
                      render={() => (
                        <SiteDocuments siteSlug={siteSlug} isAllDocuments />
                      )}
                    />
                  </TabPane>
                </Tabs>
              </>
            </Switch>
          </>
        )}
      </section>
    )
  }
}

export default Site
