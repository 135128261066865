import { fork, call, put, all, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllEscalationPoliciesAction,
  getEscalationPolicyAction,
  createEscalationPolicyAction,
  updateEscalationPolicyAction,
  deleteEscalationPolicyAction,
} from '../actions/escalationPoliciesActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllEscalationPoliciesSaga({ payload }) {
  try {
    const result = yield call(api.getAllEscalationPolicies, payload)
    yield put(getAllEscalationPoliciesAction.success(result))
  } catch (error) {
    yield put(getAllEscalationPoliciesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetEscalationPolicySaga({ payload }) {
  try {
    const result = yield call(api.getEscalationPolicyBySlug, payload)
    yield put(getEscalationPolicyAction.success(result))
  } catch (error) {
    yield put(getEscalationPolicyAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateEscalationPolicySaga({ payload }) {
  try {
    const payloadWithStep = {
      ...payload,
      policyGroups: payload.policyGroups.map((x, i) => ({
        ...x,
        step: i + 1,
      })),
    }
    const result = yield call(api.createEscalationPolicy, payloadWithStep)
    yield put(createEscalationPolicyAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    // TODO
    // yield put(push(`/rules/escalation-policies/${result.id}`))
    yield put(push('/rules'))
  } catch (error) {
    yield put(createEscalationPolicyAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateEscalationPolicySaga({ payload }) {
  try {
    const payloadWithStep = {
      ...payload,
      policyGroups: payload.policyGroups.map((x, i) => ({
        ...x,
        step: i + 1,
      })),
    }
    const result = yield call(api.updateEscalationPolicy, payloadWithStep)
    yield put(updateEscalationPolicyAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    // TODO
    // yield put(push(`/rules/escalation-policies/${result.id}`))
    yield put(push('/rules'))
  } catch (error) {
    yield put(updateEscalationPolicyAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteEscalationPolicySaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteEscalationPolicy, payload)
      yield fork(handleGetAllEscalationPoliciesSaga, {})
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteEscalationPolicyAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllEscalationPoliciesSaga() {
  yield takeEvery(
    getAllEscalationPoliciesAction.REQUEST,
    handleGetAllEscalationPoliciesSaga
  )
}

function* watchGetEscalationPolicySaga() {
  yield takeEvery(
    getEscalationPolicyAction.REQUEST,
    handleGetEscalationPolicySaga
  )
}

function* watchCreateEscalationPolicySaga() {
  yield takeEvery(
    createEscalationPolicyAction.REQUEST,
    handleCreateEscalationPolicySaga
  )
}

function* watchUpdateEscalationPolicySaga() {
  yield takeEvery(
    updateEscalationPolicyAction.REQUEST,
    handleUpdateEscalationPolicySaga
  )
}

function* watchDeleteEscalationPolicySaga() {
  yield takeEvery(
    deleteEscalationPolicyAction.REQUEST,
    handleDeleteEscalationPolicySaga
  )
}

function* escalationPoliciesSaga() {
  yield all([
    fork(watchGetAllEscalationPoliciesSaga),
    fork(watchGetEscalationPolicySaga),
    fork(watchCreateEscalationPolicySaga),
    fork(watchUpdateEscalationPolicySaga),
    fork(watchDeleteEscalationPolicySaga),
  ])
}

export {
  escalationPoliciesSaga as default,
  watchGetAllEscalationPoliciesSaga,
  watchGetEscalationPolicySaga,
  watchCreateEscalationPolicySaga,
  watchUpdateEscalationPolicySaga,
  watchDeleteEscalationPolicySaga,
  handleGetAllEscalationPoliciesSaga,
  handleGetEscalationPolicySaga,
  handleCreateEscalationPolicySaga,
  handleUpdateEscalationPolicySaga,
  handleDeleteEscalationPolicySaga,
}
