import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { getPathSnippets } from '../utils/helpers'

const isSlug = x => /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(x)
const containsOnlyLetters = x => /^[A-Za-z]*$/.test(x)
const containsLettersAndHyphens = x => /^[A-Za-z-]*$/.test(x)

const isTabbedRoute = pathname => {
  const parts = getPathSnippets(pathname)

  if (parts.length > 2) {
    return (
      containsOnlyLetters(parts[0]) &&
      isSlug(parts[1]) &&
      containsLettersAndHyphens(parts[2])
    )
  }

  return false
}

class ScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (isTabbedRoute(this.props.location.pathname)) {
        return
      }

      global.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
