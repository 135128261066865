import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { RadioGroup as AntRadioGroup, RadioButton } from './Ant'
import Meta from './Meta'
import Label from './Label'

const RadioGroup = ({
  input,
  label,
  children,
  meta,
  size,
  className = '',
  defaultValue,
}) => {
  const radioGroupClassNames = cx('RadioGroup mb3', className)
  return (
    <div>
      {label && <Label>{label}</Label>}
      <AntRadioGroup
        onChange={input.onChange}
        size={size}
        className={radioGroupClassNames}
        defaultValue={defaultValue}
        {...input}
      >
        {children}
      </AntRadioGroup>
      <Meta {...meta} />
    </div>
  )
}

RadioGroup.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  meta: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
}

export { RadioGroup as default, RadioButton }
