import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import logoSm from '../../assets/logo-sm.png'
import logoMd from '../../assets/logo-md.png'
import logoLg from '../../assets/logo-lg.png'

const PillarLogo = ({ center }) => {
  return (
    <div className={cx({ 'flex justify-center mb3': center })}>
      <img
        src={logoSm}
        srcSet={`${logoMd} 1000w, ${logoLg} 2000w`}
        alt="Pillar Technologies"
        height={32}
        width={134}
      />
    </div>
  )
}

PillarLogo.propTypes = {
  center: PropTypes.bool.isRequired,
}

PillarLogo.defaultProps = {
  center: false,
}

export default PillarLogo
