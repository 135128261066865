import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditGatewaySupplyForm from './forms/EditGatewaySupplyForm'
import { updateGatewaySupplyAction } from '../actions/gatewaySuppliesActions'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'

class EditGatewaySupply extends Component {
  static propTypes = {
    gatewaySupply: PropTypes.object.isRequired,
    getGatewaySupply: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getGatewaySupply, match } = this.props
    getGatewaySupply(match.params.id)
  }

  render() {
    const { gatewaySupply } = this.props
    const crumbs = [
      <Anchor to="/inventory/all/gateway-supplies">
        Network Gateway Supplies
      </Anchor>,
      'Edit Network Gateway Supply',
    ]
    return (
      <section className="EditGatewaySupply">
        <FormHeader crumbs={crumbs} />
        <EditGatewaySupplyForm
          submitAction={updateGatewaySupplyAction}
          initialValues={gatewaySupply}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditGatewaySupply
