import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditActionReportCauseForm from './forms/EditActionReportCauseForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateActionReportCauseAction } from '../actions/actionReportsActions'

class EditActionReportCause extends Component {
  static propTypes = {
    actionReportCause: PropTypes.object.isRequired,
    getActionReportCause: PropTypes.func.isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getAllCompanies, getActionReportCause, match } = this.props

    getActionReportCause(match.params.slug)
    getAllCompanies()
  }

  render() {
    const { actionReportCause, companies } = this.props
    const crumbs = [
      <Anchor to="/metadata/all">Metadata</Anchor>,
      <Anchor to="/metadata/all/action-report-causes">
        Action Report Causes
      </Anchor>,
      'Edit Action Report Cause',
    ]

    return (
      <section className="EditActionReportCause">
        <FormHeader crumbs={crumbs} />
        <EditActionReportCauseForm
          submitAction={updateActionReportCauseAction}
          companies={companies}
          initialValues={actionReportCause}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditActionReportCause
