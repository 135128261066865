import produce from 'immer'
import {
  authAction,
  logOutAction,
  verifyResetPasswordTokenAction,
  verifyTwoFactorAuthTokenAction,
  getUnconfirmedUserAction,
  HAS_AUTH_TOKEN,
  SET_PASSWORD_RESET_TOKEN,
  SET_ACCOUNT_CONFIRMATION_TOKEN,
  UNAUTHORIZED,
  SET_INITIAL_PATHNAME,
} from '../actions/authActions'

const INITIAL_STATE = {
  isAuthorized: true,
  unconfirmedUser: {},
  isValidToken: true,
  initialPathname: null,
  errorMessage: '',
}

const getValidInitialPathname = pathname => {
  const [, partToCheck] = pathname.split('/')
  const blacklist = ['', 'confirm', 'reset-password', 'two-factor']

  return blacklist.indexOf(partToCheck) === -1 ? pathname : null
}

const authReducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case verifyTwoFactorAuthTokenAction.SUCCESS:
      case authAction.SUCCESS:
      case HAS_AUTH_TOKEN:
        Object.assign(draft, { isAuthorized: true })
        break
      case authAction.FAILURE:
      case logOutAction.SUCCESS:
      case UNAUTHORIZED:
        draft.isAuthorized = false
        break
      case SET_PASSWORD_RESET_TOKEN:
        draft.passwordResetToken = payload
        break
      case SET_ACCOUNT_CONFIRMATION_TOKEN:
        draft.accountConfirmationToken = payload
        break
      case getUnconfirmedUserAction.SUCCESS:
        draft.unconfirmedUser = payload
        break
      case verifyResetPasswordTokenAction.FAILURE:
      case getUnconfirmedUserAction.FAILURE:
        Object.assign(draft, {
          isValidToken: false,
          errorMessage: payload.errors._error,
        })
        break
      case SET_INITIAL_PATHNAME:
        draft.initialPathname = getValidInitialPathname(payload)
        break
    }
  })

const isValidToken = state => state.isValidToken
const getUnconfirmedUsername = ({ unconfirmedUser }) => unconfirmedUser.email

export { authReducer as default, isValidToken, getUnconfirmedUsername }
