import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditPodForm from '../components/forms/EditPodForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createPodAction } from '../actions/podsActions'
import { createQueryString } from '../utils/queryParams'

class CreatePod extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllTags: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    getPodMetadata: PropTypes.func.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    podMetadata: PropTypes.object,
  }

  componentDidMount(props) {
    const { getAllAssetLocations, getPodMetadata, getAllTags } = this.props
    const qs = createQueryString({ perPage: 'all' })

    getAllAssetLocations(qs)
    getPodMetadata()
    getAllTags(createQueryString({ perPage: 'all' }))
  }

  render() {
    const { tags, assetLocations, podMetadata } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/pods">Smart Pods</Anchor>,
      'Create Smart Pod',
    ]

    return (
      <section className="CreatePod">
        <FormHeader crumbs={crumbs} />
        <EditPodForm
          tags={tags}
          submitAction={createPodAction}
          assetLocations={assetLocations}
          podMetadata={podMetadata}
          initialValues={{
            tagIds: [],
            defectiveSensors: [],
            statusOptions: [],
            availableNetworkGateways: [],
          }}
        />
      </section>
    )
  }
}

export default CreatePod
