import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { H2 } from './common/Headers'
import Divider from './common/Divider'
import Checkbox from './common/Checkbox'
import { Anchor } from './common/Anchor'
import TimeSelector from './common/TimeSelector'
import { TIME_FORMAT } from '../constants'

const AccountSettings = ({ profile, toggleAccountOption }) => {
  const {
    smsNotifications,
    emailNotifications,
    systemNotifications,
    gatewaySystemNotifications,
    notificationsStartTime,
    notificationsEndTime,
    phoneNumber,
  } = profile

  const defaultStartTime = moment('00:00', TIME_FORMAT)
  const defaultEndTime = moment('23:59', TIME_FORMAT)

  return (
    <section className="AccountSettings">
      <H2>
        Edit Settings
        <small>
          <Divider vertical />
          <Anchor to="/account/profile">Edit Profile</Anchor>
          <Divider vertical />
          <Anchor to="/account/change-password">Change Password</Anchor>
        </small>
      </H2>
      <Divider />
      <Checkbox
        type="checkbox"
        label={
          phoneNumber ? (
            'SMS Notifications'
          ) : (
            <span>
              SMS Notifications:{' '}
              <small>
                Please{' '}
                <Anchor to="/account/profile">add your phone number</Anchor> to
                enable
              </small>
            </span>
          )
        }
        input={{
          checked: smsNotifications,
          onChange: value =>
            toggleAccountOption({
              ...profile,
              smsNotifications: value,
            }),
          disabled: !phoneNumber,
        }}
      />
      <Checkbox
        type="checkbox"
        label="Email Notifications"
        input={{
          checked: emailNotifications,
          onChange: value =>
            toggleAccountOption({
              ...profile,
              emailNotifications: value,
            }),
        }}
      />
      <Checkbox
        type="checkbox"
        label="Offline Smart Pod Notifications"
        input={{
          checked: systemNotifications,
          onChange: value =>
            toggleAccountOption({
              ...profile,
              systemNotifications: value,
            }),
        }}
      />
      <Checkbox
        type="checkbox"
        label="Offline Network Gateway Notifications"
        input={{
          checked: gatewaySystemNotifications,
          onChange: value =>
            toggleAccountOption({
              ...profile,
              gatewaySystemNotifications: value,
            }),
        }}
      />
      <TimeSelector
        label="Notifications Start Time"
        input={{
          value: notificationsStartTime
            ? moment(notificationsStartTime, TIME_FORMAT)
            : defaultStartTime,
          onChange: value =>
            toggleAccountOption({
              ...profile,
              notificationsStartTime: value.format('HH:mm:ss'),
            }),
        }}
        format={TIME_FORMAT}
      />
      <TimeSelector
        label="Notifications End Time"
        input={{
          value: notificationsEndTime
            ? moment(notificationsEndTime, TIME_FORMAT)
            : defaultEndTime,
          onChange: value =>
            toggleAccountOption({
              ...profile,
              notificationsEndTime: value.format('HH:mm:ss'),
            }),
        }}
        format={TIME_FORMAT}
      />
    </section>
  )
}

AccountSettings.propTypes = {
  profile: PropTypes.object.isRequired,
  toggleAccountOption: PropTypes.func.isRequired,
}

export default AccountSettings
