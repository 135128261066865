import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getAllDocumentsAction,
  uploadDocumentAction,
} from '../actions/sitesActions'
import { getSiteDocuments } from '../reducers/sitesReducer'
import { isHistoryLoading } from '../reducers/uiReducer'
import InvoiceUpload from './InvoiceUpload'
import { Bread, Crumb } from './common/Bread'
import { Spin, Table, Column } from './common/Ant'
import { Anchor } from './common/Anchor'
import { dateFormatter } from '../utils/date'
import { SITE_DOCUMENT_TYPES } from '../constants'
import { toTitleCase } from '../utils/textFormatters'

const mapStateToProps = ({ sites, ui }) => ({
  documents: getSiteDocuments(sites),
  isLoading: isHistoryLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllDocuments: payload => dispatch(getAllDocumentsAction.request(payload)),
  uploadDocument: payload => dispatch(uploadDocumentAction.request(payload)),
})

const SiteDocuments = ({
  getAllDocuments,
  uploadDocument,
  documents,
  siteSlug,
  isBillingType = false,
  isAllDocuments = false,
  isLoading,
}) => {
  useEffect(() => {
    getAllDocuments(siteSlug)
  }, [getAllDocuments, siteSlug])

  const currentDocuments = isAllDocuments
    ? documents
    : isBillingType
    ? documents.filter(x => x.type === SITE_DOCUMENT_TYPES.CHANGE_ORDERS)
    : documents.filter(
        x =>
          x.type === SITE_DOCUMENT_TYPES.SCOPE_OF_WORK ||
          x.type === SITE_DOCUMENT_TYPES.PROPOSALS
      )

  return isLoading ? (
    <Spin size="medium" className="w-100 center mv5" />
  ) : (
    <div className="mb3">
      <div className="flex justify-center tc mb3">
        <div>
          <Bread>
            <Crumb>
              {isAllDocuments
                ? 'Documents'
                : isBillingType
                ? 'Change Orders'
                : 'Scope of Work & Proposals'}
            </Crumb>
          </Bread>
          {!isAllDocuments && (
            <InvoiceUpload
              uploadDocument={uploadDocument}
              siteSlug={siteSlug}
              documentType={
                isBillingType ? SITE_DOCUMENT_TYPES.CHANGE_ORDERS : ''
              }
              isBillingType={isBillingType}
            />
          )}
        </div>
      </div>
      <Table
        dataSource={currentDocuments}
        rowKey="id"
        pagination={false}
        tableLayout="auto"
        size="small"
      >
        <Column
          title="Type"
          dataIndex="type"
          render={text => toTitleCase(text)}
        />
        <Column
          title="Upload Date"
          dataIndex="uploadDate"
          render={text => dateFormatter(text)}
        />
        <Column title="Name" dataIndex="name" />
        <Column
          title="View document"
          dataIndex="siteDocumentUrl"
          render={text => {
            return text ? <Anchor to={text}>View</Anchor> : '--'
          }}
        />
      </Table>
    </div>
  )
}

SiteDocuments.propTypes = {
  getAllDocuments: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteSlug: PropTypes.string.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  isBillingType: PropTypes.bool.isRequired,
  isAllDocuments: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteDocuments)
