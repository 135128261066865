import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditBuildingForm from '../components/forms/EditBuildingForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createBuildingAction } from '../actions/buildingsActions'

class CreateBuilding extends Component {
  static propTypes = {
    getSite: PropTypes.func.isRequired,
    match: PropTypes.object,
    siteName: PropTypes.string,
  }

  componentDidMount() {
    const { match, siteName, getSite } = this.props

    if (!siteName) {
      getSite(match.params.siteSlug)
    }
  }

  render() {
    const { match, siteName } = this.props

    const crumbs = [
      <Anchor to="/sites">Sites</Anchor>,
      siteName && (
        <Anchor to={`/sites/${match.params.siteSlug}`}>{siteName}</Anchor>
      ),
      'Create Building',
    ]

    return (
      <section className="CreateBuilding">
        <FormHeader crumbs={crumbs} />
        <EditBuildingForm
          submitAction={createBuildingAction}
          siteSlug={match.params.siteSlug}
        />
      </section>
    )
  }
}

export default CreateBuilding
