import { fork, call, take, takeLatest, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllCommentsAction,
  createCommentAction,
  updateCommentAction,
  deleteCommentAction,
  getAllTagsAction,
  createTagAction,
  updateTagAction,
  deleteTagAction,
  getTagAction,
  getAllAvailbleUserMentionsAction,
} from '../actions/commentsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE, MESSAGES } from '../constants'
import { parseQueryParams, createQueryString } from '../utils/queryParams'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllTagsSaga(qs) {
  try {
    const payload = yield call(api.getAllTags, qs)
    yield put(getAllTagsAction.success(payload))
  } catch (error) {
    yield put(getAllTagsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllCommentsSaga(qs) {
  try {
    const payload = yield call(api.getAllComments, qs)
    yield put(getAllCommentsAction.success(payload))
  } catch (error) {
    yield put(getAllCommentsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateCommentSaga(comment) {
  try {
    const payload = yield call(api.createComment, comment)

    yield put(createCommentAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))

    const resourceType = comment.resourceType
    const resourceSlug = comment.resourceSlug

    const qs = createQueryString({
      resourceType,
      resourceSlug,
    })

    yield fork(handleGetAllCommentsSaga, qs)
  } catch (error) {
    yield put(createCommentAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateTagSaga(tag) {
  try {
    const payload = yield call(api.createTag, tag)

    yield put(createTagAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push('/metadata/all/tags'))
  } catch (error) {
    yield put(createTagAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateCommentSaga(comment) {
  try {
    const payload = yield call(api.updateComment, comment)
    yield put(updateCommentAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))

    const resourceType = comment.resourceType
    const resourceSlug = comment.resourceSlug

    const qs = createQueryString({
      resourceType,
      resourceSlug,
    })

    yield fork(handleGetAllCommentsSaga, qs)
  } catch (error) {
    yield put(updateCommentAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateTagSaga({ payload }) {
  try {
    const result = yield call(api.updateTag, payload)
    yield put(updateTagAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push('/metadata/all/tags'))
  } catch (error) {
    yield put(updateTagAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteCommentSaga(payload) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      const { commentSlug, resourceSlug, resourceType } = payload
      yield call(api.deleteComment, commentSlug)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))

      const qs = createQueryString({
        resourceType,
        resourceSlug,
      })

      yield fork(handleGetAllCommentsSaga, qs)
    }
  } catch (error) {
    yield put(deleteCommentAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteTagSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteTag, payload)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))

      const { page, perPage } = parseQueryParams(global.location)

      const query = {
        page: page ? parseInt(page, 10) : DEFAULT_PAGE,
        perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      }

      const qs = createQueryString(query)

      yield fork(handleGetAllTagsSaga, qs)
    }
  } catch (error) {
    yield put(deleteTagAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetTagSaga({ payload }) {
  try {
    const result = yield call(api.getTagById, payload)
    yield put(getTagAction.success(result))
  } catch (error) {
    yield put(getTagAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllAvailableUserMentionsSaga({ payload }) {
  try {
    const result = yield call(api.getAllAvailbleUserMentions, payload)
    yield put(
      getAllAvailbleUserMentionsAction.success(
        result.map(x => ({
          id: x.slug,
          display: x.name,
        }))
      )
    )
  } catch (error) {
    yield put(getAllAvailbleUserMentionsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetTagSaga() {
  yield takeLatest(getTagAction.REQUEST, handleGetTagSaga)
}

function* watchGetAllCommentsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllCommentsAction.REQUEST)
    yield fork(handleGetAllCommentsSaga, qs)
  }
}

function* watchGetAllTagsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllTagsAction.REQUEST)
    yield fork(handleGetAllTagsSaga, qs)
  }
}

function* watchUpdateTagSaga() {
  yield takeLatest(updateTagAction.REQUEST, handleUpdateTagSaga)
}

function* watchDeleteTagSaga() {
  yield takeLatest(deleteTagAction.REQUEST, handleDeleteTagSaga)
}

function* watchCreateCommentSaga() {
  while (true) {
    const { payload } = yield take(createCommentAction.REQUEST)
    yield fork(handleCreateCommentSaga, payload)
  }
}

function* watchCreateTagSaga() {
  while (true) {
    const { payload } = yield take(createTagAction.REQUEST)
    yield fork(handleCreateTagSaga, payload)
  }
}

function* watchUpdateCommentSaga() {
  while (true) {
    const { payload } = yield take(updateCommentAction.REQUEST)
    yield fork(handleUpdateCommentSaga, payload)
  }
}

function* watchDeleteCommentSaga() {
  while (true) {
    const { payload } = yield take(deleteCommentAction.REQUEST)
    yield fork(handleDeleteCommentSaga, payload)
  }
}

function* watchGetAllAvailableUserMentionsSaga() {
  yield takeLatest(
    getAllAvailbleUserMentionsAction.REQUEST,
    handleGetAllAvailableUserMentionsSaga
  )
}

function* commentsSaga() {
  yield all([
    fork(watchGetAllCommentsSaga),
    fork(watchCreateCommentSaga),
    fork(watchUpdateCommentSaga),
    fork(watchDeleteCommentSaga),
    fork(watchCreateTagSaga),
    fork(watchGetAllTagsSaga),
    fork(watchDeleteTagSaga),
    fork(watchUpdateTagSaga),
    fork(watchGetTagSaga),
    fork(watchGetAllAvailableUserMentionsSaga),
  ])
}

export {
  commentsSaga as default,
  watchGetAllCommentsSaga,
  watchCreateCommentSaga,
  watchUpdateCommentSaga,
  watchDeleteCommentSaga,
  watchGetAllTagsSaga,
  watchCreateTagSaga,
  watchDeleteTagSaga,
  watchUpdateTagSaga,
  watchGetTagSaga,
  handleGetTagSaga,
  handleDeleteTagSaga,
  handleUpdateTagSaga,
  handleGetAllCommentsSaga,
  handleCreateCommentSaga,
  handleUpdateCommentSaga,
  handleDeleteCommentSaga,
  handleCreateTagSaga,
  handleGetAllTagsSaga,
  watchGetAllAvailableUserMentionsSaga,
}
