import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import moment from 'moment'
import UserPermissions from './UserPermissions'
import UserCommunicationLog from './UserCommunicationLog'
import { Spin, Table, Column, Tabs, TabPane } from './common/Ant'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import Button from './common/Button'
import Logo from './common/Logo'
import { Anchor, ActionAnchor } from './common/Anchor'
import { toTitleCase } from '../utils/textFormatters'
import Block from './common/Block'
import Checkbox from './common/Checkbox'
import TimeSelector from './common/TimeSelector'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { formatPhoneNumber } from '../utils/forms'
import { getPathSnippets } from '../utils/helpers'
import { dateTimeFormatter } from '../utils/date'
import { TIME_FORMAT, USER_PERMISSIONS, ROLES } from '../constants'

class User extends Component {
  static propTypes = {
    getUser: PropTypes.func.isRequired,
    userCommunicationLog: PropTypes.arrayOf(PropTypes.object).isRequired,
    getUserCommunicationLog: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    getUserPermissions: PropTypes.func.isRequired,
    updateUserPermissions: PropTypes.func.isRequired,
    toggleUserOption: PropTypes.func.isRequired,
    setDefaultSite: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    revokeApiToken: PropTypes.func.isRequired,
    getAccessTokenHistory: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    isUserLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
  }

  state = { activeTab: undefined }

  componentDidMount() {
    const {
      match: {
        params: { slug },
      },
      getUser,
      getUserPermissions,
    } = this.props

    getUser(slug)
    hasPermission(USER_PERMISSIONS.VIEW_USER_PERMISSIONS) &&
      getUserPermissions(slug)
  }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidUpdate(prevProps) {
    const {
      user: { slug, hasActiveApiToken },
      getAccessTokenHistory,
    } = this.props

    if (!prevProps.user.slug && slug && hasActiveApiToken) {
      getAccessTokenHistory(slug)
    }
  }

  handleTabSelection = entity => {
    const { updateQueryParams, match } = this.props

    if (entity !== match.params.slug) {
      this.setState({ activeTab: entity })
      updateQueryParams({ pathname: `${match.url}/${entity}` })
    } else {
      this.setState({ activeTab: 'details' })
    }
  }

  render() {
    const {
      user,
      match,
      toggleUserOption,
      setDefaultSite,
      isUserLoading,
      meta,
      isHistoryLoading,
      updateQueryParams,
      userCommunicationLog,
      getUserCommunicationLog,
      updateUserPermissions,
    } = this.props
    const {
      slug,
      fullName,
      companyName,
      companyLogo,
      role = '',
      email,
      phoneNumber,
      title,
      sites,
      createdAt,
      confirmedAt,
      smsNotifications,
      emailNotifications,
      systemNotifications,
      gatewaySystemNotifications,
      notificationsStartTime,
      notificationsEndTime,
      hasActiveApiToken,
      tokenHistory,
      stats: { lastSeen, lastLogIn },
    } = user

    const defaultStartTime = moment('00:00', TIME_FORMAT)
    const defaultEndTime = moment('23:59', TIME_FORMAT)

    return isUserLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="User">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/users">Users</Anchor>
              </Crumb>
              <Crumb>{fullName}</Crumb>
            </Bread>
          </div>
          {hasPermission(USER_PERMISSIONS.EDIT_USER) && (
            <div className="flex justify-center mb3">
              <Anchor to={`${match.url}/edit`}>Edit</Anchor>
            </div>
          )}
          <div className="flex justify-center">
            <Logo companyName={companyName} companyLogo={companyLogo} />
          </div>
        </div>
        <Switch>
          <Route
            path={`${match.url}/`}
            exact
            render={props => <Redirect to={`${match.url}/details`} />}
          />
          <>
            <Tabs
              defaultActiveKey="details"
              type="card"
              activeKey={this.state.activeTab}
              onTabClick={this.handleTabSelection}
            >
              <TabPane tab="Details" key="details">
                <Route
                  path={`${match.url}/details`}
                  exact
                  render={props => (
                    <>
                      {sites && sites.length > 0 && (
                        <>
                          <Table
                            dataSource={sites}
                            rowKey="siteSlug"
                            pagination={false}
                            size="small"
                            tableLayout="auto"
                          >
                            <Column
                              title="Subscribed Sites"
                              dataIndex="name"
                              render={(text, record) => (
                                <Anchor to={`/sites/${record.siteSlug}`}>
                                  {record.isDefault ? (
                                    <span className="b">{text}</span>
                                  ) : (
                                    text
                                  )}
                                </Anchor>
                              )}
                            />
                            <Column
                              title="Default"
                              render={(text, record) =>
                                record.isDefault ? (
                                  <span className="b">Default</span>
                                ) : (
                                  <ActionAnchor
                                    onClick={() =>
                                      setDefaultSite({
                                        userSlug: slug,
                                        siteSlug: record.siteSlug,
                                      })
                                    }
                                  >
                                    Set as default
                                  </ActionAnchor>
                                )
                              }
                            />
                          </Table>
                        </>
                      )}
                      <Divider />
                      <div className="flex flex-wrap">
                        <Block label="Phone">
                          <a
                            className="dark-blue link"
                            href={`tel:${phoneNumber}`}
                          >
                            {formatPhoneNumber(phoneNumber)}
                          </a>
                        </Block>
                        <Block label="Email">
                          <a
                            className="dark-blue link"
                            href={`mailto:${email}`}
                          >
                            {email}
                          </a>
                        </Block>
                        <Block label="Role">{toTitleCase(role)}</Block>
                        <Block label="Job Title">{title}</Block>
                        <Block label="Last Seen">
                          {dateTimeFormatter(lastSeen, true)}
                        </Block>
                        <Block label="Last Login">
                          {dateTimeFormatter(lastLogIn, true)}
                        </Block>
                        <Block label="Created">
                          {dateTimeFormatter(createdAt, true)}
                        </Block>
                        <Block label="Confirmed">
                          {dateTimeFormatter(confirmedAt, true)}
                        </Block>
                      </div>
                      <Divider />
                      <div className="flex-l">
                        <div className="w-100 w-25-l mr2-ns">
                          <Checkbox
                            type="checkbox"
                            label="SMS Notifications"
                            input={{
                              checked: smsNotifications,
                              onChange: value =>
                                toggleUserOption({
                                  ...user,
                                  smsNotifications: value,
                                }),
                            }}
                          />
                        </div>
                        <div className="w-100 w-25-l mr2-ns">
                          <Checkbox
                            type="checkbox"
                            label="Email Notifications"
                            input={{
                              checked: emailNotifications,
                              onChange: value =>
                                toggleUserOption({
                                  ...user,
                                  emailNotifications: value,
                                }),
                            }}
                          />
                        </div>
                        <div className="w-100 w-25-l">
                          <Checkbox
                            type="checkbox"
                            label="Offline Smart Pod Notifications"
                            input={{
                              checked: systemNotifications,
                              onChange: value =>
                                toggleUserOption({
                                  ...user,
                                  systemNotifications: value,
                                }),
                            }}
                          />
                        </div>
                        <div className="w-100 w-25-l">
                          <Checkbox
                            type="checkbox"
                            label="Offline Network Gateway Notifications"
                            input={{
                              checked: gatewaySystemNotifications,
                              onChange: value =>
                                toggleUserOption({
                                  ...user,
                                  gatewaySystemNotifications: value,
                                }),
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex-l">
                        <div className="w-100 w-25-l mr2-ns">
                          <TimeSelector
                            label="Notifications Start Time"
                            input={{
                              value: notificationsStartTime
                                ? moment(notificationsStartTime, TIME_FORMAT)
                                : defaultStartTime,
                              onChange: value =>
                                toggleUserOption({
                                  ...user,
                                  notificationsStartTime: value.format(
                                    'HH:mm:ss'
                                  ),
                                }),
                            }}
                            format={TIME_FORMAT}
                          />
                        </div>
                        <div className="w-100 w-25-l">
                          <TimeSelector
                            label="Notifications End Time"
                            input={{
                              value: notificationsEndTime
                                ? moment(notificationsEndTime, TIME_FORMAT)
                                : defaultEndTime,
                              onChange: value =>
                                toggleUserOption({
                                  ...user,
                                  notificationsEndTime: value.format(
                                    'HH:mm:ss'
                                  ),
                                }),
                            }}
                            format={TIME_FORMAT}
                          />
                        </div>
                      </div>

                      {hasActiveApiToken && (
                        <div>
                          <Divider />
                          <div className="mb3">
                            <p>
                              {fullName} has developer access and an active API
                              access token.
                            </p>
                            <Button
                              onClick={() => this.props.revokeApiToken(slug)}
                              text="Revoke API Token"
                            />
                          </div>

                          <Table
                            dataSource={tokenHistory}
                            rowKey="token"
                            pagination={false}
                            size="small"
                            tableLayout="auto"
                          >
                            <Column
                              title="Developer API Token History"
                              dataIndex="token"
                            />
                            <Column
                              title="Created At"
                              dataIndex="createdAt"
                              render={text =>
                                text ? dateTimeFormatter(text) : '--'
                              }
                            />
                            <Column
                              title="Revoked At"
                              dataIndex="revokedAt"
                              render={text =>
                                text ? dateTimeFormatter(text) : '--'
                              }
                            />
                            <Column title="Revoked By" dataIndex="revokedBy" />
                          </Table>
                        </div>
                      )}
                    </>
                  )}
                />
              </TabPane>
              {hasPermission(USER_PERMISSIONS.EDIT_USER_PERMISSIONS) && (
                <TabPane tab="Permissions" key="permissions">
                  <Route
                    path={`${match.url}/permissions`}
                    exact
                    render={props => (
                      <UserPermissions
                        updateUserPermissions={permissions =>
                          updateUserPermissions(slug, permissions)
                        }
                        permissions={user.permissions}
                        allowedPermissions={user.allowedPermissions}
                      />
                    )}
                  />
                </TabPane>
              )}
              {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                <TabPane tab="Communication Log" key="communication-log">
                  <Route
                    path={`${match.url}/communication-log`}
                    exact
                    render={props => (
                      <div>
                        <UserCommunicationLog
                          slug={slug}
                          userCommunicationLog={userCommunicationLog}
                          getUserCommunicationLog={getUserCommunicationLog}
                          meta={meta}
                          isLoading={isHistoryLoading}
                          updateQueryParams={updateQueryParams}
                        />
                      </div>
                    )}
                  />
                </TabPane>
              )}
            </Tabs>
          </>
        </Switch>
      </section>
    )
  }
}

export default User
