import React from 'react'
import PropTypes from 'prop-types'

const PODIcon = ({ color }) => {
  return (
    <div className="PODIcon">
      <div
        className={`b--${color}`}
        style={{
          borderBottomWidth: '0.25rem',
          borderBottomStyle: 'solid',
          borderLeft: '0.2rem solid transparent',
          borderRight: '0.2rem solid transparent',
          height: 0,
          width: '1.25rem',
        }}
      ></div>
      <div
        className={`h1 bg-${color} flex items-center justify-center`}
        style={{
          width: '1.25rem',
        }}
      >
        <span className="b white">S</span>
      </div>
      <div
        className={`b--${color}`}
        style={{
          borderTopWidth: '0.25rem',
          borderTopStyle: 'solid',
          borderLeft: '0.2rem solid transparent',
          borderRight: '0.2rem solid transparent',
          height: 0,
          width: '1.25rem',
        }}
      ></div>
    </div>
  )
}

PODIcon.propTypes = {
  color: PropTypes.string.isRequired,
}

export default PODIcon
