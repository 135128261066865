import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  getAllInvoicesAction,
  createInvoiceAction,
  updateInvoiceAction,
  deleteInvoiceAction,
  updateSiteAction,
} from '../actions/sitesActions'
import { getSiteInvoices } from '../reducers/sitesReducer'
import { isSiteLoading } from '../reducers/uiReducer'
import InvoiceUpload from './InvoiceUpload'
import SiteDocuments from './SiteDocuments'
import { Bread, Crumb } from './common/Bread'
import Select, { Option } from './common/Select'
import { Spin, Table, Column, Menu, Dropdown, Icon, Modal } from './common/Ant'
import Divider from './common/Divider'
import Form from './common/Form'
import Input from './common/Input'
import FormError from './common/FormError'
import Button from './common/Button'
import DateSelector from './common/DateSelector'
import InlineInput from './common/InlineInput'
import { Anchor, ActionAnchor } from './common/Anchor'
import { H3 } from './common/Headers'
import { dateFormatter } from '../utils/date'
import { currencyFormatter } from '../utils/number'
import { createQueryString } from '../utils/queryParams'
import { ALL, SITE_DOCUMENT_TYPES } from '../constants'

const mapStateToProps = ({ sites, ui }) => ({
  invoices: getSiteInvoices(sites),
  isLoading: isSiteLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllInvoices: payload => dispatch(getAllInvoicesAction.request(payload)),
  createInvoice: payload => dispatch(createInvoiceAction.request(payload)),
  updateInvoice: payload => dispatch(updateInvoiceAction.request(payload)),
  deleteInvoice: payload => dispatch(deleteInvoiceAction.request(payload)),
  updateSite: payload =>
    dispatch(updateSiteAction.request({ ...payload, ignorePush: true })),
})

const Invoices = ({
  getAllCompanies,
  getAllInvoices,
  createInvoice,
  updateInvoice,
  deleteInvoice,
  uploadDocument,
  updateSite,
  siteSlug,
  currentSite,
  companies,
  invoices,
  isLoading,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [slug, setSlug] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [amount, setAmount] = useState(0)
  const [notes, setNotes] = useState('')
  const [invoiceDate, setInvoiceDate] = useState(moment())
  const [paymentDate, setPaymentDate] = useState(null)
  const [error, setError] = useState('')
  const [contractValue, setContractValue] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    getAllInvoices(siteSlug)
    getAllCompanies(createQueryString({ perPage: ALL }))
  }, [getAllInvoices, siteSlug, getAllCompanies])

  useEffect(() => {
    if (currentSite.contractValue) {
      setContractValue(currentSite.contractValue)
    }
  }, [currentSite.contractValue])

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const handleContractValueSubmit = () => {
    updateSite({ ...currentSite, contractValue })
    setIsEditing(false)
  }

  const handleSubmit = ev => {
    if (!companyId) {
      return setError('Payer is required.')
    } else if (!amount) {
      return setError('Amount is required.')
    } else if (!invoiceDate) {
      return setError('Invoice Date is required.')
    }

    if (!slug) {
      createInvoice({
        siteSlug,
        invoice: {
          companyId,
          amount,
          notes,
          invoiceDate: invoiceDate.toISOString(),
          paymentDate: paymentDate ? paymentDate.toISOString() : null,
        },
      })
    } else {
      updateInvoice({
        siteSlug,
        invoice: {
          slug,
          companyId,
          amount,
          notes,
          invoiceDate: invoiceDate.toISOString(),
          paymentDate: paymentDate ? paymentDate.toISOString() : null,
        },
      })
    }

    handleModalCancel()
    ev.preventDefault()
  }

  const handleCreate = () => {
    setSlug('')
    setCompanyId('')
    setAmount(0)
    setNotes('')
    setInvoiceDate(moment())
    setPaymentDate(null)

    setIsModalVisible(true)
  }

  const handleEdit = ({
    slug,
    companyId,
    amount,
    notes,
    invoiceDate,
    paymentDate,
  }) => {
    setSlug(slug)
    setCompanyId(companyId)
    setAmount(amount)
    setNotes(notes)
    setInvoiceDate(moment(invoiceDate))
    setPaymentDate(paymentDate ? moment(paymentDate) : null)

    setIsModalVisible(true)
  }

  return isLoading ? (
    <Spin size="large" className="w-100 center mv5" />
  ) : (
    <div className="Invoices mb5">
      <div className="flex justify-center mb3">
        <div>
          <Bread>
            <Crumb>Contract Value</Crumb>
          </Bread>
          {isEditing ? (
            <>
              <InlineInput
                value={contractValue}
                setValue={setContractValue}
                label="Contract Value"
                type="number"
              />
              <ActionAnchor onClick={handleContractValueSubmit}>
                Save
              </ActionAnchor>
            </>
          ) : (
            <div className="flex justify-center items-center">
              <div className="mr2 f3 b i">
                {currencyFormatter(contractValue)}
              </div>
              <ActionAnchor onClick={() => setIsEditing(true)}>
                Edit
              </ActionAnchor>
            </div>
          )}
        </div>
      </div>
      <Divider />
      <div>
        <SiteDocuments
          siteSlug={siteSlug}
          uploadDocument={uploadDocument}
          isBillingType
        />
      </div>
      <div className="mb3">
        <div className="flex justify-center tc mb3">
          <div>
            <Bread>
              <Crumb>Invoices</Crumb>
            </Bread>
            <ActionAnchor onClick={handleCreate}>Create Invoice</ActionAnchor>
          </div>
        </div>
        <Table
          dataSource={invoices}
          rowKey="slug"
          pagination={false}
          tableLayout="auto"
          size="small"
        >
          <Column title="Payer" dataIndex="companyName" />
          <Column
            title="Issue Date"
            dataIndex="invoiceDate"
            render={text => dateFormatter(text)}
          />
          <Column
            title="Amount"
            dataIndex="amount"
            render={x => currencyFormatter(x)}
          />
          <Column
            title="Payment Date"
            dataIndex="paymentDate"
            render={text => (text ? dateFormatter(text) : '--')}
          />
          <Column title="Notes" dataIndex="notes" />
          <Column
            title="Invoice Upload"
            dataIndex="invoiceUrl"
            render={(text, record) => {
              return text ? <Anchor to={text}>View</Anchor> : '--'
            }}
          />
          <Column
            width={100}
            render={(text, record) => {
              const menu = (
                <Menu>
                  <Menu.Item key="upload-invoice">
                    <InvoiceUpload
                      uploadDocument={uploadDocument}
                      siteSlug={siteSlug}
                      invoiceSlug={record.slug}
                      documentType={SITE_DOCUMENT_TYPES.INVOICE}
                      isBillingType
                    />
                  </Menu.Item>
                  <Menu.Item key="edit-invoice">
                    <ActionAnchor onClick={() => handleEdit(record)}>
                      Edit
                    </ActionAnchor>
                  </Menu.Item>
                  <Menu.Item key="delete-invoice">
                    <ActionAnchor
                      onClick={() =>
                        deleteInvoice({ siteSlug, slug: record.slug })
                      }
                    >
                      Delete
                    </ActionAnchor>
                  </Menu.Item>
                </Menu>
              )

              return (
                <Dropdown overlay={menu} trigger={['click']}>
                  <a className="ant-dropdown-link">
                    Actions <Icon type="down" />
                  </a>
                </Dropdown>
              )
            }}
          />
        </Table>
      </div>
      <Modal
        className="Modal"
        visible={isModalVisible}
        footer={null}
        onCancel={handleModalCancel}
        destroyOnClose
      >
        <Form onSubmit={handleSubmit}>
          <H3>{!slug ? 'Create' : 'Update'} Invoice</H3>

          <Select
            className="w-33-l mr3-l"
            label="Payer"
            placeholder="Select an payer"
            input={{
              value: companyId,
              onChange: setCompanyId,
            }}
            filterable
          >
            {companies.map(x => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
          <DateSelector
            className="w-50-l mr3-l"
            label="Issue Date"
            input={{
              value: invoiceDate,
              onChange: x => setInvoiceDate(x),
            }}
            disableTime
            allowClear={false}
          />
          <Input
            label="Amount"
            input={{
              value: amount,
              onChange: value => setAmount(value),
            }}
            type="number"
          />
          <DateSelector
            className="w-50-l mr3-l"
            label="Payment Date"
            input={{
              value: paymentDate,
              onChange: x => setPaymentDate(x),
            }}
            disableTime
            allowClear
          />
          <Input
            label="Notes"
            input={{
              value: notes,
              onChange: ev => setNotes(ev.currentTarget.value),
            }}
          />
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <Button text="Submit" onClick={handleSubmit} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

Invoices.propTypes = {
  getAllCompanies: PropTypes.func.isRequired,
  getAllInvoices: PropTypes.func.isRequired,
  createInvoice: PropTypes.func.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  updateSite: PropTypes.func.isRequired,
  siteSlug: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentSite: PropTypes.object.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
