import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useLastLocation } from 'react-router-last-location'
import ScheduleReport from './ScheduleReport'
import AllReportConfigs from './AllReportConfigs'
import AllAlertSummaries from './AllAlertSummaries'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import { Spin, Table, Column, Modal, Tabs, TabPane } from './common/Ant'
import Divider from './common/Divider'
import Select, { Option } from './common/Select'
import { createQueryString } from '../utils/queryParams'
import { dateFormatter, dateTimeFormatter } from '../utils/date'
import { renderReportType } from '../utils/textFormatters'
import { getPathSnippetAtIndex, configurePagination } from '../utils/helpers'
import { hasPermission, hasRole } from '../utils/hasPermission'
import {
  USER_PERMISSIONS,
  VIEW_ALL,
  ALL,
  REPORT_TYPES,
  ROLES,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
} from '../constants'

const TABS = {
  REPORTS: 'reports',
  CONFIGS: 'configs',
  ALERT_SUMMARIES: 'alert-summaries',
}

const handleViewAll = fn => arr => fn(arr.indexOf(ALL) !== -1 ? [] : arr)

const AllReports = ({
  getAllReports,
  getAllReportConfigs,
  updateReportConfig,
  deleteReportConfig,
  reports,
  reportConfigs,
  isLoading,
  match,
  sites,
  floors,
  locationGroups,
  buildings,
  locations,
  siteUsers,
  getAllSites,
  getAllFloors,
  getAllBuildings,
  getAllUsersBySite,
  getAllLocationGroups,
  createReportConfig,
  getAllLocationsBySite,
  getAllAlertSummaries,
  deleteAlertSummary,
  alertSummaries,
  alertSummariesMeta,
  isAlertSummaryLoading,
  meta,
}) => {
  const [reportConfig, setReportConfig] = useState(null)
  const [activeTab, setActiveTab] = useState(TABS.REPORTS)

  const [siteSlug, setSiteSlug] = useState([])
  const [reportType, setReportType] = useState([])

  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [page, setPage] = useState(DEFAULT_PAGE)

  const history = useHistory()
  const location = useLocation()
  const lastLocation = useLastLocation()

  const handleChange = (pagination, filters, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current)

      // updateQueryParams({
      //   search: createQueryString(query),
      // })
    }
  }

  useEffect(() => {
    const qs = createQueryString({ perPage })

    getAllSites(qs)
  }, [getAllSites, perPage])

  useEffect(() => {
    getAllReports(createQueryString({ page, perPage, siteSlug, reportType }))
  }, [siteSlug, reportType, getAllReports, page, perPage])

  useEffect(() => {
    const tab = getPathSnippetAtIndex(location.pathname, 1)

    if (tab === TABS.CONFIGS || tab === TABS.ALERT_SUMMARIES) {
      setActiveTab(tab)
    } else {
      setActiveTab(TABS.REPORTS)
    }
  }, [location.pathname])

  const handleTabSelection = tab => {
    setActiveTab(tab)

    history.push(tab === TABS.REPORTS ? '/reports' : `/reports/${tab}`)
  }

  const handleSetReportConfig = x => {
    setReportConfig(x)

    history.push('/reports/configs/schedule')
  }

  const handleModalCancel = () => {
    setReportConfig(null)
    if (lastLocation.pathname === '/reports/configs/schedule') {
      history.push('/reports/configs')
    } else {
      history.goBack()
    }
  }

  return isLoading ? (
    <Spin size="large" className="w-100 center mv5" />
  ) : (
    <section className="mb5 AllReports">
      <div className="mb3">
        <Bread>
          <Crumb>Reports</Crumb>
        </Bread>
      </div>
      {hasPermission(USER_PERMISSIONS.REQUEST_REPORT) && (
        <div className="flex flex-wrap justify-center mb3">
          <span>
            <Anchor to={`${match.url}/generate`}>
              Generate Historical Report
            </Anchor>
          </span>
          {hasPermission(USER_PERMISSIONS.SCHEDULE_REPORT) && (
            <span>
              <Divider vertical />
              <Anchor to={`${match.url}/configs/schedule`}>
                Schedule Recurring Report
              </Anchor>
            </span>
          )}
          {hasRole(ROLES.SUPER_ADMIN) && (
            <span>
              <Divider vertical />
              <Anchor to="/reports/alert-summaries/create">
                Schedule Alert Summary
              </Anchor>
            </span>
          )}
        </div>
      )}
      <div className="mb5">
        <Tabs
          type="card"
          defaultActiveKey={TABS.REPORTS}
          activeKey={activeTab}
          onTabClick={handleTabSelection}
        >
          <TabPane tab="Generated Reports" key={TABS.REPORTS}>
            <Route
              path={`${match.url}/`}
              render={props => (
                <>
                  <div className="flex-l items-center-l">
                    <Select
                      className="w-50-l mr3-l"
                      placeholder="Site"
                      mode="multiple"
                      input={{
                        value: siteSlug,
                        onChange: handleViewAll(setSiteSlug),
                      }}
                      filterable
                    >
                      {[{ slug: ALL, name: VIEW_ALL }, ...sites].map(x => (
                        <Option value={x.slug} key={x.slug}>
                          {x.name}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      className="w-50-l"
                      placeholder="Report Type"
                      mode="multiple"
                      input={{
                        value: reportType,
                        onChange: handleViewAll(setReportType),
                      }}
                      filterable
                    >
                      {[ALL, ...Object.keys(REPORT_TYPES)].map(x => (
                        <Option value={x} key={x}>
                          {x === ALL
                            ? VIEW_ALL
                            : renderReportType(REPORT_TYPES[x])}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="mb3">
                    <Table
                      dataSource={reports}
                      rowKey="slug"
                      onChange={handleChange}
                      pagination={configurePagination({
                        perPage,
                        onShowSizeChange: (page, perPage) =>
                          setPerPage(perPage),
                        ...meta,
                      })}
                      size="small"
                      tableLayout="auto"
                      scroll={{ x: 815 }}
                    >
                      <Column
                        title="Report Name"
                        dataIndex="name"
                        render={(text, record) => {
                          const reportUrl = `/reports/${record.url}/view`

                          return (
                            <Anchor to={reportUrl} target="_blank">
                              {text
                                ? text
                                : dateTimeFormatter(record.createdAt)}
                            </Anchor>
                          )
                        }}
                      />
                      <Column
                        title="Site"
                        dataIndex="siteName"
                        render={(text, record) => (
                          <Anchor to={`/sites/${record.siteSlug}`}>
                            {text}
                          </Anchor>
                        )}
                      />
                      <Column
                        title="Type"
                        dataIndex="reportType"
                        render={text => renderReportType(text)}
                      />
                      <Column
                        title="Start"
                        dataIndex="startTime"
                        render={text => dateFormatter(text)}
                      />
                      <Column
                        title="End"
                        dataIndex="endTime"
                        render={text => dateFormatter(text)}
                      />
                      <Column title="Created By" dataIndex="userName" />
                      <Column
                        title="Public Link Expiration Date"
                        dataIndex="expiresAt"
                        render={text => dateFormatter(text)}
                      />
                    </Table>
                  </div>
                </>
              )}
            />
          </TabPane>

          {hasPermission(USER_PERMISSIONS.SCHEDULE_REPORT) && (
            <TabPane tab="Scheduled Report Configurations" key={TABS.CONFIGS}>
              <Route
                path={`${match.url}/configs`}
                render={props => (
                  <AllReportConfigs
                    getAllReportConfigs={getAllReportConfigs}
                    deleteReportConfig={deleteReportConfig}
                    reportConfigs={reportConfigs}
                    isLoading={isLoading}
                    setReportConfig={handleSetReportConfig}
                  />
                )}
              />
            </TabPane>
          )}
          {hasRole(ROLES.SUPER_ADMIN) && (
            <TabPane tab="Alert Summaries" key={TABS.ALERT_SUMMARIES}>
              <Route
                path={`${match.url}/alert-summaries`}
                render={props => (
                  <>
                    <Helmet>
                      <title>Alert Summaries</title>
                    </Helmet>
                    <Divider />
                    <AllAlertSummaries
                      getAllAlertSummaries={getAllAlertSummaries}
                      deleteAlertSummary={deleteAlertSummary}
                      alertSummaries={alertSummaries}
                      meta={alertSummariesMeta}
                      isLoading={isAlertSummaryLoading}
                      {...props}
                    />
                  </>
                )}
              />
            </TabPane>
          )}
        </Tabs>
      </div>

      <Route
        path={`${match.url}/configs/schedule`}
        exact
        render={props => (
          <>
            <Helmet>
              <title>Schedule Report</title>
            </Helmet>
            <Modal
              className="Modal"
              visible
              footer={null}
              onCancel={handleModalCancel}
              destroyOnClose
            >
              <ScheduleReport
                getAllSites={getAllSites}
                getAllFloors={getAllFloors}
                getAllBuildings={getAllBuildings}
                getAllUsersBySite={getAllUsersBySite}
                createReportConfig={createReportConfig}
                updateReportConfig={updateReportConfig}
                getAllLocationsBySite={getAllLocationsBySite}
                getAllLocationGroups={getAllLocationGroups}
                sites={sites}
                floors={floors}
                locationGroups={locationGroups}
                buildings={buildings}
                locations={locations}
                siteUsers={siteUsers}
                reportConfig={reportConfig}
              />
            </Modal>
          </>
        )}
      />
    </section>
  )
}

AllReports.propTypes = {
  getAllReports: PropTypes.func.isRequired,
  getAllReportConfigs: PropTypes.func.isRequired,
  updateReportConfig: PropTypes.func.isRequired,
  deleteReportConfig: PropTypes.func.isRequired,
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  getAllSites: PropTypes.func.isRequired,
  getAllFloors: PropTypes.func.isRequired,
  getAllBuildings: PropTypes.func.isRequired,
  getAllUsersBySite: PropTypes.func.isRequired,
  createReportConfig: PropTypes.func.isRequired,
  getAllLocationsBySite: PropTypes.func.isRequired,
  getAllLocationGroups: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllAlertSummaries: PropTypes.func.isRequired,
  deleteAlertSummary: PropTypes.func.isRequired,
  alertSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  alertSummariesMeta: PropTypes.object.isRequired,
  isAlertSummaryLoading: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
}

export default AllReports
