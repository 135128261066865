import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllWorkflowsAction,
  getWorkflowAction,
} from '../actions/workflowsActions'

const bySlug = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllWorkflowsAction.SUCCESS:
        payload.forEach(workflow => {
          draft[workflow.slug] = workflow
        })
        break
    }
  })

const visibleWorkflows = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllWorkflowsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(workflow => workflow.slug)
        )
        break
    }
  })

const currentWorkflow = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getWorkflowAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const workflowsReducer = combineReducers({
  bySlug,
  visibleWorkflows,
  currentWorkflow,
})

const getWorkflow = (state, slug) => state.bySlug[slug]
const getCurrentWorkflow = state => state.currentWorkflow
const getVisibleWorkflows = state =>
  state.visibleWorkflows.map(slug => getWorkflow(state, slug))

export { workflowsReducer as default, getVisibleWorkflows, getCurrentWorkflow }
