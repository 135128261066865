import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { dateTimeFormatter } from '../utils/date'
import { Table, Column, Spin } from './common/Ant'
import { formatSIMCarrier } from '../utils/textFormatters'
import { SIM_CARRIERS } from '../constants'

class GatewayRestartHistory extends Component {
  componentDidMount() {
    const { getGatewayRestartHistory, pillarId } = this.props

    getGatewayRestartHistory(pillarId)
  }

  render() {
    const { gatewayRestartHistory, isLoading } = this.props

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : gatewayRestartHistory.length ? (
      <Table
        dataSource={gatewayRestartHistory}
        rowKey="id"
        scroll={{ x: 350 }}
        pagination={false}
        tableLayout="auto"
        bordered
      >
        <Column
          title="Date Restarted"
          width={100}
          dataIndex="restartedAt"
          render={(text, record) => (text ? dateTimeFormatter(text) : '--')}
        />
        <Column title="Site" width={100} dataIndex="siteName" />
        <Column
          title="Status"
          width={100}
          dataIndex="status"
          render={text => (text ? 'Successful' : 'Failed')}
        />
        <Column
          title="Carrier"
          width={100}
          dataIndex="simType"
          render={text => (text ? formatSIMCarrier(SIM_CARRIERS[text]) : '--')}
        />
        <Column
          title="Restarted By"
          width={100}
          dataIndex="userName"
          render={(text, record) => (text ? text : '--')}
        />
      </Table>
    ) : (
      <p className="tc">No Restart History</p>
    )
  }
}

GatewayRestartHistory.propTypes = {
  gatewayRestartHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  getGatewayRestartHistory: PropTypes.func.isRequired,
  pillarId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default GatewayRestartHistory
