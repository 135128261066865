import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import { DatePicker } from './Ant'
import Meta from './Meta'
import Label from './Label'

const normalizeDate = val => (val ? val.format('YYYY-MM-DD') : null)
const normalizeDateTime = val => (val ? val.format('YYYY-MM-DD HH:mm') : null)
const normalizeDateTimeISO = val => (val ? val.toISOString() : null)
const formatDateUTC = val => (val ? moment(val).utc() : null)
const formatDate = val => (val ? moment(val) : null)

const shouldDisableDate = (
  current,
  dates,
  disabledDateRangeType,
  overrideDate
) => {
  const today = overrideDate ? moment(overrideDate) : moment()
  if (disabledDateRangeType === 'before') {
    return current && current.isBefore(today.startOf('day'))
  } else if (disabledDateRangeType === 'after') {
    return current && current.isAfter(today.endOf('day'))
  }
  return current && dates.filter(date => current.isSame(date, 'day')).length
}

const shouldDisableDateRange = (current, overrideDateRange) => {
  const startDay = moment(overrideDateRange[0])
  const endDay = overrideDateRange[1]
  if (!endDay) {
    return current && current.isBefore(startDay.startOf('day'))
  }
  return (
    current &&
    current.isAfter(startDay.startOf('day')) &&
    current.isBefore(moment(endDay).endOf('day'))
  )
}

const DateSelector = ({
  input,
  label,
  meta,
  overrideDate,
  overrideDateRange = [],
  requiredFields,
  className = '',
  dateView = 'MMM D, YYYY',
  disabledDateRangeType = '',
  disabledDates = [],
  disableTime = false,
  allowClear,
  disabled,
}) => {
  const dateSelectorClassNames = cx('DateSelector mb3', className)

  return (
    <div className={dateSelectorClassNames}>
      <Label>
        {label}
        {requiredFields && requiredFields.indexOf(input.name) > -1 && (
          <span className="red"> *</span>
        )}
      </Label>
      <DatePicker
        className="db w-100 input-reset"
        value={input.value}
        format={dateView}
        onChange={input.onChange}
        disabledDate={current => {
          if (overrideDateRange.length) {
            return shouldDisableDateRange(current, overrideDateRange)
          } else {
            return shouldDisableDate(
              current,
              disabledDates,
              disabledDateRangeType,
              overrideDate
            )
          }
        }}
        showTime={
          !disableTime && { defaultValue: moment('00:00:00', 'HH:mm:ss') }
        }
        allowClear={allowClear}
        disabled={disabled}
      />
      <Meta {...meta} />
    </div>
  )
}

DateSelector.propTypes = {
  input: PropTypes.object.isRequired,
  disabledDates: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  label: PropTypes.string,
  overrideDate: PropTypes.string,
  overrideDateRange: PropTypes.arrayOf(PropTypes.string),
  disabledDateRangeType: PropTypes.string,
  className: PropTypes.string,
  dateView: PropTypes.string,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  disableTime: PropTypes.bool.isRequired,
  allowClear: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
}

DateSelector.defaultProps = {
  disabled: false,
  allowClear: true,
}

export {
  DateSelector as default,
  normalizeDate,
  normalizeDateTime,
  normalizeDateTimeISO,
  formatDateUTC,
  formatDate,
}
