import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Anchor, ActionAnchor } from './common/Anchor'
import {
  Spin,
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Modal,
  Badge,
  Tooltip,
} from './common/Ant'
import Divider from './common/Divider'
import { H3 } from './common/Headers'
import { createQueryString } from '../utils/queryParams'
import { dateFormatter, dateTimeFormatter } from '../utils/date'
import { toTitleCase, renderReportType } from '../utils/textFormatters'
import { REPORT_SEND_DAYS, DAYS_INTERVALS } from '../constants'

const renderReportSendDay = x => {
  const text = Object.keys(REPORT_SEND_DAYS).filter(
    y => REPORT_SEND_DAYS[y] === x
  )

  return !!text.length ? toTitleCase(text[0]) : '--'
}

const renderDaysInterval = x => {
  const text = Object.keys(DAYS_INTERVALS).filter(y => DAYS_INTERVALS[y] === x)

  return !!text.length ? toTitleCase(text[0]) : '--'
}

const AllReportConfigs = ({
  getAllReportConfigs,
  deleteReportConfig,
  setReportConfig,
  reportConfigs,
  isLoading,
}) => {
  useEffect(() => {
    getAllReportConfigs(createQueryString({ perPage: 'all' }))
  }, [getAllReportConfigs])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [reportName, setReportName] = useState('')
  const [upcomingSchedules, setUpcomingSchedules] = useState([])

  const handleModalOpen = reportConfig => {
    setReportName(reportConfig.name)
    setUpcomingSchedules(reportConfig.upcomingSchedules)
    setIsModalVisible(true)
  }

  const handleModalCancel = () => {
    setReportName('')
    setUpcomingSchedules([])
    setIsModalVisible(false)
  }

  return isLoading ? (
    <Spin size="large" className="w-100 center mv5" />
  ) : (
    <section className="mb5 AllReportConfigs">
      <Table
        dataSource={reportConfigs}
        rowKey="slug"
        pagination={false}
        size="small"
        scroll={{ x: 815 }}
        tableLayout="auto"
      >
        <Column
          title="Config Name"
          dataIndex="name"
          render={(text, record) => (
            <span>
              <Tooltip title={record.active ? 'Active' : 'Inactive'}>
                <Badge status={record.active ? 'success' : 'default'} />
              </Tooltip>
              {text}
            </span>
          )}
        />
        <Column
          title="Site"
          dataIndex="siteName"
          render={(text, record) => (
            <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
          )}
        />
        <Column
          title="Type"
          dataIndex="reportType"
          render={text => renderReportType(text)}
        />
        <Column title="Created By" dataIndex="userName" />
        <Column
          title="Last Sent"
          dataIndex="lastRunTime"
          render={text => (text ? dateFormatter(text) : '--')}
        />
        <Column
          title="Next Run Time"
          dataIndex="nextRunTime"
          render={(text, record) => {
            if (record.upcomingSchedules.length > 0) {
              return (
                <span>
                  {dateFormatter(text)} (
                  <ActionAnchor onClick={() => handleModalOpen(record)}>
                    View Upcoming Schedule
                  </ActionAnchor>
                  )
                </span>
              )
            } else {
              return '--'
            }
          }}
        />
        <Column
          title="Repeat Until"
          dataIndex="repeatUntil"
          render={text => dateFormatter(text)}
        />
        <Column
          title="Delivery Day"
          dataIndex="reportSendDay"
          render={text => renderReportSendDay(text)}
        />
        <Column
          title="Report Interval"
          dataIndex="daysInterval"
          render={text => renderDaysInterval(text)}
        />
        <Column
          width={100}
          render={(text, record) => {
            const menu = (
              <Menu>
                <Menu.Item key="edit-network">
                  <ActionAnchor onClick={() => setReportConfig(record)}>
                    Edit
                  </ActionAnchor>
                </Menu.Item>
                <Menu.Item key="delete-network">
                  <ActionAnchor onClick={() => deleteReportConfig(record.slug)}>
                    Delete
                  </ActionAnchor>
                </Menu.Item>
              </Menu>
            )

            return (
              <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link">
                  Actions <Icon type="down" />
                </a>
              </Dropdown>
            )
          }}
        />
      </Table>

      <Modal
        className="Modal"
        visible={isModalVisible}
        footer={null}
        onCancel={handleModalCancel}
        destroyOnClose
      >
        <div>
          <H3>Next Three Run Times for {reportName}</H3>
          {upcomingSchedules.map((x, i) => (
            <div>
              <div className="flex">
                <div className="b w-30-ns">Run Time:</div>
                <div>{dateTimeFormatter(x.runTime)}</div>
              </div>
              <div className="flex">
                <div className="b w-30-ns">Report Start Time:</div>
                <div>{dateTimeFormatter(x.startTime)}</div>
              </div>
              <div className="flex mb3">
                <div className="b w-30-ns">Report End Time:</div>
                <div>{dateTimeFormatter(x.endTime)}</div>
              </div>
              {upcomingSchedules.length > i + 1 && <Divider />}
            </div>
          ))}
        </div>
      </Modal>
    </section>
  )
}

AllReportConfigs.propTypes = {
  getAllReportConfigs: PropTypes.func.isRequired,
  deleteReportConfig: PropTypes.func.isRequired,
  setReportConfig: PropTypes.func.isRequired,
  reportConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default AllReportConfigs
