import { createFormAction } from 'redux-form-saga'

const getAllPartsAction = createFormAction('GET_ALL_PARTS')

/* For the future if we'd like to see all Parts we can assign. */
const getAllAvailablePartsAction = createFormAction('GET_ALL_AVAILABLE_PARTS')
const getPartAction = createFormAction('GET_PART')
const createPartAction = createFormAction('CREATE_PART')
const updatePartAction = createFormAction('UPDATE_PART')
const deletePartAction = createFormAction('DELETE_PART')

export {
  getAllPartsAction,
  getAllAvailablePartsAction,
  getPartAction,
  createPartAction,
  updatePartAction,
  deletePartAction,
}
