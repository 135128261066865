import React from 'react'
import PropTypes from 'prop-types'
import { dateTimeFormatter } from '../../utils/date'

const LastUpdated = ({ user, updatedAt }) => (
  <div>
    Last updated{!!user.firstName && ` by ${user.firstName} ${user.lastName}`}{' '}
    on {dateTimeFormatter(updatedAt)}.
  </div>
)

LastUpdated.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  updatedAt: PropTypes.string.isRequired,
}

LastUpdated.defaultProps = { user: {} }

export default LastUpdated
