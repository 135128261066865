import React, { Component } from 'react'
import EditWaterMeterForm from '../components/forms/EditWaterMeterForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createWaterMeterAction } from '../actions/flowMonitorsActions'

class CreateWaterMeter extends Component {
  render() {
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/water-meters">Water Meters</Anchor>,
      'Create Water Meter',
    ]
    return (
      <section className="CreateWaterMeter">
        <FormHeader crumbs={crumbs} />
        <EditWaterMeterForm submitAction={createWaterMeterAction} />
      </section>
    )
  }
}

export default CreateWaterMeter
