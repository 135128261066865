import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import RulesPage from '../components/pages/RulesPage'
import {
  getAllThresholdsAction,
  getThresholdAction,
  deleteThresholdAction,
  toggleThresholdOptionAction,
  sendTestNotificationAction,
  getThresholdAssignmentsAction,
  getThresholdEditHistoryAction,
  getAllThresholdGroupsAction,
  createThresholdGroupAction,
  updateThresholdGroupAction,
  deleteThresholdGroupAction,
} from '../actions/thresholdsActions'
import { getAllCompaniesAction } from '../actions/companiesActions'
import {
  getAllEscalationPoliciesAction,
  getEscalationPolicyAction,
  deleteEscalationPolicyAction,
} from '../actions/escalationPoliciesActions'
import {
  unsetSiteUserIdsAction,
  updateEscalationPolicySortAction,
} from '../actions/formActions'
import {
  getAllSitesAction,
  getAllUsersBySiteAction,
} from '../actions/sitesActions'
import {
  getVisibleThresholds,
  getCurrentThreshold,
  getThresholdsMeta,
  getThresholdAssignments,
  getVisibleThresholdEditHistory,
  getVisibleThresholdGroups,
} from '../reducers/thresholdsReducer'
import {
  isThresholdLoading,
  isHistoryLoading,
  isEscalationPolicyLoading,
  isThresholdGroupLoading,
} from '../reducers/uiReducer'
import { getVisibleCompanies } from '../reducers/companiesReducer'
import {
  getVisibleEscalationPolicies,
  getCurrentEscalationPolicy,
} from '../reducers/escalationPoliciesReducer'
import { getVisibleSites, getVisibleSiteUsers } from '../reducers/sitesReducer'
import { MESSAGES } from '../constants'

const mapStateToProps = ({
  thresholds,
  ui,
  companies,
  escalationPolicies,
  sites,
}) => ({
  thresholds: getVisibleThresholds(thresholds),
  currentThreshold: getCurrentThreshold(thresholds),
  thresholdAssignments: getThresholdAssignments(thresholds),
  meta: getThresholdsMeta(thresholds),
  isThresholdLoading: isThresholdLoading(ui),
  isEscalationPolicyLoading: isEscalationPolicyLoading(ui),
  isHistoryLoading: isHistoryLoading(ui),
  thresholdEditHistory: getVisibleThresholdEditHistory(thresholds),
  companies: getVisibleCompanies(companies),
  siteUsers: getVisibleSiteUsers(sites),
  escalationPolicies: getVisibleEscalationPolicies(escalationPolicies),
  currentEscalationPolicy: getCurrentEscalationPolicy(escalationPolicies),
  thresholdGroups: getVisibleThresholdGroups(thresholds),
  sites: getVisibleSites(sites),
  isThresholdGroupLoading: isThresholdGroupLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllThresholds: qs => dispatch(getAllThresholdsAction.request(qs)),
  deleteThreshold: id => dispatch(deleteThresholdAction.request(id)),
  getThreshold: id => dispatch(getThresholdAction.request(id)),
  toggleThresholdOption: threshold => {
    const confirmDeletion = global.confirm(MESSAGES.CONFIRM)

    if (confirmDeletion) {
      dispatch(toggleThresholdOptionAction.request(threshold))
    }
  },
  sendTestNotification: id => dispatch(sendTestNotificationAction.request(id)),
  getAllCompanies: qs => dispatch(getAllCompaniesAction.request(qs)),
  getAllUsersBySite: siteSlug =>
    dispatch(getAllUsersBySiteAction.request({ siteSlug })),
  unsetSiteUserIds: () => dispatch(unsetSiteUserIdsAction()),
  updateEscalationPolicySort: (dragIndex, hoverIndex) =>
    dispatch(updateEscalationPolicySortAction({ dragIndex, hoverIndex })),
  getAllEscalationPolicies: qs =>
    dispatch(getAllEscalationPoliciesAction.request(qs)),
  getEscalationPolicy: slug =>
    dispatch(getEscalationPolicyAction.request(slug)),
  deleteEscalationPolicy: slug =>
    dispatch(deleteEscalationPolicyAction.request(slug)),
  getThresholdAssignments: id =>
    dispatch(getThresholdAssignmentsAction.request(id)),
  getThresholdEditHistory: id =>
    dispatch(getThresholdEditHistoryAction.request(id)),
  updateQueryParams: location => dispatch(replace(location)),
  getAllThresholdGroups: qs =>
    dispatch(getAllThresholdGroupsAction.request(qs)),
  createThresholdGroup: thresholdGroup =>
    dispatch(createThresholdGroupAction.request(thresholdGroup)),
  updateThresholdGroup: thresholdGroup =>
    dispatch(updateThresholdGroupAction.request(thresholdGroup)),
  deleteThresholdGroup: payload =>
    dispatch(deleteThresholdGroupAction.request(payload)),
  getAllSites: qs => dispatch(getAllSitesAction.request(qs)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RulesPage)
