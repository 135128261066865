import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import EditSupportPhoneNumberForm from '../components/forms/EditSupportPhoneNumberForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateSupportPhoneNumberAction } from '../actions/communicationsActions'

const EditSupportPhoneNumber = ({
  getSupportPhoneNumber,
  getUsers,
  phoneNumber,
  users,
  match,
}) => {
  useEffect(
    () => {
      getUsers()
      getSupportPhoneNumber(match.params.slug)
    },
    [getSupportPhoneNumber, getUsers, match.params.slug]
  )

  const crumbs = [
    <Anchor to="/communications">Communications</Anchor>,
    <Anchor to="/communications/phone-numbers">Support Phone Numbers</Anchor>,
    'Edit Support Phone Number',
  ]

  return (
    <section className="EditSupportPhoneNumber">
      <FormHeader crumbs={crumbs} />
      <EditSupportPhoneNumberForm
        initialValues={phoneNumber}
        users={users}
        submitAction={updateSupportPhoneNumberAction}
        enableReinitialize
      />
    </section>
  )
}

EditSupportPhoneNumber.propTypes = {
  phoneNumber: PropTypes.object.isRequired,
  getSupportPhoneNumber: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  getUsers: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
}

export default EditSupportPhoneNumber
