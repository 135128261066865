import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import PrintableFloorPlan from '../PrintableFloorPlan'

class PrintPage extends Component {
  static propTypes = {
    getSite: PropTypes.func.isRequired,
    getFloor: PropTypes.func.isRequired,
    getAllLocationsByFloor: PropTypes.func.isRequired,
    site: PropTypes.object.isRequired,
    floor: PropTypes.object.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  }

  render() {
    const {
      getSite,
      getFloor,
      getAllLocationsByFloor,
      site,
      floor,
      locations,
      match: { url },
    } = this.props

    return (
      <div className="PrintPage bg-white pa3">
        <div className="mw7 center" style={{ width: '768px' }}>
          <Switch>
            <Route
              path={`${url}/sites/:siteSlug/floors/:floorId`}
              exact
              render={props => (
                <Fragment>
                  <Helmet>
                    <title>{floor.name || 'Loading...'}</title>
                  </Helmet>
                  <PrintableFloorPlan
                    getSite={getSite}
                    getFloor={getFloor}
                    getAllLocationsByFloor={getAllLocationsByFloor}
                    site={site}
                    floor={floor}
                    locations={locations}
                    {...props}
                  />
                </Fragment>
              )}
            />
          </Switch>
        </div>
      </div>
    )
  }
}

export default PrintPage
