import { createFormAction } from 'redux-form-saga'

const getAllSupportPhoneNumbersAction = createFormAction(
  'GET_ALL_SUPPORT_PHONE_NUMBERS'
)
const createSupportPhoneNumbersAction = createFormAction(
  'CREATE_SUPPORT_PHONE_NUMBERS'
)
const getSupportPhoneNumberAction = createFormAction('GET_SUPPORT_PHONE_NUMBER')
const updateSupportPhoneNumberAction = createFormAction(
  'UPDATE_SUPPORT_PHONE_NUMBER'
)
const deleteSupportPhoneNumberAction = createFormAction(
  'DELETE_SUPPORT_PHONE_NUMBER'
)

const getAllConversationsAction = createFormAction('GET_ALL_CONVERSATIONS')
const getConversationAction = createFormAction('GET_CONVERSATION')
const createConversationAction = createFormAction('CREATE_CONVERSATION')
const closeConversationAction = createFormAction('CLOSE_CONVERSATION')

const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION'
const clearConversationAction = () => ({
  type: CLEAR_CONVERSATION,
})

const RECEIVE_NEW_MESSAGE = 'RECEIVE_NEW_MESSAGE'
const receiveNewMessageAction = payload => ({
  type: RECEIVE_NEW_MESSAGE,
  payload,
})

const UPDATE_INBOX = 'UPDATE_INBOX'
const updateInboxAction = payload => ({
  type: UPDATE_INBOX,
  payload,
})

const createBridgedPhoneCallAction = createFormAction('BRIDGED_PHONE_CALL')

export {
  getAllSupportPhoneNumbersAction,
  createSupportPhoneNumbersAction,
  getSupportPhoneNumberAction,
  updateSupportPhoneNumberAction,
  deleteSupportPhoneNumberAction,
  getAllConversationsAction,
  getConversationAction,
  createConversationAction,
  closeConversationAction,
  CLEAR_CONVERSATION,
  clearConversationAction,
  RECEIVE_NEW_MESSAGE,
  receiveNewMessageAction,
  UPDATE_INBOX,
  updateInboxAction,
  createBridgedPhoneCallAction,
}
