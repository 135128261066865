import {
  fork,
  call,
  take,
  takeLatest,
  put,
  all,
  select,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllLocationsBySiteAction,
  getAllLocationsByFloorAction,
  getLocationAction,
  getLocationChartDataAction,
  createLocationAction,
  updateLocationAction,
  deleteLocationAction,
  addThresholdToLocationAction,
  deleteThresholdFromLocationAction,
  toggleLocationOptionAction,
  createMapLocationAction,
  updateGatewayMapLocationAction,
  deleteGatewayMapLocationAction,
  createGatewayMapLocationAction,
  updateMapLocationCoordinatesAction,
  deleteMapLocationAction,
  getAllLocationsSnapshotDataByFloorAction,
  getLocationTimeSeriesDataByLocationIdsAction,
  addEscalationPolicytoLocationThresholdAction,
  getAllLocationThresholdUserMappingsAction,
  updateLocationThresholdUserMappingAction,
  getPodAssignmentHistoryByLocationAction,
  getLocationSnapshotAction,
} from '../actions/locationsActions'
import { getSiteGatewaysAction } from '../actions/gatewaysActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { getPathSnippetAtIndex, pickErrorMessage } from '../utils/helpers'
import {
  getAllAggregateThresholdsBySiteAction,
  getAllSiteAggregateThresholdUserMappingsAction,
} from '../actions/sitesActions'
import { createQueryString } from '../utils/queryParams'

// selectors

const getSiteSlug = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 1)
const getFloorId = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 3)
const getLocationId = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 5)

// handlers

function* handleGetAllLocationsBySiteSaga({ siteSlug, qs }) {
  try {
    const payload = yield call(api.getAllLocationsBySite, siteSlug, qs)
    yield put(getAllLocationsBySiteAction.success(payload))
  } catch (error) {
    yield put(getAllLocationsBySiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllLocationsByFloorSaga({
  siteSlug,
  floorId,
  shouldFetchSnapshots = true,
}) {
  try {
    const locations = yield call(api.getAllLocationsByFloor, siteSlug, floorId)

    yield put(getAllLocationsByFloorAction.success({ locations }))

    if (shouldFetchSnapshots) {
      yield fork(handleGetAllLocationsSnapshotDataByFloorSaga, {
        siteSlug,
        floorId,
      })
    }
  } catch (error) {
    yield put(getAllLocationsByFloorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllLocationsSnapshotDataByFloorSaga({ siteSlug, floorId }) {
  try {
    const latestSensorData = yield call(
      api.getAllLocationsSnapshotDataByFloor,
      siteSlug,
      floorId
    )

    yield put(
      getAllLocationsSnapshotDataByFloorAction.success({ latestSensorData })
    )
  } catch (error) {
    yield put(getAllLocationsSnapshotDataByFloorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetLocationSaga({ siteSlug, floorId, id }) {
  try {
    const [item, { items: thresholds = [] }] = yield all([
      call(api.getLocationById, siteSlug, floorId, id),
      call(api.getAllThresholdsByLocation, siteSlug, floorId, id),
    ])
    yield put(
      getLocationSnapshotAction.request({ siteSlug, floorId, locationId: id })
    )
    yield put(getLocationAction.success({ ...item, thresholds }))
  } catch (error) {
    yield put(getLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetLocationSnapshotSaga({ payload }) {
  try {
    const { siteSlug, floorId, locationId } = payload
    const result = yield call(
      api.getLocationReadingsById,
      siteSlug,
      floorId,
      locationId
    )

    yield put(getLocationSnapshotAction.success(result))
  } catch (error) {
    yield put(getLocationSnapshotAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetLocationChartDataSaga({ siteSlug, floorId, id, qs }) {
  try {
    let result = yield call(
      api.getLocationChartDataById,
      siteSlug,
      floorId,
      id,
      qs
    )

    yield put(getLocationChartDataAction.success(result))
  } catch (error) {
    yield put(getLocationChartDataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// TODO deprecate
function* handleCreateLocationSaga(location) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const floorId = yield select(getFloorId)

    const payload = yield call(api.createLocation, siteSlug, floorId, location)
    yield put(createLocationAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(
      push(`/sites/${siteSlug}/floors/${floorId}/locations/${payload.id}`)
    )
  } catch (error) {
    yield put(createLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateMapLocationSaga(location) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const floorId = yield select(getFloorId)

    const payload = yield call(api.createLocation, siteSlug, floorId, location)
    yield put(createMapLocationAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
  } catch (error) {
    yield put(createMapLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateGatewayMapLocationSaga({ payload }) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const result = yield call(api.updateGatewayLocation, siteSlug, payload)
    yield put(updateGatewayMapLocationAction.success(result))
    yield put(getSiteGatewaysAction.request(siteSlug))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(updateGatewayMapLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateGatewayMapLocationSaga({ payload }) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const floorId = yield select(getFloorId)

    yield call(api.updateGatewayLocation, siteSlug, { ...payload, floorId })
    yield put(getSiteGatewaysAction.request(siteSlug))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
  } catch (error) {
    yield put(createGatewayMapLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteGatewayMapLocationSaga({ payload }) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.updateGatewayLocation, siteSlug, payload)
      yield put(getSiteGatewaysAction.request(siteSlug))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteGatewayMapLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteMapLocationSaga({ siteSlug, floorId, id }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteLocation, siteSlug, floorId, id)
      yield fork(handleGetAllLocationsByFloorSaga, { siteSlug, floorId })
      // TODO necessary?
      // yield put(deleteMapLocationAction.success())
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteMapLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// TODO deprecate
function* handleUpdateLocationSaga(location) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const floorId = yield select(getFloorId)

    const payload = yield call(api.updateLocation, siteSlug, floorId, location)
    yield put(updateLocationAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(
      push(`/sites/${siteSlug}/floors/${floorId}/locations/${payload.id}`)
    )
  } catch (error) {
    yield put(updateLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateMapLocationSaga({ siteSlug, floorId, location }) {
  try {
    const payload = yield call(api.updateLocation, siteSlug, floorId, location)
    yield put(updateMapLocationCoordinatesAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(updateLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// TODO deprecated?
function* handleDeleteLocationSaga({ siteSlug, floorId, id }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteLocation, siteSlug, floorId, id)
      yield fork(handleGetAllLocationsByFloorSaga, { siteSlug, floorId })
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddThresholdToLocationSaga({
  siteSlug,
  floorId,
  locationId,
  thresholdId,
}) {
  try {
    const isConfirmed = yield call(global.confirm, MESSAGES.CONFIRM)

    if (isConfirmed) {
      yield call(
        api.addThresholdToLocation,
        siteSlug,
        floorId,
        locationId,
        thresholdId
      )
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ADDED_THRESHOLD))
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(addThresholdToLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteThresholdFromLocationSaga({
  siteSlug,
  floorId,
  locationId,
  thresholdId,
}) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(
        api.deleteThresholdFromLocation,
        siteSlug,
        floorId,
        locationId,
        thresholdId
      )
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(
        showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED_THRESHOLD)
      )
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(deleteThresholdFromLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleToggleSelectionSaga(location) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const floorId = yield select(getFloorId)

    const payload = yield call(api.updateLocation, siteSlug, floorId, location)
    yield put(updateLocationAction.success(payload))
  } catch (error) {
    yield put(updateLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetLocationDataByLocationIdsSaga({ payload }) {
  try {
    const { siteSlug, qs } = payload
    const result = yield call(api.getLocationDataByLocationIds, siteSlug, qs)
    yield put(getLocationTimeSeriesDataByLocationIdsAction.success(result))
  } catch (error) {
    yield put(getLocationTimeSeriesDataByLocationIdsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddEscalationPolicytoLocationThresholdSaga({ payload }) {
  try {
    const { thresholdId, escalationPolicySlug } = payload

    const siteSlug = yield select(getSiteSlug)
    const floorId = yield select(getFloorId)
    const locationId = yield select(getLocationId)

    yield call(
      api.addEscalationPolicytoLocationThreshold,
      siteSlug,
      floorId,
      locationId,
      thresholdId,
      escalationPolicySlug
    )
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED_ESCALATION_POLICY)
    )
  } catch (error) {
    yield put(addEscalationPolicytoLocationThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllLocationThresholdUserMappingsSaga({ payload }) {
  try {
    const { siteSlug, floorId, locationId } = payload
    const result = yield call(
      api.getAllLocationThresholdUserMappings,
      siteSlug,
      floorId,
      locationId
    )
    yield put(getAllLocationThresholdUserMappingsAction.success(result))
  } catch (error) {
    yield put(getAllLocationThresholdUserMappingsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateLocationThresholdUserMappingSaga({ payload }) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const floorId =
      payload && payload.floorId ? payload.floorId : yield select(getFloorId)
    const locationId =
      payload && payload.locationId
        ? payload.locationId
        : yield select(getLocationId)
    const result = yield call(api.updateLocationThresholdUserMapping, {
      ...payload,
      siteSlug,
      floorId,
      locationId,
    })
    yield put(getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug))
    yield put(updateLocationThresholdUserMappingAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield fork(handleGetAllLocationThresholdUserMappingsSaga, {
      payload: {
        siteSlug,
        floorId,
        locationId,
      },
    })
  } catch (error) {
    yield put(updateLocationThresholdUserMappingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodAssignmentHistoryByLocationSaga(payload) {
  try {
    const { siteSlug, floorId, locationId } = payload
    const result = yield call(
      api.getPodAssignmentHistoryByLocation,
      siteSlug,
      floorId,
      locationId
    )
    yield put(getPodAssignmentHistoryByLocationAction.success(result))
  } catch (error) {
    yield put(getPodAssignmentHistoryByLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetPodAssignmentHistoryByLocationSaga() {
  while (true) {
    const { payload } = yield take(
      getPodAssignmentHistoryByLocationAction.REQUEST
    )
    yield fork(handleGetPodAssignmentHistoryByLocationSaga, payload)
  }
}

function* watchGetAllLocationsBySiteSaga() {
  while (true) {
    const { payload } = yield take(getAllLocationsBySiteAction.REQUEST)
    yield fork(handleGetAllLocationsBySiteSaga, payload)
  }
}

function* watchGetAllLocationsByFloorSaga() {
  while (true) {
    const { payload } = yield take(getAllLocationsByFloorAction.REQUEST)
    yield fork(handleGetAllLocationsByFloorSaga, payload)
  }
}

function* watchGetAllLocationsSnapshotDataByFloorSaga() {
  while (true) {
    const { payload } = yield take(
      getAllLocationsSnapshotDataByFloorAction.REQUEST
    )
    yield fork(handleGetAllLocationsSnapshotDataByFloorSaga, payload)
  }
}

function* watchGetLocationSaga() {
  while (true) {
    const { payload } = yield take(getLocationAction.REQUEST)
    yield fork(handleGetLocationSaga, payload)
  }
}

function* watchGetLocationSnapshotSaga() {
  yield takeLatest(
    getLocationSnapshotAction.REQUEST,
    handleGetLocationSnapshotSaga
  )
}

function* watchGetLocationChartDataSaga() {
  while (true) {
    const { payload } = yield take(getLocationChartDataAction.REQUEST)
    yield fork(handleGetLocationChartDataSaga, payload)
  }
}

function* watchCreateLocationSaga() {
  while (true) {
    const { payload } = yield take(createLocationAction.REQUEST)
    yield fork(handleCreateLocationSaga, payload)
  }
}

function* watchCreateMapLocationSaga() {
  while (true) {
    const { payload } = yield take(createMapLocationAction.REQUEST)
    yield fork(handleCreateMapLocationSaga, payload)
  }
}

function* watchUpdateGatewayMapLocationSaga() {
  yield takeLatest(
    updateGatewayMapLocationAction.REQUEST,
    handleUpdateGatewayMapLocationSaga
  )
}

function* watchCreateGatewayMapLocationSaga() {
  yield takeLatest(
    createGatewayMapLocationAction.REQUEST,
    handleCreateGatewayMapLocationSaga
  )
}

function* watchDeleteGatewayMapLocationSaga() {
  yield takeLatest(
    deleteGatewayMapLocationAction.REQUEST,
    handleDeleteGatewayMapLocationSaga
  )
}

function* watchDeleteMapLocationSaga() {
  while (true) {
    const { payload } = yield take(deleteMapLocationAction.REQUEST)
    yield fork(handleDeleteMapLocationSaga, payload)
  }
}

function* watchUpdateLocationSaga() {
  while (true) {
    const { payload } = yield take(updateLocationAction.REQUEST)
    yield fork(handleUpdateLocationSaga, payload)
  }
}

function* watchUpdateMapLocationSaga() {
  while (true) {
    const { payload } = yield take(updateMapLocationCoordinatesAction.REQUEST)
    yield fork(handleUpdateMapLocationSaga, payload)
  }
}

function* watchDeleteLocationSaga() {
  while (true) {
    const { payload } = yield take(deleteLocationAction.REQUEST)
    yield fork(handleDeleteLocationSaga, payload)
  }
}

function* watchAddThresholdToLocationSaga() {
  while (true) {
    const { payload } = yield take(addThresholdToLocationAction.REQUEST)
    yield fork(handleAddThresholdToLocationSaga, payload)
  }
}

function* watchDeleteThresholdFromLocationSaga() {
  while (true) {
    const { payload } = yield take(deleteThresholdFromLocationAction.REQUEST)
    yield fork(handleDeleteThresholdFromLocationSaga, payload)
  }
}

function* watchToggleSelectionSaga() {
  while (true) {
    const { payload } = yield take(toggleLocationOptionAction.REQUEST)
    yield fork(handleToggleSelectionSaga, payload)
  }
}

function* watchGetLocationDataByLocationIdsSaga() {
  yield takeLatest(
    getLocationTimeSeriesDataByLocationIdsAction.REQUEST,
    handleGetLocationDataByLocationIdsSaga
  )
}

function* watchAddEscalationPolicytoLocationThresholdSaga() {
  yield takeLatest(
    addEscalationPolicytoLocationThresholdAction.REQUEST,
    handleAddEscalationPolicytoLocationThresholdSaga
  )
}

function* watchGetAllLocationThresholdUserMappingsSaga() {
  yield takeLatest(
    getAllLocationThresholdUserMappingsAction.REQUEST,
    handleGetAllLocationThresholdUserMappingsSaga
  )
}

function* watchUpdateLocationThresholdUserMappingSaga() {
  yield takeLatest(
    updateLocationThresholdUserMappingAction.REQUEST,
    handleUpdateLocationThresholdUserMappingSaga
  )
}

function* locationsSaga() {
  yield all([
    fork(watchGetAllLocationsBySiteSaga),
    fork(watchGetAllLocationsByFloorSaga),
    fork(watchGetLocationSaga),
    fork(watchGetLocationChartDataSaga),
    fork(watchCreateLocationSaga),
    fork(watchCreateMapLocationSaga),
    fork(watchDeleteMapLocationSaga),
    fork(watchUpdateLocationSaga),
    fork(watchUpdateMapLocationSaga),
    fork(watchDeleteLocationSaga),
    fork(watchAddThresholdToLocationSaga),
    fork(watchDeleteThresholdFromLocationSaga),
    fork(watchToggleSelectionSaga),
    fork(watchGetAllLocationsSnapshotDataByFloorSaga),
    fork(watchGetLocationDataByLocationIdsSaga),
    fork(watchAddEscalationPolicytoLocationThresholdSaga),
    fork(watchGetAllLocationThresholdUserMappingsSaga),
    fork(watchUpdateLocationThresholdUserMappingSaga),
    fork(watchGetPodAssignmentHistoryByLocationSaga),
    fork(watchGetLocationSnapshotSaga),
    fork(watchUpdateGatewayMapLocationSaga),
    fork(watchCreateGatewayMapLocationSaga),
    fork(watchDeleteGatewayMapLocationSaga),
  ])
}

export {
  locationsSaga as default,
  watchGetAllLocationsBySiteSaga,
  watchGetAllLocationsByFloorSaga,
  watchGetLocationSaga,
  watchGetLocationChartDataSaga,
  watchCreateLocationSaga,
  watchUpdateLocationSaga,
  watchDeleteLocationSaga,
  watchAddThresholdToLocationSaga,
  watchDeleteThresholdFromLocationSaga,
  watchGetAllLocationsSnapshotDataByFloorSaga,
  watchCreateMapLocationSaga,
  watchDeleteMapLocationSaga,
  watchUpdateMapLocationSaga,
  watchToggleSelectionSaga,
  watchGetLocationDataByLocationIdsSaga,
  watchAddEscalationPolicytoLocationThresholdSaga,
  watchGetAllLocationThresholdUserMappingsSaga,
  watchUpdateLocationThresholdUserMappingSaga,
  watchGetPodAssignmentHistoryByLocationSaga,
  watchGetLocationSnapshotSaga,
  watchUpdateGatewayMapLocationSaga,
  watchCreateGatewayMapLocationSaga,
  watchDeleteGatewayMapLocationSaga,
  handleGetAllLocationsBySiteSaga,
  handleGetAllLocationsByFloorSaga,
  handleGetLocationSaga,
  handleGetLocationChartDataSaga,
  handleCreateLocationSaga,
  handleUpdateLocationSaga,
  handleDeleteLocationSaga,
  handleAddThresholdToLocationSaga,
  handleDeleteThresholdFromLocationSaga,
  handleGetAllLocationsSnapshotDataByFloorSaga,
  handleGetPodAssignmentHistoryByLocationSaga,
  handleUpdateGatewayMapLocationSaga,
  handleCreateGatewayMapLocationSaga,
  handleDeleteGatewayMapLocationSaga,
  getSiteSlug,
  getFloorId,
}
