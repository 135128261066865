import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import TimeSelector from './common/TimeSelector'
import Checkbox from './common/Checkbox'
import Button from './common/Button'
import { ActionAnchor } from './common/Anchor'
import { H3 } from './common/Headers'
import { toTitleCase } from '../utils/textFormatters'
import { TIME_FORMAT } from '../constants'

import { toggleThresholdOptionAction } from '../actions/thresholdsActions'

const mapDispatchToProps = dispatch => ({
  updateThreshold: payload =>
    dispatch(toggleThresholdOptionAction.request(payload)),
})

const DAYS = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
}

const Schedule = ({ schedule, index, handleUpdate, handleRemoveSchedule }) => {
  const { localStartTime, localStopTime, daysOfTheWeek } = schedule

  const handleDayUpdate = (day, bool) => {
    if (bool) {
      handleUpdate(index, 'daysOfTheWeek', [...daysOfTheWeek, day])
    } else {
      const nextDays = daysOfTheWeek.filter(y => y !== day)
      handleUpdate(index, 'daysOfTheWeek', nextDays)
    }
  }

  return (
    <div className="Schedule mb3 ba br3 b--light-gray bg-near-white pa2">
      <div className="w-80 flex">
        <div className="w-20-ns mr3-ns">
          <TimeSelector
            label="Start Time"
            input={{
              value: moment(localStartTime, TIME_FORMAT),
              onChange: x => handleUpdate(index, 'localStartTime', x),
            }}
            format={TIME_FORMAT}
          />
        </div>
        <div className="w-20-ns">
          <TimeSelector
            label="End Time"
            input={{
              value: moment(localStopTime, TIME_FORMAT),
              onChange: x => handleUpdate(index, 'localStopTime', x),
            }}
            format={TIME_FORMAT}
          />
        </div>
      </div>
      <div className="flex flex-wrap">
        {Object.keys(DAYS).map(x => (
          <div className="mr4">
            <Checkbox
              type="checkbox"
              label={toTitleCase(x)}
              input={{
                checked: daysOfTheWeek.indexOf(DAYS[x]) !== -1,
                onChange: y => handleDayUpdate(DAYS[x], y),
              }}
            />
          </div>
        ))}
      </div>
      <ActionAnchor onClick={() => handleRemoveSchedule(index)}>
        Remove Schedule
      </ActionAnchor>
    </div>
  )
}

Schedule.propTypes = {
  schedule: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleRemoveSchedule: PropTypes.func.isRequired,
}

const ThresholdScheduler = ({ schedules, updateThreshold, thresholdId }) => {
  const [isEnabled, setIsEnabled] = useState(false)
  const [formValues, setFormValues] = useState([])

  useEffect(() => {
    setFormValues(schedules)
  }, [schedules])

  useEffect(() => {
    if (formValues.length > 0) {
      setIsEnabled(true)
    }
  }, [formValues])

  const handleIsEnabled = bool => {
    if (!bool && formValues.length > 0) {
      const confirmDisable = global.confirm(
        'Disabling will remove all current schedules. Are you sure?'
      )
      if (confirmDisable) {
        setIsEnabled(bool)
        setFormValues([])
        handleSubmit(null, true)
      }
    } else {
      setIsEnabled(bool)
    }
  }

  const handleUpdate = (index, keyName, value) => {
    const schedule = { ...formValues[index], [keyName]: value }
    setFormValues(formValues.map((x, i) => (i === index ? schedule : x)))
  }

  const handleCreateSchedule = () =>
    setFormValues([
      ...formValues,
      {
        daysOfTheWeek: [],
        localStartTime: moment('08:00', TIME_FORMAT),
        localStopTime: moment('18:00', TIME_FORMAT),
      },
    ])

  const handleRemoveSchedule = index => {
    const nextFormValues = formValues.filter((_, i) => i !== index)
    setFormValues(nextFormValues)

    if (nextFormValues.length === 0) {
      handleSubmit(null, true)
    }
  }

  const handleSubmit = (_, isDisabling = false) => {
    const schedules = !isDisabling
      ? formValues.map(({ daysOfTheWeek, localStartTime, localStopTime }) => ({
          daysOfTheWeek,
          localStartTime: moment.isMoment(localStartTime)
            ? localStartTime.format(TIME_FORMAT)
            : localStartTime,
          localStopTime: moment.isMoment(localStopTime)
            ? localStopTime.format(TIME_FORMAT)
            : localStopTime,
        }))
      : []

    updateThreshold({
      id: thresholdId,
      schedules,
    })
  }

  return (
    <div className="ThresholdScheduler mb3">
      <H3>Set Threshold Schedule</H3>
      <div className="flex-l items-center-l">
        <Checkbox
          type="checkbox"
          label="Enabled"
          input={{
            checked: isEnabled,
            onChange: handleIsEnabled,
          }}
        />
        {isEnabled && (
          <ActionAnchor className="mb3 ml3" onClick={handleCreateSchedule}>
            Add Schedule
          </ActionAnchor>
        )}
      </div>
      {isEnabled && (
        <>
          {formValues.map((x, i) => (
            <Schedule
              schedule={x}
              index={i}
              handleUpdate={handleUpdate}
              handleRemoveSchedule={handleRemoveSchedule}
            />
          ))}
          <Button
            disabled={!isEnabled || formValues.length < 1}
            onClick={handleSubmit}
          />
        </>
      )}
    </div>
  )
}

ThresholdScheduler.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  thresholdId: PropTypes.string.isRequired,
  updateThreshold: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(ThresholdScheduler)
