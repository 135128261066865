import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditActionReportCauseForm from '../components/forms/EditActionReportCauseForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createActionReportCauseAction } from '../actions/actionReportsActions'

class CreateActionReportCause extends Component {
  static propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object),
    getAllCompanies: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getAllCompanies()
  }

  render() {
    const crumbs = [
      <Anchor to="/metadata/all">Metadata</Anchor>,
      <Anchor to="/metadata/all/action-report-causes">
        Action Report Causes
      </Anchor>,
      'Create Action Report Cause',
    ]

    return (
      <section className="CreateActionReportCause">
        <FormHeader crumbs={crumbs} />
        <EditActionReportCauseForm
          companies={this.props.companies}
          submitAction={createActionReportCauseAction}
        />
      </section>
    )
  }
}

export default CreateActionReportCause
