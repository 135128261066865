import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import Divider from '../common/Divider'
import GenerateReport from '../GenerateReport'
import AllReports from '../AllReports'
import CreateAlertSummary from '../CreateAlertSummary'
import AlertSummary from '../AlertSummary'
import EditAlertSummary from '../EditAlertSummary'
import PageWidth from '../PageWidth'

class ReportsPage extends Component {
  static propTypes = {
    getAllSites: PropTypes.func.isRequired,
    getAllFloors: PropTypes.func.isRequired,
    getAllBuildings: PropTypes.func.isRequired,
    getAllReports: PropTypes.func.isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    getAllReportConfigs: PropTypes.func.isRequired,
    createReportConfig: PropTypes.func.isRequired,
    updateReportConfig: PropTypes.func.isRequired,
    deleteReportConfig: PropTypes.func.isRequired,
    createReport: PropTypes.func.isRequired,
    clearReport: PropTypes.func.isRequired,
    getAllLocationsBySite: PropTypes.func.isRequired,
    report: PropTypes.object.isRequired,
    reports: PropTypes.arrayOf(PropTypes.object).isRequired,
    reportsMeta: PropTypes.object.isRequired,
    reportConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    floors: PropTypes.arrayOf(PropTypes.object).isRequired,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    locationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    isReportLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    getAllLocationGroups: PropTypes.func.isRequired,
    getAllAlertSummaries: PropTypes.func.isRequired,
    getAlertSummaryBySlug: PropTypes.func.isRequired,
    getAggregateThresholdsBySite: PropTypes.func.isRequired,
    deleteAlertSummary: PropTypes.func.isRequired,
    alertSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
    alertSummariesMeta: PropTypes.object.isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    alertSummaryFormValues: PropTypes.object.isRequired,
    alertSummary: PropTypes.object.isRequired,
    isAlertSummaryLoading: PropTypes.bool.isRequired,
  }

  render() {
    const {
      getAllSites,
      getAllFloors,
      getAllBuildings,
      getAllReports,
      getAllUsersBySite,
      getAllReportConfigs,
      createReportConfig,
      updateReportConfig,
      deleteReportConfig,
      createReport,
      clearReport,
      getAllLocationsBySite,
      report,
      reports,
      reportsMeta,
      reportConfigs,
      sites,
      floors,
      buildings,
      locationGroups,
      locations,
      siteUsers,
      isReportLoading,
      match: { url },
      getAllLocationGroups,
      getAllAlertSummaries,
      getAlertSummaryBySlug,
      getAggregateThresholdsBySite,
      deleteAlertSummary,
      alertSummaries,
      alertSummariesMeta,
      thresholds,
      alertSummaryFormValues,
      alertSummary,
      isAlertSummaryLoading,
    } = this.props

    return (
      <MainContent>
        <div className="ReportsPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/generate`}
                exact
                render={props => (
                  <>
                    <Helmet>
                      <title>Generate Report</title>
                    </Helmet>
                    <GenerateReport
                      getAllSites={getAllSites}
                      getAllFloors={getAllFloors}
                      getAllBuildings={getAllBuildings}
                      createReport={createReport}
                      clearReport={clearReport}
                      getAllLocationsBySite={getAllLocationsBySite}
                      getAllLocationGroups={getAllLocationGroups}
                      report={report}
                      sites={sites}
                      floors={floors}
                      buildings={buildings}
                      locationGroups={locationGroups}
                      locations={locations}
                      isLoading={isReportLoading}
                      {...props}
                    />
                  </>
                )}
              />

              <Route
                path={`${url}/alert-summaries/create`}
                exact
                render={props => (
                  <>
                    <Helmet>
                      <title>Create Alert Summary</title>
                    </Helmet>
                    <Divider />
                    <CreateAlertSummary
                      getAllSites={getAllSites}
                      getAllUsersBySite={getAllUsersBySite}
                      getAggregateThresholdsBySite={
                        getAggregateThresholdsBySite
                      }
                      sites={sites}
                      users={siteUsers}
                      thresholds={thresholds}
                      formValues={alertSummaryFormValues}
                      {...props}
                    />
                  </>
                )}
              />
              <Route
                path={`${url}/alert-summaries/:slug`}
                exact
                render={props => (
                  <>
                    <Helmet>
                      <title>
                        {alertSummary.description
                          ? `Alert Summary – ${alertSummary.description}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <AlertSummary
                      getAlertSummaryBySlug={getAlertSummaryBySlug}
                      alertSummary={alertSummary}
                      isAlertSummaryLoading={isAlertSummaryLoading}
                      {...props}
                    />
                  </>
                )}
              />
              <Route
                path={`${url}/alert-summaries/:slug/edit`}
                exact
                render={props => (
                  <>
                    <Helmet>
                      <title>
                        {alertSummary.description
                          ? `Edit ${alertSummary.description}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <Divider />
                    <EditAlertSummary
                      getAllSites={getAllSites}
                      getAllUsersBySite={getAllUsersBySite}
                      getAggregateThresholdsBySite={
                        getAggregateThresholdsBySite
                      }
                      getAlertSummaryBySlug={getAlertSummaryBySlug}
                      sites={sites}
                      users={siteUsers}
                      thresholds={thresholds}
                      formValues={alertSummaryFormValues}
                      alertSummary={alertSummary}
                      {...props}
                    />
                  </>
                )}
              />

              <Route
                path={`${url}/`}
                render={props => (
                  <>
                    <Helmet>
                      <title>Saved Reports</title>
                    </Helmet>
                    <AllReports
                      reports={reports}
                      meta={reportsMeta}
                      getAllReports={getAllReports}
                      isLoading={isReportLoading}
                      getAllSites={getAllSites}
                      getAllFloors={getAllFloors}
                      getAllBuildings={getAllBuildings}
                      getAllLocationGroups={getAllLocationGroups}
                      getAllUsersBySite={getAllUsersBySite}
                      createReportConfig={createReportConfig}
                      getAllLocationsBySite={getAllLocationsBySite}
                      sites={sites}
                      floors={floors}
                      locationGroups={locationGroups}
                      buildings={buildings}
                      locations={locations}
                      siteUsers={siteUsers}
                      getAllReportConfigs={getAllReportConfigs}
                      updateReportConfig={updateReportConfig}
                      deleteReportConfig={deleteReportConfig}
                      reportConfigs={reportConfigs}
                      getAllAlertSummaries={getAllAlertSummaries}
                      getAlertSummaryBySlug={getAlertSummaryBySlug}
                      getAggregateThresholdsBySite={
                        getAggregateThresholdsBySite
                      }
                      deleteAlertSummary={deleteAlertSummary}
                      alertSummaries={alertSummaries}
                      alertSummariesMeta={alertSummariesMeta}
                      thresholds={thresholds}
                      alertSummaryFormValues={alertSummaryFormValues}
                      alertSummary={alertSummary}
                      isAlertSummaryLoading={isAlertSummaryLoading}
                      {...props}
                    />
                  </>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default ReportsPage
