import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown, Icon } from './common/Ant'
import { ActionAnchor } from './common/Anchor'
import { dateTimeFormatter } from '../utils/date'

const Comment = ({
  comment,
  handleEditComment,
  handleDeleteComment,
  accountProfile,
}) => {
  const { tags, plainText, user, createdAt, startTime, endTime } = comment
  const currentUserId = accountProfile.id ? accountProfile.id.toString() : ''

  return (
    <div className="Comment">
      <div className="ba b--light-gray br2 mb3">
        <div className="flex justify-between pa3 bg-near-white bb b--light-gray br2 br--top">
          <div>
            <span className="b">
              {user.firstName} {user.lastName}
            </span>{' '}
            commented on {dateTimeFormatter(createdAt)}
          </div>
          {user.userId === currentUserId && (
            <div>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="edit-comment">
                      <ActionAnchor onClick={() => handleEditComment(comment)}>
                        Edit
                      </ActionAnchor>
                    </Menu.Item>
                    <Menu.Item key="delete-comment">
                      <ActionAnchor
                        onClick={() => handleDeleteComment(comment)}
                      >
                        Remove
                      </ActionAnchor>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <a className="ant-dropdown-link">
                  Actions <Icon type="down" />
                </a>
              </Dropdown>
            </div>
          )}
        </div>
        <div className="pa3">
          <div className="mb3">{plainText}</div>
          <div>
            <div className="gray flex">
              <div className="b pr2">Detail:</div>
              <div>
                {dateTimeFormatter(startTime)} &ndash;{' '}
                {dateTimeFormatter(endTime)}
              </div>
            </div>
            {tags.length > 0 && (
              <div className="gray flex">
                <div className="b pr2">Tagged:</div>
                <ul className="list pl0 mb0 flex">
                  {tags.map((x, i) => (
                    <li key={x.id} className="pr1 i">
                      {x.name}
                      {tags.length - 1 > i && ','}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  accountProfile: PropTypes.object.isRequired,
  handleEditComment: PropTypes.func.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
}

export default Comment
