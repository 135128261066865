import React from 'react'
import PropTypes from 'prop-types'
import Joyride from 'react-joyride'
import { H3 } from './common/Headers'
import Button from './common/Button'
import { ActionAnchor } from './common/Anchor'
import { COLORS, SIZES } from '../constants'

const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps,
}) => {
  return (
    <div {...tooltipProps} className="bg-white pa3 br2 black-80">
      {step.title && <H3>{step.title}</H3>}
      <p>{step.content}</p>
      <div className="flex justify-end items-center">
        {index > 0 && (
          <ActionAnchor {...backProps} className="mr2">
            {backProps.title}
          </ActionAnchor>
        )}
        {continuous && (
          <Button
            size={SIZES.SMALL}
            text={primaryProps.title}
            {...primaryProps}
          />
        )}
        {!continuous && (
          <Button size={SIZES.SMALL} text={closeProps.title} {...closeProps} />
        )}
      </div>
      <div>
        <ActionAnchor className="f7" {...skipProps}>
          Don't show this again.
        </ActionAnchor>
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  step: PropTypes.object.isRequired,
  backProps: PropTypes.object.isRequired,
  closeProps: PropTypes.object.isRequired,
  primaryProps: PropTypes.object.isRequired,
  skipProps: PropTypes.object.isRequired,
  tooltipProps: PropTypes.object.isRequired,
}

const FeatureTour = ({
  steps,
  run = true,
  continuous = true,
  handleIgnore,
}) => {
  return (
    <Joyride
      run={run}
      steps={steps}
      continuous={continuous}
      showSkipButton={!!handleIgnore}
      scrollOffset={85}
      tooltipComponent={Tooltip}
      styles={{
        options: {
          primaryColor: COLORS.DARK_BLUE,
        },
      }}
      callback={handleIgnore}
    />
  )
}

FeatureTour.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  run: PropTypes.bool.isRequired,
  continuous: PropTypes.bool.isRequired,
  handleIgnore: PropTypes.func,
}

export default FeatureTour
