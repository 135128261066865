import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import merge from 'lodash.merge'
import ChatMessage from './ChatMessage'
import { H4 } from './common/Headers'
import Form from './common/Form'
import Button from './common/Button'
import Input from './common/Input'
import { Icon } from './common/Ant'
import { emit, listen } from '../services/socket'
import { createQueryString } from '../utils/queryParams'
import { formatPhoneNumber } from '../utils/forms'
import messageSerializer from '../utils/messageSerializer'
import { SIZES, SOCKET_CHANNELS } from '../constants'

class Chat extends Component {
  constructor(props) {
    super(props)

    const { currentConversation } = this.props

    this.elRef = createRef()
    this.chatsRef = createRef()

    this.state = {
      isMinimized: true,
      message: '',
      messages: currentConversation.length > 0 ? currentConversation : [],
      participant: {},
      earliestMessageSlug: null,
    }
  }

  static propTypes = {
    currentConversation: PropTypes.arrayOf(PropTypes.object).isRequired,
    chatroomSlug: PropTypes.string.isRequired,
    accountProfile: PropTypes.object.isRequired,
    clearConversation: PropTypes.func.isRequired,
    getConversation: PropTypes.func.isRequired,
    participant: PropTypes.object,
  }

  componentDidMount() {
    listen(SOCKET_CHANNELS.NEW_MESSAGE, (error, result) => {
      const { messages, participant } = this.state

      if (error) {
        return console.log(error)
      }

      if (result.chatroomSlug === this.props.chatroomSlug) {
        this.setState({
          messages: [
            ...messages,
            { ...result, message: messageSerializer(result.message) },
          ],
        })
      }

      if (!participant.slug && !participant.phoneNumber) {
        this.setState({ participant: result.user })
      }

      this.handleScrollToBottom()
    })

    listen(SOCKET_CHANNELS.MESSAGE_STATUS, (error, result) => {
      if (error) {
        return console.log(error)
      }

      const { roomSlug, messageSlug, identifier, status } = result
      const { messages } = this.state

      if (roomSlug === this.props.chatroomSlug) {
        const msgIndex = messages.map(x => x.slug).indexOf(messageSlug)

        if (msgIndex !== -1) {
          const nextMessagesStatus = [...messages[msgIndex].messagesStatus]
          const statusIndex = nextMessagesStatus
            .map(x => x.identifier)
            .indexOf(identifier)

          if (statusIndex !== -1) {
            const nextMsg = merge({}, messages[msgIndex])
            nextMsg.messagesStatus = [
              merge({}, nextMessagesStatus[statusIndex], {
                status,
              }),
            ]
            this.setState({
              messages: messages.map((x, i) => (i === msgIndex ? nextMsg : x)),
            })
          }
        }
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { currentConversation, chatroomSlug, participant } = this.props

    // compare conversation length
    if (prevProps.currentConversation.length !== currentConversation.length) {
      this.setState(
        {
          messages: currentConversation,
          isMinimized: false,
        },
        () => {
          // TODO
          // more than 11 messages *should* mean that we're getting older
          // messages in a conversation and don't want to return to the
          // bottom...but is this really right?
          if (currentConversation.length < 11) {
            this.handleScrollToBottom()
          }
        }
      )

      if (currentConversation.length > 0) {
        emit(SOCKET_CHANNELS.READ_MESSAGES, {
          lastMessageSlug:
            currentConversation[currentConversation.length - 1].slug,
          room: chatroomSlug,
        })
      }
    }

    // update participant info and messages if changed
    if (prevProps.participant.slug !== participant.slug) {
      this.setState({
        participant,
        messages: currentConversation,
      })
    }

    // add scroll listener to retreive past messages
    if (!!this.chatsRef.current) {
      this.chatsRef.current.addEventListener(
        'scroll',
        this.handleScrollListener
      )
    }

    // add click listener to update message read status
    if (!!this.elRef.current) {
      this.elRef.current.addEventListener('click', this.handleReadMessages)
    }
  }

  handleScrollListener = () => {
    if (this.chatsRef.current.scrollTop === 0) {
      const { chatroomSlug, getConversation } = this.props
      const earliestMessageSlug = this.state.messages[0].slug

      getConversation(
        chatroomSlug,
        // TODO
        // change this on API as well - it's confusing
        createQueryString({ lastMessageSlug: earliestMessageSlug })
      )

      // TODO should happen on success
      this.setState({ earliestMessageSlug })
    }
  }

  handleReadMessages = () => {
    const { messages } = this.state

    emit(SOCKET_CHANNELS.READ_MESSAGES, {
      lastMessageSlug: messages[messages.length - 1].slug,
      room: this.props.chatroomSlug,
    })
  }

  handleScrollToBottom = () => {
    this.chatsRef.current &&
      (this.chatsRef.current.scrollTop = this.chatsRef.current.scrollHeight)
  }

  handleToggleChat = () =>
    this.setState({ isMinimized: !this.state.isMinimized })

  handleCloseChat = () => {
    this.props.clearConversation()
    this.setState({ participant: {} })
  }

  handleInputChange = e => this.setState({ message: e.currentTarget.value })

  handleSubmit = event => {
    event.preventDefault()
    const { message } = this.state

    if (message) {
      emit(SOCKET_CHANNELS.SEND_MESSAGE, {
        message,
        room: this.props.chatroomSlug,
      })

      this.setState({ message: '' })
    }
  }

  render() {
    const { accountProfile } = this.props
    const { messages, participant, isMinimized } = this.state

    const prefix = cx({
      dn: isMinimized,
    })

    // TODO
    // there is probably a better solution than this
    const regex = /\/communications\/conversations\/\w{8}/
    const isConversationDetailView = regex.test(global.location.pathname)

    return (
      messages.length > 0 &&
      !isConversationDetailView &&
      (!!participant.slug || !!participant.phoneNumber) && (
        <div
          className="Chat fixed bottom-0 w5 bg-light-gray br2 br--top bt br bl b--silver shadow-4"
          style={{ right: '6rem' }}
          ref={this.elRef}
        >
          <div
            className="pa2 bb b--silver relative pointer hover-bg-moon-gray bg-animate"
            onClick={this.handleToggleChat}
          >
            <H4 noMargin>
              {participant.firstName
                ? `${participant.firstName} ${participant.lastName}`
                : formatPhoneNumber(participant.phoneNumber)}
            </H4>
            <div
              className="absolute top-0 right-0 pa2"
              onClick={this.handleCloseChat}
            >
              <Icon type="close" />
            </div>
          </div>
          <div className={prefix}>
            <div className="overflow-hidden bg-white">
              <ul
                className="h5 overflow-y-scroll list mb0 pa2"
                ref={this.chatsRef}
              >
                {messages.map(x => (
                  <ChatMessage
                    key={x.slug}
                    message={x}
                    accountProfile={accountProfile}
                  />
                ))}
              </ul>
            </div>
            <Form onSubmit={this.handleSubmit} className="pa2" noMargin>
              <div className="flex justify-between items-center">
                <Input
                  className="w-100 mr2"
                  input={{
                    placeholder: 'Message',
                    value: this.state.message,
                    onChange: this.handleInputChange,
                  }}
                  noMargin
                />
                <Button
                  onClick={this.handleSubmit}
                  text="Send"
                  size={SIZES.SMALL}
                  invert
                />
              </div>
            </Form>
          </div>
        </div>
      )
    )
  }
}

export default Chat
