import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { dateTimeFormatter } from '../utils/date'
import { Anchor } from './common/Anchor'
import { Table, Column, Spin } from './common/Ant'
import InputContainer from '../containers/common/InputContainer'
import { toTitleCase } from '../utils/textFormatters'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE, ENTITY_TYPES } from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'
import { createQueryString } from '../utils/queryParams'

class ActivityLog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.activityLog,
      searchText: '',
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }
  }

  static propTypes = {
    activityLog: PropTypes.arrayOf(PropTypes.object).isRequired,
    getActivityLog: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    siteSlug: PropTypes.string.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  componentDidMount() {
    const { getActivityLog, siteSlug } = this.props
    const { page, perPage } = this.state
    const qs = createQueryString({ page, perPage, full: 't' })
    getActivityLog(siteSlug, qs)
  }

  handleChange = (pagination, filters, sorter) => {
    const { getActivityLog, siteSlug } = this.props
    const { page, perPage } = this.state

    if (page !== pagination.current) {
      const query = { page: pagination.current, perPage, full: 't' }
      const qs = createQueryString(query)
      getActivityLog(siteSlug, qs)
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getActivityLog, siteSlug } = this.props
    const { page, perPage } = this.state

    const qs = createQueryString({
      page,
      perPage,
      full: 't',
    })

    getActivityLog(siteSlug, qs)
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.activityLog
        .map(record => {
          const match =
            record.locationEntityType.match(regex) ||
            record.activityType.match(regex) ||
            record.userName.match(regex)

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  createEntityLink = (info, entityType) => {
    const { slug, siteSlug, id, thresholdId, floorId, pillarId, name } = info
    let path
    let entityName = name
    switch (entityType) {
      case ENTITY_TYPES.SITE:
        path = `/sites/${slug}`
        break
      case ENTITY_TYPES.FLOOR:
        path = `/sites/${siteSlug}/floors/${id}`
        break
      case ENTITY_TYPES.LOCATION:
        path = `/sites/${siteSlug}/floors/${floorId}/locations/${id}`
        break
      case ENTITY_TYPES.BUILDING:
        // we don't have a building detail page
        // so just linking to site page
        path = `/sites/${siteSlug}`
        break
      case ENTITY_TYPES.USER:
        path = `/users/${slug}`
        break
      case ENTITY_TYPES.THRESHOLD:
        path = `/rules/thresholds/${thresholdId}`
        break
      case ENTITY_TYPES.GATEWAY:
        path = `/inventory/gateways/${pillarId}`
        entityName = pillarId
        break
      case ENTITY_TYPES.POD:
        path = `/inventory/pods/${pillarId}`
        entityName = pillarId
        break
    }
    return path ? <Anchor to={path}>{entityName}</Anchor> : entityName
  }

  render() {
    const { isLoading, meta } = this.props
    const { data, perPage, sortedInfo, searchText } = this.state
    return isLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <div>
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: searchText,
              onChange: this.handleInputChange,
            }}
          />
        </div>
        <Table
          dataSource={data}
          onChange={this.handleChange}
          scroll={{ x: 350 }}
          rowKey="id"
          pagination={configurePagination({
            perPage,
            onShowSizeChange: (page, perPage) =>
              this.handleFilterChange('perPage')(perPage),
            ...meta,
          })}
          bordered
          tableLayout="auto"
        >
          <Column
            title="Activity"
            width={100}
            dataIndex="activityType"
            activityType
            sorter={sortBy('activityType')}
            sortOrder={sortedInfo.field === 'activityType' && sortedInfo.order}
            render={(text, record) => toTitleCase(text)}
          />
          <Column
            title="Name"
            width={100}
            dataIndex="entityType"
            render={(text, record) =>
              this.createEntityLink(record.entityInfo, text)
            }
          />
          <Column
            title="Parent"
            width={100}
            dataIndex="locationEntityType"
            sorter={sortBy('locationEntityType')}
            sortOrder={
              sortedInfo.field === 'locationEntityType' && sortedInfo.order
            }
            render={(text, record) => toTitleCase(text)}
          />
          <Column
            title="Parent Name"
            width={100}
            dataIndex="locationEntityInfo"
            render={(text, record) =>
              this.createEntityLink(
                record.locationEntityInfo,
                record.locationEntityType
              )
            }
          />
          <Column
            title="Changed By"
            width={100}
            dataIndex="userName"
            sorter={sortBy('userName')}
            sortOrder={sortedInfo.field === 'userName' && sortedInfo.order}
            render={(text, record) => (
              <Anchor to={`/users/${record.userSlug}`}>{text}</Anchor>
            )}
          />
          <Column
            title="Date Changed"
            width={100}
            dataIndex="createdAt"
            sorter={sortBy('createdAt')}
            sortOrder={sortedInfo.field === 'createdAt' && sortedInfo.order}
            render={(text, record) => (text ? dateTimeFormatter(text) : '--')}
          />
        </Table>
      </div>
    )
  }
}

export default ActivityLog
