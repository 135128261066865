import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '../../constants'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more.js'
import solidGauge from 'highcharts/modules/solid-gauge.js'
import HighchartsReact from 'highcharts-react-official'
// init the module
highchartsMore(Highcharts)
solidGauge(Highcharts)

const FlowMonitorChart = ({ flowValue, maxFlow, minFlow }) => {
  const gaugeOptions = {
    credits: { enabled: false },
    exporting: { enabled: false },
    chart: {
      type: 'solidgauge',
      style: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
      },
    },

    pane: {
      center: ['50%', '50%'],
      size: '270px',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: COLORS.LIGHT_GRAY,
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
        borderWidth: 0,
      },
    },

    yAxis: {
      min: minFlow,
      max: maxFlow,
      stops: [
        [0.1, COLORS.GREEN], // green
        [0.5, COLORS.ORANGE], // yellow
        [0.8, COLORS.RED], // red
      ],
      lineWidth: 0,
      minorTickInterval: maxFlow / 10,
      tickInterval: maxFlow / 5,
      tickWidth: 0,
    },

    title: {
      text:
        `<div style="font-size:2.2rem">${parseFloat(flowValue).toFixed(
          2
        )}<br/>` +
        '<span style="font-size:1.1rem;opacity:0.5">gal/min</span></div>',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        color: COLORS.BLACK_80,
        fontWeight: 'bold',
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        name: 'Water Flow',
        data: [parseFloat(flowValue)],
        size: '100%',
        innerSize: '60%',
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  }

  return (
    <div
      className="FlowMonitorChart"
      style={{
        height: '270px',
        overflow: 'hidden',
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={gaugeOptions} />
    </div>
  )
}

FlowMonitorChart.propTypes = {
  flowValue: PropTypes.string.isRequired,
  maxFlow: PropTypes.number.isRequired,
  minFlow: PropTypes.number.isRequired,
}

export default FlowMonitorChart
