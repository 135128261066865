import React, { Component, Fragment } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import {
  Table,
  Column,
  Badge,
  Spin,
  Menu,
  Icon,
  Dropdown,
  RadioButton,
  RadioGroup,
} from './common/Ant'
import { H3 } from './common/Headers'
import { ActionAnchor, Anchor } from './common/Anchor'
import DateRange from './common/DateRange'
import Select, { Option } from './common/Select'
import InputContainer from '../containers/common/InputContainer'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { dateTimeFormatter } from '../utils/date'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { toTitleCase, formatSIMCarrier } from '../utils/textFormatters'
import {
  ALERT_STATUS,
  SIM_CARRIERS,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  VIEW_ALL,
  USER_PERMISSIONS,
  ROLES,
} from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'

class AllGatewayAlerts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.gatewayAlerts,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      deployed: undefined,
      siteSlug: undefined,
      simType: undefined,
      status: undefined,
      to: undefined,
      from: undefined,
      hasComments: undefined,
    }
  }

  static propTypes = {
    gatewayAlerts: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.object,
    getAllGatewayAlerts: PropTypes.func.isRequired,
    closeGatewayAlert: PropTypes.func.isRequired,
    getAllSites: PropTypes.func,
    isRenderingDashboardView: PropTypes.bool,
    isGatewayAlertLoading: PropTypes.bool,
    shouldFetchAllGatewayAlerts: PropTypes.bool,
    sectionTitle: PropTypes.string,
    updateQueryParams: PropTypes.func,
    windowWidth: PropTypes.number.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object),
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  componentDidMount() {
    const {
      getAllSites,
      getAllGatewayAlerts,
      shouldFetchAllGatewayAlerts,
    } = this.props

    const {
      page,
      perPage,
      deployed,
      siteSlug,
      simType,
      status,
      to,
      from,
      hasComments,
    } = parseQueryParams(global.location)

    let query = shouldFetchAllGatewayAlerts
      ? {
          page: page ? parseInt(page, 10) : DEFAULT_PAGE,
          perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
          status,
          deployed,
          simType,
          siteSlug,
        }
      : {
          page: page ? parseInt(page, 10) : DEFAULT_PAGE,
          perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
          status: ALERT_STATUS.ACTIVE,
          deployed: 't',
          simType,
          hasComments,
        }

    if (from && to) {
      this.setState({
        from: moment(from * 1000),
        to: moment(to * 1000),
        ...query,
      })
      query = Object.assign(query, {
        from,
        to,
      })
    }
    const qs = createQueryString(query)
    getAllSites && getAllSites(createQueryString({ perPage: 'all' }))
    getAllGatewayAlerts(qs)
  }

  handleChange = (pagination, filters, sorter) => {
    const {
      shouldFetchAllGatewayAlerts,
      getAllGatewayAlerts,
      updateQueryParams,
      isRenderingDashboardView,
    } = this.props
    const {
      page,
      perPage,
      deployed,
      siteSlug,
      simType,
      status,
      to,
      from,
      hasComments,
    } = this.state

    if (!isRenderingDashboardView && page !== pagination.current) {
      const query = shouldFetchAllGatewayAlerts
        ? {
            page: pagination.current,
            perPage: perPage,
            status,
            deployed,
            siteSlug,
            simType,
            to,
            from,
          }
        : {
            page: pagination.current,
            perPage: perPage,
            deployed: 't',
            simType,
            hasComments,
          }

      const qs = createQueryString(query)

      getAllGatewayAlerts(qs)

      updateQueryParams &&
        updateQueryParams({
          search: qs,
        })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllGatewayAlerts, updateQueryParams } = this.props
    const {
      page,
      perPage,
      deployed,
      siteSlug,
      simType,
      status,
      to,
      from,
      hasComments,
    } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value
    const query = Object.assign(
      {
        page,
        perPage,
        deployed,
        siteSlug,
        simType,
        hasComments,
        status,
        to: to ? to.unix() : undefined,
        from: from ? from.unix() : undefined,
      },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllGatewayAlerts(qs)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }

  handleDateRangeChange = (from, to) => {
    const { getAllGatewayAlerts, updateQueryParams } = this.props
    const { perPage, deployed, siteSlug, simType, status } = this.state

    const query = Object.assign(
      {
        perPage,
        deployed,
        siteSlug,
        simType,
        status,
      },
      {
        from: from ? from.unix() : undefined,
        to: to ? to.unix() : undefined,
        page: DEFAULT_PAGE,
      }
    )

    this.setState({
      page: DEFAULT_PAGE,
      from,
      to,
    })

    const qs = createQueryString(query)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })

    getAllGatewayAlerts(qs)
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.gatewayAlerts
        .map(record => {
          const match =
            (record.siteName && record.siteName.match(regex)) ||
            (record.gatewayName && record.gatewayName.match(regex))

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  render() {
    const {
      isRenderingDashboardView,
      isGatewayAlertLoading,
      meta,
      sectionTitle,
      sites,
      closeGatewayAlert,
      windowWidth,
    } = this.props
    const {
      data,
      sortedInfo,
      searchText,
      perPage,
      status,
      deployed,
      siteSlug,
      simType,
      hasComments,
      to,
      from,
    } = this.state

    const isFixedColumn = windowWidth < 1224

    return (
      <section className="mb5 AllGatewayAlerts">
        {isRenderingDashboardView && (
          <H3>{sectionTitle || 'Network Gateway Alerts'}</H3>
        )}
        {!isRenderingDashboardView && (
          <div>
            <div className="flex-l items-center">
              <RadioGroup
                onChange={({ target: { value } }) =>
                  this.handleFilterChange('status')(value)
                }
                value={status}
                className="flex-l mb3 mr3"
                size="small"
              >
                <RadioButton value={undefined}>All</RadioButton>
                <RadioButton value={ALERT_STATUS.ACTIVE}>Open</RadioButton>
                <RadioButton value={ALERT_STATUS.INACTIVE}>Closed</RadioButton>
              </RadioGroup>
              {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                <RadioGroup
                  onChange={({ target: { value } }) =>
                    this.handleFilterChange('deployed')(value)
                  }
                  value={deployed}
                  className="flex-l mb3 mr3"
                  size="small"
                >
                  <RadioButton value={undefined}>All</RadioButton>
                  <RadioButton value="t">Deployed</RadioButton>
                </RadioGroup>
              )}
              {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                <Fragment>
                  <RadioGroup
                    onChange={({ target: { value } }) =>
                      this.handleFilterChange('hasComments')(value)
                    }
                    value={hasComments}
                    className="flex-l mb3 mr3-l"
                    size="small"
                  >
                    <RadioButton value={undefined}>All</RadioButton>
                    <RadioButton value="t">Commented</RadioButton>
                  </RadioGroup>
                </Fragment>
              )}
              {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY_ALERT) && (
                <Fragment>
                  <div className="w-third-l mr3-l">
                    <DateRange
                      startValue={from}
                      className="flex"
                      endValue={to}
                      onClose={this.handleDateRangeChange}
                      hideTime
                      limitAfterToday
                    />
                  </div>
                  {hasPermission(USER_PERMISSIONS.VIEW_SIM_CARD) && (
                    <Select
                      className="w-20-l mr3-l"
                      placeholder="SIM Type"
                      mode="multiple"
                      input={{
                        value: simType,
                        onChange: this.handleFilterChange('simType'),
                      }}
                      filterable
                    >
                      {['all', ...Object.keys(SIM_CARRIERS)].map(x => (
                        <Option value={x} key={x}>
                          {formatSIMCarrier(SIM_CARRIERS[x]) || VIEW_ALL}
                        </Option>
                      ))}
                    </Select>
                  )}
                  <Select
                    className={
                      hasPermission(USER_PERMISSIONS.VIEW_SIM_CARD)
                        ? 'w-40-l w-100'
                        : 'w-50-l w-100'
                    }
                    placeholder="Site"
                    mode="multiple"
                    input={{
                      value: siteSlug,
                      onChange: this.handleFilterChange('siteSlug'),
                    }}
                    filterable
                  >
                    {[{ slug: 'all', name: VIEW_ALL }, ...sites].map(x => (
                      <Option value={x.slug} key={x.slug}>
                        {x.name}
                      </Option>
                    ))}
                  </Select>
                </Fragment>
              )}
            </div>
            <div className="table-filter">
              <InputContainer
                input={{
                  placeholder: 'Filter',
                  value: searchText,
                  onChange: this.handleInputChange,
                }}
              />
            </div>
          </div>
        )}
        {isGatewayAlertLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{
              x: hasPermission(USER_PERMISSIONS.EDIT_GATEWAY_ALERT) ? 950 : 850,
            }}
            bordered
            tableLayout="auto"
            pagination={
              !isRenderingDashboardView &&
              configurePagination({
                perPage,
                onShowSizeChange: (page, perPage) =>
                  this.handleFilterChange('perPage')(perPage),
                ...meta,
              })
            }
          >
            <Column
              title="Name"
              dataIndex="gatewayName"
              sorter={sortBy('gatewayName')}
              sortOrder={sortedInfo.field === 'gatewayName' && sortedInfo.order}
              width={200}
              render={(text, record) => {
                return text ? (
                  record.active ? (
                    <span className="flex items-center">
                      <Badge status="error" />
                      <Anchor to={`/alerts/gateways/${record.slug}`}>
                        <span className="b">{text}</span>
                      </Anchor>
                    </span>
                  ) : (
                    <span className="flex items-center">
                      <Badge status="default" />
                      <Anchor to={`/alerts/gateways/${record.slug}`}>
                        {text}
                      </Anchor>
                    </span>
                  )
                ) : (
                  '--'
                )
              }}
            />
            <Column
              title="Start"
              dataIndex="startTime"
              sorter={sortBy('startTime')}
              sortOrder={sortedInfo.field === 'startTime' && sortedInfo.order}
              width={200}
              render={text => (text ? dateTimeFormatter(text) : '--')}
            />
            <Column
              title="End"
              dataIndex="endTime"
              sorter={sortBy('endTime')}
              sortOrder={sortedInfo.field === 'endTime' && sortedInfo.order}
              width={200}
              render={text => (text ? dateTimeFormatter(text) : '--')}
            />
            <Column
              title="SIM Type"
              dataIndex="gatewaySim"
              sorter={sortBy('gatewaySim')}
              sortOrder={sortedInfo.field === 'gatewaySim' && sortedInfo.order}
              width={100}
              render={text => (text ? formatSIMCarrier(text) : '--')}
            />
            <Column
              title="Network Gateway Type"
              dataIndex="gatewayType"
              sorter={sortBy('gatewayType')}
              sortOrder={sortedInfo.field === 'gatewayType' && sortedInfo.order}
              width={100}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Site"
              dataIndex="siteName"
              sorter={sortBy('siteName')}
              sortOrder={sortedInfo.field === 'siteName' && sortedInfo.order}
              width={175}
              render={(text, record) =>
                text ? (
                  <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
                ) : (
                  '--'
                )
              }
            />
            {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY_ALERT) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={100}
                render={(text, record) => {
                  if (record.active) {
                    const menu = (
                      <Menu>
                        <Menu.Item key="close-alert">
                          <ActionAnchor
                            onClick={() => closeGatewayAlert(record.slug)}
                          >
                            Close
                          </ActionAnchor>
                        </Menu.Item>
                      </Menu>
                    )
                    return (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  } else {
                    return '--'
                  }
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllGatewayAlerts
