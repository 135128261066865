import React from 'react'
import PropTypes from 'prop-types'

const FormError = ({ error }) => {
  if (error) {
    return (
      <div className="FormError mb3 lh-copy near-black i pa2 br2 bg-washed-red tc">
        {error}
      </div>
    )
  }

  return null
}

FormError.propTypes = {
  error: PropTypes.string,
}

export default FormError
