import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Divider from './common/Divider'
import Input from './common/Input'
import Select, { Option } from './common/Select'
import DateSelector from './common/DateSelector'
import TimeSelector from './common/TimeSelector'
import Button from './common/Button'
import Checkbox from './common/Checkbox'
import FormError from './common/FormError'
import { H3 } from './common/Headers'
import { usePrevious } from '../hooks'
import { isReportTypeDisabled } from '../utils/helpers'
import { createQueryString } from '../utils/queryParams'
import { toTitleCase, renderReportType } from '../utils/textFormatters'
import { hasRole } from '../utils/hasPermission'
import {
  ROLES,
  SENSOR_READING_TYPES,
  REPORT_SENSOR_READING_TYPES,
  REPORT_TYPES,
  REPORT_SEND_DAYS,
  DAYS_INTERVALS,
  TIME_FORMAT,
  ALL,
} from '../constants'

const ScheduleReport = ({
  sites,
  floors,
  locationGroups,
  buildings,
  locations,
  siteUsers,
  reportConfig,
  createReportConfig,
  updateReportConfig,
  getAllSites,
  getAllFloors,
  getAllBuildings,
  getAllUsersBySite,
  getAllLocationsBySite,
  getAllLocationGroups,
}) => {
  const [daysInterval, setDaysInterval] = useState(DAYS_INTERVALS.ONE_WEEK)
  const [reportSendTime, setReportSendTime] = useState(
    moment('09:00', TIME_FORMAT)
  )
  const [reportSendDay, setReportSendDay] = useState(REPORT_SEND_DAYS.MONDAY)
  const [reportStartDate, setReportStartDate] = useState(moment())
  const [repeatUntil, setRepeatUntil] = useState(moment().add(6, 'months'))
  const [userSlugsList, setUserSlugsList] = useState([])
  const [isActive, setIsActive] = useState(true)
  const [siteSlug, setSiteSlug] = useState()
  const [floorsList, setFloorsList] = useState([])
  const [locationGroupsList, setLocationGroupsList] = useState([])
  const [locationsList, setLocationsList] = useState([])
  const [buildingsList, setBuildingsList] = useState([])
  const [reportType, setReportType] = useState(REPORT_TYPES.LOCATIONS_PER_GRAPH)
  const [graphType, setGraphType] = useState([
    SENSOR_READING_TYPES.TEMPERATURE,
    SENSOR_READING_TYPES.HUMIDITY,
  ])
  const [name, setName] = useState('')
  const [includeFlowMonitor, setIncludeFlowMonitor] = useState(true)
  const [includeWeatherData, setIncludeWeatherData] = useState(false)
  const [error, setError] = useState('')

  const [title, setTitle] = useState('')

  const [subjectLine, setSubjectLine] = useState('')

  useEffect(() => {
    getAllSites(createQueryString({ perPage: ALL, full: 't' }))
  }, [getAllSites])

  useEffect(() => {
    if (!!siteSlug && !!title) {
      const site = sites.find(x => x.slug === siteSlug)
      const endDate = new Date().toISOString().split('T')[0]

      setSubjectLine(
        `Pillar <> ${site.companyName} - ${site.name} - ${title} ${endDate}`
      )
    } else {
      setSubjectLine('')
    }
  }, [siteSlug, sites, title])

  useEffect(() => {
    if (siteSlug) {
      getAllFloors(siteSlug)
      getAllBuildings(siteSlug)
      getAllLocationsBySite(siteSlug)
      getAllLocationGroups({
        siteSlug,
        qs: createQueryString({ perPage: ALL }),
      })
      getAllUsersBySite(siteSlug)
    }
  }, [
    getAllFloors,
    getAllBuildings,
    getAllUsersBySite,
    getAllLocationsBySite,
    getAllLocationGroups,
    siteSlug,
  ])

  useEffect(() => {
    if (!!reportConfig) {
      const {
        daysInterval,
        reportSendDay,
        reportSendTime,
        reportStartDate,
        repeatUntil,
        userSlugsList,
        siteSlug,
        floorsList,
        locationGroupsList,
        locationsList,
        buildingsList,
        reportType,
        graphType,
        name,
        title,
        includeFlowMonitor,
        graphWeather: includeWeatherData,
        active,
      } = reportConfig

      setDaysInterval(daysInterval)
      setReportSendDay(reportSendDay)
      setReportSendTime(moment(reportSendTime, TIME_FORMAT))
      setReportStartDate(moment(reportStartDate))
      setRepeatUntil(moment(repeatUntil))
      setUserSlugsList(userSlugsList)
      setSiteSlug(siteSlug)
      setFloorsList(floorsList)
      setLocationGroupsList(locationGroupsList || [])
      setLocationsList(locationsList)
      setBuildingsList(buildingsList)
      setReportType(reportType)
      setGraphType(graphType)
      setName(name)
      setTitle(title)
      setIncludeFlowMonitor(includeFlowMonitor)
      setIncludeWeatherData(includeWeatherData)
      setIsActive(active)
    }
  }, [reportConfig])

  const prevLocationGroupsListLength = usePrevious(locationGroupsList.length)

  useEffect(() => {
    if (
      (locationGroupsList.length > 0 && prevLocationGroupsListLength === 0) ||
      (locationGroupsList.length === 0 && prevLocationGroupsListLength > 0)
    ) {
      setReportType(REPORT_TYPES.LOCATIONS_PER_GRAPH)
    }
  }, [locationGroupsList.length, prevLocationGroupsListLength])

  const handleSubmit = () => {
    if (!name) {
      return setError('Name is required.')
    } else if (!title) {
      return setError('Subject line is required.')
    } else if (!siteSlug) {
      return setError('Site is required.')
    } else if (!graphType.length) {
      return setError('Sensor readings are required.')
    } else if (!userSlugsList.length) {
      return setError('Recipients are required.')
    }

    const report = {
      siteSlug,
      floorsList,
      locationGroupsList,
      locationsList,
      buildingsList,
      graphType,
      reportType,
      name,
      title,
      daysInterval,
      reportSendDay,
      reportSendTime: reportSendTime.format('HH:mm'),
      repeatUntil: parseInt(repeatUntil.valueOf() / 1000, 10),
      active: isActive,
      reportStartDate: parseInt(reportStartDate.valueOf() / 1000, 10),
      userSlugsList,
      includeFlowMonitor,
      graphWeather: includeWeatherData,
    }

    !reportConfig
      ? createReportConfig(report)
      : updateReportConfig({
          ...report,
          slug: reportConfig.slug,
        })
  }

  return (
    <section className="mb3 ScheduleReport">
      <H3>Schedule Recurring Report</H3>
      <div className="mb3">
        <div className="flex-l items-center-l">
          <Input
            label="Name"
            className="w-100"
            placeholder="Report Name"
            input={{
              value: name,
              onChange: ev => setName(ev.currentTarget.value),
            }}
          />
        </div>
        <Divider />
        <div className="flex-l items-start-l">
          <Select
            className="w-33-l mr3-l"
            label="Report Interval"
            placeholder="Select an interval"
            input={{
              value: daysInterval,
              onChange: x => setDaysInterval(x),
            }}
            filterable
          >
            {Object.keys(DAYS_INTERVALS).map(x => (
              <Option value={DAYS_INTERVALS[x]} key={DAYS_INTERVALS[x]}>
                {toTitleCase(x)}
              </Option>
            ))}
          </Select>
          <Select
            className="w-33-l mr3-l"
            label="Delivery Day"
            placeholder="Select a day of the week"
            input={{
              value: reportSendDay,
              onChange: x => setReportSendDay(x),
            }}
            filterable
          >
            {Object.keys(REPORT_SEND_DAYS).map(x => (
              <Option value={REPORT_SEND_DAYS[x]} key={REPORT_SEND_DAYS[x]}>
                {toTitleCase(x)}
              </Option>
            ))}
          </Select>
          <div className="w-33-l mb3">
            <TimeSelector
              label="Delivery Time"
              input={{
                value: reportSendTime,
                onChange: setReportSendTime,
              }}
              format={TIME_FORMAT}
            />
            <div className="f7">
              Note: Report will be sent at the local time of the selected Site.
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex-l">
          <DateSelector
            className="w-50-l mr3-l"
            label="Start Date"
            input={{
              value: reportStartDate,
              onChange: x => setReportStartDate(x),
            }}
            disableTime
            allowClear={false}
          />
          <div className="w-50-l">
            <DateSelector
              label="Repeat Until"
              input={{
                value: repeatUntil,
                onChange: x => setRepeatUntil(x),
              }}
              disableTime
              allowClear={false}
              disabledDateRangeType="before"
            />
            <div className="f7">
              Note: Report will run until selected date{' '}
              <span className="b">or</span> until selected Site is completed.
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="mb3">
        <div className="flex-l items-center-l">
          <Select
            className="w-50-l mr3-l"
            label="Sensor Readings (Four Max)"
            placeholder="Select a sensor reading"
            input={{
              value: graphType,
              onChange: x => x.length < 5 && setGraphType(x),
            }}
            filterable
            mode="multiple"
          >
            {REPORT_SENSOR_READING_TYPES.filter(
              x =>
                x.value !== 'micCount' ||
                hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN)
            ).map(x => (
              <Option value={x.value} key={x.value}>
                {x.name}
              </Option>
            ))}
          </Select>
          <Select
            className="w-50-l"
            label="Report Type"
            placeholder="Select a report type"
            input={{
              value: reportType,
              onChange: x => setReportType(x),
            }}
            filterable
          >
            {Object.keys(REPORT_TYPES).map(x => (
              <Option
                value={x}
                key={x}
                disabled={isReportTypeDisabled(x, !!locationGroupsList.length)}
              >
                {renderReportType(x)}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Checkbox
            className="mr4"
            type="checkbox"
            label="Include Water Monitors"
            input={{
              checked: includeFlowMonitor,
              onChange: setIncludeFlowMonitor,
            }}
          />
          <Checkbox
            type="checkbox"
            label="Include Weather Data"
            input={{
              checked: includeWeatherData,
              onChange: setIncludeWeatherData,
            }}
          />
        </div>
        <Divider />
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Site"
            placeholder="Select a site"
            input={{
              value: siteSlug,
              onChange: x => {
                setSiteSlug(x)
                setBuildingsList([])
                setFloorsList([])
                setLocationsList([])
                setUserSlugsList([])
              },
            }}
            filterable
            disabled={!!reportConfig}
          >
            {sites.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Buildings"
            placeholder="Select Buildings (Default: All Buildings)"
            input={{
              value: buildingsList,
              onChange: x => setBuildingsList(x),
            }}
            filterable
            disabled={!siteSlug}
            mode="multiple"
          >
            {buildings.map(x => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Floors"
            placeholder="Select Floors (Default: All Floors)"
            input={{
              value: floorsList,
              onChange: x => setFloorsList(x),
            }}
            filterable
            disabled={!siteSlug}
            mode="multiple"
          >
            {floors.map(x => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Rooms"
            placeholder="Select Rooms (Default: All Rooms)"
            input={{
              value: locationGroupsList,
              onChange: x => setLocationGroupsList(x),
            }}
            filterable
            disabled={!siteSlug}
            mode="multiple"
          >
            {locationGroups.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Locations"
            placeholder="Select Locations (Default: All Locations)"
            input={{
              value: locationsList,
              onChange: x => setLocationsList(x),
            }}
            filterable
            disabled={!siteSlug}
            mode="multiple"
          >
            {locations.map(x => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <Divider />
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Report Recipients"
            placeholder="Select Users to receive Report"
            input={{
              value: userSlugsList,
              onChange: x => setUserSlugsList(x),
            }}
            disabled={!siteSlug}
            filterable
            mode="multiple"
          >
            {siteUsers.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.firstName} {x.lastName}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <Input
            label="Subject Line"
            className="w-100"
            placeholder="Email Subject Line"
            input={{
              value: title,
              onChange: ev => setTitle(ev.currentTarget.value),
            }}
          />
          <p>
            <span className="b">Preview: </span> {subjectLine}
          </p>
        </div>

        <Divider />
        <FormError error={error} />
        <div className="flex-ns justify-between items-center-ns">
          <Checkbox
            type="checkbox"
            label="Active"
            noMargin
            input={{
              checked: isActive,
              onChange: x => setIsActive(x),
            }}
          />
          <Button
            className="mr3-ns mb3 mb0-ns"
            text="Submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </section>
  )
}

ScheduleReport.propTypes = {
  getAllSites: PropTypes.func.isRequired,
  getAllFloors: PropTypes.func.isRequired,
  getAllBuildings: PropTypes.func.isRequired,
  getAllUsersBySite: PropTypes.func.isRequired,
  getAllLocationGroups: PropTypes.func.isRequired,
  createReportConfig: PropTypes.func.isRequired,
  updateReportConfig: PropTypes.func.isRequired,
  getAllLocationsBySite: PropTypes.func.isRequired,
  reportConfig: PropTypes.object,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ScheduleReport
