import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import FormError from '../common/FormError'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import { required } from '../../utils/validators'

class EditTagForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    submitAction: PropTypes.func.isRequired,
  }

  render() {
    const { submitAction, handleSubmit, submitting, error } = this.props

    const submit = handleSubmit(submitAction)

    return (
      <section className="EditTagForm">
        <Form onSubmit={submit}>
          <div className="flex justify-between">
            <Field
              name="name"
              type="text"
              component={InputContainer}
              label="Tag Name"
              className="w-100-ns"
              validate={[required]}
            />
          </div>
          <div className="db flex justify-between mv3">
            <CancelButton defaultLocation="/metadata/all/tags" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
          <FormError error={error} />
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditTagForm' })(EditTagForm)
