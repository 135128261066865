import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllGatewaySuppliesAction,
  getAllAvailableGatewaySuppliesAction,
  getGatewaySupplyAction,
} from '../actions/gatewaySuppliesActions'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllGatewaySuppliesAction.SUCCESS:
        payload.forEach(gatewaySupply => {
          draft[gatewaySupply.id] = gatewaySupply
        })
        break
    }
  })

const availableById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAvailableGatewaySuppliesAction.SUCCESS:
        payload.forEach(gatewaySupply => {
          draft[gatewaySupply.id] = gatewaySupply
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllGatewaySuppliesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(gatewaySupply => gatewaySupply.id)
        )
        break
    }
  })

const availableVisibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailableGatewaySuppliesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(gatewaySupply => gatewaySupply.id)
        )
        break
    }
  })

const current = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getGatewaySupplyAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const gatewaySuppliesReducer = combineReducers({
  byId,
  visibleIds,
  availableById,
  availableVisibleIds,
  current,
})

const getGatewaySupply = (state, id) => state.byId[id]
const getAvailableGatewaySupply = (state, id) => state.availableById[id]
const getCurrentGatewaySupply = state => state.current
const getVisibleGatewaySupplies = state =>
  state.visibleIds.map(id => getGatewaySupply(state, id))
const getAvailableVisibleGatewaySupplies = state =>
  state.availableVisibleIds.map(id => getAvailableGatewaySupply(state, id))

export {
  gatewaySuppliesReducer as default,
  getCurrentGatewaySupply,
  getVisibleGatewaySupplies,
  getAvailableVisibleGatewaySupplies,
}
