import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { reduxForm, Field } from 'redux-form'
import MainContent from '../common/MainContent'
import { H2 } from '../common/Headers'
import InputContainer from '../../containers/common/InputContainer'
import Button from '../common/Button'
import FormError from '../common/FormError'
import { requestPasswordResetAction } from '../../actions/authActions'
import { required, isEmail } from '../../utils/validators'

const RequestResetPasswordForm = props => {
  const { handleSubmit, submitting, error, submitSucceeded } = props

  const submit = handleSubmit(requestPasswordResetAction)

  return (
    <MainContent>
      <section className="RequestResetPasswordForm">
        <Helmet>
          <title>Request Password Reset Email</title>
        </Helmet>
        <form
          className="measure-narrow center mb2"
          onSubmit={submit}
          noValidate
        >
          <H2>Request Password Reset</H2>
          <Field
            name="email"
            type="email"
            component={InputContainer}
            label="Email"
            validate={[required, isEmail]}
          />
          <FormError error={error} />
          {submitSucceeded ? (
            <p className="mb3">Success! Please check your email.</p>
          ) : (
            <Button
              className="w-100"
              type="submit"
              text="Request Password Reset Email"
              submitting={submitting}
            />
          )}
        </form>
      </section>
    </MainContent>
  )
}

RequestResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({ form: 'RequestResetPasswordForm' })(
  RequestResetPasswordForm
)
