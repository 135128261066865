import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { unauthorizedAction } from '../actions/authActions'
import {
  createNetworkAction,
  updateNetworkAction,
  deleteNetworkAction,
  getAllNetworksAction,
  getNetworkAction,
  createNetworkAuthorityAction,
  updateNetworkAuthorityAction,
  deleteNetworkAuthorityAction,
  getAllNetworkAuthoritiesAction,
  regenerateNetworkAuthorityTokenAction,
  // getNetworkAuthorityTokenHistoryAction,
} from '../actions/networksActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'
import { createQueryString } from '../utils/queryParams'

// handlers

function* handleCreateNetworkSaga({ payload }) {
  try {
    const result = yield call(api.createNetwork, payload)
    yield put(createNetworkAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield fork(handleGetAllNetworksSaga, {
      payload: createQueryString({ perPage: 'all' }),
    })
  } catch (error) {
    yield put(createNetworkAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateNetworkSaga({ payload }) {
  try {
    const result = yield call(api.updateNetwork, payload)
    yield put(updateNetworkAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield fork(handleGetAllNetworksSaga, {
      payload: createQueryString({ perPage: 'all' }),
    })
  } catch (error) {
    yield put(updateNetworkAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteNetworkSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteNetwork, payload)
      yield put(deleteNetworkAction.success())
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
      yield fork(handleGetAllNetworksSaga, {
        payload: createQueryString({ perPage: 'all' }),
      })
    }
  } catch (error) {
    yield put(deleteNetworkAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllNetworksSaga({ payload }) {
  try {
    const result = yield call(api.getAllNetworks, payload)

    yield put(getAllNetworksAction.success(result))
  } catch (error) {
    yield put(getAllNetworksAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateNetworkAuthoritySaga({ payload }) {
  try {
    const result = yield call(api.createNetworkAuthority, payload)
    yield put(createNetworkAuthorityAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield fork(handleGetAllNetworkAuthoritiesSaga, {
      payload: createQueryString({ perPage: 'all' }),
    })
  } catch (error) {
    yield put(createNetworkAuthorityAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateNetworkAuthoritySaga({ payload }) {
  try {
    const { slug, name } = payload
    const result = yield call(api.updateNetworkAuthority, slug, name)
    yield put(updateNetworkAuthorityAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield fork(handleGetAllNetworkAuthoritiesSaga, {
      payload: createQueryString({ perPage: 'all' }),
    })
  } catch (error) {
    yield put(updateNetworkAuthorityAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteNetworkAuthoritySaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteNetworkAuthority, payload)
      yield put(deleteNetworkAuthorityAction.success())
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
      yield fork(handleGetAllNetworkAuthoritiesSaga, {
        payload: createQueryString({ perPage: 'all' }),
      })
    }
  } catch (error) {
    yield put(deleteNetworkAuthorityAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllNetworkAuthoritiesSaga({ payload }) {
  try {
    const result = yield call(api.getAllNetworkAuthorities, payload)

    yield put(getAllNetworkAuthoritiesAction.success(result))
  } catch (error) {
    yield put(getAllNetworkAuthoritiesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleRegenerateNetworkAuthorityTokenSaga({ payload }) {
  try {
    const result = yield call(api.regenerateNetworkAuthorityToken, payload)

    yield put(regenerateNetworkAuthorityTokenAction.success(result))
  } catch (error) {
    yield put(regenerateNetworkAuthorityTokenAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetNetworkSaga({ payload }) {
  try {
    const result = yield call(api.getNetworkBySlug, payload)

    yield put(getNetworkAction.success(result))
  } catch (error) {
    yield put(getNetworkAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// TODO
// function* handleGetNetworkAuthorityTokenHistorySaga({ payload }) {
//   try {
//     const result = yield call(api.getNetworkAuthorityTokenHistory, payload)

//     yield put(getNetworkAuthorityTokenHistoryAction.success(result))
//   } catch (error) {
//     yield put(getNetworkAuthorityTokenHistoryAction.failure(error))
//     yield put(showErrorMessageAction(pickErrorMessage(error)))

//     if (error.status === 401) {
//       yield put(unauthorizedAction())
//     }
//   }
// }

// watchers

function* watchCreateNetworkSaga() {
  yield takeLatest(createNetworkAction.REQUEST, handleCreateNetworkSaga)
}

function* watchUpdateNetworkSaga() {
  yield takeLatest(updateNetworkAction.REQUEST, handleUpdateNetworkSaga)
}

function* watchDeleteNetworkSaga() {
  yield takeLatest(deleteNetworkAction.REQUEST, handleDeleteNetworkSaga)
}

function* watchGetAllNetworksSaga() {
  yield takeLatest(getAllNetworksAction.REQUEST, handleGetAllNetworksSaga)
}

function* watchCreateNetworkAuthoritySaga() {
  yield takeLatest(
    createNetworkAuthorityAction.REQUEST,
    handleCreateNetworkAuthoritySaga
  )
}

function* watchUpdateNetworkAuthoritySaga() {
  yield takeLatest(
    updateNetworkAuthorityAction.REQUEST,
    handleUpdateNetworkAuthoritySaga
  )
}

function* watchDeleteNetworkAuthoritySaga() {
  yield takeLatest(
    deleteNetworkAuthorityAction.REQUEST,
    handleDeleteNetworkAuthoritySaga
  )
}

function* watchGetAllNetworkAuthoritiesSaga() {
  yield takeLatest(
    getAllNetworkAuthoritiesAction.REQUEST,
    handleGetAllNetworkAuthoritiesSaga
  )
}

function* watchRegenerateNetworkAuthorityTokenSaga() {
  yield takeLatest(
    regenerateNetworkAuthorityTokenAction.REQUEST,
    handleRegenerateNetworkAuthorityTokenSaga
  )
}

function* watchGetNetworkSaga() {
  yield takeLatest(getNetworkAction.REQUEST, handleGetNetworkSaga)
}

// TODO
// function* watchGetNetworkAuthorityTokenHistorySaga() {
//   yield takeLatest(
//     getNetworkAuthorityTokenHistoryAction.REQUEST,
//     handleGetNetworkAuthorityTokenHistorySaga
//   )
// }

function* networksSaga() {
  yield all([
    fork(watchCreateNetworkAuthoritySaga),
    fork(watchUpdateNetworkAuthoritySaga),
    fork(watchDeleteNetworkAuthoritySaga),
    fork(watchGetAllNetworkAuthoritiesSaga),
    fork(watchCreateNetworkSaga),
    fork(watchUpdateNetworkSaga),
    fork(watchDeleteNetworkSaga),
    fork(watchGetAllNetworksSaga),
    fork(watchRegenerateNetworkAuthorityTokenSaga),
    fork(watchGetNetworkSaga),
    // fork(watchGetNetworkAuthorityTokenHistorySaga),
  ])
}

export { networksSaga as default }
