import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllActionReportCausesAction,
  getActionReportCauseAction,
} from '../actions/actionReportsActions'

const causesBySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllActionReportCausesAction.SUCCESS:
        payload.items.forEach(x => (draft[x.slug] = x))
        break
    }
  })

const causesVisibleSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllActionReportCausesAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const causesMeta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllActionReportCausesAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const currentCause = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getActionReportCauseAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const actionReportsReducer = combineReducers({
  causesBySlug,
  causesVisibleSlugs,
  causesMeta,
  currentCause,
})

const getActionReportCause = (state, slug) => state.causesBySlug[slug]
const getVisibleActionReportCauses = state =>
  state.causesVisibleSlugs.map(slug => getActionReportCause(state, slug))
const getActionReportCausesMeta = ({ causesMeta }) => causesMeta
const getCurrentActionReportCause = ({ currentCause }) => currentCause

export {
  actionReportsReducer as default,
  getVisibleActionReportCauses,
  getActionReportCausesMeta,
  getCurrentActionReportCause,
}
