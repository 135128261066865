import React from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import Logo from './common/Logo'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'


const BuildingHeader = ({ site, building, match }) => {
  const { name: siteName, slug: siteSlug, companyName, companyLogo } = site

  return (
    <div className="BuildingHeader">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>
              <Anchor to="/sites">Sites</Anchor>
            </Crumb>
            {siteName && (
              <Crumb>
                <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
              </Crumb>
            )}
            {building.name && <Crumb>{building.name}</Crumb>}
          </Bread>
        </div>
        <div className="flex justify-center">
          <Logo companyName={companyName} companyLogo={companyLogo} />
        </div>
        <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
            }}
        >
            {hasPermission(USER_PERMISSIONS.EDIT_BUILDING) && (
            <div className="flex justify-center" style={{padding: 5,}}>
                <Anchor 
                    to={`${match.url}/edit`}
                    className="mb3"
                    button
                    >
                        Edit Building
                    </Anchor>
            </div>
            )}
            {hasPermission(USER_PERMISSIONS.BUILDING_EVACUATION) && (
            <div className='flex justify-center' >
                <Anchor
                    to={`/sites/${siteSlug}/buildings/${building.id}/evacuate`}
                    className="mb3 mr3"
                    buttonVariant="danger"
                    button
                >
                Evacuate Building
                </Anchor>
            </div>
            )}
            {hasPermission(USER_PERMISSIONS.BUILDING_EVACUATION) && (
            <div className='flex justify-center' >
                <Anchor
                    to={`/sites/${siteSlug}/buildings/${building.id}/disable-evac`}
                    className="mb3 mr3"
                    button
                >
                Shut off Evacuation
                </Anchor>
            </div>
            )}
        </div>
        
      </div>
    </div>
  )
}

BuildingHeader.propTypes = {
  site: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  match: PropTypes.object,
}

export default BuildingHeader
