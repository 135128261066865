import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import { Spin } from './common/Ant'
import { toTitleCase, formatSIMCarrier } from '../utils/textFormatters'
import { formatPhoneNumber } from '../utils/forms'
import { dateTimeFormatter } from '../utils/date'
import { USER_PERMISSIONS } from '../constants'
import { hasPermission } from '../utils/hasPermission'

class SIMCard extends Component {
  static propTypes = {
    simCard: PropTypes.object.isRequired,
    getSIMCard: PropTypes.func.isRequired,
    isSIMCardLoading: PropTypes.bool,
    match: PropTypes.object,
  }

  componentDidMount() {
    this.props.getSIMCard(this.props.match.params.id)
  }

  render() {
    const { simCard, isSIMCardLoading, match } = this.props

    const {
      status,
      carrier,
      phoneNumber,
      simNumber,
      name,
      simUsage,
      associatedImei,
      accountGroup,
      accountNumber,
      assignedTo,
      gatewayPillarId,
      flowMonitorPillarId,
      flowMonitorId,
      assetLocationPillarId,
      deviceModel,
      planName,
      planType,
      planPrice,
      dateActivated,
      dateEnd,
    } = simCard

    return isSIMCardLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="SIMCard mb3">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/inventory/all">Inventory</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to={`/inventory/all/sim-cards`}>SIM Cards</Anchor>
              </Crumb>
              <Crumb>{name ? name : simNumber}</Crumb>
            </Bread>
          </div>
          <div className="flex justify-center mb3">
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          </div>
          <Divider />
        </div>
        <div className="flex flex-wrap">
          <Block label="SIM ID">{simNumber}</Block>
          <Block label="Carrier">{formatSIMCarrier(carrier)}</Block>
          <Block label="Usage">{toTitleCase(simUsage)}</Block>
          <Block label="Name">{name}</Block>
          <Block label="Asset Location">
            {assetLocationPillarId && (
              <Anchor
                to={`/inventory/asset-locations/${assetLocationPillarId}`}
              >
                {assetLocationPillarId}
              </Anchor>
            )}
          </Block>
          <Block label="Network Gateway">
            {gatewayPillarId && (
              <Anchor to={`/inventory/gateways/${gatewayPillarId}`}>
                {gatewayPillarId}
              </Anchor>
            )}
          </Block>
          <Block label="Water Monitor">
            {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) &&
              flowMonitorPillarId && (
                <Anchor to={`/inventory/flow-monitors/${flowMonitorId}`}>
                  {flowMonitorPillarId}
                </Anchor>
              )}
          </Block>
          <Block label="Assigned To">{assignedTo}</Block>
          <Block label="Status">{toTitleCase(status)}</Block>
          <Block label="Account Group">{accountGroup}</Block>
          <Block label="Account Number">{accountNumber}</Block>
          <Block label="Associated IMEI">{associatedImei}</Block>
          <Block label="Phone">{formatPhoneNumber(phoneNumber)}</Block>
          <Block label="Device Model">{deviceModel}</Block>
          <Block label="Plan Name">{planName}</Block>
          <Block label="Plan Type">{planType}</Block>
          <Block label="Plan Price">{planPrice ? `$${planPrice}` : '--'}</Block>
          <Block label="Date Activated">
            {dateTimeFormatter(dateActivated)}
          </Block>
          <Block label="End of Contract">{dateTimeFormatter(dateEnd)}</Block>
        </div>
      </section>
    )
  }
}

export default SIMCard
