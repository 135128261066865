import React, { Component, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import { Table, Column, Tabs, TabPane, Spin } from './common/Ant'
import { formatSIMCarrier, toTitleCase } from '../utils/textFormatters'
import { formatPhoneNumber } from '../utils/forms'
import { FaLock } from "react-icons/fa";

import {
  SIM_CARRIERS,
  USER_PERMISSIONS,
  FLOW_MONITOR_STATUS,
  WATER_METER_TYPES,
  MESSAGES,
  WATER_VALVE_STATUS,
  WATER_VALVE_TYPES,
} from '../constants'
import EditHistory from './EditHistory'
import AssignmentHistory from './AssignmentHistory'
import ValveStatusHistory from './ValveStatusHistory'
import FlowMonitorData from './FlowMonitorData'
import { hasPermission } from '../utils/hasPermission'
import { getPathSnippets } from '../utils/helpers'
import { H3 } from './common/Headers'
// import Checkbox from './common/Checkbox'
import { dateTimeFormatter } from '../utils/date'
import { TripleSwitch } from './common/TripleSwitch'
import api from '../services/api'
import FlowMonitorSchedule from './flow_monitor/FlowMonitorSchedule'

class FlowMonitor extends Component {
  static propTypes = {
    currentFlowId: PropTypes.string,
    updateQueryParams: PropTypes.func.isRequired,
    flowMonitorData: PropTypes.object.isRequired,
    updateFlowMonitor: PropTypes.func.isRequired,
    getFlowMonitorData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    flowMonitor: PropTypes.object.isRequired,
    getFlowMonitor: PropTypes.func.isRequired,
    isFlowMonitorLoading: PropTypes.bool,
    isHistoryLoading: PropTypes.bool.isRequired,
    assignmentHistory: PropTypes.arrayOf(PropTypes.object),
    editHistory: PropTypes.arrayOf(PropTypes.object),
    getAssignmentHistory: PropTypes.func.isRequired,
    valveStatusHistory: PropTypes.arrayOf(PropTypes.object),
    getValveStatusHistory: PropTypes.func.isRequired,
    getEditHistory: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  state = {
    activeTab: undefined,
  }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const { getFlowMonitor, match } = this.props
    getFlowMonitor(match.params.id)

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet)
  }

  handleTabSelection = entity => {
    const { updateQueryParams, match } = this.props
    const { activeTab } = this.state

    this.setState({ activeTab: entity === match.params.id ? 'details' : entity })

    updateQueryParams({
      pathname: `${match.url}/${entity}`,
      search: !activeTab || entity === activeTab ? global.location.search : '',
    })
  }

  statusToText = (status) => {
    const { flowMonitor } = this.props;
    const { hwSwitchLocked } = flowMonitor;

    if (!status) return "Unknown";

    let text = toTitleCase(status)
    if (text === "Close") {
        text = "Closed"
    }

    if (hwSwitchLocked === "True") text += " (locked)"

    return text
  }

  valveStatusText = (text) => {
    const { flowMonitor } = this.props;
    const { thresholdCondition } = flowMonitor;

    if (!text) return "---";

    let res = toTitleCase(text)
    if (res === "Close") {
        res = "Closed"
    }

    if (thresholdCondition === "True") res += " (threshold exceeded)"

    return res
  }

  handleValveStatusChange = status => {
    const { flowMonitor } = this.props
    const { hwSwitchLocked } = flowMonitor
    const status_msg = {
        "OPEN" : MESSAGES.CONFIRM_WATER_VALVE_OPEN,
        "CLOSE" : MESSAGES.CONFIRM_WATER_VALVE_CLOSE,
        "AUTO" : MESSAGES.CONFIRM_WATER_VALVE_AUTO
    }

    if (hwSwitchLocked !== "True") {
        let msg = status_msg[status]
        const change = global.confirm(msg)

        if (change) {
            api.setWaterValveStatus(flowMonitor.id, status)
        }

        return change;
    }

    // global.confirm("The physical switch on the water valve is not set to auto. Please set the physical switch to auto to change the status from the app.")
    global.confirm("The physical switch on the water valve is locked. Please set the physical switch to auto to change the status from the app.")
    return false;
  }

//   handleScheduleStatusChange = entry => {
//     const { updateFlowMonitor, flowMonitor } = this.props
//     let msg = entry ? MESSAGES.CONFIRM_WATER_VALVE_SCHEDULE : MESSAGES.CONFIRM_WATER_VALVE_MANUAL
//     let schedule_status = entry ? WV_SCHEDULE_STATUS.SCHEDULE : WV_SCHEDULE_STATUS.MANUAL
//     const change = global.confirm(msg)

//     if (change) {
//       updateFlowMonitor({
//         ...flowMonitor,
//         valveStatusChange: true,
//         valveStatus: schedule_status,
//       })
//     }
//   }

  render() {
    const {
      flowMonitor,
      updateQueryParams,
      getFlowMonitorData,
      flowMonitorData,
      isLoading,
      isFlowMonitorLoading,
      currentFlowId,
      isHistoryLoading,
      editHistory,
      assignmentHistory,
      getEditHistory,
      getAssignmentHistory,
      valveStatusHistory,
      getValveStatusHistory,
      match,
    } = this.props

    const {
      id,
      pillarId,
      podId,
      podPillarId,
      meterSerialId,
      piSerialId,
      meterType,
      pipeOd,
      pipeId,
      pipeMaxFlow,
      pipeMinFlow,
      pipeMaxTemp,
      pipeMinTemp,
      flowOffset,
      locationId,
      floorId,
      siteSlug,
      siteName,
      locationName,
      simType,
      simCardNumber,
      simCardId,
      phoneNumber,
      status,
      description,
      valveId,
      valveStatus,
      valveSerialId,
      valveType,
      valvePipeOd,
      valvePipeId,
      valveDescription,
      hwSwitchStatus,
      hwSwitchLocked,
      uiSwitchStatus
    } = flowMonitor
    const flowId = pillarId ? pillarId : currentFlowId
    const lastData =
      flowMonitorData && flowMonitorData.lastData
        ? flowMonitorData.lastData
        : {}
    const valveData = valveId
      ? [
          {
            expectedStatus: uiSwitchStatus ? uiSwitchStatus : '',
            time: lastData ? dateTimeFormatter(lastData.time) : '',
            valveSwitch: hwSwitchStatus ? hwSwitchStatus : "Auto",
            valveAnalog:
              lastData && lastData.valveAnalog ? lastData.valveAnalog : '',
            currentStatus: valveStatus,
            dataSource: lastData && lastData.source ? lastData.source : '',
          },
        ]
      : []
    
    let expectedStatus = uiSwitchStatus ? uiSwitchStatus : ''

    console.log("flow monitor: ", flowMonitor)

    return isFlowMonitorLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="FlowMonitor mb3">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/inventory/all">Inventory</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to={`/inventory/all/flow-monitors`}>
                  Water Monitors
                </Anchor>
              </Crumb>
              <Crumb>{pillarId ? pillarId : id}</Crumb>
            </Bread>
          </div>
          {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) && (
            <div className="flex justify-center mb3">
              <Anchor to={`${match.url}/edit`}>Edit</Anchor>
            </div>
          )}
          <div className="flex justify-center mb3">
              <Anchor
                to={`${match.url}/flow-schedule`}
                className="mb3 mr3"
                button
              >Schedule</Anchor>
          </div>
          <Divider />
        </div>
        {valveId ? (
          <div>
            <div className="w-100-ns flex">
            {/* <div className="flex"> */}
              <div>
                <H3>Valve Status</H3>
              </div>
              {
              expectedStatus === WATER_VALVE_STATUS.AUTO ||
              expectedStatus === WATER_VALVE_STATUS.OPEN ||
              expectedStatus === WATER_VALVE_STATUS.CLOSE ? (
                <div style={{paddingBottom: "60px", paddingRight: "250px", marginLeft: "auto"}}>
                <span className='mr3-ns'>
                  {
                    hwSwitchLocked === "True" ? <FaLock /> : ""
                  }
                  <TripleSwitch
                    labels={{
                      left: {
                        title: toTitleCase(WATER_VALVE_STATUS.OPEN),
                        value: WATER_VALVE_STATUS.OPEN
                      },
                      center: {
                        title: toTitleCase(WATER_VALVE_STATUS.AUTO),
                        value: WATER_VALVE_STATUS.AUTO
                      },
                      right: {
                        title: toTitleCase(WATER_VALVE_STATUS.CLOSE),
                        value: WATER_VALVE_STATUS.CLOSE
                      }
                    }}
                    onChange={this.handleValveStatusChange}
                    value={expectedStatus}
                    noMargin
                  />
                </span>
                </div>
              ) : ("")
            }
            </div>
            <span>
            <Table
              dataSource={valveData}
              rowKey="time"
              //scroll={{ x: 400 }}
              pagination={false}
              tableLayout="auto"
              bordered
            >
              <Column
                title="Last Reading"
                width={200}
                dataIndex="time"
                render={(text, record) =>
                  text ? dateTimeFormatter(text) : '--'
                }
              />
              <Column
                title="Hardware Switch Status"
                width={200}
                dataIndex="valveSwitch"
                render={(text, record) => (this.statusToText(text))}
              />
              <div>
                  <Column
                  title="Valve Status"
                  width={200}
                  dataIndex="currentStatus"
                  render={(text, record) => (this.valveStatusText(text))}
                  />
              </div>
            </Table>
            </span>
          </div>
        ) : (
          ''
        )}
        {flowId && (
          <FlowMonitorData
            pillarId={flowId}
            updateQueryParams={updateQueryParams}
            isLoading={isLoading}
            getFlowMonitorData={getFlowMonitorData}
            flowMonitorData={flowMonitorData}
          />
        )}
        <Divider />
        <Switch>
          <Route
            path={`${match.url}/`}
            exact
            render={props => <Redirect to={`${match.url}/details`} />}
          />
          <Fragment>
            <Tabs
              defaultActiveKey="details"
              type="card"
              activeKey={this.state.activeTab}
              onTabClick={this.handleTabSelection}
            >
              <TabPane tab="Details" key="details">
                <Route
                  path={`${match.url}/details`}
                  exact
                  render={props => (
                    <div>
                      <div className="flex flex-wrap">
                        <Block label="Pillar ID">{pillarId}</Block>
                        <Block label="Site">
                          {siteSlug ? (
                            <Anchor to={`/sites/${siteSlug}`}>
                              {siteName}
                            </Anchor>
                          ) : (
                            '--'
                          )}
                        </Block>
                        <Block label="Location">
                          {locationId ? (
                            <Anchor
                              to={`/sites/${siteSlug}/floors/${floorId}/locations/${locationId}/flow-monitor-chart`}
                            >
                              {locationName}
                            </Anchor>
                          ) : (
                            '--'
                          )}
                        </Block>
                        <Block label="Status">
                          {toTitleCase(FLOW_MONITOR_STATUS[status])}
                        </Block>
                        <Block label="POD">
                          {podId ? (
                            hasPermission(USER_PERMISSIONS.VIEW_POD) ? (
                              <span>
                                <Anchor to={`/inventory/pods/${podPillarId}`}>
                                  {podPillarId}
                                </Anchor>
                              </span>
                            ) : (
                              <span>({podPillarId})</span>
                            )
                          ) : (
                            '--'
                          )}
                        </Block>
                        <Block label="RasPi Serial Id">{piSerialId}</Block>
                        <Divider />
                        <Block label="Meter Serial Id">{meterSerialId}</Block>
                        <Block label="Meter Type">
                          {WATER_METER_TYPES[meterType]}
                        </Block>
                        <Block label="Pipe OD">{pipeOd}</Block>
                        <Block label="Pipe ID">{pipeId}</Block>
                        <Block label="Max Flow">{pipeMaxFlow}</Block>
                        <Block label="Min Flow">{pipeMinFlow}</Block>
                        <Block label="Max Temp">{pipeMaxTemp}</Block>
                        <Block label="Min Temp">{pipeMinTemp}</Block>
                        <Block label="Flow Offset">{flowOffset}</Block>
                        {meterSerialId ? <Divider /> : ''}
                        <Block label="Valve Serial Id">{valveSerialId}</Block>
                        <Block label="Valve Type">
                          {toTitleCase(WATER_VALVE_TYPES[valveType])}
                        </Block>
                        <Block label="Expected Valve Status">
                          {toTitleCase(WATER_VALVE_STATUS[valveStatus])}
                        </Block>
                        <Block label="Valve Pipe OD">{valvePipeOd}</Block>
                        <Block label="Valve Pipe ID">{valvePipeId}</Block>
                        <Block label="Valve description">
                          {valveDescription}
                        </Block>
                        {valveSerialId ? <Divider /> : ''}
                        <Block label="SIM Type">
                          {formatSIMCarrier(SIM_CARRIERS[simType])}
                        </Block>
                        <Block label="SIM Card Number">
                          {simCardNumber ? (
                            <Anchor to={`/inventory/sim-cards/${simCardId}`}>
                              {simCardNumber}
                            </Anchor>
                          ) : (
                            '--'
                          )}
                        </Block>
                        <Block label="Phone">
                          {formatPhoneNumber(phoneNumber)}
                        </Block>
                        <Block label="Description">{description}</Block>
                      </div>
                    </div>
                  )}
                />
              </TabPane>
              {/* <TabPane tab="Valve Schedule" key="valve-schedule">
                <Route
                  path={`${match.url}/flow-schedule`}
                  exact
                  render={props => (
                    <FlowMonitorSchedule
                        pillarId={id}
                    />
                  )}
                />
              </TabPane> */}
              <TabPane tab="Assignment History" key="assignment-history">
                <Route
                  path={`${match.url}/assignment-history`}
                  exact
                  render={props => (
                    <AssignmentHistory
                      assignmentHistory={assignmentHistory}
                      getAssignmentHistory={getAssignmentHistory}
                      resourceId={id}
                      isLoading={isHistoryLoading}
                    />
                  )}
                />
              </TabPane>
              <TabPane tab="Edit History" key="edit-history">
                <Route
                  path={`${match.url}/edit-history`}
                  exact
                  render={props => (
                    <EditHistory
                      editHistory={editHistory}
                      getEditHistory={getEditHistory}
                      resourceId={id}
                      isLoading={isHistoryLoading}
                    />
                  )}
                />
              </TabPane>
              {valveId && (
                <TabPane tab="Valve Status History" key="valve-status-history">
                  <Route
                    path={`${match.url}/valve-status-history`}
                    exact
                    render={props => (
                      <ValveStatusHistory
                        valveStatusHistory={valveStatusHistory}
                        getValveStatusHistory={getValveStatusHistory}
                        valveId={valveId}
                        isLoading={isHistoryLoading}
                      />
                    )}
                  />
                </TabPane>
              )}
              
              {/* {valveId && (
                <TabPane tab="Valve Schedule" key="valve-schedule">
                  <Route
                    path={`${match.url}/flow-schedule`}
                    exact
                    render={props => (
                      <FlowMonitorSchedule 
                        flowId={currentFlowId}
                      />
                    )}
                  />
                </TabPane>
              )} */}
            </Tabs>
          </Fragment>
        </Switch>
      </section>
    )
  }
}

export default FlowMonitor
