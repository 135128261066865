import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Bread, Crumb } from './common/Bread'
import { Tabs, TabPane, Spin, Tooltip, Icon } from './common/Ant'
import { ActionAnchor, Anchor } from './common/Anchor'
import Block from './common/Block'
import Divider from './common/Divider'
import AlertChart from './charts/AlertChart'
import AllComments from './AllComments'
import AllPodAlerts from './AllPodAlerts'
import AllFlowAlerts from './AllFlowAlerts'
import AlertAuditLog from './AlertAuditLog'
import TinyFloorPlan from './TinyFloorPlan'
import ActionReport from './ActionReport'
import { dateTimeFormatter } from '../utils/date'
import { hasPermission, hasRole } from '../utils/hasPermission'
import {
  ALERT_TYPES,
  ALERT_STATES,
  RESOURCE_TYPES,
  SIZES,
  USER_PERMISSIONS,
  ROLES,
  SYSTEM_ALERT_TYPES,
} from '../constants'
import { getPathSnippets } from '../utils/helpers'

class Alert extends Component {
  static propTypes = {
    currentAlert: PropTypes.object.isRequired,
    currentAlertChartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAlert: PropTypes.func.isRequired,
    clearAlert: PropTypes.func.isRequired,
    clearLocationChartData: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    unhideAlert: PropTypes.func.isRequired,
    closeAlert: PropTypes.func.isRequired,
    acknowledgeAlert: PropTypes.func.isRequired,
    resolveAlert: PropTypes.func.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    isAlertLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool,
    accountProfile: PropTypes.object,
    match: PropTypes.object,
    getAllComments: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    alertAuditLog: PropTypes.arrayOf(PropTypes.object).isRequired,
    locationAlertHistory: PropTypes.arrayOf(PropTypes.object),
    getLocationAlertHistory: PropTypes.func,
    windowWidth: PropTypes.number,
    getAlertAuditLog: PropTypes.func.isRequired,
    meta: PropTypes.object,
    updateQueryParams: PropTypes.func.isRequired,
    systemAlertType: PropTypes.string.isRequired,
  }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const { getAlert } = this.props
    getAlert && getAlert(this.props.match.params.slug)

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet)
  }

  componentWillUnmount() {
    this.props.clearAlert()
    this.props.clearLocationChartData()
  }

  handleTabSelection = entity => {
    const {
      updateQueryParams,
      match,
      currentAlert: { enableActionReport },
    } = this.props
    const { activeTab } = this.state

    if (entity === match.params.slug) {
      if (hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN)) {
        this.setState({ activeTab: 'comments' })
      } else if (enableActionReport) {
        this.setState({ activeTab: 'action-report' })
      }
    } else {
      this.setState({ activeTab: entity })
    }

    updateQueryParams({
      pathname:
        entity === match.params.slug
          ? `${match.url}`
          : `${match.url}/${entity}`,
      search: entity === activeTab ? global.location.search : '',
    })
  }

  state = {
    commentStartTime: undefined,
    commentEndTime: undefined,
  }

  setCommentTime = (commentStartTime, commentEndTime) =>
    this.setState({ commentStartTime, commentEndTime })

  render() {
    const {
      currentAlert,
      currentAlertChartData,
      hideAlert,
      unhideAlert,
      closeAlert,
      acknowledgeAlert,
      resolveAlert,
      accountProfile,
      isChartDataLoading,
      isAlertLoading,
      isHistoryLoading,
      comments,
      getAllComments,
      deleteComment,
      createComment,
      editComment,
      getAllTags,
      tags,
      match,
      alertAuditLog,
      getAlertAuditLog,
      locationAlertHistory,
      getLocationAlertHistory,
      windowWidth,
      meta,
      systemAlertType,
    } = this.props

    const {
      id,
      slug,
      thresholdName,
      thresholdId,
      siteName,
      siteSlug,
      floorId,
      locationId,
      locationName,
      floorNum,
      podPillarId,
      flowMonitorPillarId,
      startTime,
      endTime,
      alertActive,
      alertState,
      escalationPolicyActions = {},
      hidden,
      threshold,
      alertType,
      companyAlertId,
      enableActionReport,
      // TODO remove throughout app
      // actionReportCauses,
      companyId,
      actionReport,
    } = currentAlert

    const { commentEndTime, commentStartTime } = this.state

    const thresholdAnchor =
      hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) && thresholdName ? (
        <Anchor to={`/rules/thresholds/${thresholdId}`}>{thresholdName}</Anchor>
      ) : (
        thresholdName
      )

    const alertName = (
      <span>
        {thresholdName
          ? thresholdAnchor
          : flowMonitorPillarId
          ? 'Water Monitor Offline'
          : systemAlertType === SYSTEM_ALERT_TYPES.ANOMALY
          ? 'Anomaly'
          : systemAlertType === SYSTEM_ALERT_TYPES.WET_POD
          ? 'Wet Smart Pod'
          : 'Smart Pod Offline'}
      </span>
    )

    const floorInfo = {
      planFileName: currentAlert.floorPlanFileName,
      planZoomLevel: currentAlert.floorPlanZoomLevel,
      floorplanHeight: currentAlert.floorFloorplanHeight,
      floorplanWidth: currentAlert.floorFloorplanWidth,
    }

    return isAlertLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Alert mb3">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              {flowMonitorPillarId ? (
                <Crumb>
                  <Anchor to="/alerts/all/flow-monitors">Alerts</Anchor>
                </Crumb>
              ) : (
                <Crumb>
                  <Anchor to="/alerts/all/pods">Alerts</Anchor>
                </Crumb>
              )}
              <Crumb>
                {alertName} {companyAlertId && `(${companyAlertId})`}{' '}
                {alertType === ALERT_TYPES.SYSTEM ? (
                  systemAlertType === SYSTEM_ALERT_TYPES.ANOMALY ? (
                    <Tooltip title="Anomaly">
                      <Icon type="deployment-unit" className="pl1 orange f4" />
                    </Tooltip>
                  ) : systemAlertType === SYSTEM_ALERT_TYPES.WET_POD ? (
                    <Tooltip title="Wet Smart Pod">
                      <Icon type="thunderbolt" className="pl1 red f4" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Offline">
                      <Icon type="poweroff" className="pl1 red f4" />
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title="Online">
                    <Icon type="check-circle-o" className="pl1 green f4" />
                  </Tooltip>
                )}
              </Crumb>
            </Bread>
            <Bread size={SIZES.SMALL}>
              {!!siteName && (
                <Crumb>
                  {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) ? (
                    <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
                  ) : (
                    siteName
                  )}
                </Crumb>
              )}
              {!!floorId && (
                <Crumb>
                  {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) ? (
                    <Anchor to={`/sites/${siteSlug}/floors/${floorId}`}>
                      Floor {floorNum}
                    </Anchor>
                  ) : (
                    `Floor ${floorNum}`
                  )}
                </Crumb>
              )}
              {!!locationName && (
                <Crumb>
                  {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) &&
                  locationName ? (
                    <Anchor
                      to={`/sites/${siteSlug}/floors/${floorId}/locations/${locationId}/${
                        flowMonitorPillarId ? 'flow-monitor-chart' : 'chart'
                      }`}
                    >
                      {locationName}
                    </Anchor>
                  ) : (
                    locationName
                  )}
                </Crumb>
              )}
            </Bread>
          </div>
          {hasPermission(USER_PERMISSIONS.EDIT_ALERT) && (
            <div className="flex justify-center mb3">
              {alertActive && (
                <span>
                  <ActionAnchor onClick={() => closeAlert(id)}>
                    Close
                  </ActionAnchor>
                  <Divider vertical />
                </span>
              )}
              {hidden ? (
                <ActionAnchor onClick={() => unhideAlert(id)}>
                  Unhide
                </ActionAnchor>
              ) : (
                <ActionAnchor onClick={() => hideAlert(id)}>Hide</ActionAnchor>
              )}
              {escalationPolicyActions.ackAllowed && !alertState && (
                <span>
                  <Divider vertical />
                  <ActionAnchor onClick={() => acknowledgeAlert(slug)}>
                    Acknowledge
                  </ActionAnchor>
                </span>
              )}
              {escalationPolicyActions.resAllowed &&
                (!alertState || alertState === ALERT_STATES.ACKNOWLEDGED) && (
                  <span>
                    <Divider vertical />
                    <ActionAnchor onClick={() => resolveAlert(slug)}>
                      Resolve
                    </ActionAnchor>
                  </span>
                )}
            </div>
          )}
        </div>
        <Divider />
        <AlertChart
          data={currentAlertChartData}
          threshold={threshold}
          startTime={startTime}
          endTime={endTime}
          isLoading={isChartDataLoading}
        />
        <Divider />
        {floorInfo.planFileName && (
          <TinyFloorPlan
            floor={floorInfo}
            location={{
              ...currentAlert,
              coordinates: currentAlert.locationCoordinates,
            }}
          />
        )}
        <Divider />
        <Switch>
          <Route
            path={`${match.url}`}
            exact
            render={props => (
              <Redirect
                to={
                  enableActionReport
                    ? `${match.url}/action-report`
                    : `${match.url}/details`
                }
              />
            )}
          />
          <Fragment>
            <Tabs
              defaultActiveKey={
                enableActionReport ? 'action-report' : 'comments'
              }
              type="card"
              activeKey={this.state.activeTab}
              onTabClick={this.handleTabSelection}
            >
              <TabPane tab="Details" key="details">
                <Route
                  path={`${match.url}/details`}
                  exact
                  render={props => (
                    <div className="flex flex-wrap">
                      {podPillarId && (
                        <Block label="Smart Pod ID">
                          {hasPermission(USER_PERMISSIONS.VIEW_POD) ? (
                            <Anchor to={`/inventory/pods/${podPillarId}`}>
                              {podPillarId}
                            </Anchor>
                          ) : (
                            podPillarId
                          )}
                        </Block>
                      )}
                      {flowMonitorPillarId && (
                        <Block label="Water Monitor">
                          {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
                            <Anchor
                              to={`/inventory/flow-monitors/${flowMonitorPillarId}`}
                            >
                              {flowMonitorPillarId}
                            </Anchor>
                          ) : (
                            flowMonitorPillarId
                          )}
                        </Block>
                      )}
                      <Block label="Start Time">
                        {dateTimeFormatter(startTime)} <br />
                      </Block>
                      <Block label="End Time">
                        {endTime ? dateTimeFormatter(endTime) : 'Ongoing'}
                      </Block>
                    </div>
                  )}
                />
              </TabPane>
              {enableActionReport && thresholdName && companyId && (
                <TabPane tab="Action Report" key="action-report">
                  <Route
                    path={`${match.url}/action-report`}
                    exact
                    render={props => (
                      <ActionReport actionReport={actionReport} />
                    )}
                  />
                </TabPane>
              )}
              {hasPermission(USER_PERMISSIONS.VIEW_COMMENT) && (
                <TabPane tab="Comments" key="comments">
                  <Route
                    path={`${match.url}/comments`}
                    exact
                    render={props => (
                      <AllComments
                        resourceType={RESOURCE_TYPES.ALERT}
                        resourceSlug={match.params.slug}
                        getAllComments={getAllComments}
                        deleteComment={deleteComment}
                        createComment={createComment}
                        editComment={editComment}
                        setCommentTime={this.setCommentTime}
                        startTime={commentStartTime}
                        endTime={commentEndTime}
                        getAllTags={getAllTags}
                        tags={tags}
                        comments={comments}
                      />
                    )}
                  />
                </TabPane>
              )}
              {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                <TabPane tab="Audit Log" key="audit-log">
                  <Route
                    path={`${match.url}/audit-log`}
                    exact
                    render={props => (
                      <AlertAuditLog
                        resourceId={id}
                        auditLog={alertAuditLog}
                        getAlertAuditLog={getAlertAuditLog}
                      />
                    )}
                  />
                </TabPane>
              )}
              {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                <TabPane
                  tab="Location Alert History"
                  key="location-alert-history"
                >
                  <Route
                    path={`${match.url}/location-alert-history`}
                    exact
                    render={props =>
                      flowMonitorPillarId ? (
                        <AllFlowAlerts
                          getLocationAlertHistory={getLocationAlertHistory}
                          alerts={locationAlertHistory}
                          accountProfile={accountProfile}
                          windowWidth={windowWidth}
                          meta={meta}
                          isLoading={isHistoryLoading}
                          sectionTitle="Location Alert History"
                          locationId={locationId}
                          locationFloorId={floorId}
                          locationSiteSlug={siteSlug}
                          isLocationAlertHistory
                        />
                      ) : (
                        <AllPodAlerts
                          getLocationAlertHistory={getLocationAlertHistory}
                          alerts={locationAlertHistory}
                          accountProfile={accountProfile}
                          windowWidth={windowWidth}
                          meta={meta}
                          isLoading={isHistoryLoading}
                          sectionTitle="Location Alert History"
                          locationId={locationId}
                          locationFloorId={floorId}
                          locationSiteSlug={siteSlug}
                          isLocationAlertHistory
                        />
                      )
                    }
                  />
                </TabPane>
              )}
            </Tabs>
          </Fragment>
        </Switch>
      </section>
    )
  }
}

export default Alert
