import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import { Bread, Crumb } from '../common/Bread'
import MainContent from '../common/MainContent'
import Divider from '../common/Divider'
import { Anchor } from '../common/Anchor'
import AllThresholds from '../AllThresholds'
import AllThresholdGroups from '../AllThresholdGroups'
import AllEscalationPolicies from '../AllEscalationPolicies'
import CreateThreshold from '../CreateThreshold'
import CreateEscalationPolicy from '../CreateEscalationPolicy'
import Threshold from '../Threshold'
import EditThreshold from '../EditThreshold'
import EscalationPolicy from '../EscalationPolicy'
import EditEscalationPolicy from '../EditEscalationPolicy'
import PageWidth from '../PageWidth'
import { hasPermission } from '../../utils/hasPermission'
import { flagEnabled } from '../../utils/config'
import { USER_PERMISSIONS } from '../../constants'

class RulesPage extends Component {
  static propTypes = {
    thresholds: PropTypes.arrayOf(PropTypes.object),
    currentThreshold: PropTypes.object.isRequired,
    thresholdAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllThresholds: PropTypes.func.isRequired,
    getThreshold: PropTypes.func.isRequired,
    getThresholdAssignments: PropTypes.func.isRequired,
    getThresholdEditHistory: PropTypes.func.isRequired,
    thresholdEditHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
    deleteThreshold: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    toggleThresholdOption: PropTypes.func.isRequired,
    isThresholdLoading: PropTypes.bool.isRequired,
    sendTestNotification: PropTypes.func.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    unsetSiteUserIds: PropTypes.func.isRequired,
    updateEscalationPolicySort: PropTypes.func.isRequired,
    getAllEscalationPolicies: PropTypes.func.isRequired,
    getEscalationPolicy: PropTypes.func.isRequired,
    deleteEscalationPolicy: PropTypes.func.isRequired,
    escalationPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentEscalationPolicy: PropTypes.object.isRequired,
    isEscalationPolicyLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    getAllThresholdGroups: PropTypes.func.isRequired,
    createThresholdGroup: PropTypes.func.isRequired,
    updateThresholdGroup: PropTypes.func.isRequired,
    deleteThresholdGroup: PropTypes.func.isRequired,
    thresholdGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllSites: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    isThresholdGroupLoading: PropTypes.bool.isRequired,
  }

  render() {
    const {
      match: { url },
      thresholds,
      currentThreshold,
      thresholdAssignments,
      getAllThresholds,
      getThreshold,
      getThresholdAssignments,
      deleteThreshold,
      meta,
      toggleThresholdOption,
      isThresholdLoading,
      isEscalationPolicyLoading,
      sendTestNotification,
      getAllCompanies,
      getAllUsersBySite,
      unsetSiteUserIds,
      updateEscalationPolicySort,
      companies,
      siteUsers,
      escalationPolicies,
      currentEscalationPolicy,
      getAllEscalationPolicies,
      getEscalationPolicy,
      deleteEscalationPolicy,
      getThresholdEditHistory,
      thresholdEditHistory,
      isHistoryLoading,
      updateQueryParams,
      getAllThresholdGroups,
      createThresholdGroup,
      updateThresholdGroup,
      deleteThresholdGroup,
      thresholdGroups,
      getAllSites,
      sites,
      isThresholdGroupLoading,
    } = this.props

    return (
      <MainContent>
        <div className="RulesPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/thresholds/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Threshold</title>
                    </Helmet>
                    <CreateThreshold
                      sites={sites}
                      companies={companies}
                      getAllSites={getAllSites}
                      getAllCompanies={getAllCompanies}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/thresholds/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentThreshold.name
                          ? `Edit ${currentThreshold.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditThreshold
                      threshold={currentThreshold}
                      sites={sites}
                      companies={companies}
                      getThreshold={getThreshold}
                      getAllSites={getAllSites}
                      getAllCompanies={getAllCompanies}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/thresholds/:id`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentThreshold.name || 'Loading...'}</title>
                    </Helmet>
                    <Threshold
                      threshold={currentThreshold}
                      thresholdAssignments={thresholdAssignments}
                      getThreshold={getThreshold}
                      getThresholdAssignments={getThresholdAssignments}
                      toggleThresholdOption={toggleThresholdOption}
                      isThresholdLoading={isThresholdLoading}
                      isHistoryLoading={isHistoryLoading}
                      sendTestNotification={sendTestNotification}
                      getEditHistory={getThresholdEditHistory}
                      editHistory={thresholdEditHistory}
                      updateQueryParams={updateQueryParams}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/escalation-policies/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Escalation Policy</title>
                    </Helmet>
                    <CreateEscalationPolicy
                      {...props}
                      sites={sites}
                      siteUsers={siteUsers}
                      getAllSites={getAllSites}
                      getAllUsersBySite={getAllUsersBySite}
                      unsetSiteUserIds={unsetSiteUserIds}
                      updateEscalationPolicySort={updateEscalationPolicySort}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/escalation-policies/:slug/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentEscalationPolicy.name
                          ? `Edit ${currentEscalationPolicy.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditEscalationPolicy
                      escalationPolicy={currentEscalationPolicy}
                      sites={sites}
                      siteUsers={siteUsers}
                      getEscalationPolicy={getEscalationPolicy}
                      getAllSites={getAllSites}
                      getAllUsersBySite={getAllUsersBySite}
                      unsetSiteUserIds={unsetSiteUserIds}
                      updateEscalationPolicySort={updateEscalationPolicySort}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/escalation-policies/:slug`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentEscalationPolicy.name || 'Loading...'}
                      </title>
                    </Helmet>
                    <EscalationPolicy
                      getEscalationPolicy={getEscalationPolicy}
                      escalationPolicy={currentEscalationPolicy}
                      isEscalationPolicyLoading={isEscalationPolicyLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Thresholds</title>
                    </Helmet>

                    <div className="mb3">
                      <Bread>
                        <Crumb>Rules</Crumb>
                        <Crumb>Thresholds</Crumb>
                      </Bread>
                    </div>
                    <div className="flex flex-wrap justify-center mb3">
                      {flagEnabled('REACT_APP_ENABLE_THRESHOLD_GROUPS') &&
                        hasPermission(
                          USER_PERMISSIONS.VIEW_THRESHOLD_GROUP
                        ) && (
                          <span>
                            <Anchor to={'/rules/groups'}>
                              View Threshold Groups
                            </Anchor>
                            <Divider vertical />
                          </span>
                        )}
                      {hasPermission(USER_PERMISSIONS.ADD_THRESHOLD) && (
                        <span>
                          <Anchor to="/rules/thresholds/create">
                            Create New Threshold
                          </Anchor>
                        </span>
                      )}
                    </div>
                    <AllThresholds
                      thresholds={thresholds}
                      getAllThresholds={getAllThresholds}
                      deleteThreshold={deleteThreshold}
                      isThresholdLoading={isThresholdLoading}
                      updateQueryParams={updateQueryParams}
                      meta={meta}
                      {...props}
                    />
                    <AllEscalationPolicies
                      escalationPolicies={escalationPolicies}
                      getAllEscalationPolicies={getAllEscalationPolicies}
                      deleteEscalationPolicy={deleteEscalationPolicy}
                      meta={{}}
                      isEscalationPolicyLoading={isEscalationPolicyLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/groups`}
                render={props => (
                  <>
                    <Helmet>
                      <title>Threshold Groups</title>
                    </Helmet>
                    <AllThresholdGroups
                      getAllThresholdGroups={getAllThresholdGroups}
                      createThresholdGroup={createThresholdGroup}
                      updateThresholdGroup={updateThresholdGroup}
                      deleteThresholdGroup={deleteThresholdGroup}
                      isLoading={isThresholdGroupLoading}
                      thresholdGroups={thresholdGroups}
                      getAllSites={getAllSites}
                      getAllCompanies={getAllCompanies}
                      getAllThresholds={getAllThresholds}
                      sites={sites}
                      companies={companies}
                      thresholds={thresholds}
                      {...props}
                    />
                  </>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default RulesPage
