import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { unauthorizedAction } from '../actions/authActions'
import {
  createLocationGroupAction,
  updateLocationGroupAction,
  deleteLocationGroupAction,
  getAllLocationGroupsAction,
  getLocationGroupAction,
  addThresholdToLocationGroupAction,
  deleteThresholdFromLocationGroupAction,
} from '../actions/locationGroupActions'
import {
  getAllAggregateThresholdsBySiteAction,
  getAllSiteAggregateThresholdUserMappingsAction,
} from '../actions/sitesActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'
import { createQueryString } from '../utils/queryParams'

// handlers

function* handleCreateLocationGroupSaga({ payload }) {
  try {
    const { siteSlug, body } = payload
    const result = yield call(api.createLocationGroup, siteSlug, body)
    yield put(createLocationGroupAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))

    yield fork(handleGetAllLocationGroupsSaga, {
      payload: {
        siteSlug,
        qs: createQueryString({ perPage: 'all' }),
      },
    })
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllLocationGroupsSaga({ payload }) {
  try {
    const { siteSlug, qs } = payload
    const result = yield call(api.getAllLocationGroups, siteSlug, qs)

    yield put(getAllLocationGroupsAction.success(result))
  } catch (error) {
    yield put(getAllLocationGroupsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetLocationGroupSaga({ payload }) {
  try {
    const { siteSlug, groupSlug } = payload
    const result = yield call(api.getLocationGroup, siteSlug, groupSlug)

    yield put(getLocationGroupAction.success(result))
  } catch (error) {
    yield put(getLocationGroupAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateLocationGroupSaga({ payload }) {
  try {
    const { siteSlug, body } = payload
    const result = yield call(api.updateLocationGroup, siteSlug, body)
    yield put(updateLocationGroupAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))

    yield fork(handleGetAllLocationGroupsSaga, {
      payload: {
        siteSlug,
        qs: createQueryString({ perPage: 'all' }),
      },
    })
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteLocationGroupSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)

    if (confirmDeletion) {
      const { siteSlug, groupSlug } = payload
      yield call(api.deleteLocationGroup, siteSlug, groupSlug)
      yield put(deleteLocationGroupAction.success())
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))

      yield fork(handleGetAllLocationGroupsSaga, {
        payload: {
          siteSlug,
          qs: createQueryString({ perPage: 'all' }),
        },
      })
    }
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddThresholdToLocationGroupSaga({ payload }) {
  try {
    const { siteSlug, locationGroupSlug, thresholdId } = payload

    const isConfirmed = yield call(global.confirm, MESSAGES.CONFIRM)

    if (isConfirmed) {
      yield call(
        api.addThresholdToLocationGroup,
        siteSlug,
        locationGroupSlug,
        thresholdId
      )
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ADDED_THRESHOLD))
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(addThresholdToLocationGroupAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteThresholdFromLocationGroupSaga({ payload }) {
  try {
    const { siteSlug, locationGroupSlug, locationGroupThresholdId } = payload

    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)

    if (confirmDeletion) {
      yield call(
        api.deleteThresholdFromLocationGroup,
        siteSlug,
        locationGroupSlug,
        locationGroupThresholdId
      )
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(
        showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED_THRESHOLD)
      )
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(deleteThresholdFromLocationGroupAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchCreateLocationGroupSaga() {
  yield takeLatest(
    createLocationGroupAction.REQUEST,
    handleCreateLocationGroupSaga
  )
}

function* watchGetAllLocationGroupsSaga() {
  yield takeLatest(
    getAllLocationGroupsAction.REQUEST,
    handleGetAllLocationGroupsSaga
  )
}

function* watchGetLocationGroupSaga() {
  yield takeLatest(getLocationGroupAction.REQUEST, handleGetLocationGroupSaga)
}

function* watchUpdateLocationGroupSaga() {
  yield takeLatest(
    updateLocationGroupAction.REQUEST,
    handleUpdateLocationGroupSaga
  )
}

function* watchDeleteLocationGroupSaga() {
  yield takeLatest(
    deleteLocationGroupAction.REQUEST,
    handleDeleteLocationGroupSaga
  )
}

function* watchAddThresholdToLocationGroupSaga() {
  yield takeLatest(
    addThresholdToLocationGroupAction.REQUEST,
    handleAddThresholdToLocationGroupSaga
  )
}

function* watchDeleteThresholdFromLocationGroupSaga() {
  yield takeLatest(
    deleteThresholdFromLocationGroupAction.REQUEST,
    handleDeleteThresholdFromLocationGroupSaga
  )
}

function* locationGroupsSaga() {
  yield all([
    fork(watchCreateLocationGroupSaga),
    fork(watchGetAllLocationGroupsSaga),
    fork(watchGetLocationGroupSaga),
    fork(watchUpdateLocationGroupSaga),
    fork(watchDeleteLocationGroupSaga),
    fork(watchAddThresholdToLocationGroupSaga),
    fork(watchDeleteThresholdFromLocationGroupSaga),
  ])
}

export { locationGroupsSaga as default }
