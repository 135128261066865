import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Input from './Input'
import { createQueryString } from '../../utils/queryParams'

const SearchFilter = ({ getItems, setSearchText, searchText, query }) => {
  const [filter, setFilter] = useState(searchText)

  const handleSearch = ev => {
    setFilter(ev.currentTarget.value)
    setSearchText(ev.currentTarget.value)

    getItems(createQueryString({ ...query, filter: ev.currentTarget.value }))
  }

  return (
    <div className="SearchFilter">
      <Input
        input={{
          placeholder: 'Search',
          value: filter,
          onChange: handleSearch,
        }}
      />
    </div>
  )
}

SearchFilter.propTypes = {
  searchText: PropTypes.string,
  getItems: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
}

export default SearchFilter
