import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Anchor } from './common/Anchor'
import { Bread, Crumb } from './common/Bread'
import { Icon, Tooltip, Table, Column, Spin } from './common/Ant'
import sortBy from 'sort-by'
import { dateTimeFormatter } from '../utils/date'
import hiltiLogo from '../assets/hilti-logo.png'
import logoSm from '../assets/logo-sm.png'
import logoMd from '../assets/logo-md.png'
import logoLg from '../assets/logo-lg.png'

class AllImuNanoIots extends Component {
  static propTypes = {
    allImuNanoIots: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllImuNanoIots: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
    }
  }

  static defaultProps = {
    allImuNanoIots: [],
  }

  componentDidMount() {
    const { getAllImuNanoIots } = this.props
    getAllImuNanoIots()
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }

  render() {
    const { isLoading, allImuNanoIots } = this.props
    const { sortedInfo } = this.state

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : (
      <Fragment>
        <div className="flex items-center">
          <img
            src={hiltiLogo}
            srcSet={`${hiltiLogo} 1000w, ${hiltiLogo} 2000w`}
            alt="Hilti"
            height={56}
            width={238}
          />
          <div className="flex items-center">
            <div className="mh3">powered by</div>
            <div>
              <img
                src={logoSm}
                srcSet={`${logoMd} 1000w, ${logoLg} 2000w`}
                alt="Pillar Technologies"
                height={24}
                width={100}
              />
            </div>
          </div>
        </div>
        <div className="mb2" />
        <div className="mb3">
          <Bread>
            <Crumb>All Devices</Crumb>
          </Bread>
        </div>
        <Table
          onChange={this.handleChange}
          dataSource={allImuNanoIots}
          rowKey="macAddress"
          scroll={{ x: 400 }}
          rowClassName={record =>
            record.penetration > record.cableOccupancy
              ? 'bg-light-red'
              : 'bg-light-green'
          }
          pagination={false}
          tableLayout="auto"
          bordered
        >
          <Column
            title="Device Address"
            width={100}
            dataIndex="macAddress"
            className={'bg-white'}
            sorter={sortBy('macAddress')}
            sortOrder={sortedInfo.field === 'macAddress' && sortedInfo.order}
            render={(text, record) =>
              text ? (
                <Tooltip
                  title={
                    text.length < 18
                      ? 'Arduino Device'
                      : `Arduino Device - ${text}`
                  }
                >
                  <Icon type="check-circle-o" className="green" />
                  <span className="pl2">
                    <Anchor to={`/hilti/${text}`}>
                      {text.length > 17 ? `${text.substring(0, 17)}...` : text}
                    </Anchor>
                  </span>
                </Tooltip>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Cable Occupancy"
            width={100}
            dataIndex="cableOccupancy"
            className={'bg-white'}
            sorter={sortBy('cableOccupancy')}
            sortOrder={
              sortedInfo.field === 'cableOccupancy' && sortedInfo.order
            }
            render={(text, record) =>
              text ? `${parseFloat(text).toFixed(2)} %` : '--'
            }
          />
          <Column
            title="Penetration"
            width={100}
            dataIndex="penetration"
            className={'bg-white'}
            sorter={sortBy('penetration')}
            sortOrder={sortedInfo.field === 'penetration' && sortedInfo.order}
            render={(text, record) =>
              text ? `${parseFloat(text).toFixed(2)} %` : '--'
            }
          />
          <Column
            title="Closure Status"
            width={100}
            dataIndex="status"
            render={(text, record) =>
              text
                ? record.penetration > record.cableOccupancy
                  ? 'Firestop Open'
                  : 'Firestop Closed'
                : '--'
            }
          />
          <Column
            title="Last Reading Time"
            width={200}
            dataIndex="time"
            className={'bg-white'}
            sorter={sortBy('time')}
            sortOrder={sortedInfo.field === 'time' && sortedInfo.order}
            render={(text, record) => (text ? dateTimeFormatter(text) : '--')}
          />
        </Table>
      </Fragment>
    )
  }
}

export default AllImuNanoIots
