import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from './common/Ant'
import Button from './common/Button'
import { Spin } from './common/Ant'
import { SIZES } from '../constants'
import Label from './common/Label'

class CalibrationCSVUpload extends Component {
  state = {
    modalOpen: false,
    formData: null,
  }

  static propTypes = {
    uploadCalibrationCSV: PropTypes.func.isRequired,
  }

  componentWillUnmount() {
    if (this.state.modalOpen) {
      this.handleClose()
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
      addedCount: 0,
      failedCount: 0,
      existsCount: 0,
    })
  }

  handleFileSelection = event => {
    const files = Array.from(event.target.files)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('name', file.name)
      formData.append('type', file.type)
      formData.append('files[]', file)

      this.setState({ formData })
    })
  }

  handleSubmit = () => {
    const { formData } = this.state

    if (formData) {
      this.setState({ submitting: true })
      this.props.uploadCalibrationCSV(formData)
      this.handleClose()
      this.setState({ submitting: false })
    }
  }

  render() {
    const { modalOpen, submitting } = this.state

    return (
      <div className="CalibrationCSVUpload">
        <Button
          onClick={this.handleOpen}
          text="Import Calibration CSV"
          size={SIZES.SMALL}
        />
        <Modal
          visible={modalOpen}
          className="Modal"
          title="Import Calibration CSV"
          onCancel={this.handleClose}
          style={{ top: 16 }}
          footer={null}
          destroyOnClose
        >
          {submitting ? (
            <Spin size="large" className="w-100 center" />
          ) : (
            <div>
              <Label>Select a CSV to upload</Label>
              <input
                type="file"
                accept="text/csv,application/vnd.ms-excel"
                onChange={this.handleFileSelection}
                className="mb3 w-100"
              />
              <Button onClick={this.handleSubmit} />
            </div>
          )}
        </Modal>
      </div>
    )
  }
}

export default CalibrationCSVUpload
