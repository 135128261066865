import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Report from './Report'
import { Spin } from './common/Ant'
import { Anchor } from './common/Anchor'
import logoSm from '../assets/logo-sm.png'
import logoMd from '../assets/logo-md.png'
import logoLg from '../assets/logo-lg.png'

const PublicReport = ({ getReport, report, isLoading, match: { params } }) => {
  useEffect(() => {
    getReport(params.reportSlug)
  }, [getReport, params.reportSlug])

  return (
    <div className="PublicReport bg-white pa3">
      <Helmet>
        <title>
          {report.name
            ? report.name
            : !!report.siteInfo && !!report.siteInfo.name
            ? `${report.siteInfo.name} — Report`
            : 'Loading...'}
        </title>
      </Helmet>
      <div className="mw7 center">
        {isLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : report.isExpired ? (
          <div className="mt6">
            <div className="bg-yellow pa1 b w5 br2 center tc mb5">
              <div>Report expired!</div>
            </div>
            <div className="flex justify-center mb5 w5 center">
              <Anchor to="/dashboard">
                <img
                  src={logoSm}
                  srcSet={`${logoMd} 1000w, ${logoLg} 2000w`}
                  alt="Pillar Technologies"
                />
              </Anchor>
            </div>
            <div className="b tc mb3">
              <Anchor to="/dashboard">Go to Dashboard &raquo;</Anchor>
            </div>
          </div>
        ) : (
          <Report {...report} />
        )}
      </div>
    </div>
  )
}

PublicReport.propTypes = {
  getReport: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default PublicReport
