import React from 'react'
import PropTypes from 'prop-types'
import EditAccountProfileForm from './forms/EditAccountProfileForm'
import Divider from './common/Divider'
import { H2 } from './common/Headers'
import { Anchor } from './common/Anchor'

const EditAccountProfile = ({ profile }) => (
  <section className="EditAccountProfile">
    <H2>
      Edit Profile
      <small>
        <Divider vertical />
        <Anchor to="/account/settings">Edit Settings</Anchor>
        <Divider vertical />
        <Anchor to="/account/change-password">Change Password</Anchor>
      </small>
    </H2>
    <EditAccountProfileForm initialValues={profile} enableReinitialize />
  </section>
)

EditAccountProfile.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default EditAccountProfile
