import { createFormAction } from 'redux-form-saga'

const getAllAssetLocationsAction = createFormAction('GET_ALL_ASSET_LOCATIONS')
const getAssetLocationAction = createFormAction('GET_ASSET_LOCATION')
const createAssetLocationAction = createFormAction('CREATE_ASSET_LOCATION')
const deleteAssetLocationAction = createFormAction('DELETE_ASSET_LOCATION')
const updateAssetLocationAction = createFormAction('UPDATE_ASSET_LOCATION')

export {
  getAllAssetLocationsAction,
  getAssetLocationAction,
  createAssetLocationAction,
  deleteAssetLocationAction,
  updateAssetLocationAction,
}
