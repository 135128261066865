import { createFormAction } from 'redux-form-saga'

const getAllActionReportCausesAction = createFormAction(
  'GET_ALL_ACTION_REPORT_CAUSES'
)
const getActionReportCauseAction = createFormAction('GET_ACTION_REPORT_CAUSE')
const createActionReportCauseAction = createFormAction(
  'CREATE_ACTION_REPORT_CAUSE'
)
const updateActionReportCauseAction = createFormAction(
  'UPDATE_ACTION_REPORT_CAUSE'
)
const deleteActionReportCauseAction = createFormAction(
  'DELETE_ACTION_REPORT_CAUSE'
)

export {
  getAllActionReportCausesAction,
  getActionReportCauseAction,
  createActionReportCauseAction,
  updateActionReportCauseAction,
  deleteActionReportCauseAction,
}
