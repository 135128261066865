import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { dateFormatter } from '../utils/date'
import { toTitleCaseArray } from '../utils/textFormatters'
import { Table, Column } from './common/Ant'
import { Anchor } from './common/Anchor'

class AlertAuditLog extends Component {
  componentDidMount() {
    const { resourceId, getAlertAuditLog } = this.props
    getAlertAuditLog(resourceId)
  }

  render() {
    const { auditLog } = this.props

    return auditLog.length ? (
      <Table
        dataSource={auditLog}
        rowKey="slug"
        scroll={{ x: 1050 }}
        bordered
        tableLayout="auto"
      >
        <Column
          title="Sent To"
          width={100}
          dataIndex="name"
          render={(text, record) => (
            <Anchor to={`/users/${record.userSlug}`}>{text}</Anchor>
          )}
        />
        <Column
          title="Sent Via"
          width={100}
          dataIndex="communicationMode"
          render={(text, record) => (text ? toTitleCaseArray(text) : '--')}
        />
        <Column
          title="Acknowledged"
          width={100}
          dataIndex="ackTimestamp"
          render={(text, record) => (text ? dateFormatter(text) : '--')}
        />
        <Column
          title="Acknowledged Via"
          width={100}
          dataIndex="ackMode"
          render={(text, record) => (text ? text : '--')}
        />
        <Column
          title="Resolved"
          width={100}
          dataIndex="resolveTimestamp"
          render={(text, record) => (text ? dateFormatter(text) : '--')}
        />
        <Column
          title="Resolved Via"
          width={100}
          dataIndex="resolveMode"
          render={(text, record) => (text ? text : '--')}
        />
      </Table>
    ) : (
      'No Audit Log Available'
    )
  }
}

AlertAuditLog.propTypes = {
  auditLog: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAlertAuditLog: PropTypes.func.isRequired,
  resourceId: PropTypes.number,
}

export default AlertAuditLog
