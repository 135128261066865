import { connect } from 'react-redux'
import EditCommentForm from '../components/forms/EditCommentForm'
import { getAllAvailbleUserMentionsAction } from '../actions/commentsActions'
import { getAllUserMentions } from '../reducers/commentsReducer'

const mapStateToProps = ({ comments }) => ({
  mentions: getAllUserMentions(comments),
})

const mapDispatchToProps = dispatch => ({
  getUserMentions: qs => dispatch(getAllAvailbleUserMentionsAction.request(qs)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCommentForm)
