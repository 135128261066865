import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Clipboard from 'react-clipboard.js'
import {
  Spin,
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Modal,
  message,
} from './common/Ant'
import { ActionAnchor } from './common/Anchor'
import Divider from './common/Divider'
import Form from './common/Form'
import Input from './common/Input'
import FormError from './common/FormError'
import Button from './common/Button'
import { H3 } from './common/Headers'
import { MESSAGES } from '../constants'

const NetworkAuthorities = ({
  createNetworkAuthority,
  updateNetworkAuthority,
  deleteNetworkAuthority,
  clearToken,
  regenerateToken,
  token,
  networkAuthorities,
  isLoading,
  isAuthorityModalVisible,
  setIsAuthorityModalVisible,
}) => {
  const [name, setName] = useState('')
  const [slug, setSlug] = useState('')
  const [error, setError] = useState('')

  const handleTokenModalCancel = () => {
    clearToken()
  }

  const handleModalCancel = () => {
    setSlug('')
    setIsAuthorityModalVisible(false)
  }

  const handleSubmit = ev => {
    if (!name) {
      return setError('Name is required.')
    }

    if (!slug) {
      createNetworkAuthority(name)
    } else {
      updateNetworkAuthority({ slug, name })
    }

    handleModalCancel()
    ev.preventDefault()
  }

  const handleEdit = record => {
    setName(record.name)
    setSlug(record.slug)
    setIsAuthorityModalVisible(true)
  }

  return isLoading ? (
    <Spin size="large" className="w-100 center mv5" />
  ) : (
    <div className="NetworkAuthorities mb5">
      <H3>Authorities</H3>
      <div className="mb3">
        <Table
          dataSource={networkAuthorities}
          rowKey="slug"
          pagination={false}
          size="small"
          tableLayout="auto"
        >
          <Column title="Name" dataIndex="name" />
          <Column title="Created By" dataIndex="userName" />
          <Column
            width={100}
            render={(text, record) => {
              const menu = (
                <Menu>
                  <Menu.Item key="regenerate-token">
                    <ActionAnchor onClick={() => regenerateToken(record.slug)}>
                      Regenerate Token
                    </ActionAnchor>
                  </Menu.Item>
                  <Menu.Item key="edit-network-authority">
                    <ActionAnchor onClick={() => handleEdit(record)}>
                      Edit
                    </ActionAnchor>
                  </Menu.Item>
                  <Menu.Item key="delete-network-authority">
                    <ActionAnchor
                      onClick={() => deleteNetworkAuthority(record.slug)}
                    >
                      Delete
                    </ActionAnchor>
                  </Menu.Item>
                </Menu>
              )

              return (
                <Dropdown overlay={menu} trigger={['click']}>
                  <a className="ant-dropdown-link">
                    Actions <Icon type="down" />
                  </a>
                </Dropdown>
              )
            }}
          />
        </Table>
      </div>

      <Modal
        className="Modal"
        visible={!!token}
        footer={null}
        onCancel={handleTokenModalCancel}
        destroyOnClose
      >
        <div className="mb3">
          <H3>Authority Token</H3>
          <div className="mb3">
            <span className="b">Copy Token: </span>
            <Clipboard
              component="span"
              data-clipboard-text={token}
              onSuccess={() => message.success(MESSAGES.COPIED)}
              className="pointer mb3"
            >
              {token}
            </Clipboard>
          </div>
          <div>
            If you lose or forget this token, you can regenerate it, but be
            aware that any scripts, applications, or hardware using this token
            will need to be updated.
          </div>
        </div>
      </Modal>

      <Modal
        className="Modal"
        visible={isAuthorityModalVisible}
        footer={null}
        onCancel={handleModalCancel}
        destroyOnClose
      >
        <Form onSubmit={handleSubmit}>
          <H3>{!slug ? 'Create' : 'Update'} Authority</H3>
          <Input
            label="Name"
            input={{
              value: name,
              onChange: ev => setName(ev.currentTarget.value),
            }}
          />
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <Button text="Submit" onClick={handleSubmit} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

NetworkAuthorities.propTypes = {
  createNetworkAuthority: PropTypes.func.isRequired,
  updateNetworkAuthority: PropTypes.func.isRequired,
  deleteNetworkAuthority: PropTypes.func.isRequired,
  clearToken: PropTypes.func.isRequired,
  regenerateToken: PropTypes.func.isRequired,
  token: PropTypes.string,
  networkAuthorities: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthorityModalVisible: PropTypes.bool.isRequired,
  setIsAuthorityModalVisible: PropTypes.func.isRequired,
}

export default NetworkAuthorities
