import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Column, Spin } from './common/Ant'
import { dateTimeFormatter } from '../utils/date'
import { UNITS } from '../constants'

class LastSnapshot extends Component {
  static propTypes = {
    isFlowMonitor: PropTypes.bool,
    isSnapshotLoading: PropTypes.bool.isRequired,
    snapshotData: PropTypes.object.isRequired,
    siteSlug: PropTypes.string.isRequired,
    floorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    getLocationSnapshot: PropTypes.func.isRequired,
  }

  static defaultProps = {
    snapshotData: {},
    isFlowMonitor: false,
  }

  componentDidMount() {
    const { siteSlug, floorId, locationId, getLocationSnapshot } = this.props
    getLocationSnapshot(siteSlug, floorId, locationId)
  }

  render() {
    const { isFlowMonitor, isSnapshotLoading, snapshotData } = this.props

    return isSnapshotLoading ? (
      <Spin size="large" className="w-70 center mt1" />
    ) : snapshotData.payload && snapshotData.payload.time ? (
      <Table
        dataSource={snapshotData.data}
        rowKey="time"
        pagination={false}
        tableLayout="auto"
      >
        <Column
          title="Latest Reading"
          width={100}
          dataIndex="time"
          render={(text, record) =>
            text ? dateTimeFormatter(text, true) : '--'
          }
        />
        <Column
          title="Signal Strength"
          width={100}
          dataIndex="signalRssi"
          render={(text, record) =>
            text ? `${parseFloat(text).toFixed(2)} ${UNITS.SIGNAL_RSSI}` : '--'
          }
        />
        {isFlowMonitor && (
          <Column
            title="Water Flow"
            width={100}
            dataIndex="flowValue"
            render={(text, record) =>
              text ? `${parseFloat(text).toFixed(2)} ${UNITS.FLOW_VALUE}` : '--'
            }
          />
        )}
        {isFlowMonitor && (
          <Column
            title="Pipe Temperature"
            width={100}
            dataIndex="temp"
            render={(text, record) =>
              text
                ? `${parseFloat(text).toFixed(2)} ${UNITS.TEMPERATURE}`
                : '--'
            }
          />
        )}
        {!isFlowMonitor && (
          <Column
            title="Temperature"
            width={100}
            dataIndex="temp"
            render={(text, record) =>
              text
                ? `${parseFloat(text).toFixed(2)} ${UNITS.TEMPERATURE}`
                : '--'
            }
          />
        )}
        {!isFlowMonitor && (
          <Column
            title="Humidity"
            width={100}
            dataIndex="hum"
            render={(text, record) =>
              text ? `${parseFloat(text).toFixed(2)} ${UNITS.HUMIDITY}` : '--'
            }
          />
        )}
        {!isFlowMonitor && (
          <Column
            title="Dust"
            width={100}
            dataIndex="dust"
            render={(text, record) =>
              text ? `${parseFloat(text).toFixed(2)} ${UNITS.DUST}` : '--'
            }
          />
        )}
        {!isFlowMonitor && (
          <Column
            title="Pressure"
            width={100}
            dataIndex="pres"
            render={(text, record) =>
              text ? `${parseFloat(text).toFixed(2)} ${UNITS.PRESSURE}` : '--'
            }
          />
        )}
      </Table>
    ) : (
      <p className="tc">No reading available.</p>
    )
  }
}

export default LastSnapshot
