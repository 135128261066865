import { createFormAction } from 'redux-form-saga'

const getAllSIMCardsAction = createFormAction('GET_ALL_SIM_CARDS')

/* For the future if we'd like to see all SIM cards we can assign. */
const getAllAvailableSIMCardsAction = createFormAction(
  'GET_ALL_AVAILABLE_SIM_CARDS'
)
const getSIMCardAction = createFormAction('GET_SIM_CARD')
const createSIMCardAction = createFormAction('CREATE_SIM_CARD')
const updateSIMCardAction = createFormAction('UPDATE_SIM_CARD')
const deleteSIMCardAction = createFormAction('DELETE_SIM_CARD')

export {
  getAllSIMCardsAction,
  getAllAvailableSIMCardsAction,
  getSIMCardAction,
  createSIMCardAction,
  updateSIMCardAction,
  deleteSIMCardAction,
}
