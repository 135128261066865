import { createFormAction } from 'redux-form-saga'

const getAllFloorsAction = createFormAction('GET_ALL_FLOORS')
const getAllFloorSummariesAction = createFormAction('GET_ALL_FLOOR_SUMMARIES')
const getFloorAction = createFormAction('GET_FLOOR')
const getFloorSummaryAction = createFormAction('GET_FLOOR_SUMMARY')
const createFloorAction = createFormAction('CREATE_FLOOR')
const submitSiteFloorsAction = createFormAction('SUBMIT_SITE_FLOORS')
const updateFloorAction = createFormAction('UPDATE_FLOOR')
const uploadFloorPlanAction = createFormAction('UPLOAD_FLOOR_PLAN')
const deleteFloorAction = createFormAction('DELETE_FLOOR')
const getAllFloorsByBuildingAction = createFormAction(
  'GET_ALL_FLOORS_BY_BUILDING'
)
const getAllFloorSummariesByBuildingAction = createFormAction(
  'GET_ALL_FLOOR_SUMMARIES_BY_BUILDING'
)

const REMOVE_BUILDING_FROM_FLOOR = 'REMOVE_BUILDING_FROM_FLOOR'
const removeBuildingFromFloorAction = () => ({
  type: REMOVE_BUILDING_FROM_FLOOR,
})

const CLEAR_CURRENT_FLOOR = 'CLEAR_CURRENT_FLOOR'
const clearCurrentFloorAction = () => ({ type: CLEAR_CURRENT_FLOOR })

const addThresholdToFloorAction = createFormAction('ADD_THRESHOLD_TO_FLOOR')
const deleteThresholdFromFloorAction = createFormAction(
  'DELETE_THRESHOLD_FROM_FLOOR'
)

const getAllFloorThresholdUserMappingsAction = createFormAction(
  'GET_ALL_FLOOR_THRESHOLD_USER_MAPPINGS'
)
const updateFloorThresholdUserMappingAction = createFormAction(
  'UPDATE_FLOOR_THRESHOLD_USER_MAPPING'
)

const updateSortOrderAction = createFormAction('UPDATE_FLOOR_SORT_ORDER')

export {
  getAllFloorsAction,
  getFloorAction,
  createFloorAction,
  submitSiteFloorsAction,
  updateFloorAction,
  uploadFloorPlanAction,
  deleteFloorAction,
  REMOVE_BUILDING_FROM_FLOOR,
  removeBuildingFromFloorAction,
  CLEAR_CURRENT_FLOOR,
  clearCurrentFloorAction,
  getAllFloorSummariesAction,
  getFloorSummaryAction,
  addThresholdToFloorAction,
  deleteThresholdFromFloorAction,
  getAllFloorThresholdUserMappingsAction,
  updateFloorThresholdUserMappingAction,
  getAllFloorsByBuildingAction,
  getAllFloorSummariesByBuildingAction,
  updateSortOrderAction,
}
