import { connect } from 'react-redux'
import BLEPage from '../components/pages/BLEPage'
import { getAllBLEDataAction } from '../actions/bleActions'
import { getVisibleBLEData } from '../reducers/bleReducer'
import { isBLELoading } from '../reducers/uiReducer'

const mapStateToProps = ({ ble, ui }) => ({
  data: getVisibleBLEData(ble),
  isLoading: isBLELoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllBLEData: payload => dispatch(getAllBLEDataAction.request(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BLEPage)
