import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import AdminDashboard from '../AdminDashboard'
import UserDashboard from '../UserDashboard'
import ImuNanoIot from '../ImuNanoIot'
import AllImuNanoIots from '../AllImuNanoIots'
import PageWidth from '../PageWidth'
import { H3 } from '../common/Headers'
import { hasRole, hasPermission } from '../../utils/hasPermission'
import { ROLES, USER_PERMISSIONS } from '../../constants'

class DashboardPage extends Component {
  static propTypes = {
    getAdminDashboard: PropTypes.func.isRequired,
    getUserDashboard: PropTypes.func.isRequired,
    getAllLocationsByFloor: PropTypes.func.isRequired,
    getAllFloorSummaries: PropTypes.func.isRequired,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    getAllGatewaysBySite: PropTypes.func.isRequired,
    gatewayAlerts: PropTypes.arrayOf(PropTypes.object),
    thresholdAlerts: PropTypes.arrayOf(PropTypes.object),
    buildings: PropTypes.arrayOf(PropTypes.object),
    floors: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    thresholds: PropTypes.arrayOf(PropTypes.object),
    allActiveSites: PropTypes.arrayOf(PropTypes.object),
    currentSite: PropTypes.object,
    siteGateways: PropTypes.arrayOf(PropTypes.object),
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    counts: PropTypes.object.isRequired,
    isFloorLoading: PropTypes.bool.isRequired,
    isLocationLoading: PropTypes.bool.isRequired,
    isSiteLoading: PropTypes.bool.isRequired,
    isAlertLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    pushToPath: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    getAllImuNanoIots: PropTypes.func.isRequired,
    allImuNanoIots: PropTypes.arrayOf(PropTypes.object).isRequired,
    getImuData: PropTypes.func.isRequired,
    imuData: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.shape({ params: PropTypes.object, url: PropTypes.string })
      .isRequired,
  }

  render() {
    const { accountProfile, match } = this.props

    if (accountProfile.role) {
      if (
        hasRole(
          ROLES.SUPER_ADMIN,
          ROLES.PILLAR_ADMIN,
          ROLES.ORGANIZATION_ADMIN,
          ROLES.JOBSITE_ADMIN,
          ROLES.JOBSITE_USER,
          ROLES.PILLAR_CONTRACTOR
        ) &&
        match.url.search('hilti') < 0
      ) {
        const {
          getAdminDashboard,
          getUserDashboard,
          getAllLocationsByFloor,
          snapshotData,
          getLocationSnapshot,
          getAllFloorSummaries,
          getAllGatewaysBySite,
          thresholdAlerts,
          gatewayAlerts,
          allActiveSites,
          counts,
          currentSite,
          siteGateways,
          buildings,
          floors,
          locations,
          isAlertLoading,
          isSiteLoading,
          isFloorLoading,
          isLocationLoading,
          windowWidth,
          pushToPath,
          match,
        } = this.props

        if (hasRole(ROLES.JOBSITE_USER) && accountProfile.sites.length === 1) {
          const defaultSiteIndex = accountProfile.sites
            .map(x => x.isDefault)
            .indexOf(true)

          if (defaultSiteIndex !== -1) {
            const { siteSlug } = accountProfile.sites[defaultSiteIndex]

            return (
              <MainContent>
                <section className="DashboardPage with-side-nav is-user">
                  <PageWidth>
                    <UserDashboard
                      getDashboard={getUserDashboard}
                      getAllLocationsByFloor={getAllLocationsByFloor}
                      snapshotData={snapshotData}
                      getLocationSnapshot={getLocationSnapshot}
                      getAllGatewaysBySite={getAllGatewaysBySite}
                      defaultSiteSlug={siteSlug}
                      currentSite={currentSite}
                      siteGateways={siteGateways}
                      buildings={buildings}
                      floors={floors}
                      getAllFloorSummaries={getAllFloorSummaries}
                      locations={locations}
                      accountProfile={accountProfile}
                      windowWidth={windowWidth}
                      isFloorLoading={isFloorLoading}
                      isLocationLoading={isLocationLoading}
                      isThresholdLoading={isSiteLoading}
                      pushToPath={pushToPath}
                      match={match}
                    />
                  </PageWidth>
                </section>
              </MainContent>
            )
          } else {
            return (
              <MainContent>
                <section className="DashboardPage with-side-nav is-user">
                  <H3>No assigned sites. Please contact your supervisor.</H3>
                </section>
              </MainContent>
            )
          }
        }

        return (
          <MainContent>
            <section className="DashboardPage with-side-nav is-admin">
              <PageWidth>
                <AdminDashboard
                  getDashboard={getAdminDashboard}
                  thresholdAlerts={thresholdAlerts}
                  gatewayAlerts={gatewayAlerts}
                  allActiveSites={allActiveSites}
                  counts={counts}
                  isAlertLoading={isAlertLoading}
                  isSiteLoading={isSiteLoading}
                />
              </PageWidth>
            </section>
          </MainContent>
        )
      } else if (
        hasRole(ROLES.HILTI_USER) ||
        hasPermission(USER_PERMISSIONS.ENABLE_EROS)
      ) {
        const {
          updateQueryParams,
          getAllImuNanoIots,
          allImuNanoIots,
          getImuData,
          imuData,
          isHistoryLoading,
          isChartDataLoading,
        } = this.props

        return (
          <MainContent>
            <section className="DashboardPage with-side-nav">
              <Switch>
                <Route
                  path={`${match.url}/`}
                  exact
                  render={props => (
                    <AllImuNanoIots
                      getAllImuNanoIots={getAllImuNanoIots}
                      allImuNanoIots={allImuNanoIots}
                      isLoading={isHistoryLoading}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/:macAddress`}
                  exact
                  render={props => (
                    <ImuNanoIot
                      updateQueryParams={updateQueryParams}
                      isLoading={isChartDataLoading}
                      getImuData={getImuData}
                      imuData={imuData}
                      {...props}
                    />
                  )}
                />
              </Switch>
            </section>
          </MainContent>
        )
      } else {
        return (
          <MainContent>
            <section className="DashboardPage with-side-nav is-user">
              <H3>No assigned sites. Please contact your supervisor.</H3>
            </section>
          </MainContent>
        )
      }
    }

    return null
  }
}

export default DashboardPage
