import {
  IMU_SENSOR_READING_TYPES,
  FLOW_SENSOR_READING_TYPES,
  SENSOR_READING_TYPES,
  SMART_SENSOR_TYPES,
  UNITS,
  COLORS,
} from '../constants'
import { toTitleCase } from './textFormatters'
import iconDust from '../assets/icon-particulates.svg'
import iconPressure from '../assets/icon-pressure.svg'
import iconSignal from '../assets/icon-signal.svg'
import iconTemperature from '../assets/icon-temperature.svg'
import iconFlowMonitor from '../assets/flow-online.svg'
import iconHumidity from '../assets/icon-humidity.svg'
import iconVOC from '../assets/icon-voc.svg'
import iconCarbonMonoxide from '../assets/icon-carbon.svg'
import iconLight from '../assets/icon-light.svg'
import iconNoise from '../assets/icon-noise.svg'
import iconBattery from '../assets/icon-battery.svg'

const toFixed = x => (x ? parseFloat(x).toFixed(2) : 0)

const getSensorReading = (type, value = 0) => {
  switch (type) {
    case SENSOR_READING_TYPES.TEMPERATURE:
      return {
        color: COLORS.BLUE,
        name: 'Temperature',
        unit: UNITS.TEMPERATURE,
        value: toFixed(value),
        icon: iconTemperature,
      }
    case SENSOR_READING_TYPES.HUMIDITY:
      return {
        color: COLORS.GREEN,
        name: 'Humidity',
        unit: UNITS.HUMIDITY,
        value: toFixed(value),
        icon: iconHumidity,
      }
    case SENSOR_READING_TYPES.HUMIDITY_UNCALIBRATED:
      return {
        color: COLORS.HOT_PINK,
        name: 'Humidity (Uncalibrated)',
        unit: UNITS.HUMIDITY,
        value: toFixed(value),
        icon: iconHumidity,
      }
    case SENSOR_READING_TYPES.HUMIDITY_CALIBRATED_1PT:
      return {
        color: COLORS.PINK,
        name: 'Humidity (Calibrated 1 pt)',
        unit: UNITS.HUMIDITY,
        value: toFixed(value),
        icon: iconHumidity,
      }
    case SENSOR_READING_TYPES.HUMIDITY_CALIBRATED_2PT:
      return {
        color: COLORS.LIGHT_PINK,
        name: 'Humidity (Calibrated 2 pt)',
        unit: UNITS.HUMIDITY,
        value: toFixed(value),
        icon: iconHumidity,
      }
    case SENSOR_READING_TYPES.DUST:
      return {
        color: COLORS.LIGHT_PURPLE,
        name: 'Dust',
        unit: UNITS.DUST,
        value: toFixed(value),
        icon: iconDust,
      }
    case SENSOR_READING_TYPES.PRESSURE:
      return {
        color: COLORS.ORANGE,
        name: 'Pressure',
        unit: UNITS.PRESSURE,
        value: toFixed(value),
        icon: iconPressure,
      }
    case SENSOR_READING_TYPES.SOUND:
      return {
        color: COLORS.PURPLE,
        name: 'Sound',
        value: toFixed(value),
        icon: iconNoise,
      }
    case SENSOR_READING_TYPES.VOC:
      return {
        color: COLORS.GOLD,
        name: 'VOC (Alpha)',
        unit: UNITS.VOC,
        value: toFixed(value),
        icon: iconVOC,
      }
    case SENSOR_READING_TYPES.CARBON_MONOXIDE:
      return {
        color: COLORS.GRAY,
        name: 'Carbon Monoxide',
        unit: UNITS.CARBON_MONOXIDE,
        value: toFixed(value),
        icon: iconCarbonMonoxide,
      }
    case SENSOR_READING_TYPES.AMBIENT_LIGHT_FC:
      return {
        color: COLORS.YELLOW,
        name: ' Ambient Light (foot-candles)',
        unit: UNITS.AMBIENT_LIGHT_FC,
        value: toFixed(value),
        icon: iconLight,
      }
    case SENSOR_READING_TYPES.LIGHT:
      return {
        color: COLORS.YELLOW,
        name: ' Ambient Light',
        unit: UNITS.LIGHT,
        value: toFixed(value),
        icon: iconLight,
      }
    case SENSOR_READING_TYPES.SIGNAL:
      return {
        color: COLORS.DARK_BLUE,
        name: 'Signal',
        unit: UNITS.SIGNAL_RSSI,
        value: toFixed(value),
      }
    case SENSOR_READING_TYPES.SIGNAL_RSSI:
      return {
        color: COLORS.DARK_BLUE,
        name: 'Signal Strength',
        unit: UNITS.SIGNAL_RSSI,
        value: toFixed(value),
        icon: iconSignal,
      }
    case SENSOR_READING_TYPES.SIGNAL_SNR:
      return {
        color: COLORS.DARK_PINK,
        name: 'Signal-to-Noise',
        unit: UNITS.SIGNAL_SNR,
        value: toFixed(value),
      }
    case SENSOR_READING_TYPES.SIGNAL_SF:
      return {
        color: COLORS.DARK_GREEN,
        name: 'Spreading Factor',
        unit: UNITS.SIGNAL_SF,
        value: toFixed(value),
      }
    case SENSOR_READING_TYPES.DP_LOW:
      return {
        color: COLORS.GOLD,
        name: 'Differential Pressure - Low',
        unit: UNITS.PDS,
        value: toFixed(value),
      }
    case SENSOR_READING_TYPES.DP_HIGH:
      return {
        color: COLORS.ORANGE,
        name: 'Differential Pressure - High',
        unit: UNITS.PDS,
        value: toFixed(value),
      }
    case SENSOR_READING_TYPES.DP_AVG:
      return {
        color: COLORS.GREEN,
        name: 'Differential Pressure - Average',
        unit: UNITS.PDS,
        value: toFixed(value),
      }
    case SENSOR_READING_TYPES.DP_ALARM:
      return {
        color: COLORS.DARK_BLUE,
        name: 'Differential Pressure Alarm Setting',
        unit: UNITS.PDS,
        value: toFixed(value),
      }
    case SENSOR_READING_TYPES.BATTERY_NORMAL:
      return {
        color: COLORS.RED,
        name: 'Battery Normal',
        unit: UNITS.BATTERY,
        value: toFixed(value),
        icon: iconBattery,
      }
    case SENSOR_READING_TYPES.BATTERY_LOADED:
      return {
        color: COLORS.NAVY,
        name: 'Battery Loaded',
        unit: UNITS.BATTERY,
        value: toFixed(value),
        icon: iconBattery,
      }
    case SENSOR_READING_TYPES.BATTERY_DROP:
      return {
        color: COLORS.ORANGE,
        name: 'Battery Drop',
        unit: UNITS.BATTERY,
        value: toFixed(value),
        icon: iconBattery,
      }
    case SENSOR_READING_TYPES.DEW_POINT:
      return {
        color: COLORS.BLACK,
        name: 'Dew Point',
        unit: UNITS.TEMPERATURE,
        value: toFixed(value),
        icon: iconTemperature,
      }
    // Adding IMU Sensor reading types, can be removed later if not used
    case IMU_SENSOR_READING_TYPES.DISTANCE_1:
      return {
        color: COLORS.BLUE,
        name: 'Distance 1',
        unit: UNITS.DISTANCE,
        value: toFixed(value),
      }
    case IMU_SENSOR_READING_TYPES.DISTANCE_2:
      return {
        color: COLORS.GREEN,
        name: 'Distance 2',
        unit: UNITS.DISTANCE,
        value: toFixed(value),
      }
    case IMU_SENSOR_READING_TYPES.DISTANCE_3:
      return {
        color: COLORS.ORANGE,
        name: 'Distance 3',
        unit: UNITS.DISTANCE,
        value: toFixed(value),
      }
    case IMU_SENSOR_READING_TYPES.DISTANCE_4:
      return {
        color: COLORS.PURPLE,
        name: 'Distance 4',
        unit: UNITS.DISTANCE,
        value: toFixed(value),
      }
    case IMU_SENSOR_READING_TYPES.CABLE_OCCUPANCY_ToF:
      return {
        color: COLORS.GRAY,
        name: 'Cable Occupancy',
        unit: UNITS.CABLE_OCCUPANCY_ToF,
        value: toFixed(value),
      }
    case IMU_SENSOR_READING_TYPES.CABLE_OCCUPANCY_ACCEL:
      return {
        color: COLORS.DARK_PINK,
        name: 'Penetration',
        unit: UNITS.CABLE_OCCUPANCY_ToF,
        value: toFixed(value),
      }
    case IMU_SENSOR_READING_TYPES.ROTATION_ANGLE:
      return {
        color: COLORS.GOLD,
        name: 'Rotation Angle',
        unit: UNITS.ROTATION_ANGLE,
        value: toFixed(value),
      }
    // Adding Flow Sensor reading types, can be removed later if not used
    case FLOW_SENSOR_READING_TYPES.FLOW_VALUE:
      return {
        color: COLORS.BLUE,
        name: 'Water Flow',
        unit: UNITS.FLOW_VALUE,
        value: toFixed(value),
        icon: iconFlowMonitor,
      }
    case FLOW_SENSOR_READING_TYPES.PIPE_TEMPERATURE:
      return {
        color: COLORS.NAVY,
        name: 'Pipe Temperature',
        unit: UNITS.TEMPERATURE,
        value: toFixed(value),
        icon: iconTemperature,
      }
    case FLOW_SENSOR_READING_TYPES.VALVE_ANALOG:
      return {
        color: COLORS.LIGHT_PURPLE,
        name: 'Valve Opening',
        unit: UNITS.VALVE_ANALOG,
        value: toFixed(value),
      }
    case FLOW_SENSOR_READING_TYPES.VALVE_STATUS:
      return {
        color: COLORS.ORANGE,
        name: 'Valve Status',
        unit: UNITS.VALVE_STATUS,
        value: value ? toTitleCase(value) : '--',
      }
    case FLOW_SENSOR_READING_TYPES.SWITCH_STATUS:
      return {
        color: COLORS.GREEN,
        name: 'Switch Status',
        unit: UNITS.SWITCH_STATUS,
        value: value ? toTitleCase(value) : '--',
      }
    case FLOW_SENSOR_READING_TYPES.VALVE_STATUS_INT:
      return {
        color: COLORS.ORANGE,
        name: 'Valve Status',
        unit: UNITS.VALVE_STATUS,
        value: value ? toFixed(value) : '--',
      }
    case FLOW_SENSOR_READING_TYPES.SWITCH_STATUS_INT:
      return {
        color: COLORS.GREEN,
        name: 'Switch Status',
        unit: UNITS.SWITCH_STATUS,
        value: value ? toFixed(value) : '--',
      }
    case FLOW_SENSOR_READING_TYPES.AVERAGE_WEEKLY_FLOW:
      return {
        color: COLORS.LIGHT_BLUE,
        name: 'Weekly Avg. Flow',
        unit: UNITS.FLOW_VALUE,
        value: toFixed(value),
      }
    case FLOW_SENSOR_READING_TYPES.AVERAGE_FLOW:
      return {
        color: COLORS.GRAY,
        name: 'Average Flow',
        unit: UNITS.FLOW_VALUE,
        value: toFixed(value),
      }
    case FLOW_SENSOR_READING_TYPES.TOTAL_FLOW:
      return {
        color: COLORS.BLUE,
        name: 'Total Flow',
        unit: UNITS.TOTAL_FLOW,
        value: toFixed(value),
      }
    case SENSOR_READING_TYPES.MIC_COUNT:
      return {
        color: COLORS.LIGHT_RED,
        name: 'Noise Count (> 85 dBA)',
        unit: '',
        value: value,
      }
    default:
      return {
        color: COLORS.BLACK,
        name: toTitleCase(type),
        unit: '',
        value: toFixed(value),
      }
  }
}

const getReading = reading => {
  const result = [
    ...Object.keys(SMART_SENSOR_TYPES),
    ...Object.keys(FLOW_SENSOR_READING_TYPES),
    'carbon_monoxide',
  ].filter(x => {
    return (
      SMART_SENSOR_TYPES[x] === reading ||
      FLOW_SENSOR_READING_TYPES[x] === reading ||
      x === 'carbon_monoxide'
    )
  })

  return !!result[0] ? toTitleCase(result[0]) : null
}

export { getSensorReading, getReading }
