import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAccountProfileAction,
  updateAccountProfileAction,
  CLEAR_ACCOUNT_PROFILE,
} from '../actions/accountActions'
import { authAction } from '../actions/authActions'
import { UPDATE_INBOX } from '../actions/communicationsActions'

const INITIAL_STATE = {
  sites: [],
  permissions: [],
  slug: '',
  unreadChats: 0,
  // not currently used
  unreadMessageCount: 0,
}

const profileReducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAccountProfileAction.SUCCESS:
      case updateAccountProfileAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case authAction.SUCCESS:
        Object.assign(draft, payload.user)
        break
      case UPDATE_INBOX:
        Object.assign(draft, {
          unreadChats: payload.unreadChats,
          unreadMessageCount: payload.unreadMessageCount,
        })
        break
      case CLEAR_ACCOUNT_PROFILE:
        return INITIAL_STATE
    }
  })

const accountReducer = combineReducers({
  profile: profileReducer,
})

const getAccountProfile = ({ profile }) => profile
const getAccountDefaultSiteName = ({ profile }) => {
  const index = profile.sites.map(x => x.isDefault).indexOf(true)

  return index !== -1 ? profile.sites[index].name : ''
}
const getAccountProfileSlug = ({ profile }) => profile.slug

export {
  accountReducer as default,
  profileReducer,
  getAccountProfile,
  getAccountDefaultSiteName,
  getAccountProfileSlug,
}
