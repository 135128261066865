import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import { Spin } from '../common/Ant'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import FormError from '../common/FormError'
import { toTitleCase } from '../../utils/textFormatters'
import { ALL_SUPPLY_TYPES } from '../../constants'
import { required, isNumeric } from '../../utils/validators'

class EditGatewaySupplyForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    isGatewaySupplyLoading: PropTypes.bool,
    error: PropTypes.string,
  }

  render() {
    const {
      submitAction,
      handleSubmit,
      submitting,
      isGatewaySupplyLoading,
      error,
    } = this.props

    const submit = handleSubmit(submitAction)

    return isGatewaySupplyLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EditGatewaySupplyForm">
        <Form onSubmit={submit}>
          <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
            <div className="flex-ns">
              <Field
                name="name"
                component={SelectContainer}
                label="Network Gateway Supply Type"
                placeholder="Network Gateway Supply type"
                validate={required}
                className="mr3-ns w-30-ns"
                filterable
              >
                {Object.keys(ALL_SUPPLY_TYPES).map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(ALL_SUPPLY_TYPES[x])}
                  </Option>
                ))}
              </Field>
              <Field
                name="quantity"
                type="number"
                component={InputContainer}
                label="Quantity"
                validate={[required, isNumeric]}
                className="mr3-ns w-30-ns"
              />
              <Field
                name="description"
                type="text"
                component={InputContainer}
                label="Description"
                className="w-40-ns"
              />
            </div>
            <div className="flex-ns">
              <Field
                name="itemContents"
                type="text"
                component={InputContainer}
                label="Contents"
                className="w-100-ns"
              />
            </div>
          </div>
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/inventory/all/gateway-supplies" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditGatewaySupplyForm' })(
  EditGatewaySupplyForm
)
