import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown, Icon, Table, Column, Spin } from './common/Ant'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import { toTitleCase } from '../utils/textFormatters'
import { dateTimeFormatter } from '../utils/date'

class AllWorkflows extends Component {
  static propTypes = {
    workflows: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllWorkflows: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
  }

  componentDidMount() {
    this.props.getAllWorkflows()
  }

  render() {
    const { workflows, windowWidth, isLoading } = this.props
    const isFixedColumn = windowWidth < 750

    return (
      <section className="mb5 AllWorkflows">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>Workflows</Crumb>
            </Bread>
          </div>
          <div className="flex justify-center">
            <Anchor to="/workflows/create">Create New</Anchor>
          </div>
        </div>
        {isLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={workflows}
            rowKey="slug"
            scroll={{ x: 450 }}
            bordered
            pagination={false}
            tableLayout="auto"
          >
            <Column
              title="Type"
              dataIndex="workflowType"
              width={200}
              render={(text, { workflowType }) => (
                <Anchor to={`/workflows/${workflowType}`}>
                  {toTitleCase(workflowType)}
                </Anchor>
              )}
            />
            <Column
              title="Created By"
              dataIndex="addedBy"
              render={(text, { addedBy }) => (addedBy ? addedBy : '--')}
              width={100}
            />
            <Column
              title="Updated At"
              dataIndex="addedAt"
              render={(text, { addedAt }) =>
                addedAt ? dateTimeFormatter(addedAt) : '--'
              }
              width={100}
            />
            <Column
              fixed={isFixedColumn ? 'right' : false}
              width={100}
              render={(text, record) => {
                const menu = (
                  <Menu>
                    <Menu.Item key="update-workflow">
                      <Anchor
                        to={`/workflows/create?workflowType=${record.workflowType}`}
                      >
                        Update
                      </Anchor>
                    </Menu.Item>
                  </Menu>
                )

                return (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link">
                      Actions <Icon type="down" />
                    </a>
                  </Dropdown>
                )
              }}
            />
          </Table>
        )}
      </section>
    )
  }
}

export default AllWorkflows
