import { createFormAction } from 'redux-form-saga'

const getAllGatewaysAction = createFormAction('GET_ALL_GATEWAYS')
const getAllAvailableGatewaysAction = createFormAction(
  'GET_ALL_AVAILABLE_GATEWAYS'
)
const getGatewayAlertsUptimeDataAction = createFormAction(
  'GET_GATEWAY_ALERTS_CHART_DATA'
)
const getGatewayConnectedPodsAction = createFormAction(
  'GET_GATEWAY_CONNECTED_PODS'
)
const getSiteGatewaysAction = createFormAction('GET_SITE_GATEWAYS')
const getGatewayAction = createFormAction('GET_GATEWAY')
const createGatewayAction = createFormAction('CREATE_GATEWAY')
const updateGatewayAction = createFormAction('UPDATE_GATEWAY')
const deleteGatewayAction = createFormAction('DELETE_GATEWAY')
const restartGatewayAction = createFormAction('RESET_GATEWAY')
const getGatewayAssignmentHistoryAction = createFormAction(
  'GET_GATEWAY_ASSIGNMENT_HISTORY'
)
const getGatewayRestartHistoryAction = createFormAction(
  'GET_GATEWAY_RESTART_HISTORY'
)

export {
  getAllGatewaysAction,
  getAllAvailableGatewaysAction,
  getGatewayAlertsUptimeDataAction,
  getGatewayConnectedPodsAction,
  getGatewayAssignmentHistoryAction,
  getGatewayAction,
  createGatewayAction,
  updateGatewayAction,
  deleteGatewayAction,
  restartGatewayAction,
  getSiteGatewaysAction,
  getGatewayRestartHistoryAction,
}
