import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { dateTimeFormatter } from '../utils/date'
import { getSensorReading } from '../utils/sensorData'
import { toTitleCase } from '../utils/textFormatters'
import { SMART_SENSOR_TYPES, VISIBLE_READINGS } from '../constants'

const ReadingSummary = ({ summary, borderPrefix }) => (
  <div
    className={cx(
      'ReadingSummary h-100 bg-dark-gray near-white pa2',
      borderPrefix
    )}
  >
    {summary.time ? (
      <Fragment>
        <div className="mb2 b tc">
          Latest Reading: {dateTimeFormatter(summary.time, true)}
        </div>
        <ul className="pl0 mb0 list">
          {VISIBLE_READINGS.map((reading, index) => {
            const readingValue = summary[SMART_SENSOR_TYPES[reading]]
            const { color, name, unit, value, icon } = getSensorReading(
              SMART_SENSOR_TYPES[reading],
              readingValue
            )
            const prefix = cx('flex justify-between', {
              mb1: index < VISIBLE_READINGS.length - 1,
            })

            return (
              <li className={prefix} key={reading}>
                <div className="flex items-center">
                  {!!icon ? (
                    <img src={icon} alt={reading} className="w1 h1 mr2" />
                  ) : (
                    <div
                      className="mr2 br-pill"
                      style={{
                        height: 8,
                        width: 8,
                        backgroundColor: `${color}`,
                      }}
                    />
                  )}
                  <span className="b">{`${toTitleCase(name)}:`}</span>
                </div>
                {value ? `${value}${unit}` : '--'}
              </li>
            )
          })}
        </ul>
      </Fragment>
    ) : (
      <div className="flex items-center h-100 justify-center b">
        No Recent Reading
      </div>
    )}
  </div>
)

ReadingSummary.propTypes = {
  summary: PropTypes.object.isRequired,
  borderPrefix: PropTypes.string.isRequired,
}

export default ReadingSummary
