import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ companyLogo, companyName }) => {
  const name = companyName ? companyName : null

  return companyLogo ? (
    <img src={companyLogo} alt={name} height={50} width="mw5" />
  ) : (
    name
  )
}

Logo.propTypes = {
  companyName: PropTypes.string,
  companyLogo: PropTypes.string,
}

export default Logo
