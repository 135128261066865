import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import Select, { Option } from './common/Select'
import InputContainer from '../containers/common/InputContainer'
import { toTitleCase, formatSIMCarrier } from '../utils/textFormatters'
import { hasPermission } from '../utils/hasPermission'
import { formatPhoneNumber } from '../utils/forms'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  SIM_CARD_STATUS,
  SIM_CARRIERS,
  SIM_USAGE,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  VIEW_ALL,
  USER_PERMISSIONS,
} from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'

class AllSIMCards extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.simCards,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      simUsage: undefined,
      carrier: undefined,
      status: undefined,
    }
  }

  static propTypes = {
    simCards: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    meta: PropTypes.object,
    getAllSIMCards: PropTypes.func,
    deleteSIMCard: PropTypes.func,
    updateQueryParams: PropTypes.func,
    isSIMCardLoading: PropTypes.bool,
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  componentDidMount() {
    const { page, perPage, simUsage, status, carrier } = parseQueryParams(
      global.location
    )
    const { getAllSIMCards } = this.props
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      simUsage,
      status,
      carrier,
    }
    this.setState(query)
    getAllSIMCards && getAllSIMCards(createQueryString(query))
  }

  handleChange = (pagination, filters, sorter) => {
    const { getAllSIMCards, updateQueryParams } = this.props
    const { page, perPage, simUsage, status, carrier } = this.state

    if (page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
        simUsage,
        status,
        carrier,
      }

      const qs = createQueryString(query)
      getAllSIMCards && getAllSIMCards(qs)

      updateQueryParams && updateQueryParams({ search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllSIMCards, updateQueryParams } = this.props
    const { page, perPage, simUsage, status, carrier } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    const query = Object.assign(
      {
        page,
        perPage,
        simUsage,
        status,
        carrier,
      },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllSIMCards(qs)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.simCards
        .map(record => {
          let match = null
          if (record.name) {
            match = record.name.match(regex)
          } else if (record.simNumber) {
            match = record.simNumber.match(regex)
          } else if (record.id) {
            match = record.id.toString().match(regex)
          }

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  render() {
    const { deleteSIMCard, meta, windowWidth, isSIMCardLoading } = this.props
    const {
      data,
      sortedInfo,
      searchText,
      perPage,
      simUsage,
      status,
      carrier,
    } = this.state

    const isFixedColumn = windowWidth < 750

    const isFiltering = !!(
      (carrier && carrier.length) ||
      (status && status.length) ||
      (simUsage && simUsage.length)
    )

    return (
      <section className="mb5 AllSIMCards">
        {hasPermission(USER_PERMISSIONS.ADD_SIM_CARD) && (
          <div>
            <Anchor to="/inventory/sim-cards/create" className="mb3" button>
              Create New
            </Anchor>
            <div className="dib red">
              {isFiltering && (
                <p className="pl3">
                  Found {meta.total} SIM cards matching your criteria
                </p>
              )}
            </div>
          </div>
        )}
        <div className="flex-l items-center-l">
          <Select
            className="w-third-l mr3-l"
            placeholder="Status"
            mode="multiple"
            input={{
              value: status,
              onChange: this.handleFilterChange('status'),
            }}
            filterable
          >
            {['all', ...Object.keys(SIM_CARD_STATUS)].map(x => (
              <Option value={SIM_CARD_STATUS[x] || x} key={x}>
                {toTitleCase(SIM_CARD_STATUS[x]) || VIEW_ALL}
              </Option>
            ))}
          </Select>
          <Select
            className="w-third-l mr3-l"
            placeholder="Carrier"
            mode="multiple"
            input={{
              value: carrier,
              onChange: this.handleFilterChange('carrier'),
            }}
            filterable
          >
            {['all', ...Object.keys(SIM_CARRIERS)].map(x => (
              <Option value={SIM_CARRIERS[x] || x} key={x}>
                {formatSIMCarrier(SIM_CARRIERS[x]) || VIEW_ALL}
              </Option>
            ))}
          </Select>
          <Select
            className="w-third-l"
            placeholder="Usage"
            mode="multiple"
            input={{
              value: simUsage,
              onChange: this.handleFilterChange('simUsage'),
            }}
            filterable
          >
            {['all', ...Object.keys(SIM_USAGE)].map(x => (
              <Option value={SIM_USAGE[x] || x} key={x}>
                {toTitleCase(SIM_USAGE[x]) || VIEW_ALL}
              </Option>
            ))}
          </Select>
        </div>
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: searchText,
              onChange: this.handleInputChange,
            }}
          />
        </div>
        {isSIMCardLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{ x: 650 }}
            bordered
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            tableLayout="auto"
          >
            <Column
              title="SIM ID"
              dataIndex="simNumber"
              sorter={sortBy('simNumber')}
              sortOrder={sortedInfo.field === 'simNumber' && sortedInfo.order}
              width={100}
              render={(text, { simNumber, id }) => (
                <Anchor to={`/inventory/sim-cards/${id}`}>{simNumber}</Anchor>
              )}
            />

            <Column
              title="Name"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              width={200}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Carrier"
              dataIndex="carrier"
              width={150}
              sorter={sortBy('carrier')}
              sortOrder={sortedInfo.field === 'carrier' && sortedInfo.order}
              render={(text, { carrier }) =>
                text ? formatSIMCarrier(carrier) : '--'
              }
            />
            <Column
              title="Phone Number"
              dataIndex="phoneNumber"
              sorter={sortBy('phoneNumber')}
              sortOrder={sortedInfo.field === 'phoneNumber' && sortedInfo.order}
              width={100}
              render={text => (text ? formatPhoneNumber(text) : '--')}
            />
            <Column
              title="Status"
              dataIndex="status"
              sorter={sortBy('status')}
              sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
              width={100}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Usage"
              dataIndex="simUsage"
              sorter={sortBy('simUsage')}
              sortOrder={sortedInfo.field === 'simUsage' && sortedInfo.order}
              width={100}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            {hasPermission(USER_PERMISSIONS.EDIT_SIM_CARD) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={100}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-sim-card">
                        <Anchor to={`/inventory/sim-cards/${record.id}/edit`}>
                          Edit
                        </Anchor>
                      </Menu.Item>
                      <Menu.Item key="delete-sim-card">
                        <ActionAnchor onClick={() => deleteSIMCard(record.id)}>
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )

                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllSIMCards
