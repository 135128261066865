import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditWaterMeterForm from './forms/EditWaterMeterForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateWaterMeterAction } from '../actions/flowMonitorsActions'

class EditWaterMeter extends Component {
  static propTypes = {
    waterMeter: PropTypes.object.isRequired,
    getWaterMeter: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getWaterMeter, match } = this.props
    getWaterMeter(match.params.id)
  }

  render() {
    const { waterMeter } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/water-meters">Water Meters</Anchor>,
      'Edit',
      `${
        waterMeter && waterMeter.meterSerialId ? waterMeter.meterSerialId : ''
      }`,
    ]

    return (
      <section className="EditWaterMeter">
        <FormHeader crumbs={crumbs} />
        <EditWaterMeterForm
          submitAction={updateWaterMeterAction}
          initialValues={waterMeter}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditWaterMeter
