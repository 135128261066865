import React, { Component } from 'react'
import EditPartForm from '../components/forms/EditPartForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createPartAction } from '../actions/partsActions'

class CreatePart extends Component {
  render() {
    const crumbs = [
      <Anchor to="/inventory/all/parts">Parts</Anchor>,
      'Create Part',
    ]
    return (
      <section className="CreatePart">
        <FormHeader crumbs={crumbs} />
        <EditPartForm submitAction={createPartAction} />
      </section>
    )
  }
}

export default CreatePart
