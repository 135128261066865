import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Anchor } from './common/Anchor'
import { Table, Column } from './common/Ant'

class ConnectedPodsList extends Component {
  static propTypes = {
    gatewayConnectedPods: PropTypes.arrayOf(PropTypes.object).isRequired,
    getGatewayConnectedPods: PropTypes.func.isRequired,
    gatewayId: PropTypes.string.isRequired,
  }

  componentDidMount() {
    const { getGatewayConnectedPods, gatewayId } = this.props
    getGatewayConnectedPods(gatewayId)
  }

  render() {
    const { gatewayConnectedPods } = this.props

    return gatewayConnectedPods.length ? (
      <Table
        dataSource={gatewayConnectedPods}
        rowKey="id"
        scroll={{ x: 350 }}
        pagination={false}
        tableLayout="auto"
        bordered
      >
        <Column
          title="Smart Pod"
          width={100}
          dataIndex="pillarId"
          render={(text, { pillarId }) =>
            text ? (
              <Anchor to={`/inventory/pods/${pillarId}`}>{pillarId}</Anchor>
            ) : (
              '--'
            )
          }
        />
        <Column
          title="Firmware Version"
          width={100}
          dataIndex="firmwareVersion"
          render={text => text || '--'}
        />
        <Column
          title="Product Version"
          width={100}
          dataIndex="productVersion"
          render={text => text || '--'}
        />
      </Table>
    ) : (
      'No Smart Pods Connected'
    )
  }
}

export default ConnectedPodsList
