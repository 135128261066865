import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import cx from 'classnames'
import { DatePicker } from './Ant'
import Label from './Label'

const { RangePicker } = DatePicker

class DateRange extends Component {
  static propTypes = {
    label: PropTypes.string,
    startValue: PropTypes.object,
    endValue: PropTypes.object,
    shouldLimitRange: PropTypes.bool,
    limitAfterToday: PropTypes.bool,
    hideTime: PropTypes.bool,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    hideTime: false,
    shouldLimitRange: false,
    limitAfterToday: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      startValue: this.props.startValue,
      endValue: this.props.endValue,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { startValue, endValue } = this.props
    const { startValue: startState, endValue: endState } = this.state

    if (
      startValue !== prevProps.startValue ||
      endValue !== prevProps.endValue
    ) {
      this.setState({ startValue, endValue })
    }

    if (
      startState === prevState.startValue &&
      endState !== prevState.endValue
    ) {
      this.props.onClose(startState, endState)
    }

    if (startState === null && endState === null) {
      this.props.onClose()
    }
  }

  disabledDate = current => {
    const { startValue } = this.state
    const { shouldLimitRange, limitAfterToday } = this.props

    // Cannot select days after today
    if (limitAfterToday) {
      return current && current > moment().endOf('day')
    }

    // Cannot select more than 30 days after start date
    if (shouldLimitRange) {
      return (
        current &&
        current >
          moment(startValue)
            .add(1, 'month')
            .endOf('day')
      )
    }
  }

  isStartDateDisabled = startValue => {
    if (startValue) {
      const { shouldLimitRange, limitAfterToday } = this.props
      const { endValue } = this.state

      if (limitAfterToday) {
        return startValue.isAfter(moment().endOf('day'))
      }

      const limitRange =
        shouldLimitRange &&
        startValue <
          moment()
            .subtract(1, 'month')
            .startOf('day')

      return (
        limitRange || (endValue && startValue.valueOf() > endValue.valueOf())
      )
    }
    return null
  }

  isEndDateDisabled = endValue => {
    if (endValue) {
      const { startValue } = this.state

      return (
        endValue > moment().endOf('day') ||
        (startValue && endValue.valueOf() <= startValue.valueOf())
      )
    }
    return null
  }

  handleCalendarChange = dates => {
    if (dates?.length > 0) {
      this.setState({ startValue: dates[0], endValue: dates[1] })
    } else {
      this.setState({ startValue: null, endValue: null })
    }
  }

  render() {
    const { label, hideTime, className } = this.props
    const { startValue, endValue } = this.state

    const dateRangeClassNames = cx('DateRange mb3', className)

    return (
      <div className={dateRangeClassNames}>
        {label && <Label>{label}</Label>}
        <RangePicker
          className="mr2 input-reset"
          format="MMM D, YYYY HH:mm"
          showTime={!hideTime && { format: 'HH:mm' }}
          value={[startValue, endValue]}
          disabledDate={this.disabledDate}
          onCalendarChange={this.handleCalendarChange}
        />
      </div>
    )
  }
}

export default DateRange
