import { createFormAction } from 'redux-form-saga'

const getAllAlertSummariesAction = createFormAction('GET_ALERT_SUMMARIES')
const getAlertSummaryAction = createFormAction('GET_ALERT_SUMMARY')
const createAlertSummaryAction = createFormAction('CREATE_ALERT_SUMMARY')
const updateAlertSummaryAction = createFormAction('UPDATE_ALERT_SUMMARY')
const deleteAlertSummaryAction = createFormAction('DELETE_ALERT_SUMMARY')

export {
  getAllAlertSummariesAction,
  getAlertSummaryAction,
  createAlertSummaryAction,
  updateAlertSummaryAction,
  deleteAlertSummaryAction,
}
