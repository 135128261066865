import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getSensorReading } from '../../utils/sensorData'
import { snakeCaseToCamelCase } from '../../utils/textFormatters'
// import { createQueryString, parseQueryParams } from '../../utils/queryParams'
import { FLOW_SENSOR_READING_TYPES, COLORS } from '../../constants'
import moment from 'moment'

const FlowHistoryChart = ({ data, columns, updateQueryParams }) => {
  const [visibleReadings, setVisibleReadings] = useState([
    FLOW_SENSOR_READING_TYPES.FLOW_VALUE,
    FLOW_SENSOR_READING_TYPES.AVERAGE_WEEKLY_FLOW,
  ])

  // TODO build default opts object and extend for each chart??
  const chartOptions = {
    credits: { enabled: false },
    exporting: { enabled: false },
    chart: {
      zoomType: 'xy',
      style: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
      },
    },
    plotOptions: {
      series: {
        type: 'line',
        events: {
          legendItemClick: ({ target: { userOptions } }) => {
            if (!userOptions.visible) {
              visibleReadings.push(userOptions.columnName)
            } else {
              const index = visibleReadings.indexOf(userOptions.columnName)
              visibleReadings.splice(index, 1)
            }

            // TODO this isn't working...chart doesn't update...I dunno
            // const {
            //   endTime,
            //   startTime,
            //   smooth,
            //   smoothInterval
            // } = parseQueryParams(global.location)
            // updateQueryParams({
            //   search: createQueryString({
            //     endTime,
            //     startTime,
            //     smooth,
            //     smoothInterval
            //     // visibleReadings
            //   })
            // })

            setVisibleReadings(visibleReadings)
          },
        },
      },
      column: {
        animation: false,
      },
    },
    title: {
      text: null,
    },
    yAxis: [
      {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        labels: {
          format: '{value} gal/min',
          style: {
            color: COLORS.BLUE,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        labels: {
          format: '{value} °F',
          style: {
            color: COLORS.NAVY,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
        opposite: true,
      },
    ],
    xAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'longdash',
      type: 'datetime',
      labels: {
        style: {
          color: COLORS.BLACK_80,
          fontSize: '0.75rem',
        },
      },
      title: {
        text: null,
      },
      crosshair: true,
    },
    tooltip: {
      valueDecimals: 2,
      headerFormat:
        "<span style='font-size: 0.875rem; margin-bottom: 0.875rem'>{point.key}</span><br />",
      style: {
        fontSize: '0.875rem',
        color: COLORS.BLACK_80,
      },
      shared: true,
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '0.75rem',
        color: COLORS.BLACK_80,
      },
      itemHoverStyle: {
        color: COLORS.MOON_GRAY,
      },
    },
    series: columns.map(columnx => {
      const column = snakeCaseToCamelCase(columnx)
      const readingData = getSensorReading(column)
      if (column === 'temp') {
        return {
          visible: visibleReadings.indexOf(column) !== -1,
          name: 'Pipe Temperature',
          type: 'spline',
          yAxis: 1,
          color: COLORS.NAVY,
          columnName: column,
          data: data.map(y => [
            moment(y.time).valueOf(),
            Number(parseFloat(y[column]).toFixed(2)),
          ]),
          tooltip: {
            valueSuffix: readingData.unit,
          },
        }
      } else {
        return {
          visible: visibleReadings.indexOf(column) !== -1,
          name: readingData.name,
          type: 'spline',
          color: readingData.color,
          columnName: column,
          data: data.map(y => [
            moment(y.time).valueOf(),
            Number(parseFloat(y[column]).toFixed(2)),
          ]),
          tooltip: {
            valueSuffix: readingData.unit,
          },
        }
      }
    }),
  }

  return (
    <div className="FlowHistoryChart">
      <div className="mb3">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  )
}

FlowHistoryChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateQueryParams: PropTypes.func.isRequired,
}

export default FlowHistoryChart
