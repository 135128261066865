import React from 'react'
import PropTypes from 'prop-types'
import { H3 } from './common/Headers'
import LastUpdated from './common/LastUpdated'
import { groupBy } from '../utils/helpers'

const ACTION_REPORT_RESPONSE_TYPES = {
  CAUSES: 'causes',
  ACTIONS: 'actions',
}

const FILE_TYPES = {
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
}

const getResponse = (responses, responseType, fileType) => {
  const type = responseType.toUpperCase()
  const result = responses[type]
    ? responses[type].filter(x => x.fileType === fileType)
    : []

  if (result.length > 0) {
    return result[0]
  }

  return null
}

const ActionReport = ({ actionReport }) => {
  let audioCause
  let imageCause
  let audioActions
  let imageActions

  const assignedUser =
    actionReport.assignedUsers && actionReport.assignedUsers.length > 0
      ? actionReport.assignedUsers[0]
      : null

  const responsibleUser =
    actionReport.users && actionReport.users.length > 0
      ? actionReport.users[0]
      : null

  if (actionReport.attachments && actionReport.attachments.length > 0) {
    const responses = groupBy(actionReport.attachments, x => x.responseType)
    audioCause = getResponse(
      responses,
      ACTION_REPORT_RESPONSE_TYPES.CAUSES,
      FILE_TYPES.AUDIO
    )
    imageCause = getResponse(
      responses,
      ACTION_REPORT_RESPONSE_TYPES.CAUSES,
      FILE_TYPES.IMAGE
    )
    audioActions = getResponse(
      responses,
      ACTION_REPORT_RESPONSE_TYPES.ACTIONS,
      FILE_TYPES.AUDIO
    )
    imageActions = getResponse(
      responses,
      ACTION_REPORT_RESPONSE_TYPES.ACTIONS,
      FILE_TYPES.IMAGE
    )
  }

  return (
    <div className="ActionReport">
      <div className="flex-l mb2">
        <div className="w-100 w-50-l mb3 mb0-l mr2-l ba b--light-gray bg-near-white br2 pa2">
          <H3>Assigned User</H3>
          {assignedUser ? (
            <div className="mb2">
              <span>
                {assignedUser.firstName} {assignedUser.lastName} &mdash;{' '}
                {assignedUser.company}
              </span>
            </div>
          ) : (
            <div className="mb2">No Assigned User</div>
          )}
        </div>
        <div className="w-100 w-50-l mb3 mb0-l mr2-l ba b--light-gray bg-near-white br2 pa2">
          <H3>Responsible User</H3>
          {responsibleUser ? (
            <div className="mb2">
              <span>
                {responsibleUser.firstName} {responsibleUser.lastName} &mdash;{' '}
                {responsibleUser.company}
              </span>
            </div>
          ) : (
            <div className="mb2">No Responsible User</div>
          )}
        </div>
      </div>

      <div className="flex-l mb2">
        <div className="w-100 w-50-l mb3 mb0-l mr2-l ba b--light-gray bg-near-white br2 pa2">
          <H3>Alert Cause</H3>
          {audioCause ? (
            <div className="mb2">
              {actionReport.causes ? (
                <span>{actionReport.causes}</span>
              ) : (
                <span className="i">Transcribing audio memo...</span>
              )}
            </div>
          ) : (
            <div className="mb2">No Cause Reported</div>
          )}
          {imageCause && (
            <img src={imageCause.assetFileUrl} alt="Alert Cause" />
          )}
        </div>
        <div className="w-100 w-50-l ml2-l ba b--light-gray bg-near-white br2 pa2">
          <H3>Corrective Action</H3>
          {audioActions ? (
            <div className="mb2">
              {actionReport.actions ? (
                <span>{actionReport.actions}</span>
              ) : (
                <span className="i">Transcribing audio memo...</span>
              )}
            </div>
          ) : (
            <div className="mb2">No Corrective Action Reported</div>
          )}
          {imageActions && (
            <img src={imageActions.assetFileUrl} alt="Corrective Action" />
          )}
        </div>
      </div>

      {actionReport.updatedAt && (
        <LastUpdated
          user={actionReport.author}
          updatedAt={actionReport.updatedAt}
        />
      )}
    </div>
  )
}

ActionReport.propTypes = {
  actionReport: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    causes: PropTypes.string,
    actions: PropTypes.string,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        assetFileUrl: PropTypes.string,
        fileType: PropTypes.string,
        responseType: PropTypes.string,
        // addedAt: PropTypes.string
      })
    ).isRequired,
    updatedAt: PropTypes.string,
  }).isRequired,
}

export default ActionReport
