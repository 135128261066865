import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DateRange from '../common/DateRange'
import moment from 'moment'
import { parse } from 'query-string'
import { Spin } from '../common/Ant'
import { createQueryString } from '../../utils/queryParams'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class UptimeChart extends Component {
  static propTypes = {
    uptimeData: PropTypes.object.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    getGatewayUptimeData: PropTypes.func,
    getLocationUptimeData: PropTypes.func,
    isByLocation: PropTypes.bool,
    isByLocationUptime: PropTypes.bool,
    updateQueryParams: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.now = moment()
    this.state = {
      endTime: this.now.endOf('day'),
      startTime: moment(this.now)
        .subtract(20, 'd')
        .startOf('day'),
    }
  }

  componentDidMount() {
    const { startTime, endTime } = this.state
    const {
      match,
      getGatewayUptimeData,
      getLocationUptimeData,
      isByLocationUptime,
      isByLocation,
    } = this.props
    const { pillarId, siteSlug, floorId, id } = match.params

    const searchParams = parse(global.location.search)

    let query
    if (searchParams.from && searchParams.to) {
      query = {
        from: searchParams.from,
        to: searchParams.to,
        uptimeChart: isByLocationUptime,
      }
      this.setState({
        startTime: moment(searchParams.from * 1000),
        endTime: moment(searchParams.to * 1000),
      })
    } else {
      query = {
        from: startTime.unix(),
        to: endTime.unix(),
        uptimeChart: isByLocationUptime,
      }
    }
    const qs = createQueryString(query)
    // isByLocation
    //   ? getLocationUptimeData(siteSlug, floorId, id, qs)
    //   : getGatewayUptimeData(pillarId, qs)
  }

  onCloseDateRange = (startTime, endTime) => {
    const {
      match,
      getGatewayUptimeData,
      getLocationUptimeData,
      isByLocationUptime,
      isByLocation,
      updateQueryParams,
    } = this.props
    const { pillarId, siteSlug, floorId, id } = match.params
    this.setState({ startTime, endTime })

    const query = {
      from: startTime.unix(),
      to: endTime.unix(),
      uptimeChart: isByLocationUptime,
    }
    const qs = createQueryString(query)

    updateQueryParams({
      pathname: global.location.pathname,
      search: createQueryString({
        from: startTime.unix(),
        to: endTime.unix(),
        uptimeChart: isByLocationUptime,
      }),
    })

    // isByLocation
    //   ? getLocationUptimeData(siteSlug, floorId, id, qs)
    //   : getGatewayUptimeData(pillarId, qs)
  }

  render() {
    const { startTime, endTime } = this.state
    const { uptimeData, isChartDataLoading } = this.props
    const { xData, allData } = uptimeData

    const options = {
      credits: { enabled: false },
      exporting: { enabled: false },
      chart: {
        type: 'column',
        style: {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
        },
      },
      title: {
        text: null,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          states: {
            inactive: {
              enabled: false,
            },
          },
          borderWidth: 0,
          showInLegend: false,
        },
      },
      xAxis: {
        categories: xData,
        labels: {
          rotation: -60,
          style: {
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Hours',
          style: {
            fontWeight: 'bold',
          },
        },
        tickAmount: 25,
        max: 24,
        tickInterval: 1,
        gridLineWidth: 1,
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{point.name}',
      },
      series: allData,
    }

    return isChartDataLoading ? (
      <Spin size="large" className="w-100 items-center mv5" />
    ) : (
      <div className="UptimeChart">
        <div className="flex justify-center">
          <DateRange
            label="Select Uptime Date Range"
            startValue={startTime}
            endValue={endTime}
            onClose={this.onCloseDateRange}
            hideTime
          />
        </div>
        <div className="mb5">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    )
  }
}

export default UptimeChart
