import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Breadcrumb } from './Ant'
import { SIZES } from '../../constants'

const { Item } = Breadcrumb

const Bread = ({ children, size, align }) => {
  const wrapperPrefix = cx('tc', {
    'flex justify-center': align === 'center',
  })
  const prefix = cx({
    f4: size === SIZES.MEDIUM,
    f5: size === SIZES.SMALL,
    f6: size === SIZES.XS,
  })

  return (
    <div className={wrapperPrefix}>
      <Breadcrumb className={prefix}>{children}</Breadcrumb>
    </div>
  )
}

Bread.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
}

Bread.defaultProps = {
  align: 'center',
  size: SIZES.MEDIUM,
}

export { Bread, Item as Crumb }
