import { connect } from 'react-redux'
import {
  getVisibleWorkflows,
  getCurrentWorkflow,
} from '../reducers/workflowsReducer'
import { isWorkflowLoading, getWindowWidth } from '../reducers/uiReducer'
import {
  getAllWorkflowsAction,
  getWorkflowAction,
  setWorkflowTypeAction,
} from '../actions/workflowsActions'
import WorkflowPage from '../components/pages/WorkflowPage'
import { createQueryString } from '../utils/queryParams'

const mapStateToProps = ({ workflows, account, ui }) => ({
  workflows: getVisibleWorkflows(workflows),
  currentWorkflow: getCurrentWorkflow(workflows),
  isLoading: isWorkflowLoading(ui),
  windowWidth: getWindowWidth(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllWorkflows: () =>
    dispatch(
      getAllWorkflowsAction.request(createQueryString({ perPage: 'all' }))
    ),
  getWorkflow: qs => dispatch(getWorkflowAction.request(qs)),
  setWorkflowType: workflowType =>
    dispatch(setWorkflowTypeAction(workflowType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPage)
