import { createFormAction } from 'redux-form-saga'

const getAllBuildingsAction = createFormAction('GET_ALL_BUILDINGS')
const getBuildingAction = createFormAction('GET_BUILDING')
const createBuildingAction = createFormAction('CREATE_BUILDING')
const updateBuildingAction = createFormAction('UPDATE_BUILDING')
const deleteBuildingAction = createFormAction('DELETE_BUILDING')
const evacuateBuildingAction = createFormAction('EVACUATE_BUILDING')

const addThresholdToBuildingAction = createFormAction(
  'ADD_THRESHOLD_TO_BUILDING'
)
const deleteThresholdFromBuildingAction = createFormAction(
  'DELETE_THRESHOLD_FROM_BUILDING'
)

const getAllBuildingThresholdUserMappingsAction = createFormAction(
  'GET_ALL_BUILDING_THRESHOLD_USER_MAPPINGS'
)
const updateBuildingThresholdUserMappingAction = createFormAction(
  'UPDATE_BUILDING_THRESHOLD_USER_MAPPING'
)

export {
  getAllBuildingsAction,
  getBuildingAction,
  createBuildingAction,
  updateBuildingAction,
  deleteBuildingAction,
  evacuateBuildingAction,
  addThresholdToBuildingAction,
  deleteThresholdFromBuildingAction,
  getAllBuildingThresholdUserMappingsAction,
  updateBuildingThresholdUserMappingAction,
}
