import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { AntCheckboxGroup } from './Ant'
import Meta from './Meta'
import Label from './Label'

const CheckboxGroup = props => {
  const {
    input,
    disabledOptions = [],
    availableOptions = [],
    meta,
    className = '',
    label = '',
    disabledValuesLabel = 'Unavailable',
  } = props
  const checkboxClassNames = cx('CheckboxGroup mb3', className)
  // FIXME why does onBlur => {payload: true} and throw an error for CheckboxGroup value?
  const { onBlur, ...rest } = input

  return (
    <div className={checkboxClassNames}>
      <div className="db">
        {!!availableOptions.length && (
          <div className="mb2">
            {label && <Label>{label}</Label>}
            <AntCheckboxGroup options={availableOptions} {...rest} />
          </div>
        )}
        {!!disabledOptions.length && (
          <div>
            <Label>{disabledValuesLabel}</Label>
            <AntCheckboxGroup options={disabledOptions} disabled {...rest} />
          </div>
        )}
      </div>
      <Meta {...meta} />
    </div>
  )
}

CheckboxGroup.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  disabledValuesLabel: PropTypes.string,
  availableOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabledOptions: PropTypes.arrayOf(PropTypes.object),
}

export default CheckboxGroup
