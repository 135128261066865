import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { reduxForm, Field } from 'redux-form'
import { Spin } from '../common/Ant'
import MainContent from '../common/MainContent'
import { authAction } from '../../actions/authActions'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import Button from '../common/Button'
import FormError from '../common/FormError'
import { H2 } from '../common/Headers'
import { Anchor } from '../common/Anchor'
import { required, isEmail } from '../../utils/validators'
import appStoreBadge from '../../assets/app-store-badge.png'
import googlePlayBadge from '../../assets/google-play-badge.png'

const AuthForm = ({ handleSubmit, submitting, error, isLoading }) => {
  const submit = handleSubmit(authAction)

  return (
    <MainContent>
      <section className="AuthForm">
        <Helmet>
          <title>Log In</title>
        </Helmet>
        {isLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <div>
            <Form className="measure-narrow center mb5" onSubmit={submit}>
              <fieldset className="pa0 mh0 ba b--transparent">
                <H2>Log In</H2>
                <Field
                  name="username"
                  type="email"
                  component={InputContainer}
                  label="Email"
                  validate={[required, isEmail]}
                />
                <Field
                  name="password"
                  type="password"
                  component={InputContainer}
                  label="Password"
                  validate={[required]}
                />
              </fieldset>
              <FormError error={error} />
              <div className="mb2">
                <Button
                  className="w-100"
                  text="Log In"
                  type="submit"
                  submitting={submitting}
                />
              </div>
              <div className="lh-copy tc">
                <Anchor className="f6" to="/reset-password">
                  Reset Password
                </Anchor>
              </div>
              <div className="lh-copy tc">
                <Anchor className="f6" to="/confirm">
                  Resend Confirmation Email
                </Anchor>
              </div>
            </Form>
            <div className="measure-narrow center">
              <div className="mb3 mw4 center">
                <a
                  href="https://apps.apple.com/us/app/pillar-on-site/id1476714166?ls=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="Download on the App Store" src={appStoreBadge} />
                </a>
              </div>
              <div className="mw4 center">
                <a
                  href="https://play.google.com/store/apps/details?id=tech.pillar.PillarNative&hl=en&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="Get it on Google Play" src={googlePlayBadge} />
                </a>
              </div>
            </div>
          </div>
        )}
      </section>
    </MainContent>
  )
}

AuthForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({ form: 'AuthForm' })(AuthForm)
