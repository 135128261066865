import produce from 'immer'
import { combineReducers } from 'redux'
import sortBy from 'sort-by'
import {
  getAllEscalationPoliciesAction,
  getEscalationPolicyAction,
  updateEscalationPolicyAction,
} from '../actions/escalationPoliciesActions'
import { getAllEscalationPoliciesByCompanyIdAction } from '../actions/companiesActions'

const bySlug = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllEscalationPoliciesAction.SUCCESS:
        payload.items.forEach(escalationPolicy => {
          draft[escalationPolicy.slug] = escalationPolicy
        })
        break
      case getAllEscalationPoliciesByCompanyIdAction.SUCCESS:
        payload.items.forEach(escalationPolicy => {
          draft[escalationPolicy.slug] = escalationPolicy
        })
        break
    }
  })

const visibleSlugs = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllEscalationPoliciesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(escalationPolicy => escalationPolicy.slug)
        )
        break
      case getAllEscalationPoliciesByCompanyIdAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(escalationPolicy => escalationPolicy.slug)
        )
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllEscalationPoliciesAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const current = (state = { policyGroups: [] }, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getEscalationPolicyAction.SUCCESS:
        action.payload.policyGroups.sort(sortBy('step'))

        Object.assign(draft, action.payload)
        break
      case updateEscalationPolicyAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const escalationPoliciesReducer = combineReducers({
  bySlug,
  visibleSlugs,
  current,
  meta,
})

const getEscalationPolicy = (state, slug) => state.bySlug[slug]
const getCurrentEscalationPolicy = state => state.current
const getVisibleEscalationPolicies = state =>
  state.visibleSlugs.map(slug => getEscalationPolicy(state, slug))
const getEscalationPoliciesMeta = state => state.meta

export {
  escalationPoliciesReducer as default,
  getCurrentEscalationPolicy,
  getVisibleEscalationPolicies,
  getEscalationPoliciesMeta,
}
