import React from 'react'
import EditTagForm from '../components/forms/EditTagForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createTagAction } from '../actions/commentsActions'

const CreateTag = () => {
  const crumbs = [
    <Anchor to="/metadata/all">Metadata</Anchor>,
    <Anchor to="/metadata/all/tags">Tags</Anchor>,
    'Create Tag',
  ]

  return (
    <section className="CreateTag">
      <FormHeader crumbs={crumbs} />
      <EditTagForm submitAction={createTagAction} />
    </section>
  )
}

export default CreateTag
