import { createFormAction } from 'redux-form-saga'

const authAction = createFormAction('AUTHENTICATE')
const logOutAction = createFormAction('LOG_OUT')
const verifyTwoFactorAuthTokenAction = createFormAction(
  'VERIFY_TWO_FACTOR_TOKEN'
)
const resendTwoFactorAuthTokenAction = createFormAction(
  'RESEND_TWO_FACTOR_TOKEN'
)
const requestPasswordResetAction = createFormAction('REQUEST_PASSWORD_RESET')
const resetPasswordAction = createFormAction('RESET_PASSWORD')
const verifyResetPasswordTokenAction = createFormAction(
  'VERIFY_RESET_PASSWORD_TOKEN'
)
const requestAccountConfirmationAction = createFormAction(
  'REQUEST_ACCOUNT_CONFIRMATION'
)
const confirmAccountAction = createFormAction('CONFIRM_ACCOUNT')
const getUnconfirmedUserAction = createFormAction('GET_UNCONFIRMED_USER')

const SET_PASSWORD_RESET_TOKEN = 'SET_PASSWORD_RESET_TOKEN'
const SET_ACCOUNT_CONFIRMATION_TOKEN = 'SET_ACCOUNT_CONFIRMATION_TOKEN'
const HAS_AUTH_TOKEN = 'HAS_AUTH_TOKEN'
const UNAUTHORIZED = 'UNAUTHORIZED'

const setPasswordResetTokenAction = payload => ({
  type: SET_PASSWORD_RESET_TOKEN,
  payload,
})

const setAccountConfirmationTokenAction = payload => ({
  type: SET_ACCOUNT_CONFIRMATION_TOKEN,
  payload,
})

const hasAuthTokenAction = payload => ({ type: HAS_AUTH_TOKEN, payload })
const unauthorizedAction = () => ({ type: UNAUTHORIZED })

const SET_INITIAL_PATHNAME = 'SET_INITIAL_PATHNAME'
const setInitialPathnameAction = payload => ({
  type: SET_INITIAL_PATHNAME,
  payload,
})

export {
  authAction,
  logOutAction,
  requestPasswordResetAction,
  verifyResetPasswordTokenAction,
  resetPasswordAction,
  requestAccountConfirmationAction,
  confirmAccountAction,
  setPasswordResetTokenAction,
  setAccountConfirmationTokenAction,
  getUnconfirmedUserAction,
  hasAuthTokenAction,
  unauthorizedAction,
  setInitialPathnameAction,
  verifyTwoFactorAuthTokenAction,
  resendTwoFactorAuthTokenAction,
  SET_PASSWORD_RESET_TOKEN,
  SET_ACCOUNT_CONFIRMATION_TOKEN,
  HAS_AUTH_TOKEN,
  UNAUTHORIZED,
  SET_INITIAL_PATHNAME,
}
