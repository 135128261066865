import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TimeSelector from '../../components/common/TimeSelector'
import { getRequiredFields } from '../../reducers/formReducer'

const TimeSelectorContainer = ({ requiredFields, ...rest }) => (
  <TimeSelector requiredFields={requiredFields} {...rest} />
)

TimeSelectorContainer.propTypes = {
  requiredFields: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = ({ form }) => ({
  requiredFields: getRequiredFields(form),
})

export default connect(mapStateToProps)(TimeSelectorContainer)
