import { createFormAction } from 'redux-form-saga'

const getAllThresholdsAction = createFormAction('GET_ALL_THRESHOLDS')
const createThresholdAction = createFormAction('CREATE_THRESHOLD')
const getThresholdAction = createFormAction('GET_THRESHOLD')
const updateThresholdAction = createFormAction('UPDATE_THRESHOLD')
const deleteThresholdAction = createFormAction('DELETE_THRESHOLD')
const getThresholdEditHistoryAction = createFormAction(
  'GET_THRESHOLD_EDIT_HISTORY'
)
const toggleThresholdOptionAction = createFormAction('TOGGLE_THRESHOLD_OPTION')
const sendTestNotificationAction = createFormAction(
  'TEST_THRESHOLD_NOTIFICATION'
)
const getThresholdAssignmentsAction = createFormAction(
  'GET_THRESHOLD_ASSIGNMENTS'
)

const getAllThresholdGroupsAction = createFormAction('GET_ALL_THRESHOLD_GROUPS')
const createThresholdGroupAction = createFormAction('CREATE_THRESHOLD_GROUP')
const updateThresholdGroupAction = createFormAction('UPDATE_THRESHOLD_GROUP')
const deleteThresholdGroupAction = createFormAction('DELETE_THRESHOLD_GROUP')

const createSiteThresholdAction = createFormAction('CREATE_SITE_THRESHOLD')
const updateSiteThresholdAction = createFormAction('UPDATE_SITE_THRESHOLD')

export {
  getAllThresholdsAction,
  createThresholdAction,
  getThresholdAction,
  updateThresholdAction,
  deleteThresholdAction,
  toggleThresholdOptionAction,
  sendTestNotificationAction,
  getThresholdAssignmentsAction,
  getThresholdEditHistoryAction,
  getAllThresholdGroupsAction,
  createThresholdGroupAction,
  updateThresholdGroupAction,
  deleteThresholdGroupAction,
  createSiteThresholdAction,
  updateSiteThresholdAction,
}
