import { createFormAction } from 'redux-form-saga'

const getAllSitesAction = createFormAction('GET_ALL_SITES')
const getSiteAction = createFormAction('GET_SITE')
const getAdminSiteAction = createFormAction('GET_ADMIN_SITE')
const createSiteAction = createFormAction('CREATE_SITE')
const updateSiteAction = createFormAction('UPDATE_SITE')
const deleteSiteAction = createFormAction('DELETE_SITE')
const getPodAssignmentHistoryBySiteAction = createFormAction(
  'GET_POD_ASSIGNMENT_HISTORY_BY_SITE'
)
const addUserToSiteAction = createFormAction('ADD_USER_TO_SITE')
const deleteUserFromSiteAction = createFormAction('DELETE_USER_FROM_SITE')
const addGatewayToSiteAction = createFormAction('ADD_GATEWAY_TO_SITE')
const deleteGatewayFromSiteAction = createFormAction('DELETE_GATEWAY_FROM_SITE')
const getSiteAvailableUsersAction = createFormAction('GET_SITE_AVAILABLE_USERS')
const getAllThresholdsBySiteAction = createFormAction(
  'GET_ALL_THRESHOLDS_BY_SITE'
)
const addThresholdToSiteAction = createFormAction('ADD_THRESHOLD_TO_SITE')
const deleteThresholdFromSiteAction = createFormAction(
  'DELETE_THRESHOLD_FROM_SITE'
)
const generateGrafanaConfigAction = createFormAction('GENERATE_GRAFANA_CONFIG')
const addEscalationPolicytoSiteThresholdAction = createFormAction(
  'ADD_ESCALATION_POLICY_TO_SITE_THRESHOLD'
)

const getWeatherByLatLngAction = createFormAction('GET_WEATHER')
const CLEAR_WEATHER = 'CLEAR_WEATHER'
const clearWeatherAction = () => ({
  type: CLEAR_WEATHER,
})

const getAllSiteThresholdUserMappingsAction = createFormAction(
  'GET_ALL_SITE_THRESHOLD_USER_MAPPINGS'
)
const getAllSiteAggregateThresholdUserMappingsAction = createFormAction(
  'GET_ALL_SITE_AGGREGATE_THRESHOLD_USER_MAPPINGS'
)
const updateSiteThresholdUserMappingAction = createFormAction(
  'UPDATE_SITE_THRESHOLD_USER_MAPPING'
)
const getSiteAssetsAction = createFormAction('GET_SITE_ASSETS')
const getAllUsersBySiteAction = createFormAction('GET_ALL_USERS_BY_SITE')
const getAllAggregateThresholdsBySiteAction = createFormAction(
  'GET_ALL_AGGREGATE_THRESHOLDS_BY_SITE'
)
const getActivityLogAction = createFormAction('GET_ACTIVITY_LOG')

const getSiteContractorsAction = createFormAction('GET_SITE_CONTRACTORS')

const addSiteContractorAction = createFormAction('ADD_SITE_CONTRACTOR')

const deleteSiteContractorAction = createFormAction('DELETE_SITE_CONTRACTOR')

const getRiMetricsAction = createFormAction('GET_RI_METRICS')

const createSiteActionReportAction = createFormAction(
  'CREATE_SITE_ACTION_REPORT'
)
const updateSiteActionReportAction = createFormAction(
  'UPDATE_SITE_ACTION_REPORT'
)
const deleteSiteActionReportAction = createFormAction(
  'DELETE_SITE_ACTION_REPORT'
)

const uploadUsersCSVAction = createFormAction('UPLOAD_USERS_CSV')
const uploadFloorPlanPdfAction = createFormAction('UPLOAD_FLOOR_PLAN_PDF')
const getFloorPlanSnapsAction = createFormAction('GET_FLOOR_PLAN_SNAPS')

const getInventoryHistoryBySiteAction = createFormAction(
  'GET_INVENTORY_HISTORY_BY_SITE'
)

const getAllInvoicesAction = createFormAction('GET_ALL_INVOICES')
const createInvoiceAction = createFormAction('CREATE_INVOICE')
const updateInvoiceAction = createFormAction('UPDATE_INVOICE')
const deleteInvoiceAction = createFormAction('DELETE_INVOICE')
const uploadDocumentAction = createFormAction('UPLOAD_DOCUMENT')
const getAllDocumentsAction = createFormAction('GET_ALL_DOCUMENTS')

export {
  getAllSitesAction,
  getSiteAction,
  getAdminSiteAction,
  createSiteAction,
  updateSiteAction,
  deleteSiteAction,
  addUserToSiteAction,
  addSiteContractorAction,
  deleteSiteContractorAction,
  deleteUserFromSiteAction,
  addGatewayToSiteAction,
  deleteGatewayFromSiteAction,
  getSiteAvailableUsersAction,
  getAllThresholdsBySiteAction,
  addThresholdToSiteAction,
  deleteThresholdFromSiteAction,
  generateGrafanaConfigAction,
  addEscalationPolicytoSiteThresholdAction,
  getWeatherByLatLngAction,
  CLEAR_WEATHER,
  clearWeatherAction,
  getAllSiteThresholdUserMappingsAction,
  getAllSiteAggregateThresholdUserMappingsAction,
  updateSiteThresholdUserMappingAction,
  getSiteAssetsAction,
  getAllUsersBySiteAction,
  getPodAssignmentHistoryBySiteAction,
  getAllAggregateThresholdsBySiteAction,
  getActivityLogAction,
  getSiteContractorsAction,
  getRiMetricsAction,
  createSiteActionReportAction,
  updateSiteActionReportAction,
  deleteSiteActionReportAction,
  uploadUsersCSVAction,
  uploadFloorPlanPdfAction,
  getFloorPlanSnapsAction,
  getInventoryHistoryBySiteAction,
  getAllInvoicesAction,
  createInvoiceAction,
  updateInvoiceAction,
  deleteInvoiceAction,
  uploadDocumentAction,
  getAllDocumentsAction,
}
