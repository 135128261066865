import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import cx from 'classnames'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Spin,
  RadioButton,
  RadioGroup,
} from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import CreateConversationModal from './CreateConversationModal'
import BridgePhoneCallModal from './BridgePhoneCallModal'
import MessageType from './MessageType'
import { configurePagination } from '../utils/helpers'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { dateTimeFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  CHAT_MESSAGE_TYPES,
  CONVERSATION_STATUS,
} from '../constants'

const DEFAULT_ALL = 'f'

const INBOX_VIEW = {
  ALL_CONVERSATIONS: 't',
  PERSONAL_CONVERSATIONS: 'f',
}

const Inbox = ({
  getAllConversations,
  getConversation,
  conversations,
  meta,
  isCommunicationLoading,
  getUsers,
  users,
  createConversation,
  createBridgedPhoneCall,
  closeConversation,
  updateQueryParams,
  accountProfile,
}) => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [sortedInfo, setSortedInfo] = useState({})
  const [all, setAll] = useState(DEFAULT_ALL)
  const [status, setStatus] = useState()
  const [isConversationModalVisible, setIsConversationModalVisible] = useState(
    false
  )
  const [
    isBridgePhoneCallModalVisible,
    setIsBridgePhoneCallModalVisible,
  ] = useState(false)

  useEffect(() => {
    const { page, perPage, all, status } = parseQueryParams(global.location)

    const nextPage = page ? parseInt(page, 10) : DEFAULT_PAGE
    const nextPerPage = perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE
    const nextAll = all ? all : DEFAULT_ALL
    const nextStatus = status ? status : undefined

    getAllConversations({
      page: nextPage,
      perPage: nextPerPage,
      all: nextAll,
      status: nextStatus,
    })

    setPage(nextPage)
    setPerPage(nextPerPage)
    setAll(nextAll)
    setStatus(nextStatus)
  }, [getAllConversations])

  useEffect(() => {
    setData(conversations)
  }, [data, conversations])

  useEffect(() => {
    if (!sortedInfo.field) {
      setData(conversations.sort(sortBy('-lastMessageTimestamp')))
    }
  }, [conversations, sortedInfo])

  const handleChange = (pagination, filters, sorter) => {
    if (pagination.current !== page) {
      const query = { page: pagination.current, perPage }

      getAllConversations(query)
      setPage(pagination.current)

      updateQueryParams({
        search: createQueryString(query),
      })
    }

    setSortedInfo(sorter)
  }

  const handleFilterChange = keyName => value => {
    const query = Object.assign({ page, perPage, all, status })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      setPage(DEFAULT_PAGE)
    }

    getAllConversations({ ...query, [keyName]: value })

    updateQueryParams({
      search: createQueryString({ ...query, [keyName]: value }),
    })

    switch (keyName) {
      case 'perPage':
        setPerPage(value)
        break
      case 'all':
        setAll(value)
        break
      case 'status':
        setStatus(value)
        break
    }
  }

  const handleBridgePhoneCall = user =>
    createBridgedPhoneCall(user.phoneNumber, user.slug)

  const handleCloseConversation = conversationSlug =>
    closeConversation(conversationSlug)

  return (
    <div className="Inbox mb5">
      {isCommunicationLoading ? (
        <Spin size="large" className="w-100 center mv5" />
      ) : (
        <div>
          <div className="flex-ns items-center mb3">
            <ActionAnchor
              onClick={() => setIsConversationModalVisible(true)}
              button
              className="mr3-ns mb2 mb0-ns"
            >
              Create New Conversation
            </ActionAnchor>

            {accountProfile.hasSupportNumber && (
              <ActionAnchor
                className="mr3-ns mb2 mb0-ns"
                onClick={() => setIsBridgePhoneCallModalVisible(true)}
                button
              >
                Place Phone Call
              </ActionAnchor>
            )}

            <RadioGroup
              onChange={({ target: { value } }) =>
                handleFilterChange('all')(value)
              }
              value={all}
              className="flex-l mr3-ns mb2 mb0-ns"
              size="small"
            >
              <RadioButton value={INBOX_VIEW.PERSONAL_CONVERSATIONS}>
                Personal Conversations
              </RadioButton>
              <RadioButton value={INBOX_VIEW.ALL_CONVERSATIONS}>
                All Conversations
              </RadioButton>
            </RadioGroup>

            <RadioGroup
              onChange={({ target: { value } }) =>
                handleFilterChange('status')(value)
              }
              value={status}
              className="flex-l"
              size="small"
            >
              <RadioButton value={undefined}>All</RadioButton>
              <RadioButton value={CONVERSATION_STATUS.ACTIVE}>
                Active
              </RadioButton>
              <RadioButton value={CONVERSATION_STATUS.RESOLVED}>
                Resolved
              </RadioButton>
            </RadioGroup>
          </div>
          <Table
            bordered
            dataSource={data}
            onChange={handleChange}
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            rowKey="slug"
            scroll={{
              x: 500,
            }}
            tableLayout="auto"
          >
            <Column
              title="Sent"
              dataIndex="lastMessageTimestamp"
              sorter={sortBy('lastMessageTimestamp')}
              sortOrder={
                sortedInfo.field === 'lastMessageTimestamp' && sortedInfo.order
              }
              render={lastMessageTimestamp =>
                dateTimeFormatter(lastMessageTimestamp, true)
              }
            />
            <Column
              title="From"
              dataIndex="from"
              sorter={sortBy('from')}
              sortOrder={sortedInfo.field === 'from' && sortedInfo.order}
            />
            {all === INBOX_VIEW.ALL_CONVERSATIONS && (
              <Column
                title="To"
                dataIndex="to"
                sorter={sortBy('to')}
                sortOrder={sortedInfo.field === 'to' && sortedInfo.order}
                render={text =>
                  text
                    ? text
                    : accountProfile.firstName
                    ? `${accountProfile.firstName} ${accountProfile.lastName}`
                    : ''
                }
              />
            )}
            <Column
              title="Last Message"
              dataIndex={['lastMessage', 0]}
              render={(lastMessage, record) =>
                record.isParticipant ? (
                  <div className="flex justify-between items-center">
                    <ActionAnchor
                      onClick={() => {
                        getConversation(record.slug)
                      }}
                      className={cx({
                        b: lastMessage.unread,
                      })}
                    >
                      {lastMessage.messageType === CHAT_MESSAGE_TYPES.CALL
                        ? 'Phone Call'
                        : lastMessage.message.length > 30
                        ? `${lastMessage.message.substr(0, 30).trim()}...`
                        : lastMessage.message}
                    </ActionAnchor>
                    <div className="moon-gray">
                      <MessageType messageType={lastMessage.messageType} />
                    </div>
                  </div>
                ) : lastMessage.messageType === CHAT_MESSAGE_TYPES.CALL ? (
                  'Phone Call'
                ) : lastMessage.message.length > 30 ? (
                  `${lastMessage.message.substr(0, 30).trim()}...`
                ) : (
                  lastMessage.message
                )
              }
            />
            <Column
              title="Status"
              dataIndex="status"
              sorter={sortBy('status')}
              sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
              render={text => toTitleCase(text)}
            />
            <Column
              width={100}
              render={(text, record) => {
                const menu = (
                  <Menu>
                    <Menu.Item key="conversation-history">
                      <Anchor
                        to={`/communications/conversations/${record.slug}`}
                      >
                        Conversation History
                      </Anchor>
                    </Menu.Item>
                    {record.status === CONVERSATION_STATUS.ACTIVE && (
                      <Menu.Item key="close-conversation">
                        <ActionAnchor
                          onClick={() => handleCloseConversation(record.slug)}
                        >
                          Close Conversation
                        </ActionAnchor>
                      </Menu.Item>
                    )}
                    {accountProfile.hasSupportNumber && (
                      <Menu.Item key="bridge-phone-call">
                        <ActionAnchor
                          onClick={() =>
                            handleBridgePhoneCall(record.participants[0])
                          }
                        >
                          Place Phone Call
                        </ActionAnchor>
                      </Menu.Item>
                    )}
                    {record.participants[0].email && (
                      <Menu.Item key="send-email">
                        <a
                          className="dark-blue link"
                          href={`mailto:${record.participants[0].email}`}
                        >
                          Send Email
                        </a>
                      </Menu.Item>
                    )}
                  </Menu>
                )

                return (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link">
                      Actions <Icon type="down" />
                    </a>
                  </Dropdown>
                )
              }}
            />
          </Table>
          <CreateConversationModal
            isModalVisible={isConversationModalVisible}
            handleModalCancel={() => setIsConversationModalVisible(false)}
            getUsers={getUsers}
            users={users}
            createConversation={createConversation}
          />
          <BridgePhoneCallModal
            isModalVisible={isBridgePhoneCallModalVisible}
            handleModalCancel={() => setIsBridgePhoneCallModalVisible(false)}
            getUsers={getUsers}
            users={users}
            createBridgedPhoneCall={createBridgedPhoneCall}
          />
        </div>
      )}
    </div>
  )
}

Inbox.propTypes = {
  getAllConversations: PropTypes.func.isRequired,
  getConversation: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  conversations: PropTypes.arrayOf(PropTypes.object).isRequired,
  meta: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  createConversation: PropTypes.func.isRequired,
  createBridgedPhoneCall: PropTypes.func.isRequired,
  closeConversation: PropTypes.func.isRequired,
  isCommunicationLoading: PropTypes.bool.isRequired,
  updateQueryParams: PropTypes.func.isRequired,
  accountProfile: PropTypes.object.isRequired,
}

export default Inbox
