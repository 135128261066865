import produce from 'immer'
import {
  getAccessTokenAction,
  generateAccessTokenAction,
} from '../actions/developerActions'

const developerReducer = (
  state = { token: null, createdAt: null },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getAccessTokenAction.SUCCESS:
      case generateAccessTokenAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const getAccessToken = state => state

export { developerReducer as default, getAccessToken }
