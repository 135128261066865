import React from 'react'
import PropTypes from 'prop-types'

const InlineInput = ({
  value,
  setValue,
  label,
  type = 'text',
  // initialWidth = label.length ? label.length * 1.1 : 10,
  required = false,
}) => {
  // const [width, setWidth] = useState(value ? value.length : initialWidth)

  const onChange = event => {
    setValue(event.target.value)

    // if (type === 'text') {
    //   const { length } = event.target.value
    //   setWidth(length * (length < 10 ? 1.1 : 0.9))
    // } else {
    //   setWidth(
    //     event.target.value.length > initialWidth ? event.target.value.length : 4
    //   )
    // }
  }

  const onKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur()
    }
  }

  const onBlur = event => {
    // if (event.target.value.trim() === '') {
    //   setWidth(10)
    // }

    setValue(event.target.value)
  }

  return (
    <input
      className="pa1 mh2 tac ba bw2 b--dashed b--light-gray pointer hover-bg-light-gray"
      type={type}
      aria-label={label}
      value={value}
      placeholder={label}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      style={{
        // width: `${width}ch`,
        WebkitAppearance: 'none',
        MozAppearance: 'none',
      }}
      required={required}
    />
  )
}

InlineInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // initialWidth: PropTypes.number.isRequired,
  required: PropTypes.bool.isRequired,
}

export default InlineInput
