import {
  ERROR_MESSAGE_LIST,
  GENERIC_ERROR_MESSAGE,
  DEFAULT_PER_PAGE,
  REPORT_TYPES,
} from '../constants'

const unique = (x, idx, self) => self.indexOf(x) === idx

const existy = x => x != null

const groupBy = (array, iterator) =>
  array.reduce((mem, x) => {
    const value = iterator(x)

    mem[value] = mem[value] || []
    mem[value].push(x)

    return mem
  }, {})

const debounce = (func, wait, immediate) => {
  let timeout

  return function() {
    const context = this,
      args = arguments
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}

const normalizeAmbientLightReading = dataObj => {
  const { ambientLight, ...rest } = dataObj
  return ambientLight
    ? Object.assign({ ambientLight: ambientLight / 1000 }, rest)
    : dataObj
}

const getPathSnippets = pathname => pathname.split('/').filter(x => x)
const getPathSnippetAtIndex = (pathname, index) =>
  getPathSnippets(pathname)[index]

const pickErrorMessage = ({ apiError = {} }) => {
  const index = ERROR_MESSAGE_LIST.map(x => x.apiKey).indexOf(
    apiError.message || apiError.status
  )
  const errorMessage = ERROR_MESSAGE_LIST[index]

  if (index > -1) {
    if (errorMessage.duration) {
      return errorMessage
    } else {
      return errorMessage.message
    }
  } else {
    return GENERIC_ERROR_MESSAGE
  }
}

const configurePagination = ({
  perPage = DEFAULT_PER_PAGE,
  page,
  total,
  onShowSizeChange,
}) => ({
  onShowSizeChange,
  pageSizeOptions: [25, 50, 100],
  showSizeChanger: total > 25,
  position: ['topRight', 'bottomRight'],
  defaultPageSize: perPage,
  current: page,
  total,
  size: 'small',
})

const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const isReportTypeDisabled = (reportType, hasLocationGroups) => {
  if (hasLocationGroups) {
    switch (reportType) {
      case REPORT_TYPES.FLOOR_LOCATIONS_PER_GRAPH:
      case REPORT_TYPES.FLOOR_AVG_PER_GRAPH:
      case REPORT_TYPES.ALL_FLOORS_AVG_PER_GRAPH:
        return true
    }
  } else {
    return reportType === REPORT_TYPES.LOCATION_GROUPS_PER_GRAPH
  }
}

export {
  unique,
  existy,
  groupBy,
  debounce,
  getPathSnippets,
  getPathSnippetAtIndex,
  normalizeAmbientLightReading,
  pickErrorMessage,
  configurePagination,
  escapeRegExp,
  isReportTypeDisabled,
}
