import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { AntSelect } from './Ant'
import Meta from './Meta'
import Label from './Label'

const { Option } = AntSelect

const Select = ({
  input,
  label,
  children,
  meta,
  placeholder,
  mode = '',
  className = '',
  onSelect,
  onSearch,
  filterable = false,
  disabled = false,
  noMargin = false,
  requiredFields,
  isRequired,
  dropdownMatchSelectWidth = true,
  allowClear = false,
}) => {
  const selectClassNames = cx('Select', className, { mb3: !noMargin })
  const { onBlur, ...rest } = input

  let filterProps = {}
  if (filterable) {
    filterProps = {
      showSearch: true,
      optionFilterProp: 'children',
      filterOption: (input, option) => {
        let optionString = option.props.children

        if (optionString.length && optionString instanceof Array) {
          optionString = optionString.join('')
        }

        return optionString.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
    }
  }

  return (
    <div className={selectClassNames}>
      {label && (
        <Label>
          {label}
          {((requiredFields && requiredFields.indexOf(input.name) > -1) ||
            isRequired) && <span className="red"> *</span>}
        </Label>
      )}
      <AntSelect
        className="db w-100 input-reset"
        onChange={input.onChange}
        placeholder={placeholder}
        mode={mode}
        disabled={disabled}
        onSelect={onSelect}
        onSearch={onSearch}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        allowClear={allowClear}
        {...filterProps}
        {...rest}
      >
        {children}
      </AntSelect>
      <Meta {...meta} />
    </div>
  )
}

Select.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  mode: PropTypes.string,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  filterable: PropTypes.bool,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  isRequired: PropTypes.bool,
  dropdownMatchSelectWidth: PropTypes.bool,
  allowClear: PropTypes.bool,
}

export { Select as default, Option }
