import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import moment from 'moment'
import { unauthorizedAction } from '../actions/authActions'
import {
  createReportAction,
  getAllReportsAction,
  getPublicReportAction,
  getAllReportConfigsAction,
  createReportConfigAction,
  updateReportConfigAction,
  deleteReportConfigAction,
} from '../actions/reportsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES, DAYS_INTERVALS } from '../constants'
import { pickErrorMessage } from '../utils/helpers'
import { createQueryString } from '../utils/queryParams'

// handlers

function* handleCreateReportSaga({ payload }) {
  try {
    const result = yield call(api.createReport, payload)

    const pages = result.pages.map(page => {
      const items = page.items
      const mapped = items.map(y => {
        if (y.type === 'LAST_AVERAGE') {
          let timeAdjustment = {}

          if (result.daysInterval === DAYS_INTERVALS.ONE_WEEK) {
            timeAdjustment = { duration: 1, unit: 'week' }
          } else if (result.daysInterval === DAYS_INTERVALS.TWO_WEEKS) {
            timeAdjustment = { duration: 2, unit: 'week' }
          } else if (result.daysInterval === DAYS_INTERVALS.ONE_MONTH) {
            timeAdjustment = { duration: 1, unit: 'month' }
          }

          return {
            ...y,
            data: y.data.map(z => {
              return {
                ...z,
                originalTime: z.time,
                time: moment(z.time)
                  .add(timeAdjustment.duration, timeAdjustment.unit)
                  .toISOString(),
              }
            }),
          }
        } else {
          return y
        }
      })
      return { ...page, items: mapped }
    })

    yield put(createReportAction.success({ ...result, pages }))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_GENERATED_REPORT))

    // TODO
    // yield put(push('/reports'))
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllReportsSaga({ payload }) {
  try {
    const result = yield call(api.getAllReports, payload)

    yield put(getAllReportsAction.success(result))
  } catch (error) {
    yield put(getAllReportsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPublicReportSaga({ payload }) {
  try {
    const result = yield call(api.getPublicReportByReportSlug, payload)

    const pages = result.pages.map(page => {
      const items = page.items
      const mapped = items.map(y => {
        if (y.type === 'LAST_AVERAGE') {
          let timeAdjustment = {}

          if (result.daysInterval === DAYS_INTERVALS.ONE_WEEK) {
            timeAdjustment = { duration: 1, unit: 'week' }
          } else if (result.daysInterval === DAYS_INTERVALS.TWO_WEEKS) {
            timeAdjustment = { duration: 2, unit: 'week' }
          } else if (result.daysInterval === DAYS_INTERVALS.ONE_MONTH) {
            timeAdjustment = { duration: 1, unit: 'month' }
          }

          return {
            ...y,
            data: y.data.map(z => {
              return {
                ...z,
                originalTime: z.time,
                time: moment(z.time)
                  .add(timeAdjustment.duration, timeAdjustment.unit)
                  .toISOString(),
              }
            }),
          }
        } else {
          return y
        }
      })
      return { ...page, items: mapped }
    })

    yield put(getPublicReportAction.success({ ...result, pages }))
  } catch (error) {
    yield put(getPublicReportAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllReportConfigsSaga({ payload }) {
  try {
    const result = yield call(api.getAllReportConfigs, payload)

    yield put(getAllReportConfigsAction.success(result))
  } catch (error) {
    yield put(getAllReportConfigsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateReportConfigSaga({ payload }) {
  try {
    const result = yield call(api.createReportConfig, payload)
    yield put(createReportConfigAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))

    yield fork(handleGetAllReportConfigsSaga, {
      payload: createQueryString({ perPage: 'all' }),
    })

    yield put(push('/reports/configs'))
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateReportConfigSaga({ payload }) {
  try {
    const result = yield call(api.updateReportConfig, payload)
    yield put(updateReportConfigAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))

    yield fork(handleGetAllReportConfigsSaga, {
      payload: createQueryString({ perPage: 'all' }),
    })

    yield put(push('/reports/configs'))
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteReportConfigSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)

    if (confirmDeletion) {
      yield call(api.deleteReportConfig, payload)
      yield put(deleteReportConfigAction.success())
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))

      yield fork(handleGetAllReportConfigsSaga, {
        payload: createQueryString({ perPage: 'all' }),
      })
    }
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchCreateReportSaga() {
  yield takeLatest(createReportAction.REQUEST, handleCreateReportSaga)
}

function* watchGetAllReportsSaga() {
  yield takeLatest(getAllReportsAction.REQUEST, handleGetAllReportsSaga)
}

function* watchGetPublicReportSaga() {
  yield takeLatest(getPublicReportAction.REQUEST, handleGetPublicReportSaga)
}

function* watchGetAllReportConfigsSaga() {
  yield takeLatest(
    getAllReportConfigsAction.REQUEST,
    handleGetAllReportConfigsSaga
  )
}

function* watchCreateReportConfigSaga() {
  yield takeLatest(
    createReportConfigAction.REQUEST,
    handleCreateReportConfigSaga
  )
}

function* watchUpdateReportConfigSaga() {
  yield takeLatest(
    updateReportConfigAction.REQUEST,
    handleUpdateReportConfigSaga
  )
}

function* watchDeleteReportConfigSaga() {
  yield takeLatest(
    deleteReportConfigAction.REQUEST,
    handleDeleteReportConfigSaga
  )
}

function* reportsSaga() {
  yield all([
    fork(watchCreateReportSaga),
    fork(watchGetAllReportsSaga),
    fork(watchGetPublicReportSaga),
    fork(watchGetAllReportConfigsSaga),
    fork(watchCreateReportConfigSaga),
    fork(watchUpdateReportConfigSaga),
    fork(watchDeleteReportConfigSaga),
  ])
}

export { reportsSaga as default }
