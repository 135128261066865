import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import Checkbox from '../common/Checkbox'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { required } from '../../utils/validators'
import { COMPANY_TYPES } from '../../constants'
import { toTitleCase } from '../../utils/textFormatters'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'

const EditCompanyForm = props => {
  const { handleSubmit, submitting, error, submitAction } = props

  const submit = handleSubmit(submitAction)

  return (
    <section className="EditCompanyForm">
      <Form onSubmit={submit}>
        <div className="flex-ns justify-between">
          <Field
            name="name"
            type="text"
            component={InputContainer}
            label="Name"
            validate={[required]}
            className="mr3-ns w-40-ns"
          />
          <Field
            name="companyType"
            component={SelectContainer}
            label="Company Type"
            placeholder="Select part type"
            validate={required}
            className="mr3-ns w-30-ns"
            filterable
          >
            {Object.keys(COMPANY_TYPES).map(x => (
              <Option value={COMPANY_TYPES[x]} key={x}>
                {toTitleCase(COMPANY_TYPES[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="force2faAuth"
            component={Checkbox}
            type="checkbox"
            label="Enable Two-Factor Authentication"
            className="w-30-ns"
          />
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton defaultLocation="/companies" />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditCompanyForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditCompanyForm' })(EditCompanyForm)
