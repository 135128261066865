import { createFormAction } from 'redux-form-saga'

const getAllDeploymentsAction = createFormAction('GET_ALL_DEPLOYMENTS')
const getAllDeploymentsBySiteAction = createFormAction(
  'GET_ALL_DEPLOYMENTS_BY_SITE'
)
const getAllShipmentsAction = createFormAction('GET_ALL_SHIPMENTS')
const getDeploymentAction = createFormAction('GET_DEPLOYMENT')
const createDeploymentAction = createFormAction('CREATE_DEPLOYMENT')
const updateDeploymentAction = createFormAction('UPDATE_DEPLOYMENT')
const updateDeploymentEstimatesAction = createFormAction(
  'UPDATE_DEPLOYMENT_ESTIMATES'
)
const deleteDeploymentAction = createFormAction('DELETE_DEPLOYMENT')
const updateShipmentAction = createFormAction('UPDATE_SHIPMENT')
const getShipmentAction = createFormAction('GET_SHIPMENT')
const regeneratePackingListAction = createFormAction('REGENERATE_PACKING_LIST')
const getDeploymentEditHistoryAction = createFormAction(
  'GET_DEPLOYMENT_EDIT_HISTORY'
)

export {
  getAllDeploymentsAction,
  getAllDeploymentsBySiteAction,
  getAllShipmentsAction,
  getDeploymentAction,
  createDeploymentAction,
  updateDeploymentAction,
  updateDeploymentEstimatesAction,
  deleteDeploymentAction,
  updateShipmentAction,
  getShipmentAction,
  regeneratePackingListAction,
  getDeploymentEditHistoryAction,
}
