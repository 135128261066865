import React, { Component } from 'react'
import EditWaterValveForm from '../components/forms/EditWaterValveForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createWaterValveAction } from '../actions/flowMonitorsActions'

class CreateWaterValve extends Component {
  render() {
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/water-valves">Water Valves</Anchor>,
      'Create Water Valve',
    ]
    return (
      <section className="CreateWaterValve">
        <FormHeader crumbs={crumbs} />
        <EditWaterValveForm submitAction={createWaterValveAction} />
      </section>
    )
  }
}

export default CreateWaterValve
