import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { Modal } from './common/Ant'
import Button from './common/Button'
import Label from './common/Label'
import Input from './common/Input'
import Checkbox from './common/Checkbox'
import DateSelector from './common/DateSelector'
import { uploadCalibrationZipfileAction } from '../actions/podsActions'
import { SIZES } from '../constants'

const mapDispatchToProps = dispatch => ({
  uploadCalibrationZipfile: payload =>
    dispatch(uploadCalibrationZipfileAction.request(payload)),
})

const CalibrationZipfileUpload = ({ uploadCalibrationZipfile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formData, setFormData] = useState(null)
  const [testName, setTestName] = useState('')
  const [testDate, setTestDate] = useState(moment())
  const [sensorTypes, setSensorTypes] = useState([])

  const handleSetSensorTypes = type => {
    if (sensorTypes.indexOf(type) !== -1) {
      setSensorTypes(sensorTypes.filter(x => x !== type))
    } else {
      setSensorTypes([...sensorTypes, type])
    }
  }

  useEffect(() => {
    return () => isModalOpen && handleClose()
  }, [isModalOpen])

  const handleOpen = () => setIsModalOpen(true)

  const handleClose = () => {
    setIsModalOpen(false)
    setTestName('')
    setTestDate(moment())
    setFormData(null)
  }

  const handleFileSelection = event => {
    const files = Array.from(event.target.files)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('name', file.name)
      formData.append('type', file.type)
      formData.append('files[]', file)

      setFormData(formData)
    })
  }

  const handleSubmit = () => {
    if (formData) {
      formData.append('test_name', testName)
      formData.append('test_date', testDate.toISOString())
      formData.append('sensor_types', sensorTypes)

      uploadCalibrationZipfile(formData)
      handleClose()
    }
  }

  return (
    <div className="CalibrationZipfileUpload">
      <Button
        onClick={handleOpen}
        text="Import Calibration Zipfile"
        size={SIZES.SMALL}
      />
      <Modal
        visible={isModalOpen}
        className="Modal"
        title="Import Calibration Zipfile"
        onCancel={handleClose}
        style={{ top: 16 }}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <div>
          <Input
            label="Test Name"
            className="w-50-l mr3"
            placeholder="Test Name"
            input={{
              value: testName,
              onChange: ev => setTestName(ev.currentTarget.value),
            }}
          />
          <DateSelector
            className="w-50-l"
            label="Test Date"
            input={{
              value: testDate,
              onChange: setTestDate,
            }}
            disableTime
          />

          <div>
            <Label>Sensors to Calibrate</Label>
            <Checkbox
              type="checkbox"
              label="Temperature"
              input={{
                checked: sensorTypes.indexOf('temp') !== -1,
                onChange: () => handleSetSensorTypes('temp'),
              }}
            />
            <Checkbox
              type="checkbox"
              label="Humidity"
              input={{
                checked: sensorTypes.indexOf('hum') !== -1,
                onChange: () => handleSetSensorTypes('hum'),
              }}
            />
            <Checkbox
              type="checkbox"
              label="Pressure"
              input={{
                checked: sensorTypes.indexOf('pres') !== -1,
                onChange: () => handleSetSensorTypes('pres'),
              }}
            />
            <Checkbox
              type="checkbox"
              label="Carbon Monoxide"
              input={{
                checked: sensorTypes.indexOf('carbon_monoxide') !== -1,
                onChange: () => handleSetSensorTypes('carbon_monoxide'),
              }}
            />
          </div>

          <Label>Select a Zipfile to upload</Label>
          <input
            type="file"
            accept=".zip,.rar,.7zip"
            onChange={handleFileSelection}
            className="mb3 w-100"
          />
          <Button onClick={handleSubmit} />
        </div>
      </Modal>
    </div>
  )
}

CalibrationZipfileUpload.propTypes = {
  uploadCalibrationZipfile: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(CalibrationZipfileUpload)
