import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { reduxForm, Field } from 'redux-form'
import MainContent from '../common/MainContent'
import InputContainer from '../../containers/common/InputContainer'
import { H2 } from '../common/Headers'
import Checkbox from '../common/Checkbox'
import Button from '../common/Button'
import FormError from '../common/FormError'
import { Anchor } from '../common/Anchor'
import { confirmAccountAction } from '../../actions/authActions'
import {
  required,
  maxLength,
  minLength,
  match,
  isPhoneNumber,
} from '../../utils/validators'
import { normalizePhoneNumber, formatPhoneNumber } from '../../utils/forms'

const maxLength30 = maxLength(30)
const minLength6 = minLength(6)

class ConfirmAccountForm extends Component {
  static propTypes = {
    setAccountConfirmationToken: PropTypes.func.isRequired,
    getUnconfirmedUser: PropTypes.func.isRequired,
    isValidToken: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    errorMessage: PropTypes.string,
    submitSucceeded: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      matchPassword: match(''),
    }
  }

  componentDidMount() {
    const { token } = this.props
    if (token) {
      this.props.setAccountConfirmationToken(token)
      this.props.getUnconfirmedUser(token)
    }
  }

  handleChange = password => {
    const value = password.target.value
    const matchPassword = match(value)
    this.setState({ matchPassword })
  }

  render() {
    const {
      isValidToken,
      handleSubmit,
      error,
      submitSucceeded,
      errorMessage,
    } = this.props

    const submit = handleSubmit(confirmAccountAction)
    const { matchPassword } = this.state

    return (
      <MainContent>
        <section className="ConfirmAccountForm">
          <Helmet>
            <title>Confirm Account</title>
          </Helmet>
          <form
            className="measure-narrow center mb2"
            onSubmit={submit}
            noValidate
          >
            {isValidToken ? (
              <div className="mb3">
                <H2>Confirm Account</H2>
                <Field
                  name="password"
                  type="password"
                  component={InputContainer}
                  label="New Password"
                  onChange={this.handleChange}
                  validate={[required, minLength6, maxLength30]}
                />
                <Field
                  name="confirmPassword"
                  type="password"
                  component={InputContainer}
                  label="Confirm Password"
                  validate={[required, matchPassword]}
                />
                <Field
                  name="firstName"
                  type="text"
                  component={InputContainer}
                  label="First Name"
                  validate={[required]}
                />
                <Field
                  name="lastName"
                  type="text"
                  component={InputContainer}
                  label="Last Name"
                  validate={[required]}
                />
                <Field
                  name="phoneNumber"
                  type="tel"
                  component={InputContainer}
                  label="Phone Number"
                  validate={[required, isPhoneNumber]}
                  normalize={normalizePhoneNumber}
                  format={formatPhoneNumber}
                />
                <Field
                  name="smsNotifications"
                  type="checkbox"
                  component={Checkbox}
                  label="SMS Notifications"
                />
                <Field
                  name="emailNotifications"
                  type="checkbox"
                  component={Checkbox}
                  label="Email Notifications"
                />
                <FormError error={error} />
                {submitSucceeded ? (
                  <p className="mb3">
                    Success! Please <Anchor to="/">log in</Anchor> with your new
                    password.
                  </p>
                ) : (
                  <Button
                    className="w-100"
                    type="submit"
                    text="Confirm Account"
                    submitting={this.props.submitting}
                  />
                )}
              </div>
            ) : (
              <p className="tc b lh-copy">{errorMessage}</p>
            )}
            <p className="tc lh-copy">
              If you are have trouble confirming your account, please contact
              our Field Solutions Manager, Martin Steidl, at{' '}
              <a className="link dark-blue" href="tel:1-347-687-3860">
                347-687-3860
              </a>{' '}
              or{' '}
              <a className="link dark-blue" href="mailto:martin@pillar.tech">
                martin@pillar.tech
              </a>
              .
            </p>
          </form>
        </section>
      </MainContent>
    )
  }
}

export default reduxForm({ form: 'ConfirmAccountForm' })(ConfirmAccountForm)
