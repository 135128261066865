import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { required, isAlphaNumeric } from '../../utils/validators'

const EditBuildingForm = props => {
  const {
    handleSubmit,
    submitting,
    error,
    submitAction,
    siteSlug,
    buildingId,
  } = props
  const submit = handleSubmit(submitAction)

  return (
    <section className="EditBuildingForm">
      <Form onSubmit={submit}>
        <div className="flex-ns">
          <Field
            name="building"
            type="text"
            component={InputContainer}
            label="Building"
            validate={[required, isAlphaNumeric]}
            className="w-10-ns mr3-ns"
          />
          <Field
            name="name"
            type="text"
            component={InputContainer}
            label="Name"
            validate={[required]}
            className="w-90-ns"
          />
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton
            defaultLocation={
              buildingId
                ? `/sites/${siteSlug}/buildings/${buildingId}`
                : `/sites/${siteSlug}`
            }
          />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditBuildingForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  siteSlug: PropTypes.string.isRequired,
  buildingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditBuildingForm' })(EditBuildingForm)
