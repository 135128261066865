import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import AlertsPage from '../components/pages/AlertsPage'
import {
  getAllAlertsAction,
  getAlertAction,
  hideAlertAction,
  unhideAlertAction,
  closeAlertAction,
  closeGatewayAlertAction,
  clearAlertAction,
  acknowledgeAlertAction,
  resolveAlertAction,
  getAlertAuditLogAction,
  getLocationAlertHistoryAction,
  getAllGatewayAlertsAction,
  getGatewayAlertAction,
  getAllActionReportsAction,
  generateAlertExportAction,
  batchUpdateAlertsAction,
} from '../actions/alertsActions'
import { restartGatewayAction } from '../actions/gatewaysActions'
import {
  getAllCommentsAction,
  getAllTagsAction,
  deleteCommentAction,
  createCommentAction,
  updateCommentAction,
} from '../actions/commentsActions'
import { getAllThresholdsAction } from '../actions/thresholdsActions'
import { clearLocationChartDataAction } from '../actions/locationsActions'
import { getAllSitesAction } from '../actions/sitesActions'
import { getAllCompaniesAction } from '../actions/companiesActions'
import { getVisibleSites } from '../reducers/sitesReducer'
import { getAccountProfile } from '../reducers/accountReducer'
import { getVisibleThresholds } from '../reducers/thresholdsReducer'
import { getCurrentLocationChartData } from '../reducers/locationsReducer'
import {
  getVisibleAlerts,
  getCurrentAlert,
  getAlertsMeta,
  getCurrentAlertAuditLog,
  getVisibleAlertsByLocation,
  getVisibleGatewayAlertsById,
  getGatewayAlertsMeta,
  getCurrentGatewayAlert,
  getVisibleActionReports,
  getActionReportsMeta,
} from '../reducers/alertsReducer'
import { getVisibleComments, getVisibleTags } from '../reducers/commentsReducer'
import { getVisibleCompanies } from '../reducers/companiesReducer'
import {
  getWindowWidth,
  isChartDataLoading,
  isAlertLoading,
  isHistoryLoading,
} from '../reducers/uiReducer'

const mapStateToProps = ({
  alerts,
  companies,
  account,
  locations,
  comments,
  thresholds,
  ui,
  sites,
}) => ({
  alerts: getVisibleAlerts(alerts),
  locationAlertHistory: getVisibleAlertsByLocation(alerts),
  comments: getVisibleComments(comments),
  tags: getVisibleTags(comments),
  currentAlertAuditLog: getCurrentAlertAuditLog(alerts),
  currentAlert: getCurrentAlert(alerts),
  currentAlertChartData: getCurrentLocationChartData(locations),
  meta: getAlertsMeta(alerts),
  accountProfile: getAccountProfile(account),
  isChartDataLoading: isChartDataLoading(ui),
  isAlertLoading: isAlertLoading(ui),
  isHistoryLoading: isHistoryLoading(ui),
  windowWidth: getWindowWidth(ui),
  sites: getVisibleSites(sites),
  thresholds: getVisibleThresholds(thresholds),
  gatewayAlerts: getVisibleGatewayAlertsById(alerts),
  gatewayAlertsMeta: getGatewayAlertsMeta(alerts),
  currentGatewayAlert: getCurrentGatewayAlert(alerts),
  companies: getVisibleCompanies(companies),
  actionReports: getVisibleActionReports(alerts),
  actionReportsMeta: getActionReportsMeta(alerts),
})

const mapDispatchToProps = dispatch => ({
  getAlertAuditLog: id => dispatch(getAlertAuditLogAction.request(id)),
  getAllAlerts: qs => dispatch(getAllAlertsAction.request(qs)),
  getLocationAlertHistory: (siteSlug, floorId, locationId, qs) =>
    dispatch(
      getLocationAlertHistoryAction.request({
        siteSlug,
        floorId,
        locationId,
        qs,
      })
    ),
  getAllTags: qs => dispatch(getAllTagsAction.request(qs)),
  getAllComments: qs => dispatch(getAllCommentsAction.request(qs)),
  deleteComment: comment => dispatch(deleteCommentAction.request(comment)),
  createComment: comment => dispatch(createCommentAction.request(comment)),
  editComment: comment => dispatch(updateCommentAction.request(comment)),
  getAlert: slug => dispatch(getAlertAction.request(slug)),
  clearAlert: () => dispatch(clearAlertAction()),
  clearLocationChartData: () => dispatch(clearLocationChartDataAction()),
  hideAlert: id => dispatch(hideAlertAction.request(id)),
  unhideAlert: id => dispatch(unhideAlertAction.request(id)),
  closeGatewayAlert: slug => dispatch(closeGatewayAlertAction.request(slug)),
  closeAlert: id => dispatch(closeAlertAction.request(id)),
  acknowledgeAlert: slug => dispatch(acknowledgeAlertAction.request(slug)),
  resolveAlert: slug => dispatch(resolveAlertAction.request(slug)),
  getAllSites: qs => dispatch(getAllSitesAction.request(qs)),
  getAllThresholds: qs => dispatch(getAllThresholdsAction.request(qs)),
  getAllGatewayAlerts: qs => dispatch(getAllGatewayAlertsAction.request(qs)),
  getGatewayAlert: slug => dispatch(getGatewayAlertAction.request(slug)),
  restartGateway: id => dispatch(restartGatewayAction.request(id)),
  updateQueryParams: location => dispatch(replace(location)),
  getAllCompanies: qs => dispatch(getAllCompaniesAction.request(qs)),
  getAllActionReports: qs => dispatch(getAllActionReportsAction.request(qs)),
  generateAlertExport: qs => dispatch(generateAlertExportAction.request(qs)),
  batchUpdateAlerts: payload =>
    dispatch(batchUpdateAlertsAction.request(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertsPage)
