import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import SelectContainer from '../../containers/common/SelectContainer'
import FormError from '../common/FormError'
import { Option } from '../common/Select'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import { required } from '../../utils/validators'

class EditActionReportCauseForm extends Component {
  static propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object),
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    submitAction: PropTypes.func.isRequired,
  }

  render() {
    const {
      companies,
      submitAction,
      handleSubmit,
      submitting,
      error,
    } = this.props

    const submit = handleSubmit(submitAction)

    return (
      <section className="EditActionReportCauseForm">
        <Form onSubmit={submit}>
          <div className="flex justify-between">
            <Field
              name="cause"
              type="text"
              component={InputContainer}
              label="Cause"
              className="w-50-ns w-100 mr3-ns"
              validate={[required]}
            />
            <Field
              name="companySlug"
              component={SelectContainer}
              label="Company"
              className="w-50-ns w-100"
              filterable
            >
              {companies.map(x => (
                <Option value={x.slug} key={x.slug}>
                  {x.name}
                </Option>
              ))}
            </Field>
          </div>
          <div className="db flex justify-between mv3">
            <CancelButton defaultLocation="/metadata/all/action-report-causes" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
          <FormError error={error} />
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditActionReportCauseForm' })(
  EditActionReportCauseForm
)
