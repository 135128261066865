import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useLastLocation } from 'react-router-last-location'
import EditThresholdGroup from './EditThresholdGroup'
import { Bread, Crumb } from './common/Bread'
import { Anchor, ActionAnchor } from './common/Anchor'
import {
  Tooltip,
  Spin,
  Table,
  Column,
  Modal,
  Dropdown,
  Menu,
  Icon,
  Badge,
} from './common/Ant'
import { createQueryString } from '../utils/queryParams'
import { toTitleCase, renderThresholdGroupType } from '../utils/textFormatters'
import { unique } from '../utils/helpers'
import { ALL } from '../constants'

const AllThresholdGroups = ({
  getAllThresholdGroups,
  createThresholdGroup,
  updateThresholdGroup,
  deleteThresholdGroup,
  isLoading,
  match: { url },
  thresholdGroups,
  getAllSites,
  getAllCompanies,
  getAllThresholds,
  sites,
  companies,
  thresholds,
}) => {
  const [thresholdGroup, setThresholdGroup] = useState(null)

  const history = useHistory()
  const lastLocation = useLastLocation()

  useEffect(() => {
    getAllThresholdGroups(createQueryString({ perPage: ALL }))
  }, [getAllThresholdGroups])

  const handleSetThresholdGroup = x => {
    setThresholdGroup(x)

    history.push(`${url}/${x.slug}/edit`)
  }

  const handleModalCancel = () => {
    setThresholdGroup(null)
    if (
      lastLocation.pathname.indexOf('/create') ||
      lastLocation.pathname.indexOf('/edit')
    ) {
      history.push(url)
    } else {
      history.goBack()
    }
  }

  const expandedRowRender = thresholdGroup => (
    <Table
      dataSource={thresholdGroup.thresholds}
      rowKey="slug"
      pagination={false}
      tableLayout="auto"
    >
      <Column
        title="Name"
        dataIndex="name"
        render={(text, record) => {
          const badge = record.active ? (
            <Badge status="success" />
          ) : (
            <Badge status="default" />
          )
          return (
            <>
              {badge}{' '}
              <Anchor to={`/rules/thresholds/${record.id}`}>{text}</Anchor>
            </>
          )
        }}
      />
      <Column
        title="Alert Type"
        dataIndex="thresholdAlertType"
        render={toTitleCase}
      />
    </Table>
  )

  return isLoading ? (
    <Spin size="large" className="w-100 center mv5" />
  ) : (
    <section className="mb5 AllThresholdGroups">
      <div className="mb3">
        <Bread>
          <Crumb>
            <Crumb>Rules</Crumb>
            <Anchor to="/rules">Thresholds</Anchor>
          </Crumb>
          <Crumb>Threshold Groups</Crumb>
        </Bread>
      </div>
      <div className="flex flex-wrap justify-center mb3">
        <span>
          <Anchor to={`${url}/create`}>Create Group</Anchor>
        </span>
      </div>
      <div className="mb3">
        <Table
          dataSource={thresholdGroups}
          rowKey="slug"
          pagination={false}
          size="small"
          scroll={{ x: 815 }}
          expandedRowRender={expandedRowRender}
          tableLayout="auto"
        >
          <Column
            title="Name"
            dataIndex="name"
            render={(text, record) => (
              <ActionAnchor onClick={() => handleSetThresholdGroup(record)}>
                {text}
              </ActionAnchor>
            )}
          />
          <Column
            title="Site"
            dataIndex="siteName"
            render={(text, record) =>
              text ? (
                <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Company"
            dataIndex="companyName"
            render={(text, record) =>
              text ? (
                <Anchor to={`/companies/${record.companySlug}`}>{text}</Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Thresholds"
            dataIndex="thresholds"
            render={item => item.length.toString()}
          />
          <Column
            title="Group Type"
            dataIndex="groupType"
            render={(text, record) => {
              const t = renderThresholdGroupType(text)

              if (record.default) {
                return (
                  <Tooltip title={`Default group for ${t}!`}>
                    <span className="b">{t}</span>{' '}
                    <span className="pl1">
                      <Icon type="exclamation-circle-o" className="moon-gray" />
                    </span>
                  </Tooltip>
                )
              }

              return t ? t : '--'
            }}
          />
          <Column
            title="User Roles"
            dataIndex="thresholds"
            render={(text, record) => {
              const uniqueUserRolesString = record.thresholds
                .map(x => x.visibleToUserRoles)
                .flat()
                .map(toTitleCase)
                .filter(unique)
                .join(', ')

              const result =
                uniqueUserRolesString.length > 20
                  ? `${uniqueUserRolesString.substr(0, 20)} ...`
                  : uniqueUserRolesString

              return <Tooltip title={uniqueUserRolesString}>{result}</Tooltip>
            }}
          />
          <Column
            render={(text, record) => {
              const menu = (
                <Menu>
                  <Menu.Item key="edit-threshold">
                    <ActionAnchor
                      onClick={() => handleSetThresholdGroup(record)}
                    >
                      Edit
                    </ActionAnchor>
                  </Menu.Item>
                  <Menu.Item key="remove-threshold-from-site">
                    <ActionAnchor onClick={() => deleteThresholdGroup(record)}>
                      Delete
                    </ActionAnchor>
                  </Menu.Item>
                </Menu>
              )

              return (
                <Dropdown overlay={menu} trigger={['click']}>
                  <a className="ant-dropdown-link">
                    Actions <Icon type="down" />
                  </a>
                </Dropdown>
              )
            }}
          />
        </Table>
      </div>

      <Route
        path={[`${url}/create`, `${url}/:slug/edit`]}
        exact
        render={props => {
          return (
            <>
              <Helmet>
                <title>Edit Threshold Group</title>
              </Helmet>
              <Modal
                className="Modal"
                visible
                footer={null}
                onCancel={handleModalCancel}
                destroyOnClose
                maskClosable={false}
              >
                <EditThresholdGroup
                  createThresholdGroup={createThresholdGroup}
                  updateThresholdGroup={updateThresholdGroup}
                  thresholdGroup={thresholdGroup}
                  setThresholdGroup={slug => {
                    const index = thresholdGroups.map(x => x.slug).indexOf(slug)

                    setThresholdGroup(
                      index !== -1 ? thresholdGroups[index] : null
                    )
                  }}
                  getAllSites={getAllSites}
                  getAllCompanies={getAllCompanies}
                  getAllThresholds={getAllThresholds}
                  sites={sites}
                  companies={companies}
                  thresholds={thresholds}
                  {...props}
                />
              </Modal>
            </>
          )
        }}
      />
    </section>
  )
}

AllThresholdGroups.propTypes = {
  getAllThresholdGroups: PropTypes.func.isRequired,
  createThresholdGroup: PropTypes.func.isRequired,
  updateThresholdGroup: PropTypes.func.isRequired,
  deleteThresholdGroup: PropTypes.func.isRequired,
  thresholdGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  getAllSites: PropTypes.func.isRequired,
  getAllCompanies: PropTypes.func.isRequired,
  getAllThresholds: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  thresholds: PropTypes.arrayOf(PropTypes.object),
}

export default AllThresholdGroups
