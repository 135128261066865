import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditPartForm from './forms/EditPartForm'
import { H2 } from './common/Headers'
import { updatePartAction } from '../actions/partsActions'

class EditPart extends Component {
  static propTypes = {
    part: PropTypes.object.isRequired,
    getPart: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getPart, match } = this.props
    getPart(match.params.id)
  }

  render() {
    const { part } = this.props
    return (
      <section className="EditPart">
        <H2>Edit Part</H2>
        <EditPartForm
          submitAction={updatePartAction}
          initialValues={part}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditPart
