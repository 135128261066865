import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import CompaniesPage from '../components/pages/CompaniesPage'
import {
  getAllCompaniesAction,
  getCompanyAction,
  deleteCompanyAction,
  uploadLogoAction,
} from '../actions/companiesActions'
import {
  getVisibleCompanies,
  getCurrentCompany,
  getCompaniesMeta,
} from '../reducers/companiesReducer'
import { isCompanyLoading, getWindowWidth } from '../reducers/uiReducer'
import { getAccountProfile } from '../reducers/accountReducer'

const mapStateToProps = ({ companies, ui, account }) => ({
  companies: getVisibleCompanies(companies),
  currentCompany: getCurrentCompany(companies),
  meta: getCompaniesMeta(companies),
  isCompanyLoading: isCompanyLoading(ui),
  accountProfile: getAccountProfile(account),
  windowWidth: getWindowWidth(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllCompanies: qs => dispatch(getAllCompaniesAction.request(qs)),
  deleteCompany: id => dispatch(deleteCompanyAction.request(id)),
  getCompany: id => dispatch(getCompanyAction.request(id)),
  uploadLogo: (companyId, formData) =>
    dispatch(uploadLogoAction.request({ companyId, formData })),
  updateQueryParams: location => dispatch(replace(location)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPage)
