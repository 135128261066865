import { createFormAction } from 'redux-form-saga'

const getAllPodsAction = createFormAction('GET_ALL_PODS')
const markPodAsLostAction = createFormAction('MARK_POD_LOST')
const getAllAvailablePodsAction = createFormAction('GET_ALL_AVAILABLE_PODS')
const getPodAction = createFormAction('GET_POD')
const getPodChartDataAction = createFormAction('GET_POD_DATA')
const createPodAction = createFormAction('CREATE_POD')
const updatePodAction = createFormAction('UPDATE_POD')
const batchUpdatePodsAction = createFormAction('BATCH_UPDATE_PODS')
const deletePodAction = createFormAction('DELETE_POD')
const getPodMetadataAction = createFormAction('GET_POD_DETAILS')
const generatePillarIDsAction = createFormAction('GENERATE_PILLAR_IDS')
const getPodAssignmentHistoryAction = createFormAction(
  'GET_POD_ASSIGNMENT_HISTORY'
)
const getPodEditHistoryAction = createFormAction('GET_POD_EDIT_HISTORY')

const getAllPodFirmwareVersionsAction = createFormAction(
  'GET_POD_FIRMWARE_VERSIONS'
)
const updatePodFirmwareAction = createFormAction('UPDATE_POD_FIRMWARE')
const getAllAvailablePodsBySiteAction = createFormAction(
  'GET_ALL_AVAILABLE_PODS_BY_SITE'
)

const uploadPodsCSVAction = createFormAction('UPLOAD_PODS_CSV')
const uploadCalibrationCSVAction = createFormAction('UPLOAD_CALIBRATION_CSV')
const uploadCalibrationZipfileAction = createFormAction(
  'UPLOAD_CALIBRATION_ZIPFILE'
)
const getPodAssetsAction = createFormAction('GET_POD_ASSETS')
const getPodWorkflowResponsesAction = createFormAction(
  'GET_POD_WORKFLOW_RESPONSES'
)
const getPodWorkflowResponseAction = createFormAction(
  'GET_POD_WORKFLOW_RESPONSE'
)
const getPodCalibrationHistoryAction = createFormAction(
  'GET_POD_CALIBRATION_HISTORY'
)
const getMultiplePodsDataAction = createFormAction('GET_MULTIPLE_PODS_DATA')

export {
  getAllPodsAction,
  getAllAvailablePodsAction,
  getPodAction,
  getPodChartDataAction,
  createPodAction,
  updatePodAction,
  deletePodAction,
  getPodMetadataAction,
  getAllPodFirmwareVersionsAction,
  updatePodFirmwareAction,
  generatePillarIDsAction,
  getPodAssignmentHistoryAction,
  getPodEditHistoryAction,
  getAllAvailablePodsBySiteAction,
  batchUpdatePodsAction,
  markPodAsLostAction,
  uploadPodsCSVAction,
  uploadCalibrationCSVAction,
  uploadCalibrationZipfileAction,
  getPodAssetsAction,
  getPodWorkflowResponsesAction,
  getPodWorkflowResponseAction,
  getPodCalibrationHistoryAction,
  getMultiplePodsDataAction,
}
