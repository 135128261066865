import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Icon } from './common/Ant'

const MEDIA_TYPES = {
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  TEXT: 'text',
  APPLICATION: 'application',
}

const MessageAttachmentMedia = ({
  mediaUrl,
  mimeType,
  message,
  isPhoneCall,
}) => {
  const mediaType = mimeType.split('/')[0]

  let el

  switch (mediaType) {
    case MEDIA_TYPES.IMAGE:
      el = <img src={mediaUrl} alt={message} />
      break
    case MEDIA_TYPES.AUDIO:
      el = (
        <span>
          {!isPhoneCall ? 'Audio File' : 'Review Call'} <Icon type="link" />
        </span>
      )
      break
    case MEDIA_TYPES.VIDEO:
      el = (
        <span>
          Video File <Icon type="link" />
        </span>
      )
      break
    case MEDIA_TYPES.TEXT:
      el = (
        <span>
          Text File <Icon type="link" />
        </span>
      )
      break
    case MEDIA_TYPES.APPLICATION:
      el = (
        <span>
          PDF File <Icon type="link" />
        </span>
      )
      break
  }

  return (
    <div className="MessageAttachmentMedia mv1">
      <a
        className={cx(
          'link',
          !isPhoneCall ? 'dark-blue' : 'hover-white moon-gray'
        )}
        href={mediaUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {el}
      </a>
    </div>
  )
}

MessageAttachmentMedia.propTypes = {
  mediaUrl: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isPhoneCall: PropTypes.bool.isRequired,
}

const MessageAttachments = ({ attachments, message, isPhoneCall }) => {
  if (attachments.length > 0) {
    return (
      <div className="MessageAttachments mv1">
        {attachments.map(x => (
          <MessageAttachmentMedia
            {...x}
            message={message}
            isPhoneCall={isPhoneCall}
            key={x.slug}
          />
        ))}
      </div>
    )
  }

  return null
}

MessageAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  message: PropTypes.string.isRequired,
  isPhoneCall: PropTypes.bool.isRequired,
}

export default MessageAttachments
