import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import { normalizeDate, formatDateUTC } from '../common/DateSelector'
import { normalizePhoneNumber, formatPhoneNumber } from '../../utils/forms'
import { Spin } from '../common/Ant'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { required, isNumeric } from '../../utils/validators'

import { toTitleCase, formatSIMCarrier } from '../../utils/textFormatters'
import {
  SIM_CARD_STATUS,
  SIM_CARRIERS,
  SIM_USAGE,
  USER_PERMISSIONS,
} from '../../constants'
import { hasPermission } from '../../utils/hasPermission'

const EditSIMCardForm = props => {
  const {
    handleSubmit,
    submitting,
    error,
    submitAction,
    gateways,
    flowMonitors,
    assetLocations,
    isSIMCardLoading,
  } = props

  const submit = handleSubmit(submitAction)
  return isSIMCardLoading ? (
    <Spin size="large" className="w-100 center mv5" />
  ) : (
    <section className="EditSIMCardForm">
      <Form onSubmit={submit}>
        <div className="flex-ns">
          <Field
            name="name"
            type="text"
            component={InputContainer}
            label="Name"
            className="mr3-ns w-third-ns"
          />
          <Field
            name="simNumber"
            type="text"
            component={InputContainer}
            label="SIM/Device ID"
            validate={[required]}
            className="mr3-ns w-third-ns"
          />
          <Field
            name="carrier"
            component={SelectContainer}
            label="Carrier"
            validate={[required]}
            placeholder="Select SIM Carrier Service"
            className="w-third-ns"
            filterable
          >
            {Object.keys(SIM_CARRIERS).map(x => (
              <Option value={SIM_CARRIERS[x]} key={SIM_CARRIERS[x]}>
                {formatSIMCarrier(SIM_CARRIERS[x])}
              </Option>
            ))}
          </Field>
        </div>
        <div className="flex-ns">
          <Field
            name="gatewayId"
            component={SelectContainer}
            label="Network Gateway"
            className="mr3-ns w-third-ns"
            props={{ placeholder: 'Select a Network Gateway' }}
            filterable
          >
            {[{ id: '', gatewayPillarId: '--' }, ...gateways].map(x => (
              <Option value={x.id.toString()} key={x.id}>
                {x.gatewayPillarId}
              </Option>
            ))}
          </Field>
          {hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
            <Field
              name="flowMonitorId"
              component={SelectContainer}
              label="Water Monitor"
              className="mr3-ns w-third-ns"
              props={{ placeholder: 'Select a Water Monitor' }}
              filterable
            >
              {[{ id: '', pillarId: '--' }, ...flowMonitors].map(x => (
                <Option value={x.id.toString()} key={x.id}>
                  {x.pillarId}
                </Option>
              ))}
            </Field>
          )}
          <Field
            name="assetLocationId"
            component={SelectContainer}
            label="Asset Location"
            className="mr3-ns w-third-ns"
            props={{ placeholder: 'Select an Asset Location' }}
            filterable
          >
            {[{ id: '', pillarId: '--' }, ...assetLocations].map(x => (
              <Option value={x.id.toString()} key={x.id}>
                {x.pillarId}
              </Option>
            ))}
          </Field>
        </div>
        <div className="flex-ns">
          <Field
            name="assignedTo"
            type="text"
            component={InputContainer}
            label="Assigned To"
            className="w-third-ns"
            render={(text, { assignedTo }) => (assignedTo ? assignedTo : '--')}
          />
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="simUsage"
            component={SelectContainer}
            label="Usage"
            placeholder="Select a usage for this card"
            className="w-third-ns mr3-ns"
            filterable
          >
            {Object.keys(SIM_USAGE).map(x => (
              <Option value={SIM_USAGE[x]} key={x}>
                {toTitleCase(SIM_USAGE[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="status"
            component={SelectContainer}
            label="Status"
            placeholder="Select a status"
            className="mr3-ns w-third-ns"
            filterable
          >
            {Object.keys(SIM_CARD_STATUS).map(x => (
              <Option value={SIM_CARD_STATUS[x]} key={x}>
                {toTitleCase(SIM_CARD_STATUS[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="phoneNumber"
            type="tel"
            component={InputContainer}
            label="Phone Number"
            normalize={normalizePhoneNumber}
            format={formatPhoneNumber}
            className="w-third-ns"
          />
        </div>
        <div className="flex-ns">
          <Field
            name="accountNumber"
            type="text"
            component={InputContainer}
            label="Account Number"
            className="mr3-ns w-third-ns"
            render={(text, { accountNumber }) =>
              accountNumber ? accountNumber : '--'
            }
          />
          <Field
            name="accountGroup"
            type="text"
            component={InputContainer}
            label="Account Group"
            className="mr3-ns w-third-ns"
            render={(text, { accountGroup }) =>
              accountGroup ? accountGroup : '--'
            }
          />
          <Field
            name="planName"
            type="text"
            component={InputContainer}
            label="Plan Name"
            className="w-third-ns"
            render={(text, { planName }) => (planName ? planName : '--')}
          />
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="planType"
            type="text"
            component={InputContainer}
            label="Plan Type"
            className="mr3-ns w-third-ns"
            render={(text, { planType }) => (planType ? planType : '--')}
          />
          <Field
            name="deviceModel"
            type="text"
            component={InputContainer}
            label="Device Model"
            className="mr3-ns w-third-ns"
            render={(text, { deviceModel }) =>
              deviceModel ? deviceModel : '--'
            }
          />

          <Field
            name="planPrice"
            type="number"
            component={InputContainer}
            label="Plan Price"
            className="w-third-ns"
            validate={[isNumeric]}
            render={(text, { planPrice }) => (planPrice ? planPrice : '--')}
          />
        </div>
        <div className="flex-ns">
          <Field
            name="associatedImei"
            type="text"
            component={InputContainer}
            label="IMEI"
            validate={[isNumeric]}
            className="mr3-ns w-third-ns"
            render={(text, { associatedImei }) =>
              associatedImei ? associatedImei : '--'
            }
          />

          <Field
            name="dateActivated"
            type="date"
            component={DateSelectorContainer}
            label="Date Activated"
            format={formatDateUTC}
            normalize={normalizeDate}
            disabledDateRangeType="after"
            className="mr3-ns w-third-ns"
          />
          <Field
            name="dateEnd"
            type="date"
            component={DateSelectorContainer}
            label="Date End"
            format={formatDateUTC}
            normalize={normalizeDate}
            className="w-third-ns"
          />
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton defaultLocation="/inventory/all/sim-cards" />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditSIMCardForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  gateways: PropTypes.arrayOf(PropTypes.object),
  flowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
  assetLocations: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isSIMCardLoading: PropTypes.bool,
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditSIMCardForm' })(EditSIMCardForm)
