import produce from 'immer'
import { combineReducers } from 'redux'
import { getAllBLEDataAction } from '../actions/bleActions'

const byLocalId = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllBLEDataAction.SUCCESS:
        payload.forEach(x => {
          draft[x.localId] = x
        })
        break
    }
  })

const visibleLocalIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllBLEDataAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.map(x => x.localId))
        break
    }
  })

const bleReducer = combineReducers({
  byLocalId,
  visibleLocalIds,
})

const getBLE = (state, localId) => state.byLocalId[localId]
const getVisibleBLEData = state =>
  state.visibleLocalIds.map(localId => getBLE(state, localId))

export { bleReducer as default, getVisibleBLEData }
