import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import SelectContainer from '../../containers/common/SelectContainer'
import { Option } from '../common/Select'
import FormError from '../common/FormError'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import { required } from '../../utils/validators'
import { toTitleCase } from '../../utils/textFormatters'
import { parseQueryParams } from '../../utils/queryParams'
import { WORKFLOW_TYPES } from '../../constants'

class EditWorkflowForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    submitAction: PropTypes.func.isRequired,
    setWorkflowType: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { workflowType } = parseQueryParams(global.location)

    if (!!workflowType) {
      this.props.setWorkflowType(workflowType)
    }
  }

  render() {
    const { submitAction, handleSubmit, submitting, error } = this.props

    const submit = handleSubmit(submitAction)

    return (
      <section className="EditWorkflowForm">
        <Form onSubmit={submit}>
          <div>
            <Field
              name="workflowType"
              component={SelectContainer}
              label="Workflow Type"
              validate={[required]}
              className="w-100"
              filterable
            >
              {Object.keys(WORKFLOW_TYPES).map(x => (
                <Option value={x} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Field>
            <Field
              name="schema"
              type="textArea"
              component={InputContainer}
              label="Workflow JSON"
              className="w-100"
              validate={[required]}
              isTallTextArea
              isCode
            />
            <Field
              name="rawWorkflow"
              type="textArea"
              component={InputContainer}
              label="Raw Workflow"
              className="w-100"
              validate={[required]}
              isTallTextArea
              isCode
            />
            <Field
              name="schemaSvg"
              type="textArea"
              component={InputContainer}
              label="SVG Markup"
              className="w-100"
              isTallTextArea
              isCode
            />
          </div>
          <div className="db flex justify-between mv3">
            <CancelButton defaultLocation="/workflows" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
          <FormError error={error} />
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditWorkflowForm' })(EditWorkflowForm)
