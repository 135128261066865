import React, { Component } from 'react'
import EditGatewaySupplyForm from '../components/forms/EditGatewaySupplyForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createGatewaySupplyAction } from '../actions/gatewaySuppliesActions'

class CreateGatewaySupply extends Component {
  render() {
    const crumbs = [
      <Anchor to="/inventory/all/gateway-supplies">
        Network Gateway Supplies
      </Anchor>,
      'Create Network Gateway Supply',
    ]
    return (
      <section className="CreateGatewaySupply">
        <FormHeader crumbs={crumbs} />
        <EditGatewaySupplyForm submitAction={createGatewaySupplyAction} />
      </section>
    )
  }
}

export default CreateGatewaySupply
