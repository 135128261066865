import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting.js'
import offlineExporting from 'highcharts/modules/offline-exporting.js'
import exportData from 'highcharts/modules/export-data.js'
import HighchartsReact from 'highcharts-react-official'
import { Spin } from '../common/Ant'
import { dateTimeFormatter } from '../../utils/date'
import { getSensorReading, getReading } from '../../utils/sensorData'
import {
  COLORS,
  REPORT_CHART_COLORS,
  DEFAULT_CHART_THEME,
} from '../../constants'

exporting(Highcharts)
offlineExporting(Highcharts)
exportData(Highcharts)

class ReportChart extends Component {
  static propTypes = {
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteName: PropTypes.string,
    reading: PropTypes.string.isRequired,
    isReport: PropTypes.bool.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    omittedLocations: PropTypes.arrayOf(PropTypes.string),
    setOmittedLocations: PropTypes.func,
  }

  static defaultProps = {
    isChartDataLoading: false,
    isReport: false,
  }

  render() {
    const {
      locations,
      siteName,
      reading,
      isReport,
      isChartDataLoading,
      omittedLocations,
      setOmittedLocations,
    } = this.props

    const options = {
      credits: { enabled: false },
      colors: REPORT_CHART_COLORS[reading] || DEFAULT_CHART_THEME,
      exporting: !isReport
        ? {
            filename: `${siteName} — ${getReading(reading)}`,
            buttons: {
              contextButton: {
                menuItems: [
                  'viewFullscreen',
                  'printChart',
                  'separator',
                  'downloadPNG',
                  'downloadJPEG',
                  'separator',
                  'downloadCSV',
                ],
              },
            },
          }
        : false,
      chart: {
        animation: false,
        zoomType: 'x',
        style: {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
        },
      },
      plotOptions: {
        series: {
          type: 'line',
          events: {
            legendItemClick: ev => {
              if (!!setOmittedLocations) {
                if (ev.target.visible) {
                  setOmittedLocations([
                    ...omittedLocations,
                    ev.target.name + reading,
                  ])
                } else {
                  setOmittedLocations(
                    omittedLocations.filter(x => x !== ev.target.name + reading)
                  )
                }
              }
            },
          },
        },
        line: {
          animation: false,
          marker: {
            enabled: false,
          },
        },
      },
      title: {
        text:
          !isReport && siteName
            ? `${siteName} — ${getReading(reading)}`
            : getReading(reading),
      },
      yAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        labels: {
          style: {
            color: COLORS.BLACK_80,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        type: 'datetime',
        labels: {
          style: {
            color: COLORS.BLACK_80,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      tooltip: {
        valueDecimals: 2,
        headerFormat:
          "<span style='font-size: 0.875rem; margin-bottom: 0.875rem'>{point.key}</span><br />",
        style: {
          fontSize: '0.875rem',
          color: COLORS.BLACK_80,
        },
      },
      legend: {
        verticalAlign: 'top',
        itemStyle: {
          fontSize: '0.875rem',
          color: COLORS.BLACK_80,
        },
        itemHoverStyle: {
          color: COLORS.MOON_GRAY,
        },
      },
      series: locations.map(x => {
        const readingData = getSensorReading(reading)
        const isFloorAvg = x.type === 'AVERAGE'
        const isLastAvg = x.type === 'LAST_AVERAGE'

        return {
          name: isLastAvg ? 'Previous Period Avg.' : x.label,
          data: x.data.map(y => ({
            x: new Date(y.time).valueOf(),
            y: y[reading],
            name: isLastAvg
              ? dateTimeFormatter(y.originalTime, true)
              : undefined,
          })),
          tooltip: {
            valueSuffix: readingData.unit,
          },
          opacity: 0.8,
          lineWidth: isFloorAvg || isLastAvg ? 3 : 2,
          dashStyle: isFloorAvg || isLastAvg ? 'ShortDash' : 'Solid',
          zIndex: isFloorAvg || isLastAvg ? 1000 : undefined,
        }
      }),
    }

    const locationsContainSensorData =
      locations.filter(x => x.data.length > 0).length > 0

    return (
      <div className="ReportChart mb3">
        {isChartDataLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : locationsContainSensorData ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { height: '400px' } }}
          />
        ) : (
          <div>
            <div className="tc" style={{ fontSize: '18px' }}>
              {getReading(reading)}
            </div>
            <h4 className="f4 tc mv4">
              No data available for selected time period
            </h4>
          </div>
        )}
      </div>
    )
  }
}

export default ReportChart
