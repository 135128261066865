import { stringify, parse } from 'query-string'
import { camelizeKeys, decamelizeKeys } from 'humps'

const createQueryString = query => {
  const qs = stringify(decamelizeKeys(query))

  return qs ? `?${qs}` : ''
}

const parseQueryParams = location => camelizeKeys(parse(location.search))

export { parseQueryParams, createQueryString }
