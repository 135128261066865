import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { required, isEmail, isPhoneNumber } from '../../utils/validators'
import { hasRole } from '../../utils/hasPermission'
import { ROLES } from '../../constants'
import { toTitleCase } from '../../utils/textFormatters'
import { normalizePhoneNumber, formatPhoneNumber } from '../../utils/forms'

const EditUserForm = props => {
  const { handleSubmit, submitting, error, submitAction, companies } = props

  const submit = handleSubmit(submitAction)

  const roles = Object.keys(ROLES)
  const availableRoles = hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN)
    ? roles
    : [
        'ORGANIZATION_ADMIN',
        'JOBSITE_ADMIN',
        'JOBSITE_USER',
        'CONTRACTOR',
        'SUB_CONTRACTOR',
      ]

  return (
    <section className="EditUserForm">
      <Form onSubmit={submit}>
        <div className="flex-ns">
          <Field
            name="email"
            type="email"
            component={InputContainer}
            label="Email"
            validate={[required, isEmail]}
            className="mr3-ns w-third-ns"
          />
          <Field
            name="companyId"
            component={SelectContainer}
            label="Company"
            validate={[required]}
            placeholder="Select a company"
            className="mr3-ns w-third-ns"
            filterable
          >
            {companies.map(x => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Field>
          <Field
            name="role"
            component={SelectContainer}
            label="Role"
            validate={[required]}
            placeholder="Select a role"
            className="w-third-ns"
            filterable
          >
            {availableRoles.map(x => (
              <Option value={x} key={x}>
                {toTitleCase(x.replace(/_/g, ' '))}
              </Option>
            ))}
          </Field>
        </div>
        <div className="flex-ns">
          <Field
            name="firstName"
            type="text"
            component={InputContainer}
            label="First Name"
            validate={[required]}
            className="w-50-ns mr3-ns"
          />
          <Field
            name="lastName"
            type="text"
            component={InputContainer}
            label="Last Name"
            validate={[required]}
            className="w-50-ns"
          />
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="phoneNumber"
            type="tel"
            component={InputContainer}
            label="Phone Number"
            className="w-50-ns mr3-ns"
            normalize={normalizePhoneNumber}
            format={formatPhoneNumber}
            validate={[isPhoneNumber]}
          />
          <Field
            name="title"
            type="text"
            component={InputContainer}
            label="Job Title"
            className="w-50-ns"
          />
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton defaultLocation="/users" />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditUserForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditUserForm' })(EditUserForm)
