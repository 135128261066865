import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Form = ({ onSubmit, className = '', children, noMargin }) => {
  const formClassNames = cx(className, {
    mb3: !noMargin,
    mb0: noMargin,
  })

  return (
    <form className={formClassNames} onSubmit={onSubmit} noValidate>
      {children}
    </form>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  noMargin: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
}

Form.defaultProps = { noMargin: false }

export default Form
