import React from 'react'
import MainContent from './common/MainContent'
import { H2 } from './common/Headers'
import appStoreBadge from '../assets/app-store-badge.png'
import googlePlayBadge from '../assets/google-play-badge.png'

const NativeApps = () => {
  return (
    <MainContent>
      <div className="NativeApps with-side-nav">
        <H2>Pillar On-Site</H2>
        <p>
          Pillar offers an app for both iOS and Android. Our On-Site app allows
          you to:
        </p>
        <ul>
          <li>view floor plans to see real-time status of your job site</li>
          <li>
            scan Pillar devices to show their current status and latest
            environmental readings
          </li>
          <li>
            subscribe to push notifications that alert you when issues arise
          </li>
        </ul>
        <div className="mb3 mw4">
          <a
            href="https://apps.apple.com/us/app/pillar-on-site/id1476714166?ls=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Download on the App Store" src={appStoreBadge} />
          </a>
        </div>
        <div className="mw4">
          <a
            href="https://play.google.com/store/apps/details?id=tech.pillar.PillarNative&hl=en&gl=US"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Get it on Google Play" src={googlePlayBadge} />
          </a>
        </div>
      </div>
    </MainContent>
  )
}

export default NativeApps
