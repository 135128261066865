import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditPodForm from './forms/EditPodForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updatePodAction } from '../actions/podsActions'
import { createQueryString } from '../utils/queryParams'

class EditPod extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllTags: PropTypes.func.isRequired,
    pod: PropTypes.object.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    getPod: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    getPodMetadata: PropTypes.func.isRequired,
    podMetadata: PropTypes.object,
    match: PropTypes.object,
  }

  componentDidMount() {
    const {
      getPod,
      getAllAssetLocations,
      getPodMetadata,
      getAllTags,
    } = this.props
    const qs = createQueryString({ perPage: 'all' })

    getPod(this.props.match.params.pillarId)
    getAllAssetLocations(qs)
    getPodMetadata()
    getAllTags(createQueryString({ perPage: 'all' }))
  }

  render() {
    const { tags, pod, assetLocations, podMetadata } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/pods">Smart Pods</Anchor>,
      'Edit Smart Pod',
    ]

    return (
      <section className="EditPod">
        <FormHeader crumbs={crumbs} />
        <EditPodForm
          tags={tags}
          submitAction={updatePodAction}
          initialValues={pod}
          assetLocations={assetLocations}
          podMetadata={podMetadata}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditPod
