import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditPurchaseForm from '../components/forms/EditPurchaseForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createPurchaseAction } from '../actions/purchasesActions'
import { createQueryString } from '../utils/queryParams'

class CreatePurchase extends Component {
  static propTypes = {
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    parts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    getAllParts: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { getAllParts, getAllAssetLocations } = this.props
    const allQs = createQueryString({ perPage: 'all' })

    getAllParts(allQs)
    getAllAssetLocations(allQs)
  }

  render() {
    const { parts, assetLocations } = this.props
    const crumbs = [
      <Anchor to="/inventory/all/purchases">Purchases</Anchor>,
      'Create Purchase',
    ]
    return (
      <section className="CreatePurchase">
        <FormHeader crumbs={crumbs} />
        <EditPurchaseForm
          submitAction={createPurchaseAction}
          parts={parts}
          assetLocations={assetLocations}
        />
      </section>
    )
  }
}

export default CreatePurchase
