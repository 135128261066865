import { createFormAction } from 'redux-form-saga'

const getAllCommentsAction = createFormAction('GET_COMMENTS')
const createCommentAction = createFormAction('CREATE_COMMENT')
const updateCommentAction = createFormAction('UPDATE_COMMENT')
const deleteCommentAction = createFormAction('DELETE_COMMENT')

const getAllTagsAction = createFormAction('GET_ALL_TAGS')
const getTagAction = createFormAction('GET_TAG')
const createTagAction = createFormAction('CREATE_TAG')
const updateTagAction = createFormAction('UPDATE_TAG')
const deleteTagAction = createFormAction('DELETE_TAG')

const getAllAvailbleUserMentionsAction = createFormAction(
  'GET_ALL_USER_MENTIONS'
)

export {
  getAllCommentsAction,
  createCommentAction,
  updateCommentAction,
  deleteCommentAction,
  getAllTagsAction,
  getTagAction,
  createTagAction,
  updateTagAction,
  deleteTagAction,
  getAllAvailbleUserMentionsAction,
}
