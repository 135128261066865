import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditEscalationPolicyForm from '../components/forms/EditEscalationPolicyForm'
import { H2 } from './common/Headers'
import { createEscalationPolicyAction } from '../actions/escalationPoliciesActions'
import { createQueryString } from '../utils/queryParams'

class CreateEscalationPolicy extends Component {
  static propTypes = {
    accountProfile: PropTypes.object.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllSites: PropTypes.func.isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    unsetSiteUserIds: PropTypes.func.isRequired,
    updateEscalationPolicySort: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getAllSites(createQueryString({ perPage: 'all' }))
  }

  render() {
    const {
      sites,
      siteUsers,
      getAllUsersBySite,
      unsetSiteUserIds,
      updateEscalationPolicySort,
    } = this.props

    return (
      <section className="CreateEscalationPolicy">
        <H2>Create Escalation Policy</H2>
        <EditEscalationPolicyForm
          initialValues={{ policyGroups: [] }}
          enableReinitialize
          submitAction={createEscalationPolicyAction}
          sites={sites}
          siteUsers={siteUsers}
          getAllUsersBySite={getAllUsersBySite}
          unsetSiteUserIds={unsetSiteUserIds}
          updateEscalationPolicySort={updateEscalationPolicySort}
        />
      </section>
    )
  }
}

export default CreateEscalationPolicy
