import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { COLORS } from '../../constants'

const InventoryProjectionsChart = ({ counts, monthlyProjections }) => {
  const [aggregatePodsVisible, setAggregatePodsVisible] = useState(undefined)
  const [aggregateGatewaysVisible, setAggregateGatewaysVisible] = useState(
    undefined
  )
  const [
    aggregateFlowMonitorsVisible,
    setAggregateFlowMonitorsVisible,
  ] = useState(undefined)

  const [monthlyRequiredPodsVisible, setMonthlyRequiredPodsVisible] = useState(
    undefined
  )
  const [
    monthlyRequiredGatewaysVisible,
    setMonthlyRequiredGatewaysVisible,
  ] = useState(undefined)
  const [
    monthlyRequiredFlowMonitorsVisible,
    setMonthlyRequiredFlowMonitorsVisible,
  ] = useState(undefined)

  const [
    podInventoryAvailableVisible,
    setPodInventoryAvailableVisible,
  ] = useState(undefined)
  const [
    gatewayInventoryAvailableVisible,
    setGatewayInventoryAvailableVisible,
  ] = useState(undefined)
  const [
    flowMonitorInventoryAvailableVisible,
    setFlowMonitorInventoryAvailableVisible,
  ] = useState(undefined)

  const options = {
    credits: { enabled: false },
    exporting: { enabled: false },
    chart: {
      animation: false,
      zoomType: 'xy',
      style: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
      },
      height: '800px',
    },
    plotOptions: {
      series: {
        type: 'line',
      },
      line: {
        dashStyle: 'ShortDot',
        marker: {
          enabled: false,
        },
      },
      column: {
        tooltip: {
          xDateFormat: '%B %Y',
        },
        width: 40,
        borderWidth: 4,
        borderColor: COLORS.BLACK_80,
        stacking: 'normal',
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      labels: {
        style: {
          color: COLORS.BLACK_80,
          fontSize: '0.75rem',
        },
      },
      title: {
        text: 'Units',
      },
      plotLines: [
        {
          color: COLORS.BLACK_80,
          dashStyle: 'Solid',
          value: 0,
          width: 1,
          zIndex: 5,
        },
      ],
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      type: 'datetime',
      labels: {
        style: {
          color: COLORS.BLACK_80,
          fontSize: '0.75rem',
        },
      },
      title: {
        text: 'Date',
      },
      crosshair: true,
    },
    tooltip: {
      headerFormat:
        "<span style='font-size: 0.875rem; margin-bottom: 0.875rem'>{point.key}</span><br />",
      style: {
        fontSize: '0.875rem',
        color: COLORS.BLACK_80,
      },
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '0.875rem',
        color: COLORS.BLACK_80,
      },
      itemHoverStyle: {
        color: COLORS.MOON_GRAY,
      },
    },
    series: [
      {
        name: 'Aggregate Smart Pods Required',
        data: counts.data.map(x => [x.time, x.pods]),
        color: COLORS.DARK_BLUE,
        visible: aggregatePodsVisible,
        events: {
          legendItemClick: ev => {
            setAggregatePodsVisible(
              aggregatePodsVisible === undefined ? false : !aggregatePodsVisible
            )
            ev.preventDefault()
          },
        },
        tooltip: {
          xDateFormat: '%B %d, %Y',
        },
      },
      {
        name: 'Aggregate Network Gateways Required',
        data: counts.data.map(x => [x.time, x.gateways]),
        color: COLORS.DARK_GREEN,
        visible: aggregateGatewaysVisible,
        events: {
          legendItemClick: ev => {
            setAggregateGatewaysVisible(
              aggregateGatewaysVisible === undefined
                ? false
                : !aggregateGatewaysVisible
            )
            ev.preventDefault()
          },
        },
        tooltip: {
          xDateFormat: '%B %d, %Y',
        },
      },
      {
        name: 'Aggregate Water Monitors Required',
        data: counts.data.map(x => [x.time, x.flowMonitors]),
        color: COLORS.DARK_PINK,
        visible: aggregateFlowMonitorsVisible,
        events: {
          legendItemClick: ev => {
            setAggregateFlowMonitorsVisible(
              aggregateFlowMonitorsVisible === undefined
                ? false
                : !aggregateFlowMonitorsVisible
            )
            ev.preventDefault()
          },
        },
        tooltip: {
          xDateFormat: '%B %d, %Y',
        },
      },
      {
        name: 'Monthly Smart Pods Required',
        data: monthlyProjections.map(x => [x.timestamp, x.totalPods]),
        type: 'column',
        color: COLORS.BLUE,
        opacity: 0.75,
        visible: monthlyRequiredPodsVisible,
        events: {
          legendItemClick: ev => {
            setMonthlyRequiredPodsVisible(
              monthlyRequiredPodsVisible === undefined
                ? false
                : !monthlyRequiredPodsVisible
            )
            ev.preventDefault()
          },
        },
      },
      {
        name: 'Monthly Network Gateways Required',
        data: monthlyProjections.map(x => [x.timestamp, x.totalGateways]),
        type: 'column',
        color: COLORS.GREEN,
        opacity: 0.75,
        visible: monthlyRequiredGatewaysVisible,
        events: {
          legendItemClick: ev => {
            setMonthlyRequiredGatewaysVisible(
              monthlyRequiredGatewaysVisible === undefined
                ? false
                : !monthlyRequiredGatewaysVisible
            )
            ev.preventDefault()
          },
        },
      },
      {
        name: 'Monthly Water Monitors Required',
        data: monthlyProjections.map(x => [x.timestamp, x.totalFlowMonitors]),
        type: 'column',
        color: COLORS.PINK,
        opacity: 0.75,
        visible: monthlyRequiredFlowMonitorsVisible,
        events: {
          legendItemClick: ev => {
            setMonthlyRequiredFlowMonitorsVisible(
              monthlyRequiredFlowMonitorsVisible === undefined
                ? false
                : !monthlyRequiredFlowMonitorsVisible
            )
            ev.preventDefault()
          },
        },
      },
      {
        name: 'Smart Pod Inventory Available',
        data: monthlyProjections.map(x => [x.timestamp, x.balancePods]),
        zones: [
          {
            value: counts.inventoryProjections.podsRequired,
            color: COLORS.RED,
          },
          {
            color: COLORS.LIGHT_BLUE,
          },
        ],
        dashStyle: 'LongDashDotDot',
        visible: podInventoryAvailableVisible,
        events: {
          legendItemClick: ev => {
            setPodInventoryAvailableVisible(
              podInventoryAvailableVisible === undefined
                ? false
                : !podInventoryAvailableVisible
            )
            ev.preventDefault()
          },
        },
        tooltip: {
          xDateFormat: '%B %Y',
          pointFormatter: function() {
            return `
              <div style="display: flex">
                <div style="background: ${
                  this.color
                }; display: block; height: 16px; width: 16px" />
                <div>
                  ${this.series.name}: ${this.y.toLocaleString()}
                </div>
              </div>`
          },
        },
      },

      {
        name: 'Network Gateway Inventory Available',
        data: monthlyProjections.map(x => [x.timestamp, x.balanceGateways]),
        zones: [
          {
            value: counts.inventoryProjections.gatewaysRequired,
            color: COLORS.RED,
          },
          {
            color: COLORS.LIGHT_GREEN,
          },
        ],
        dashStyle: 'LongDashDotDot',
        visible: gatewayInventoryAvailableVisible,
        events: {
          legendItemClick: ev => {
            setGatewayInventoryAvailableVisible(
              gatewayInventoryAvailableVisible === undefined
                ? false
                : !gatewayInventoryAvailableVisible
            )
            ev.preventDefault()
          },
        },
        tooltip: {
          xDateFormat: '%B %Y',
          pointFormatter: function() {
            return `
              <div style="display: flex">
                <div style="background: ${
                  this.color
                }; display: block; height: 16px; width: 16px" />
                <div>
                  ${this.series.name}: ${this.y.toLocaleString()}
                </div>
              </div>`
          },
        },
      },
      {
        name: 'Water Monitor Inventory Available',
        data: monthlyProjections.map(x => [x.timestamp, x.balanceFlowMonitors]),
        zones: [
          {
            value: counts.inventoryProjections.flowMonitorsRequired,
            color: COLORS.RED,
          },
          {
            color: COLORS.LIGHT_PINK,
          },
        ],
        dashStyle: 'LongDashDotDot',
        visible: flowMonitorInventoryAvailableVisible,
        events: {
          legendItemClick: ev => {
            setFlowMonitorInventoryAvailableVisible(
              flowMonitorInventoryAvailableVisible === undefined
                ? false
                : !flowMonitorInventoryAvailableVisible
            )
            ev.preventDefault()
          },
        },
        tooltip: {
          xDateFormat: '%B %Y',
          pointFormatter: function() {
            return `
              <div style="display: flex">
                <div style="background: ${
                  this.color
                }; display: block; height: 16px; width: 16px" />
                <div>
                  ${this.series.name}: ${this.y.toLocaleString()}
                </div>
              </div>`
          },
        },
      },
    ],
  }

  return (
    <div className="InventoryProjectionsChart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

InventoryProjectionsChart.propTypes = {
  monthlyProjections: PropTypes.arrayOf(PropTypes.object).isRequired,
  counts: PropTypes.object.isRequired,
}

export default InventoryProjectionsChart
