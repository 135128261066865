import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Tabs, TabPane } from '../common/Ant'
import { Bread, Crumb } from '../common/Bread'
import MainContent from '../common/MainContent'
import AllSupportPhoneNumbers from '../AllSupportPhoneNumbers'
import Inbox from '../Inbox'
import Conversation from '../Conversation'
import CreateSupportPhoneNumber from '../CreateSupportPhoneNumber'
import EditSupportPhoneNumber from '../EditSupportPhoneNumber'
import PageWidth from '../PageWidth'
import { hasRole } from '../../utils/hasPermission'
import { getPathSnippets } from '../../utils/helpers'
import { ROLES } from '../../constants'

const CommunicationsPage = props => {
  const {
    match: { url },
    updateQueryParams,
    getAllSupportPhoneNumbers,
    getSupportPhoneNumber,
    deleteSupportPhoneNumber,
    getUsers,
    getUser,
    clearUser,
    clearConversation,
    phoneNumbers,
    currentPhoneNumber,
    users,
    phoneNumbersMeta,
    getAllConversations,
    getConversation,
    createConversation,
    createBridgedPhoneCall,
    closeConversation,
    conversations,
    conversationMeta,
    accountProfile,
    isCommunicationLoading,
    currentConversation,
    participant,
    pillarParticipant,
    user,

    isCommentLoading,
    getAllComments,
    deleteComment,
    createComment,
    editComment,
    getAllTags,
    tags,
    comments,
  } = props
  const [activeTab, setActiveTab] = useState('inbox')

  const handleTabSelection = entity => {
    setActiveTab(entity)

    updateQueryParams({
      pathname: `${url}/${entity}`,
      search: entity === activeTab ? global.location.search : '',
    })
  }

  useEffect(() => {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    handleTabSelection(lastSnippet)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContent>
      <Helmet>
        <title>Communications</title>
      </Helmet>
      <div className="CommunicationsPage with-side-nav">
        <PageWidth>
          <Switch>
            <Route
              path={`${url}/`}
              exact
              render={props => <Redirect to={`${url}/inbox`} />}
            />
            <Route
              path={`${url}/phone-numbers/create`}
              exact
              render={props => (
                <Fragment>
                  <Helmet>
                    <title>Create Support Phone Number</title>
                  </Helmet>
                  <CreateSupportPhoneNumber
                    getUsers={getUsers}
                    users={users}
                    {...props}
                  />
                </Fragment>
              )}
            />
            <Route
              path={`${url}/phone-numbers/:slug/edit`}
              exact
              render={props => (
                <Fragment>
                  <Helmet>
                    <title>Edit Support Phone Number</title>
                  </Helmet>
                  <EditSupportPhoneNumber
                    getSupportPhoneNumber={getSupportPhoneNumber}
                    getUsers={getUsers}
                    phoneNumber={currentPhoneNumber}
                    users={users}
                    {...props}
                  />
                </Fragment>
              )}
            />
            <Route
              path={`${url}/conversations/:chatroomSlug`}
              exact
              render={props => (
                <Fragment>
                  <Helmet>
                    <title>Conversation</title>
                  </Helmet>
                  <Conversation
                    getConversation={getConversation}
                    getUser={getUser}
                    clearUser={clearUser}
                    clearConversation={clearConversation}
                    user={user}
                    conversation={currentConversation}
                    participant={participant}
                    pillarParticipant={pillarParticipant}
                    accountProfile={accountProfile}
                    getAllComments={getAllComments}
                    deleteComment={deleteComment}
                    createComment={createComment}
                    editComment={editComment}
                    getAllTags={getAllTags}
                    tags={tags}
                    comments={comments}
                    isCommentLoading={isCommentLoading}
                    {...props}
                  />
                </Fragment>
              )}
            />
            <Route
              path={`${url}/`}
              render={() => (
                <div>
                  <div className="mb3">
                    <Bread>
                      <Crumb>Communications</Crumb>
                    </Bread>
                  </div>
                  <Tabs
                    type="card"
                    defaultActiveKey="inbox"
                    activeKey={activeTab}
                    onTabClick={handleTabSelection}
                  >
                    <TabPane tab="Inbox" key="inbox">
                      <Route
                        path={`${url}/inbox`}
                        exact
                        render={props => (
                          <Inbox
                            getAllConversations={getAllConversations}
                            getConversation={getConversation}
                            getUsers={getUsers}
                            users={users.filter(
                              x => x.slug !== accountProfile.slug
                            )}
                            conversations={conversations}
                            meta={conversationMeta}
                            createConversation={createConversation}
                            createBridgedPhoneCall={createBridgedPhoneCall}
                            closeConversation={closeConversation}
                            isCommunicationLoading={isCommunicationLoading}
                            updateQueryParams={updateQueryParams}
                            accountProfile={accountProfile}
                            {...props}
                          />
                        )}
                      />
                    </TabPane>
                    {hasRole(ROLES.SUPER_ADMIN) && (
                      <TabPane tab="Phone Numbers" key="phone-numbers">
                        <Route
                          path={`${url}/phone-numbers`}
                          exact
                          render={props => (
                            <AllSupportPhoneNumbers
                              getAllSupportPhoneNumbers={
                                getAllSupportPhoneNumbers
                              }
                              getUsers={getUsers}
                              deleteSupportPhoneNumber={
                                deleteSupportPhoneNumber
                              }
                              phoneNumbers={phoneNumbers}
                              users={users}
                              meta={phoneNumbersMeta}
                              isCommunicationLoading={isCommunicationLoading}
                              updateQueryParams={updateQueryParams}
                              {...props}
                            />
                          )}
                        />
                      </TabPane>
                    )}
                  </Tabs>
                </div>
              )}
            />
          </Switch>
        </PageWidth>
      </div>
    </MainContent>
  )
}

CommunicationsPage.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  updateQueryParams: PropTypes.func.isRequired,
  getAllSupportPhoneNumbers: PropTypes.func.isRequired,
  getSupportPhoneNumber: PropTypes.func.isRequired,
  deleteSupportPhoneNumber: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
  clearConversation: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPhoneNumber: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  phoneNumbersMeta: PropTypes.object.isRequired,
  getAllConversations: PropTypes.func.isRequired,
  getConversation: PropTypes.func.isRequired,
  createConversation: PropTypes.func.isRequired,
  createBridgedPhoneCall: PropTypes.func.isRequired,
  closeConversation: PropTypes.func.isRequired,
  conversations: PropTypes.arrayOf(PropTypes.object).isRequired,
  conversationMeta: PropTypes.object.isRequired,
  accountProfile: PropTypes.object.isRequired,
  isCommunicationLoading: PropTypes.bool.isRequired,
  currentConversation: PropTypes.arrayOf(PropTypes.object).isRequired,
  participant: PropTypes.object,
  pillarParticipant: PropTypes.object,
  user: PropTypes.object,

  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllComments: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  getAllTags: PropTypes.func.isRequired,
  isCommentLoading: PropTypes.bool.isRequired,
}

export default CommunicationsPage
