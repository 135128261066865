import React from 'react'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createAssetLocationAction } from '../actions/assetLocationsActions'
import CreateAssetLocationForm from '../components/forms/CreateAssetLocationForm'

const CreateAssetLocation = props => {
  const crumbs = [
    <Anchor to="/inventory/all">Inventory</Anchor>,
    <Anchor to="/inventory/all/asset-locations">Asset Locations</Anchor>,
    'Create Asset Location',
  ]

  return (
    <section className="CreateAssetLocation">
      <FormHeader crumbs={crumbs} />
      <CreateAssetLocationForm
        submitAction={createAssetLocationAction}
        {...props}
      />
    </section>
  )
}

export default CreateAssetLocation
