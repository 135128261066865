import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllUsersAction,
  getUserAction,
  getUserCommunicationLogAction,
  getUserPermissionsAction,
  updateUserPermissionsAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  toggleUserOptionAction,
  updateUserDefaultSiteAction,
  revokeApiTokenAction,
  getDeveloperAccessTokenHistoryAction,
} from '../actions/usersActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES, DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'
import { pickErrorMessage } from '../utils/helpers'
import { parseQueryParams, createQueryString } from '../utils/queryParams'

// handlers

function* handleGetAllUsersSaga({ payload }) {
  try {
    const result = yield call(api.getAllUsers, payload)

    yield put(getAllUsersAction.success(result))

    const isExport = /export=true/.test(payload)
    if (isExport) {
      yield put(
        showSuccessMessageAction(MESSAGES.SUCCESSFULLY_GENERATED_USER_EXPORT)
      )
    }
  } catch (error) {
    yield put(getAllUsersAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetUserSaga({ payload }) {
  try {
    const result = yield call(api.getUserBySlug, payload)

    yield put(getUserAction.success(result))
  } catch (error) {
    yield put(getUserAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateUserSaga({ payload }) {
  try {
    const result = yield call(api.createUser, payload)

    yield put(createUserAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/users/${result.slug}`))
  } catch (error) {
    yield put(createUserAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateUserSaga({ payload }) {
  try {
    const result = yield call(api.updateUser, payload)
    yield put(updateUserAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/users/${result.slug}`))
  } catch (error) {
    yield put(updateUserAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteUserSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteUser, payload)

      const { page, perPage, role, companyId, siteSlug } = parseQueryParams(
        global.location
      )
      const query = {
        page: page ? parseInt(page, 10) : DEFAULT_PAGE,
        perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
        role,
        companyId: companyId ? parseInt(companyId, 10) : undefined,
        siteSlug,
      }

      yield fork(handleGetAllUsersSaga, { payload: createQueryString(query) })
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteUserAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleToggleSelectionSaga({ payload }) {
  try {
    const result = yield call(api.updateUser, payload)
    yield put(updateUserAction.success(result))
  } catch (error) {
    yield put(updateUserAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateUserDefaultSiteSaga({ payload }) {
  try {
    const result = yield call(api.updateUserDefaultSite, payload)
    yield put(updateUserDefaultSiteAction.success(result))
  } catch (error) {
    yield put(updateUserDefaultSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetUserCommunicationLogSaga({ payload }) {
  try {
    const { slug, qs } = payload
    const result = yield call(api.getUserCommunicationLog, slug, qs)

    yield put(getUserCommunicationLogAction.success(result))
  } catch (error) {
    yield put(getUserCommunicationLogAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetUserPermissionsSaga({ payload }) {
  try {
    const result = yield call(api.getUserPermissionsBySlug, payload)

    yield put(getUserPermissionsAction.success(result))
  } catch (error) {
    yield put(getUserPermissionsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateUserPermissionsSaga({ payload }) {
  try {
    const { slug, permissions } = payload
    const result = yield call(api.updateUserPermissions, slug, permissions)

    yield put(updateUserPermissionsAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(updateUserPermissionsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleRevokeApiTokenSaga({ payload }) {
  try {
    const result = yield call(api.revokeApiToken, payload)

    yield put(revokeApiTokenAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(getUserAction.request(payload))
  } catch (error) {
    yield put(revokeApiTokenAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetDeveloperAccessTokenHistorySaga({ payload }) {
  try {
    const result = yield call(api.getDeveloperAccessTokenHistory, payload)

    yield put(getDeveloperAccessTokenHistoryAction.success(result))
  } catch (error) {
    yield put(getDeveloperAccessTokenHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllUsersSaga() {
  yield takeLatest(getAllUsersAction.REQUEST, handleGetAllUsersSaga)
}

function* watchGetUserSaga() {
  yield takeLatest(getUserAction.REQUEST, handleGetUserSaga)
}

function* watchGetUserCommunicationLogSaga() {
  yield takeLatest(
    getUserCommunicationLogAction.REQUEST,
    handleGetUserCommunicationLogSaga
  )
}

function* watchGetUserPermissionsSaga() {
  yield takeLatest(
    getUserPermissionsAction.REQUEST,
    handleGetUserPermissionsSaga
  )
}

function* watchUpdateUserPermissionsSaga() {
  yield takeLatest(
    updateUserPermissionsAction.REQUEST,
    handleUpdateUserPermissionsSaga
  )
}

function* watchCreateUserSaga() {
  yield takeLatest(createUserAction.REQUEST, handleCreateUserSaga)
}

function* watchUpdateUserSaga() {
  yield takeLatest(updateUserAction.REQUEST, handleUpdateUserSaga)
}

function* watchDeleteUserSaga() {
  yield takeLatest(deleteUserAction.REQUEST, handleDeleteUserSaga)
}

function* watchToggleSelectionSaga() {
  yield takeLatest(toggleUserOptionAction.REQUEST, handleToggleSelectionSaga)
}

function* watchUpdateUserDefaultSiteSaga() {
  yield takeLatest(
    updateUserDefaultSiteAction.REQUEST,
    handleUpdateUserDefaultSiteSaga
  )
}

function* watchRevokeApiTokenSaga() {
  yield takeLatest(revokeApiTokenAction.REQUEST, handleRevokeApiTokenSaga)
}

function* watchGetDeveloperAccessTokenHistorySaga() {
  yield takeLatest(
    getDeveloperAccessTokenHistoryAction.REQUEST,
    handleGetDeveloperAccessTokenHistorySaga
  )
}

function* usersSaga() {
  yield all([
    fork(watchGetAllUsersSaga),
    fork(watchGetUserSaga),
    fork(watchGetUserCommunicationLogSaga),
    fork(watchGetUserPermissionsSaga),
    fork(watchUpdateUserPermissionsSaga),
    fork(watchCreateUserSaga),
    fork(watchUpdateUserSaga),
    fork(watchDeleteUserSaga),
    fork(watchToggleSelectionSaga),
    fork(watchUpdateUserDefaultSiteSaga),
    fork(watchRevokeApiTokenSaga),
    fork(watchGetDeveloperAccessTokenHistorySaga),
  ])
}

export {
  usersSaga as default,
  watchGetAllUsersSaga,
  watchGetUserSaga,
  watchGetUserCommunicationLogSaga,
  watchGetUserPermissionsSaga,
  watchUpdateUserPermissionsSaga,
  watchCreateUserSaga,
  watchUpdateUserSaga,
  watchDeleteUserSaga,
  watchToggleSelectionSaga,
  watchUpdateUserDefaultSiteSaga,
  watchRevokeApiTokenSaga,
  watchGetDeveloperAccessTokenHistorySaga,
  handleGetAllUsersSaga,
  handleGetUserSaga,
  handleCreateUserSaga,
  handleUpdateUserSaga,
  handleDeleteUserSaga,
  handleToggleSelectionSaga,
}
