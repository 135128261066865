import React from 'react'
import { fork, call, take, takeEvery, all, delay } from 'redux-saga/effects'
import { message, notification } from '../components/common/Ant'
import { ActionAnchor } from '../components/common/Anchor'
import {
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  SHOW_ERROR_MESSAGE_WITH_DURATION,
  SHOW_UPGRADE_NOTIFICATION,
  SHOW_PHONE_VERIFICATION_NOTIFICATION,
  SHOW_SUCCESS_MESSAGE_WITH_DURATION,
} from '../actions/uiActions'
import { handlePhoneVerificationModal } from '../components/PillarProvider'

message.config({ top: 16 })
notification.config({ top: 16 })

// handlers

function* handleShowSuccessMessageSaga({ payload }) {
  yield call(message.success, payload)
}

function* handleShowSuccessMessageWithDurationSaga({ payload }) {
  yield call(message.success, payload.message, payload.duration)
}

function* handleShowErrorMessageSaga({ payload }) {
  yield call(message.error, payload)
}

function* handleShowErrorMessageWithDurationSaga({ payload }) {
  yield call(message.error, payload.message, payload.duration)
}

function* handleShowUpgradeNotificationSaga() {
  const handleRedirect = () => global.location.replace('/')

  yield call(notification.open, {
    message: 'New Version Available',
    description: (
      <p>
        Please refresh your browser or{' '}
        <ActionAnchor onClick={handleRedirect}>click here</ActionAnchor> to use
        the latest version of Pillar's website.
      </p>
    ),
    duration: 0,
  })
}

function* handleShowPhoneVerificationNotificationSaga() {
  yield call(notification.open, {
    message: 'Phone Verification',
    description: (
      <p>
        <ActionAnchor onClick={handlePhoneVerificationModal}>
          Please verify your phone number
        </ActionAnchor>{' '}
        in order to receive SMS or voice alerts.
      </p>
    ),
    duration: 0,
  })
}

// watchers

function* watchShowSuccessMessageSaga() {
  yield takeEvery(SHOW_SUCCESS_MESSAGE, handleShowSuccessMessageSaga)
}

function* watchShowErrorMessageSaga() {
  while (true) {
    const { payload } = yield take(SHOW_ERROR_MESSAGE)
    yield handleShowErrorMessageSaga({ payload })
    yield delay(3000)
  }
}

function* watchShowErrorMessageWithDurationSaga() {
  while (true) {
    const { payload } = yield take(SHOW_ERROR_MESSAGE_WITH_DURATION)
    yield handleShowErrorMessageWithDurationSaga({ payload })
    yield delay(3000)
  }
}

function* watchShowUpgradeNotificationSaga() {
  yield takeEvery(SHOW_UPGRADE_NOTIFICATION, handleShowUpgradeNotificationSaga)
}

function* watchShowPhoneVerificationNotificationSaga() {
  yield takeEvery(
    SHOW_PHONE_VERIFICATION_NOTIFICATION,
    handleShowPhoneVerificationNotificationSaga
  )
}

function* watchShowSuccessMessageWithDurationSaga() {
  yield takeEvery(
    SHOW_SUCCESS_MESSAGE_WITH_DURATION,
    handleShowSuccessMessageWithDurationSaga
  )
}

function* uiSaga() {
  yield all([
    fork(watchShowSuccessMessageSaga),
    fork(watchShowErrorMessageSaga),
    fork(watchShowUpgradeNotificationSaga),
    fork(watchShowPhoneVerificationNotificationSaga),
    fork(watchShowErrorMessageWithDurationSaga),
    fork(watchShowSuccessMessageWithDurationSaga),
  ])
}

export { uiSaga as default }
