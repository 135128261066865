import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from './common/Ant'
import { ActionAnchor } from './common/Anchor'
import { H3, H4 } from './common/Headers'
import Button from './common/Button'
import { Spin } from './common/Ant'
import Label from './common/Label'

class CSVUserUpload extends Component {
  state = {
    modalOpen: false,
    formData: null,
    addedCount: 0,
    failedCount: 0,
  }

  static propTypes = {
    uploadUsersCSV: PropTypes.func.isRequired,
    csvUploadResults: PropTypes.object.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // handles putting initial state data and updates
    return { ...nextProps.csvUploadResults, submitting: false }
  }

  componentWillUnmount() {
    if (this.state.modalOpen) {
      this.handleClose()
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
      addedCount: 0,
      failedCount: 0,
    })
  }

  handleFileSelection = event => {
    const files = Array.from(event.target.files)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('name', file.name)
      formData.append('type', file.type)
      formData.append('files[]', file)

      this.setState({ formData })
    })
  }

  handleSubmit = () => {
    const { formData } = this.state

    if (formData) {
      this.setState({ submitting: true })
      this.props.uploadUsersCSV(formData)
    }
  }

  render() {
    const {
      modalOpen,
      submitting,
      addedCount,
      failedCount,
      failed,
    } = this.state

    const isResultVisible = addedCount > 0 || failedCount > 0

    return (
      <div className="CSVUserUpload">
        <ActionAnchor onClick={this.handleOpen}>
          Import Sub-Contractors
        </ActionAnchor>
        <Modal
          visible={modalOpen}
          className="Modal"
          title={isResultVisible ? 'CSV Upload Results' : 'Upload CSV'}
          onCancel={this.handleClose}
          style={{ top: 16 }}
          footer={null}
          destroyOnClose
        >
          {submitting ? (
            <Spin size="large" className="w-100 center" />
          ) : isResultVisible ? (
            failedCount > 0 ? (
              <div>
                <H3 className="red">Failure!</H3>
                {failedCount > 0 && (
                  <div>
                    <H4>
                      {failedCount} Users failed validation. Please review the
                      rows shown below and try again.
                    </H4>
                    <div>
                      <pre>{failed.map(x => `${x}\n`)}</pre>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <H3 className="dark-blue">Success!</H3>
                <H4>{addedCount} Users added.</H4>
              </div>
            )
          ) : (
            <div>
              <Label>Select a CSV to upload</Label>
              <input
                type="file"
                accept="text/csv,application/vnd.ms-excel"
                onChange={this.handleFileSelection}
                className="mb3 w-100"
              />
              <Button onClick={this.handleSubmit} />
            </div>
          )}
        </Modal>
      </div>
    )
  }
}

export default CSVUserUpload
