import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Tooltip,
  Spin,
} from './common/Ant'
import Divider from './common/Divider'
import { H3 } from './common/Headers'
import { Anchor, ActionAnchor } from './common/Anchor'
import InputContainer from '../containers/common/InputContainer'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { dateTimeFormatter } from '../utils/date'
import { escapeRegExp } from '../utils/helpers'
import { ALERT_TYPES, USER_PERMISSIONS, ROLES } from '../constants'

class AllLocations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.locations,
      searchText: '',
      filtered: false,
    }
  }

  static propTypes = {
    deleteLocation: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    siteSlug: PropTypes.string.isRequired,
    floorId: PropTypes.string.isRequired,
    isLocationLoading: PropTypes.bool.isRequired,
    getAllLocationsByFloor: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { siteSlug, floorId, getAllLocationsByFloor } = this.props

    getAllLocationsByFloor(siteSlug, floorId)
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.locations
        .map(record => {
          const match =
            record.id.toString().match(regex) || record.name.match(regex)

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  handleChange = (pagination, filters, sorter) =>
    this.setState({ sortedInfo: sorter })

  render() {
    const {
      deleteLocation,
      siteSlug,
      floorId,
      windowWidth,
      isLocationLoading,
    } = this.props
    const { data, sortedInfo } = this.state

    const isFixedColumn = windowWidth < 750

    return (
      <section className="AllLocations">
        <H3 inline>Locations</H3>
        {hasPermission(USER_PERMISSIONS.ADD_LOCATION) ? (
          <Anchor
            button
            to={`/sites/${siteSlug}/floors/${floorId}/locations/create`}
          >
            Create New
          </Anchor>
        ) : null}
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: this.state.searchText,
              onChange: this.handleInputChange,
            }}
          />
        </div>
        {isLocationLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            rowKey="id"
            pagination={false}
            scroll={{
              x: hasPermission(USER_PERMISSIONS.EDIT_LOCATION) ? 900 : 800,
            }}
            onChange={this.handleChange}
            tableLayout="auto"
            bordered
          >
            <Column
              title="Name"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              width={300}
              render={(text, record) => (
                <Anchor
                  to={
                    record.flowMonitorPillarId
                      ? `/sites/${siteSlug}/floors/${floorId}/locations/${record.id}/flow-monitor-chart`
                      : `/sites/${siteSlug}/floors/${floorId}/locations/${record.id}`
                  }
                >
                  {text}
                </Anchor>
              )}
            />
            <Column
              title="Pillar ID"
              dataIndex="podPillarId"
              sorter={sortBy('podPillarId')}
              width={200}
              sortOrder={sortedInfo.field === 'podPillarId' && sortedInfo.order}
              render={(text, record) =>
                text || record.flowMonitorPillarId ? (
                  record.alertActive ? (
                    record.alertType === ALERT_TYPES.THRESHOLD ? (
                      <Tooltip title="Active Alert">
                        <Icon type="exclamation-circle-o" className="red" />
                        <span className="pl2">
                          {text ? text : record.flowMonitorPillarId}
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Offline">
                        <Icon type="poweroff" className="red" />
                        <span className="pl2">
                          {text ? text : record.flowMonitorPillarId}
                        </span>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip title="Online">
                      <Icon type="check-circle-o" className="green" />
                      <span className="pl2">
                        {text ? text : record.flowMonitorPillarId}
                      </span>
                    </Tooltip>
                  )
                ) : (
                  '--'
                )
              }
            />
            <Column
              title="Deployed At"
              dataIndex="podDeployedAt"
              sorter={sortBy('podDeployedAt')}
              sortOrder={
                sortedInfo.field === 'podDeployedAt' && sortedInfo.order
              }
              width={300}
              render={(text, record) =>
                text ? dateTimeFormatter(text, true) : '--'
              }
            />
            {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
              <Column
                title="Module ID"
                dataIndex="podModuleId"
                width={200}
                render={text =>
                  text ? (
                    <Anchor
                      to={`https://conductor.link-labs.com/symphony/${text}`}
                    >
                      {text}
                    </Anchor>
                  ) : (
                    '--'
                  )
                }
              />
            )}
            {hasPermission(USER_PERMISSIONS.EDIT_LOCATION) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={100}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-location">
                        <Anchor
                          to={`/sites/${siteSlug}/floors/${floorId}/locations/${record.id}/edit`}
                        >
                          Edit
                        </Anchor>
                      </Menu.Item>
                      <Menu.Item key="delete-location">
                        <ActionAnchor
                          onClick={() =>
                            deleteLocation(siteSlug, floorId, record.id)
                          }
                        >
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )

                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
        <Divider />
      </section>
    )
  }
}

export default AllLocations
