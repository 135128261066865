import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import { ActionAnchor } from '../common/Anchor'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import Label from '../common/Label'
import Checkbox from '../common/Checkbox'
import { required, isAlphaNumeric } from '../../utils/validators'

const EditFloorForm = props => {
  const {
    handleSubmit,
    submitting,
    error,
    submitAction,
    siteSlug,
    buildings,
    buildingId,
    removeBuildingFromFloor,
  } = props
  const submit = handleSubmit(submitAction)

  return (
    <section className="EditFloorForm">
      <Form onSubmit={submit}>
        <div className="flex-ns">
          <Field
            name="floor"
            type="text"
            component={InputContainer}
            label="Floor"
            validate={[required, isAlphaNumeric]}
            className="w-10-ns mr3-ns"
          />
          {buildingId ? (
            <div className="w-50-ns mb3">
              <Label>Assigned Building</Label>
              <div className="flex mt2">
                <div className="mr2 b">{buildingId}</div>
                <ActionAnchor onClick={removeBuildingFromFloor}>
                  (remove)
                </ActionAnchor>
              </div>
            </div>
          ) : (
            <Field
              name="buildingId"
              component={SelectContainer}
              label="Building"
              placeholder="Select a building"
              className="mr3-ns w-40-ns"
              filterable
            >
              {buildings.map(x => (
                <Option value={x.id} key={x.id}>
                  {x.name}
                </Option>
              ))}
            </Field>
          )}
          <Field
            name="name"
            type="text"
            component={InputContainer}
            label="Name"
            validate={[required]}
            className="w-50-ns"
          />
        </div>
        <div className="flex-ns">
          <Field
            name="active"
            component={Checkbox}
            type="checkbox"
            label="Active"
            className="w-10-ns w-100 mr3-ns"
          />
          <Field
            name="notes"
            type="text"
            component={InputContainer}
            label="Notes"
            className="w-90-ns w-100"
          />
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton defaultLocation={`/sites/${siteSlug}`} />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditFloorForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  siteSlug: PropTypes.string.isRequired,
  buildingId: PropTypes.number,
  buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeBuildingFromFloor: PropTypes.func,
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditFloorForm' })(EditFloorForm)
