import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { MentionsInput, Mention } from 'react-mentions'
import DateRange from '../common/DateRange'
import { normalizeDateTime, formatDateUTC } from '../common/DateSelector'
import Select, { Option } from '../common/Select'
import Label from '../common/Label'
import Button from '../common/Button'
import { createQueryString } from '../../utils/queryParams'

// TODO break out CommentWithMentions as component
const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: '#f4f4f4',
      border: '1px solid #eee',
      borderRadius: '4px',
      fontSize: '0.875rem',
    },
    item: {
      padding: '0.25rem 1rem',
      borderBottom: '1px solid #eee',
      '&focused': {
        color: '#fff',
        backgroundColor: '#00449e',
      },
    },
  },
}

const defaultMentionStyle = {
  backgroundColor: 'rgba(0, 68, 158, 0.3)',
}

class EditCommentForm extends Component {
  constructor(props) {
    super(props)

    const { isEditing, comment, startTime, endTime } = this.props

    this.state = {
      commentNotInput: false,
      selectedTags: comment.tagIds,
      text: comment.text,
      plainText: comment.plainText,
      attachments: comment.attachments || [],
      endTime:
        isEditing && comment.endTime
          ? moment(comment.endTime)
          : endTime
          ? moment(endTime)
          : undefined,
      startTime:
        isEditing && comment.startTime
          ? moment(comment.startTime)
          : startTime
          ? moment(startTime)
          : undefined,
    }
  }

  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    comment: PropTypes.object,
    resourceSlug: PropTypes.string,
    resourceType: PropTypes.string,
    startTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    submitFn: PropTypes.func.isRequired,
    setCommentTime: PropTypes.func,
    isEditing: PropTypes.bool,
    showDateFields: PropTypes.bool.isRequired,
    resetCommentState: PropTypes.func,
    getUserMentions: PropTypes.func.isRequired,
    mentions: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  static defaultProps = {
    comment: {
      tagIds: [],
      text: '',
    },
    showDateFields: true,
  }

  componentDidMount() {
    const { resourceType, resourceSlug, getUserMentions } = this.props

    getUserMentions(createQueryString({ resourceType, resourceSlug }))
  }

  componentDidUpdate(prevProps) {
    const { isEditing, comment, endTime, startTime } = this.props

    if (
      prevProps.isEditing !== isEditing ||
      prevProps.comment.createdAt !== comment.createdAt
    ) {
      this.setState({
        selectedTags: comment.tagIds,
        text: comment.text,
        endTime: comment.endTime
          ? moment(comment.endTime)
          : endTime
          ? moment(endTime)
          : undefined,
        startTime: comment.startTime
          ? moment(comment.startTime)
          : startTime
          ? moment(startTime)
          : undefined,
      })
    }
    // we want to load the comment's original start and end first
    // and only change it if there's been an update
    if (startTime !== prevProps.startTime && endTime !== prevProps.endTime) {
      this.setState({
        endTime: endTime
          ? moment(endTime)
          : comment.endTime
          ? moment(comment.endTime)
          : undefined,
        startTime: startTime
          ? moment(startTime)
          : comment.startTime
          ? moment(comment.startTime)
          : undefined,
      })
    }
  }

  handleSubmit = () => {
    if (!this.state.text) {
      this.setState({ commentNotInput: true })
    } else {
      const {
        isEditing,
        resourceType,
        resourceSlug,
        comment,
        submitFn,
        resetCommentState,
        setCommentTime,
      } = this.props
      const {
        startTime,
        endTime,
        selectedTags,
        text,
        plainText,
        attachments,
      } = this.state

      const body = {
        slug: isEditing ? comment.slug : '',
        startTime: normalizeDateTime(formatDateUTC(startTime)),
        endTime: normalizeDateTime(formatDateUTC(endTime)),
        resourceType,
        resourceSlug,
        text,
        plainText,
        tagIds: selectedTags,
        attachments: attachments.map(x => x.id),
      }

      this.setState({ commentNotInput: false })

      submitFn(body)
      setCommentTime && setCommentTime(undefined, undefined)
      resetCommentState && resetCommentState()

      this.setState({
        selectedTags: [],
        text: '',
        plainText: '',
        attachments: [],
        endTime: this.props.endTime ? moment(this.props.endTime) : undefined,
        startTime: this.props.startTime
          ? moment(this.props.startTime)
          : undefined,
      })
    }
  }

  handleSelectChange = keyName => value => this.setState({ [keyName]: value })

  handleInputChange = keyName => e =>
    this.setState({ [keyName]: e.currentTarget.value })

  onCloseDateRange = (startTime, endTime) =>
    this.setState({ startTime, endTime })

  handleMentionChange = (event, newValue, newPlainTextValue, mentions) => {
    this.setState({
      text: newValue,
      plainText: newPlainTextValue,
      attachments: mentions,
    })
  }

  render() {
    const { tags, showDateFields, mentions } = this.props
    const { text, startTime, endTime } = this.state

    return (
      <section className="EditCommentForm mb3">
        <div>
          <div className="CommentWithMentions mb3">
            <Label>Comment</Label>
            <MentionsInput
              value={text}
              onChange={this.handleMentionChange}
              style={defaultStyle}
            >
              <Mention
                trigger="@"
                data={mentions}
                style={defaultMentionStyle}
              />
            </MentionsInput>
          </div>
        </div>
        <div className="flex-ns">
          <Select
            label="Tags"
            name="tagIds"
            mode="multiple"
            className="w-100"
            placeholder="Select Tags"
            input={{
              value: this.state.selectedTags,
              onChange: this.handleSelectChange('selectedTags'),
            }}
            filterable
          >
            {tags.map(tag => (
              <Option value={tag.id} key={tag.id}>
                {tag.name}
              </Option>
            ))}
          </Select>
        </div>
        {showDateFields && (
          <div className="flex-ns justify-between w-50-l w-100">
            <DateRange
              label="Comment Time Range"
              startValue={startTime}
              endValue={endTime}
              onClose={this.onCloseDateRange}
              shouldLimitRange
            />
          </div>
        )}
        <div className="flex justify-between">
          <Button text="Submit" onClick={this.handleSubmit} />
        </div>
      </section>
    )
  }
}

export default EditCommentForm
