import {
  fork,
  call,
  take,
  put,
  all,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllThresholdsAction,
  getThresholdAction,
  createThresholdAction,
  updateThresholdAction,
  deleteThresholdAction,
  toggleThresholdOptionAction,
  sendTestNotificationAction,
  getThresholdAssignmentsAction,
  getThresholdEditHistoryAction,
  getAllThresholdGroupsAction,
  createThresholdGroupAction,
  updateThresholdGroupAction,
  deleteThresholdGroupAction,
  createSiteThresholdAction,
  updateSiteThresholdAction,
} from '../actions/thresholdsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES, DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'
import { pickErrorMessage } from '../utils/helpers'
import { parseQueryParams, createQueryString } from '../utils/queryParams'

// handlers

function* handleGetAllThresholdsSaga(qs) {
  try {
    const payload = yield call(api.getAllThresholds, qs)
    yield put(getAllThresholdsAction.success(payload))
  } catch (error) {
    yield put(getAllThresholdsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetThresholdSaga(id) {
  try {
    const payload = yield call(api.getThresholdById, id)
    yield put(getThresholdAction.success(payload))
  } catch (error) {
    yield put(getThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateThresholdSaga(threshold) {
  try {
    const payload = yield call(api.createThreshold, threshold)
    yield put(createThresholdAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/rules/thresholds/${payload.id}`))
  } catch (error) {
    yield put(createThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateSiteThresholdSaga({ payload }) {
  try {
    const result = yield call(api.createThreshold, payload)
    yield put(createSiteThresholdAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield fork(
      handleGetAllThresholdsSaga,
      createQueryString({ perPage: 'all' })
    )
  } catch (error) {
    yield put(createSiteThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateSiteThresholdSaga({ payload }) {
  try {
    const result = yield call(api.updateThreshold, payload)
    yield put(updateSiteThresholdAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield fork(
      handleGetAllThresholdsSaga,
      createQueryString({ perPage: 'all' })
    )
  } catch (error) {
    yield put(updateSiteThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateThresholdSaga(threshold) {
  try {
    const payload = yield call(api.updateThreshold, threshold)
    yield put(updateThresholdAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/rules/thresholds/${payload.id}`))
  } catch (error) {
    yield put(updateThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteThresholdSaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteThreshold, id)

      const { page, perPage } = parseQueryParams(global.location)
      const query = {
        page: page ? parseInt(page, 10) : DEFAULT_PAGE,
        perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      }

      yield fork(handleGetAllThresholdsSaga, createQueryString(query))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleToggleSelectionSaga(threshold) {
  try {
    const payload = yield call(api.updateThreshold, threshold)
    yield put(updateThresholdAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(updateThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleTestNotificationSaga({ payload: id }) {
  try {
    const payload = yield call(api.sendTestNotification, id)

    yield put(sendTestNotificationAction.success(payload))
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_SENT_TEST_NOTIFICATION)
    )
  } catch (error) {
    yield put(sendTestNotificationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetThresholdAssignmentsSaga({ payload }) {
  try {
    const result = yield call(api.getThresholdAssignmentsById, payload)
    yield put(getThresholdAssignmentsAction.success(result))
  } catch (error) {
    yield put(getThresholdAssignmentsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetThresholdEditHistorySaga({ payload: id }) {
  try {
    const result = yield call(api.getThresholdEditHistoryById, id)
    yield put(getThresholdEditHistoryAction.success(result))
  } catch (error) {
    yield put(getThresholdEditHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllThresholdGroupsSaga({ payload }) {
  try {
    const result = yield call(api.getAllThresholdGroups, payload)

    yield put(getAllThresholdGroupsAction.success(result))
  } catch (error) {
    yield put(getAllThresholdGroupsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateThresholdGroupSaga({ payload }) {
  try {
    const result = yield call(api.createThresholdGroup, payload)
    yield put(createThresholdGroupAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))

    yield fork(handleGetAllThresholdGroupsSaga, {
      payload: createQueryString({ perPage: 'all' }),
    })

    yield put(push('/rules/groups'))
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateThresholdGroupSaga({ payload }) {
  try {
    const result = yield call(api.updateThresholdGroup, payload)
    yield put(updateThresholdGroupAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))

    yield fork(handleGetAllThresholdGroupsSaga, {
      payload: createQueryString({ perPage: 'all' }),
    })

    yield put(push('/rules/groups'))
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteThresholdGroupSaga({ payload }) {
  try {
    const confirmDeletion = yield call(
      global.confirm,
      payload.default
        ? MESSAGES.CONFIRM_THRESHOLD_GROUP_DEFAULT
        : MESSAGES.CONFIRM
    )

    if (confirmDeletion) {
      yield call(api.deleteThresholdGroup, payload.slug)
      yield put(deleteThresholdGroupAction.success())
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))

      yield fork(handleGetAllThresholdGroupsSaga, {
        payload: createQueryString({ perPage: 'all' }),
      })
    }
  } catch (error) {
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetThresholdEditHistorySaga() {
  yield takeLatest(
    getThresholdEditHistoryAction.REQUEST,
    handleGetThresholdEditHistorySaga
  )
}

function* watchGetAllThresholdsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllThresholdsAction.REQUEST)
    yield fork(handleGetAllThresholdsSaga, qs)
  }
}

function* watchGetThresholdSaga() {
  while (true) {
    const { payload } = yield take(getThresholdAction.REQUEST)
    yield fork(handleGetThresholdSaga, payload)
  }
}

function* watchCreateThresholdSaga() {
  while (true) {
    const { payload } = yield take(createThresholdAction.REQUEST)
    yield fork(handleCreateThresholdSaga, payload)
  }
}

function* watchUpdateThresholdSaga() {
  while (true) {
    const { payload } = yield take(updateThresholdAction.REQUEST)
    yield fork(handleUpdateThresholdSaga, payload)
  }
}

function* watchDeleteThresholdSaga() {
  while (true) {
    const { payload } = yield take(deleteThresholdAction.REQUEST)
    yield fork(handleDeleteThresholdSaga, payload)
  }
}

function* watchToggleSelectionSaga() {
  while (true) {
    const { payload } = yield take(toggleThresholdOptionAction.REQUEST)
    yield fork(handleToggleSelectionSaga, payload)
  }
}

function* watchGetThresholdAssigmentsSaga() {
  yield takeLatest(
    getThresholdAssignmentsAction.REQUEST,
    handleGetThresholdAssignmentsSaga
  )
}

function* watchTestNotificationSaga() {
  yield takeEvery(
    sendTestNotificationAction.REQUEST,
    handleTestNotificationSaga
  )
}

function* watchGetAllThresholdGroupsSaga() {
  yield takeLatest(
    getAllThresholdGroupsAction.REQUEST,
    handleGetAllThresholdGroupsSaga
  )
}

function* watchCreateThresholdGroupSaga() {
  yield takeLatest(
    createThresholdGroupAction.REQUEST,
    handleCreateThresholdGroupSaga
  )
}

function* watchUpdateThresholdGroupSaga() {
  yield takeLatest(
    updateThresholdGroupAction.REQUEST,
    handleUpdateThresholdGroupSaga
  )
}

function* watchDeleteThresholdGroupSaga() {
  yield takeLatest(
    deleteThresholdGroupAction.REQUEST,
    handleDeleteThresholdGroupSaga
  )
}

function* watchCreateSiteThresholdSaga() {
  yield takeLatest(
    createSiteThresholdAction.REQUEST,
    handleCreateSiteThresholdSaga
  )
}

function* watchUpdateSiteThresholdSaga() {
  yield takeLatest(
    updateSiteThresholdAction.REQUEST,
    handleUpdateSiteThresholdSaga
  )
}

function* thresholdsSaga() {
  yield all([
    fork(watchGetAllThresholdsSaga),
    fork(watchGetThresholdSaga),
    fork(watchCreateThresholdSaga),
    fork(watchUpdateThresholdSaga),
    fork(watchDeleteThresholdSaga),
    fork(watchToggleSelectionSaga),
    fork(watchTestNotificationSaga),
    fork(watchGetThresholdAssigmentsSaga),
    fork(watchGetThresholdEditHistorySaga),
    fork(watchGetAllThresholdGroupsSaga),
    fork(watchCreateThresholdGroupSaga),
    fork(watchUpdateThresholdGroupSaga),
    fork(watchDeleteThresholdGroupSaga),
    fork(watchCreateSiteThresholdSaga),
    fork(watchUpdateSiteThresholdSaga),
  ])
}

export {
  thresholdsSaga as default,
  watchGetThresholdEditHistorySaga,
  watchGetAllThresholdsSaga,
  watchGetThresholdSaga,
  watchCreateThresholdSaga,
  watchUpdateThresholdSaga,
  watchDeleteThresholdSaga,
  watchToggleSelectionSaga,
  watchGetThresholdAssigmentsSaga,
  handleGetAllThresholdsSaga,
  handleGetThresholdSaga,
  handleCreateThresholdSaga,
  handleUpdateThresholdSaga,
  handleDeleteThresholdSaga,
  handleTestNotificationSaga,
  handleGetThresholdEditHistorySaga,
}
