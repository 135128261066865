import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Clipboard from 'react-clipboard.js'
import Report from './Report'
import FeatureTour from './FeatureTour'
import { message } from './common/Ant'
import { Bread, Crumb } from './common/Bread'
import Select, { Option } from './common/Select'
import DateSelector from './common/DateSelector'
import { Anchor } from './common/Anchor'
import Divider from './common/Divider'
import Button from './common/Button'
import Checkbox from './common/Checkbox'
import Input from './common/Input'
import { Spin } from './common/Ant'
import { usePrevious } from '../hooks'
import {
  getIgnoreReportJoyride,
  setIgnoreReportJoyride,
} from '../services/storage'
import { isReportTypeDisabled } from '../utils/helpers'
import { createQueryString } from '../utils/queryParams'
import { renderReportType } from '../utils/textFormatters'
import { hasRole } from '../utils/hasPermission'
import {
  ROLES,
  SENSOR_READING_TYPES,
  REPORT_SENSOR_READING_TYPES,
  REPORT_TYPES,
  MESSAGES,
  ALL,
} from '../constants'

const steps = [
  {
    target: '#select-site',
    content:
      'First, choose a Site. You may then filter by Buildings, Floors, Rooms, or Locations within that Site.',
  },
  {
    target: '#select-dates',
    content: 'Next, select a start date and end date for your Report.',
  },
  {
    target: '#select-sensors',
    content: 'Choose which sensor readings to deliver in your Report.',
  },
  {
    target: '#select-report-type',
    content:
      'Choose a type of Report. If your Report is filtered by Floors or Rooms, your options will be limited according to the selected type.',
  },
  {
    target: '#save-report',
    content:
      'Toggle to save this Report. Saving a Report allows you to share the link with others in your organization.',
  },
  {
    target: '#select-name',
    content:
      'If the report is to be saved, please add a name and select an expiration date for your Report. The default expiration date is two weeks from the current date.',
  },
]

const GenerateReport = ({
  report,
  sites,
  floors,
  locationGroups,
  buildings,
  locations,
  createReport,
  clearReport,
  getAllSites,
  getAllBuildings,
  getAllFloors,
  getAllLocationsBySite,
  getAllLocationGroups,
  isLoading,
}) => {
  const [siteSlug, setSiteSlug] = useState()
  const [floorsList, setFloorsList] = useState([])
  const [locationGroupsList, setLocationGroupsList] = useState([])
  const [locationsList, setLocationsList] = useState([])
  const [buildingsList, setBuildingsList] = useState([])
  const [reportType, setReportType] = useState(REPORT_TYPES.LOCATIONS_PER_GRAPH)
  const [graphType, setGraphType] = useState([
    SENSOR_READING_TYPES.TEMPERATURE,
    SENSOR_READING_TYPES.HUMIDITY,
  ])
  const [endTime, setEndTime] = useState(moment())
  const [startTime, setStartTime] = useState(
    moment(endTime).subtract(7, 'days')
  )
  const [shouldSave, setShouldSave] = useState(false)
  const [name, setName] = useState('')
  const [expiresAt, setExpiresAt] = useState(moment().add(2, 'weeks'))
  const [includeFlowMonitor, setIncludeFlowMonitor] = useState(true)
  const [includeWeatherData, setIncludeWeatherData] = useState(false)
  const [ignoreFeatureTour, setIgnoreFeatureTour] = useState(false)

  useEffect(() => {
    getAllSites(createQueryString({ perPage: 'all' }))

    return function willUnmount() {
      clearReport()
    }
  }, [getAllSites, clearReport])

  useEffect(() => {
    if (siteSlug) {
      getAllFloors(siteSlug)
      getAllBuildings(siteSlug)
      getAllLocationsBySite(siteSlug)
      getAllLocationGroups({
        siteSlug,
        qs: createQueryString({ perPage: ALL }),
      })
    }
  }, [
    getAllFloors,
    getAllBuildings,
    getAllLocationsBySite,
    siteSlug,
    getAllLocationGroups,
  ])

  useEffect(() => {
    setIgnoreFeatureTour(getIgnoreReportJoyride())
  }, [])

  const prevLocationGroupsListLength = usePrevious(locationGroupsList.length)

  useEffect(() => {
    if (
      (locationGroupsList.length > 0 && prevLocationGroupsListLength === 0) ||
      (locationGroupsList.length === 0 && prevLocationGroupsListLength > 0)
    ) {
      setReportType(REPORT_TYPES.LOCATIONS_PER_GRAPH)
    }
  }, [locationGroupsList.length, prevLocationGroupsListLength])

  const handleSubmit = () => {
    clearReport()

    createReport({
      siteSlug,
      floorsList,
      locationGroupsList,
      locationsList,
      buildingsList,
      toTimestamp: parseInt(endTime.valueOf() / 1000, 10),
      fromTimestamp: parseInt(startTime.valueOf() / 1000, 10),
      graphType,
      reportType,
      save: shouldSave,
      name,
      expiresAt: expiresAt.toISOString(),
      includeFlowMonitor,
      graphWeather: includeWeatherData,
    })
  }

  const handleSetStartTime = startTime => {
    setStartTime(startTime)

    const maxRange = moment(startTime).add(3, 'month')

    if (endTime.isAfter(maxRange)) {
      setEndTime(maxRange)
    }
  }

  const handleSetEndTime = endTime => {
    const maxRange = moment(startTime).add(3, 'month')

    if (endTime.isAfter(maxRange)) {
      setEndTime(maxRange)
    } else {
      setEndTime(endTime)
    }
  }

  const reportUrl = `${process.env.REACT_APP_BASE_URL}/reports/${report.url}/view`

  const handleIgnore = ({ action }) => {
    if (action === 'skip') {
      setIgnoreReportJoyride(true)
    }
  }

  return (
    <section className="mb5 GenerateReport">
      <FeatureTour
        steps={steps}
        run={!ignoreFeatureTour}
        handleIgnore={handleIgnore}
      />
      <div className="mb3">
        <Bread>
          <Crumb>
            <Anchor to="/reports">Reports</Anchor>
          </Crumb>
          <Crumb>Generate Historical Report</Crumb>
        </Bread>
      </div>
      <Divider />
      <div className="mb3">
        <div id="select-site">
          <div className="flex-l items-center-l">
            <Select
              className="w-100"
              label="Site"
              placeholder="Select a site"
              input={{
                value: siteSlug,
                onChange: x => {
                  setSiteSlug(x)
                  setFloorsList([])
                  setLocationGroupsList([])
                  setLocationsList([])
                },
              }}
              filterable
            >
              {sites.map(x => (
                <Option value={x.slug} key={x.slug}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex-l items-center-l">
            <Select
              className="w-100"
              label="Buildings"
              placeholder="Select Buildings (Default: All Buildings)"
              input={{
                value: buildingsList,
                onChange: x => setBuildingsList(x),
              }}
              filterable
              disabled={!siteSlug}
              mode="multiple"
            >
              {buildings.map(x => (
                <Option value={x.id} key={x.id}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex-l items-center-l">
            <Select
              className="w-100"
              label="Floors"
              placeholder="Select Floors (Default: All Floors)"
              input={{
                value: floorsList,
                onChange: x => setFloorsList(x),
              }}
              filterable
              disabled={!siteSlug}
              mode="multiple"
            >
              {floors.map(x => (
                <Option value={x.id} key={x.id}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex-l items-center-l">
            <Select
              className="w-100"
              label="Rooms"
              placeholder="Select Rooms (Default: All Rooms)"
              input={{
                value: locationGroupsList,
                onChange: x => setLocationGroupsList(x),
              }}
              filterable
              disabled={!siteSlug}
              mode="multiple"
            >
              {locationGroups.map(x => (
                <Option value={x.slug} key={x.slug}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex-l items-center-l">
            <Select
              className="w-100"
              label="Locations"
              placeholder="Select Locations (Default: All Locations)"
              input={{
                value: locationsList,
                onChange: x => setLocationsList(x),
              }}
              filterable
              disabled={!siteSlug}
              mode="multiple"
            >
              {locations.map(x => (
                <Option value={x.id} key={x.id}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Divider />
        <div id="select-dates">
          <div className="flex-l items-center-l">
            <DateSelector
              className="w-50-l mr3-l"
              label="Start Date"
              input={{
                value: startTime,
                onChange: handleSetStartTime,
              }}
              disableTime
              disabledDateRangeType="after"
            />
            <DateSelector
              className="w-50-l"
              label="End Date"
              input={{
                value: endTime,
                onChange: handleSetEndTime,
              }}
              disableTime
              disabledDateRangeType="after"
            />
          </div>
          <div className="f7 mb3">
            Note: Reports can contain a maximum of{' '}
            <span className="b">three months</span> of data.
          </div>
        </div>
        <Divider />
        <div className="flex-l items-center-l">
          <div id="select-sensors" className="w-50-l mr3-l">
            <Select
              label="Sensor Readings (Four Max)"
              placeholder="Select sensor readings"
              input={{
                value: graphType,
                onChange: x => x.length < 5 && setGraphType(x),
              }}
              filterable
              mode="multiple"
            >
              {REPORT_SENSOR_READING_TYPES.filter(
                x =>
                  x.value !== 'micCount' ||
                  hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN)
              ).map(x => (
                <Option value={x.value} key={x.value}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </div>
          <div id="select-report-type" className="w-50-l">
            <Select
              label="Report Type"
              placeholder="Select a report type"
              input={{
                value: reportType,
                onChange: x => setReportType(x),
              }}
              filterable
            >
              {Object.keys(REPORT_TYPES).map(x => (
                <Option
                  value={x}
                  key={x}
                  disabled={isReportTypeDisabled(
                    x,
                    !!locationGroupsList.length
                  )}
                >
                  {renderReportType(x)}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex-l items-center-l">
          <Checkbox
            className="mr4"
            type="checkbox"
            label="Include Water Monitors"
            input={{
              checked: includeFlowMonitor,
              onChange: setIncludeFlowMonitor,
            }}
          />
          <Checkbox
            type="checkbox"
            label="Include Weather Data"
            input={{
              checked: includeWeatherData,
              onChange: setIncludeWeatherData,
            }}
          />
        </div>
        <Divider />
        <div className="flex-l items-center-l" id="select-name">
          <Input
            label="Name"
            className="w-50-l mr3"
            placeholder="Report Name"
            disabled={!shouldSave}
            input={{
              value: name,
              onChange: ev => setName(ev.currentTarget.value),
            }}
          />
          <DateSelector
            className="w-50-l"
            label="Public Link Expiration Date"
            input={{
              value: expiresAt,
              onChange: x => setExpiresAt(x),
            }}
            disabledDateRangeType="before"
            disableTime
            disabled={!shouldSave}
          />
        </div>
        <div className="flex-ns justify-end items-center-ns">
          <Button
            className="mr3-ns mb3 mb0-ns"
            text="Submit"
            onClick={handleSubmit}
          />
          <div id="save-report">
            <Checkbox
              type="checkbox"
              label="Save Report"
              noMargin
              input={{
                checked: shouldSave,
                onChange: setShouldSave,
              }}
            />
          </div>
        </div>
      </div>
      <Divider />
      {report.url && (
        <div>
          <div className="mb3">
            <span className="b">Copy Link: </span>
            <Clipboard
              component="a"
              data-clipboard-text={reportUrl}
              onSuccess={() => message.success(MESSAGES.COPIED)}
              className="pt1 br3 fs-normal"
            >
              {reportUrl}
            </Clipboard>
          </div>
          <Divider />
        </div>
      )}

      {isLoading ? (
        <Spin size="large" className="w-100 center mv5" />
      ) : (
        <Report {...report} />
      )}
    </section>
  )
}

GenerateReport.propTypes = {
  getAllSites: PropTypes.func.isRequired,
  getAllFloors: PropTypes.func.isRequired,
  getAllBuildings: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  clearReport: PropTypes.func.isRequired,
  getAllLocationsBySite: PropTypes.func.isRequired,
  getAllLocationGroups: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default GenerateReport
