import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TopNav from '../components/TopNav'
import { logOutAction } from '../actions/authActions'
import { toggleFullWidthAction } from '../actions/uiActions'
import { getAccountProfile } from '../reducers/accountReducer'
import { isFullWidth } from '../reducers/uiReducer'

const mapStateToProps = ({ auth, account, ui }) => ({
  isAuthorized: auth.isAuthorized,
  accountProfile: getAccountProfile(account),
  isFullWidth: isFullWidth(ui),
})

const mapDispatchToProps = dispatch => ({
  handleLogOut: () => dispatch(logOutAction.request()),
  toggleFullWidth: payload => dispatch(toggleFullWidthAction(payload)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav))
