const { REACT_APP_INTERCOM_APP_ID, NODE_ENV } = process.env

const intercomFn = NODE_ENV === 'production' ? global.Intercom : () => null

const bootIntercom = () =>
  intercomFn('boot', { app_id: REACT_APP_INTERCOM_APP_ID })

const shutdownIntercom = () => intercomFn('shutdown')

const updateIntercom = () => intercomFn('update')

const setIntercomUser = user =>
  (global.intercomSettings = {
    app_id: REACT_APP_INTERCOM_APP_ID,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    company: {
      id: user.companyId,
      name: user.companyName,
    },
  })

export { bootIntercom, shutdownIntercom, updateIntercom, setIntercomUser }
