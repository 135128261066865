import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditGatewayForm from './forms/EditGatewayForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateGatewayAction } from '../actions/gatewaysActions'
import { createQueryString } from '../utils/queryParams'

class EditGateway extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllTags: PropTypes.func.isRequired,
    gateway: PropTypes.object.isRequired,
    getGateway: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getGateway, getAllAssetLocations, getAllTags } = this.props
    const qs = createQueryString({ perPage: 'all' })

    getGateway(this.props.match.params.pillarId)
    getAllAssetLocations(qs)
    getAllTags(
      createQueryString({
        perPage: 'all',
      })
    )
  }

  render() {
    const { tags, gateway, assetLocations } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/gateways">Network Gateways</Anchor>,
      'Edit Network Gateway',
    ]

    return (
      <section className="EditGateway">
        <FormHeader crumbs={crumbs} />
        <EditGatewayForm
          tags={tags}
          submitAction={updateGatewayAction}
          initialValues={gateway}
          assetLocations={assetLocations}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditGateway
