import React from 'react'
import PropTypes from 'prop-types'
import { dateTimeFormatter } from '../utils/date'

const PodAssets = ({ images }) => (
  <div className="PodAssets">
    {images.length > 0 ? (
      images.map(x => (
        <div key={x.slug} className="mb4">
          <div className="tc mb1 b">{dateTimeFormatter(x.uploadTime)}</div>
          <div className="pa1 ba b--light-gray">
            <img className="w-100 " src={x.filePath} alt={x.uploadTime} />
          </div>
        </div>
      ))
    ) : (
      <p className="tc">No Smart Pod Assets Available</p>
    )}
  </div>
)

PodAssets.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PodAssets
