import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Tooltip,
  Spin,
} from './common/Ant'
import { Bread, Crumb } from './common/Bread'
import { Anchor, ActionAnchor } from './common/Anchor'
import Divider from './common/Divider'
import SearchFilter from './common/SearchFilter'
import Select, { Option } from './common/Select'
import { hasRole, hasPermission } from '../utils/hasPermission'
import { toTitleCase } from '../utils/textFormatters'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  ROLES,
  VIEW_ALL,
  DEFAULT_PAGE,
  USER_PERMISSIONS,
  ALL,
} from '../constants'
import { configurePagination } from '../utils/helpers'

const PER_PAGE = 100

class AllUsers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.users,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: PER_PAGE,
      role: undefined,
      companyId: undefined,
      siteSlug: undefined,
    }
  }

  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    windowWidth: PropTypes.number.isRequired,
    isUserLoading: PropTypes.bool,
    getAllUsers: PropTypes.func,
    sites: PropTypes.arrayOf(PropTypes.object),
    getAllSites: PropTypes.func,
    deleteUser: PropTypes.func,
    deleteUserFromSite: PropTypes.func,
    updateQueryParams: PropTypes.func,
    url: PropTypes.string,
    meta: PropTypes.object,
    isRenderingSiteView: PropTypes.bool,
    isRenderingDashboardView: PropTypes.bool,
    resendConfirmation: PropTypes.func,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.users })
  }

  componentDidMount() {
    const { getAllUsers, getAllSites } = this.props

    const { page, perPage, role, companyId, siteSlug } = parseQueryParams(
      global.location
    )

    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : PER_PAGE,
      role,
      companyId: companyId ? parseInt(companyId, 10) : undefined,
      siteSlug,
    }

    this.setState(query)
    const allResourcesQuery = createQueryString({ perPage: ALL })
    getAllSites && getAllSites(allResourcesQuery)
    getAllUsers && getAllUsers(createQueryString(query))
  }

  handleChange = (pagination, filters, sorter) => {
    const {
      url,
      getAllUsers,
      updateQueryParams,
      isRenderingDashboardView,
      isRenderingSiteView,
    } = this.props
    const { page, perPage, role, companyId, siteSlug } = this.state

    if (
      !isRenderingDashboardView &&
      !isRenderingSiteView &&
      page !== pagination.current
    ) {
      const query = {
        page: pagination.current,
        perPage,
        role,
        companyId,
        siteSlug,
      }

      const qs = createQueryString(query)

      getAllUsers(qs)

      updateQueryParams &&
        updateQueryParams({
          pathname: url,
          search: qs,
        })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllUsers, updateQueryParams, url } = this.props
    const { page, perPage, role, companyId, siteSlug } = this.state

    const newValue = value === ALL ? undefined : value
    const query = Object.assign(
      { role, companyId, siteSlug, perPage, page },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllUsers(qs)

    updateQueryParams &&
      updateQueryParams({
        pathname: url,
        search: qs,
      })
  }

  setSearchText = searchText => this.setState({ searchText })

  handleUserExport = () => {
    const { role, companyId, siteSlug } = parseQueryParams(global.location)

    const query = {
      role,
      companyId,
      siteSlug,
      perPage: ALL,
      export: true,
    }

    this.props.getAllUsers(createQueryString(query))
  }

  render() {
    const {
      deleteUser,
      deleteUserFromSite,
      meta,
      companies,
      sites,
      windowWidth,
      isRenderingDashboardView,
      isRenderingSiteView,
      isUserLoading = false,
      resendConfirmation,
      getAllUsers,
    } = this.props
    const {
      data,
      sortedInfo,
      role,
      companyId,
      siteSlug,
      searchText,
      page,
      perPage,
    } = this.state
    const isFixedColumn = windowWidth < 850

    const query = {
      page,
      perPage,
      role,
      companyId,
      siteSlug,
    }

    const roles = Object.keys(ROLES)
    const availableRoles = hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN)
      ? roles
      : [
          'ORGANIZATION_ADMIN',
          'JOBSITE_ADMIN',
          'JOBSITE_USER',
          'CONTRACTOR',
          'SUB_CONTRACTOR',
        ]

    return (
      <section className="mb5 AllUsers">
        {!isRenderingSiteView && (
          <div className="mb3">
            <div className="mb2">
              <Bread>
                <Crumb>Users</Crumb>
              </Bread>
            </div>
            {!isRenderingDashboardView && (
              <div className="flex justify-center">
                {hasPermission(USER_PERMISSIONS.ADD_USER) && (
                  <Anchor to="/users/create">Create New</Anchor>
                )}
                {hasPermission(USER_PERMISSIONS.VIEW_LEAD) && (
                  <span>
                    <Divider vertical />
                    <Anchor to="/users/leads">View Leads</Anchor>
                  </span>
                )}
                {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                  <span>
                    <Divider vertical />
                    <ActionAnchor onClick={this.handleUserExport}>
                      Export Users
                    </ActionAnchor>
                  </span>
                )}
              </div>
            )}
          </div>
        )}
        {!isRenderingDashboardView && !isRenderingSiteView && (
          <Fragment>
            <div className="flex-l items-center-l">
              <Select
                className="w-third-l mr3-l"
                placeholder="Role"
                input={{
                  value: role,
                  onChange: this.handleFilterChange('role'),
                }}
                filterable
              >
                {['all', ...availableRoles].map(x => (
                  <Option value={x} key={x}>
                    {x !== 'all' ? toTitleCase(x) : VIEW_ALL}
                  </Option>
                ))}
              </Select>
              <Select
                className="w-third-l mr3-l"
                placeholder="Company"
                input={{
                  value: companyId,
                  onChange: this.handleFilterChange('companyId'),
                }}
                filterable
              >
                {[{ id: 'all', name: VIEW_ALL }, ...companies].map(x => (
                  <Option value={x.id} key={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>
              <Select
                className="w-third-l"
                placeholder="Site"
                input={{
                  value: siteSlug,
                  onChange: this.handleFilterChange('siteSlug'),
                }}
                filterable
              >
                {[{ id: 'all', name: VIEW_ALL, slug: 'all' }, ...sites].map(
                  x => (
                    <Option value={x.slug} key={x.slug}>
                      {x.name}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <SearchFilter
              searchText={searchText}
              setSearchText={this.setSearchText}
              getItems={getAllUsers}
              query={query}
            />
          </Fragment>
        )}
        {isUserLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{
              x:
                !isRenderingDashboardView &&
                hasPermission(USER_PERMISSIONS.EDIT_SITE_USER)
                  ? 815
                  : 550,
            }}
            bordered
            tableLayout="auto"
            pagination={
              !isRenderingDashboardView &&
              !isRenderingSiteView &&
              configurePagination({
                perPage,
                onShowSizeChange: (page, perPage) =>
                  this.handleFilterChange('perPage')(perPage),
                ...meta,
              })
            }
          >
            <Column
              title="Name"
              dataIndex="fullName"
              fixed={isFixedColumn ? 'left' : false}
              sorter={sortBy('fullName')}
              sortOrder={sortedInfo.field === 'fullName' && sortedInfo.order}
              width={200}
              render={(text, record) =>
                hasPermission(USER_PERMISSIONS.VIEW_USER) ? (
                  <Anchor to={`/users/${record.slug}`}>{text}</Anchor>
                ) : (
                  text
                )
              }
            />
            {!isRenderingDashboardView &&
              !isRenderingSiteView &&
              hasPermission(USER_PERMISSIONS.VIEW_COMPANY) && (
                <Column
                  title="Company"
                  dataIndex="companyName"
                  sorter={sortBy('companyName')}
                  sortOrder={
                    sortedInfo.field === 'companyName' && sortedInfo.order
                  }
                  width={200}
                  render={(text, record) => (
                    <Anchor to={`/companies/${record.companyId}`}>
                      {text}
                    </Anchor>
                  )}
                />
              )}
            {hasPermission(USER_PERMISSIONS.EDIT_USER) && (
              <Column
                title="Role"
                dataIndex="role"
                sorter={sortBy('role')}
                sortOrder={sortedInfo.field === 'role' && sortedInfo.order}
                width={200}
                render={text => toTitleCase(text)}
              />
            )}
            <Column
              width={100}
              title="Notifications"
              render={(text, record) => {
                const smsNotifications = record.smsNotifications ? (
                  <Tooltip title="SMS Notifications Enabled">
                    <Icon type="phone" className="green" />
                  </Tooltip>
                ) : (
                  <Tooltip title="SMS Notifications Disabled">
                    <Icon type="phone" className="light-gray" />
                  </Tooltip>
                )

                const emailNotifications = record.emailNotifications ? (
                  <Tooltip title="Email Notifications Enabled">
                    <Icon type="mail" className="green" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Email Notifications Disabled">
                    <Icon type="mail" className="light-gray" />
                  </Tooltip>
                )

                const unconfirmed =
                  record.confirmedAt === null ? (
                    <Tooltip title="Unconfirmed User">
                      <Icon type="exclamation-circle-o" className="red" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Confirmed User">
                      <Icon type="check-circle-o" className="green" />
                    </Tooltip>
                  )

                const offlineNotifications =
                  record.systemNotifications &&
                  record.gatewaySystemNotifications ? (
                    <Tooltip title="Offline Smart Pod and Network Gateway Notifications Active">
                      <Icon type="check-circle-o" className="green" />
                    </Tooltip>
                  ) : record.systemNotifications ||
                    record.gatewaySystemNotifications ? (
                    <Tooltip
                      title={
                        !record.systemNotifications
                          ? 'Offline Smart Pod Notifications Muted'
                          : 'Offline Network Gateway Notifications Muted'
                      }
                    >
                      <Icon type="warning" className="yellow" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Offline Smart Pod and Network Gateway Notifications Muted">
                      <Icon type="warning" className="orange" />
                    </Tooltip>
                  )

                return (
                  <div className="flex justify-between">
                    <span style={{ width: 20 }} className="dib">
                      {smsNotifications}
                    </span>
                    <span style={{ width: 20 }} className="dib">
                      {emailNotifications}
                    </span>
                    <span style={{ width: 20 }} className="dib">
                      {offlineNotifications}
                    </span>
                    <span style={{ width: 20 }} className="dib">
                      {unconfirmed}
                    </span>
                  </div>
                )
              }}
            />
            {!isRenderingDashboardView &&
              hasPermission(USER_PERMISSIONS.EDIT_SITE_USER) &&
              (isRenderingSiteView ? (
                <Column
                  width={100}
                  render={(text, record) => {
                    const menu = (
                      <Menu>
                        <Menu.Item key="edit-user">
                          <Anchor to={`/users/${record.slug}/edit`}>
                            Edit
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="delete-user">
                          <ActionAnchor
                            onClick={() => deleteUserFromSite(record.id)}
                          >
                            Remove
                          </ActionAnchor>
                        </Menu.Item>
                      </Menu>
                    )

                    return (
                      <Dropdown
                        key={record.id}
                        overlay={menu}
                        trigger={['click']}
                      >
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  }}
                />
              ) : (
                <Column
                  width={100}
                  render={(text, record) => {
                    const isConfirmed = record.confirmedAt !== null

                    const menu = (
                      <Menu>
                        {!isConfirmed && (
                          <Menu.Item key="resend-confirmation">
                            <ActionAnchor
                              onClick={() => resendConfirmation(record.email)}
                            >
                              Resend Confirmation
                            </ActionAnchor>
                          </Menu.Item>
                        )}

                        <Menu.Item key="edit-user">
                          <Anchor to={`/users/${record.slug}/edit`}>
                            Edit
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="delete-user">
                          <ActionAnchor onClick={() => deleteUser(record.slug)}>
                            Delete
                          </ActionAnchor>
                        </Menu.Item>
                      </Menu>
                    )

                    return (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  }}
                />
              ))}
          </Table>
        )}
      </section>
    )
  }
}

export default AllUsers
