const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gi
const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi

const messageSerializer = str => {
  if (!str) {
    return
  }

  const serialized = str
    .replace(urlRegex, '<a href="$&" target="_blank">$&</a>')
    .replace(emailRegex, '<a href="mailto:$&">$&</a>')

  return serialized
}

export default messageSerializer
