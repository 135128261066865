import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Legend = ({ children, className = '' }) => {
  const legendClass = cx('f4 fw6 ph0 mh0 mb3 lh-title', className)

  return <legend className={legendClass}>{children}</legend>
}

Legend.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Legend
