import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditEscalationPolicyForm from '../components/forms/EditEscalationPolicyForm'
import { H2 } from './common/Headers'
import { updateEscalationPolicyAction } from '../actions/escalationPoliciesActions'
import { createQueryString } from '../utils/queryParams'

class EditEscalationPolicy extends Component {
  static propTypes = {
    escalationPolicy: PropTypes.object.isRequired,
    accountProfile: PropTypes.object.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    getEscalationPolicy: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    unsetSiteUserIds: PropTypes.func.isRequired,
    updateEscalationPolicySort: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getAllSites, getEscalationPolicy, match } = this.props

    getEscalationPolicy(match.params.slug)

    getAllSites(createQueryString({ perPage: 'all' }))
  }

  render() {
    const {
      escalationPolicy,
      sites,
      siteUsers,
      getAllUsersBySite,
      unsetSiteUserIds,
      updateEscalationPolicySort,
    } = this.props

    return (
      <section className="EditEscalationPolicy">
        <H2>Edit Escalation Policy</H2>
        <EditEscalationPolicyForm
          initialValues={escalationPolicy}
          enableReinitialize
          submitAction={updateEscalationPolicyAction}
          sites={sites}
          siteUsers={siteUsers}
          getAllUsersBySite={getAllUsersBySite}
          unsetSiteUserIds={unsetSiteUserIds}
          updateEscalationPolicySort={updateEscalationPolicySort}
        />
      </section>
    )
  }
}

export default EditEscalationPolicy
