import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import Divider from '../common/Divider'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import FormError from '../common/FormError'
import { required } from '../../utils/validators'

class CreateAssetLocationForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }

  render() {
    const { handleSubmit, submitting, error, submitAction } = this.props
    const submit = handleSubmit(submitAction)

    return (
      <section className="CreateAssetLocationForm">
        <Form onSubmit={submit}>
          <Field
            name="pillarId"
            type="text"
            component={InputContainer}
            label="Pillar ID"
            validate={[required]}
            className="w-100-ns mr3-ns"
          />
          <Field
            name="description"
            type="text"
            component={InputContainer}
            label="Description"
            className="w-100-ns mr3-ns"
          />
          <FormError error={error} />
          <Divider />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/inventory/all/asset-locations" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'CreateAssetLocationForm' })(
  CreateAssetLocationForm
)
