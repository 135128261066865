import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Anchor, ActionAnchor } from './common/Anchor'
import { Table, Column, Spin } from './common/Ant'
import { createQueryString } from '../utils/queryParams'
import { Option } from './common/Select'
import SelectContainer from '../containers/common/SelectContainer'
import { H3 } from './common/Headers'
import sortBy from 'sort-by'
import { toTitleCase } from '../utils/textFormatters'

let availableSiteContractors = []
class SiteContractors extends Component {
  static propTypes = {
    addSiteContractor: PropTypes.func.isRequired,
    deleteSiteContractor: PropTypes.func.isRequired,
    siteContractors: PropTypes.arrayOf(PropTypes.object).isRequired,
    getSiteContractors: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    siteSlug: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
    }
  }

  static defaultProps = {
    companies: [],
    siteContractors: [],
  }

  componentDidMount() {
    const { getSiteContractors, getAllCompanies, siteSlug } = this.props
    getSiteContractors(siteSlug)
    getAllCompanies(createQueryString({ perPage: 'all' }))
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }

  handleAddSiteContractor = companySlug => {
    const { addSiteContractor, siteSlug } = this.props

    addSiteContractor(siteSlug, companySlug)
  }

  handleDeleteSiteContractor = contractorId => {
    const { deleteSiteContractor, siteSlug } = this.props

    deleteSiteContractor(siteSlug, contractorId)
  }

  render() {
    const { isLoading, siteContractors, companies } = this.props
    const { sortedInfo } = this.state
    if (
      availableSiteContractors.length !==
      companies.length - siteContractors.length
    ) {
      availableSiteContractors = []
      companies.forEach(company => {
        const isSiteContractor = siteContractors.find(
          cont => cont.companySlug === company.slug
        )
          ? true
          : false
        if (!isSiteContractor) {
          availableSiteContractors.push(company)
        }
      })
    }

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : (
      <Fragment>
        <H3>Site Contractors</H3>
        <div className="mb2">
          <SelectContainer
            label="Add Contractor to Site"
            placeholder="Select a contractor"
            input={{
              onChange: this.handleAddSiteContractor,
            }}
            filterable
          >
            {availableSiteContractors.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </SelectContainer>
        </div>
        <Table
          onChange={this.handleChange}
          dataSource={siteContractors}
          rowKey="id"
          scroll={{ x: 350 }}
          pagination={false}
          bordered
          tableLayout="auto"
        >
          <Column
            title="Company Name"
            width={200}
            dataIndex="companyName"
            sorter={sortBy('companyName')}
            sortOrder={sortedInfo.field === 'companyName' && sortedInfo.order}
            render={(text, record) =>
              text ? (
                <Anchor to={`/companies/${record.companyId}`}>{text}</Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Company Type"
            width={100}
            dataIndex="companyType"
            sorter={sortBy('companyType')}
            sortOrder={sortedInfo.field === 'companyType' && sortedInfo.order}
            render={(text, record) => (text ? toTitleCase(text) : '--')}
          />
          <Column
            width={50}
            dataIndex="id"
            render={(text, record) => (
              <ActionAnchor
                onClick={() => this.handleDeleteSiteContractor(record.id)}
              >
                Remove
              </ActionAnchor>
            )}
          />
        </Table>
      </Fragment>
    )
  }
}

export default SiteContractors
