import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import { Spin } from '../common/Ant'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { required, isNumeric, isAlphaNumeric } from '../../utils/validators'
import { H3 } from '../common/Headers'
import { WATER_VALVE_TYPES, WATER_VALVE_STATUS } from '../../constants'
import { toTitleCase } from '../../utils/textFormatters'

class EditWaterValveForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    isWaterValveLoading: PropTypes.bool,
    error: PropTypes.string,
  }

  render() {
    const {
      submitAction,
      handleSubmit,
      submitting,
      isWaterValveLoading,
      error,
      initialValues,
    } = this.props

    const currentValveStatus =
      initialValues && initialValues.valveStatus
        ? initialValues.valveStatus
        : ''
    const submit = handleSubmit(submitAction)

    return isWaterValveLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EditWaterValveForm">
        <Form onSubmit={submit}>
          <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
            <H3>Water Valve Info </H3>
            <div className="flex-ns">
              <Field
                name="valveSerialId"
                type="text"
                component={InputContainer}
                label="Serial id"
                validate={[required, isAlphaNumeric]}
                className="mr3-ns w-third-ns"
              />
              <Field
                name="valveType"
                component={SelectContainer}
                label="Valve type"
                className="w-third-ns mr3-ns"
                validate={[required]}
                placeholder="Select a Valve Type"
                filterable
              >
                {Object.keys(WATER_VALVE_TYPES).map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(WATER_VALVE_TYPES[x])}
                  </Option>
                ))}
              </Field>
              <Field
                name="valveStatus"
                component={SelectContainer}
                label="Valve status"
                className="w-third-ns"
                validate={[required]}
                placeholder="Select a Valve Status"
                filterable
              >
                {// only allow select valve status to change
                // NOT ALLOWED: x->MAINTENANCE or OPEN->CLOSE or CLOSE->OPEN
                Object.keys(WATER_VALVE_STATUS)
                  .filter(v =>
                    currentValveStatus === WATER_VALVE_STATUS.OPEN
                      ? v === WATER_VALVE_STATUS.OPEN ||
                        v === WATER_VALVE_STATUS.MISSING
                      : currentValveStatus === WATER_VALVE_STATUS.CLOSE
                      ? v === WATER_VALVE_STATUS.CLOSE ||
                        v === WATER_VALVE_STATUS.MISSING
                      : v !== WATER_VALVE_STATUS.MAINTENANCE
                  )
                  .map(x => (
                    <Option value={x} key={x}>
                      {toTitleCase(WATER_VALVE_STATUS[x])}
                    </Option>
                  ))}
              </Field>
            </div>
            <div className="flex-ns">
              <Field
                name="pipeOd"
                type="text"
                component={InputContainer}
                label="Pipe OD"
                validate={[required, isNumeric]}
                className="mr3-ns w-third-ns"
              />
              <Field
                name="pipeId"
                type="text"
                component={InputContainer}
                label="Pipe ID"
                validate={isNumeric}
                className="mr3-ns w-third-ns"
              />
              <Field
                name="valveDescription"
                type="text"
                component={InputContainer}
                label="Description"
                className="w-third-ns"
              />
            </div>
          </div>
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/inventory/all/water-valves" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditWaterValveForm' })(EditWaterValveForm)
