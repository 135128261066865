import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Divider from './common/Divider'
import Input from './common/Input'
import Select, { Option } from './common/Select'
import Button from './common/Button'
import Checkbox from './common/Checkbox'
import FormError from './common/FormError'
import { H3 } from './common/Headers'
import { renderThresholdGroupType } from '../utils/textFormatters'
import { createQueryString } from '../utils/queryParams'
import { THRESHOLD_GROUP_TYPES, ALL } from '../constants'

const EditThresholdGroup = ({
  thresholdGroup,
  createThresholdGroup,
  updateThresholdGroup,
  getAllSites,
  getAllCompanies,
  getAllThresholds,
  sites,
  companies,
  thresholds,
  setThresholdGroup,
  match: { url, params },
}) => {
  const isNewGroup = !thresholdGroup

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [siteSlug, setSiteSlug] = useState()
  const [companySlug, setCompanySlug] = useState()
  const [thresholdSlugs, setThresholdSlugs] = useState([])
  const [isActive, setIsActive] = useState(true)
  const [groupType, setGroupType] = useState()
  const [isDefault, setIsDefault] = useState(false)

  const [error, setError] = useState('')

  useEffect(() => {
    if (!isNewGroup) {
      const {
        name,
        description,
        siteSlug,
        companySlug,
        thresholdSlugs,
        active: isActive,
        groupType,
        default: isDefault,
      } = thresholdGroup

      setName(name)
      setDescription(description)
      setSiteSlug(siteSlug || undefined)
      setCompanySlug(companySlug || undefined)
      setThresholdSlugs(thresholdSlugs)
      setIsActive(isActive)
      setGroupType(groupType)
      setIsDefault(isDefault)
    }

    if (url !== '/rules/groups/create' && isNewGroup) {
      setThresholdGroup(params.slug)
    }
  }, [thresholdGroup, isNewGroup, url, setThresholdGroup, params.slug])

  useEffect(() => {
    const qs = createQueryString({ perPage: ALL })
    getAllSites(qs)
    getAllCompanies(qs)
    getAllThresholds(qs)
  }, [getAllSites, getAllCompanies, getAllThresholds])

  const handleSubmit = () => {
    if (!name) {
      return setError('Name is required.')
    } else if (!thresholdSlugs.length) {
      return setError('Thresholds are required.')
    }

    const reqBody = {
      name,
      description,
      siteSlug: siteSlug || '',
      companySlug: companySlug || '',
      thresholdSlugs,
      active: isActive,
      groupType,
      default: isDefault,
    }

    isNewGroup
      ? createThresholdGroup(reqBody)
      : updateThresholdGroup({
          ...reqBody,
          slug: thresholdGroup.slug,
        })

    setThresholdGroup()
  }

  return (
    <section className="mb3 EditThresholdGroup">
      <H3>Edit Threshold Group</H3>
      <div className="mb3">
        <div className="flex-l items-center-l">
          <Input
            label="Name"
            className="w-100"
            placeholder="Name"
            input={{
              value: name,
              onChange: ev => setName(ev.currentTarget.value),
            }}
          />
        </div>
        <div className="flex-l items-center-l">
          <Input
            label="Description"
            className="w-100"
            placeholder="Description"
            input={{
              value: description,
              onChange: ev => setDescription(ev.currentTarget.value),
            }}
          />
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Site"
            placeholder="Restrict this group to use with a single Site"
            input={{
              value: siteSlug,
              onChange: setSiteSlug,
            }}
            filterable
            allowClear
          >
            {sites.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Company"
            placeholder="Restrict this group to use with a single Company"
            input={{
              value: companySlug,
              onChange: setCompanySlug,
            }}
            filterable
            allowClear
          >
            {companies.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Thresholds"
            placeholder="Select thresholds to be in this group"
            input={{
              value: thresholdSlugs,
              onChange: setThresholdSlugs,
            }}
            filterable
            mode="multiple"
          >
            {thresholds.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Group Type"
            placeholder="Select a group type"
            input={{
              value: groupType,
              onChange: setGroupType,
            }}
            filterable
          >
            {Object.keys(THRESHOLD_GROUP_TYPES).map(x => (
              <Option
                value={THRESHOLD_GROUP_TYPES[x]}
                key={THRESHOLD_GROUP_TYPES[x]}
              >
                {renderThresholdGroupType(x)}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Checkbox
            type="checkbox"
            label="Use as Default for Selected Group"
            noMargin
            disabled={!groupType}
            input={{
              checked: isDefault,
              onChange: setIsDefault,
            }}
          />
        </div>
      </div>
      <Divider />
      <div className="mb3">
        <FormError error={error} />
        <div className="flex-ns justify-between items-center-ns">
          <Checkbox
            type="checkbox"
            label="Active"
            noMargin
            input={{
              checked: isActive,
              onChange: setIsActive,
            }}
          />
          <Button
            className="mr3-ns mb3 mb0-ns"
            text="Submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </section>
  )
}

EditThresholdGroup.propTypes = {
  createThresholdGroup: PropTypes.func.isRequired,
  updateThresholdGroup: PropTypes.func.isRequired,
  thresholdGroup: PropTypes.object,
  getAllSites: PropTypes.func.isRequired,
  getAllCompanies: PropTypes.func.isRequired,
  getAllThresholds: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  thresholds: PropTypes.arrayOf(PropTypes.object),
  setThresholdGroup: PropTypes.func.isRequired,
  match: PropTypes.shape({ url: PropTypes.string, params: PropTypes.object })
    .isRequired,
}

export default EditThresholdGroup
