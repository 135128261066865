import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import { Spin } from './common/Ant'
import { H3 } from './common/Headers'
import { toTitleCase } from '../utils/textFormatters'
import { ALL_SUPPLY_TYPES } from '../constants'

class GatewaySupply extends Component {
  static propTypes = {
    gatewaySupply: PropTypes.object.isRequired,
    getGatewaySupply: PropTypes.func.isRequired,
    isGatewaySupplyLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    this.props.getGatewaySupply(this.props.match.params.id)
  }

  render() {
    const { gatewaySupply, isGatewaySupplyLoading, match } = this.props

    return isGatewaySupplyLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Gateway Supply mb3">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/inventory/all">Inventory</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to={`/inventory/all/gateway-supplies`}>
                  Network Gateway Supplies
                </Anchor>
              </Crumb>
              <Crumb>{toTitleCase(ALL_SUPPLY_TYPES[gatewaySupply.name])}</Crumb>
            </Bread>
          </div>
          <div className="flex justify-center mb3">
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          </div>
          <Divider />
        </div>
        <H3> Network Gateway Supplies Details</H3>
        <div className="flex flex-wrap">
          <Block label="Network Gateway Supply Type">
            {toTitleCase(ALL_SUPPLY_TYPES[gatewaySupply.name])}
          </Block>
          <Block label="Quantity">{gatewaySupply.quantity}</Block>
          <Block label="Description">{gatewaySupply.description}</Block>
        </div>
      </section>
    )
  }
}

export default GatewaySupply
