import { fork, call, takeLatest, takeEvery, put, all } from 'redux-saga/effects'
import moment from 'moment'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllAlertsAction,
  getAllGatewayAlertsAction,
  getAlertAction,
  getGatewayAlertAction,
  hideAlertAction,
  unhideAlertAction,
  closeAlertAction,
  closeGatewayAlertAction,
  acknowledgeAlertAction,
  resolveAlertAction,
  getAlertAuditLogAction,
  getLocationAlertHistoryAction,
  getLocationUptimeDataAction,
  getAllActionReportsAction,
  generateAlertExportAction,
  batchUpdateAlertsAction,
} from '../actions/alertsActions'
import { getLocationChartDataAction } from '../actions/locationsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { createQueryString } from '../utils/queryParams'
import { MESSAGES, ALERT_TYPES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllAlertsSaga({ payload }) {
  try {
    const result = yield call(api.getAllAlerts, payload)

    yield put(getAllAlertsAction.success(result))
  } catch (error) {
    yield put(getAllAlertsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetLocationAlertHistorySaga({ payload }) {
  try {
    const { siteSlug, floorId, locationId, qs } = payload

    const result = yield call(
      api.getLocationAlertHistory,
      siteSlug,
      floorId,
      locationId,
      qs
    )

    yield put(getLocationAlertHistoryAction.success(result))
  } catch (error) {
    yield put(getLocationAlertHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetLocationUptimeDataSaga({ payload }) {
  try {
    const { siteSlug, floorId, locationId, qs } = payload

    const result = yield call(
      api.getLocationUptimeData,
      siteSlug,
      floorId,
      locationId,
      qs
    )

    yield put(getLocationUptimeDataAction.success(result))
  } catch (error) {
    yield put(getLocationUptimeDataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllGatewayAlertsSaga({ payload }) {
  try {
    const result = yield call(api.getAllGatewayAlerts, payload)
    yield put(getAllGatewayAlertsAction.success(result))
  } catch (error) {
    yield put(getAllGatewayAlertsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAlertSaga({ payload: slug }) {
  try {
    const result = yield call(api.getAlertBySlug, slug)

    yield put(getAlertAction.success(result))

    let startValue
    let endValue
    if (result.alertType === ALERT_TYPES.THRESHOLD) {
      startValue = result.lagTime
        ? moment(result.lagTime)
        : result.startTime
        ? moment(result.startTime)
        : moment().subtract(6, 'h')
      endValue = result.leadTime
        ? moment(result.leadTime)
        : result.endTime
        ? moment(result.endTime)
        : moment()
    } else {
      startValue = moment(result.startTime).subtract(24, 'hours')
      endValue = moment(result.startTime)
    }

    const query = {
      startTime: startValue.unix(),
      endTime: endValue.unix(),
      smooth: 'f',
    }
    const qs = createQueryString(query)

    yield put(
      getLocationChartDataAction.request({
        siteSlug: result.siteSlug,
        floorId: result.floorId,
        id: result.locationId,
        qs,
      })
    )
  } catch (error) {
    yield put(getAlertAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetGatewayAlertSaga({ payload: slug }) {
  try {
    const result = yield call(api.getGatewayAlertBySlug, slug)

    yield put(getGatewayAlertAction.success(result))
  } catch (error) {
    yield put(getGatewayAlertAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleHideAlertSaga({ payload: id }) {
  try {
    const result = yield call(api.hideAlert, id)
    yield put(hideAlertAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_HID_ALERT))
  } catch (error) {
    yield put(hideAlertAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUnhideAlertSaga({ payload: id }) {
  try {
    const result = yield call(api.unhideAlert, id)

    yield put(unhideAlertAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UNHID_ALERT))
  } catch (error) {
    yield put(unhideAlertAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCloseAlertSaga({ payload: id }) {
  try {
    const result = yield call(api.closeAlert, id)

    yield put(closeAlertAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CLOSE_ALERT))
  } catch (error) {
    yield put(closeAlertAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCloseGatewayAlertSaga({ payload: slug }) {
  try {
    const result = yield call(api.closeGatewayAlert, slug)

    yield put(closeGatewayAlertAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CLOSE_ALERT))
  } catch (error) {
    yield put(closeGatewayAlertAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAcknowledgeAlertSaga({ payload }) {
  try {
    const result = yield call(api.acknowledgeAlert, payload)

    yield put(acknowledgeAlertAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ACKNOWLEDGE_ALERT))
  } catch (error) {
    yield put(acknowledgeAlertAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleResolveAlertSaga({ payload }) {
  try {
    const result = yield call(api.resolveAlert, payload)

    yield put(resolveAlertAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_RESOLVE_ALERT))
  } catch (error) {
    yield put(resolveAlertAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAlertAuditLogSaga({ payload: id }) {
  try {
    const result = yield call(api.getAlertAuditLog, id)

    yield put(getAlertAuditLogAction.success(result))
  } catch (error) {
    yield put(getAlertAuditLogAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllActionReportsSaga({ payload }) {
  try {
    const result = yield call(api.getAllActionReports, payload)

    yield put(getAllActionReportsAction.success(result))
  } catch (error) {
    yield put(getAllActionReportsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGenerateAlertExportSaga({ payload }) {
  try {
    yield call(api.generateAlertExport, payload)

    yield put(generateAlertExportAction.success())
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_GENERATED_ALERT_EXPORT)
    )
  } catch (error) {
    yield put(generateAlertExportAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleBatchUpdateAlertsSaga({ payload }) {
  try {
    const result = yield call(api.batchUpdateAlerts, payload)

    yield put(batchUpdateAlertsAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(batchUpdateAlertsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAlertAuditLogSaga() {
  yield takeEvery(getAlertAuditLogAction.REQUEST, handleGetAlertAuditLogSaga)
}

function* watchGetAllAlertsSaga() {
  yield takeEvery(getAllAlertsAction.REQUEST, handleGetAllAlertsSaga)
}

function* watchGetLocationAlertHistorySaga() {
  yield takeEvery(
    getLocationAlertHistoryAction.REQUEST,
    handleGetLocationAlertHistorySaga
  )
}

function* watchGetLocationUptimeDataSaga() {
  yield takeEvery(
    getLocationUptimeDataAction.REQUEST,
    handleGetLocationUptimeDataSaga
  )
}

function* watchGetAllGatewayAlertsSaga() {
  yield takeEvery(
    getAllGatewayAlertsAction.REQUEST,
    handleGetAllGatewayAlertsSaga
  )
}

function* watchGetAlertSaga() {
  yield takeLatest(getAlertAction.REQUEST, handleGetAlertSaga)
}

function* watchGetGatewayAlertSaga() {
  yield takeLatest(getGatewayAlertAction.REQUEST, handleGetGatewayAlertSaga)
}

function* watchHideAlertSaga() {
  yield takeLatest(hideAlertAction.REQUEST, handleHideAlertSaga)
}

function* watchUnhideAlertSaga() {
  yield takeLatest(unhideAlertAction.REQUEST, handleUnhideAlertSaga)
}

function* watchCloseAlertSaga() {
  yield takeLatest(closeAlertAction.REQUEST, handleCloseAlertSaga)
}

function* watchCloseGatewayAlertSaga() {
  yield takeLatest(closeGatewayAlertAction.REQUEST, handleCloseGatewayAlertSaga)
}

function* watchAcknowledgeAlertSaga() {
  yield takeLatest(acknowledgeAlertAction.REQUEST, handleAcknowledgeAlertSaga)
}

function* watchResolveAlertSaga() {
  yield takeLatest(resolveAlertAction.REQUEST, handleResolveAlertSaga)
}

function* watchGetAllActionReportsSaga() {
  yield takeLatest(
    getAllActionReportsAction.REQUEST,
    handleGetAllActionReportsSaga
  )
}

function* watchGenerateAlertExportSaga() {
  yield takeLatest(
    generateAlertExportAction.REQUEST,
    handleGenerateAlertExportSaga
  )
}

function* watchBatchUpdateAlertsSaga() {
  yield takeEvery(batchUpdateAlertsAction.REQUEST, handleBatchUpdateAlertsSaga)
}

function* alertsSaga() {
  yield all([
    fork(watchGetAllAlertsSaga),
    fork(watchGetAllGatewayAlertsSaga),
    fork(watchGetAlertSaga),
    fork(watchHideAlertSaga),
    fork(watchUnhideAlertSaga),
    fork(watchAcknowledgeAlertSaga),
    fork(watchResolveAlertSaga),
    fork(watchCloseAlertSaga),
    fork(watchGetAlertAuditLogSaga),
    fork(watchGetGatewayAlertSaga),
    fork(watchGetLocationAlertHistorySaga),
    fork(watchGetLocationUptimeDataSaga),
    fork(watchCloseGatewayAlertSaga),
    fork(watchGetAllActionReportsSaga),
    fork(watchGenerateAlertExportSaga),
    fork(watchBatchUpdateAlertsSaga),
  ])
}

export {
  alertsSaga as default,
  handleGetAllAlertsSaga,
  handleGetAllGatewayAlertsSaga,
  handleGetAlertSaga,
  handleGetGatewayAlertSaga,
  handleHideAlertSaga,
  handleUnhideAlertSaga,
  handleCloseAlertSaga,
  handleGetAlertAuditLogSaga,
  handleGetLocationAlertHistorySaga,
  handleGetLocationUptimeDataSaga,
  handleCloseGatewayAlertSaga,
  handleAcknowledgeAlertSaga,
  handleResolveAlertSaga,
  watchGetAllAlertsSaga,
  watchGetAllGatewayAlertsSaga,
  watchGetAlertSaga,
  watchHideAlertSaga,
  watchUnhideAlertSaga,
  watchCloseAlertSaga,
  watchAcknowledgeAlertSaga,
  watchResolveAlertSaga,
  watchGetAlertAuditLogSaga,
  watchGetGatewayAlertSaga,
  watchGetLocationAlertHistorySaga,
  watchGetLocationUptimeDataSaga,
  watchCloseGatewayAlertSaga,
  watchGetAllActionReportsSaga,
  watchGenerateAlertExportSaga,
  watchBatchUpdateAlertsSaga,
}
