import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { unauthorizedAction } from '../actions/authActions'
import { getAllLeadsAction } from '../actions/leadsActions'
import { showErrorMessageAction } from '../actions/uiActions'
import api from '../services/api'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllLeadsSaga({ payload }) {
  try {
    const result = yield call(api.getAllLeads, payload)

    yield put(getAllLeadsAction.success(result))
  } catch (error) {
    yield put(getAllLeadsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllLeadsSaga() {
  yield takeLatest(getAllLeadsAction.REQUEST, handleGetAllLeadsSaga)
}

function* leadsSaga() {
  yield all([fork(watchGetAllLeadsSaga)])
}

export { leadsSaga as default, watchGetAllLeadsSaga, handleGetAllLeadsSaga }
