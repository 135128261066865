import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditFloorForm from './forms/EditFloorForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateFloorAction } from '../actions/floorsActions'

class EditFloor extends Component {
  static propTypes = {
    currentFloor: PropTypes.object.isRequired,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    getSite: PropTypes.func.isRequired,
    getAllBuildings: PropTypes.func.isRequired,
    getFloor: PropTypes.func.isRequired,
    removeBuildingFromFloor: PropTypes.func.isRequired,
    siteName: PropTypes.string,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getSite, getAllBuildings, getFloor, match, siteName } = this.props
    const { floorId, siteSlug } = match.params

    getFloor(siteSlug, floorId)
    getAllBuildings(siteSlug)

    if (!siteName) {
      getSite(siteSlug)
    }
  }

  render() {
    const {
      currentFloor,
      buildings,
      removeBuildingFromFloor,
      match,
      siteName,
    } = this.props
    const { siteSlug } = match.params

    const currentBuildingIndex =
      currentFloor.buildingId &&
      buildings.map(x => x.id).indexOf(currentFloor.buildingId)
    const crumbs = [
      <Anchor to="/sites">Sites</Anchor>,
      siteName && <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>,
      !!buildings[currentBuildingIndex] && (
        <Anchor to={`/sites/${siteSlug}/buildings/${currentFloor.buildingId}`}>
          {buildings[currentBuildingIndex].name}
        </Anchor>
      ),
      'Edit Floor',
    ]

    return (
      <section className="EditFloor">
        <FormHeader crumbs={crumbs} />
        <EditFloorForm
          submitAction={updateFloorAction}
          initialValues={currentFloor}
          siteSlug={siteSlug}
          buildings={buildings}
          buildingId={currentFloor.buildingId}
          removeBuildingFromFloor={removeBuildingFromFloor}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditFloor
