import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, RadioButton, RadioGroup } from './common/Ant'
import Form from './common/Form'
import Divider from './common/Divider'
import Input from './common/Input'
import Select, { Option } from './common/Select'
import Button from './common/Button'
import FormError from './common/FormError'
import Label from './common/Label'
import { H3 } from './common/Headers'
import { normalizePhoneNumber, formatPhoneNumber } from '../utils/forms'

const PHONE_CALL_TYPE = {
  USER: 'USER',
  NUMBER: 'NUMBER',
}

const BridgePhoneCallModal = ({
  getUsers,
  users,
  isModalVisible,
  handleModalCancel,
  createBridgedPhoneCall,
}) => {
  const [phoneCallType, setPhoneCallType] = useState(PHONE_CALL_TYPE.USER)
  const [userSlug, setUserSlug] = useState(null)
  const [userPhoneNumber, setUserPhoneNumber] = useState('')
  const [error, setError] = useState('')

  const handleCancel = () => {
    handleModalCancel()
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!userSlug && !userPhoneNumber) {
      setError('Please select a user or enter a phone number to call.')

      return
    }

    setError('')

    createBridgedPhoneCall(normalizePhoneNumber(userPhoneNumber), userSlug)
    setUserSlug(null)
    setUserPhoneNumber('')
    handleCancel()
  }

  const handleSearch = value => {
    value && getUsers(value)
  }

  return (
    <Modal
      className="Modal"
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
    >
      <H3>Place Phone Call</H3>
      <Form onSubmit={handleSubmit}>
        <Label>Would you like to call a user or enter a phone number?</Label>
        <RadioGroup
          onChange={({ target: { value } }) => setPhoneCallType(value)}
          value={phoneCallType}
          className="mb3"
          size="small"
        >
          <RadioButton value={PHONE_CALL_TYPE.USER}>User</RadioButton>
          <RadioButton value={PHONE_CALL_TYPE.NUMBER}>Phone Number</RadioButton>
        </RadioGroup>
        {phoneCallType === PHONE_CALL_TYPE.USER ? (
          <Select
            label="Select a user to call"
            placeholder="Users"
            onSearch={handleSearch}
            input={{
              value: userSlug,
              onChange: value => setUserSlug(value),
            }}
            filterable
          >
            {users.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.firstName} {x.lastName}
              </Option>
            ))}
          </Select>
        ) : (
          <Input
            type="text"
            label="Phone Number"
            input={{
              placeholder: 'Enter a phone number to call',
              value: userPhoneNumber,
              onChange: ev =>
                setUserPhoneNumber(formatPhoneNumber(ev.currentTarget.value)),
            }}
          />
        )}
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <Button text="Submit" type="submit" />
        </div>
      </Form>
    </Modal>
  )
}

BridgePhoneCallModal.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  handleModalCancel: PropTypes.func.isRequired,
  createBridgedPhoneCall: PropTypes.func.isRequired,
}

export default BridgePhoneCallModal
