import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { register } from './serviceWorker'
import bowser from 'bowser/bundled'
import * as Sentry from '@sentry/browser'
import moment from 'moment-timezone'
import Highcharts from 'highcharts'
import PillarProvider, {
  handleServiceWorkerUpdate,
} from './components/PillarProvider'
import { getTimeZone } from './utils/date'
import { version } from '../package.json'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
})

const { browser, os } = bowser.parse(global.navigator.userAgent)
const isIE =
  browser.name === 'Internet Explorer' && parseInt(browser.version, 10) <= 11
const isUnsupportedIE =
  browser.name === 'Internet Explorer' && parseInt(browser.version, 10) < 11

Sentry.setTag('athena_version', version)

if (isIE) {
  Sentry.setTag('browser_name', browser.name)
  Sentry.setTag('browser_version', browser.version)
  Sentry.setTag('os_name', os.name)
  Sentry.setTag('os_version', os.version)
  Sentry.setTag('os_version_name', os.versionName)
}

if (isUnsupportedIE) {
  const rootEl = document.getElementById('root')
  const ieMessageEl = document.getElementById('ie-message')

  rootEl.style.display = 'none'
  ieMessageEl.style.display = 'block'
} else {
  render(<PillarProvider />, document.getElementById('root'))
}

global.moment = moment

Highcharts.setOptions({
  time: {
    timezone: getTimeZone(),
  },
})

register({ onUpdate: handleServiceWorkerUpdate })
