import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import { configurePagination } from '../utils/helpers'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { formatPhoneNumber } from '../utils/forms'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'

const AllSupportPhoneNumbers = ({
  getAllSupportPhoneNumbers,
  deleteSupportPhoneNumber,
  phoneNumbers,
  meta,
  isCommunicationLoading,
  updateQueryParams,
}) => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [sortedInfo, setSortedInfo] = useState({})

  useEffect(() => {
    const { page, perPage } = parseQueryParams(global.location)

    const nextPage = page ? parseInt(page, 10) : DEFAULT_PAGE
    const nextPerPage = perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE
    getAllSupportPhoneNumbers(
      createQueryString({
        page: nextPage,
        perPage: nextPerPage,
      })
    )

    setPage(nextPage)
    setPerPage(nextPerPage)
  }, [getAllSupportPhoneNumbers])

  useEffect(() => {
    setData(phoneNumbers)
  }, [data, phoneNumbers])

  const handleChange = (pagination, filters, sorter) => {
    const query = { page: pagination.current, perPage }
    const qs = createQueryString(query)
    getAllSupportPhoneNumbers(qs)

    setPage(pagination.current)
    setSortedInfo(sorter)

    updateQueryParams({
      search: qs,
    })
  }

  const handleFilterChange = keyName => value => {
    const query = Object.assign({ page, perPage })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      setPage(DEFAULT_PAGE)
    }

    const qs = createQueryString({ ...query, [keyName]: value })
    getAllSupportPhoneNumbers(qs)

    updateQueryParams({
      search: qs,
    })

    switch (keyName) {
      case 'perPage':
        setPerPage(value)
    }
  }

  return (
    <div className="AllSupportPhoneNumbers mb5">
      {isCommunicationLoading ? (
        <Spin size="large" className="w-100 center mv5" />
      ) : (
        <div>
          <Anchor
            to="/communications/phone-numbers/create"
            button
            className="mr3 mb3 dib"
          >
            Create New
          </Anchor>
          <Table
            bordered
            dataSource={data}
            onChange={handleChange}
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            tableLayout="auto"
            rowKey="slug"
          >
            <Column
              title="Name"
              dataIndex="assignedUserName"
              sorter={sortBy('assignedUserName')}
              sortOrder={
                sortedInfo.field === 'assignedUserName' && sortedInfo.order
              }
              render={(text, record) => (
                <Anchor to={`/users/${record.assignedUserSlug}`}>{text}</Anchor>
              )}
            />
            <Column
              title="Phone Number"
              dataIndex="phoneNumber"
              sorter={sortBy('phoneNumber')}
              sortOrder={sortedInfo.field === 'phoneNumber' && sortedInfo.order}
              render={text => formatPhoneNumber(text)}
            />
            <Column
              render={(text, record) => {
                const menu = (
                  <Menu>
                    <Menu.Item key="edit-phone-number">
                      <Anchor
                        to={`/communications/phone-numbers/${record.slug}/edit`}
                      >
                        Edit
                      </Anchor>
                    </Menu.Item>
                    <Menu.Item key="delete-phone-number">
                      <ActionAnchor
                        onClick={() => deleteSupportPhoneNumber(record.slug)}
                      >
                        Delete
                      </ActionAnchor>
                    </Menu.Item>
                  </Menu>
                )

                return (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link">
                      Actions <Icon type="down" />
                    </a>
                  </Dropdown>
                )
              }}
            />
          </Table>
        </div>
      )}
    </div>
  )
}

AllSupportPhoneNumbers.propTypes = {
  getAllSupportPhoneNumbers: PropTypes.func.isRequired,
  deleteSupportPhoneNumber: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.arrayOf(PropTypes.object).isRequired,
  meta: PropTypes.object.isRequired,
  isCommunicationLoading: PropTypes.bool.isRequired,
  updateQueryParams: PropTypes.func.isRequired,
}

export default AllSupportPhoneNumbers
