import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { dateTimeFormatter } from '../utils/date'
import { Table, Column, Spin } from './common/Ant'
import { createQueryString } from '../utils/queryParams'
import { THRESHOLD_DATA_SOURCES } from '../constants'

const renderSensorName = source => {
  switch (source) {
    case THRESHOLD_DATA_SOURCES.TEMPERATURE:
      return 'Temperature'
    case THRESHOLD_DATA_SOURCES.PRESSURE:
      return 'Pressure'
    case THRESHOLD_DATA_SOURCES.HUMIDITY:
      return 'Humidity'
    case THRESHOLD_DATA_SOURCES.DUST:
      return 'Dust'
    case THRESHOLD_DATA_SOURCES.SOUND:
      return 'Sound'
    case THRESHOLD_DATA_SOURCES.VOC:
      return 'VOC (Alpha)'
    case THRESHOLD_DATA_SOURCES.CARBON_MONOXIDE:
      return 'Carbon Monoxide'
    case THRESHOLD_DATA_SOURCES.MIC_COUNT:
      return 'Noise Count (> 85 dBA)'
    case THRESHOLD_DATA_SOURCES.DEW_POINT:
      return 'Dew Point'
    case THRESHOLD_DATA_SOURCES.WATER_FLOW:
      return 'Water Flow'
    case THRESHOLD_DATA_SOURCES.LEAK_EVENT:
      return 'Leak Event'
    case THRESHOLD_DATA_SOURCES.LEAK_STATE:
      return 'Leak State'
    default:
      return '--'
  }
}

const CalibrationHistory = ({
  getCalibrationHistory,
  podPillarId,
  calibrationHistory,
  isLoading,
}) => {
  useEffect(() => {
    getCalibrationHistory(podPillarId, createQueryString({ perPage: 'all' }))
  }, [getCalibrationHistory, podPillarId])

  // username	"Axel Anderson"
  // calibration_data	"1121"
  // rmse	-1

  return isLoading ? (
    <Spin size="large" className="w-100 center mt5" />
  ) : calibrationHistory.length ? (
    <Table
      dataSource={calibrationHistory}
      rowKey="id"
      scroll={{ x: 750 }}
      pagination={false}
      tableLayout="auto"
      bordered
    >
      <Column
        title="Identifier"
        width={100}
        dataIndex="testIdentifier"
        render={text => (text ? text : '--')}
      />
      <Column
        title="Date Tested"
        width={100}
        dataIndex="testDate"
        render={text => (text ? dateTimeFormatter(text) : '--')}
      />
      <Column
        title="Sensor"
        width={100}
        dataIndex="sensorName"
        render={text => (text ? renderSensorName(text) : '--')}
      />
      <Column
        title="Calibration Data"
        width={100}
        dataIndex="calibrationData"
        render={text => (text ? text : '--')}
      />
      <Column
        title="RMSE"
        width={100}
        dataIndex="rmse"
        render={text => (text ? text : '--')}
      />
      <Column
        title="Tested By"
        width={100}
        dataIndex="username"
        render={text => (text ? text : '--')}
      />
    </Table>
  ) : (
    'No Calibration History'
  )
}

CalibrationHistory.propTypes = {
  calibrationHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCalibrationHistory: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  podPillarId: PropTypes.string.isRequired,
}

export default CalibrationHistory
