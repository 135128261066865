import React from 'react'
import PropTypes from 'prop-types'
import EditUserForm from '../components/forms/EditUserForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createUserAction } from '../actions/usersActions'

const CreateUser = ({ companies }) => {
  const crumbs = [<Anchor to="/users">Users</Anchor>, 'Create User']

  return (
    <section className="CreateUser">
      <FormHeader crumbs={crumbs} />
      <EditUserForm companies={companies} submitAction={createUserAction} />
    </section>
  )
}

CreateUser.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CreateUser
