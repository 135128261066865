import { connect } from 'react-redux'
import { replace, push } from 'connected-react-router'
import DashboardPage from '../components/pages/DashboardPage'
import {
  getAllSiteImuNanoIotAction,
  getImuDataAction,
  getAdminDashboardAction,
  getUserDashboardAction,
} from '../actions/dashboardActions'
import {
  getAllLocationsByFloorAction,
  getLocationSnapshotAction,
} from '../actions/locationsActions'
import { getAllFloorSummariesAction } from '../actions/floorsActions'
import { getSiteGatewaysAction } from '../actions/gatewaysActions'
import {
  getVisibleThresholdAlertsById,
  getVisibleGatewayAlertsById,
} from '../reducers/alertsReducer'
import { getCurrentSite, getVisibleSites } from '../reducers/sitesReducer'
import { getVisibleBuildings } from '../reducers/buildingsReducer'
import { getAccountProfile } from '../reducers/accountReducer'
import { getVisibleFloorsWithSummaries } from '../reducers/floorsReducer'
import {
  getVisibleLocations,
  getLocationSnapshot,
} from '../reducers/locationsReducer'
import { getVisibleSiteGateways } from '../reducers/gatewaysReducer'
import {
  getWindowWidth,
  isFloorLoading,
  isLocationLoading,
  isSiteLoading,
  isHistoryLoading,
  isChartDataLoading,
  isAlertLoading,
} from '../reducers/uiReducer'
import {
  getDashboardCounts,
  getVisibleAllImuNanoIots,
  getVisibleImuData,
} from '../reducers/dashboardReducer'
import { getVisibleDeployments } from '../reducers/deploymentsReducer'

const mergeSiteDeploymentsAndGatewayAlerts = (sites, deployments, alerts) => {
  const activeSites = getVisibleSites(sites)
  const allActiveDeployments = getVisibleDeployments(deployments)

  const allActiveGatewayAlerts = getVisibleGatewayAlertsById(alerts)

  return activeSites.map(x => {
    const activeDeployments = allActiveDeployments.filter(
      y => y.siteSlug === x.slug
    )

    const activeGatewayAlerts = allActiveGatewayAlerts.filter(
      z => z.siteSlug === x.slug
    )

    return { ...x, activeDeployments, activeGatewayAlerts }
  })
}

const getFloorsWithGatewayCounts = (floors, sites) => {
  const allFloors = getVisibleFloorsWithSummaries(floors)
  const currentSite = getCurrentSite(sites)

  const floorsWithGatewayCounts = currentSite.gateways
    ? allFloors.map(floor => {
        const floorGateways = currentSite.gateways.filter(
          gateway => gateway.floorId === floor.id
        )
        const offlineGatewayCount = floorGateways.filter(
          gateway => !gateway.online
        ).length
        const onlineGatewayCount = floorGateways.filter(
          gateway => gateway.online
        ).length
        return { offlineGatewayCount, onlineGatewayCount, ...floor }
      })
    : allFloors

  return floorsWithGatewayCounts
}

const mapStateToProps = ({
  alerts,
  sites,
  buildings,
  floors,
  locations,
  account,
  ui,
  dashboard,
  deployments,
  gateways,
}) => ({
  thresholdAlerts: getVisibleThresholdAlertsById(alerts),
  gatewayAlerts: getVisibleGatewayAlertsById(alerts),
  buildings: getVisibleBuildings(buildings),
  floors: getFloorsWithGatewayCounts(floors, sites),
  locations: getVisibleLocations(locations),
  snapshotData: getLocationSnapshot(locations),
  currentSite: getCurrentSite(sites),
  allImuNanoIots: getVisibleAllImuNanoIots(dashboard),
  imuData: getVisibleImuData(dashboard),
  accountProfile: getAccountProfile(account),
  windowWidth: getWindowWidth(ui),
  counts: getDashboardCounts(dashboard),
  isFloorLoading: isFloorLoading(ui),
  isLocationLoading: isLocationLoading(ui),
  isSiteLoading: isSiteLoading(ui),
  isHistoryLoading: isHistoryLoading(ui),
  isChartDataLoading: isChartDataLoading(ui),
  isAlertLoading: isAlertLoading(ui),
  allActiveSites: mergeSiteDeploymentsAndGatewayAlerts(
    sites,
    deployments,
    alerts
  ),
  siteGateways: getVisibleSiteGateways(gateways),
})

const mapDispatchToProps = dispatch => ({
  getAdminDashboard: () => dispatch(getAdminDashboardAction.request()),
  getUserDashboard: siteSlug =>
    dispatch(getUserDashboardAction.request(siteSlug)),
  getAllImuNanoIots: () => dispatch(getAllSiteImuNanoIotAction.request()),
  getImuData: (macAddress, qs) =>
    dispatch(getImuDataAction.request({ macAddress, qs })),
  updateQueryParams: location => dispatch(replace(location)),
  getAllLocationsByFloor: (siteSlug, floorId) =>
    dispatch(getAllLocationsByFloorAction.request({ siteSlug, floorId })),
  getAllFloorSummaries: siteSlug =>
    dispatch(getAllFloorSummariesAction.request({ siteSlug })),
  pushToPath: floorId =>
    dispatch(push(floorId ? `/dashboard/${floorId}` : '/dashboard')),
  getLocationSnapshot: (siteSlug, floorId, locationId) =>
    dispatch(
      getLocationSnapshotAction.request({
        siteSlug,
        floorId,
        locationId,
      })
    ),
  getAllGatewaysBySite: siteSlug =>
    dispatch(getSiteGatewaysAction.request(siteSlug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
