import React from 'react'

const Meta = props => {
  const { touched, error, warning } = props

  if (error || warning) {
    return (
      touched &&
      ((error && <div className="red">{error}</div>) ||
        (warning && <div className="orange">{warning}</div>))
    )
  }

  return null
}

export default Meta
