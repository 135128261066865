import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import { Spin } from './common/Ant'
import { Table, Column } from './common/Ant'
import { H3, H4 } from './common/Headers'
import { dateFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'

class Purchase extends Component {
  static propTypes = {
    purchase: PropTypes.object.isRequired,
    getPurchase: PropTypes.func.isRequired,
    isPurchaseLoading: PropTypes.bool,
    match: PropTypes.object,
  }

  componentDidMount() {
    this.props.getPurchase(this.props.match.params.id)
  }

  render() {
    const { purchase, isPurchaseLoading, match } = this.props

    return isPurchaseLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Purchase mb3">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/inventory/all">Inventory</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to={`/inventory/all/purchases`}>Purchases</Anchor>
              </Crumb>
            </Bread>
          </div>
          <div className="flex justify-center mb3">
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          </div>
          <Divider />
        </div>
        <H3> Purchase Details</H3>
        <div className="flex flex-wrap">
          <Block label="Purchase ID">{purchase['name']}</Block>
          <Block label="Status">
            {purchase['status'] && toTitleCase(purchase['status'])}
          </Block>
          <Block label="Purchase Cost">{purchase['cost']}</Block>
          <Block label="Date Ordered">
            {purchase['dateOrdered'] && dateFormatter(purchase['dateOrdered'])}
          </Block>
          <Block label="Date Delivered">
            {purchase['dateDelivered'] &&
              dateFormatter(purchase['dateDelivered'])}
          </Block>
          <Block label="Purchase Cost">{purchase['cost']}</Block>
          <Block label="Number of Bom Parts">
            {purchase['numberBomParts']}
          </Block>
          <Block label="Number of Asset Locations">
            {purchase['numberAssetLocations']}
          </Block>
          <Block label="Description">{purchase['description']}</Block>
        </div>
        <Divider />
        <H4>BOM Part</H4>
        <Table
          dataSource={purchase.bomParts}
          rowKey="id"
          scroll={{ x: 650 }}
          bordered
          tableLayout="auto"
          pagination={false}
        >
          <Column
            title="Pillar P/N"
            dataIndex="pillarId"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Manufacturer P/N"
            dataIndex="manufacturerPn"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Vendor P/N"
            dataIndex="vendorPn"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Cost"
            dataIndex="cost"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Quantity Requested"
            dataIndex="quantityRequested"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Quantity Delivered"
            dataIndex="quantityDelivered"
            width={300}
            render={text => (text ? text : '--')}
          />
        </Table>
        <H4> Asset Locations</H4>
        <Table
          dataSource={purchase.assetLocations}
          rowKey="id"
          scroll={{ x: 650 }}
          bordered
          tableLayout="auto"
          pagination={false}
        >
          <Column
            title="Asset Location Name"
            dataIndex="pillarId"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Part Quantity Added"
            dataIndex="partQuantityAdded"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Part Name"
            dataIndex="partName"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Pillar P/N"
            dataIndex="partPillarId"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Manufacturer P/N"
            dataIndex="manufacturerPn"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Vendor P/N"
            dataIndex="vendorPn"
            width={300}
            render={text => (text ? text : '--')}
          />
        </Table>
      </section>
    )
  }
}

export default Purchase
