import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditAlertSummaryForm from '../components/forms/EditAlertSummaryForm'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import { updateAlertSummaryAction } from '../actions/alertSummariesActions'

class EditAlertSummary extends Component {
  static propTypes = {
    getAllSites: PropTypes.func.isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    getAggregateThresholdsBySite: PropTypes.func.isRequired,
    getAlertSummaryBySlug: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    alertSummary: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.getAlertSummaryBySlug(this.props.match.params.slug)
  }

  render() {
    const {
      getAllSites,
      getAllUsersBySite,
      getAggregateThresholdsBySite,
      sites,
      users,
      thresholds,
      formValues,
      alertSummary,
    } = this.props

    // fetching all Alert Summaries does not return the thresholdIds or
    // siteUserIds keys so we have to check for it here before rendering the
    // EditAlertSummaryForm
    const initialValues = alertSummary.thresholdIds
      ? alertSummary
      : { ...alertSummary, thresholdIds: [], siteUserIds: [] }

    return (
      <section className="EditAlertSummary">
        <div className="mb3">
          <Bread>
            <Crumb>
              <Anchor to="/reports">Reports</Anchor>
            </Crumb>
            <Crumb>
              <Anchor to="/reports/alert-summaries">Alert Summaries</Anchor>
            </Crumb>
            <Crumb>{alertSummary.description}</Crumb>
          </Bread>
        </div>
        <EditAlertSummaryForm
          submitAction={updateAlertSummaryAction}
          getAllSites={getAllSites}
          getAllUsersBySite={getAllUsersBySite}
          getAggregateThresholdsBySite={getAggregateThresholdsBySite}
          sites={sites}
          users={users}
          thresholds={thresholds}
          initialValues={initialValues}
          formValues={formValues}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditAlertSummary
