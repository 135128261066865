import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import SearchFilter from './common/SearchFilter'
import Select, { Option } from './common/Select'
import { hasPermission } from '../utils/hasPermission'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  USER_PERMISSIONS,
  VIEW_ALL,
  WATER_VALVE_STATUS,
  WATER_VALVE_TYPES,
} from '../constants'
import { toTitleCase } from '../utils/textFormatters'
import { configurePagination } from '../utils/helpers'

class AllWaterValves extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.waterValves,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      valveType: undefined,
      valveStatus: undefined,
    }
  }

  static propTypes = {
    getAllWaterValves: PropTypes.func.isRequired,
    waterValves: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    isWaterValveLoading: PropTypes.bool.isRequired,
    deleteWaterValve: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func,
    meta: PropTypes.object,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.waterValves })
  }

  componentDidMount() {
    const { getAllWaterValves } = this.props

    const { page, perPage, valveType, valveStatus } = parseQueryParams(
      global.location
    )
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      valveType,
      valveStatus,
    }

    this.setState(query)

    getAllWaterValves(createQueryString(query))
  }

  handleChange = (pagination, filters, sorter) => {
    const { getAllWaterValves, updateQueryParams } = this.props
    const { page, perPage, valveType, valveStatus } = this.state

    if (page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
        valveType,
        valveStatus,
      }
      const qs = createQueryString(query)

      getAllWaterValves(qs)
      updateQueryParams({ search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllWaterValves, updateQueryParams } = this.props
    const { page, perPage, valveType, valveStatus } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    const query = Object.assign(
      {
        page,
        perPage,
        valveType,
        valveStatus,
      },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    const qs = createQueryString(query)
    getAllWaterValves(qs)
    updateQueryParams({
      search: qs,
    })
  }

  setSearchText = searchText => this.setState({ searchText })

  render() {
    const {
      deleteWaterValve,
      windowWidth,
      isWaterValveLoading,
      meta,
      getAllWaterValves,
    } = this.props
    const {
      data,
      sortedInfo,
      searchText,
      page,
      perPage,
      valveType,
      valveStatus,
    } = this.state

    const isFixedColumn = windowWidth < 750

    const query = {
      page,
      perPage,
      valveType,
      valveStatus,
    }

    return (
      <section className="mb5 AllWaterValves">
        {hasPermission(USER_PERMISSIONS.ADD_FLOW_MONITOR) && (
          <Anchor
            to="/inventory/water-valves/create"
            button
            className="mr3 mb3 dib"
          >
            Create New
          </Anchor>
        )}
        <div className="flex-l items-center-l">
          <Select
            className="w-50-l mr3-l"
            placeholder="Valve Type"
            mode="multiple"
            input={{
              value: valveType,
              onChange: this.handleFilterChange('valveType'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...Object.keys(WATER_VALVE_TYPES)].map(x => (
              <Option value={x} key={x}>
                {toTitleCase(WATER_VALVE_TYPES[x]) || VIEW_ALL}
              </Option>
            ))}
          </Select>
          <Select
            className="w-50-l"
            placeholder="Status"
            mode="multiple"
            input={{
              value: valveStatus,
              onChange: this.handleFilterChange('valveStatus'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...Object.keys(WATER_VALVE_STATUS)].map(x => (
              <Option value={x} key={x}>
                {toTitleCase(WATER_VALVE_STATUS[x]) || VIEW_ALL}
              </Option>
            ))}
          </Select>
        </div>
        <SearchFilter
          searchText={searchText}
          setSearchText={this.setSearchText}
          getItems={getAllWaterValves}
          query={query}
        />
        {isWaterValveLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{ x: 650 }}
            bordered
            tableLayout="auto"
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
          >
            <Column
              title="Serial ID"
              dataIndex="valveSerialId"
              sorter={sortBy('valveSerialId')}
              sortOrder={
                sortedInfo.field === 'valveSerialId' && sortedInfo.order
              }
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Valve Type"
              dataIndex="valveType"
              sorter={sortBy('valveType')}
              sortOrder={sortedInfo.field === 'valveType' && sortedInfo.order}
              width={150}
              render={text =>
                text ? toTitleCase(WATER_VALVE_TYPES[text]) : '--'
              }
            />
            <Column
              title="Expected Valve Status"
              dataIndex="valveStatus"
              sorter={sortBy('valveStatus')}
              sortOrder={sortedInfo.field === 'valveStatus' && sortedInfo.order}
              width={150}
              render={text =>
                text ? toTitleCase(WATER_VALVE_STATUS[text]) : '--'
              }
            />
            <Column
              title="Pipe OD"
              dataIndex="pipeOd"
              sorter={sortBy('pipeOd')}
              sortOrder={sortedInfo.field === 'pipeOd' && sortedInfo.order}
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Pipe ID"
              dataIndex="pipeId"
              sorter={sortBy('pipeId')}
              sortOrder={sortedInfo.field === 'pipeId' && sortedInfo.order}
              width={150}
              render={text => (text ? text : '--')}
            />
            {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={50}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-water-valve">
                        <Anchor
                          to={`/inventory/water-valves/${record.id}/edit`}
                        >
                          Edit
                        </Anchor>
                      </Menu.Item>
                      <Menu.Item key="delete-water-valve">
                        <ActionAnchor
                          onClick={() => deleteWaterValve(record.id)}
                        >
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )
                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllWaterValves
