import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tooltip, Icon } from './common/Ant'
import Select, { Option } from './common/Select'
import { ActionAnchor } from './common/Anchor'

const mapDispatchToProps = dispatch => ({
  addEscalationPolicy: (submitAction, thresholdId, escalationPolicySlug) =>
    dispatch(submitAction.request({ thresholdId, escalationPolicySlug })),
})

class EscalationPolicySelect extends Component {
  static propTypes = {
    escalationPolicySlug: PropTypes.string,
    thresholdId: PropTypes.number.isRequired,
    escalationPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
    addEscalationPolicy: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
  }

  static defaultProps = {
    escalationPolicies: [],
  }

  state = {
    selectedSlug: this.props.escalationPolicySlug,
  }

  handleEscalationPolicyChange = value => {
    const { thresholdId, submitAction, addEscalationPolicy } = this.props

    addEscalationPolicy(submitAction, thresholdId, value)
    this.setState({ selectedSlug: value })
  }

  handleRemoveEscalationPolicy = () => {
    const { thresholdId, submitAction, addEscalationPolicy } = this.props

    addEscalationPolicy(submitAction, thresholdId, '')
    this.setState({ selectedSlug: '' })
  }

  render() {
    const { escalationPolicies } = this.props
    const { selectedSlug } = this.state

    const index = escalationPolicies
      .map(({ slug }) => slug)
      .indexOf(selectedSlug)

    if (index !== -1) {
      const { name, policyGroups } = escalationPolicies[index]

      const showWarning = policyGroups.reduce(
        (acc, { communicationModes, usersInfo }) => {
          if (communicationModes.indexOf('sms') !== -1) {
            const smsDisabled = usersInfo.filter(
              ({ smsNotifications }) => !smsNotifications
            )
            if (smsDisabled.length > 0) {
              return true
            }
          }

          if (communicationModes.indexOf('email') !== -1) {
            const emailDisabled = usersInfo.filter(
              ({ emailNotifications }) => !emailNotifications
            )
            if (emailDisabled.length > 0) {
              return true
            }
          }

          if (communicationModes.indexOf('voice') !== -1) {
            const voiceDisabled = usersInfo.filter(
              ({ voiceNotifications }) => !voiceNotifications
            )
            if (voiceDisabled.length > 0) {
              return true
            }
          }

          return false
        },
        false
      )

      return (
        <div className="flex">
          <div className="mr2 b">{name}</div>
          {showWarning && (
            <div className="mr2">
              <Tooltip title="Users in Escalation Policy have notifications muted!">
                <Icon type="exclamation-circle-o" className="orange" />
              </Tooltip>
            </div>
          )}
          <ActionAnchor onClick={this.handleRemoveEscalationPolicy}>
            (remove)
          </ActionAnchor>
        </div>
      )
    }

    return (
      <Select
        placeholder="Select an escalation policy"
        input={{
          value: selectedSlug,
          onChange: this.handleEscalationPolicyChange,
        }}
        noMargin
        filterable
      >
        {escalationPolicies.map(x => (
          <Option value={x.slug} key={x.slug}>
            {x.name}
          </Option>
        ))}
      </Select>
    )
  }
}

export default connect(null, mapDispatchToProps)(EscalationPolicySelect)
