const getCookie = name => {
  let value = ''

  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        value = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }

  return value
}

const setCookie = (name, value = '', days) => {
  let expires = ''

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = ';expires=' + date.toUTCString()
  }

  document.cookie = `${name}=${value}${expires};domain=.pillar.tech;Path=/;SameSite=Strict;Secure`
  // The line below is to test on localhost (omits "domain=" and "Secure")
  // document.cookie = `${name}=${value}${expires};Path=/;SameSite=Strict;`;
}

const removeCookie = name => (document.cookie = name + '=; Max-Age=-99999999;')

export { getCookie, setCookie, removeCookie }
