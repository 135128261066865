import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import { H4 } from './common/Headers'
import { ActionAnchor } from './common/Anchor'
import { Modal, Tooltip } from './common/Ant'
import { createQueryString } from '../utils/queryParams'
import {
  addThresholdToSiteAction,
  deleteThresholdFromSiteAction,
} from '../actions/sitesActions'
import { getAllThresholdGroupsAction } from '../actions/thresholdsActions'
import { getVisibleThresholdGroups } from '../reducers/thresholdsReducer'
import { hasPermission } from '../utils/hasPermission'
import {
  renderThresholdGroupType,
  renderThresholdGroupSVG,
} from '../utils/textFormatters'
import { USER_PERMISSIONS } from '../constants'

const mapStateToProps = ({ thresholds }) => ({
  thresholdGroups: getVisibleThresholdGroups(thresholds),
})

const mapDispatchToProps = dispatch => ({
  addThresholdToSite: (siteSlug, thresholdGroupSlug) =>
    dispatch(
      addThresholdToSiteAction.request({ siteSlug, thresholdGroupSlug })
    ),
  deleteThresholdFromSite: (siteSlug, thresholdId) =>
    dispatch(deleteThresholdFromSiteAction.request({ siteSlug, thresholdId })),
  getAllThresholdGroups: qs =>
    dispatch(getAllThresholdGroupsAction.request(qs)),
})

const Group = ({ group, hasModifyPermissions, handleModalOpen, groupsLen }) => {
  const [isActive, setIsActive] = useState(false)
  const { name, description, active, groupType } = group

  useEffect(() => {
    setIsActive(active)
  }, [active])

  const prefix = cx(
    {
      'bg-near-white b--light-gray': !isActive,
      'bg-blue white b--light-blue': isActive,
      'pointer dim shadow-1': hasModifyPermissions,
    },
    'flex-row items-center space-between tc ba br2 pa3 mh3'
  )

  return (
    <div
      className={cx('Group w-100 w-50-m mb4', {
        'w-25-l': groupsLen === 8,
        'w-33-l': groupsLen % 3 === 0,
        'w-50-l':
          groupsLen === 7 ||
          groupsLen === 5 ||
          groupsLen === 4 ||
          groupsLen === 2,
      })}
    >
      <Tooltip title={description}>
        <div
          className={prefix}
          style={{ height: '100%' }}
          onClick={() => hasModifyPermissions && handleModalOpen(group)}
        >
          <div className="f3 lh-title mv3">{name}</div>
          <img
            src={renderThresholdGroupSVG(groupType, !isActive)}
            alt={renderThresholdGroupType(groupType)}
            className="w3"
          />
          <div className="f4 lh-title mb3">
            {isActive ? <span className="b">Enabled</span> : 'Disabled'}
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

Group.propTypes = {
  group: PropTypes.object.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  hasModifyPermissions: PropTypes.bool.isRequired,
  groupsLen: PropTypes.number.isRequired,
}

const AssignThresholdGroups = ({
  getAllThresholdGroups,
  addThresholdToSite,
  deleteThresholdFromSite,
  thresholdGroups,
  siteSlug,
  assignedThresholds,
}) => {
  const [groups, setGroups] = useState([])
  const [hasAdditionalThresholds, setHasAdditionalThresholds] = useState(false)
  const [focusedGroup, setFocusedGroup] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)

  const hasModifyPermissions = hasPermission(
    USER_PERMISSIONS.ADD_SITE_THRESHOLD
  )

  useEffect(() => {
    getAllThresholdGroups(createQueryString({ onlyDefaults: true }))
  }, [getAllThresholdGroups])

  useEffect(() => {
    const assignedSlugs = assignedThresholds.map(x => x.thresholdGroupSlug)

    setGroups(
      thresholdGroups
        .filter(x => x.default)
        .map(x => {
          const index = assignedSlugs.indexOf(x.slug)
          const threshold = assignedThresholds[index]

          return {
            ...x,
            active: index !== -1,
            thresholdMappingId: threshold ? threshold.id : null,
          }
        })
    )
  }, [thresholdGroups, assignedThresholds])

  useEffect(() => {
    const assignedGroups = groups.filter(x => x.active)

    setHasAdditionalThresholds(
      assignedGroups.length !== assignedThresholds.length
    )
  }, [groups, assignedThresholds])

  const activeGroups = groups.filter(x => x.active)

  const handleModalOpen = group => {
    setIsModalVisible(true)
    setFocusedGroup(group)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
    setFocusedGroup({})
  }

  const handleChange = nextValue => {
    if (nextValue) {
      addThresholdToSite(siteSlug, focusedGroup.slug)
    } else {
      deleteThresholdFromSite(siteSlug, focusedGroup.thresholdMappingId)
    }
  }

  return (
    <div className="AssignThresholdGroups pa2 mb3">
      <H4>Assign Threshold Groups</H4>
      <div className="mb4">
        <p className="f5">
          There{' '}
          {activeGroups.length > 1 || activeGroups.length === 0 ? 'are' : 'is'}{' '}
          currently <span className="b">{activeGroups.length}</span> group
          {activeGroups.length > 1 || activeGroups.length === 0 ? 's ' : ' '}
          assigned on your site.
        </p>
        {groups.length > 0 && hasAdditionalThresholds && (
          <p className="bg-light-yellow ph3 pv2 br2 dark-gray b">
            Your site has customized Thresholds assigned to it in addition to
            any groups enabled here.
          </p>
        )}
      </div>
      <div className="flex flex-wrap">
        {groups.map(x => (
          <Group
            key={x.slug}
            siteSlug={siteSlug}
            addThresholdToSite={addThresholdToSite}
            deleteThresholdFromSite={deleteThresholdFromSite}
            group={x}
            hasModifyPermissions={hasModifyPermissions}
            handleModalOpen={handleModalOpen}
            groupsLen={groups.length}
          />
        ))}
      </div>
      <Modal
        className="Modal"
        visible={isModalVisible}
        footer={null}
        onCancel={handleModalCancel}
        destroyOnClose
      >
        <div
          className={cx(
            {
              'bg-near-white b--light-gray': !focusedGroup.active,
              'bg-blue white b--light-blue': focusedGroup.active,
            },
            'flex-row items-center space-between tc ba br2 pa3 ma4'
          )}
        >
          <div className="f3 lh-title mv3">{focusedGroup.name}</div>
          <img
            src={renderThresholdGroupSVG(
              focusedGroup.groupType,
              !focusedGroup.active
            )}
            alt={renderThresholdGroupType(focusedGroup.groupType)}
            className="w3"
          />
          <div className="mb3">{focusedGroup.description}</div>
          <div className="mb3">
            <H4>Thresholds</H4>
            <ul className="list pl0">
              {!!focusedGroup.thresholds &&
                focusedGroup.thresholds.map(x => <li>{x.name}</li>)}
            </ul>
          </div>
        </div>
        <div className="mb3 flex justify-center">
          <ActionAnchor
            button
            onClick={() => handleChange(!focusedGroup.active)}
            invert={focusedGroup.active}
          >
            {focusedGroup.active ? (
              'Disable'
            ) : (
              <span className="b f5 pa2">Enable</span>
            )}
          </ActionAnchor>
        </div>
      </Modal>
    </div>
  )
}

AssignThresholdGroups.propTypes = {
  siteSlug: PropTypes.string,
  getAllThresholdGroups: PropTypes.func.isRequired,
  addThresholdToSite: PropTypes.func.isRequired,
  deleteThresholdFromSite: PropTypes.func.isRequired,
  thresholdGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  assignedThresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const AssignThresholdGroupsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignThresholdGroups)

export default AssignThresholdGroupsContainer
