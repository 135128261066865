import { createFormAction } from 'redux-form-saga'

const getAllEscalationPoliciesAction = createFormAction(
  'GET_ALL_ESCALATION_POLICIES'
)
const createEscalationPolicyAction = createFormAction(
  'CREATE_ESCALATION_POLICY'
)
const getEscalationPolicyAction = createFormAction('GET_ESCALATION_POLICY')
const updateEscalationPolicyAction = createFormAction(
  'UPDATE_ESCALATION_POLICY'
)
const deleteEscalationPolicyAction = createFormAction(
  'DELETE_ESCALATION_POLICY'
)

export {
  getAllEscalationPoliciesAction,
  createEscalationPolicyAction,
  getEscalationPolicyAction,
  updateEscalationPolicyAction,
  deleteEscalationPolicyAction,
}
