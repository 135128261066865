import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllGatewaySuppliesAction,
  // getAllAvailableGatewaySuppliesAction,
  getGatewaySupplyAction,
  createGatewaySupplyAction,
  updateGatewaySupplyAction,
  deleteGatewaySupplyAction,
} from '../actions/gatewaySuppliesActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllGatewaySuppliesSaga(qs) {
  try {
    const payload = yield call(api.getAllGatewaySupplies, qs)
    yield put(getAllGatewaySuppliesAction.success(payload))
  } catch (error) {
    yield put(getAllGatewaySuppliesAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// function* handleGetAllAvailableGatewaySuppliesSaga() {
//   try {
//     const payload = yield call(api.getAllAvailableGatewaySupplies)
//     yield put(getAllAvailableGatewaySuppliesAction.success(payload))
//   } catch (error) {
//     yield put(getAllAvailableGatewaySuppliesAction.failure())
//     yield put(showErrorMessageAction(pickErrorMessage(error)))

//     if (error.status === 401) {
//       yield put(unauthorizedAction())
//     }
//   }
// }

function* handleGetGatewaySupplySaga(id) {
  try {
    const payload = yield call(api.getGatewaySupply, id.payload)
    yield put(getGatewaySupplyAction.success(payload))
  } catch (error) {
    yield put(getGatewaySupplyAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateGatewaySupplySaga({ payload }) {
  try {
    const result = yield call(api.createGatewaySupply, payload)

    yield put(createGatewaySupplyAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/inventory/gateway-supplies/${result.id}`))
  } catch (error) {
    yield put(createGatewaySupplyAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateGatewaySupplySaga({ payload }) {
  try {
    const result = yield call(api.updateGatewaySupply, payload, payload['id'])
    yield put(updateGatewaySupplyAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/gateway-supplies/${result.id}`))
  } catch (error) {
    yield put(updateGatewaySupplyAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteGatewaySupplySaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteGatewaySupply, id.payload)
      yield fork(handleGetAllGatewaySuppliesSaga)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteGatewaySupplyAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllGatewaySuppliesSaga() {
  yield takeLatest(
    getAllGatewaySuppliesAction.REQUEST,
    handleGetAllGatewaySuppliesSaga
  )
}

// function* watchGetAllAvailableGatewaySuppliesSaga() {
//   yield takeLatest(getAllAvailableGatewaySuppliesAction.REQUEST, handleGetAllAvailableGatewaySuppliesSaga)
// }

function* watchGetGatewaySupplySaga() {
  yield takeLatest(getGatewaySupplyAction.REQUEST, handleGetGatewaySupplySaga)
}

function* watchCreateGatewaySupplySaga() {
  yield takeLatest(
    createGatewaySupplyAction.REQUEST,
    handleCreateGatewaySupplySaga
  )
}

function* watchUpdateGatewaySupplySaga() {
  yield takeLatest(
    updateGatewaySupplyAction.REQUEST,
    handleUpdateGatewaySupplySaga
  )
}

function* watchDeleteGatewaySupplySaga() {
  yield takeLatest(
    deleteGatewaySupplyAction.REQUEST,
    handleDeleteGatewaySupplySaga
  )
}

function* gatewaySuppliesSaga() {
  yield all([
    fork(watchGetAllGatewaySuppliesSaga),
    // fork(watchGetAllAvailableGatewaySuppliesSaga),
    fork(watchGetGatewaySupplySaga),
    fork(watchCreateGatewaySupplySaga),
    fork(watchUpdateGatewaySupplySaga),
    fork(watchDeleteGatewaySupplySaga),
  ])
}

export {
  gatewaySuppliesSaga as default,
  watchGetAllGatewaySuppliesSaga,
  // watchGetAllAvailableGatewaySuppliesSaga,
  watchGetGatewaySupplySaga,
  watchCreateGatewaySupplySaga,
  watchUpdateGatewaySupplySaga,
  watchDeleteGatewaySupplySaga,
  handleGetAllGatewaySuppliesSaga,
  // handleGetAllAvailableGatewaySuppliesSaga,
  handleGetGatewaySupplySaga,
  handleCreateGatewaySupplySaga,
  handleUpdateGatewaySupplySaga,
  handleDeleteGatewaySupplySaga,
}
