import AntDivider from 'antd/lib/divider'
import AntInput from 'antd/lib/input'
import AntSelect from 'antd/lib/select'
import Avatar from 'antd/lib/avatar'
import Badge from 'antd/lib/badge'
import Breadcrumb from 'antd/lib/breadcrumb'
import DatePicker from 'antd/lib/date-picker'
import Dropdown from 'antd/lib/dropdown'
// import Icon from 'antd/lib/icon'
import InputNumber from 'antd/lib/input-number'
import Layout from 'antd/lib/layout'
import Menu from 'antd/lib/menu'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Slider from 'antd/lib/slider'
import Spin from 'antd/lib/spin'
import Steps from 'antd/lib/steps'
import Switch from 'antd/lib/switch'
import Table from 'antd/lib/table'
import Tag from 'antd/lib/tag'
import Tabs from 'antd/lib/tabs'
import TimePicker from 'antd/lib/time-picker'
import Tooltip from 'antd/lib/tooltip'
import Checkbox from 'antd/lib/checkbox'
import Timeline from 'antd/lib/timeline'
import Progress from 'antd/lib/progress'
import Collapse from 'antd/lib/collapse'
import message from 'antd/lib/message'
import notification from 'antd/lib/notification'
import { Icon } from '@ant-design/compatible'

import 'antd/lib/avatar/style'
import 'antd/lib/checkbox/style'
import 'antd/lib/badge/style'
import 'antd/lib/breadcrumb/style'
import 'antd/lib/date-picker/style'
import 'antd/lib/divider/style'
import 'antd/lib/dropdown/style'
import 'antd/lib/icon/style'
import 'antd/lib/input-number/style'
import 'antd/lib/input/style'
import 'antd/lib/layout/style'
import 'antd/lib/menu/style'
import 'antd/lib/message/style'
import 'antd/lib/modal/style'
import 'antd/lib/notification/style'
import 'antd/lib/radio/style'
import 'antd/lib/select/style'
import 'antd/lib/slider/style'
import 'antd/lib/spin/style'
import 'antd/lib/steps/style'
import 'antd/lib/switch/style'
import 'antd/lib/table/style'
import 'antd/lib/tag/style'
import 'antd/lib/time-picker/style'
import 'antd/lib/tooltip/style'
import 'antd/lib/tabs/style'
import 'antd/lib/timeline/style'
import 'antd/lib/progress/style'
import 'antd/lib/collapse/style'

const { Header, Content, Sider } = Layout
const { Column } = Table
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const AntCheckboxGroup = Checkbox.Group
const { Step } = Steps
const TabPane = Tabs.TabPane
const { Panel } = Collapse

export {
  AntInput,
  AntSelect,
  Avatar,
  Badge,
  Breadcrumb,
  Checkbox,
  AntCheckboxGroup,
  Column,
  Content,
  DatePicker,
  AntDivider,
  Dropdown,
  Header,
  Icon,
  InputNumber,
  Layout,
  Menu,
  Modal,
  Radio,
  RadioButton,
  RadioGroup,
  Sider,
  Slider,
  Spin,
  Steps,
  Step,
  Switch,
  Table,
  Tag,
  TimePicker,
  Tooltip,
  Tabs,
  TabPane,
  Timeline,
  Progress,
  Collapse,
  Panel,
  message,
  notification,
}
