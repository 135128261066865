import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import MainContent from '../common/MainContent'
import AllDeployments from '../AllDeployments'
import PageWidth from '../PageWidth'
import { createQueryString, parseQueryParams } from '../../utils/queryParams'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  DEPLOYMENT_STATUS,
} from '../../constants'

class DeploymentsPage extends Component {
  static propTypes = {
    getAllDeployments: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    deployments: PropTypes.arrayOf(PropTypes.object).isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.object.isRequired,
    isDeploymentLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  }

  componentDidMount() {
    const { getAllDeployments } = this.props

    const { page, perPage, status, siteSlug } = parseQueryParams(
      global.location
    )
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      status: status
        ? status
        : [
            DEPLOYMENT_STATUS.CREATED,
            DEPLOYMENT_STATUS.PACKING,
            DEPLOYMENT_STATUS.PACKED,
            DEPLOYMENT_STATUS.SHIPPED,
          ],
      siteSlug,
    }

    this.setState(query)

    const qs = createQueryString(query)

    getAllDeployments(qs)
  }

  render() {
    const {
      match: { url },
      deployments,
      sites,
      meta,
      getAllDeployments,
      getAllSites,
      updateQueryParams,
      isDeploymentLoading,
    } = this.props

    return (
      <MainContent>
        <div className="DeploymentsPage with-side-nav">
          <PageWidth>
            <Helmet>
              <title>Deployments</title>
            </Helmet>
            <AllDeployments
              getAllDeployments={getAllDeployments}
              getAllSites={getAllSites}
              updateQueryParams={updateQueryParams}
              deployments={deployments}
              sites={sites}
              meta={meta}
              url={url}
              isDeploymentLoading={isDeploymentLoading}
            />
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default DeploymentsPage
