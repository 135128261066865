import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ConfirmAccountForm from '../components/forms/ConfirmAccountForm'
import {
  setAccountConfirmationTokenAction,
  getUnconfirmedUserAction,
} from '../actions/authActions'
import { isValidToken } from '../reducers/authReducer'

const ConfirmAccountFormContainer = ({
  setAccountConfirmationToken,
  getUnconfirmedUser,
  isValidToken,
  unconfirmedUser,
  errorMessage,
  match: {
    params: { token },
  },
}) => (
  <ConfirmAccountForm
    initialValues={unconfirmedUser}
    setAccountConfirmationToken={setAccountConfirmationToken}
    getUnconfirmedUser={getUnconfirmedUser}
    isValidToken={isValidToken}
    token={token}
    errorMessage={errorMessage}
    enableReinitialize
  />
)

ConfirmAccountFormContainer.propTypes = {
  setAccountConfirmationToken: PropTypes.func.isRequired,
  getUnconfirmedUser: PropTypes.func.isRequired,
  isValidToken: PropTypes.bool.isRequired,
  unconfirmedUser: PropTypes.object.isRequired,
  token: PropTypes.string,
  errorMessage: PropTypes.string,
  match: PropTypes.object,
}

const mapStateToProps = ({ auth }) => ({
  isValidToken: isValidToken(auth),
  unconfirmedUser: auth.unconfirmedUser,
  errorMessage: auth.errorMessage,
})

const mapDispatchToProps = dispatch => ({
  setAccountConfirmationToken: token =>
    dispatch(setAccountConfirmationTokenAction(token)),
  getUnconfirmedUser: token =>
    dispatch(getUnconfirmedUserAction.request(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  ConfirmAccountFormContainer
)
