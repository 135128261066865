import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import MessageStatus from './MessageStatus'
import MessageType from './MessageType'
import MessageAttachments from './MessageAttachments'
import { dateTimeFormatter } from '../utils/date'
import { CHAT_MESSAGE_TYPES } from '../constants'

const ChatMessage = ({
  message: {
    user,
    message,
    messageType,
    createdAt,
    messagesStatus,
    attachments,
  },
  accountProfile,
  pillarParticipant,
}) => {
  const isPhoneCall = messageType === CHAT_MESSAGE_TYPES.CALL
  const isCurrentUser = user.slug === accountProfile.slug
  const prefix = cx('ChatMessage flex mb1', {
    'justify-end':
      user.slug === accountProfile.slug || user.slug === pillarParticipant.slug,
    'justify-start': user.slug !== accountProfile.slug,
  })
  const bgPrefix = cx('w-80 br2 pv1 ph2', {
    'bg-dark-green white': isPhoneCall,
    'bg-dark-blue white': !isPhoneCall && isCurrentUser,
    'bg-light-gray': !isPhoneCall && !isCurrentUser,
  })

  return (
    <li className={prefix}>
      <div className={bgPrefix}>
        <div>
          {!isPhoneCall ? (
            <span
              className="serialized-message"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          ) : (
            'Phone Call'
          )}
        </div>
        <MessageAttachments
          attachments={attachments}
          message={message}
          isPhoneCall={isPhoneCall}
        />
        <div
          className={cx(
            'flex items-center',
            isCurrentUser || isPhoneCall ? 'moon-gray' : 'gray'
          )}
          style={{ fontSize: '0.7rem' }}
          title={dateTimeFormatter(createdAt)}
        >
          {dateTimeFormatter(createdAt, true)}
          <MessageType messageType={messageType} />
          <MessageStatus messagesStatus={messagesStatus} />
        </div>
      </div>
    </li>
  )
}

ChatMessage.propTypes = {
  message: PropTypes.object.isRequired,
  accountProfile: PropTypes.object.isRequired,
  pillarParticipant: PropTypes.object,
}

ChatMessage.defaultProps = {
  pillarParticipant: {},
}

export default ChatMessage
