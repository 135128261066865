import { createFormAction } from 'redux-form-saga'

const getAllWorkflowsAction = createFormAction('GET_ALL_WORKFLOWS')
const getWorkflowAction = createFormAction('GET_WORKFLOW')
const createWorkflowAction = createFormAction('CREATE_WORKFLOW')

const SET_WORKFLOW_TYPE = 'SET_WORKFLOW_TYPE'
const setWorkflowTypeAction = payload => ({
  type: SET_WORKFLOW_TYPE,
  payload,
})

export {
  getAllWorkflowsAction,
  getWorkflowAction,
  createWorkflowAction,
  setWorkflowTypeAction,
  SET_WORKFLOW_TYPE,
}
