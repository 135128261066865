import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
// import SearchFilter from './common/SearchFilter'
import { Table, Column, Spin } from './common/Ant'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'
import { configurePagination } from '../utils/helpers'
import { toTitleCase } from '../utils/textFormatters'
import { dateTimeFormatter } from '../utils/date'

class UserCommunicationLog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.userCommunicationLog,
      // searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }
  }

  static propTypes = {
    slug: PropTypes.string.isRequired,
    userCommunicationLog: PropTypes.arrayOf(PropTypes.object).isRequired,
    getUserCommunicationLog: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    //windowWidth: PropTypes.number.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    url: PropTypes.string,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.userCommunicationLog })
  }

  componentDidMount() {
    const { page, perPage } = parseQueryParams(global.location)

    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
    }

    this.setState(query)

    const { slug, getUserCommunicationLog } = this.props
    getUserCommunicationLog(slug, createQueryString({ ...query, full: 't' }))
  }

  handleChange = (pagination, filters, sorter) => {
    const { url, slug, getUserCommunicationLog, updateQueryParams } = this.props
    const { perPage } = this.state

    const query = { page: pagination.current, perPage, full: 't' }
    const qs = createQueryString(query)
    getUserCommunicationLog(slug, qs)

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      // searchText: '',
    })

    updateQueryParams &&
      updateQueryParams({
        pathname: url,
        search: qs,
      })
  }

  handleFilterChange = keyName => value => {
    const { slug, getUserCommunicationLog, updateQueryParams } = this.props
    const { page, perPage } = this.state

    const query = Object.assign(
      { page, perPage, full: 't' },
      { [keyName]: value }
    )

    this.setState({ [keyName]: value })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }
    const qs = createQueryString(query)
    getUserCommunicationLog(slug, qs)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }
  // TODO: use this for search filter
  // handleInputChange = e => {
  //   this.setState({ searchText: e.currentTarget.value }, () =>
  //     this.handleSearch()
  //   )
  // }

  // handleSearch() {
  //   const { searchText } = this.state
  //   const regex = new RegExp(escapeRegExp(searchText), 'gi')

  //   this.setState({
  //     filtered: !!searchText,
  //     data: this.props.userCommunicationLog
  //       .map(record => {
  //         const match =
  //           record.name.match(regex) || record.id.toString().match(regex)

  //         return match ? record : null
  //       })
  //       .filter(record => !!record),
  //   })
  // }

  // setSearchText = searchText => this.setState({ searchText })

  render() {
    const { meta, isLoading } = this.props
    const { data, sortedInfo, perPage } = this.state

    return (
      <section className="UserCommunicationLog">
        {isLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            bordered
            dataSource={data}
            onChange={this.handleChange}
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            rowKey="identifier"
            tableLayout="auto"
            scroll={{ x: 820 }}
          >
            <Column
              title="Type"
              dataIndex="resourceType"
              sorter={sortBy('resourceType')}
              sortOrder={
                sortedInfo.field === 'resourceType' && sortedInfo.order
              }
              width={200}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Status"
              dataIndex="status"
              sorter={sortBy('status')}
              sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
              width={200}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Created"
              dataIndex="createdAt"
              sorter={sortBy('createdAt')}
              sortOrder={sortedInfo.field === 'createdAt' && sortedInfo.order}
              width={200}
              render={text => (text ? dateTimeFormatter(text, true) : '--')}
            />
            <Column
              title="Mode"
              dataIndex="communicationMode"
              sorter={sortBy('communicationMode')}
              sortOrder={
                sortedInfo.field === 'communicationMode' && sortedInfo.order
              }
              width={200}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Service"
              dataIndex="service"
              sorter={sortBy('service')}
              sortOrder={sortedInfo.field === 'service' && sortedInfo.order}
              width={200}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Description"
              dataIndex="description"
              sorter={sortBy('description')}
              sortOrder={sortedInfo.field === 'description' && sortedInfo.order}
              width={200}
              render={text => (text ? text : '--')}
            />
          </Table>
        )}
      </section>
    )
  }
}

export default UserCommunicationLog
