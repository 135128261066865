import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ChatContainer from '../containers/ChatContainer'
import { listen } from '../services/socket'
import { SOCKET_CHANNELS } from '../constants'

const AllChats = ({ chats, actions }) => {
  useEffect(() => {
    listen(SOCKET_CHANNELS.NEW_MESSAGE, (error, result) => {
      if (error) {
        return console.log(error)
      }

      actions.receiveNewMessage(result)
    })

    listen(SOCKET_CHANNELS.INBOX_STATUS, (error, result) => {
      if (error) {
        return console.log(error)
      }

      actions.updateInbox(result)
    })
  }, [actions])

  return <ChatContainer />
}

AllChats.propTypes = {
  chats: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.shape({
    receiveNewMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default AllChats
