import { createFormAction } from 'redux-form-saga'

const getAllCompaniesAction = createFormAction('GET_ALL_COMPANIES')
const getCompanyAction = createFormAction('GET_COMPANY')
const createCompanyAction = createFormAction('CREATE_COMPANY')
const updateCompanyAction = createFormAction('UPDATE_COMPANY')
const deleteCompanyAction = createFormAction('DELETE_COMPANY')
const getAllUsersByCompanyIdAction = createFormAction(
  'GET_ALL_USERS_BY_COMPANY'
)

const getAllEscalationPoliciesByCompanyIdAction = createFormAction(
  'GET_ALL_ESCALATION_POLICIES_BY_COMPANY'
)

const uploadLogoAction = createFormAction('UPLOAD_LOGO')

export {
  getAllCompaniesAction,
  getCompanyAction,
  createCompanyAction,
  updateCompanyAction,
  deleteCompanyAction,
  getAllUsersByCompanyIdAction,
  getAllEscalationPoliciesByCompanyIdAction,
  uploadLogoAction,
}
