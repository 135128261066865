import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import MainContent from '../common/MainContent'
import EditAccountProfile from '../EditAccountProfile'
import AccountSettings from '../AccountSettings'
import EditAccountPassword from '../EditAccountPassword'
import PageWidth from '../PageWidth'

class AccountPage extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    toggleAccountOption: PropTypes.func.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  }

  render() {
    const {
      match: { url },
      profile,
      toggleAccountOption,
    } = this.props

    return (
      <MainContent>
        <div className="AccountPage with-side-nav">
          <PageWidth>
            <Switch>
              <Redirect exact from={`${url}/`} to={`${url}/profile`} />
              <Route
                path={`${url}/profile`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Edit Profile</title>
                    </Helmet>
                    <EditAccountProfile profile={profile} {...props} />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/settings`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Edit Settings</title>
                    </Helmet>
                    <AccountSettings
                      profile={profile}
                      toggleAccountOption={toggleAccountOption}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/change-password`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Change Password</title>
                    </Helmet>
                    <EditAccountPassword {...props} />
                  </Fragment>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default AccountPage
