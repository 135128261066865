import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import InputContainer from '../containers/common/InputContainer'
import { hasPermission } from '../utils/hasPermission'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE, USER_PERMISSIONS } from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'

class AllAssetLocations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.assetLocations,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }
  }

  static propTypes = {
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    isAssetLocationLoading: PropTypes.bool.isRequired,
    getAllAssetLocations: PropTypes.func,
    deleteAssetLocation: PropTypes.func,
    updateQueryParams: PropTypes.func,
    meta: PropTypes.object,
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  componentDidMount() {
    const { getAllAssetLocations } = this.props

    const { page, perPage } = parseQueryParams(global.location)
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
    }

    this.setState(query)

    getAllAssetLocations && getAllAssetLocations(createQueryString(query))
  }

  handleChange = (pagination, filters, sorter) => {
    const { getAllAssetLocations, updateQueryParams } = this.props
    const { page, perPage } = this.state

    if (page !== pagination.current) {
      const query = { page: pagination.current, perPage }
      const qs = createQueryString(query)

      getAllAssetLocations && getAllAssetLocations(qs)
      updateQueryParams && updateQueryParams({ search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllAssetLocations, updateQueryParams } = this.props
    const { page, perPage } = this.state

    const query = Object.assign({ page, perPage }, { [keyName]: value })

    this.setState({ [keyName]: value })

    const qs = createQueryString(query)
    getAllAssetLocations(qs)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.assetLocations
        .map(record => {
          const match =
            record.pillarId.match(regex) ||
            (record.description ? record.description.match(regex) : null)

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  render() {
    const {
      deleteAssetLocation,
      windowWidth,
      isAssetLocationLoading,
      meta,
    } = this.props
    const { data, sortedInfo, searchText, perPage } = this.state

    const isFixedColumn = windowWidth < 750

    return (
      <section className="mb5 AllAssetLocations">
        {hasPermission(USER_PERMISSIONS.ADD_ASSET_LOCATION) && (
          <Anchor to="/inventory/asset-locations/create" className="mb3" button>
            Create New
          </Anchor>
        )}
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: searchText,
              onChange: this.handleInputChange,
            }}
          />
        </div>
        {isAssetLocationLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{ x: 500 }}
            bordered
            tableLayout="auto"
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
          >
            <Column
              title="Pillar ID"
              dataIndex="pillarId"
              sorter={sortBy('pillarId')}
              sortOrder={sortedInfo.field === 'pillarId' && sortedInfo.order}
              width={150}
              render={(text, { pillarId }) =>
                hasPermission(USER_PERMISSIONS.VIEW_ASSET_LOCATION) ? (
                  <Anchor to={`/inventory/asset-locations/${pillarId}`}>
                    {text}
                  </Anchor>
                ) : (
                  text
                )
              }
            />
            <Column title="Description" dataIndex="description" width={300} />
            {hasPermission(USER_PERMISSIONS.EDIT_ASSET_LOCATION) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={50}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-asset-location">
                        <Anchor
                          to={`/inventory/asset-locations/${record.pillarId}/edit`}
                        >
                          Edit
                        </Anchor>
                      </Menu.Item>
                      <Menu.Item key="delete-asset-location">
                        <ActionAnchor
                          onClick={() => deleteAssetLocation(record.id)}
                        >
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )
                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllAssetLocations
