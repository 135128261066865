import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import AllCompanies from '../AllCompanies'
import CreateCompany from '../CreateCompany'
import Company from '../Company'
import EditCompany from '../EditCompany'
import PageWidth from '../PageWidth'

const CompaniesPage = props => {
  const {
    match: { url },
    companies,
    currentCompany,
    getAllCompanies,
    getCompany,
    deleteCompany,
    updateQueryParams,
    isCompanyLoading,
    meta,
    windowWidth,
    accountProfile,
    uploadLogo,
  } = props

  return (
    <MainContent>
      <div className="CompaniesPage with-side-nav">
        <PageWidth>
          <Switch>
            <Route
              path={`${url}/`}
              exact
              render={props => (
                <Fragment>
                  <Helmet>
                    <title>Companies</title>
                  </Helmet>
                  <AllCompanies
                    companies={companies}
                    getAllCompanies={getAllCompanies}
                    deleteCompany={deleteCompany}
                    updateQueryParams={updateQueryParams}
                    isCompanyLoading={isCompanyLoading}
                    meta={meta}
                    windowWidth={windowWidth}
                    url={url}
                    {...props}
                  />
                </Fragment>
              )}
            />
            <Route
              path={`${url}/create`}
              render={props => (
                <Fragment>
                  <Helmet>
                    <title>Create Company</title>
                  </Helmet>
                  <CreateCompany {...props} />
                </Fragment>
              )}
            />
            <Route
              path={`${url}/:id`}
              exact
              render={props => (
                <Fragment>
                  <Helmet>
                    <title>{currentCompany.name || 'Loading...'}</title>
                  </Helmet>
                  <Company
                    company={currentCompany}
                    companies={companies}
                    getCompany={getCompany}
                    isCompanyLoading={isCompanyLoading}
                    windowWidth={windowWidth}
                    accountProfile={accountProfile}
                    {...props}
                  />
                </Fragment>
              )}
            />
            <Route
              path={`${url}/:id/edit`}
              render={props => (
                <Fragment>
                  <Helmet>
                    <title>
                      {currentCompany.name
                        ? `Edit ${currentCompany.name}`
                        : 'Loading...'}
                    </title>
                  </Helmet>
                  <EditCompany
                    company={currentCompany}
                    companies={companies}
                    getCompany={getCompany}
                    uploadLogo={uploadLogo}
                    {...props}
                  />
                </Fragment>
              )}
            />
          </Switch>
        </PageWidth>
      </div>
    </MainContent>
  )
}

CompaniesPage.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  currentCompany: PropTypes.object.isRequired,
  getAllCompanies: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  updateQueryParams: PropTypes.func.isRequired,
  isCompanyLoading: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
  accountProfile: PropTypes.object.isRequired,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  meta: PropTypes.object,
}

export default CompaniesPage
