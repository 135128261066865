import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import AllMetadata from '../AllMetadata'
import CreateTag from '../CreateTag'
import EditTag from '../EditTag'
import CreateActionReportCause from '../CreateActionReportCause'
import EditActionReportCause from '../EditActionReportCause'
import PageWidth from '../PageWidth'

class MetadataPage extends Component {
  static propTypes = {
    getAllCompanies: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    getAllTags: PropTypes.func.isRequired,
    getTag: PropTypes.func.isRequired,
    currentTag: PropTypes.object.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    tagsMeta: PropTypes.object.isRequired,
    actionReportCauses: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllActionReportCauses: PropTypes.func.isRequired,
    deleteActionReportCause: PropTypes.func.isRequired,
    getActionReportCause: PropTypes.func.isRequired,
    isActionReportLoading: PropTypes.bool.isRequired,
    actionReportCausesMeta: PropTypes.object.isRequired,
    actionReportCause: PropTypes.object.isRequired,
    deleteTag: PropTypes.func.isRequired,
    accountProfile: PropTypes.object.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    isTagLoading: PropTypes.bool,
    windowWidth: PropTypes.number.isRequired,
  }

  render() {
    const {
      match: { url },
      getAllCompanies,
      companies,
      getAllTags,
      getTag,
      tags,
      tagsMeta,
      accountProfile,
      isTagLoading,
      actionReportCauses,
      getAllActionReportCauses,
      deleteActionReportCause,
      getActionReportCause,
      isActionReportLoading,
      actionReportCausesMeta,
      actionReportCause,
      updateQueryParams,
      deleteTag,
      windowWidth,
      currentTag,
    } = this.props

    return (
      <MainContent>
        <div className="MetadataPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/all`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Tags</title>
                    </Helmet>
                    <AllMetadata
                      actionReportCauses={actionReportCauses}
                      getAllActionReportCauses={getAllActionReportCauses}
                      deleteActionReportCause={deleteActionReportCause}
                      isActionReportLoading={isActionReportLoading}
                      actionReportCausesMeta={actionReportCausesMeta}
                      tags={tags}
                      getAllTags={getAllTags}
                      tagsMeta={tagsMeta}
                      deleteTag={deleteTag}
                      isTagLoading={isTagLoading}
                      accountProfile={accountProfile}
                      updateQueryParams={updateQueryParams}
                      windowWidth={windowWidth}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/tags/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Tag</title>
                    </Helmet>
                    <CreateTag {...props} />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/tags/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentTag ? `Edit ${currentTag.name}` : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditTag getTag={getTag} tag={currentTag} {...props} />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/action-report-causes/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Action Report Cause</title>
                    </Helmet>
                    <CreateActionReportCause
                      getAllCompanies={getAllCompanies}
                      companies={companies}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/action-report-causes/:slug/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {actionReportCause
                          ? `Edit ${actionReportCause.cause}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditActionReportCause
                      getAllCompanies={getAllCompanies}
                      getActionReportCause={getActionReportCause}
                      companies={companies}
                      actionReportCause={actionReportCause}
                      {...props}
                    />
                  </Fragment>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default MetadataPage
