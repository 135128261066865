import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import isUrlExternal from 'is-url-external'
import { Icon } from './Ant'

const Anchor = props => {
  const {
    to,
    children,
    target,
    className = '',
    button = false,
    invert = false,
    buttonVariant = 'primary',
  } = props

  let anchorClass
  if (button) {
    let button_classes = '';
    
    if (buttonVariant === 'primary') {
        if (invert) {
            button_classes = 'bg-white hover-bg-black-20 black-50 hover-black-80 b--black-50';
        } else {
            button_classes = 'bg-dark-blue hover-bg-blue white hover-white b--dark-blue t-shadow-1';
        }
    } else if (buttonVariant === 'danger') {
        button_classes ='bg-dark-red hover-bg-red white hover-white b--dark-red t-shadow-1'
    }

    anchorClass = cx(
      'Anchor--button',
      'f7 dib bg-animate no-underline pa1 pointer lh-copy br2 ba',
      button_classes,
      className
    )
  } else {
    anchorClass = cx('link dark-blue', className)
  }

  return isUrlExternal(to) ? (
    <a
      className={anchorClass}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children} <Icon type="link" />
    </a>
  ) : target ? (
    <Link
      className={anchorClass}
      to={to}
      target={target}
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  ) : (
    <Link className={anchorClass} to={to}>
      {children}
    </Link>
  )
}

Anchor.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  button: PropTypes.bool,
  invert: PropTypes.bool,
  buttonVariant: PropTypes.string,
}

const ActionAnchor = props => {
  const {
    onClick,
    children,
    className = '',
    button = false,
    invert = false,
  } = props

  let anchorClass
  if (button) {
    anchorClass = cx(
      'Anchor--button',
      'f7 dib bg-animate no-underline pa1 pointer lh-copy br2 ba',
      {
        'bg-dark-blue hover-bg-blue white hover-white t-shadow-1': !invert,
        'bg-white hover-bg-black-60 black-80 hover-white': invert,
      },
      className
    )
  } else {
    anchorClass = cx('link dark-blue pointer', className)
  }

  return (
    <span className={anchorClass} onClick={onClick}>
      {children}
    </span>
  )
}

ActionAnchor.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  button: PropTypes.bool,
  invert: PropTypes.bool,
}

export { Anchor, ActionAnchor }
