import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { getAccountProfile } from '../reducers/accountReducer'
import {
  getCurrentTag,
  getVisibleTags,
  getAllTagsMeta,
} from '../reducers/commentsReducer'
import {
  getCurrentActionReportCause,
  getVisibleActionReportCauses,
  getActionReportCausesMeta,
} from '../reducers/actionReportsReducer'
import { getVisibleCompanies } from '../reducers/companiesReducer'
import {
  isCommentLoading,
  isActionReportLoading,
  getWindowWidth,
} from '../reducers/uiReducer'
import {
  getActionReportCauseAction,
  deleteActionReportCauseAction,
  getAllActionReportCausesAction,
} from '../actions/actionReportsActions'
import {
  getTagAction,
  deleteTagAction,
  getAllTagsAction,
} from '../actions/commentsActions'
import { getAllCompaniesAction } from '../actions/companiesActions'
import MetadataPage from '../components/pages/MetadataPage'
import { createQueryString } from '../utils/queryParams'

const mapStateToProps = ({
  companies,
  account,
  comments,
  actionReports,
  ui,
}) => ({
  companies: getVisibleCompanies(companies),
  accountProfile: getAccountProfile(account),
  tags: getVisibleTags(comments),
  currentTag: getCurrentTag(comments),
  tagsMeta: getAllTagsMeta(comments),
  isTagLoading: isCommentLoading(ui),
  actionReportCauses: getVisibleActionReportCauses(actionReports),
  actionReportCause: getCurrentActionReportCause(actionReports),
  actionReportCausesMeta: getActionReportCausesMeta(actionReports),
  isActionReportLoading: isActionReportLoading(ui),
  windowWidth: getWindowWidth(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllCompanies: () =>
    dispatch(
      getAllCompaniesAction.request(createQueryString({ perPage: 'all' }))
    ),
  getAllTags: qs => dispatch(getAllTagsAction.request(qs)),
  getTag: qs => dispatch(getTagAction.request(qs)),
  updateQueryParams: location => dispatch(replace(location)),
  deleteTag: id => dispatch(deleteTagAction.request(id)),
  getAllActionReportCauses: qs =>
    dispatch(getAllActionReportCausesAction.request(qs)),
  getActionReportCause: qs => dispatch(getActionReportCauseAction.request(qs)),
  deleteActionReportCause: id =>
    dispatch(deleteActionReportCauseAction.request(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MetadataPage)
