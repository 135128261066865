import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const genericClassNames = cx('mt0 lh-title black-80')

const H1 = ({ children, inline = false, className = '' }) => {
  const h1ClassNames = cx('f2 mb3', genericClassNames, className, {
    'dib pr4': inline,
  })

  return <h1 className={h1ClassNames}>{children}</h1>
}

H1.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

const H2 = ({ children, inline = false, className = '' }) => {
  const h2ClassNames = cx('f3 mb3', genericClassNames, className, {
    'dib pr4': inline,
  })

  return <h2 className={h2ClassNames}>{children}</h2>
}

H2.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

const H3 = ({ children, inline, className, noMargin }) => {
  const h3ClassNames = cx('f4', genericClassNames, className, {
    'dib pr4': inline,
    mb0: noMargin,
    mb3: !noMargin,
  })

  return <h3 className={h3ClassNames}>{children}</h3>
}

H3.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
}

H3.defaultProps = { className: '', inline: false, noMargin: false }

const H4 = ({ children, inline, className, noMargin }) => {
  const h4ClassNames = cx('f5', genericClassNames, className, {
    'dib pr4': inline,
    mb0: noMargin,
    mb3: !noMargin,
  })

  return <h4 className={h4ClassNames}>{children}</h4>
}

H4.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
}

H4.defaultProps = { className: '', inline: false, noMargin: false }

export { H1, H2, H3, H4 }
