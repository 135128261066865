import React, { Component } from 'react'
import { Provider } from 'react-redux'
import formSaga from 'redux-form-saga'
import accountSaga from '../sagas/accountSaga'
import buildingsSaga from '../sagas/buildingsSaga'
import companiesSaga from '../sagas/companiesSaga'
import dashboardSaga from '../sagas/dashboardSaga'
import deploymentsSaga from '../sagas/deploymentsSaga'
import alertsSaga from '../sagas/alertsSaga'
import floorsSaga from '../sagas/floorsSaga'
import gatewaysSaga from '../sagas/gatewaysSaga'
import locationsSaga from '../sagas/locationsSaga'
import authSaga from '../sagas/authSaga'
import podsSaga from '../sagas/podsSaga'
import reportsSaga from '../sagas/reportsSaga'
import sitesSaga from '../sagas/sitesSaga'
import thirdPartySaga from '../sagas/thirdPartySaga'
import thresholdsSaga from '../sagas/thresholdsSaga'
import uiSaga from '../sagas/uiSaga'
import usersSaga from '../sagas/usersSaga'
import otaSaga from '../sagas/otaSaga'
import inventorySaga from '../sagas/inventorySaga'
import assetLocationsSaga from '../sagas/assetLocationsSaga'
import commentsSaga from '../sagas/commentsSaga'
import escalationPoliciesSaga from '../sagas/escalationPoliciesSaga'
import simCardsSaga from '../sagas/simCardsSaga'
import partsSaga from '../sagas/partsSaga'
import purchasesSaga from '../sagas/purchasesSaga'
import flowMonitorsSaga from '../sagas/flowMonitorsSaga'
import gatewaySuppliesSaga from '../sagas/gatewaySuppliesSaga'
import actionReportsSaga from '../sagas/actionReportsSaga'
import workflowsSaga from '../sagas/workflowsSaga'
import developerSaga from '../sagas/developerSaga'
import communicationsSaga from '../sagas/communicationsSaga'
import leadsSaga from '../sagas/leadsSaga'
import bleSaga from '../sagas/bleSaga'
import networksSaga from '../sagas/networksSaga'
import alertSummariesSaga from '../sagas/alertSummariesSaga'
import locationGroupsSaga from '../sagas/locationGroupsSaga'
import store from '../store/configureStore'
import {
  showUpgradeNotificationAction,
  showPhoneVerificationModalAction,
} from '../actions/uiActions'
import RootContainer from '../containers/RootContainer'

const handleServiceWorkerUpdate = () =>
  store.dispatch(showUpgradeNotificationAction())

const handlePhoneVerificationModal = () =>
  store.dispatch(showPhoneVerificationModalAction())

store.runSaga(accountSaga)
store.runSaga(buildingsSaga)
store.runSaga(companiesSaga)
store.runSaga(dashboardSaga)
store.runSaga(deploymentsSaga)
store.runSaga(alertsSaga)
store.runSaga(floorsSaga)
store.runSaga(formSaga)
store.runSaga(gatewaysSaga)
store.runSaga(locationsSaga)
store.runSaga(authSaga)
store.runSaga(podsSaga)
store.runSaga(reportsSaga)
store.runSaga(sitesSaga)
store.runSaga(thirdPartySaga)
store.runSaga(thresholdsSaga)
store.runSaga(uiSaga)
store.runSaga(usersSaga)
store.runSaga(otaSaga)
store.runSaga(inventorySaga)
store.runSaga(escalationPoliciesSaga)
store.runSaga(assetLocationsSaga)
store.runSaga(commentsSaga)
store.runSaga(simCardsSaga)
store.runSaga(partsSaga)
store.runSaga(purchasesSaga)
store.runSaga(flowMonitorsSaga)
store.runSaga(gatewaySuppliesSaga)
store.runSaga(actionReportsSaga)
store.runSaga(workflowsSaga)
store.runSaga(developerSaga)
store.runSaga(communicationsSaga)
store.runSaga(leadsSaga)
store.runSaga(bleSaga)
store.runSaga(networksSaga)
store.runSaga(alertSummariesSaga)
store.runSaga(locationGroupsSaga)

class PillarProvider extends Component {
  render() {
    return (
      <Provider store={store}>
        <RootContainer />
      </Provider>
    )
  }
}

export {
  PillarProvider as default,
  handlePhoneVerificationModal,
  handleServiceWorkerUpdate,
}
