import produce from 'immer'
import { combineReducers } from 'redux'
import {
  updateLocationGroupAction,
  getAllLocationGroupsAction,
  getLocationGroupAction,
  // addThresholdToLocationGroupAction,
  // getAllThresholdsByLocationGroupAction,
  // deleteThresholdFromLocationGroupAction,
  // getAllLocationGroupThresholdUserMappingsAction,
  // updateLocationGroupThresholdUserMappingAction,
} from '../actions/locationGroupActions'

const bySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLocationGroupsAction.SUCCESS:
        payload.items.forEach(site => {
          draft[site.slug] = site
        })
        break
    }
  })

const visibleSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLocationGroupsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const meta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLocationGroupsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const current = (state = { locations: [] }, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getLocationGroupAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case updateLocationGroupAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const locationGroupsReducer = combineReducers({
  bySlug,
  visibleSlugs,
  meta,
  current,
})

const getLocationGroup = (state, slug) => state.bySlug[slug]
const getVisibleLocationGroups = state =>
  state.visibleSlugs.map(slug => getLocationGroup(state, slug))
const getLocationGroupsMeta = state => state.meta
const getCurrentLocationGroup = state => state.current

export {
  locationGroupsReducer as default,
  getVisibleLocationGroups,
  getLocationGroupsMeta,
  getCurrentLocationGroup,
}
