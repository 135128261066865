import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateAssetLocationAction } from '../actions/assetLocationsActions'
import CreateAssetLocationForm from '../components/forms/CreateAssetLocationForm'

class EditAssetLocation extends Component {
  static propTypes = {
    assetLocation: PropTypes.object.isRequired,
    getAssetLocation: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getAssetLocation, match } = this.props
    getAssetLocation && getAssetLocation(match.params.pillarId)
  }

  render() {
    const { assetLocation } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/asset-locations">Asset Locations</Anchor>,
      'Edit Asset Location',
    ]

    return (
      <section className="EditAssetLocation">
        <FormHeader crumbs={crumbs} />
        <CreateAssetLocationForm
          submitAction={updateAssetLocationAction}
          initialValues={assetLocation}
          enableReinitialize
          {...this.props}
        />
      </section>
    )
  }
}

export default EditAssetLocation
