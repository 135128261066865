import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import NetworkAuthorities from './NetworkAuthorities'
import Network from './Network'
import { Bread, Crumb } from './common/Bread'
import { Spin, Table, Column, Menu, Dropdown, Icon, Modal } from './common/Ant'
import Divider from './common/Divider'
import Form from './common/Form'
import Input from './common/Input'
import Select, { Option } from './common/Select'
import Checkbox from './common/Checkbox'
import FormError from './common/FormError'
import Button from './common/Button'
import { Anchor, ActionAnchor } from './common/Anchor'
import { H3 } from './common/Headers'
import { createQueryString } from '../utils/queryParams'

const NETWORK_TIMING_MODE = [2000, 2500]

const Networks = ({
  getAllNetworks,
  createNetwork,
  updateNetwork,
  deleteNetwork,
  getAllNetworkAuthorities,
  createNetworkAuthority,
  updateNetworkAuthority,
  deleteNetworkAuthority,
  clearToken,
  regenerateToken,
  networkAuthorities,
  networks,
  token,
  match: { url },
  isLoading,
}) => {
  const [isAuthorityModalVisible, setIsAuthorityModalVisible] = useState(false)
  const [slug, setSlug] = useState('')
  const [name, setName] = useState('')
  const [authoritySlug, setAuthoritySlug] = useState('')
  const [authorityName, setAuthorityName] = useState('')
  const [timingMode, setTimingMode] = useState(2000)
  const [grantKeys, setGrantKeys] = useState(false)
  const [error, setError] = useState('')

  const history = useHistory()

  useEffect(() => {
    getAllNetworks(createQueryString({ perPage: 'all' }))
    getAllNetworkAuthorities(createQueryString({ perPage: 'all' }))
  }, [getAllNetworks, getAllNetworkAuthorities])

  const handleModalCancel = () => {
    setSlug('')

    history.push(url)
  }

  const handleSubmit = ev => {
    if (!name) {
      return setError('Name is required.')
    }

    if (!authoritySlug) {
      return setError('Authority is required.')
    }

    if (!slug) {
      createNetwork({ name, authoritySlug, timingMode, grantKeys })
    } else {
      updateNetwork({ slug, name, authoritySlug, timingMode, grantKeys })
    }

    handleModalCancel()
    ev.preventDefault()
  }

  const handleView = ({ slug, name, authorityName, timingMode, grantKeys }) => {
    setSlug(slug)
    setName(name)
    setAuthorityName(authorityName)
    setTimingMode(timingMode)
    setGrantKeys(grantKeys)

    history.push(`${url}/${slug}/view`)
  }

  const handleCreate = () => {
    setSlug('')
    setName('')
    setAuthorityName('')
    setTimingMode(2000)
    setGrantKeys(false)

    history.push(`${url}/create`)
  }

  const handleEdit = ({ slug, name, authoritySlug, timingMode, grantKeys }) => {
    setSlug(slug)
    setName(name)
    setAuthoritySlug(authoritySlug)
    setTimingMode(timingMode)
    setGrantKeys(grantKeys)

    history.push(`${url}/${slug}/edit`)
  }

  return isLoading ? (
    <Spin size="large" className="w-100 center mv5" />
  ) : (
    <div className="Networks mb5">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>Networks</Crumb>
          </Bread>
        </div>
        <div className="flex justify-center">
          <ActionAnchor onClick={handleCreate}>Create Network</ActionAnchor>
          <span>
            <Divider vertical />
            <ActionAnchor onClick={() => setIsAuthorityModalVisible(true)}>
              Create Authority
            </ActionAnchor>
          </span>
        </div>
      </div>
      <Divider />

      <div className="mb3">
        <H3>Networks</H3>
        <Table
          dataSource={networks}
          rowKey="slug"
          pagination={false}
          size="small"
          tableLayout="auto"
        >
          <Column
            title="Name"
            dataIndex="name"
            render={(text, record) => (
              <ActionAnchor onClick={() => handleView(record)}>
                {text}
              </ActionAnchor>
            )}
          />
          <Column title="Authority" dataIndex="authorityName" />
          <Column
            title="Site"
            dataIndex="siteName"
            render={(text, record) =>
              text ? (
                <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column title="Timing Mode" dataIndex="timingMode" />
          <Column
            title="Grant Keys"
            dataIndex="grantKeys"
            render={grantKeys => (grantKeys ? 'Yes' : 'No')}
          />
          <Column
            width={100}
            render={(text, record) => {
              const menu = (
                <Menu>
                  <Menu.Item key="edit-network">
                    <ActionAnchor onClick={() => handleEdit(record)}>
                      Edit
                    </ActionAnchor>
                  </Menu.Item>
                  <Menu.Item key="delete-network">
                    <ActionAnchor onClick={() => deleteNetwork(record.slug)}>
                      Delete
                    </ActionAnchor>
                  </Menu.Item>
                </Menu>
              )

              return (
                <Dropdown overlay={menu} trigger={['click']}>
                  <a className="ant-dropdown-link">
                    Actions <Icon type="down" />
                  </a>
                </Dropdown>
              )
            }}
          />
        </Table>
      </div>
      <div className="mb3">
        <NetworkAuthorities
          createNetworkAuthority={createNetworkAuthority}
          updateNetworkAuthority={updateNetworkAuthority}
          deleteNetworkAuthority={deleteNetworkAuthority}
          clearToken={clearToken}
          regenerateToken={regenerateToken}
          token={token}
          networkAuthorities={networkAuthorities}
          isLoading={isLoading}
          isAuthorityModalVisible={isAuthorityModalVisible}
          setIsAuthorityModalVisible={setIsAuthorityModalVisible}
        />

        <Route
          path={[`${url}/create`, `${url}/:slug/edit`, `${url}/:slug/view`]}
          exact
          render={props => {
            return (
              <>
                <Helmet>
                  <title>Network</title>
                </Helmet>
                <Modal
                  className="Modal"
                  visible
                  footer={null}
                  onCancel={handleModalCancel}
                  destroyOnClose
                >
                  {global.location.pathname.indexOf('/view') !== -1 ? (
                    <Network
                      slug={slug}
                      name={name}
                      authorityName={authorityName}
                      timingMode={timingMode}
                      grantKeys={grantKeys}
                      {...props}
                    />
                  ) : (
                    <Form onSubmit={handleSubmit}>
                      <H3>{!slug ? 'Create' : 'Update'} Network</H3>
                      <Input
                        label="Name"
                        input={{
                          value: name,
                          onChange: ev => setName(ev.currentTarget.value),
                        }}
                      />
                      <Select
                        label="Authority"
                        placeholder="Select an Authority"
                        input={{
                          value: authoritySlug,
                          onChange: x => setAuthoritySlug(x),
                        }}
                        filterable
                      >
                        {networkAuthorities.map(x => (
                          <Option value={x.slug} key={x.slug}>
                            {x.name}
                          </Option>
                        ))}
                      </Select>
                      <Select
                        name="timingMode"
                        label="Timing Mode"
                        type="number"
                        props={{ placeholder: 'Timing Mode' }}
                        input={{
                          value: timingMode,
                          onChange: x => setTimingMode(x),
                        }}
                      >
                        {NETWORK_TIMING_MODE.map(x => (
                          <Option value={x} key={x}>
                            {x}
                          </Option>
                        ))}
                      </Select>
                      <Checkbox
                        type="checkbox"
                        label="Grant Keys"
                        input={{
                          checked: grantKeys,
                          onChange: x => setGrantKeys(x),
                        }}
                      />
                      <Divider />
                      <FormError error={error} />
                      <div className="flex justify-between">
                        <Button text="Submit" onClick={handleSubmit} />
                      </div>
                    </Form>
                  )}
                </Modal>
              </>
            )
          }}
        />
      </div>
    </div>
  )
}

Networks.propTypes = {
  getAllNetworks: PropTypes.func.isRequired,
  createNetwork: PropTypes.func.isRequired,
  updateNetwork: PropTypes.func.isRequired,
  deleteNetwork: PropTypes.func.isRequired,
  getAllNetworkAuthorities: PropTypes.func.isRequired,
  createNetworkAuthority: PropTypes.func.isRequired,
  updateNetworkAuthority: PropTypes.func.isRequired,
  deleteNetworkAuthority: PropTypes.func.isRequired,
  clearToken: PropTypes.func.isRequired,
  regenerateToken: PropTypes.func.isRequired,
  networkAuthorities: PropTypes.arrayOf(PropTypes.object).isRequired,
  networks: PropTypes.arrayOf(PropTypes.object).isRequired,
  token: PropTypes.string,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Networks
