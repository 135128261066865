import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import MainContent from '../common/MainContent'
import AllAlerts from '../AllAlerts'
import Alert from '../Alert'
import GatewayAlert from '../GatewayAlert'
import PageWidth from '../PageWidth'

class AlertsPage extends Component {
  static propTypes = {
    getAllComments: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    currentComment: PropTypes.object,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    createComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAlerts: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    unhideAlert: PropTypes.func.isRequired,
    closeAlert: PropTypes.func.isRequired,
    acknowledgeAlert: PropTypes.func.isRequired,
    resolveAlert: PropTypes.func.isRequired,
    currentAlert: PropTypes.object.isRequired,
    currentAlertChartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAlert: PropTypes.func.isRequired,
    clearAlert: PropTypes.func.isRequired,
    clearLocationChartData: PropTypes.func.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    meta: PropTypes.object.isRequired,
    gatewayAlertsMeta: PropTypes.object.isRequired,
    accountProfile: PropTypes.object.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    isAlertLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool,
    windowWidth: PropTypes.number.isRequired,
    getAllSites: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllThresholds: PropTypes.func.isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    gatewayAlerts: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentAlertAuditLog: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAlertAuditLog: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    getLocationAlertHistory: PropTypes.func.isRequired,
    getAllGatewayAlerts: PropTypes.func.isRequired,
    locationAlertHistory: PropTypes.arrayOf(PropTypes.object),
    currentGatewayAlert: PropTypes.object.isRequired,
    getGatewayAlert: PropTypes.func.isRequired,
    restartGateway: PropTypes.func.isRequired,
    closeGatewayAlert: PropTypes.func.isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    getAllActionReports: PropTypes.func.isRequired,
    actionReports: PropTypes.arrayOf(PropTypes.object).isRequired,
    actionReportsMeta: PropTypes.object.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    generateAlertExport: PropTypes.func.isRequired,
    batchUpdateAlerts: PropTypes.func.isRequired,
  }

  render() {
    const {
      match: { url },
      getAllComments,
      deleteComment,
      editComment,
      createComment,
      currentComment,
      getAllTags,
      comments,
      tags,
      alerts,
      getAllAlerts,
      currentAlert,
      currentAlertChartData,
      getAlert,
      clearAlert,
      clearLocationChartData,
      hideAlert,
      unhideAlert,
      closeAlert,
      acknowledgeAlert,
      resolveAlert,
      meta,
      accountProfile,
      isChartDataLoading,
      isAlertLoading,
      windowWidth,
      getAllSites,
      sites,
      getAllThresholds,
      thresholds,
      getAlertAuditLog,
      currentAlertAuditLog,
      updateQueryParams,
      getLocationAlertHistory,
      isHistoryLoading,
      locationAlertHistory,
      gatewayAlerts,
      gatewayAlertsMeta,
      getAllGatewayAlerts,
      currentGatewayAlert,
      getGatewayAlert,
      restartGateway,
      closeGatewayAlert,
      getAllActionReports,
      getAllCompanies,
      actionReports,
      actionReportsMeta,
      companies,
      generateAlertExport,
      batchUpdateAlerts,
    } = this.props

    return (
      <MainContent>
        <div className="AlertsPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/`}
                exact
                render={props => <Redirect to={`${url}/all`} />}
              />
              <Route
                path={`${url}/all`}
                render={props => (
                  <AllAlerts
                    alerts={alerts}
                    getAllAlerts={getAllAlerts}
                    hideAlert={hideAlert}
                    unhideAlert={unhideAlert}
                    closeAlert={closeAlert}
                    acknowledgeAlert={acknowledgeAlert}
                    resolveAlert={resolveAlert}
                    meta={meta}
                    isLoading={isAlertLoading}
                    windowWidth={windowWidth}
                    getAllSites={getAllSites}
                    sites={sites}
                    getAllThresholds={getAllThresholds}
                    thresholds={thresholds}
                    accountProfile={accountProfile}
                    updateQueryParams={updateQueryParams}
                    gatewayAlerts={gatewayAlerts}
                    closeGatewayAlert={closeGatewayAlert}
                    gatewayAlertsMeta={gatewayAlertsMeta}
                    getAllGatewayAlerts={getAllGatewayAlerts}
                    getAllActionReports={getAllActionReports}
                    getAllCompanies={getAllCompanies}
                    actionReports={actionReports}
                    actionReportsMeta={actionReportsMeta}
                    companies={companies}
                    generateAlertExport={generateAlertExport}
                    batchUpdateAlerts={batchUpdateAlerts}
                    getAllTags={getAllTags}
                    tags={tags}
                    {...props}
                  />
                )}
              />
              <Route
                path={`${url}/pods/:slug`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentAlert.id
                          ? currentAlert.podPillarId
                            ? `Alert at ${currentAlert.podPillarId}`
                            : 'Smart Pod Offline'
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <Alert
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      currentComment={currentComment}
                      comments={comments}
                      tags={tags}
                      meta={meta}
                      getLocationAlertHistory={getLocationAlertHistory}
                      locationAlertHistory={locationAlertHistory}
                      currentAlert={currentAlert}
                      currentAlertChartData={currentAlertChartData}
                      getAlert={getAlert}
                      clearAlert={clearAlert}
                      clearLocationChartData={clearLocationChartData}
                      hideAlert={hideAlert}
                      unhideAlert={unhideAlert}
                      closeAlert={closeAlert}
                      acknowledgeAlert={acknowledgeAlert}
                      resolveAlert={resolveAlert}
                      accountProfile={accountProfile}
                      isChartDataLoading={isChartDataLoading}
                      isAlertLoading={isAlertLoading}
                      isHistoryLoading={isHistoryLoading}
                      alertAuditLog={currentAlertAuditLog}
                      getAlertAuditLog={getAlertAuditLog}
                      windowWidth={windowWidth}
                      updateQueryParams={updateQueryParams}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/gateways/:slug`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentGatewayAlert
                          ? currentGatewayAlert.slug
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <GatewayAlert
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      currentComment={currentComment}
                      comments={comments}
                      tags={tags}
                      gatewayAlert={currentGatewayAlert}
                      closeGatewayAlert={closeGatewayAlert}
                      getGatewayAlert={getGatewayAlert}
                      isGatewayAlertLoading={isAlertLoading}
                      restartGateway={restartGateway}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/flow-monitors/:slug`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentAlert.id
                          ? currentAlert.flowMonitorPillarId
                            ? `Alert at ${currentAlert.flowMonitorPillarId}`
                            : 'Water Monitor Offline'
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <Alert
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      currentComment={currentComment}
                      comments={comments}
                      tags={tags}
                      meta={meta}
                      getLocationAlertHistory={getLocationAlertHistory}
                      locationAlertHistory={locationAlertHistory}
                      currentAlert={currentAlert}
                      currentAlertChartData={currentAlertChartData}
                      getAlert={getAlert}
                      clearAlert={clearAlert}
                      clearLocationChartData={clearLocationChartData}
                      hideAlert={hideAlert}
                      unhideAlert={unhideAlert}
                      closeAlert={closeAlert}
                      acknowledgeAlert={acknowledgeAlert}
                      resolveAlert={resolveAlert}
                      accountProfile={accountProfile}
                      isChartDataLoading={isChartDataLoading}
                      isAlertLoading={isAlertLoading}
                      isHistoryLoading={isHistoryLoading}
                      alertAuditLog={currentAlertAuditLog}
                      getAlertAuditLog={getAlertAuditLog}
                      windowWidth={windowWidth}
                      updateQueryParams={updateQueryParams}
                      {...props}
                    />
                  </Fragment>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default AlertsPage
