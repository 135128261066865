import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  getLocationGroupAction,
  createLocationGroupAction,
  updateLocationGroupAction,
} from '../actions/locationGroupActions'

import { getCurrentLocationGroup } from '../reducers/locationGroupsReducer'

import { H3 } from './common/Headers'
import Divider from './common/Divider'
import Input from './common/Input'
import Select, { Option } from './common/Select'
import Button from './common/Button'
import FormError from './common/FormError'

const mapStateToProps = ({ locationGroups }) => ({
  locationGroup: getCurrentLocationGroup(locationGroups),
})

const mapDispatchToProps = dispatch => ({
  getLocationGroup: payload =>
    dispatch(getLocationGroupAction.request(payload)),
  createLocationGroup: payload =>
    dispatch(createLocationGroupAction.request(payload)),
  updateLocationGroup: payload =>
    dispatch(updateLocationGroupAction.request(payload)),
})

const EditLocationGroup = ({
  getLocationGroup,
  createLocationGroup,
  updateLocationGroup,
  locations,
  locationGroup,
  siteSlug,
  match: { params },
  handleModalCancel,
}) => {
  const [name, setName] = useState('')
  const [locationSlugs, setLocationsSlugs] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    if (params.slug) {
      getLocationGroup({ siteSlug, groupSlug: params.slug })
    }
  }, [getLocationGroup, siteSlug, params.slug])

  useEffect(() => {
    if (params.slug) {
      const { name, locations } = locationGroup

      setName(name)
      setLocationsSlugs(locations.map(x => x.slug))
    }
  }, [locationGroup, params.slug])

  const handleSubmit = () => {
    if (!name) {
      return setError('Name is required.')
    } else if (!locationSlugs.length) {
      return setError('Locations are required.')
    }

    setError('')

    const body = { name, locationSlugs }

    params.slug
      ? updateLocationGroup({
          siteSlug,
          body: {
            ...body,
            slug: params.slug,
          },
        })
      : createLocationGroup({
          siteSlug,
          body,
        })

    handleModalCancel()
  }

  return (
    <div className="EditLocationGroup">
      <H3>{params.slug ? 'Edit' : 'Create'} Room</H3>

      <div className="mb3">
        <div className="flex-l items-center-l">
          <Input
            label="Name"
            className="w-100"
            placeholder="Room Name"
            input={{
              value: name,
              onChange: ev => setName(ev.currentTarget.value),
            }}
          />
        </div>
        <Divider />
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Locations"
            placeholder="Select Locations"
            input={{
              value: locationSlugs,
              onChange: x => setLocationsSlugs(x),
            }}
            filterable
            mode="multiple"
          >
            {locations.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex-ns justify-between items-center-ns">
          <Button
            className="mr3-ns mb3 mb0-ns"
            text="Submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

EditLocationGroup.propTypes = {
  getLocationGroup: PropTypes.func.isRequired,
  createLocationGroup: PropTypes.func.isRequired,
  updateLocationGroup: PropTypes.func.isRequired,
  locationGroup: PropTypes.object.isRequired,
  siteSlug: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  handleModalCancel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLocationGroup)
