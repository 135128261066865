import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import Checkbox from '../common/Checkbox'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import Legend from '../common/Legend'
import NetworkSelect from '../NetworkSelect'
import { required } from '../../utils/validators'
import { toTitleCase } from '../../utils/textFormatters'
import { hasPermission } from '../../utils/hasPermission'
import {
  GATEWAY_STATUS,
  GATEWAY_TYPES,
  GATEWAY_STACK_TYPES,
  CELLULAR_ANTENNA_TYPE,
  LORA_ANTENNA_TYPE,
  LORA_CABLE_SIZE,
  SOLAR_PANEL,
  UPS_BATTERY,
  USER_PERMISSIONS,
} from '../../constants'

const EditGatewayForm = props => {
  const {
    handleSubmit,
    submitting,
    error,
    submitAction,
    assetLocations,
    tags,
  } = props

  const submit = handleSubmit(submitAction)
  return (
    <section className="EditGatewayForm">
      <Form onSubmit={submit}>
        <div className="flex-ns">
          <Field
            name="gatewayName"
            type="text"
            component={InputContainer}
            label="Name"
            validate={[required]}
            className="mr3-ns w-two-thirds-ns"
          />
          <Field
            name="gatewayPillarId"
            type="text"
            component={InputContainer}
            label="Pillar ID"
            validate={[required]}
            className="w-third-ns"
          />
        </div>
        <div className="flex-ns">
          <Field
            name="moduleId"
            type="text"
            component={InputContainer}
            label="Module ID"
            validate={[required]}
            className="mr3-ns w-50-ns"
          />
          <Field
            name="status"
            component={SelectContainer}
            label="Status"
            placeholder="Select a status"
            className="w-50-ns"
            filterable
          >
            {Object.keys(GATEWAY_STATUS).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(GATEWAY_STATUS[x])}
              </Option>
            ))}
          </Field>
          {
            // TODO this throws a warning - should be a Checkbox
          }
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="gatewayType"
            component={SelectContainer}
            label="Type"
            placeholder="Select a type"
            className="w-third-ns mr3-ns"
            filterable
          >
            {Object.keys(GATEWAY_TYPES).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(GATEWAY_TYPES[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="gatewayStackType"
            component={SelectContainer}
            label="Stack Type"
            placeholder="Select a stack type"
            className="w-third-ns mr3-ns"
            filterable
          >
            {Object.keys(GATEWAY_STACK_TYPES).map(x => (
              <Option value={GATEWAY_STACK_TYPES[x]} key={x}>
                {toTitleCase(GATEWAY_STACK_TYPES[x])}
              </Option>
            ))}
          </Field>
          {hasPermission(USER_PERMISSIONS.MANAGE_NETWORK) && (
            <NetworkSelect className="w-third-ns" />
          )}
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="firmwareVersion"
            type="text"
            component={InputContainer}
            label="Firmware Version"
            className="mr3-ns w-two-thirds-ns"
          />
          <Field
            name="assetLocationId"
            component={SelectContainer}
            label="Asset Location"
            placeholder="Select an asset location"
            className="w-third-ns"
            filterable
          >
            {[{ id: 0, pillarId: '-- Unassigned --' }, ...assetLocations].map(
              x => (
                <Option value={x.id} key={x.id}>
                  {x.pillarId}
                </Option>
              )
            )}
          </Field>
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="cellAntennaType"
            component={SelectContainer}
            label="Cellular Antenna Type"
            placeholder="Select a size"
            className="w-third-ns mr3-ns"
            filterable
          >
            {Object.keys(CELLULAR_ANTENNA_TYPE).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(CELLULAR_ANTENNA_TYPE[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="loraAntennaType"
            component={SelectContainer}
            label="LoRa Antenna Type"
            placeholder="Select a size"
            className="w-third-ns mr3-ns"
            filterable
          >
            {Object.keys(LORA_ANTENNA_TYPE).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(LORA_ANTENNA_TYPE[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="loraCableSize"
            component={SelectContainer}
            label="LoRa Cable Size"
            placeholder="Select a size"
            className="w-third-ns"
            filterable
          >
            {Object.keys(LORA_CABLE_SIZE).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(LORA_CABLE_SIZE[x])}
              </Option>
            ))}
          </Field>
        </div>
        <div className="flex-ns">
          <Field
            name="solarPanel"
            component={SelectContainer}
            label="Solar Panel "
            placeholder="Select solar panel"
            className="w-50-ns mr3-ns"
            filterable
          >
            {Object.keys(SOLAR_PANEL).map(x => (
              <Option value={SOLAR_PANEL[x]} key={x}>
                {toTitleCase(SOLAR_PANEL[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="upsBattery"
            component={SelectContainer}
            label="UPS Battery"
            placeholder="Select a battery"
            className="w-50-ns"
            filterable
          >
            {Object.keys(UPS_BATTERY).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(UPS_BATTERY[x])}
              </Option>
            ))}
          </Field>
        </div>
        <Divider />
        <Legend>Monitoring</Legend>
        <div className="flex-ns">
          <Field
            name="monitor"
            component={Checkbox}
            type="checkbox"
            label="Offline Notifications"
            className="w-third-ns"
          />
          <Field
            name="offlineNotificationDelayMinutes"
            component={InputContainer}
            type="number"
            label="Offline Notification Delay (minutes)"
            className="w-third-ns"
          />
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="tagIds"
            component={SelectContainer}
            label="Tags"
            mode="multiple"
            className="w-100"
            props={{ placeholder: 'Select Tags' }}
            filterable
          >
            {tags.map(tag => (
              <Option value={tag.id} key={tag.id}>
                {tag.name}
              </Option>
            ))}
          </Field>
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton defaultLocation="/inventory/all/gateways" />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditGatewayForm.propTypes = {
  initialValues: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.object),
  submitAction: PropTypes.func.isRequired,
  assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditGatewayForm' })(EditGatewayForm)
