import React, { Component, useRef } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray } from 'redux-form'
import { useDrag, useDrop } from 'react-dnd'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import Button from '../common/Button'
import Checkbox from '../common/Checkbox'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import { H3, H4 } from '../common/Headers'
import FormError from '../common/FormError'
import { ActionAnchor } from '../common/Anchor'
import { required, isNumeric } from '../../utils/validators'
import { createQueryString } from '../../utils/queryParams'
import { COMMUNICATION_MODES } from '../../constants'

const onlyPositiveIntegers = x => (isNaN(x) || x < 0 ? 0 : Math.round(x))

const Group = ({
  group,
  siteUsers,
  index,
  fields,
  updateEscalationPolicySort,
}) => {
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: 'ESCALATION_POLICY_GROUP',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      updateEscalationPolicySort(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'ESCALATION_POLICY_GROUP',
    item: () => {
      return { group, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  }

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <div ref={ref} data-handler-id={handlerId} style={{ ...style, opacity }}>
      <H4 inline>Group {index + 1}</H4>
      <ActionAnchor onClick={() => fields.remove(index)}>Remove</ActionAnchor>
      <div className="flex-ns">
        <Field
          name={`${group}.repeatCount`}
          type="number"
          normalize={onlyPositiveIntegers}
          component={InputContainer}
          label="Repeat Count"
          validate={[required, isNumeric]}
          className="w-25-ns mr3-ns"
        />
        <Field
          name={`${group}.waitTime`}
          type="number"
          normalize={onlyPositiveIntegers}
          component={InputContainer}
          label="Wait Time (min)"
          validate={[required, isNumeric]}
          className="w-25-ns mr3-ns"
        />
        <Field
          name={`${group}.communicationModes`}
          component={SelectContainer}
          mode="multiple"
          label="Communication Modes"
          validate={[required]}
          className="w-50-ns"
          filterable
        >
          {COMMUNICATION_MODES.map(x => (
            <Option value={x.value} key={x.value}>
              {x.label}
            </Option>
          ))}
        </Field>
      </div>
      <div>
        <Field
          name={`${group}.userIds`}
          component={SelectContainer}
          mode="multiple"
          label="Users"
          placeholder="Select users"
          validate={[required]}
          className="w-100"
          filterable
        >
          {siteUsers.map(x => (
            <Option value={x.id} key={x.id}>
              {x.firstName} {x.lastName}
            </Option>
          ))}
        </Field>
      </div>
    </div>
  )
}

Group.propTypes = {
  group: PropTypes.string.isRequired,
  siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  updateEscalationPolicySort: PropTypes.func.isRequired,
}

const EscalationPolicyGroups = ({
  siteUsers,
  fields,
  updateEscalationPolicySort,
}) => {
  return (
    <div>
      <H3>Groups</H3>
      {fields.map((group, index) => (
        <div key={index}>
          {index > 0 ? <Divider /> : null}
          <Group
            group={group}
            siteUsers={siteUsers}
            index={index}
            updateEscalationPolicySort={updateEscalationPolicySort}
            fields={fields}
          />
        </div>
      ))}
      <ActionAnchor
        onClick={() => fields.push({ userIds: [], communicationModes: [] })}
        button
      >
        Create New
      </ActionAnchor>
    </div>
  )
}

EscalationPolicyGroups.propTypes = {
  siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.object.isRequired,
  updateEscalationPolicySort: PropTypes.func.isRequired,
}

class EditEscalationPolicyForm extends Component {
  static propTypes = {
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    unsetSiteUserIds: PropTypes.func.isRequired,
    updateEscalationPolicySort: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
  }

  componentDidUpdate() {
    const { initialValues, sites, siteUsers, getAllUsersBySite } = this.props

    if (sites.length > 0 && initialValues.siteSlug && !siteUsers.length) {
      const [site] = sites.filter(x => x.slug === initialValues.siteSlug)
      const qs = createQueryString({ perPage: 'all' })
      getAllUsersBySite(site.slug, qs)
    }
  }

  handleSiteChange = (event, value) => {
    const { unsetSiteUserIds, getAllUsersBySite, sites } = this.props

    unsetSiteUserIds()

    const [site] = sites.filter(x => x.slug === value)

    if (site) {
      const qs = createQueryString({ perPage: 'all' })
      getAllUsersBySite(site.slug, qs)
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      submitAction,
      sites,
      siteUsers,
      updateEscalationPolicySort,
    } = this.props

    const submit = handleSubmit(submitAction)

    return (
      <section className="EditEscalationPolicyForm">
        <Form onSubmit={submit}>
          <Divider />
          <div className="flex-ns">
            <Field
              name="name"
              type="text"
              component={InputContainer}
              label="Name"
              validate={[required]}
              className="w-33-ns mr3-ns"
            />
            <Field
              name="ackWaitTime"
              type="number"
              normalize={onlyPositiveIntegers}
              component={InputContainer}
              label="Acknowledgement Wait Time (min)"
              validate={[required, isNumeric]}
              className="w-33-ns mr3-ns"
            />
            <Field
              name="siteSlug"
              component={SelectContainer}
              label="Site"
              validate={[required]}
              placeholder="Select a site"
              className="w-33-ns"
              onChange={this.handleSiteChange}
              filterable
            >
              {sites.map(x => (
                <Option value={x.slug} key={x.slug}>
                  {x.name}
                </Option>
              ))}
            </Field>
          </div>
          <Divider />
          <div className="flex-ns">
            <Field
              className="w-30-ns"
              name="enableInteractions"
              type="checkbox"
              component={Checkbox}
              label="Enable Acknowledge Interaction"
            />
            <Field
              className="w-30-ns"
              name="enableResolveInteraction"
              type="checkbox"
              component={Checkbox}
              label="Enable Resolve Interaction"
            />
          </div>
          <Divider />
          <div className="mb3">
            <FieldArray
              name="policyGroups"
              component={EscalationPolicyGroups}
              siteUsers={siteUsers}
              updateEscalationPolicySort={updateEscalationPolicySort}
            />
          </div>
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/rules" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditEscalationPolicyForm' })(
  EditEscalationPolicyForm
)
