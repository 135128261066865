import io from 'socket.io-client'
import { camelizeKeys, decamelizeKeys } from 'humps'
import store from '../store/configureStore'
import { getToken } from '../services/storage'
import {
  socketConnectAction,
  socketDisconnectAction,
} from '../actions/socketActions'
import { unauthorizedAction } from '../actions/authActions'
import { debounce } from '../utils/helpers'

let socket

const connect = () => {
  global.setInterval(function() {
    socket.emit('my_ping')
  }, 20000)

  socket = io(process.env.REACT_APP_SOCKET_BASE, {
    path: '/api/sio',
    transports: ['websocket'],
    query: { jwt: getToken() },
  })

  const debouncedConnect = debounce(() => socket.connect(), 60000)

  socket.on('connect', () => {
    store.dispatch(socketConnectAction())
  })

  socket.on('disconnect', reason => {
    store.dispatch(socketDisconnectAction())

    if (
      document.hasFocus() &&
      (reason === 'io server disconnect' || reason === 'ping timeout')
    ) {
      debouncedConnect()
    }
  })

  socket.on('unauthorized', () => {
    store.dispatch(unauthorizedAction())
  })
}

const emit = (channel, message) =>
  socket.emit(channel, JSON.stringify(decamelizeKeys(message)))

const listen = (channel, cb) =>
  socket.on(channel, message => {
    const parsed = camelizeKeys(JSON.parse(message))
    // TODO handle error
    cb(null, parsed)
  })

const verifySocketConnection = () => {
  if (!socket || socket.connected) {
    return
  }

  socket.connect()
}

const disconnectSocket = () => {
  if (!!socket && socket.connected) {
    socket.disconnect()
  }
}

export { connect, emit, listen, verifySocketConnection, disconnectSocket }
