import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from './common/Checkbox'
import { H4 } from './common/Headers'
import Divider from './common/Divider'
import { toTitleCase } from '../utils/textFormatters'

const UserPermissions = ({
  permissions,
  allowedPermissions,
  updateUserPermissions,
}) => (
  <div className="UserPermissions flex flex-wrap justify-between">
    {Object.keys(allowedPermissions).map(x => (
      <div key={x} className="w-25-l w-third-m w-50 ph2 mb3">
        <H4>{toTitleCase(x)}</H4>
        <Divider />
        <div className="vh-50 overflow-y-auto bg-light-gray pa2 br2">
          {allowedPermissions[x].map(y => (
            <Checkbox
              key={y}
              type="checkbox"
              label={toTitleCase(y)}
              input={{
                checked: permissions.indexOf(y) !== -1,
                onChange: value => {
                  if (value) {
                    updateUserPermissions([...permissions, y])
                  } else {
                    const index = permissions.indexOf(y)
                    const copiedPermissions = [...permissions]

                    copiedPermissions.splice(index, 1)
                    updateUserPermissions(copiedPermissions)
                  }
                },
              }}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
)

UserPermissions.propTypes = {
  updateUserPermissions: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowedPermissions: PropTypes.object.isRequired,
}

export default UserPermissions
