import { createFormAction } from 'redux-form-saga'

const getAccountProfileAction = createFormAction('GET_ACCOUNT_PROFILE')
const updateAccountProfileAction = createFormAction('UPDATE_ACCOUNT_PROFILE')
const updateAccountPasswordAction = createFormAction('UPDATE_ACCOUNT_PASSWORD')
const toggleAccountOptionAction = createFormAction('TOGGLE_ACCOUNT_OPTION')
const verifyPhoneTokenAction = createFormAction('VERIFY_PHONE_TOKEN')
const requestPhoneVerificationTokenAction = createFormAction(
  'REQUEST_PHONE_VERIFICATION_TOKEN'
)

const CLEAR_ACCOUNT_PROFILE = 'CLEAR_ACCOUNT_PROFILE'
const clearAccountProfileAction = () => ({
  type: CLEAR_ACCOUNT_PROFILE,
})

export {
  getAccountProfileAction,
  updateAccountProfileAction,
  updateAccountPasswordAction,
  toggleAccountOptionAction,
  verifyPhoneTokenAction,
  requestPhoneVerificationTokenAction,
  clearAccountProfileAction,
  CLEAR_ACCOUNT_PROFILE,
}
