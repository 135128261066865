import React from 'react'
import PropTypes from 'prop-types'
import EditWorkflowForm from '../components/forms/EditWorkflowForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createWorkflowAction } from '../actions/workflowsActions'

const CreateWorkflow = ({ setWorkflowType }) => {
  const crumbs = [<Anchor to="/workflows">Workflows</Anchor>, 'Create Workflow']

  return (
    <section className="CreateWorkflow">
      <FormHeader crumbs={crumbs} />
      <EditWorkflowForm
        setWorkflowType={setWorkflowType}
        submitAction={createWorkflowAction}
      />
    </section>
  )
}

CreateWorkflow.propTypes = {
  setWorkflowType: PropTypes.func.isRequired,
}

export default CreateWorkflow
