import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditBuildingForm from './forms/EditBuildingForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateBuildingAction } from '../actions/buildingsActions'

class EditBuilding extends Component {
  static propTypes = {
    building: PropTypes.object.isRequired,
    getBuilding: PropTypes.func.isRequired,
    getSite: PropTypes.func.isRequired,
    siteName: PropTypes.string,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { match, siteName, getSite, getBuilding } = this.props
    const { id, siteSlug } = match.params

    getBuilding(siteSlug, id)

    if (!siteName) {
      getSite(match.params.siteSlug)
    }
  }

  render() {
    const { building, match, siteName } = this.props
    const { siteSlug, id } = match.params

    const crumbs = [
      <Anchor to="/sites">Sites</Anchor>,
      siteName && <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>,
      'Edit Building',
    ]

    return (
      <section className="EditBuilding">
        <FormHeader crumbs={crumbs} />
        <EditBuildingForm
          submitAction={updateBuildingAction}
          initialValues={building}
          siteSlug={siteSlug}
          buildingId={id}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditBuilding
