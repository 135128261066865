import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllSupportPhoneNumbersAction,
  getSupportPhoneNumberAction,
  getAllConversationsAction,
  getConversationAction,
  createConversationAction,
  closeConversationAction,
  CLEAR_CONVERSATION,
  RECEIVE_NEW_MESSAGE,
} from '../actions/communicationsActions'
import messageSerializer from '../utils/messageSerializer'

const phoneNumbersBySlugReducer = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllSupportPhoneNumbersAction.SUCCESS:
        payload.items.forEach(x => {
          draft[x.slug] = x
        })
        break
    }
  })

const visiblePhoneNumberSlugsReducer = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllSupportPhoneNumbersAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const phoneNumbersMetaReducer = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllSupportPhoneNumbersAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const currentPhoneNumberReducer = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getSupportPhoneNumberAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const conversationsBySlugReducer = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllConversationsAction.SUCCESS:
        payload.items.forEach(x => {
          draft[x.slug] = x
        })
        break
      case RECEIVE_NEW_MESSAGE:
        if (draft[payload.chatroomSlug]) {
          draft[payload.chatroomSlug].lastMessage[0] = payload
          draft[payload.chatroomSlug].lastMessageTimestamp = payload.createdAt
        }
        break
      case closeConversationAction.SUCCESS:
        draft[payload.slug] = payload
        break
    }
  })

const visibleConversationSlugsReducer = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllConversationsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const conversationsMetaReducer = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllConversationsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const currentConversationReducer = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getConversationAction.SUCCESS:
        return [
          ...payload.messages.map(x => ({
            ...x,
            message: messageSerializer(x.message),
          })),
          ...draft,
        ]
      case createConversationAction.SUCCESS:
        return [
          ...payload.messages.map(x => ({
            ...x,
            message: messageSerializer(x.message),
          })),
        ]
      case CLEAR_CONVERSATION:
        return []
    }
  })

const currentConversationParticipantReducer = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getConversationAction.SUCCESS:
      case createConversationAction.SUCCESS:
        return payload.participants[0]
      case CLEAR_CONVERSATION:
        return {}
    }
  })

const currentConversationPillarParticipantReducer = (
  state = {},
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getConversationAction.SUCCESS:
      case createConversationAction.SUCCESS:
        return payload.participants[1]
      case CLEAR_CONVERSATION:
        return {}
    }
  })

const currentConversationSlugReducer = (state = '', { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getConversationAction.SUCCESS:
      case createConversationAction.SUCCESS:
        return payload.slug
      case CLEAR_CONVERSATION:
        return ''
      default:
        return state
    }
  })

const communicationsReducer = combineReducers({
  phoneNumbersBySlug: phoneNumbersBySlugReducer,
  visiblePhoneNumberSlugs: visiblePhoneNumberSlugsReducer,
  phoneNumbersMeta: phoneNumbersMetaReducer,
  currentPhoneNumber: currentPhoneNumberReducer,
  conversationsBySlug: conversationsBySlugReducer,
  visibleConversationSlugs: visibleConversationSlugsReducer,
  conversationsMeta: conversationsMetaReducer,
  currentConversation: currentConversationReducer,
  currentConversationParticipant: currentConversationParticipantReducer,
  currentConversationPillarParticipant: currentConversationPillarParticipantReducer,
  currentConversationSlug: currentConversationSlugReducer,
})

const getPhoneNumber = (state, slug) => state.phoneNumbersBySlug[slug]
const getCurrentPhoneNumber = state => state.currentPhoneNumber
const getVisiblePhoneNumbers = state =>
  state.visiblePhoneNumberSlugs.map(slug => getPhoneNumber(state, slug))
const getPhoneNumbersMeta = state => state.phoneNumbersMeta
const getConversation = (state, slug) => state.conversationsBySlug[slug]
const getCurrentConversation = state => state.currentConversation
const getCurrentConversationParticipant = state =>
  state.currentConversationParticipant
const getCurrentConversationPillarParticipant = state =>
  state.currentConversationPillarParticipant
const getCurrentConversationSlug = state => state.currentConversationSlug
const getVisibleConversations = state =>
  state.visibleConversationSlugs.map(slug => getConversation(state, slug))
const getConversationsMeta = state => state.conversationsMeta

export {
  communicationsReducer as default,
  phoneNumbersBySlugReducer,
  visiblePhoneNumberSlugsReducer,
  phoneNumbersMetaReducer,
  currentPhoneNumberReducer,
  getCurrentPhoneNumber,
  getVisiblePhoneNumbers,
  getPhoneNumbersMeta,
  getCurrentConversation,
  getCurrentConversationParticipant,
  getCurrentConversationPillarParticipant,
  getCurrentConversationSlug,
  getVisibleConversations,
  getConversationsMeta,
}
