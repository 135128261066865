import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import SelectContainer from '../../containers/common/SelectContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { required, isPhoneNumber } from '../../utils/validators'
import { normalizePhoneNumber, formatPhoneNumber } from '../../utils/forms'
import { Option } from '../common/Select'

const EditSupportPhoneNumberForm = ({
  users,
  handleSubmit,
  submitting,
  error,
  submitAction,
}) => {
  const submit = handleSubmit(submitAction)

  return (
    <section className="EditSupportPhoneNumberForm">
      <Form onSubmit={submit}>
        <div className="flex-ns justify-between">
          <Field
            name="phoneNumber"
            type="tel"
            component={InputContainer}
            label="Phone Number"
            className="w-50-ns mr3-ns"
            validate={[required, isPhoneNumber]}
            normalize={normalizePhoneNumber}
            format={formatPhoneNumber}
          />
          <Field
            name="assignedUserSlug"
            component={SelectContainer}
            label="User"
            placeholder="Select a user"
            validate={required}
            className="w-50-ns"
            filterable
          >
            {users.map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.fullName}
              </Option>
            ))}
          </Field>
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton defaultLocation="/communications/phone-numbers" />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditSupportPhoneNumberForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditSupportPhoneNumberForm' })(
  EditSupportPhoneNumberForm
)
