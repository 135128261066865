import { connect } from 'react-redux'
import sortBy from 'sort-by'
import MultipleLocations from '../components/MultipleLocations'
import {
  getAllLocationsBySiteAction,
  getLocationTimeSeriesDataByLocationIdsAction,
} from '../actions/locationsActions'
import {
  getVisibleLocations,
  getVisibleTimeSeriesData,
} from '../reducers/locationsReducer'

const mapStateToProps = ({ locations }) => ({
  locations: getVisibleTimeSeriesData(locations),
  allSiteLocations: getVisibleLocations(locations).sort(sortBy('name')),
})

const mapDispatchToProps = dispatch => ({
  getLocationsTimeSeriesData: (siteSlug, qs) =>
    dispatch(
      getLocationTimeSeriesDataByLocationIdsAction.request({ siteSlug, qs })
    ),
  getAllLocationsBySite: (siteSlug, qs) =>
    dispatch(getAllLocationsBySiteAction.request({ siteSlug, qs })),
})

export default connect(mapStateToProps, mapDispatchToProps)(MultipleLocations)
