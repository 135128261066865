const SOCKET_CONNECT = 'SOCKET_CONNECT'
const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT'

const socketConnectAction = () => ({ type: SOCKET_CONNECT })
const socketDisconnectAction = () => ({ type: SOCKET_DISCONNECT })

export {
  socketConnectAction,
  socketDisconnectAction,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
}
