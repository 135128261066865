import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from './common/Ant'
import Button from './common/Button'
import { Spin } from './common/Ant'
import { H3, H4 } from './common/Headers'
import { SIZES } from '../constants'
import Label from './common/Label'

class PodsCSVUpload extends Component {
  state = {
    modalOpen: false,
    formData: null,
  }

  static propTypes = {
    getAllPods: PropTypes.func.isRequired,
    uploadPodsCSV: PropTypes.func.isRequired,
    csvUploadResults: PropTypes.object.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // handles putting initial state data and updates
    return { ...nextProps.csvUploadResults, submitting: false }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.addedCount > 0 &&
      prevState.addedCount !== this.state.addedCount
    ) {
      this.props.getAllPods()
    }
  }

  componentWillUnmount() {
    if (this.state.modalOpen) {
      this.handleClose()
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
      addedCount: 0,
      failedCount: 0,
      existsCount: 0,
    })
  }

  handleFileSelection = event => {
    const files = Array.from(event.target.files)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('name', file.name)
      formData.append('type', file.type)
      formData.append('files[]', file)

      this.setState({ formData })
    })
  }

  handleSubmit = () => {
    const { formData } = this.state
    if (formData) {
      this.setState({ submitting: true })
      this.props.uploadPodsCSV(formData)
    }
  }

  render() {
    const {
      addedCount,
      existsCount,
      failedCount,
      exists,
      failed,
      modalOpen,
      submitting,
    } = this.state

    const isResultVisible = addedCount > 0 || existsCount > 0 || failedCount > 0

    return (
      <div className="PodsCSVUpload">
        <Button
          onClick={this.handleOpen}
          text="Import New Smart Pod CSV"
          size={SIZES.SMALL}
        />
        <Modal
          visible={modalOpen}
          className="Modal"
          title={
            isResultVisible
              ? 'Smart Pod Import Results'
              : 'Import New Smart Pod CSV'
          }
          onCancel={this.handleClose}
          style={{ top: 16 }}
          footer={null}
          destroyOnClose
        >
          {submitting ? (
            <Spin size="large" className="w-100 center" />
          ) : isResultVisible ? (
            existsCount > 0 || failedCount > 0 ? (
              <div>
                <H3 className="red">Failure!</H3>
                {existsCount > 0 && (
                  <div>
                    <H4>
                      {existsCount} Smart Pods already exist in inventory.
                      Please remove the rows shown below and try again.
                    </H4>
                    <div>
                      <pre>{exists.map(x => `${x}\n`)}</pre>
                      <ul />
                    </div>
                  </div>
                )}
                {failedCount > 0 && (
                  <div>
                    <H4>
                      {failedCount} Smart Pods failed validation. Please review
                      the rows shown below and try again.
                    </H4>
                    <div>
                      <pre>{failed.map(x => `${x}\n`)}</pre>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <H3 className="dark-blue">Success!</H3>
                <H4>{addedCount} Smart Pods added to inventory.</H4>
              </div>
            )
          ) : (
            <div>
              <Label>Select a CSV to upload</Label>
              <input
                type="file"
                accept="text/csv,application/vnd.ms-excel"
                onChange={this.handleFileSelection}
                className="mb3 w-100"
              />
              <Button onClick={this.handleSubmit} />
            </div>
          )}
        </Modal>
      </div>
    )
  }
}

export default PodsCSVUpload
