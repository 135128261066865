import {
  fork,
  call,
  takeLatest,
  put,
  all,
  cancel,
  cancelled,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllWorkflowsAction,
  getWorkflowAction,
  createWorkflowAction,
} from '../actions/workflowsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllWorkflowsSaga() {
  try {
    const result = yield call(api.getAllWorkflows)
    yield put(getAllWorkflowsAction.success(result))
  } catch (error) {
    yield put(getAllWorkflowsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateWorkflowSaga({ payload }) {
  try {
    const confirmUpdate = yield call(
      global.confirm,
      MESSAGES.CONFIRM_WORKFLOW_UPDATE
    )

    if (confirmUpdate) {
      const result = yield call(api.createWorkflow, payload)

      yield put(createWorkflowAction.success(result))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
      yield put(push('/workflows'))
    } else {
      yield cancel()
    }
  } catch (error) {
    yield put(createWorkflowAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  } finally {
    if (yield cancelled()) {
      // TODO this seems weird...
      yield put(createWorkflowAction.success())
    }
  }
}

function* handleGetWorkflowSaga({ payload }) {
  try {
    const result = yield call(api.getWorkflowByType, payload)
    yield put(getWorkflowAction.success(result))
  } catch (error) {
    yield put(getWorkflowAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllWorkflowsSaga() {
  yield takeLatest(getAllWorkflowsAction.REQUEST, handleGetAllWorkflowsSaga)
}

function* watchGetWorkflowSaga() {
  yield takeLatest(getWorkflowAction.REQUEST, handleGetWorkflowSaga)
}

function* watchCreateWorkflowSaga() {
  yield takeLatest(createWorkflowAction.REQUEST, handleCreateWorkflowSaga)
}

function* workflowsSaga() {
  yield all([
    fork(watchCreateWorkflowSaga),
    fork(watchGetAllWorkflowsSaga),
    fork(watchGetWorkflowSaga),
  ])
}

export {
  workflowsSaga as default,
  watchGetAllWorkflowsSaga,
  watchCreateWorkflowSaga,
  watchGetWorkflowSaga,
  handleGetWorkflowSaga,
  handleCreateWorkflowSaga,
  handleGetAllWorkflowsSaga,
}
