import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import { Spin } from './common/Ant'
import { Table, Column } from './common/Ant'
import { H3, H4 } from './common/Headers'
import { toTitleCase } from '../utils/textFormatters'

class Part extends Component {
  static propTypes = {
    part: PropTypes.object.isRequired,
    getPart: PropTypes.func.isRequired,
    isPartLoading: PropTypes.bool,
    match: PropTypes.object,
  }

  componentDidMount() {
    this.props.getPart(this.props.match.params.id)
  }

  render() {
    const { part, isPartLoading, match } = this.props

    return isPartLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Part mb3">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/inventory/all">Inventory</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to={`/inventory/all/parts`}>Parts</Anchor>
              </Crumb>
            </Bread>
          </div>
          <div className="flex justify-center mb3">
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          </div>
          <Divider />
        </div>
        <H3> Part Details</H3>
        <div className="flex flex-wrap">
          <Block label="Name">{part['name']}</Block>
          <Block label="Pillar Id">{part['pillarId']}</Block>
          <Block label="Part Resource Type">
            {part['partType'] && toTitleCase(part['partType'])}
          </Block>
          <Block label="Number of Manufacturers">
            {part['numberManufacturers']}
          </Block>
          <Block label="Number of Vendors">{part['numberVendors']}</Block>
          <Block label="Description">{part['description']}</Block>
        </div>
        <H4> Vendors</H4>
        <Table
          dataSource={part.vendors}
          rowKey="id"
          scroll={{ x: 650 }}
          bordered
          pagination={false}
          tableLayout="auto"
        >
          <Column
            title="Vendor Name"
            dataIndex="name"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Manufacturer Name"
            dataIndex="manufacturerName"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Vendor P/N"
            dataIndex="vendorPn"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Manufacturer P/N"
            dataIndex="manufacturerPn"
            width={300}
            render={text => (text ? text : '--')}
          />
          <Column
            title="Description"
            dataIndex="description"
            width={300}
            render={text => (text ? text : '--')}
          />
        </Table>
      </section>
    )
  }
}

export default Part
