import produce from 'immer'
import { combineReducers } from 'redux'
import sortBy from 'sort-by'
import {
  getAllUsersAction,
  getUserAction,
  getUserCommunicationLogAction,
  getUserPermissionsAction,
  updateUserPermissionsAction,
  updateUserAction,
  updateUserDefaultSiteAction,
  getDeveloperAccessTokenHistoryAction,
  CLEAR_USER,
} from '../actions/usersActions'

const byId = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllUsersAction.SUCCESS:
        payload.items.forEach(user => {
          user.fullName = `${user.firstName} ${user.lastName}`
          draft[user.id] = user
        })
        break
    }
  })

const visibleIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllUsersAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(user => user.id))
        break
    }
  })

const communicationLog = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getUserCommunicationLogAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items)
        break
    }
  })

const meta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllUsersAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const logMeta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getUserCommunicationLogAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const CURRENT_USER_INITIAL_STATE = {
  permissions: [],
  allowedPermissions: {},
  stats: {},
}

const current = (state = CURRENT_USER_INITIAL_STATE, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getUserAction.SUCCESS:
        Object.assign(draft, payload, {
          fullName: `${payload.firstName} ${payload.lastName}`,
          sites: payload.sites.sort(sortBy('name')),
        })
        break
      case getUserPermissionsAction.SUCCESS:
      case updateUserPermissionsAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case updateUserAction.SUCCESS:
      case updateUserDefaultSiteAction.SUCCESS:
        Object.assign(draft, payload, {
          fullName: `${payload.firstName} ${payload.lastName}`,
        })
        break
      case getDeveloperAccessTokenHistoryAction.SUCCESS:
        Object.assign(draft, { tokenHistory: payload })
        break
      case CLEAR_USER:
        return CURRENT_USER_INITIAL_STATE
    }
  })

const usersReducer = combineReducers({
  byId,
  visibleIds,
  communicationLog,
  current,
  logMeta,
  meta,
})

const getUser = (state, id) => state.byId[id]
const getCurrentUser = state => state.current
const getVisibleUsers = state => state.visibleIds.map(id => getUser(state, id))
const getVisibleUserCommunicationLog = state => state.communicationLog
const getUserCommunicationLogMeta = state => state.logMeta
const getUsersMeta = state => state.meta

export {
  usersReducer as default,
  getCurrentUser,
  getVisibleUserCommunicationLog,
  getVisibleUsers,
  getUsersMeta,
  getUserCommunicationLogMeta,
}
