import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Option } from './common/Select'
import SelectContainer from '../containers/common/SelectContainer'
import { getAllNetworksAction } from '../actions/networksActions'
import { getVisibleNetworks } from '../reducers/networksReducer'
import { createQueryString } from '../utils/queryParams'

const mapStateToProps = ({ networks }) => ({
  networks: getVisibleNetworks(networks),
})

const mapDispatchToProps = dispatch => ({
  getAllNetworks: payload => dispatch(getAllNetworksAction.request(payload)),
})

const NetworkSelect = ({ networks, getAllNetworks, className }) => {
  useEffect(() => {
    getAllNetworks(createQueryString({ perPage: 'all' }))
  }, [getAllNetworks])

  return (
    <Field
      name="networkSlug"
      component={SelectContainer}
      label="Network"
      className={className || 'w-100'}
      props={{ placeholder: 'Select a network' }}
      filterable
    >
      {[{ slug: null, name: '-- Unassigned --' }, ...networks].map(
        x => (
          <Option value={x.slug} key={x.slug}>
            {x.name}
          </Option>
        )
      )}
    </Field>
  )
}

NetworkSelect.propTypes = {
  networks: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllNetworks: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkSelect)
