import { fork, takeLatest, put, all, call } from 'redux-saga/effects'
import {
  getInventoryAction,
  getInventoryProjectionsAction,
  createInventoryProposalAction,
  updateInventoryProposalAction,
  getInventoryProposalAction,
  deleteInventoryProposalAction,
  convertInventoryProposalAction,
} from '../actions/inventoryActions'
import { unauthorizedAction } from '../actions/authActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { pickErrorMessage } from '../utils/helpers'
import { MESSAGES } from '../constants'

// handlers

function* handleGetInventorySaga() {
  try {
    const data = yield call(api.getInventoryCounts)
    yield put(getInventoryAction.success(data))
  } catch (error) {
    yield put(getInventoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetInventoryProjectionsSaga({ payload }) {
  try {
    const result = yield call(api.getInventoryProjections, payload)
    yield put(getInventoryProjectionsAction.success(result))
  } catch (error) {
    yield put(getInventoryProjectionsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateInventoryProposalSaga({ payload }) {
  try {
    const result = yield call(api.createInventoryProposal, payload)
    yield put(createInventoryProposalAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
  } catch (error) {
    yield put(createInventoryProposalAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateInventoryProposalSaga({ payload }) {
  try {
    const result = yield call(api.updateInventoryProposal, payload)
    yield put(updateInventoryProposalAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(updateInventoryProposalAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetInventoryProposalSaga({ payload }) {
  try {
    const result = yield call(api.getInventoryProposalBySlug, payload)
    yield put(getInventoryProposalAction.success(result))
  } catch (error) {
    yield put(getInventoryProposalAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteInventoryProposalSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      const { slug, qs } = payload
      yield call(api.deleteInventoryProposal, slug)
      yield fork(handleGetInventoryProjectionsSaga, { payload: qs })
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteInventoryProposalAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleConvertInventoryProposalSaga({ payload }) {
  try {
    const result = yield call(
      api.generatePillarIDsFromInventoryProposal,
      payload
    )
    yield put(convertInventoryProposalAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CONVERTED))
  } catch (error) {
    yield put(convertInventoryProposalAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetInventorySaga() {
  yield takeLatest(getInventoryAction.REQUEST, handleGetInventorySaga)
}

function* watchGetInventoryProjectionsSaga() {
  yield takeLatest(
    getInventoryProjectionsAction.REQUEST,
    handleGetInventoryProjectionsSaga
  )
}

function* watchCreateInventoryProposalSaga() {
  yield takeLatest(
    createInventoryProposalAction.REQUEST,
    handleCreateInventoryProposalSaga
  )
}

function* watchUpdateInventoryProposalSaga() {
  yield takeLatest(
    updateInventoryProposalAction.REQUEST,
    handleUpdateInventoryProposalSaga
  )
}

function* watchGetInventoryProposalSaga() {
  yield takeLatest(
    getInventoryProposalAction.REQUEST,
    handleGetInventoryProposalSaga
  )
}

function* watchDeleteInventoryProposalSaga() {
  yield takeLatest(
    deleteInventoryProposalAction.REQUEST,
    handleDeleteInventoryProposalSaga
  )
}

function* watchConvertInventoryProposalSaga() {
  yield takeLatest(
    convertInventoryProposalAction.REQUEST,
    handleConvertInventoryProposalSaga
  )
}

function* inventorySaga() {
  yield all([
    fork(watchGetInventorySaga),
    fork(watchGetInventoryProjectionsSaga),
    fork(watchCreateInventoryProposalSaga),
    fork(watchUpdateInventoryProposalSaga),
    fork(watchGetInventoryProposalSaga),
    fork(watchDeleteInventoryProposalSaga),
    fork(watchConvertInventoryProposalSaga),
  ])
}

export {
  inventorySaga as default,
  watchGetInventorySaga,
  watchGetInventoryProjectionsSaga,
  watchCreateInventoryProposalSaga,
  watchUpdateInventoryProposalSaga,
  watchGetInventoryProposalSaga,
  watchDeleteInventoryProposalSaga,
  watchConvertInventoryProposalSaga,
  handleGetInventorySaga,
}
