import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { unauthorizedAction } from '../actions/authActions'
import { getAllOTALogsAction, getOTALogAction } from '../actions/otaActions'
import { showErrorMessageAction } from '../actions/uiActions'
import api from '../services/api'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllOTALogsSaga() {
  try {
    const result = yield call(api.getAllOTALogs)
    yield put(getAllOTALogsAction.success(result))
  } catch (error) {
    yield put(getAllOTALogsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetOTALogSaga({ payload }) {
  try {
    const result = yield call(api.getOTALogBySlug, payload)
    yield put(getOTALogAction.success(result))
  } catch (error) {
    yield put(getOTALogAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllOTALogsSaga() {
  yield takeLatest(getAllOTALogsAction.REQUEST, handleGetAllOTALogsSaga)
}

function* watchGetOTALogBySlugSaga() {
  yield takeLatest(getOTALogAction.REQUEST, handleGetOTALogSaga)
}

function* otaSaga() {
  yield all([fork(watchGetAllOTALogsSaga), fork(watchGetOTALogBySlugSaga)])
}

export { otaSaga as default }
