import { connect } from 'react-redux'
import PrintPage from '../components/pages/PrintPage'
import { mergeSiteGatewaysFloorsBuildings } from './SitesPageContainer'
import { getSiteAction } from '../actions/sitesActions'
import { getFloorAction } from '../actions/floorsActions'
import { getAllLocationsByFloorAction } from '../actions/locationsActions'
import { getCurrentFloor } from '../reducers/floorsReducer'
import { getVisibleLocations } from '../reducers/locationsReducer'

const mapStateToProps = ({ sites, floors, locations }) => ({
  site: mergeSiteGatewaysFloorsBuildings(sites, floors),
  floor: getCurrentFloor(floors),
  locations: getVisibleLocations(locations),
})

const mapDispatchToProps = dispatch => ({
  getSite: siteSlug =>
    dispatch(getSiteAction.request({ siteSlug, shouldFetchBuildings: false })),
  getFloor: (siteSlug, floorId) =>
    dispatch(getFloorAction.request({ siteSlug, floorId })),
  getAllLocationsByFloor: (siteSlug, floorId) =>
    dispatch(
      getAllLocationsByFloorAction.request({
        siteSlug,
        floorId,
        shouldFetchSnapshots: false,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrintPage)
