import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAccessTokenAction,
  generateAccessTokenAction,
} from '../actions/developerActions'
import {
  showSuccessMessageWithDurationAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAccessTokenSaga({ payload }) {
  try {
    const result = yield call(api.getDeveloperAccessToken, payload)
    yield put(getAccessTokenAction.success(result))
  } catch (error) {
    yield put(getAccessTokenAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGenerateAccessTokenAction({ payload }) {
  try {
    const result = yield call(api.generateDeveloperAccessToken, payload)
    yield put(
      showSuccessMessageWithDurationAction({
        message: MESSAGES.SUCCESSFULLY_GENERATED_ACCESS_TOKEN,
        duration: 5,
      })
    )
    yield put(generateAccessTokenAction.success(result))
  } catch (error) {
    yield put(generateAccessTokenAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAccessTokenSaga() {
  yield takeLatest(getAccessTokenAction.REQUEST, handleGetAccessTokenSaga)
}

function* watchGenerateAccessTokenAction() {
  yield takeLatest(
    generateAccessTokenAction.REQUEST,
    handleGenerateAccessTokenAction
  )
}

function* developerSaga() {
  yield all([
    fork(watchGetAccessTokenSaga),
    fork(watchGenerateAccessTokenAction),
  ])
}

export {
  developerSaga as default,
  watchGetAccessTokenSaga,
  watchGenerateAccessTokenAction,
  handleGetAccessTokenSaga,
  handleGenerateAccessTokenAction,
}
