import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditUserForm from './forms/EditUserForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateUserAction } from '../actions/usersActions'

class EditUser extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    getUser: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    this.props.getUser(this.props.match.params.id)
  }

  render() {
    const { user, companies } = this.props

    const crumbs = [<Anchor to="/users">Users</Anchor>, 'Edit User']

    return (
      <section className="EditUser">
        <FormHeader crumbs={crumbs} />
        <EditUserForm
          submitAction={updateUserAction}
          initialValues={user}
          companies={companies}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditUser
