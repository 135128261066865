import { createFormAction } from 'redux-form-saga'

const getAllOTALogsAction = createFormAction('GET_ALL_OTA_LOGS')
const getOTALogAction = createFormAction('GET_OTA_LOG')

const SET_OTA_POD_IDS = 'SET_OTA_POD_IDS'
const setOTAPodIdsAction = payload => ({
  type: SET_OTA_POD_IDS,
  payload,
})

export {
  getAllOTALogsAction,
  getOTALogAction,
  SET_OTA_POD_IDS,
  setOTAPodIdsAction,
}
