import { createFormAction } from 'redux-form-saga'

const createLocationGroupAction = createFormAction('CREATE_LOCATION_GROUP')
const updateLocationGroupAction = createFormAction('UPDATE_LOCATION_GROUP')
const deleteLocationGroupAction = createFormAction('DELETE_LOCATION_GROUP')
const getAllLocationGroupsAction = createFormAction('GET_ALL_LOCATION_GROUPS')
const getLocationGroupAction = createFormAction('GET_LOCATION_GROUP')
const addThresholdToLocationGroupAction = createFormAction(
  'ADD_THRESHOLD_TO_LOCATION_GROUP'
)
const getAllThresholdsByLocationGroupAction = createFormAction(
  'GET_ALL_LOCATION_GROUP_THRESHOLDS'
)
const deleteThresholdFromLocationGroupAction = createFormAction(
  'DELETE_THRESHOLD_FROM_LOCATION_GROUP'
)
const getAllLocationGroupThresholdUserMappingsAction = createFormAction(
  'GET_ALL_LOCATION_GROUP_THRESHOLD_USER_MAPPINGS'
)
const updateLocationGroupThresholdUserMappingAction = createFormAction(
  'UPDATE_LOCATION_GROUP_THRESHOLD_USER_MAPPINGS'
)

export {
  createLocationGroupAction,
  updateLocationGroupAction,
  deleteLocationGroupAction,
  getAllLocationGroupsAction,
  getLocationGroupAction,
  addThresholdToLocationGroupAction,
  getAllThresholdsByLocationGroupAction,
  deleteThresholdFromLocationGroupAction,
  getAllLocationGroupThresholdUserMappingsAction,
  updateLocationGroupThresholdUserMappingAction,
}
