import produce from 'immer'
import { combineReducers } from 'redux'
import { getAllOTALogsAction, getOTALogAction } from '../actions/otaActions'

const bySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllOTALogsAction.SUCCESS:
        payload.items.forEach(log => {
          draft[log.slug] = log
        })
        break
    }
  })

const visibleSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllOTALogsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(log => log.slug))
        break
    }
  })

const meta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllOTALogsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const current = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getOTALogAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const otaReducer = combineReducers({
  bySlug,
  visibleSlugs,
  meta,
  current,
})

const getOTALog = ({ bySlug }, slug) => bySlug[slug]
const getCurrentOTALog = ({ current }) => current
const getVisibleOTALogs = ({ visibleSlugs, bySlug }) =>
  visibleSlugs.map(slug => getOTALog({ bySlug }, slug))

export { otaReducer as default, getOTALog, getCurrentOTALog, getVisibleOTALogs }
