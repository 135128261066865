import React from 'react'
import PropTypes from 'prop-types'
import { ActionAnchor } from './common/Anchor'
import { dateFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'

const ThresholdUserMappingsCSVDownload = ({ mappings, siteName }) => {
  const handleDownload = () => {
    const thresholdColumnNames = mappings[0].thresholds.map(x => `${x.name}`)
    let csv = `Name,Role,${thresholdColumnNames.map(x => x)}\n`

    mappings.forEach(x => {
      const { name, role, thresholds } = x

      csv += `${name},${toTitleCase(role)},${thresholds.map(x =>
        x.active ? 'Active' : '--'
      )}\n`
    })

    const currentDate = dateFormatter(Date.now())
      .split('/')
      .join('-')
    let fileName = `${siteName}-Threshold-User-Mappings--${currentDate}`

    const encodedCsv = global.encodeURI(csv)

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodedCsv
    hiddenElement.target = '_blank'
    hiddenElement.download = `${fileName}.csv`
    hiddenElement.click()
  }

  return (
    <section className="ThresholdUserMappingsCSVDownload">
      <span>
        <ActionAnchor onClick={handleDownload}>
          Download Threshold User Mappings
        </ActionAnchor>
      </span>
    </section>
  )
}

ThresholdUserMappingsCSVDownload.propTypes = {
  mappings: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteName: PropTypes.string.isRequired,
}

export default ThresholdUserMappingsCSVDownload
