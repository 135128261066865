import { createFormAction } from 'redux-form-saga'

const getAllGatewaySuppliesAction = createFormAction('GET_ALL_GATEWAY_SUPPLIES')

/* For the future if we'd like to see all Gateway Supplies we can assign. */
const getAllAvailableGatewaySuppliesAction = createFormAction(
  'GET_ALL_AVAILABLE_GATEWAY_SUPPLIES'
)
const getGatewaySupplyAction = createFormAction('GET_GATEWAY_SUPPLY')
const createGatewaySupplyAction = createFormAction('CREATE_GATEWAY_SUPPLY')
const updateGatewaySupplyAction = createFormAction('UPDATE_GATEWAY_SUPPLY')
const deleteGatewaySupplyAction = createFormAction('DELETE_GATEWAY_SUPPLY')

export {
  getAllGatewaySuppliesAction,
  getAllAvailableGatewaySuppliesAction,
  getGatewaySupplyAction,
  createGatewaySupplyAction,
  updateGatewaySupplyAction,
  deleteGatewaySupplyAction,
}
