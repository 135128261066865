import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { Icon } from './Ant'
import { SIZES } from '../../constants'

/* TODO: Accept a `children` prop so we can pass an arbitrary number of elements*/
const Button = props => {
  const {
    text = 'Submit',
    icon,
    disabled = false,
    submitting,
    onClick,
    to,
    invert,
    type = 'button',
    overrideSkin = false,
    className = '',
    size = SIZES.MEDIUM,
    overrideText = false,
    variant='primary',
  } = props

  let btnColors
//   if (variant === "danger") {
//     btnColors = 'bg-dark-red hover-bg-red white hover-white b--dark-red t-shadow-1'
//   } else {
  btnColors = {
        'bg-dark-red hover-bg-red white hover-white b--dark-red t-shadow-1':
        variant === "danger",
        'bg-white hover-bg-black-20 black-50 hover-black-80 b--black-50':
          !disabled && invert && variant !== "danger",
        'bg-dark-blue hover-bg-blue b--dark-blue white t-shadow-1':
          !disabled && !overrideSkin && !invert && variant !== "danger",
        'pv2 ph4 fw6 f6': size === SIZES.MEDIUM,
        pa1: size === SIZES.SMALL,
        'bg-black-10 b--black-20 black-20': disabled,
      }
//   }

  const btnClass = cx(
    'button-reset dib bg-animate no-underline ba pointer lh-copy br2',
    btnColors,
    className
  )

  return to ? 
    onClick ? (
    <Link className={btnClass} onClick={onClick} to={to}>
      {overrideText ? <Icon type={icon} /> : text}
    </Link>
  ) : <Link className={btnClass} to={to}>
        {overrideText ? <Icon type={icon} /> : text}
     </Link>
  : (
    <button
      className={btnClass}
      type={type}
      onClick={onClick}
      disabled={disabled || submitting}
    >
      {overrideText ? <Icon type={icon} /> : text}
      {submitting && <Icon type="loading" className="pl3" />}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  invert: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  overrideSkin: PropTypes.bool,
  overrideText: PropTypes.bool,
  variant: PropTypes.string
}

export { Button as default }
