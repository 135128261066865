import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import MainContent from './common/MainContent'
import { H2 } from './common/Headers'
import Button from './common/Button'

class ErrorBoundary extends Component {
  constructor() {
    super()

    this.state = { hasError: false }
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  handleReport = () => Sentry.showReportDialog({ eventId: this.state.eventId })

  render() {
    return this.state.hasError ? (
      <MainContent>
        <div className="ErrorBoundary with-side-nav">
          <H2>Something went wrong.</H2>
          <Button onClick={this.handleReport} text="Report feedback" />
        </div>
      </MainContent>
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
