import produce from 'immer'
import { combineReducers } from 'redux'
import { getAllLeadsAction } from '../actions/leadsActions'

const byId = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLeadsAction.SUCCESS:
        payload.items.forEach(user => {
          draft[user.id] = user
        })
        break
    }
  })

const visibleIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLeadsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(user => user.id))
        break
    }
  })

const meta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLeadsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const leadsReducer = combineReducers({
  byId,
  visibleIds,
  meta,
})

const getLead = (state, id) => state.byId[id]
const getVisibleLeads = state => state.visibleIds.map(id => getLead(state, id))
const getLeadsMeta = state => state.meta

export { leadsReducer as default, getVisibleLeads, getLeadsMeta }
