const TOGGLE_SIDE_NAV_COLLAPSE = 'TOGGLE_SIDE_NAV_COLLAPSE'
const toggleSideNavCollapseAction = () => ({ type: TOGGLE_SIDE_NAV_COLLAPSE })

const TOGGLE_FULL_WIDTH = 'TOGGLE_FULL_WIDTH'
const toggleFullWidthAction = payload => ({ type: TOGGLE_FULL_WIDTH, payload })

const UPDATE_WINDOW_WIDTH = 'UPDATE_WINDOW_WIDTH'
const updateWindowWidthAction = payload => ({
  type: UPDATE_WINDOW_WIDTH,
  payload,
})

const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE'
const SHOW_SUCCESS_MESSAGE_WITH_DURATION = 'SHOW_SUCCESS_MESSAGE_WITH_DURATION'
const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'
const SHOW_ERROR_MESSAGE_WITH_DURATION = 'SHOW_ERROR_MESSAGE_WITH_DURATION'

const showSuccessMessageAction = payload => ({
  type: SHOW_SUCCESS_MESSAGE,
  payload,
})

const showSuccessMessageWithDurationAction = payload => ({
  type: SHOW_SUCCESS_MESSAGE_WITH_DURATION,
  payload,
})

const showErrorMessageAction = payload => ({
  type: SHOW_ERROR_MESSAGE,
  payload,
})

const showErrorMessageWithDurationAction = payload => ({
  type: SHOW_ERROR_MESSAGE_WITH_DURATION,
  payload,
})

const SHOW_UPGRADE_NOTIFICATION = 'SHOW_UPGRADE_NOTIFICATION'
const showUpgradeNotificationAction = () => ({
  type: SHOW_UPGRADE_NOTIFICATION,
})

const SHOW_PHONE_VERIFICATION_NOTIFICATION =
  'SHOW_PHONE_VERIFICATION_NOTIFICATION'
const showPhoneVerificationNotificationAction = () => ({
  type: SHOW_PHONE_VERIFICATION_NOTIFICATION,
})

const SHOW_PHONE_VERIFICATION_MODAL = 'SHOW_PHONE_VERIFICATION_MODAL'
const showPhoneVerificationModalAction = () => ({
  type: SHOW_PHONE_VERIFICATION_MODAL,
})

const HIDE_PHONE_VERIFICATION_MODAL = 'HIDE_PHONE_VERIFICATION_MODAL'
const hidePhoneVerificationModalAction = () => ({
  type: HIDE_PHONE_VERIFICATION_MODAL,
})

export {
  TOGGLE_SIDE_NAV_COLLAPSE,
  toggleSideNavCollapseAction,
  UPDATE_WINDOW_WIDTH,
  updateWindowWidthAction,
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  SHOW_ERROR_MESSAGE_WITH_DURATION,
  showSuccessMessageAction,
  showSuccessMessageWithDurationAction,
  SHOW_SUCCESS_MESSAGE_WITH_DURATION,
  showErrorMessageAction,
  SHOW_UPGRADE_NOTIFICATION,
  showUpgradeNotificationAction,
  SHOW_PHONE_VERIFICATION_NOTIFICATION,
  showPhoneVerificationNotificationAction,
  SHOW_PHONE_VERIFICATION_MODAL,
  showPhoneVerificationModalAction,
  HIDE_PHONE_VERIFICATION_MODAL,
  hidePhoneVerificationModalAction,
  showErrorMessageWithDurationAction,
  TOGGLE_FULL_WIDTH,
  toggleFullWidthAction,
}
