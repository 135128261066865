import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { H3 } from './common/Headers'
import Divider from './common/Divider'
import Button from './common/Button'
import { Spin } from './common/Ant'
import { toTitleCase } from '../utils/textFormatters'
import { dateFormatter } from '../utils/date'

const ConvertInventoryProposal = ({
  proposal,
  convertInventoryProposal,
  getInventoryProposal,
  match: { params },
}) => {
  const {
    slug,
    resourceType,
    productVersion,
    quantity,
    estimatedDate,
    notes,
  } = proposal

  useEffect(() => {
    if (params.slug) {
      getInventoryProposal(params.slug)
    }
  }, [getInventoryProposal, params.slug])

  return (
    <div className="ConvertInventoryProposal">
      <H3>Convert Inventory Proposal</H3>
      {!slug ? (
        <Spin size="large" className="w-100 center mv5" />
      ) : (
        <>
          <div className="mb3 i">
            You are about to generate{' '}
            <span className="b">
              {quantity} {toTitleCase(resourceType)}
            </span>{' '}
            Pillar IDs from this proposal. Proceeding will remove this proposal,
            generate a CSV of Pillar IDs for these units, and deliver it to your
            email.
          </div>
          <div className="mb3 f5">
            <div>
              <span className="b">Units:</span> {quantity}
            </div>
            <div>
              <span className="b">Resource Type:</span>{' '}
              {toTitleCase(resourceType)}
            </div>
            <div>
              <span className="b">Estimated Date:</span>{' '}
              {dateFormatter(estimatedDate)}
            </div>
            {productVersion && (
              <div>
                <span className="b">Product Version:</span> {productVersion}
              </div>
            )}
            {notes && (
              <div>
                <span className="b">Notes:</span> {notes}
              </div>
            )}
          </div>
          <Divider />
          <div className="mb3">
            <div className="flex-ns justify-between items-center-ns">
              <Button
                className="mr3-ns mb3 mb0-ns"
                text="Submit"
                onClick={() => convertInventoryProposal(slug)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

ConvertInventoryProposal.propTypes = {
  proposal: PropTypes.object.isRequired,
  convertInventoryProposal: PropTypes.func.isRequired,
  getInventoryProposal: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
}

export default ConvertInventoryProposal
