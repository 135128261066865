import {
  fork,
  call,
  take,
  takeEvery,
  takeLatest,
  put,
  all,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import sortBy from 'sort-by'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllPodsAction,
  getAllAvailablePodsAction,
  getAllAvailablePodsBySiteAction,
  getPodAction,
  getPodChartDataAction,
  createPodAction,
  updatePodAction,
  deletePodAction,
  getPodMetadataAction,
  getAllPodFirmwareVersionsAction,
  updatePodFirmwareAction,
  generatePillarIDsAction,
  getPodEditHistoryAction,
  getPodAssignmentHistoryAction,
  batchUpdatePodsAction,
  markPodAsLostAction,
  uploadPodsCSVAction,
  uploadCalibrationCSVAction,
  uploadCalibrationZipfileAction,
  getPodAssetsAction,
  getPodWorkflowResponsesAction,
  getPodWorkflowResponseAction,
  getPodCalibrationHistoryAction,
  getMultiplePodsDataAction,
} from '../actions/podsActions'
import { handleGetLocationSaga } from './locationsSaga'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { pickErrorMessage } from '../utils/helpers'
import { MESSAGES, RESOURCE_TYPES, WORKFLOW_TYPES } from '../constants'

// handlers

function* handleUploadPodsCSVSaga({ payload }) {
  try {
    const { formData } = payload

    const result = yield call(api.uploadPodsCSV, formData)
    yield put(uploadPodsCSVAction.success(result))
  } catch (error) {
    yield put(uploadPodsCSVAction.failure(error))

    if (error.status === 413) {
      const apiError = { message: 'file_too_large' }
      yield put(showErrorMessageAction(pickErrorMessage({ apiError })))
    } else if (error.status === 401) {
      yield put(unauthorizedAction())
    } else {
      yield put(showErrorMessageAction(pickErrorMessage(error)))
    }
  }
}

function* handleUploadCalibrationCSVSaga({ payload }) {
  try {
    const { formData } = payload

    const result = yield call(api.uploadCalibrationCSV, formData)
    yield put(uploadCalibrationCSVAction.success(result))
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPLOADED_CALIBRATION_CSV)
    )
  } catch (error) {
    yield put(uploadCalibrationCSVAction.failure(error))

    if (error.status === 413) {
      const apiError = { message: 'file_too_large' }
      yield put(showErrorMessageAction(pickErrorMessage({ apiError })))
    } else if (error.status === 401) {
      yield put(unauthorizedAction())
    } else {
      yield put(showErrorMessageAction(pickErrorMessage(error)))
    }
  }
}

function* handleUploadCalibrationZipfileSaga({ payload }) {
  try {
    const result = yield call(api.uploadCalibrationZipfile, payload)
    yield put(uploadCalibrationZipfileAction.success(result))
    yield put(
      showSuccessMessageAction(
        MESSAGES.SUCCESSFULLY_UPLOADED_CALIBRATION_ZIPFILE
      )
    )
  } catch (error) {
    yield put(uploadCalibrationZipfileAction.failure(error))

    if (error.status === 413) {
      const apiError = { message: 'file_too_large' }
      yield put(showErrorMessageAction(pickErrorMessage({ apiError })))
    } else if (error.status === 401) {
      yield put(unauthorizedAction())
    } else {
      yield put(showErrorMessageAction(pickErrorMessage(error)))
    }
  }
}

function* handleGetAllPodsSaga(qs) {
  try {
    const result = yield call(api.getAllPods, qs)
    yield put(getAllPodsAction.success(result))
  } catch (error) {
    yield put(getAllPodsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllAvailablePodsSaga() {
  try {
    const result = yield call(api.getAllAvailablePods)
    yield put(getAllAvailablePodsAction.success(result))
  } catch (error) {
    yield put(getAllAvailablePodsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllAvailablePodsBySiteSaga({ payload }) {
  try {
    const { siteSlug, qs } = payload

    const result = yield call(api.getAllAvailablePodsBySite, siteSlug, qs)
    yield put(getAllAvailablePodsBySiteAction.success(result))
  } catch (error) {
    yield put(getAllAvailablePodsBySiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodSaga(id) {
  try {
    const result = yield call(api.getPodById, id)
    yield put(getPodAction.success(result))
  } catch (error) {
    yield put(getPodAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodChartDataSaga({ id, qs }) {
  try {
    const result = yield call(api.getPodChartDataById, id, qs)
    yield put(getPodChartDataAction.success(result))
  } catch (error) {
    yield put(getPodChartDataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreatePodSaga(pod) {
  try {
    const result = yield call(api.createPod, pod)

    yield put(createPodAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/inventory/pods/${result.pillarId}`))
  } catch (error) {
    yield put(createPodAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGeneratePillarIDsSaga(payload) {
  try {
    const result = yield call(api.generatePillarIDs, payload)

    yield put(generatePillarIDsAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/inventory/all/pods`))
  } catch (error) {
    yield put(generatePillarIDsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdatePodSaga(pod) {
  try {
    const result = yield call(api.updatePod, pod)
    yield put(updatePodAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/pods/${result.pillarId}`))
  } catch (error) {
    yield put(updatePodAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleMarkLostPodSaga(pod) {
  try {
    const confirmUpdate = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmUpdate) {
      const result = yield call(api.markPodAsLost, pod)
      const { locationId, floorId, siteSlug } = pod
      yield fork(handleGetLocationSaga, {
        id: locationId,
        floorId,
        siteSlug,
      })
      yield put(markPodAsLostAction.success(result))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    }
  } catch (error) {
    yield put(updatePodAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeletePodSaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deletePod, id)
      const qs = yield call(
        createQueryString,
        parseQueryParams(global.location)
      )
      yield fork(handleGetAllPodsSaga, qs)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deletePodAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodMetadataSaga() {
  try {
    const result = yield call(api.getPodMetadata)
    yield put(getPodMetadataAction.success(result))
  } catch (error) {
    yield put(getPodMetadataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodFirmwareVersionsSaga() {
  try {
    const result = yield call(api.getAllPodFirmwareVersions)
    yield put(getAllPodFirmwareVersionsAction.success(result))
  } catch (error) {
    yield put(getAllPodFirmwareVersionsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdatePodFirmwareSaga({ payload }) {
  try {
    yield call(api.updatePodFirmware, payload)
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_SCHEDULED_FIRMWARE_UPDATE)
    )
    yield put(updatePodFirmwareAction.success())
  } catch (error) {
    yield put(updatePodFirmwareAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodAssignmentHistorySaga(id) {
  try {
    const result = yield call(api.getPodAssignmentHistory, id)
    yield put(getPodAssignmentHistoryAction.success(result))
  } catch (error) {
    yield put(getPodAssignmentHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodEditHistorySaga(id) {
  try {
    const result = yield call(api.getPodEditHistory, id)
    yield put(getPodEditHistoryAction.success(result))
  } catch (error) {
    yield put(getPodEditHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleBatchUpdatePodsSaga({ payload }) {
  try {
    const result = yield call(api.batchUpdatePods, payload)
    yield put(batchUpdatePodsAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(batchUpdatePodsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodAssetsSaga({ payload }) {
  try {
    const result = yield call(api.getPodAssets, payload)

    yield put(getPodAssetsAction.success(result.sort(sortBy('-uploadTime'))))
  } catch (error) {
    yield put(getPodAssetsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodWorkflowResponsesSaga({ payload }) {
  try {
    const result = yield call(
      api.getAllWorkflowResponsesByResourceSlug,
      WORKFLOW_TYPES.POD__REFURB,
      RESOURCE_TYPES.POD,
      payload
    )

    yield put(getPodWorkflowResponsesAction.success(result))
  } catch (error) {
    yield put(getPodWorkflowResponsesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodWorkflowResponseSaga({ payload }) {
  const { podSlug, workflowResponseSlug } = payload

  try {
    const result = yield call(
      api.getWorkflowResponseByResponseSlug,
      WORKFLOW_TYPES.POD__REFURB,
      RESOURCE_TYPES.POD,
      podSlug,
      workflowResponseSlug
    )

    yield put(getPodWorkflowResponseAction.success(result))
  } catch (error) {
    yield put(getPodWorkflowResponseAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodCalibrationHistorySaga({ payload }) {
  try {
    const { podPillarId, qs } = payload
    const result = yield call(api.getPodCalibrationHistory, podPillarId, qs)
    yield put(getPodCalibrationHistoryAction.success(result))
  } catch (error) {
    yield put(getPodCalibrationHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetMultiplePodsDataSaga({ payload }) {
  try {
    const { qs } = payload
    const result = yield call(api.getMultiplePodsData, qs)
    yield put(getMultiplePodsDataAction.success(result))
  } catch (error) {
    yield put(getMultiplePodsDataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchUploadPodsCSVSaga() {
  yield takeEvery(uploadPodsCSVAction.REQUEST, handleUploadPodsCSVSaga)
}

function* watchUploadCalibrationCSVSaga() {
  yield takeEvery(
    uploadCalibrationCSVAction.REQUEST,
    handleUploadCalibrationCSVSaga
  )
}

function* watchUploadCalibrationZipfileSaga() {
  yield takeEvery(
    uploadCalibrationZipfileAction.REQUEST,
    handleUploadCalibrationZipfileSaga
  )
}

function* watchGetAllPodsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllPodsAction.REQUEST)
    yield fork(handleGetAllPodsSaga, qs)
  }
}

function* watchGetAllAvailablePodsSaga() {
  yield takeEvery(
    getAllAvailablePodsAction.REQUEST,
    handleGetAllAvailablePodsSaga
  )
}

function* watchGetAllAvailablePodsBySiteSaga() {
  yield takeEvery(
    getAllAvailablePodsBySiteAction.REQUEST,
    handleGetAllAvailablePodsBySiteSaga
  )
}

function* watchGetPodSaga() {
  while (true) {
    const { payload } = yield take(getPodAction.REQUEST)
    yield fork(handleGetPodSaga, payload)
  }
}

function* watchGetPodAssignmentHistorySaga() {
  while (true) {
    const { payload } = yield take(getPodAssignmentHistoryAction.REQUEST)
    yield fork(handleGetPodAssignmentHistorySaga, payload)
  }
}

function* watchGetPodEditHistorySaga() {
  while (true) {
    const { payload } = yield take(getPodEditHistoryAction.REQUEST)
    yield fork(handleGetPodEditHistorySaga, payload)
  }
}

function* watchGetPodChartDataSaga() {
  while (true) {
    const { payload } = yield take(getPodChartDataAction.REQUEST)
    yield fork(handleGetPodChartDataSaga, payload)
  }
}

function* watchCreatePodSaga() {
  while (true) {
    const { payload } = yield take(createPodAction.REQUEST)
    yield fork(handleCreatePodSaga, payload)
  }
}

function* watchGeneratePillarIDsSaga() {
  while (true) {
    const { payload } = yield take(generatePillarIDsAction.REQUEST)
    yield fork(handleGeneratePillarIDsSaga, payload)
  }
}

function* watchUpdatePodSaga() {
  while (true) {
    const { payload } = yield take(updatePodAction.REQUEST)
    yield fork(handleUpdatePodSaga, payload)
  }
}

function* watchMarkLostPodSaga() {
  while (true) {
    const { payload } = yield take(markPodAsLostAction.REQUEST)
    yield fork(handleMarkLostPodSaga, payload)
  }
}

function* watchDeletePodSaga() {
  while (true) {
    const { payload } = yield take(deletePodAction.REQUEST)
    yield fork(handleDeletePodSaga, payload)
  }
}

function* watchGetPodMetadataSaga() {
  yield takeEvery(getPodMetadataAction.REQUEST, handleGetPodMetadataSaga)
}

function* watchGetPodFirmwareVersionsSaga() {
  yield takeEvery(
    getAllPodFirmwareVersionsAction.REQUEST,
    handleGetPodFirmwareVersionsSaga
  )
}

function* watchUpdatePodFirmwareSaga() {
  yield takeEvery(updatePodFirmwareAction.REQUEST, handleUpdatePodFirmwareSaga)
}

function* watchBatchUpdatePodsSaga() {
  yield takeEvery(batchUpdatePodsAction.REQUEST, handleBatchUpdatePodsSaga)
}

function* watchGetPodAssetsSaga() {
  yield takeEvery(getPodAssetsAction.REQUEST, handleGetPodAssetsSaga)
}

function* watchGetPodWorkflowResponsesSaga() {
  yield takeEvery(
    getPodWorkflowResponsesAction.REQUEST,
    handleGetPodWorkflowResponsesSaga
  )
}

function* watchGetPodWorkflowResponseSaga() {
  yield takeEvery(
    getPodWorkflowResponseAction.REQUEST,
    handleGetPodWorkflowResponseSaga
  )
}

function* watchGetPodCalibrationHistorySaga() {
  yield takeEvery(
    getPodCalibrationHistoryAction.REQUEST,
    handleGetPodCalibrationHistorySaga
  )
}

function* watchGetMultiplePodsDataSaga() {
  yield takeLatest(
    getMultiplePodsDataAction.REQUEST,
    handleGetMultiplePodsDataSaga
  )
}

function* podsSaga() {
  yield all([
    fork(watchGetAllPodsSaga),
    fork(watchGetAllAvailablePodsSaga),
    fork(watchGetAllAvailablePodsBySiteSaga),
    fork(watchGetPodSaga),
    fork(watchGetPodChartDataSaga),
    fork(watchCreatePodSaga),
    fork(watchUpdatePodSaga),
    fork(watchDeletePodSaga),
    fork(watchGetPodMetadataSaga),
    fork(watchGetPodFirmwareVersionsSaga),
    fork(watchUpdatePodFirmwareSaga),
    fork(watchGeneratePillarIDsSaga),
    fork(watchGetPodEditHistorySaga),
    fork(watchGetPodAssignmentHistorySaga),
    fork(watchBatchUpdatePodsSaga),
    fork(watchMarkLostPodSaga),
    fork(watchUploadPodsCSVSaga),
    fork(watchUploadCalibrationCSVSaga),
    fork(watchUploadCalibrationZipfileSaga),
    fork(watchGetPodAssetsSaga),
    fork(watchGetPodWorkflowResponsesSaga),
    fork(watchGetPodWorkflowResponseSaga),
    fork(watchGetPodCalibrationHistorySaga),
    fork(watchGetMultiplePodsDataSaga),
  ])
}

export {
  podsSaga as default,
  watchGetAllPodsSaga,
  watchGetAllAvailablePodsSaga,
  watchGetAllAvailablePodsBySiteSaga,
  watchGetPodSaga,
  watchCreatePodSaga,
  watchUpdatePodSaga,
  watchDeletePodSaga,
  watchGetPodMetadataSaga,
  watchGetPodChartDataSaga,
  watchGetPodFirmwareVersionsSaga,
  watchUpdatePodFirmwareSaga,
  watchGeneratePillarIDsSaga,
  watchGetPodAssignmentHistorySaga,
  watchGetPodEditHistorySaga,
  watchBatchUpdatePodsSaga,
  watchMarkLostPodSaga,
  watchUploadPodsCSVSaga,
  watchUploadCalibrationCSVSaga,
  watchUploadCalibrationZipfileSaga,
  watchGetPodAssetsSaga,
  watchGetPodWorkflowResponsesSaga,
  watchGetPodWorkflowResponseSaga,
  watchGetPodCalibrationHistorySaga,
  watchGetMultiplePodsDataSaga,
  handleUploadPodsCSVSaga,
  handleGetAllPodsSaga,
  handleGetAllAvailablePodsSaga,
  handleGetPodSaga,
  handleCreatePodSaga,
  handleUpdatePodSaga,
  handleDeletePodSaga,
  handleGetPodMetadataSaga,
  handleGeneratePillarIDsSaga,
  handleGetPodAssignmentHistorySaga,
  handleGetPodEditHistorySaga,
  handleMarkLostPodSaga,
}
