import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Spin } from './common/Ant'
import InputContainer from '../containers/common/InputContainer'
import { Bread, Crumb } from './common/Bread'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'

class AllLeads extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.leads,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }
  }

  static propTypes = {
    leads: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllLeads: PropTypes.func.isRequired,
    isUserLoading: PropTypes.bool.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    match: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    meta: PropTypes.object.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  componentDidMount() {
    const { getAllLeads } = this.props

    const { page, perPage } = parseQueryParams(global.location)

    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
    }

    this.setState(query)

    getAllLeads(createQueryString(query))
  }

  handleChange = (pagination, filters, sorter) => {
    const { match, getAllLeads, updateQueryParams } = this.props
    const { page, perPage } = this.state

    if (page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
      }

      const qs = createQueryString(query)

      getAllLeads(qs)

      updateQueryParams({
        pathname: match.url,
        search: qs,
      })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllLeads, updateQueryParams, match } = this.props
    const { page, perPage } = this.state

    const newValue = value === 'all' ? undefined : value
    const query = Object.assign({ perPage, page }, { [keyName]: newValue })

    this.setState({ [keyName]: newValue })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllLeads(qs)

    updateQueryParams({
      pathname: match.url,
      search: qs,
    })
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.leads
        .map(record => {
          const match =
            record.name.match(regex) ||
            record.email.match(regex) ||
            record.company.match(regex) ||
            record.message.match(regex)

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  render() {
    const { meta, isUserLoading = false } = this.props
    const { data, sortedInfo, searchText, perPage } = this.state

    return (
      <section className="mb5 AllLeads">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>Leads</Crumb>
            </Bread>
          </div>
        </div>
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: searchText,
              onChange: this.handleInputChange,
            }}
          />
        </div>
        {isUserLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{
              x: 750,
            }}
            bordered
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            tableLayout="auto"
          >
            <Column
              title="Name"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              width={200}
            />
            <Column
              title="Email"
              dataIndex="email"
              sorter={sortBy('email')}
              sortOrder={sortedInfo.field === 'email' && sortedInfo.order}
              width={200}
            />
            <Column
              title="Company"
              dataIndex="company"
              sorter={sortBy('company')}
              sortOrder={sortedInfo.field === 'company' && sortedInfo.order}
              width={200}
            />
            <Column
              title="Message"
              dataIndex="message"
              sorter={sortBy('message')}
              sortOrder={sortedInfo.field === 'message' && sortedInfo.order}
              width={200}
            />
          </Table>
        )}
      </section>
    )
  }
}

export default AllLeads
