import React from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import { Tooltip, Icon } from './common/Ant'
import { ActionAnchor, Anchor } from './common/Anchor'
import Logo from './common/Logo'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { renderPodType } from '../utils/textFormatters'
import { ALERT_TYPES, USER_PERMISSIONS, ROLES } from '../constants'

const LocationHeader = ({ markPodLost, site, floor, location, match }) => {
  const { name: siteName, slug: siteSlug, companyName, companyLogo } = site
  const { name: floorName, id: floorId } = floor
  const {
    name: locationName,
    flowMonitorPillarId,
    flowMonitorId,
    podPillarId,
    alertType,
    alertActive,
    podId,
    id,
    podType,
  } = location

  return (
    <div className="LocationHeader">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>
              {hasRole(
                ROLES.JOBSITE_ADMIN,
                ROLES.ORGANIZATION_ADMIN,
                ROLES.PILLAR_CONTRACTOR,
                ROLES.PILLAR_ADMIN,
                ROLES.SUPER_ADMIN
              ) ? (
                <Anchor to="/sites">Sites</Anchor>
              ) : (
                'Sites'
              )}
            </Crumb>
            {siteName && (
              <Crumb>
                {hasRole(
                  ROLES.JOBSITE_ADMIN,
                  ROLES.ORGANIZATION_ADMIN,
                  ROLES.PILLAR_CONTRACTOR,
                  ROLES.PILLAR_ADMIN,
                  ROLES.SUPER_ADMIN
                ) ? (
                  <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
                ) : (
                  siteName
                )}
              </Crumb>
            )}
            {floorName && (
              <Crumb>
                {hasRole(
                  ROLES.ORGANIZATION_ADMIN,
                  ROLES.PILLAR_CONTRACTOR,
                  ROLES.PILLAR_ADMIN,
                  ROLES.SUPER_ADMIN
                ) ? (
                  <Anchor to={`/sites/${siteSlug}/floors/${floorId}`}>
                    {floorName}
                  </Anchor>
                ) : (
                  floorName
                )}
              </Crumb>
            )}
            <Crumb>
              {locationName}
              {flowMonitorPillarId && (
                <span>
                  {' '}
                  (
                  {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
                    <Anchor
                      to={`/inventory/flow-monitors/${parseInt(flowMonitorId)}`}
                    >
                      {flowMonitorPillarId}
                    </Anchor>
                  ) : (
                    flowMonitorPillarId
                  )}
                  )
                  {alertActive && alertType !== ALERT_TYPES.THRESHOLD ? (
                    <Tooltip title="Offline">
                      <Icon type="poweroff" className="pl1 red f4" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Online">
                      <Icon type="check-circle-o" className="pl1 green f4" />
                    </Tooltip>
                  )}
                </span>
              )}
              {podPillarId && (
                <span>
                  {alertActive && alertType !== ALERT_TYPES.THRESHOLD ? (
                    <Tooltip title="Offline">
                      <Icon type="poweroff" className="pl1 red f4" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Online">
                      <Icon type="check-circle-o" className="pl1 green f4" />
                    </Tooltip>
                  )}
                </span>
              )}
            </Crumb>
          </Bread>
        </div>
        <div className="flex justify-center mb3 b">
          {hasPermission(USER_PERMISSIONS.VIEW_POD) ? (
            <Anchor to={`/inventory/pods/${podPillarId}`}>{podPillarId}</Anchor>
          ) : (
            podPillarId
          )}{' '}
          <span className="pl1 gray">({renderPodType(podType)})</span>
        </div>
        <div className="flex justify-center mb3">
          {hasPermission(USER_PERMISSIONS.EDIT_LOCATION) && (
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          )}
          {hasPermission(USER_PERMISSIONS.EDIT_POD) && podPillarId && (
            <span>
              <Divider vertical />
              <ActionAnchor
                onClick={() => markPodLost(podId, id, floorId, siteSlug)}
              >
                Mark Smart Pod Lost
              </ActionAnchor>
            </span>
          )}
        </div>
        <div className="flex justify-center">
          <Logo companyName={companyName} companyLogo={companyLogo} />
        </div>
      </div>
    </div>
  )
}

LocationHeader.propTypes = {
  site: PropTypes.object.isRequired,
  floor: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  markPodLost: PropTypes.func.isRequired,
  match: PropTypes.object,
}

export default LocationHeader
