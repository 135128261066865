import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FloorBlock from './FloorBlock'
import { Spin } from './common/Ant'
import { hasRole } from '../utils/hasPermission'
import { ROLES } from '../constants'

class AllFloorsBlock extends Component {
  static propTypes = {
    floors: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteSlug: PropTypes.string.isRequired,
    handleModalOpen: PropTypes.func.isRequired,
    deleteFloor: PropTypes.func.isRequired,
    moveFloor: PropTypes.func,
    isFloorLoading: PropTypes.bool,
    viewType: PropTypes.string.isRequired,
    isSorting: PropTypes.bool.isRequired,
    buildingId: PropTypes.number,
  }

  static defaultProps = {
    viewType: 'locations',
  }

  render() {
    const {
      siteSlug,
      floors,
      isFloorLoading,
      handleModalOpen,
      deleteFloor,
      viewType,
      moveFloor,
      isSorting,
      buildingId,
    } = this.props

    return (
      <section className="AllFloorsBlock">
        {isFloorLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          floors
            .filter(
              x => hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) || x.active
            )
            .map((floor, index, arr) => (
              <FloorBlock
                key={floor.id}
                siteSlug={siteSlug}
                floor={floor}
                index={index}
                floors={arr}
                handleModalOpen={handleModalOpen}
                deleteFloor={deleteFloor}
                viewType={viewType}
                moveFloor={moveFloor}
                isSorting={isSorting}
                buildingId={buildingId}
              />
            ))
        )}
      </section>
    )
  }
}

export default AllFloorsBlock
