import React from 'react'
import PropTypes from 'prop-types'
import { H3, H4 } from './Headers'
import { Anchor } from './Anchor'
import { formatPhoneNumber } from '../../utils/forms'
import { dateTimeFormatter } from '../../utils/date'
import { toTitleCase } from '../../utils/textFormatters'
import { hasPermission } from '../../utils/hasPermission'
import { USER_PERMISSIONS } from '../../constants'

const UserStats = ({ user }) => {
  const {
    slug,
    firstName,
    lastName,
    role,
    companyName,
    phoneNumber,
    email,
    createdAt,
    confirmedAt,
    sites,
    stats: { lastSeen, lastLogIn },
  } = user

  return (
    <div className="UserStats mb3">
      <div className="mb3">
        <H3>
          {firstName} {lastName}
        </H3>
        <div>{companyName}</div>
        <div>{toTitleCase(role)}</div>
        <div>
          <a className="dark-blue link" href={`tel:${phoneNumber}`}>
            {formatPhoneNumber(phoneNumber)}
          </a>
        </div>
        <div className="mb3">
          <a className="dark-blue link" href={`mailto:${email}`}>
            {email}
          </a>
        </div>

        <div className="f7 mb3">
          <div>
            <span className="b">Last Seen:</span>{' '}
            {lastSeen ? dateTimeFormatter(lastSeen, true) : '--'}
          </div>
          <div>
            <span className="b">Last Login:</span>{' '}
            {lastLogIn ? dateTimeFormatter(lastLogIn, true) : '--'}
          </div>
          <div>
            <span className="b">Created:</span>{' '}
            {dateTimeFormatter(createdAt, true)}
          </div>
          <div>
            <span className="b">Confirmed:</span>{' '}
            {confirmedAt ? (
              dateTimeFormatter(confirmedAt, true)
            ) : (
              <span className="i">Unconfirmed User</span>
            )}
          </div>
        </div>

        {hasPermission(USER_PERMISSIONS.EDIT_USER) && (
          <div className="f7">
            <Anchor to={`/users/${slug}/permissions`}>
              Edit {firstName}'s Permissions
            </Anchor>
          </div>
        )}
      </div>

      <div className="bg-near-white br2 pa2">
        <H4>Subscribed Sites</H4>
        {!!sites.length ? (
          <ul className="list mb0 pl0">
            {sites.map(x => (
              <li key={x.siteSlug}>
                <Anchor to={`/sites/${x.siteSlug}`}>
                  {x.isDefault ? <span className="b">{x.name}</span> : x.name}
                </Anchor>
              </li>
            ))}
          </ul>
        ) : (
          <div className="tc">No Sites</div>
        )}
      </div>
    </div>
  )
}

UserStats.propTypes = {
  user: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    confirmedAt: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    stats: PropTypes.shape({
      lastSeen: PropTypes.string.isRequired,
      lastLogIn: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default UserStats
