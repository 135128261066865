import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import EditAlertSummaryForm from '../components/forms/EditAlertSummaryForm'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import { createAlertSummaryAction } from '../actions/alertSummariesActions'

class CreateAlertSummary extends Component {
  static propTypes = {
    getAllSites: PropTypes.func.isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    getAggregateThresholdsBySite: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object,
  }

  render() {
    const {
      getAllSites,
      getAllUsersBySite,
      getAggregateThresholdsBySite,
      sites,
      users,
      thresholds,
      formValues,
    } = this.props

    return (
      <section className="CreateAlertSummary">
        <div className="mb3">
          <Bread>
            <Crumb>
              <Anchor to="/reports">Reports</Anchor>
            </Crumb>
            <Crumb>
              <Anchor to="/reports/alert-summaries">Alert Summaries</Anchor>
            </Crumb>
            <Crumb>Create</Crumb>
          </Bread>
        </div>
        <EditAlertSummaryForm
          submitAction={createAlertSummaryAction}
          getAllSites={getAllSites}
          getAllUsersBySite={getAllUsersBySite}
          getAggregateThresholdsBySite={getAggregateThresholdsBySite}
          sites={sites}
          users={users}
          thresholds={thresholds}
          initialValues={{
            siteUserIds: [],
            thresholdIds: [],
            active: true,
            skipSystemAlerts: true,
            scheduledTime: moment().format('HH:mm:00+00:00'),
            scheduledDate: moment().toISOString(),
          }}
          formValues={formValues}
        />
      </section>
    )
  }
}

export default CreateAlertSummary
