import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getSensorReading } from '../../utils/sensorData'
// import { createQueryString, parseQueryParams } from '../../utils/queryParams'
import { IMU_SENSOR_READING_TYPES, COLORS } from '../../constants'
import { snakeCaseToCamelCase } from '../../utils/textFormatters'
import moment from 'moment'

const ImuSensorChart = ({ data, columns, updateQueryParams }) => {
  const [visibleReadings, setVisibleReadings] = useState([
    IMU_SENSOR_READING_TYPES.CABLE_OCCUPANCY_ToF,
  ])

  // TODO build default opts object and extend for each chart??
  const chartOptions = {
    credits: { enabled: false },
    exporting: { enabled: false },
    chart: {
      style: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
      },
    },
    plotOptions: {
      series: {
        type: 'line',
        events: {
          legendItemClick: ({ target: { userOptions } }) => {
            if (!userOptions.visible) {
              visibleReadings.push(userOptions.columnName)
            } else {
              const index = visibleReadings.indexOf(userOptions.columnName)
              visibleReadings.splice(index, 1)
            }

            // TODO this isn't working...chart doesn't update...I dunno
            // const {
            //   endTime,
            //   startTime,
            //   smooth,
            //   smoothInterval
            // } = parseQueryParams(global.location)
            // updateQueryParams({
            //   search: createQueryString({
            //     endTime,
            //     startTime,
            //     smooth,
            //     smoothInterval
            //     // visibleReadings
            //   })
            // })

            setVisibleReadings(visibleReadings)
          },
        },
      },
      line: {
        animation: false,
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'longdash',
      labels: {
        style: {
          color: COLORS.BLACK_80,
          fontSize: '0.75rem',
        },
      },
      title: {
        text: null,
      },
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'longdash',
      type: 'datetime',
      labels: {
        style: {
          color: COLORS.BLACK_80,
          fontSize: '0.75rem',
        },
      },
      title: {
        text: null,
      },
    },
    tooltip: {
      valueDecimals: 2,
      headerFormat:
        "<span style='font-size: 0.875rem; margin-bottom: 0.875rem'>{point.key}</span><br />",
      style: {
        fontSize: '0.875rem',
        color: COLORS.BLACK_80,
      },
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '0.75rem',
        color: COLORS.BLACK_80,
      },
      itemHoverStyle: {
        color: COLORS.MOON_GRAY,
      },
    },
    series: columns.map(x => {
      const columnX = snakeCaseToCamelCase(x)
      const readingData = getSensorReading(columnX)
      return {
        visible: visibleReadings.indexOf(columnX) !== -1,
        name: readingData.name,
        columnName: columnX,
        data: data.map(y => [
          moment(y.time).valueOf(),
          Number(parseFloat(y[columnX]).toFixed(2)),
        ]),
        tooltip: {
          valueSuffix: readingData.unit,
        },
      }
    }),
  }

  return (
    <div className="ImuSensorChart">
      <div className="mb3">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  )
}

ImuSensorChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateQueryParams: PropTypes.func.isRequired,
}

export default ImuSensorChart
