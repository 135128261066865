import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import cx from 'classnames'
import LocationBlocks from './LocationBlocks'
import ReadingSummary from './ReadingSummary'
import { Dropdown, Menu, Icon, Tooltip } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'

export const ItemTypes = {
  FLOOR: 'FLOOR',
}

const FloorBlock = ({
  siteSlug,
  buildingId,
  handleModalOpen,
  deleteFloor,
  viewType,
  floor,
  index,
  floors,
  moveFloor,
  isSorting,
}) => {
  const ref = useRef(null)
  const { id, floor: floorNumber, name, slug, summary = {} } = floor

  const header = (
    <div className="lh-title">
      {floorNumber}
      <span className="dn dib-l">: {name}</span>
    </div>
  )

  const borderPrefix = cx('b--silver', {
    br2: floors.length === 1,
    bt: floors.length > 1 && index > 0,
    'br2 br--bottom': floors.length > 1 && index === floors.length - 1,
    'br2 br--top': floors.length > 1 && index === 0,
  })

  const overlay = hasPermission(USER_PERMISSIONS.EDIT_FLOOR) ? (
    <Menu>
      <Menu.Item key="view-floor">
        <Anchor to={`/sites/${siteSlug}/floors/${id}`}>
          View/Edit Floor Plan
        </Anchor>
      </Menu.Item>
      <Menu.Item key="edit-floor">
        <Anchor to={`/sites/${siteSlug}/floors/${id}/edit`}>Edit</Anchor>
      </Menu.Item>
      {hasPermission(USER_PERMISSIONS.DELETE_FLOOR) && (
        <Menu.Item key="delete-floor">
          <ActionAnchor onClick={() => deleteFloor(siteSlug, id)}>
            Delete
          </ActionAnchor>
        </Menu.Item>
      )}
    </Menu>
  ) : hasPermission(USER_PERMISSIONS.VIEW_FLOOR) ? (
    <Menu>
      <Menu.Item key="view-floor">
        <Anchor to={`/sites/${siteSlug}/floors/${id}`}>
          View/Edit Floor Plan
        </Anchor>
      </Menu.Item>
    </Menu>
  ) : null

  const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  }

  const [{ handlerId }, drop] = useDrop({
    accept: `${siteSlug} + ${buildingId}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveFloor(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: `${siteSlug} + ${buildingId}`,
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <div
      ref={ref}
      className="hover-bg-light-gray ph1 br2"
      data-handler-id={handlerId}
      style={isSorting ? { ...style, opacity } : null}
    >
      <div className="flex items-center">
        <div className="w-20 w-50-ns mr2-ns flex items-center-ns justify-between">
          {hasPermission(USER_PERMISSIONS.EDIT_FLOOR) ||
          hasPermission(USER_PERMISSIONS.EDIT_LOCATION) ? (
            <>
              <div className="flex items-center">
                {!floor.active && (
                  <Tooltip title="Inactive">
                    <Icon type="poweroff" className="pr2 black-50" />
                  </Tooltip>
                )}
                <ActionAnchor onClick={() => handleModalOpen(slug)}>
                  {header}
                </ActionAnchor>
              </div>
              <div className="dn db-ns ml2 tr" style={{ minWidth: '75px' }}>
                <Dropdown overlay={overlay} trigger={['click']}>
                  <a className="ant-dropdown-link">
                    Actions <Icon type="down" />
                  </a>
                </Dropdown>
              </div>
            </>
          ) : (
            <ActionAnchor onClick={() => handleModalOpen(slug)}>
              {header}
            </ActionAnchor>
          )}
        </div>
        <div className="w-80 w-50-ns ml2">
          {viewType === 'locations' ? (
            <LocationBlocks
              borderPrefix={borderPrefix}
              floor={floor}
              handleModalOpen={handleModalOpen}
            />
          ) : (
            <ReadingSummary summary={summary} borderPrefix={borderPrefix} />
          )}
        </div>
      </div>
    </div>
  )
}

FloorBlock.propTypes = {
  floor: PropTypes.object.isRequired,
  siteSlug: PropTypes.string.isRequired,
  buildingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleModalOpen: PropTypes.func.isRequired,
  deleteFloor: PropTypes.func.isRequired,
  moveFloor: PropTypes.func,
  isFloorLoading: PropTypes.bool,
  viewType: PropTypes.string.isRequired,
  isSorting: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default FloorBlock
