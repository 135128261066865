import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import { Spin, Steps, Step, Table, Column } from '../common/Ant'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { toTitleCase } from '../../utils/textFormatters'
import { PART_RESOURCE_TYPE, SIZES } from '../../constants'
import { required, isAlphaNumeric } from '../../utils/validators'
import { H3, H4 } from '../common/Headers'
import Block from '../common/Block'
import { ActionAnchor } from '../common/Anchor'
import actions from 'redux-form/lib/actions'
import { connect } from 'react-redux'

const { change } = actions

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, value) =>
    dispatch(change('EditPartForm', field, value)),
})

class Vendor extends Component {
  static propTypes = {
    changeFormField: PropTypes.func.isRequired,
    vendor: PropTypes.string.isRequired,
  }

  renderFields = vendor => {
    return (
      <div className="flex-ns">
        <Field
          name={`${vendor}.name`}
          type="text"
          component={InputContainer}
          label={`Name`}
          validate={[required, isAlphaNumeric]}
          className="mr3-ns w-20-ns"
        />
        <Field
          name={`${vendor}.vendorPn`}
          type="text"
          component={InputContainer}
          label={`P/N`}
          validate={[required, isAlphaNumeric]}
          className="mr3-ns w-20-ns"
        />
        <Field
          name={`${vendor}.description`}
          type="text"
          component={InputContainer}
          label={`Description`}
          className="w-60-ns"
        />
      </div>
    )
  }

  render() {
    const { vendor } = this.props

    return <div>{this.renderFields(vendor)}</div>
  }
}

const ConnectedVendor = connect(mapDispatchToProps)(Vendor)

const Vendors = ({ fields, meta: { error } }) => (
  <div className="mb3">
    <H3>Vendors </H3>
    {fields.map((vendor, index) => {
      return (
        <div key={index}>
          {index > 0 ? <Divider /> : null}
          <H4 inline>Vendor {index + 1}</H4>
          <ActionAnchor onClick={e => fields.remove(index)}>
            Remove
          </ActionAnchor>
          <ConnectedVendor vendor={vendor} />
        </div>
      )
    })}
    <ActionAnchor onClick={e => fields.push()} button>
      Create New Vendor
    </ActionAnchor>
  </div>
)

Vendors.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object,
}

class Manufacturer extends Component {
  static propTypes = {
    changeFormField: PropTypes.func.isRequired,
    manufacturer: PropTypes.string.isRequired,
  }

  renderFields = manufacturer => {
    return (
      <div className="ba b--light-gray">
        <div className="flex-ns mh3 mv3">
          <Field
            name={`${manufacturer}.name`}
            type="text"
            component={InputContainer}
            label={`Name`}
            validate={[required, isAlphaNumeric]}
            className="mr3-ns w-20-ns"
          />
          <Field
            name={`${manufacturer}.manufacturerPn`}
            type="text"
            component={InputContainer}
            label={`P/N`}
            validate={[required, isAlphaNumeric]}
            className="mr3-ns w-20-ns"
          />
          <Field
            name={`${manufacturer}.description`}
            type="text"
            component={InputContainer}
            label={`Description`}
            className="w-60-ns"
          />
        </div>
        <div className="ml3 mr3">
          <FieldArray name={`${manufacturer}.vendors`} component={Vendors} />
        </div>
      </div>
    )
  }

  render() {
    const { manufacturer } = this.props

    return <div>{this.renderFields(manufacturer)}</div>
  }
}

const ConnectedManufacturer = connect(mapDispatchToProps)(Manufacturer)

const Manufacturers = ({ fields, meta: { error } }) => (
  <div className="mb3">
    <H3>Manufacturers </H3>
    {fields.map((manufacturer, index) => {
      return (
        <div key={index} className="mt3">
          <H4 inline>Manufacturer {index + 1}</H4>
          <ActionAnchor onClick={e => fields.remove(index)}>
            Remove
          </ActionAnchor>
          <ConnectedManufacturer manufacturer={manufacturer} />
        </div>
      )
    })}
    <ActionAnchor className="mt3" onClick={e => fields.push()} button>
      Create New Manufacturer
    </ActionAnchor>
  </div>
)

Manufacturers.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object,
}

class EditPartForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    isPartLoading: PropTypes.bool,
    touch: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    anyTouched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      current: 0,
      reviewInputs: {},
    }

    this.inputs = {}
  }

  handleNext = event => {
    const { valid, anyTouched, touch } = this.props
    const { current } = this.state

    if (anyTouched && valid) {
      let reviewInputs = {}
      Object.keys(this.inputs).map(
        key => (reviewInputs[key] = this.inputs[key].value)
      )
      this.setState({ reviewInputs })
      const cur = current + 1
      this.setState({ current: cur })
    } else {
      const fieldNames = Object.keys(this.inputs).map(
        x => this.inputs[x].props.name
      )
      touch(...fieldNames)
    }

    event.preventDefault()
  }

  handlePrev = event => {
    const current = this.state.current - 1
    this.setState({ current })

    event.preventDefault()
  }

  render() {
    const {
      submitAction,
      handleSubmit,
      submitting,
      isPartLoading,
      error,
    } = this.props

    const { current, reviewInputs } = this.state

    const submit = handleSubmit(submitAction)

    let steps = [
      {
        title: 'Add Part Details',
        content: (
          <div>
            <H3>Part Info </H3>
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="name"
                type="text"
                component={InputContainer}
                label="Part Name"
                validate={[required, isAlphaNumeric]}
                className="mr3-ns w-30-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="partType"
                component={SelectContainer}
                label="Part Resource Type"
                placeholder="Select part type"
                validate={required}
                className="mr3-ns w-30-ns"
                filterable
              >
                {Object.keys(PART_RESOURCE_TYPE).map(x => (
                  <Option value={PART_RESOURCE_TYPE[x]} key={x}>
                    {toTitleCase(PART_RESOURCE_TYPE[x])}
                  </Option>
                ))}
              </Field>
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="description"
                type="text"
                component={InputContainer}
                label="Description"
                className="w-40-ns"
              />
            </div>
            <Divider />
            <FieldArray
              ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
              name="manufacturers"
              component={Manufacturers}
            />
          </div>
        ),
      },
    ]

    steps.push({
      title: `Review and Submit`,
      content: reviewInputs ? (
        <div>
          <div className="flex flex-wrap">
            <Block label="Name">{reviewInputs['name']}</Block>
            <Block label="Part Resource Type">
              {reviewInputs['partType'] &&
                toTitleCase(reviewInputs['partType'])}
            </Block>
            <Block label="Number of Manufacturers">
              {reviewInputs['manufacturers']
                ? reviewInputs['manufacturers'].length
                : 0}
            </Block>
            <Block label="Description">{reviewInputs['description']}</Block>
          </div>
          <Divider />
          <H3>Manufacturers</H3>
          {reviewInputs['manufacturers'] && reviewInputs['manufacturers'].length
            ? reviewInputs['manufacturers'].map((manf, index) => (
                <div key={index} className="ba b--light-gray">
                  <div className="mh3 mv3">
                    <H4>Manufacturer {index + 1}</H4>
                    <div className="flex flex-wrap">
                      <Block label="Name">{manf['name']}</Block>
                      <Block label="Manufacturer P/N">
                        {manf['manufacturerPn']}
                      </Block>
                      <Block label="Description">{manf['description']}</Block>
                    </div>
                    <H4>Vendors</H4>
                    {manf['vendors'] && manf['vendors'].length ? (
                      <Table
                        dataSource={manf['vendors']}
                        rowKey="vendorPn"
                        scroll={{ x: 600 }}
                        bordered
                        tableLayout="auto"
                        pagination={false}
                      >
                        <Column
                          title="Vendor Name"
                          dataIndex="name"
                          width={300}
                          render={text => (text ? text : '--')}
                        />
                        <Column
                          title="Vendor P/N"
                          dataIndex="vendorPn"
                          width={300}
                          render={text => (text ? text : '--')}
                        />
                        <Column
                          title="Description"
                          dataIndex="description"
                          width={300}
                          render={text => (text ? text : '--')}
                        />
                      </Table>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              ))
            : '--'}
        </div>
      ) : (
        'No inputs yet'
      ),
    })

    return isPartLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EditPartForm">
        <Form onSubmit={submit}>
          <Steps current={current} className="mb3">
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
            {steps[current].content}
          </div>
          <div className="mb3 flex justify-between">
            <Button
              disabled={current === 0}
              size={SIZES.SMALL}
              onClick={this.handlePrev}
              text="Previous"
            />
            <Button
              disabled={current >= steps.length - 1}
              size={SIZES.SMALL}
              onClick={this.handleNext}
              text="Next"
            />
          </div>
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/inventory/all/parts" />
            <Button
              disabled={current !== steps.length - 1 || !this.props.valid}
              text="Submit"
              type="submit"
              submitting={submitting}
            />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditPartForm' })(EditPartForm)
