import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllNetworksAction,
  createNetworkAuthorityAction,
  getAllNetworkAuthoritiesAction,
  getNetworkAction,
  CLEAR_NETWORK_AUTHORITY_TOKEN,
  regenerateNetworkAuthorityTokenAction,
} from '../actions/networksActions'

const token = (state = '', { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case createNetworkAuthorityAction.SUCCESS:
      case regenerateNetworkAuthorityTokenAction.SUCCESS:
        return payload.token
      case CLEAR_NETWORK_AUTHORITY_TOKEN:
        return ''
    }
  })

const networksBySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllNetworksAction.SUCCESS:
        payload.items.forEach(x => {
          draft[x.slug] = x
        })
        break
    }
  })

const networksVisibleSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllNetworksAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const networksMeta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllNetworksAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const authoritiesBySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllNetworkAuthoritiesAction.SUCCESS:
        payload.items.forEach(x => {
          draft[x.slug] = x
        })
        break
    }
  })

const authoritiesVisibleSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllNetworkAuthoritiesAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const authoritiesMeta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllNetworkAuthoritiesAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const current = (
  state = {
    pods: [],
    gateways: [],
    name: '',
    authorityName: '',
    timingMode: 2000,
    grantKeys: false,
  },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getNetworkAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const networksReducer = combineReducers({
  token,
  authoritiesBySlug,
  authoritiesVisibleSlugs,
  authoritiesMeta,
  networksBySlug,
  networksVisibleSlugs,
  networksMeta,
  current,
})

const getNetworkAuthorityToken = ({ token }) => token
const getNetworkAuthority = (state, slug) => state.authoritiesBySlug[slug]
const getVisibleNetworkAuthorities = state =>
  state.authoritiesVisibleSlugs.map(slug => getNetworkAuthority(state, slug))
const getNetworkAuthorityMeta = state => state.authoritiesMeta

const getNetwork = (state, slug) => state.networksBySlug[slug]
const getVisibleNetworks = state =>
  state.networksVisibleSlugs.map(slug => getNetwork(state, slug))
const getNetworkMeta = state => state.networksMeta
const getCurrentNetwork = state => state.current

export {
  networksReducer as default,
  getNetworkAuthorityToken,
  getVisibleNetworkAuthorities,
  getNetworkAuthorityMeta,
  getVisibleNetworks,
  getNetworkMeta,
  getCurrentNetwork,
}
