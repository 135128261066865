import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import InputContainer from '../containers/common/InputContainer'
import { toTitleCase } from '../utils/textFormatters'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS, ALL_SUPPLY_TYPES } from '../constants'

class AllGatewaySupplies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.gatewaySupplies,
      searchText: '',
      filtered: false,
    }
  }

  static propTypes = {
    gatewaySupplies: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    getAllGatewaySupplies: PropTypes.func.isRequired,
    deleteGatewaySupply: PropTypes.func.isRequired,
    isGatewaySupplyLoading: PropTypes.bool.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  componentDidMount() {
    const { getAllGatewaySupplies } = this.props

    getAllGatewaySupplies()
  }

  handleChange = (pagination, filters, sorter) => {
    const { getAllGatewaySupplies } = this.props

    getAllGatewaySupplies()

    this.setState({
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(searchText, 'gi')

    this.setState((state, props) => {
      const data = props.gatewaySupplies
        ? props.gatewaySupplies
            .map(record => {
              let match = null
              if (record.name) {
                match = record.name.match(regex)
              } else if (record.id) {
                match = record.id.toString().match(regex)
              }

              return match ? record : null
            })
            .filter(record => !!record)
        : []

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  render() {
    const {
      deleteGatewaySupply,
      windowWidth,
      isGatewaySupplyLoading,
    } = this.props
    const { data, sortedInfo, searchText } = this.state

    const isFixedColumn = windowWidth < 750

    return (
      <section className="mb5 AllGatewaySupplies">
        {hasPermission(USER_PERMISSIONS.ADD_GATEWAY_SUPPLY) && (
          <div>
            <Anchor
              to="/inventory/gateway-supplies/create"
              className="mb3"
              button
            >
              Create a Network Gateway Supply
            </Anchor>
          </div>
        )}
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: searchText,
              onChange: this.handleInputChange,
            }}
          />
        </div>
        {isGatewaySupplyLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{ x: 650 }}
            bordered
            tableLayout="auto"
            pagination={false}
          >
            <Column
              title="Network Gateway Supply Type"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              width={300}
              render={(text, record) =>
                text ? (
                  <Anchor to={`/inventory/gateway-supplies/${record.id}`}>
                    {toTitleCase(ALL_SUPPLY_TYPES[text])}
                  </Anchor>
                ) : (
                  '--'
                )
              }
            />
            <Column
              title="Quantity"
              dataIndex="quantity"
              sorter={sortBy('quantity')}
              sortOrder={sortedInfo.field === 'quantity' && sortedInfo.order}
              width={300}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Description"
              dataIndex="description"
              sorter={sortBy('description')}
              sortOrder={sortedInfo.field === 'description' && sortedInfo.order}
              width={300}
              render={text => (text ? text : '--')}
            />
            {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY_SUPPLY) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={100}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-gateway-supply">
                        <Anchor
                          to={`/inventory/gateway-supplies/${record.id}/edit`}
                        >
                          Edit
                        </Anchor>
                      </Menu.Item>
                      <Menu.Item key="delete-gateway-supply">
                        <ActionAnchor
                          onClick={() => deleteGatewaySupply(record.id)}
                        >
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )
                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllGatewaySupplies
