const TOKEN = '__pillarToken'
const REFRESH_TOKEN = '__pillarRefreshToken'
const USER_SLUG = '__userSlug'
const FULL_WIDTH = '__pillarFullWidth'
const IGNORE_REPORT_JOYRIDE = '__pillar__IGNORE_REPORT_JOYRIDE'

const setItem = (keyName, value) => {
  try {
    value
      ? global.localStorage.setItem(keyName, value)
      : global.localStorage.removeItem(keyName)
  } catch (error) {
    console.error('localStorage not available!', error)
  }
}

const getItem = keyName => {
  try {
    return global.localStorage.getItem(keyName)
  } catch (error) {
    console.error('localStorage not available!')
  }
}

const setToken = token => setItem(TOKEN, token)
const setRefreshToken = refreshToken => setItem(REFRESH_TOKEN, refreshToken)
const setUserSlug = slug => setItem(USER_SLUG, slug)

const getToken = () => getItem(TOKEN)
const getRefreshToken = () => getItem(REFRESH_TOKEN)
const getUserSlug = () => getItem(USER_SLUG)

const clearTokens = () => {
  try {
    global.localStorage.removeItem(TOKEN)
    global.localStorage.removeItem(REFRESH_TOKEN)
  } catch (error) {
    console.error('localStorage not available!', error)
  }
}

const clearUserSlug = () => {
  try {
    global.localStorage.removeItem(USER_SLUG)
  } catch (error) {
    console.error('localStorage not available!', error)
  }
}

const setFullWidth = x => setItem(FULL_WIDTH, x)
const getFullWidth = () => getItem(FULL_WIDTH) === 'true'

const setIgnoreReportJoyride = x => setItem(IGNORE_REPORT_JOYRIDE, x)
const getIgnoreReportJoyride = () => getItem(IGNORE_REPORT_JOYRIDE) === 'true'

export {
  setToken,
  setRefreshToken,
  getToken,
  getRefreshToken,
  clearTokens,
  setUserSlug,
  getUserSlug,
  clearUserSlug,
  setFullWidth,
  getFullWidth,
  setIgnoreReportJoyride,
  getIgnoreReportJoyride,
}
