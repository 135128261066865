import {
  fork,
  call,
  take,
  takeLatest,
  put,
  all,
  select,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllFloorsAction,
  getFloorAction,
  createFloorAction,
  submitSiteFloorsAction,
  updateFloorAction,
  uploadFloorPlanAction,
  deleteFloorAction,
  getAllFloorSummariesAction,
  getFloorSummaryAction,
  addThresholdToFloorAction,
  deleteThresholdFromFloorAction,
  getAllFloorThresholdUserMappingsAction,
  updateFloorThresholdUserMappingAction,
  getAllFloorsByBuildingAction,
  getAllFloorSummariesByBuildingAction,
  updateSortOrderAction,
} from '../actions/floorsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { getPathSnippetAtIndex, pickErrorMessage } from '../utils/helpers'
import {
  getAllAggregateThresholdsBySiteAction,
  getAllSiteAggregateThresholdUserMappingsAction,
} from '../actions/sitesActions'
import { createQueryString } from '../utils/queryParams'

// selectors

const getSiteSlug = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 1)

const getFloorId = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 3)

// handlers

function* handleGetAllFloorsSaga({ siteSlug, qs }) {
  try {
    const payload = yield call(api.getAllFloors, siteSlug, qs)
    yield put(getAllFloorsAction.success(payload))
  } catch (error) {
    yield put(getAllFloorsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetFloorSaga({ siteSlug, floorId }) {
  try {
    const payload = yield call(api.getFloorByFloor, siteSlug, floorId)
    yield put(getFloorAction.success(payload))
  } catch (error) {
    yield put(getFloorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllFloorSummariesSaga({ siteSlug }) {
  try {
    const payload = yield call(api.getAllFloorSummaries, siteSlug)
    yield put(getAllFloorSummariesAction.success(payload))
  } catch (error) {
    yield put(getAllFloorSummariesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetFloorSummarySaga({ siteSlug, floorId }) {
  try {
    const payload = yield call(api.getFloorSummary, siteSlug, floorId)
    yield put(getFloorSummaryAction.success(payload))
  } catch (error) {
    yield put(getFloorSummaryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateFloorSaga(floor) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const payload = yield call(api.createFloor, siteSlug, floor)
    yield put(createFloorAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/sites/${siteSlug}/floors/${payload.id}`))
  } catch (error) {
    yield put(createFloorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleSubmitSiteFloorsSaga(floor) {
  try {
    let floorPlans = {}
    let floorPlansJson = []
    for (let index = 0; index < floor['siteFloorPlans']; index++) {
      floorPlansJson.push({
        floorPlanBuildingId: floor[`floorPlan${index + 1}BuildingId`]
          ? floor[`floorPlan${index + 1}BuildingId`]
          : '',
        floorPlanFileName: floor[`floorPlan${index + 1}FileName`],
        floorPlanHeight: floor[`floorPlan${index + 1}Height`],
        floorPlanWidth: floor[`floorPlan${index + 1}Width`],
        floorPlanX: floor[`floorPlan${index + 1}X`],
        floorPlanY: floor[`floorPlan${index + 1}Y`],
        floors: floor[`floorPlan${index + 1}`]
          ? floor[`floorPlan${index + 1}`]
          : [],
      })
    }
    floorPlans['floorPlansJson'] = floorPlansJson

    const siteSlug = yield select(getSiteSlug)
    const payload = yield call(api.submitSiteFloors, siteSlug, floorPlans)
    yield put(submitSiteFloorsAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/sites/${siteSlug}`))
  } catch (error) {
    yield put(submitSiteFloorsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateFloorSaga(floor) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const payload = yield call(api.updateFloor, siteSlug, floor.id, floor)
    yield put(updateFloorAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/sites/${siteSlug}/floors/${payload.id}`))
  } catch (error) {
    yield put(updateFloorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateSortOrderSaga({ payload }) {
  try {
    const { siteSlug, sortOrder } = payload
    const result = yield call(
      api.updateFloorSortOrderBySiteSlug,
      siteSlug,
      sortOrder
    )
    yield put(updateSortOrderAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(updateSortOrderAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUploadFloorPlanSaga({ payload }) {
  try {
    const { siteSlug, floorId, formData } = payload

    const result = yield call(api.uploadFloorPlan, siteSlug, floorId, formData)
    yield put(uploadFloorPlanAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(getFloorAction.request({ siteSlug, floorId }))
  } catch (error) {
    yield put(uploadFloorPlanAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteFloorSaga({ siteSlug, floorId }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteFloor, siteSlug, floorId)
      yield fork(handleGetAllFloorsSaga, { siteSlug })
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteFloorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddThresholdToFloorSaga({ payload }) {
  try {
    const isConfirmed = yield call(global.confirm, MESSAGES.CONFIRM)

    if (isConfirmed) {
      const { siteSlug, floorSlug, thresholdId } = payload

      yield call(api.addThresholdToFloor, siteSlug, floorSlug, thresholdId)
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ADDED_THRESHOLD))
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(addThresholdToFloorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteThresholdFromFloorSaga({ payload }) {
  try {
    const { siteSlug, floorSlug, thresholdId } = payload

    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      const floorId = payload.floorId
        ? payload.floorId
        : yield select(getFloorId)

      yield call(api.deleteThresholdFromFloor, siteSlug, floorSlug, thresholdId)
      yield put(getFloorAction.request({ siteSlug, floorId }))
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(
        showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED_THRESHOLD)
      )
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(deleteThresholdFromFloorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllFloorThresholdUserMappingsSaga({ payload }) {
  try {
    const { siteSlug, floorId } = payload
    const result = yield call(
      api.getAllFloorThresholdUserMappings,
      siteSlug,
      floorId
    )
    yield put(getAllFloorThresholdUserMappingsAction.success(result))
  } catch (error) {
    yield put(getAllFloorThresholdUserMappingsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateFloorThresholdUserMappingSaga({ payload }) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const floorId =
      payload && payload.floorId ? payload.floorId : yield select(getFloorId)
    const result = yield call(api.updateFloorThresholdUserMapping, {
      ...payload,
      siteSlug,
      floorId,
    })
    yield put(getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug))
    yield put(updateFloorThresholdUserMappingAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield fork(handleGetAllFloorThresholdUserMappingsSaga, {
      payload: { siteSlug, floorId },
    })
  } catch (error) {
    yield put(updateFloorThresholdUserMappingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllFloorsByBuildingSaga({ payload }) {
  try {
    const { siteSlug, buildingId } = payload
    const result = yield call(
      api.getAllFloorsByBuildingId,
      siteSlug,
      buildingId
    )

    const summariesResult = yield call(
      api.getAllFloorSummariesByBuildingId,
      siteSlug,
      buildingId
    )
    yield put(getAllFloorsByBuildingAction.success(result))
    yield put(getAllFloorSummariesByBuildingAction.success(summariesResult))
  } catch (error) {
    yield put(getAllFloorsByBuildingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllFloorsSaga() {
  while (true) {
    const { payload } = yield take(getAllFloorsAction.REQUEST)
    yield fork(handleGetAllFloorsSaga, payload)
  }
}

function* watchGetFloorSaga() {
  while (true) {
    const { payload } = yield take(getFloorAction.REQUEST)
    yield fork(handleGetFloorSaga, payload)
  }
}

function* watchGetAllFloorSummariesSaga() {
  while (true) {
    const { payload } = yield take(getAllFloorSummariesAction.REQUEST)
    yield fork(handleGetAllFloorSummariesSaga, payload)
  }
}

function* watchGetFloorSummarySaga() {
  while (true) {
    const { payload } = yield take(getFloorSummaryAction.REQUEST)
    yield fork(handleGetFloorSummarySaga, payload)
  }
}

function* watchCreateFloorSaga() {
  while (true) {
    const { payload } = yield take(createFloorAction.REQUEST)
    yield fork(handleCreateFloorSaga, payload)
  }
}

function* watchSubmitSiteFloorsSaga() {
  while (true) {
    const { payload } = yield take(submitSiteFloorsAction.REQUEST)
    yield fork(handleSubmitSiteFloorsSaga, payload)
  }
}

function* watchUpdateFloorSaga() {
  while (true) {
    const { payload } = yield take(updateFloorAction.REQUEST)
    yield fork(handleUpdateFloorSaga, payload)
  }
}

function* watchUpdateSortOrderSaga() {
  yield takeLatest(updateSortOrderAction.REQUEST, handleUpdateSortOrderSaga)
}

function* watchUploadFloorPlanSaga() {
  yield takeLatest(uploadFloorPlanAction.REQUEST, handleUploadFloorPlanSaga)
}

function* watchDeleteFloorSaga() {
  while (true) {
    const { payload } = yield take(deleteFloorAction.REQUEST)
    yield fork(handleDeleteFloorSaga, payload)
  }
}

function* watchAddThresholdToFloorSaga() {
  yield takeLatest(
    addThresholdToFloorAction.REQUEST,
    handleAddThresholdToFloorSaga
  )
}

function* watchDeleteThresholdFromFloorSaga() {
  yield takeLatest(
    deleteThresholdFromFloorAction.REQUEST,
    handleDeleteThresholdFromFloorSaga
  )
}

function* watchGetAllFloorThresholdUserMappingsSaga() {
  yield takeLatest(
    getAllFloorThresholdUserMappingsAction.REQUEST,
    handleGetAllFloorThresholdUserMappingsSaga
  )
}

function* watchUpdateFloorThresholdUserMappingSaga() {
  yield takeLatest(
    updateFloorThresholdUserMappingAction.REQUEST,
    handleUpdateFloorThresholdUserMappingSaga
  )
}

function* watchGetAllFloorsByBuildingSaga() {
  yield takeLatest(
    getAllFloorsByBuildingAction.REQUEST,
    handleGetAllFloorsByBuildingSaga
  )
}

function* floorsSaga() {
  yield all([
    fork(watchGetAllFloorsSaga),
    fork(watchGetFloorSaga),
    fork(watchCreateFloorSaga),
    fork(watchSubmitSiteFloorsSaga),
    fork(watchUpdateFloorSaga),
    fork(watchUploadFloorPlanSaga),
    fork(watchDeleteFloorSaga),
    fork(watchGetAllFloorSummariesSaga),
    fork(watchGetFloorSummarySaga),
    fork(watchAddThresholdToFloorSaga),
    fork(watchDeleteThresholdFromFloorSaga),
    fork(watchGetAllFloorThresholdUserMappingsSaga),
    fork(watchUpdateFloorThresholdUserMappingSaga),
    fork(watchGetAllFloorsByBuildingSaga),
    fork(watchUpdateSortOrderSaga),
  ])
}

export {
  floorsSaga as default,
  watchGetAllFloorsSaga,
  watchGetFloorSaga,
  watchCreateFloorSaga,
  watchSubmitSiteFloorsSaga,
  watchUpdateFloorSaga,
  watchUploadFloorPlanSaga,
  watchDeleteFloorSaga,
  watchGetAllFloorSummariesSaga,
  watchGetFloorSummarySaga,
  watchAddThresholdToFloorSaga,
  watchDeleteThresholdFromFloorSaga,
  watchGetAllFloorThresholdUserMappingsSaga,
  watchUpdateFloorThresholdUserMappingSaga,
  watchGetAllFloorsByBuildingSaga,
  watchUpdateSortOrderSaga,
  handleGetAllFloorsSaga,
  handleGetFloorSaga,
  handleCreateFloorSaga,
  handleSubmitSiteFloorsSaga,
  handleUpdateFloorSaga,
  handleDeleteFloorSaga,
  handleGetFloorSummarySaga,
  handleGetAllFloorSummariesSaga,
  handleAddThresholdToFloorSaga,
  handleDeleteThresholdFromFloorSaga,
  getSiteSlug,
  getFloorId,
}
