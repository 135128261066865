import { connect } from 'react-redux'
import TinySensorChart from '../components/charts/TinySensorChart'
import { getLocationChartDataAction } from '../actions/locationsActions'
import { getCurrentLocationChartData } from '../reducers/locationsReducer'
import { isChartDataLoading } from '../reducers/uiReducer'

const mapStateToProps = ({ locations, ui }) => ({
  data: getCurrentLocationChartData(locations),
  isChartDataLoading: isChartDataLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getLocationChartData: (siteSlug, floorId, id, qs) =>
    dispatch(getLocationChartDataAction.request({ siteSlug, floorId, id, qs })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TinySensorChart)
