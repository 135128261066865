import moment from 'moment'
import { dateTimeFormatter, dateFormatter, timeFormatter } from '../utils/date'
import { COLORS } from '../constants'
import { getLocationUptimeDataAction } from '../actions/alertsActions'

const getChartData = (days, isByLocation) => {
  const maxAlertsInADay = days
    ? Math.max(
        ...days.map(day => {
          const { labels, colors, names, date, ...alerts } = day
          return Object.keys(alerts).length
        })
      )
    : 0

  let seriesData = []
  let allData = []
  let labelCount = []
  days &&
    days.forEach(day => {
      const { labels } = day
      labelCount.push(
        Object.values(labels).filter(label => label !== 'Online').length
      )
    })

  for (let series = 0; series < maxAlertsInADay; series++) {
    seriesData[series] = []
    for (let today = 0; today < days.length; today++) {
      const { labels, colors, names, date, ...alerts } = days[today]
      const todayAlerts = Object.values(alerts).reverse()
      const todayColors = Object.values(colors).reverse()
      const todayNames = Object.values(names)
      const todayLabels = Object.values(labels).filter(
        label => label !== 'Online'
      )

      let nameData = ''
      isByLocation &&
        Object.keys(labels).forEach(key => {
          const prefix = `<span style='color:${colors[key]}'>◼    </span>`
          nameData += `${prefix} ${labels[key]} ${names[key]}<br/>`
        })

      if (series < todayAlerts.length + 1 && todayAlerts[series]) {
        if (todayColors[series] === COLORS.GREEN) {
          seriesData[series].push({
            name: `<span style='color:${
              todayColors[series]
            }'>◼    </span> Online`,
            color: todayColors[series],
            y: todayAlerts[series] / 60,
          })
        } else {
          const alertLabel = labelCount[today] - 1 // get label count for today
          labelCount[today] = alertLabel
          seriesData[series].push({
            name: isByLocation
              ? nameData
              : `<span style='color:${todayColors[series]}'>◼    </span> ${
                  todayLabels[alertLabel]
                } ${todayNames[alertLabel]}`,
            color: todayColors[series],
            y: todayAlerts[series] / 60,
          })
        }
      } else {
        seriesData[series].push({ color: null, y: null })
      }
    }
    allData.push({ data: seriesData[series] })
  }

  const xData =
    days && days.length
      ? days.map(day => {
          const { date } = day
          return date.slice(0, -6)
        })
      : []

  return {
    xData,
    allData,
  }
}

const checkTodayAlerts = (alertWindow, today, alertTypes) => {
  let startMinAfterSOD, endMinAfterEOD, startMinBeforeEOD, endMinAfterSOD
  // using endOf/startOf mutates the `today` object
  const clonedToday = today.clone()

  startMinAfterSOD = moment(alertWindow.start).diff(
    moment(clonedToday.startOf('day')),
    'minutes'
  )

  endMinAfterEOD =
    alertWindow.end &&
    moment(alertWindow.end).diff(moment(clonedToday.endOf('day')), 'minutes')

  endMinAfterSOD =
    alertWindow.end &&
    moment(alertWindow.end).diff(moment(clonedToday.startOf('day')), 'minutes')

  startMinBeforeEOD = moment(alertWindow.start).diff(
    moment(clonedToday.endOf('day')),
    'minutes'
  )
  let alertType
  if (startMinAfterSOD < 0 && !alertWindow.end) {
    alertType = alertTypes.CASE_1
  } else if (startMinAfterSOD < 0 && endMinAfterEOD < 0 && endMinAfterSOD > 0) {
    alertType = alertTypes.CASE_2
  } else if (
    startMinAfterSOD >= 0 &&
    startMinBeforeEOD < 0 &&
    endMinAfterEOD <= 0 &&
    alertWindow.end
  ) {
    alertType = alertTypes.CASE_3
  } else if (
    startMinAfterSOD >= 0 &&
    startMinBeforeEOD < 0 &&
    endMinAfterEOD > 0
  ) {
    alertType = alertTypes.CASE_4
  } else if (
    startMinAfterSOD >= 0 &&
    startMinBeforeEOD < 0 &&
    !alertWindow.end
  ) {
    alertType = alertTypes.CASE_5
  } else if (startMinAfterSOD < 0 && endMinAfterEOD > 0) {
    alertType = alertTypes.CASE_6
  }

  return alertType
}

const currentUptimeData = action => {
  const alerts = action.payload.items
  const start = action.payload.timeRange.rangeStartTime
  const end = action.payload.timeRange.rangeEndTime
  const isByLocation = action.type === getLocationUptimeDataAction.SUCCESS

  let minutes, minutesBetweenSODAndNow
  let today = moment(start).startOf('day')
  const endDate = moment(end)

  const now = moment()
  const days = []
  const alertTypes = {
    CASE_1: 'alertType1',
    CASE_2: 'alertType2',
    CASE_3: 'alertType3',
    CASE_4: 'alertType4',
    CASE_5: 'alertType5',
    CASE_6: 'alertType6',
  }

  if (alerts.length > 0) {
    let colors, names, labels, times, cutoff, onlineMinutes, date

    const datesAlerts = []

    // In this loop, we gather all the alerts
    // that occur each day
    while (today.isSameOrBefore(endDate)) {
      let todayAlerts = []
      let alertIndex = 0,
        cA = alerts[alertIndex],
        alertWindow = {
          start: cA.startTime,
          end: cA.endTime,
          type: cA.alertType,
        },
        alertType

      // In case of only one alert, alertIndex is used after loop
      alertIndex = 1

      for (alertIndex = 1; alertIndex < alerts.length; alertIndex++) {
        cA = alerts[alertIndex - 1]
        const nA = alerts[alertIndex]
        const gA = {
          startTime: alertWindow.start,
          endTime: alertWindow.end,
          alertType: alertWindow.type,
        }
        if (gA.endTime > nA.endTime || gA.endTime === null) {
          if (nA.alertType !== 'THRESHOLD') {
            alertWindow = {
              start: gA.startTime,
              end: nA.startTime,
              type: gA.alertType,
            }
            alertType = checkTodayAlerts(alertWindow, today, alertTypes)
            if (alertType) {
              todayAlerts.push({
                alertType,
                alertWindow,
              })
            }

            alertWindow = {
              start: nA.startTime,
              end: nA.endTime,
              type: nA.alertType,
            }
            if (nA.endTime !== null) {
              alertType = checkTodayAlerts(alertWindow, today, alertTypes)
              if (alertType) {
                todayAlerts.push({
                  alertType,
                  alertWindow,
                })
              }

              alertWindow = {
                start: nA.endTime,
                end: gA.endTime,
                type: gA.alertType,
              }
            }
          }
        } else if (gA.endTime > nA.startTime) {
          if (gA.alertType !== 'THRESHOLD') {
            alertWindow = {
              start: gA.startTime,
              end: gA.endTime,
              type: gA.alertType,
            }
            alertType = checkTodayAlerts(alertWindow, today, alertTypes)
            if (alertType) {
              todayAlerts.push({
                alertType,
                alertWindow,
              })
            }

            alertWindow = {
              start: gA.endTime,
              end: nA.endTime,
              type: nA.alertType,
            }
          } else if (nA.alertType !== 'THRESHOLD') {
            alertWindow = {
              start: gA.startTime,
              end: nA.startTime,
              type: gA.alertType,
            }
            alertType = checkTodayAlerts(alertWindow, today, alertTypes)
            if (alertType) {
              todayAlerts.push({
                alertType,
                alertWindow,
              })
            }

            alertWindow = {
              start: nA.startTime,
              end: nA.endTime,
              type: nA.alertType,
            }
          } else {
            alertWindow = {
              start: gA.startTime,
              end: nA.endTime,
              type: gA.alertType,
            }
          }
        } else {
          alertType = checkTodayAlerts(alertWindow, today, alertTypes)
          if (alertType) {
            todayAlerts.push({
              alertType,
              alertWindow,
            })
          }

          alertWindow = {
            start: nA.startTime,
            end: nA.endTime,
            type: nA.alertType,
          }
        }
      }
      alertType = checkTodayAlerts(alertWindow, today, alertTypes)
      if (alertType) {
        todayAlerts.push({
          alertType,
          alertWindow,
        })
      }

      let todayLabels = []
      for (let index = 0; index < alerts.length; index++) {
        const lA = alerts[index]
        const clonedToday = today.clone()
        if (moment(lA.startTime) <= moment(clonedToday.endOf('day'))) {
          if (
            moment(lA.endTime) >= moment(clonedToday.startOf('day')) ||
            lA.endTime === null
          ) {
            alertWindow = {
              start: lA.startTime,
              end: lA.endTime,
              type: lA.alertType,
              name: lA.thresholdName,
            }
            alertType = checkTodayAlerts(alertWindow, today, alertTypes)
            if (alertType) {
              todayLabels.push({
                alertType,
                alertWindow,
              })
            }
          }
        }
      }
      /*
        We need to convert `today` to a string
        because the pointer to the moment object
        reference does not change, so the date
        isn't frozen
        */

      datesAlerts.push({
        frozenDate: dateTimeFormatter(today),
        todayAlerts,
        todayLabels,
      })

      today = today.add(1, 'day')
    }

    cutoff = moment(start).startOf('day')

    datesAlerts.forEach(day => {
      const { frozenDate, todayAlerts, todayLabels } = day
      let timeKey
      let keyCount = 0
      date = dateFormatter(frozenDate, false, true)

      // need to convert back to Date or
      // moment will throw a deprecation warning
      today = moment(new Date(frozenDate))
      minutesBetweenSODAndNow = now.diff(today.startOf('day'), 'minutes')

      colors = {}
      labels = {}
      times = {}
      names = {}
      let lastDayAlertEndTime

      if (todayAlerts.length) {
        todayAlerts.forEach(alert => {
          const { alertType, alertWindow } = alert

          const alertColor = isByLocation
            ? alertWindow.type === 'THRESHOLD'
              ? COLORS.RED
              : COLORS.ORANGE
            : COLORS.ORANGE

          cutoff = cutoff.isBefore(today.startOf('day'))
            ? today.startOf('day')
            : cutoff

          switch (alertType) {
            case alertTypes.CASE_1:
              minutes =
                minutesBetweenSODAndNow < 1440 ? minutesBetweenSODAndNow : 1440

              cutoff = today.endOf('day')
              timeKey = '' + keyCount++

              times[timeKey] = minutes
              colors[timeKey] = alertColor
              break

            case alertTypes.CASE_2:
              minutes = moment(alertWindow.end).diff(
                today.startOf('day'),
                'minutes'
              )

              timeKey = '' + keyCount++
              times[timeKey] = minutes
              colors[timeKey] = alertColor
              cutoff = moment(alertWindow.end)
              break

            case alertTypes.CASE_3:
              onlineMinutes = moment(alertWindow.start).diff(cutoff, 'minutes')
              timeKey = '' + keyCount++
              times[timeKey] = onlineMinutes
              colors[timeKey] = COLORS.GREEN

              minutes = moment(alertWindow.end).diff(
                moment(alertWindow.start),
                'minutes'
              )
              timeKey = '' + keyCount++
              times[timeKey] = minutes
              colors[timeKey] = alertColor

              cutoff = moment(alertWindow.end)
              break

            case alertTypes.CASE_4:
              onlineMinutes = moment(alertWindow.start).diff(cutoff, 'minutes')
              timeKey = '' + keyCount++
              times[timeKey] = onlineMinutes
              colors[timeKey] = COLORS.GREEN

              minutes = moment(today.endOf('day')).diff(
                moment(alertWindow.start),
                'minutes'
              )
              timeKey = '' + keyCount++
              times[timeKey] = minutes
              colors[timeKey] = alertColor

              cutoff = moment(alertWindow.end)
              break

            case alertTypes.CASE_5:
              onlineMinutes = moment(alertWindow.start).diff(cutoff, 'minutes')
              timeKey = '' + keyCount++
              times[timeKey] = onlineMinutes
              colors[timeKey] = COLORS.GREEN

              const lastDay = endDate.format('LL')
              if (
                today.format('LL') === lastDay &&
                now.format('LL') === lastDay
              ) {
                minutes = now.diff(moment(alertWindow.start), 'minutes')
              } else {
                minutes = moment(today.endOf('day')).diff(
                  moment(alertWindow.start),
                  'minutes'
                )
              }

              timeKey = '' + keyCount++
              times[timeKey] = minutes
              colors[timeKey] = alertColor

              cutoff = today.endOf('day')
              break

            case alertTypes.CASE_6:
              minutes = 1440
              cutoff = today.endOf('day')
              timeKey = '' + keyCount++

              times[timeKey] = minutes
              colors[timeKey] = alertColor
              break
          }
          lastDayAlertEndTime = alertWindow.end
        })
        //done with alerts but show online if time.now() > lastDayAlertEndTime
        if (lastDayAlertEndTime) {
          const lastDay = endDate.format('LL')
          if (today.format('LL') === lastDay && now.format('LL') === lastDay) {
            onlineMinutes = now.diff(moment(lastDayAlertEndTime), 'minutes')
            timeKey = '' + keyCount++
            times[timeKey] = onlineMinutes
            colors[timeKey] = COLORS.GREEN
          }
        }
      } else {
        // no offline alerts; add add green day
        minutes =
          minutesBetweenSODAndNow < 1440 ? minutesBetweenSODAndNow : 1440
        timeKey = '' + keyCount++
        times[timeKey] = minutes
        colors[timeKey] = COLORS.GREEN
        labels[timeKey] = 'Online'
        cutoff = today.endOf('day')
      }

      const totalTime = Object.values(times).reduce((a, b) => a + b, 0)

      /*
        Sometimes when we run 'end of day', `today` becomes
        23:59, so the total number of minutes becomes 1439.
        This is because we fill in the number of minutes
        only prior to an alert as online. If an alert ends with
        no new alerts in the day, i.e. when there's more than 2
        minutes missing from the total minutes in the day,
        we need to fill the rest of the day as 'online'
        */

      if (minutesBetweenSODAndNow >= 1440 && 1440 - totalTime > 2) {
        timeKey = '' + keyCount++
        times[timeKey] = 1440 - totalTime
        colors[timeKey] = COLORS.GREEN
        cutoff = today.endOf('day')
      }

      if (todayLabels.length) {
        todayLabels.forEach(alertLabel => {
          const { alertType, alertWindow } = alertLabel
          const { start, end, type } = alertWindow

          const label = `${dateTimeFormatter(start, true)} - ${
            end ? dateTimeFormatter(end, true) : 'Present'
          }`

          const startDur = moment(new Date(start))
          const endDur = moment(new Date(end)).diff(startDur)
          const duration = moment.duration(endDur)
          const labelDuration = `${timeFormatter(
            start,
            true
          )} - ${timeFormatter(end, true)}
                                        (${duration.hours()} hrs ${duration.minutes()} mins)`

          const labelColor = isByLocation
            ? type === 'THRESHOLD'
              ? COLORS.RED
              : COLORS.ORANGE
            : COLORS.ORANGE

          timeKey = '' + keyCount++
          times[timeKey] = null
          colors[timeKey] = labelColor
          labels[timeKey] =
            alertType === alertTypes.CASE_3 ? labelDuration : label
          names[timeKey] = isByLocation
            ? type === 'THRESHOLD'
              ? ` — ${alertWindow.name}`
              : ' — Offline'
            : ''
        })
      }

      days.push(
        Object.assign(times, { date }, { colors }, { labels }, { names })
      )
    })
  } else {
    while (today.isSameOrBefore(endDate)) {
      minutesBetweenSODAndNow = now.diff(today.startOf('day'), 'minutes')

      minutes = minutesBetweenSODAndNow < 1440 ? minutesBetweenSODAndNow : 1440
      days.push(
        Object.assign(
          { '0': minutes },
          { date: dateFormatter(today, false, true) },
          { colors: { '0': COLORS.GREEN } },
          { labels: { '0': 'Online' } },
          { names: { '0': '' } }
        )
      )
      today = today.add(1, 'day')
    }
  }

  // special case: single day requested
  if (days.length === 1) {
    // we need to fill in the remaining minutes in day with white
    let totalMinutes = 0
    let lastKey
    let { date, colors, labels, names, ...allMinutes } = days.pop()

    Object.keys(allMinutes).forEach(key => {
      totalMinutes += allMinutes[key]
      lastKey = key
    })

    // add remaining minutes and new color key for it
    const newKey = (+lastKey + 1).toString()
    const newMinutes = { [newKey]: 1440 - totalMinutes }
    const newColors = Object.assign(colors, { [newKey]: COLORS.WHITE })

    days.push(
      Object.assign(
        allMinutes,
        newMinutes,
        { colors: newColors },
        { labels },
        { names },
        { date }
      )
    )
  }
  const chartData = getChartData(days, isByLocation)
  return chartData
}

export { currentUptimeData }
