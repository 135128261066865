import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllGatewaysAction,
  getAllAvailableGatewaysAction,
  getGatewayAlertsUptimeDataAction,
  getGatewayConnectedPodsAction,
  getSiteGatewaysAction,
  getGatewayAssignmentHistoryAction,
  getGatewayAction,
  getGatewayRestartHistoryAction,
} from '../actions/gatewaysActions'
import { currentUptimeData } from './utils'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllGatewaysAction.SUCCESS:
        payload.items.forEach(gateway => {
          draft[gateway.id] = gateway
        })
        break
    }
  })

const availableById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAvailableGatewaysAction.SUCCESS:
        payload.forEach(gateway => {
          draft[gateway.id] = gateway
        })
        break
    }
  })

const siteGatewayById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getSiteGatewaysAction.SUCCESS:
        payload.forEach(gateway => {
          draft[gateway.gatewayId] = gateway
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllGatewaysAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(gateway => gateway.id)
        )
        break
    }
  })

const siteGatewayVisibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getSiteGatewaysAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(gateway => gateway.gatewayId)
        )
        break
    }
  })

const availableVisibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailableGatewaysAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(gateway => gateway.id)
        )
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllGatewaysAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const current = (state = { tags: [], tagIds: [] }, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getGatewayAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const assignmentHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getGatewayAssignmentHistoryAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.items)
        break
    }
  })

const connectedPodsById = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getGatewayConnectedPodsAction.SUCCESS:
        payload.forEach(x => (draft[x.id] = x))
        break
    }
  })

const visibleConnectedPods = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getGatewayConnectedPodsAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.map(({ id }) => id))
        break
    }
  })

const currentGatewayUptimeData = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getGatewayAlertsUptimeDataAction.SUCCESS:
        const chartData = currentUptimeData(action)
        Object.assign(draft, chartData)
        break
    }
  })

const restartHistory = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getGatewayRestartHistoryAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items)
        break
    }
  })

const gatewaysReducer = combineReducers({
  byId,
  visibleIds,
  availableById,
  availableVisibleIds,
  siteGatewayById,
  siteGatewayVisibleIds,
  connectedPodsById,
  visibleConnectedPods,
  current,
  currentGatewayUptimeData,
  assignmentHistory,
  meta,
  restartHistory,
})

const getCurrentGatewayUptimeData = state => state.currentGatewayUptimeData
const getVisibleGatewayAssignmentHistory = state => state.assignmentHistory
const getGateway = (state, id) => state.byId[id]
const getAvailableGateway = (state, id) => state.availableById[id]
const getSiteGateway = (state, id) => state.siteGatewayById[id]
const getCurrentGateway = state => state.current
const getVisibleGateways = state =>
  state.visibleIds.map(id => getGateway(state, id))
const getGatewaysMeta = state => state.meta
const getAvailableVisibleGateways = state =>
  state.availableVisibleIds.map(id => getAvailableGateway(state, id))
const getVisibleSiteGateways = state =>
  state.siteGatewayVisibleIds.map(id => getSiteGateway(state, id))
const getGatewayRestartHistory = ({ restartHistory }) => restartHistory
const getConnectedPods = (state, id) => state.connectedPodsById[id]
const getCurrentGatewayConnectedPods = state =>
  state.visibleConnectedPods.map(id => getConnectedPods(state, id))

export {
  gatewaysReducer as default,
  getCurrentGateway,
  getVisibleGateways,
  getAvailableVisibleGateways,
  getCurrentGatewayUptimeData,
  getCurrentGatewayConnectedPods,
  getVisibleGatewayAssignmentHistory,
  getVisibleSiteGateways,
  getGatewaysMeta,
  getGatewayRestartHistory,
}
