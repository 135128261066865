import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import Form from './common/Form'
import Button from './common/Button'
import Label from './common/Label'
import { Modal } from './common/Ant'
import { ActionAnchor } from './common/Anchor'

class FloorPlanUpload extends Component {
  constructor() {
    super()

    this.state = {
      isModalOpen: false,
      src: null,
      imgWidth: 0,
      imgHeight: 0,
      cropHeight: 0,
      cropWidth: 0,
      formData: null,
      hasError: false,
      crop: {
        x: 25,
        y: 25,
        width: 50,
        height: 50,
      },
    }
  }

  static propTypes = {
    siteSlug: PropTypes.string.isRequired,
    floorId: PropTypes.string.isRequired,
    uploadFloorPlan: PropTypes.func.isRequired,
    isNewFloorPlan: PropTypes.bool.isRequired,
  }

  componentWillUnmount() {
    if (this.state.isModalOpen) {
      this.handleClose()
    }
  }

  handleOpen = () => this.setState({ isModalOpen: true })

  handleClose = () => this.setState({ isModalOpen: false })

  handleCropChange = crop => {
    this.setState({ crop })
    this.isCropValid()
  }

  handleFileSelection = event => {
    const files = Array.from(event.target.files)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('name', file.name)
      formData.append('type', file.type)
      formData.append('files[]', file)

      const src = global.URL.createObjectURL(file)
      const img = new Image()

      img.onload = () =>
        this.setState({
          src,
          imgWidth: img.width,
          imgHeight: img.height,
          formData,
        })

      img.src = src
    })
  }

  isCropValid = () => {
    const { crop, imgWidth, imgHeight } = this.state

    const cropHeight = imgHeight * (crop.height / 100)
    const cropWidth = imgWidth * (crop.width / 100)

    if (
      cropHeight < 1000 ||
      cropHeight > 5000 ||
      cropWidth < 1000 ||
      cropWidth > 5000
    ) {
      this.setState({ cropHeight, cropWidth, hasError: true })

      return false
    }

    this.setState({ cropHeight, cropWidth, hasError: false })

    return true
  }

  handleSubmit = () => {
    const { uploadFloorPlan, siteSlug, floorId } = this.props
    const { formData, crop } = this.state

    if (this.isCropValid()) {
      formData.append('height', crop.height)
      formData.append('width', crop.width)
      formData.append('x', crop.x)
      formData.append('y', crop.y)

      uploadFloorPlan(siteSlug, floorId, formData)
      this.handleClose()
    }
  }

  render() {
    const {
      isModalOpen,
      src,
      crop,
      hasError,
      cropWidth,
      cropHeight,
    } = this.state
    const { isNewFloorPlan } = this.props

    return (
      <div className="FloorPlanUpload mb3">
        <div className={isNewFloorPlan ? 'tc' : ''}>
          <ActionAnchor onClick={this.handleOpen}>
            {isNewFloorPlan
              ? 'Upload a Floor Plan Image'
              : 'Upload a new Floor Plan Image'}
          </ActionAnchor>
        </div>
        <Modal
          visible={isModalOpen}
          className="Modal"
          title="Upload Floor Plan"
          onCancel={this.handleClose}
          footer={null}
          maskClosable={false}
        >
          <Form onSubmit={this.handleSubmit}>
            <Label>Select an image to upload</Label>
            <input
              type="file"
              accept="image/*"
              onChange={this.handleFileSelection}
              className="mb3 w-100"
            />
            {src && (
              <ReactCrop
                className="mb3"
                src={src}
                crop={crop}
                onChange={this.handleCropChange}
                onImageLoaded={this.isCropValid}
              />
            )}
            {hasError && (
              <p className="red mb3">
                A floor plan must be between 1000-5000px tall and wide. Your
                current selection is{' '}
                <span className="b">{Math.round(cropHeight)}px tall</span> and{' '}
                <span className="b">{Math.round(cropWidth)}px wide</span>.
                Please re-size to fit constraints.
              </p>
            )}
            <Button onClick={this.handleSubmit} disabled={hasError} />
          </Form>
        </Modal>
      </div>
    )
  }
}

export default FloorPlanUpload
