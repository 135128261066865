import { createFormAction } from 'redux-form-saga'

const createNetworkAction = createFormAction('CREATE_NETWORK')
const updateNetworkAction = createFormAction('UPDATE_NETWORK')
const deleteNetworkAction = createFormAction('DELETE_NETWORK')
const getAllNetworksAction = createFormAction('GET_ALL_NETWORKS')
const getNetworkAction = createFormAction('GET_NETWORK')

const createNetworkAuthorityAction = createFormAction(
  'CREATE_NETWORK_AUTHORITY'
)
const updateNetworkAuthorityAction = createFormAction(
  'UPDATE_NETWORK_AUTHORITY'
)
const deleteNetworkAuthorityAction = createFormAction(
  'DELETE_NETWORK_AUTHORITY'
)
const getAllNetworkAuthoritiesAction = createFormAction(
  'GET_ALL_NETWORK_AUTHORITIES'
)

const CLEAR_NETWORK_AUTHORITY_TOKEN = 'CLEAR_NETWORK_AUTHORITY_TOKEN'
const clearNetworkAuthorityTokenAction = () => ({
  type: CLEAR_NETWORK_AUTHORITY_TOKEN,
})

const regenerateNetworkAuthorityTokenAction = createFormAction(
  'REGENERATE_NETWORK_AUTHORITY_TOKEN'
)
const getNetworkAuthorityTokenHistoryAction = createFormAction(
  'GET_NETWORK_AUTHORITY_TOKEN_HISTORY'
)

export {
  createNetworkAction,
  updateNetworkAction,
  deleteNetworkAction,
  getAllNetworksAction,
  createNetworkAuthorityAction,
  updateNetworkAuthorityAction,
  deleteNetworkAuthorityAction,
  getAllNetworkAuthoritiesAction,
  clearNetworkAuthorityTokenAction,
  CLEAR_NETWORK_AUTHORITY_TOKEN,
  regenerateNetworkAuthorityTokenAction,
  getNetworkAuthorityTokenHistoryAction,
  getNetworkAction,
}
