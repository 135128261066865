import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import AllTags from './AllTags'
import AllActionReportCauses from './AllActionReportCauses'
import { Bread, Crumb } from './common/Bread'
import { Tabs, TabPane } from './common/Ant'
import { Anchor } from './common/Anchor'
import { getPathSnippets } from '../utils/helpers'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'

class AllMetadata extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllTags: PropTypes.func.isRequired,
    tagsMeta: PropTypes.object.isRequired,
    deleteTag: PropTypes.func.isRequired,
    isTagLoading: PropTypes.bool.isRequired,
    actionReportCauses: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllActionReportCauses: PropTypes.func.isRequired,
    deleteActionReportCause: PropTypes.func.isRequired,
    isActionReportLoading: PropTypes.bool.isRequired,
    actionReportCausesMeta: PropTypes.object.isRequired,
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    updateQueryParams: PropTypes.func.isRequired,
  }

  state = {
    activeTab: undefined,
  }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.handleTabSelection(lastSnippet)
  }

  handleTabSelection = entity => {
    const {
      updateQueryParams,
      match: { url },
    } = this.props
    const { activeTab } = this.state

    this.setState({ activeTab: entity })
    updateQueryParams({
      pathname: `${url}/${entity}`,
      search: !activeTab || entity === activeTab ? global.location.search : '',
    })
  }

  render() {
    const {
      tags,
      getAllTags,
      tagsMeta,
      deleteTag,
      isTagLoading,
      actionReportCauses,
      getAllActionReportCauses,
      deleteActionReportCause,
      isActionReportLoading,
      actionReportCausesMeta,
      accountProfile,
      windowWidth,
      match: { url },
      updateQueryParams,
    } = this.props

    const isTags = global.location.pathname === '/metadata/all/tags'

    return (
      <div className="AllMetadata">
        <Helmet>
          <title>Metadata</title>
        </Helmet>
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>Metadata</Crumb>
              <Crumb>{isTags ? 'Tags' : 'Action Report Causes'}</Crumb>
            </Bread>
          </div>
          {hasPermission(USER_PERMISSIONS.ADD_TAG) && (
            <div className="flex justify-center">
              <Anchor
                to={
                  isTags
                    ? '/metadata/tags/create'
                    : '/metadata/action-report-causes/create'
                }
              >
                Create New
              </Anchor>
            </div>
          )}
        </div>
        <Switch>
          <Route
            path={`${url}/`}
            exact
            render={props => <Redirect to={`${url}/tags`} />}
          />
          <Tabs
            type="card"
            defaultActiveKey="tags"
            activeKey={this.state.activeTab}
            onTabClick={this.handleTabSelection}
          >
            {hasPermission(USER_PERMISSIONS.VIEW_TAG) && (
              <TabPane tab="Tags" key="tags">
                <Route
                  path={`${url}/tags`}
                  exact
                  render={props => (
                    <AllTags
                      tags={tags}
                      getAllTags={getAllTags}
                      meta={tagsMeta}
                      deleteTag={deleteTag}
                      isTagLoading={isTagLoading}
                      accountProfile={accountProfile}
                      updateQueryParams={updateQueryParams}
                      windowWidth={windowWidth}
                      url={url}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.VIEW_ACTION_REPORT_CAUSES) && (
              <TabPane tab="Action Report Causes" key="action-report-causes">
                <Route
                  path={`${url}/action-report-causes`}
                  exact
                  render={props => (
                    <AllActionReportCauses
                      actionReportCauses={actionReportCauses}
                      getAllActionReportCauses={getAllActionReportCauses}
                      meta={actionReportCausesMeta}
                      deleteActionReportCause={deleteActionReportCause}
                      isLoading={isActionReportLoading}
                      accountProfile={accountProfile}
                      updateQueryParams={updateQueryParams}
                      windowWidth={windowWidth}
                      url={url}
                    />
                  )}
                />
              </TabPane>
            )}
          </Tabs>
        </Switch>
      </div>
    )
  }
}

export default AllMetadata
