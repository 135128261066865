import React, { Component } from 'react'
import Gallery from 'react-grid-gallery'
import PropTypes from 'prop-types'
import EditSiteFloorPlanForm from '../components/forms/EditSiteFloorPlanForm'
import { H3 } from './common/Headers'
import { submitSiteFloorsAction } from '../actions/floorsActions'
import Form from './common/Form'
import Button from './common/Button'
import Label from './common/Label'
import { Modal, Spin } from './common/Ant'
import { Anchor } from './common/Anchor'
import { SIZES } from '../constants'

let floorImages = []

class EditSiteFloorPlan extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isSiteLoading: PropTypes.bool,
    isFloorPlanTab: PropTypes.bool,
    isFloorPlanEdit: PropTypes.bool,
    getAllBuildings: PropTypes.func,
    getFloorPlanSnaps: PropTypes.func,
    uploadFloorPlanPdf: PropTypes.func,
    buildings: PropTypes.arrayOf(PropTypes.object),
    siteFloorPlanSnaps: PropTypes.arrayOf(PropTypes.object),
    formValues: PropTypes.object.isRequired,
    createBuilding: PropTypes.func.isRequired,
  }

  constructor() {
    super()

    this.state = {
      isModalOpenPdf: false,
      formData: null,
    }
  }

  static defaultProps = {
    formValues: {},
    siteFloorPlanSnaps: [],
  }

  componentDidMount() {
    const { match, getFloorPlanSnaps, getAllBuildings } = this.props

    const { siteSlug } = match.params

    getFloorPlanSnaps(siteSlug)
    getAllBuildings && getAllBuildings(siteSlug)
  }

  componentWillUnmount() {
    if (this.state.isModalOpenPdf) {
      this.handleClosePdf()
    }
  }

  handleOpenPdf = () => this.setState({ isModalOpenPdf: true })

  handleClosePdf = () => this.setState({ isModalOpenPdf: false })

  handleFileSelectionPdf = event => {
    const files = Array.from(event.target.files)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('name', file.name)
      formData.append('type', file.type)
      formData.append('files[]', file)

      const src = global.URL.createObjectURL(file)
      const reader = new FileReader()

      reader.onload = () =>
        this.setState({
          src,
          formData,
        })
      reader.readAsDataURL(file)
    })
  }

  handleSubmitPdf = () => {
    const { uploadFloorPlanPdf, match } = this.props
    const { formData } = this.state
    const { siteSlug } = match.params

    uploadFloorPlanPdf && uploadFloorPlanPdf(siteSlug, formData)
    this.handleClosePdf()
  }

  render() {
    const {
      siteFloorPlanSnaps,
      buildings,
      match,
      isSiteLoading,
      formValues,
      createBuilding,
    } = this.props

    const { isModalOpenPdf } = this.state
    const { siteSlug } = match.params

    if (floorImages.length < siteFloorPlanSnaps.length) {
      for (let i = 0; i < siteFloorPlanSnaps.length; i++) {
        floorImages.push({
          src: siteFloorPlanSnaps[i].src,
          thumbnail: siteFloorPlanSnaps[i].src,
          thumbnailWidth: 330,
          thumbnailHeight: 330,
          planFileName: siteFloorPlanSnaps[i].planFileName,
          pageHeight: siteFloorPlanSnaps[i].height,
          pageWidth: siteFloorPlanSnaps[i].width,
        })
      }
    }

    return (
      <section className="EditSiteFloorPlan">
        {isSiteLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <div>
            {this.props.isFloorPlanEdit ? (
              <div>
                <H3>
                  <Anchor to="/sites/">Sites</Anchor>
                  {` / `}
                  <Anchor to={`/sites/${siteSlug}/floorplan`}>
                    Floor Plans
                  </Anchor>
                  {` / `}
                  Edit Site Floor Plan
                </H3>
                <EditSiteFloorPlanForm
                  siteSlug={`siteSlug`}
                  createBuilding={createBuilding}
                  isSiteLoading={isSiteLoading}
                  siteFloorPlanSnaps={siteFloorPlanSnaps}
                  formValues={formValues}
                  buildings={buildings}
                  submitAction={submitSiteFloorsAction}
                />
              </div>
            ) : (
              <div>
                <div className="flex justify-between mr5-ns ml5-ns mb3 mt1">
                  {siteFloorPlanSnaps.length > 0 && (
                    <Button
                      to={`/sites/${siteSlug}/floorplan/edit`}
                      text="Assign Images To New Floors"
                      size={SIZES.MEDIUM}
                    />
                  )}
                  <Button
                    onClick={this.handleOpenPdf}
                    text="Upload New Floor Plan PDF"
                    size={SIZES.MEDIUM}
                  />
                  <Modal
                    visible={isModalOpenPdf}
                    className="Modal"
                    title="Upload Floor Plan PDF"
                    onCancel={this.handleClosePdf}
                    footer={null}
                  >
                    <p>
                      Upload a PDF containing all the floor plans on your Site.
                      Our system will process it and notify you via email. You
                      can then create Buildings, Floors, and Locations for each
                      processed floor plan.
                    </p>
                    <Form onSubmit={this.handleSubmitPdf}>
                      <Label>Select a PDF file to upload</Label>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={this.handleFileSelectionPdf}
                        className="mb3 w-100"
                      />
                      <Button onClick={this.handleSubmitPdf} />
                    </Form>
                  </Modal>
                </div>
                {siteFloorPlanSnaps.length > 0 && (
                  <Gallery images={floorImages} rowHeight={330} />
                )}
              </div>
            )}
          </div>
        )}
      </section>
    )
  }
}

export default EditSiteFloorPlan
