import { fork, call, take, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllPartsAction,
  // getAllAvailablePartsAction,
  getPartAction,
  createPartAction,
  updatePartAction,
  deletePartAction,
} from '../actions/partsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllPartsSaga(qs) {
  try {
    const payload = yield call(api.getAllParts, qs)
    yield put(getAllPartsAction.success(payload))
  } catch (error) {
    yield put(getAllPartsAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// function* handleGetAllAvailablePartsSaga() {
//   try {
//     const payload = yield call(api.getAllAvailableParts)
//     yield put(getAllAvailablePartsAction.success(payload))
//   } catch (error) {
//     yield put(getAllAvailablePartsAction.failure())
//     yield put(showErrorMessageAction(pickErrorMessage(error)))

//     if (error.status === 401) {
//       yield put(unauthorizedAction())
//     }
//   }
// }

function* handleGetPartSaga(id) {
  try {
    const payload = yield call(api.getPart, id)
    yield put(getPartAction.success(payload))
  } catch (error) {
    yield put(getPartAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreatePartSaga(part) {
  try {
    let parts = {}
    let partsJson = []
    partsJson.push({
      name: part['name'],
      partType: part['partType'],
      description: part['description'] ? part['description'] : '',
      manufacturers: part['manufacturers'] ? part['manufacturers'] : [],
    })
    parts['partsJson'] = partsJson

    const payload = yield call(api.createPart, parts)

    yield put(createPartAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/inventory/parts/${payload[0].id}`))
  } catch (error) {
    yield put(createPartAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdatePartSaga(part) {
  try {
    let parts = {}
    let partsJson = []
    partsJson.push({
      name: part['name'],
      partType: part['partType'],
      description: part['description'] ? part['description'] : '',
      manufacturers: part['manufacturers'] ? part['manufacturers'] : [],
    })
    parts['partsJson'] = partsJson

    const payload = yield call(api.updatePart, parts, part['id'])
    yield put(updatePartAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/parts/${payload[0].id}`))
  } catch (error) {
    yield put(updatePartAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeletePartSaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deletePart, id)
      yield fork(handleGetAllPartsSaga)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deletePartAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllPartsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllPartsAction.REQUEST)
    yield fork(handleGetAllPartsSaga, qs)
  }
}

// function* watchGetAllAvailablePartsSaga() {
//   while (true) {
//     yield take(getAllAvailablePartsAction.REQUEST)
//     yield fork(handleGetAllAvailablePartsSaga)
//   }
// }

function* watchGetPartSaga() {
  while (true) {
    const { payload } = yield take(getPartAction.REQUEST)
    yield fork(handleGetPartSaga, payload)
  }
}

function* watchCreatePartSaga() {
  while (true) {
    const { payload } = yield take(createPartAction.REQUEST)
    yield fork(handleCreatePartSaga, payload)
  }
}

function* watchUpdatePartSaga() {
  while (true) {
    const { payload } = yield take(updatePartAction.REQUEST)
    yield fork(handleUpdatePartSaga, payload)
  }
}

function* watchDeletePartSaga() {
  while (true) {
    const { payload } = yield take(deletePartAction.REQUEST)
    yield fork(handleDeletePartSaga, payload)
  }
}

function* partsSaga() {
  yield all([
    fork(watchGetAllPartsSaga),
    // fork(watchGetAllAvailablePartsSaga),
    fork(watchGetPartSaga),
    fork(watchCreatePartSaga),
    fork(watchUpdatePartSaga),
    fork(watchDeletePartSaga),
  ])
}

export {
  partsSaga as default,
  watchGetAllPartsSaga,
  // watchGetAllAvailablePartsSaga,
  watchGetPartSaga,
  watchCreatePartSaga,
  watchUpdatePartSaga,
  watchDeletePartSaga,
  handleGetAllPartsSaga,
  // handleGetAllAvailablePartsSaga,
  handleGetPartSaga,
  handleCreatePartSaga,
  handleUpdatePartSaga,
  handleDeletePartSaga,
}
