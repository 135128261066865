import { fork, call, takeLatest, put, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as Sentry from '@sentry/browser'
import { notification } from '../components/common/Ant'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAccountProfileAction,
  updateAccountProfileAction,
  updateAccountPasswordAction,
  toggleAccountOptionAction,
  verifyPhoneTokenAction,
  requestPhoneVerificationTokenAction,
} from '../actions/accountActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
  showPhoneVerificationNotificationAction,
  hidePhoneVerificationModalAction,
} from '../actions/uiActions'
import api from '../services/api'
import { connect } from '../services/socket'
import { setToken, setRefreshToken, getRefreshToken } from '../services/storage'
import { setCookie } from '../services/cookies'
import { setFullstoryUser } from '../services/third-party/fullstory'
import { setIntercomUser } from '../services/third-party/intercom'
import { pickErrorMessage } from '../utils/helpers'
import { flagEnabled } from '../utils/config'
import { MESSAGES, PILLAR_TOKEN } from '../constants'

const isCommunicationsEnabled = flagEnabled('REACT_APP_ENABLE_COMMUNICATIONS')

// selectors
const getUserId = state => state.account.profile.id

// handlers

function* handleGetAccountProfileSaga({ payload = null }) {
  try {
    const result = yield call(api.getAccountProfile)

    yield call(setIntercomUser, result)
    yield call(setFullstoryUser, result)
    yield call(Sentry.setUser, {
      id: result.id,
      username: result.email,
      companyId: result.companyId,
      companyName: result.companyName,
    })

    if (isCommunicationsEnabled) {
      yield call(connect)
    }

    yield put(getAccountProfileAction.success(result))

    if (payload) {
      yield put(push(payload))
    }

    if (
      flagEnabled('REACT_APP_ENABLE_PHONE_VERIFICATION') &&
      result.phoneNumber &&
      !result.verified
    ) {
      yield put(showPhoneVerificationNotificationAction())
    }
  } catch (error) {
    yield put(getAccountProfileAction.failure(error))

    if (error.status === 401) {
      const refreshToken = yield call(getRefreshToken)

      if (refreshToken) {
        try {
          const { token, refreshToken: nextRefreshToken } = yield call(
            api.refreshAuthToken,
            refreshToken
          )

          yield call(setToken, token)
          yield call(setRefreshToken, nextRefreshToken)
          yield call(setCookie, PILLAR_TOKEN, token, 30)
          yield fork(handleGetAccountProfileSaga, {})

          if (isCommunicationsEnabled) {
            yield call(connect)
          }
        } catch (error) {
          yield put(showErrorMessageAction(pickErrorMessage(error)))
          yield put(unauthorizedAction())
        }
      } else {
        yield put(showErrorMessageAction(pickErrorMessage(error)))
        yield put(unauthorizedAction())
      }
    } else {
      yield put(showErrorMessageAction(pickErrorMessage(error)))
    }
  }
}

function* handleUpdateAccountProfileSaga({ payload }) {
  try {
    const result = yield call(api.updateAccountProfile, payload)

    yield put(updateAccountProfileAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push('/dashboard'))
  } catch (error) {
    yield put(updateAccountProfileAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateAccountPasswordSaga({ payload }) {
  try {
    yield call(api.updateAccountPassword, payload)
    yield put(updateAccountPasswordAction.success())
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push('/dashboard'))
  } catch (error) {
    yield put(updateAccountPasswordAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleToggleSelectionSaga({ payload }) {
  try {
    const result = yield call(api.updateAccountProfile, payload)

    yield put(updateAccountProfileAction.success(result))
  } catch (error) {
    yield put(updateAccountProfileAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleVerifyPhoneNumberSaga({ payload }) {
  try {
    const userId = yield select(getUserId)
    const result = yield call(
      api.confirmVerificationToken,
      Object.assign(payload, { userId })
    )

    yield put(verifyPhoneTokenAction.success(result))
    yield put(hidePhoneVerificationModalAction())
    yield call(notification.destroy)
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_VERIFIED_PHONE))
  } catch (error) {
    yield put(verifyPhoneTokenAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleRequestPhoneVerificationTokenSaga() {
  try {
    const userId = yield select(getUserId)
    yield call(api.requestVerificationToken, { userId })

    yield put(
      showSuccessMessageAction(
        MESSAGES.SUCCESSFULLY_REQUESTED_PHONE_VERIFICATION
      )
    )
    yield put(requestPhoneVerificationTokenAction.success())
  } catch (error) {
    yield put(requestPhoneVerificationTokenAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAccountProfileSaga() {
  yield takeLatest(getAccountProfileAction.REQUEST, handleGetAccountProfileSaga)
}

function* watchUpdateAccountProfileSaga() {
  yield takeLatest(
    updateAccountProfileAction.REQUEST,
    handleUpdateAccountProfileSaga
  )
}

function* watchUpdateAccountPasswordSaga() {
  yield takeLatest(
    updateAccountPasswordAction.REQUEST,
    handleUpdateAccountPasswordSaga
  )
}

function* watchToggleSelectionSaga() {
  yield takeLatest(toggleAccountOptionAction.REQUEST, handleToggleSelectionSaga)
}

function* watchVerifyPhoneNumberSaga() {
  yield takeLatest(verifyPhoneTokenAction.REQUEST, handleVerifyPhoneNumberSaga)
}

function* watchRequestPhoneVerificationTokenSaga() {
  yield takeLatest(
    requestPhoneVerificationTokenAction.REQUEST,
    handleRequestPhoneVerificationTokenSaga
  )
}

function* accountSaga() {
  yield all([
    fork(watchGetAccountProfileSaga),
    fork(watchUpdateAccountProfileSaga),
    fork(watchUpdateAccountPasswordSaga),
    fork(watchToggleSelectionSaga),
    fork(watchVerifyPhoneNumberSaga),
    fork(watchRequestPhoneVerificationTokenSaga),
  ])
}

export {
  accountSaga as default,
  watchGetAccountProfileSaga,
  watchUpdateAccountProfileSaga,
  watchUpdateAccountPasswordSaga,
  watchToggleSelectionSaga,
  watchVerifyPhoneNumberSaga,
  watchRequestPhoneVerificationTokenSaga,
  handleGetAccountProfileSaga,
  handleUpdateAccountProfileSaga,
  handleUpdateAccountPasswordSaga,
  handleToggleSelectionSaga,
}
