import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CRS, divIcon } from 'leaflet'
import { Map, ImageOverlay, Marker, ZoomControl } from 'react-leaflet'
import { Bread, Crumb } from './common/Bread'
import { ZOOM_LEVELS } from '../constants'
import logoSm from '../assets/logo-sm.png'
import logoMd from '../assets/logo-md.png'
import logoLg from '../assets/logo-lg.png'

class PrintableFloorPlan extends Component {
  static propTypes = {
    getSite: PropTypes.func.isRequired,
    getFloor: PropTypes.func.isRequired,
    getAllLocationsByFloor: PropTypes.func.isRequired,
    site: PropTypes.object.isRequired,
    floor: PropTypes.object.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  }

  componentDidMount() {
    const {
      getSite,
      getFloor,
      getAllLocationsByFloor,
      match: {
        params: { siteSlug, floorId },
      },
    } = this.props

    getSite(siteSlug)
    getFloor(siteSlug, floorId)
    getAllLocationsByFloor(siteSlug, floorId)
  }

  renderMarker = location => {
    const { id, name, coordinates, podPillarId } = location

    const podIcon = divIcon({
      className: 'pod-icon',
      html: `<div class="flex items-center justify-center bg-neon-lime br-100 shadow-2 w2 h2 f7 b tc">${
        podPillarId ? podPillarId : name
      }</div>`,
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: [0, -16],
    })

    return (
      coordinates && <Marker position={coordinates} key={id} icon={podIcon} />
    )
  }

  render() {
    const {
      site: { name: siteName },
      floor: {
        name: floorName,
        buildingName,
        planFileName,
        planZoomLevel,
        floorplanHeight = 0,
        floorplanWidth = 0,
      },
      locations,
      match: {
        params: { floorId },
      },
    } = this.props

    const bounds = [[0, 0], [floorplanHeight, floorplanWidth]]

    return (
      <div className="PrintableFloorPlan">
        <div className="flex justify-center mb3">
          <img
            src={logoSm}
            srcSet={`${logoMd} 1000w, ${logoLg} 2000w`}
            alt="Pillar Technologies"
            height={32}
            width={134}
          />
        </div>
        <div className="flex justify-center mb3">
          <Bread>
            {siteName && <Crumb>{siteName}</Crumb>}
            {buildingName && <Crumb>{buildingName}</Crumb>}
            {floorName && <Crumb>{floorName}</Crumb>}
          </Bread>
        </div>
        {planFileName ? (
          <div>
            <Map
              crs={CRS.Simple}
              center={[floorplanHeight / 2, floorplanWidth / 2]}
              maxBounds={bounds}
              zoom={planZoomLevel}
              viewport={{}}
              minZoom="-3.5"
              maxZoom={ZOOM_LEVELS.MAX}
              zoomSnap={0}
              zoomDelta={0.25}
              wheelPxPerZoomLevel={20}
              className="ba b--gray br2 vh-75 black-80 sans-serif f7 lh-copy bg-moon-gray z-3 mb3"
              attributionControl={false}
              zoomControl={false}
              doubleClickZoom={false}
            >
              <ImageOverlay url={planFileName} bounds={bounds} />
              {locations.map(this.renderMarker)}
              <ZoomControl position="topright" />
            </Map>
            <p className="b tc print-instructions mw6 center">
              For best results, print using a horizontal/landscape orientation.
              You may also zoom in/out if the floor plan is cut off when
              printed.
            </p>
          </div>
        ) : floorId ? (
          <p className="tc bg-white pa5 w-100 br2 f4 b">
            Preparing floor plan...
          </p>
        ) : (
          <p className="tc bg-white pa5 w-100 br2 f4 b">
            Floor plan does not exist!
          </p>
        )}
      </div>
    )
  }
}

export default PrintableFloorPlan
