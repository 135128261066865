import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditSIMCardForm from '../components/forms/EditSIMCardForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createSIMCardAction } from '../actions/simCardsActions'
import { createQueryString } from '../utils/queryParams'
import { USER_PERMISSIONS } from '../constants'
import { hasPermission } from '../utils/hasPermission'
import { flagEnabled } from '../utils/config'

const isFlowMonitorEnabled = flagEnabled('REACT_APP_ENABLE_FLOW_MONITOR')

class CreateSIMCard extends Component {
  static propTypes = {
    getAllFlowMonitors: PropTypes.func.isRequired,
    flowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllGateways: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    gateways: PropTypes.arrayOf(PropTypes.object),
    assetLocations: PropTypes.arrayOf(PropTypes.object),
  }

  componentDidMount() {
    const qs = createQueryString({ perPage: 'all' })
    this.props.getAllGateways(qs)
    this.props.getAllAssetLocations(qs)
    isFlowMonitorEnabled &&
      hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
      this.props.getAllFlowMonitors(qs)
  }

  render() {
    const { gateways, flowMonitors, assetLocations } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/sim-cards">SIM Cards</Anchor>,
      'Create SIM Card',
    ]

    return (
      <section className="CreateSIMCard">
        <FormHeader crumbs={crumbs} />
        <EditSIMCardForm
          submitAction={createSIMCardAction}
          gateways={gateways}
          flowMonitors={flowMonitors}
          assetLocations={assetLocations}
        />
      </section>
    )
  }
}

export default CreateSIMCard
