import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { parse } from 'query-string'
import { decamelize } from 'humps'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import DateRange from './common/DateRange'
import Button from './common/Button'
import { Anchor } from './common/Anchor'
import Select, { Option } from './common/Select'
import ReportChart from './charts/ReportChart'
import { hasRole } from '../utils/hasPermission'
import { createQueryString } from '../utils/queryParams'
import { toTitleCase } from '../utils/textFormatters'
import { SMART_SENSOR_TYPES, ROLES } from '../constants'

const readingOptions = Object.keys(SMART_SENSOR_TYPES).filter(
  x =>
    SMART_SENSOR_TYPES[x] !== SMART_SENSOR_TYPES.SIGNAL &&
    SMART_SENSOR_TYPES[x] !== SMART_SENSOR_TYPES.CARBON__MONOXIDE &&
    SMART_SENSOR_TYPES[x] !== SMART_SENSOR_TYPES.AIR_QUALITY_INDEX
)

class MultipleLocations extends Component {
  constructor() {
    super()

    this.now = moment()

    this.state = {
      now: this.now,
      endValue: this.now,
      startValue: moment(this.now).subtract(1, 'd'),
      locationIds: [],
      reading: SMART_SENSOR_TYPES.TEMPERATURE,
    }
  }

  static propTypes = {
    getLocationsTimeSeriesData: PropTypes.func.isRequired,
    getAllLocationsBySite: PropTypes.func.isRequired,
    getSite: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    allSiteLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    siteName: PropTypes.string,
    match: PropTypes.object,
  }

  componentDidMount() {
    const {
      getLocationsTimeSeriesData,
      getAllLocationsBySite,
      getSite,
      match,
    } = this.props
    const { startValue, endValue } = this.state
    const { siteSlug } = match.params

    getAllLocationsBySite(siteSlug)
    getSite(siteSlug)

    const searchParams = parse(global.location.search)

    if (searchParams.location_ids) {
      const locationIds = searchParams.location_ids
        ? searchParams.location_ids.split(',').map(x => parseInt(x, 10))
        : []
      const columns = searchParams.columns
        ? searchParams.columns.split(',')
        : []
      const query = {
        location_ids: locationIds.join(','),
        columns: decamelize(columns[0]),
      }

      this.setState({
        locationIds,
        reading: columns[0], // currently support selecting single reading only!
      })

      if (searchParams.from && searchParams.to) {
        query.start_time = searchParams.from
        query.end_time = searchParams.to

        this.setState({
          startValue: moment(searchParams.from * 1000),
          endValue: moment(searchParams.to * 1000),
        })
      } else {
        query.start_time = startValue.unix()
        query.end_time = endValue.unix()
      }

      const qs = createQueryString(query)

      getLocationsTimeSeriesData(siteSlug, qs)
    }
  }

  handleCloseDateRange = (startValue, endValue) =>
    this.setState({ startValue, endValue })

  handleLocationIdsChange = locationIds => this.setState({ locationIds })

  handleReadingChange = reading => this.setState({ reading })

  handleSubmit = () => {
    const { getLocationsTimeSeriesData, updateQueryParams, match } = this.props
    const { startValue, endValue, locationIds, reading } = this.state
    const { siteSlug } = match.params

    const query = {
      start_time: startValue.unix(),
      end_time: endValue.unix(),
      columns: decamelize(reading),
      location_ids: locationIds.join(','),
    }
    const qs = createQueryString(query)

    updateQueryParams({
      pathname: match.url,
      search: createQueryString({
        from: startValue.unix(),
        to: endValue.unix(),
        columns: reading,
        location_ids: locationIds.join(','),
      }),
    })

    getLocationsTimeSeriesData(siteSlug, qs)
  }

  render() {
    const {
      allSiteLocations,
      locations,
      isChartDataLoading,
      match: {
        params: { siteSlug },
      },
      siteName,
    } = this.props
    const { startValue, endValue, locationIds, reading } = this.state

    return (
      <section className="MultipleLocations">
        <div className="mb3">
          <Bread>
            <Crumb>
              <Anchor to="/sites">Sites</Anchor>
            </Crumb>
            {siteName && (
              <Crumb>
                <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
              </Crumb>
            )}
            <Crumb>Compare Locations</Crumb>
          </Bread>
        </div>
        <Divider />
        <div>
          <Select
            label="Select locations to compare"
            placeholder="Locations"
            mode="multiple"
            input={{
              value: locationIds,
              onChange: this.handleLocationIdsChange,
            }}
            filterable
          >
            {allSiteLocations.map(x => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex-ns justify-between-ns">
          <Select
            className="w-40-ns mr3-ns"
            label="Select sensor reading to plot"
            placeholder="Sensor reading"
            input={{
              value: reading,
              onChange: this.handleReadingChange,
            }}
            filterable
          >
            {readingOptions.map(x => (
              <Option value={SMART_SENSOR_TYPES[x]} key={SMART_SENSOR_TYPES[x]}>
                {toTitleCase(x)}
              </Option>
            ))}
          </Select>
          <div>
            <DateRange
              label="Select date range"
              startValue={startValue}
              endValue={endValue}
              onClose={this.handleCloseDateRange}
              shouldLimitRange={!hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN)}
            />
          </div>
        </div>
        <div className="flex-ns justify-end-ns mb3">
          <Button
            onClick={this.handleSubmit}
            text={locations.length ? 'Update' : 'Submit'}
          />
        </div>
        {locations.length ? (
          <ReportChart
            locations={locations}
            siteName={siteName}
            reading={reading}
            startValue={startValue}
            endValue={endValue}
            isChartDataLoading={isChartDataLoading}
          />
        ) : (
          <h4 className="f4 tc mv6">Please make selections above.</h4>
        )}
      </section>
    )
  }
}

export default MultipleLocations
