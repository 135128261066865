import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting.js'
import offlineExporting from 'highcharts/modules/offline-exporting.js'
import exportData from 'highcharts/modules/export-data.js'
import HighchartsReact from 'highcharts-react-official'
import { Spin } from '../common/Ant'
import { H4 } from '../common/Headers'
import { getSensorReading } from '../../utils/sensorData'
import { snakeCaseToCamelCase } from '../../utils/textFormatters'
import { unique } from '../../utils/helpers'
import { COLORS } from '../../constants'

exporting(Highcharts)
offlineExporting(Highcharts)
exportData(Highcharts)

const AlertChart = ({ data, threshold, startTime, endTime, isLoading }) => {
  const reading =
    !!threshold.rulesJson &&
    threshold.rulesJson
      .map(x => snakeCaseToCamelCase(x.source))
      .filter(unique)[0]

  let options = {}

  if (reading) {
    const readingData = getSensorReading(reading)

    options = {
      credits: { enabled: false },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'separator',
              'downloadCSV',
            ],
          },
        },
        // TODO
        // filename: '',
        // tableCaption: ''
      },
      chart: {
        zoomType: 'x',
        style: {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
        },
      },
      plotOptions: {
        series: {
          type: 'line',
        },
        line: {
          animation: false,
        },
      },
      title: {
        text: null,
      },
      yAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        labels: {
          style: {
            color: COLORS.BLACK_80,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        type: 'datetime',
        labels: {
          style: {
            color: COLORS.BLACK_80,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      tooltip: {
        valueDecimals: 2,
        headerFormat:
          "<span style='font-size: 0.875rem; margin-bottom: 0.875rem'>{point.key}</span><br />",
        style: {
          fontSize: '0.875rem',
          color: COLORS.BLACK_80,
        },
      },
      legend: {
        verticalAlign: 'top',
        itemStyle: {
          fontSize: '0.875rem',
          color: COLORS.BLACK_80,
        },
        itemHoverStyle: {
          color: COLORS.MOON_GRAY,
        },
      },
      series: [
        {
          name: readingData.name,
          data: data.map(y => [y.time, y[reading]]),
          color: readingData.color,
          tooltip: {
            valueSuffix: readingData.unit,
          },
        },
      ],
    }

    if (startTime) {
      const plotLines = [
        {
          color: COLORS.MOON_GRAY,
          dashStyle: 'longdash',
          value: new Date(startTime).valueOf(),
          width: 1,
          zIndex: 1,
          label: {
            text: 'Start Time',
          },
        },
      ]

      if (endTime) {
        plotLines.push({
          color: COLORS.MOON_GRAY,
          dashStyle: 'longdash',
          value: new Date(endTime).valueOf(),
          width: 1,
          zIndex: 1,
          label: {
            text: 'End Time',
          },
        })
      }

      Object.assign(options.xAxis, {
        plotLines,
        plotBands: [
          {
            color: 'rgba(244, 129, 32, 0.25)',
            from: new Date(startTime).valueOf(),
            to: endTime
              ? new Date(endTime).valueOf()
              : data.length > 0
              ? new Date(data[data.length - 1].time).valueOf()
              : null,
            zIndex: 1,
          },
        ],
      })
    }

    if (threshold.rulesJson && threshold.rulesJson[0]) {
      const rule = threshold.rulesJson[0]
      const thresholdHigh = rule.high
      const thresholdLow = rule.low

      const plotLines = []

      if (thresholdHigh) {
        plotLines.push({
          color: COLORS.DARK_RED,
          dashStyle: 'longdash',
          value: thresholdHigh,
          width: 1,
          zIndex: 1,
          label: {
            text: 'Threshold High',
          },
        })
      }

      if (thresholdLow) {
        plotLines.push({
          color: COLORS.DARK_RED,
          dashStyle: 'longdash',
          value: thresholdLow,
          width: 1,
          zIndex: 1,
          label: {
            text: 'Threshold Low',
          },
        })
      }

      Object.assign(options.yAxis, { plotLines })
    }
  }
  return (
    <div className="AlertChart">
      {isLoading ? (
        <Spin size="large" className="w-100 center mv6" />
      ) : data.length && !!reading ? (
        <div className="mb3">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { height: '400px' } }}
          />
        </div>
      ) : (
        <H4 className="tc">No Data Available for Selected Date Range</H4>
      )}
    </div>
  )
}
AlertChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  threshold: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
}

export default AlertChart
