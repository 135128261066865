import React, { Component } from 'react'
import Gallery from 'react-grid-gallery'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import Divider from '../common/Divider'
import { H3, H4 } from '../common/Headers'
import { Steps, Step, Table, Column } from '../common/Ant'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import Button from '../common/Button'
import { required, isNumeric, isAlphaNumeric } from '../../utils/validators'
import { SIZES } from '../../constants'
import { Spin } from '../common/Ant'
import { Modal } from '../common/Ant'
import ReactCrop from 'react-image-crop'
import { ActionAnchor } from '../common/Anchor'
import actions from 'redux-form/lib/actions'
import { connect } from 'react-redux'

const { change } = actions
let screenWidth = 1024
let screenHeight = 512

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, value) =>
    dispatch(change('EditSiteFloorPlanForm', field, value)),
})

class Floor extends Component {
  static propTypes = {
    changeFormField: PropTypes.func.isRequired,
    floor: PropTypes.string.isRequired,
  }

  renderFields = floor => {
    return (
      <div className="ba b--light-gray">
        <div className="flex-ns mh3 mv3">
          <Field
            name={`${floor}.number`}
            type="text"
            component={InputContainer}
            label="Number"
            validate={[required, isAlphaNumeric]}
            className="w-40-ns mr3-ns"
          />
          <Field
            name={`${floor}.name`}
            type="text"
            component={InputContainer}
            label="Name"
            validate={[required]}
            className="w-40-ns mr3-ns"
          />
          <Field
            name={`${floor}.locations`}
            type="number"
            component={InputContainer}
            label="Number of Locations"
            validate={[required, isNumeric]}
            className="w-20-ns"
          />
        </div>
      </div>
    )
  }

  render() {
    const { floor } = this.props

    return <div>{this.renderFields(floor)}</div>
  }
}

const ConnectedFloor = connect(mapDispatchToProps)(Floor)

const Floors = ({ fields }) => (
  <div className="mb3">
    <H4>Floors</H4>
    <p>
      Create at least one new Floor to assign this floor plan. Locations will be
      automatically added and can be moved after your images have been assigned.
    </p>
    {fields.map((floor, index) => {
      return (
        <div key={index} className="mt3">
          <H4 inline>Floor {index + 1}</H4>
          <ActionAnchor onClick={() => fields.remove(index)}>
            Remove
          </ActionAnchor>
          <ConnectedFloor floor={floor} />
        </div>
      )
    })}
    <ActionAnchor className="mt3" onClick={() => fields.push()} button>
      Create New Floor
    </ActionAnchor>
  </div>
)

Floors.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object,
}

class EditSiteFloorPlanForm extends Component {
  static propTypes = {
    siteSlug: PropTypes.string.isRequired,
    createBuilding: PropTypes.func.isRequired,
    isSiteLoading: PropTypes.bool.isRequired,
    siteFloorPlanSnaps: PropTypes.arrayOf(PropTypes.object).isRequired,
    submitAction: PropTypes.func.isRequired,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    anyTouched: PropTypes.bool.isRequired,
    formValues: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      current: 0,
      src: null,
      cropHeight: 0,
      cropWidth: 0,
      formData: null,
      hasError: false,
      crop: {
        x: 25,
        y: 25,
        width: 50,
        height: 50,
      },
      floorImages: [],
      validImages: [],
      isModalOpen: false,
    }

    this.inputs = {}

    this.onSelectImage = this.onSelectImage.bind(this)
    this.getSelectedImages = this.getSelectedImages.bind(this)
  }

  static defaultProps = {
    formValues: {},
  }

  isCropValid = () => {
    const { crop, validImages, current } = this.state

    const cropHeight = validImages[current - 1].pageHeight * (crop.height / 100)
    const cropWidth = validImages[current - 1].pageWidth * (crop.width / 100)

    if (
      cropHeight < 1000 ||
      cropHeight > 5000 ||
      cropWidth < 1000 ||
      cropWidth > 5000
    ) {
      this.setState({ cropHeight, cropWidth, hasError: true })

      return false
    }

    this.setState({ cropHeight, cropWidth, hasError: false })

    return true
  }

  handleCropChange = crop => {
    this.setState({ crop })
    this.isCropValid()
  }

  getSelectedImages() {
    let selected = []
    let images = this.state.floorImages
    for (let i = 0; i < images.length; i++) {
      if (images[i].isSelected === true) {
        selected.push(images[i])
      }
    }
    return selected
  }

  handleNext = event => {
    const { valid, anyTouched, touch, formValues } = this.props
    let goNext = false
    const { current, crop } = this.state

    if (current === 0) {
      const selectedImages = this.getSelectedImages()
      if (selectedImages.length > 0) {
        this.setState({ validImages: selectedImages })
        goNext = true
      }
    }

    if (current === 0 && goNext) {
      const cur = current + 1
      this.setState({ current: cur })
    } else if (current !== 0 && this.isCropValid() && anyTouched && valid) {
      formValues[`floorPlan${current}X`] = crop.x
      formValues[`floorPlan${current}Y`] = crop.y
      formValues[`floorPlan${current}Width`] = crop.width
      formValues[`floorPlan${current}Height`] = crop.height
      const cur = current + 1
      this.setState({ current: cur })
    } else {
      const fieldNames = Object.keys(this.inputs).map(
        x => this.inputs[x].props.name
      )
      touch(...fieldNames)
    }

    event.preventDefault()
  }

  handlePrev = event => {
    const { formValues } = this.props
    const current = this.state.current - 1
    this.setState({ current })

    if (current !== 0) {
      const crop = {
        x: formValues[`floorPlan${current}X`],
        y: formValues[`floorPlan${current}Y`],
        width: formValues[`floorPlan${current}Width`],
        height: formValues[`floorPlan${current}Height`],
      }
      this.setState({ crop })
    }

    event.preventDefault()
  }

  onSelectImage(index, image) {
    let images = this.state.floorImages
    let img = images[index]
    img.isSelected = !img.isSelected
    this.setState({ floorImages: images })
    this.getSelectedImages()
  }

  getBuildingNameById = id => {
    const building = this.props.buildings.filter(building => building.id === id)
    return building[0].name
  }

  handleOpen = () => this.setState({ isModalOpen: true })
  handleClose = () => this.setState({ isModalOpen: false })

  handleBuildingSubmit = building => {
    const { createBuilding, siteSlug, formValues } = this.props

    if (
      formValues['building'] &&
      isAlphaNumeric(formValues['building']) === undefined &&
      formValues['buildingName']
    ) {
      createBuilding(
        siteSlug,
        formValues['building'],
        formValues['buildingName'],
        true
      )
      this.handleClose()
    }
  }

  render() {
    const {
      formValues,
      handleSubmit,
      submitting,
      submitAction,
      isSiteLoading,
      buildings,
    } = this.props

    const {
      current,
      crop,
      hasError,
      floorImages,
      validImages,
      cropWidth,
      cropHeight,
      isModalOpen,
    } = this.state

    let imageHeight =
      cropHeight && crop.height
        ? Math.round((100 * cropHeight) / crop.height)
        : 1024
    let imageWidth =
      cropWidth && crop.width
        ? Math.round((100 * cropWidth) / crop.width)
        : 1024
    const imageRatio = imageWidth / imageHeight
    screenHeight =
      (this.cropDiv && this.cropDiv.offsetHeight > screenHeight * 1.1) ||
      (this.cropDiv && this.cropDiv.offsetHeight < screenHeight * 0.9)
        ? this.cropDiv.offsetHeight
        : screenHeight
    screenWidth =
      (this.cropDiv && this.cropDiv.offsetWidth > screenWidth * 1.1) ||
      (this.cropDiv && this.cropDiv.offsetWidth < screenWidth * 0.9)
        ? this.cropDiv.offsetWidth
        : screenWidth
    const screenRatio = screenWidth / screenHeight

    if (imageRatio >= 1) {
      if (imageRatio >= screenRatio) {
        imageHeight = screenHeight / imageRatio
        imageWidth = screenWidth
      } else {
        imageHeight = screenHeight / imageRatio
        imageWidth = screenWidth / imageRatio
      }
    } else {
      imageHeight = screenHeight
      imageWidth = screenWidth * imageRatio
    }

    const { siteFloorPlanSnaps } = this.props
    const submit = handleSubmit(submitAction)

    if (floorImages.length < siteFloorPlanSnaps.length) {
      for (let i = 0; i < siteFloorPlanSnaps.length; i++) {
        floorImages.push({
          src: siteFloorPlanSnaps[i].src,
          thumbnail: siteFloorPlanSnaps[i].src,
          thumbnailWidth: 330,
          thumbnailHeight: 330,
          isSelected: true,
          planFileName: siteFloorPlanSnaps[i].planFileName,
          pageHeight: siteFloorPlanSnaps[i].height,
          pageWidth: siteFloorPlanSnaps[i].width,
        })
      }
    }

    let steps = []

    if (siteFloorPlanSnaps.length > 0) {
      steps = [
        {
          title: 'Select Floor Plans',
          content: (
            <div>
              <Gallery
                images={floorImages}
                onSelectImage={this.onSelectImage}
                rowHeight={330}
              />
            </div>
          ),
        },
      ]

      formValues['siteFloorPlans'] = validImages.length

      for (let index = 0; index < validImages.length; index++) {
        formValues[`floorPlan${index + 1}FileName`] =
          validImages[index].planFileName

        steps.push({
          title: `Floor ${index + 1} of ${validImages.length}`,
          content: (
            <div>
              {validImages.length > 0 && (
                <div ref={el => (this.cropDiv = el)}>
                  <H4>Crop Floor Plan</H4>
                  {hasError && (
                    <p className="red mb3">
                      A floor plan must be between 1000-5000px tall and wide.
                      Your current selection is{' '}
                      <span className="b">{Math.round(cropHeight)}px tall</span>{' '}
                      and{' '}
                      <span className="b">{Math.round(cropWidth)}px wide</span>.
                      Please re-size to fit constraints.
                    </p>
                  )}
                  <ReactCrop
                    className="mb3"
                    imageStyle={{
                      minWidth: `${imageWidth}px`,
                      minHeight: `${imageWidth}px`,
                      width: `${imageWidth}px`,
                      height: `${imageHeight}px`,
                    }}
                    src={validImages[index].src}
                    crop={crop}
                    onChange={this.handleCropChange}
                    onImageLoaded={this.isCropValid}
                  />
                </div>
              )}
              <Divider />
              <div className="CreateNewBuilding mb3">
                <H4>Buildings</H4>
                <p>Select an existing Building or create a new one.</p>
                <Button
                  onClick={this.handleOpen}
                  text="Create New Building"
                  size={SIZES.SMALL}
                />
                <Modal
                  visible={isModalOpen}
                  className="Modal"
                  title="Create New Building"
                  onCancel={this.handleClose}
                  footer={null}
                >
                  <Form onSubmit={this.handleBuildingSubmit}>
                    <div className="flex-ns">
                      <Field
                        name="building"
                        type="text"
                        component={InputContainer}
                        label="Building"
                        validate={[required, isAlphaNumeric]}
                        className="w-20-ns mr3-ns"
                      />
                      <Field
                        name="buildingName"
                        type="text"
                        component={InputContainer}
                        label="Name"
                        validate={[required]}
                        className="w-80-ns"
                      />
                    </div>
                    <div className="flex justify-between">
                      <Button invert text="Cancel" onClick={this.handleClose} />
                      <Button onClick={this.handleBuildingSubmit} />
                    </div>
                  </Form>
                </Modal>
              </div>
              <div className="flex-ns">
                <Field
                  name={`floorPlan${index + 1}BuildingId`}
                  component={SelectContainer}
                  label="Building"
                  placeholder="Select a building"
                  className="w-100-ns"
                  filterable
                >
                  {buildings.map(x => (
                    <Option value={x.id} key={x.id}>
                      {x.name}
                    </Option>
                  ))}
                </Field>
              </div>
              <Divider />
              <FieldArray
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name={`floorPlan${index + 1}`}
                component={Floors}
              />
            </div>
          ),
        })
      }

      let selectedFloors = []
      if (current > 1) {
        validImages.forEach((x, index) => {
          const floors =
            formValues[`floorPlan${index + 1}`] &&
            formValues[`floorPlan${index + 1}`].length
              ? formValues[`floorPlan${index + 1}`]
              : []

          for (let flIndex = 0; flIndex < floors.length; flIndex++) {
            selectedFloors.push({
              floorNumber: floors[flIndex] ? floors[flIndex].number : '',
              floorName: floors[flIndex] ? floors[flIndex].name : '',
              floorLocations: floors[flIndex] ? floors[flIndex].locations : '',
              floorBuilding: formValues[`floorPlan${index + 1}BuildingId`],
            })
          }
        })
      }

      steps.push({
        title: 'Submit',
        content: (
          <div>
            <H3 className="tc">Summary</H3>
            <Table
              dataSource={selectedFloors}
              rowKey="floorNumber"
              pagination={false}
              size="small"
              scroll={{ x: 500 }}
              tableLayout="auto"
              bordered
            >
              <Column
                title="Floor Number"
                dataIndex="floorNumber"
                render={text => (text ? text : '--')}
              />
              <Column
                title="Floor Name"
                dataIndex="floorName"
                render={text => (text ? text : '--')}
              />
              <Column
                title="Locations"
                dataIndex="floorLocations"
                render={text => (text ? text : '--')}
              />
              <Column
                title="Building"
                dataIndex="floorBuilding"
                render={text => (text ? this.getBuildingNameById(text) : '--')}
              />
            </Table>
            <div className="flex justify-center">
              <Button
                text="Create Floors"
                type="submit"
                submitting={submitting}
                className="w5 center"
              />
            </div>
          </div>
        ),
      })
    }

    return (
      <section className="EditSiteFloorPlanForm">
        <div>
          {isSiteLoading ? (
            <Spin size="large" className="w-100 center mv5" />
          ) : (
            <div>
              {siteFloorPlanSnaps.length > 0 ? (
                <div>
                  <Form onSubmit={submit}>
                    <Steps current={current} className="mb3">
                      {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>
                    <div className="mb3 ba b--light-gray br2 pa3 overflow-auto">
                      {steps[current].content}
                    </div>
                    <div className="flex justify-between">
                      <Button
                        disabled={current === 0}
                        size={SIZES.SMALL}
                        onClick={this.handlePrev}
                        text="Previous"
                      />
                      <Button
                        disabled={current >= steps.length - 1}
                        size={SIZES.SMALL}
                        onClick={this.handleNext}
                        text="Next"
                      />
                    </div>
                  </Form>
                </div>
              ) : (
                <div className="flex justify-center">No floor plan found!</div>
              )}
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditSiteFloorPlanForm' })(
  EditSiteFloorPlanForm
)
