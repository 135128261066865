import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllCommentsAction,
  getAllTagsAction,
  getTagAction,
  getAllAvailbleUserMentionsAction,
} from '../actions/commentsActions'

const byCommentId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllCommentsAction.SUCCESS:
        payload.items.forEach(comment => {
          draft[comment.id] = comment
        })
        break
    }
  })

const byTagId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllTagsAction.SUCCESS:
        payload.items.forEach(tag => {
          tag.createdBy =
            tag.user.firstName && tag.user.lastName
              ? `${tag.user.firstName} ${tag.user.lastName}`
              : null
          draft[tag.id] = tag
        })
        break
    }
  })

const visibleComments = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllCommentsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(comment => comment.id)
        )
        break
    }
  })

const visibleTags = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllTagsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(tag => tag.id)
        )
        break
    }
  })

const currentTag = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getTagAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllCommentsAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const tagsMeta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllTagsAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const userMentions = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllAvailbleUserMentionsAction.SUCCESS:
        return payload
    }
  })

const commentsReducer = combineReducers({
  byCommentId,
  byTagId,
  visibleComments,
  visibleTags,
  currentTag,
  tagsMeta,
  meta,
  userMentions,
})

const getComment = (state, id) => state.byCommentId[id]
const getTag = (state, id) => state.byTagId[id]
const getCurrentTag = state => state.currentTag
const getVisibleComments = state =>
  state.visibleComments.map(id => getComment(state, id))
const getVisibleTags = state => state.visibleTags.map(id => getTag(state, id))
const getAllCommentsMeta = state => state.meta
const getAllTagsMeta = state => state.tagsMeta
const getAllUserMentions = state => state.userMentions

export {
  commentsReducer as default,
  getVisibleComments,
  getVisibleTags,
  getAllCommentsMeta,
  getAllTagsMeta,
  getCurrentTag,
  getAllUserMentions,
}
