import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import Button from '../common/Button'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { H3 } from '../common/Headers'
import { ActionAnchor } from '../common/Anchor'
import { verifyPhoneTokenAction } from '../../actions/accountActions'
import { required, isNumeric } from '../../utils/validators'

const PhoneVerificationForm = ({
  handleSubmit,
  submitting,
  error,
  requestPhoneVerificationToken,
}) => {
  const submit = handleSubmit(verifyPhoneTokenAction)

  return (
    <section className="PhoneVerificationForm">
      <H3>Verify Phone Number</H3>
      <div>
        <p>
          Please enter the verification token you received below. If you did not
          receive a message or your token has expired,{' '}
          <ActionAnchor onClick={requestPhoneVerificationToken}>
            request a new token
          </ActionAnchor>
          .
        </p>
      </div>
      <Form onSubmit={submit}>
        <Divider />
        <div className="flex-ns">
          <Field
            name="token"
            type="text"
            component={InputContainer}
            label="Verification Token"
            validate={[required, isNumeric]}
            className="w-100"
          />
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

PhoneVerificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  requestPhoneVerificationToken: PropTypes.func.isRequired,
}

export default reduxForm({ form: 'PhoneVerificationForm' })(
  PhoneVerificationForm
)
