import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from './authReducer'
import companiesReducer from './companiesReducer'
import usersReducer from './usersReducer'
import sitesReducer from './sitesReducer'
import gatewaysReducer from './gatewaysReducer'
import podsReducer from './podsReducer'
import buildingsReducer from './buildingsReducer'
import floorsReducer from './floorsReducer'
import locationsReducer from './locationsReducer'
import thresholdsReducer from './thresholdsReducer'
import alertsReducer from './alertsReducer'
import accountReducer from './accountReducer'
import deploymentsReducer from './deploymentsReducer'
import dashboardReducer from './dashboardReducer'
import uiReducer from './uiReducer'
import formReducer from './formReducer'
import shipmentsReducer from './shipmentsReducer'
import reportsReducer from './reportsReducer'
import otaReducer from './otaReducer'
import simCardsReducer from './simCardsReducer'
import partsReducer from './partsReducer'
import purchasesReducer from './purchasesReducer'
import flowMonitorsReducer from './flowMonitorsReducer'
import gatewaySuppliesReducer from './gatewaySuppliesReducer'
import commentsReducer from './commentsReducer'
import inventoryReducer from './inventoryReducer'
import escalationPoliciesReducer from './escalationPoliciesReducer'
import assetLocationsReducer from './assetLocationsReducer'
import actionReportsReducer from './actionReportsReducer'
import workflowsReducer from './workflowsReducer'
import developerReducer from './developerReducer'
import communicationsReducer from './communicationsReducer'
import leadsReducer from './leadsReducer'
import routerLocationHistoryReducer from './routerLocationHistoryReducer'
import socketReducer from './socketReducer'
import bleReducer from './bleReducer'
import networksReducer from './networksReducer'
import alertSummariesReducer from './alertSummariesReducer'
import locationGroupsReducer from './locationGroupsReducer'

const createRootReducer = history =>
  combineReducers({
    auth: authReducer,
    companies: companiesReducer,
    users: usersReducer,
    simCards: simCardsReducer,
    parts: partsReducer,
    purchases: purchasesReducer,
    flowMonitors: flowMonitorsReducer,
    gatewaySupplies: gatewaySuppliesReducer,
    sites: sitesReducer,
    gateways: gatewaysReducer,
    pods: podsReducer,
    buildings: buildingsReducer,
    floors: floorsReducer,
    locations: locationsReducer,
    thresholds: thresholdsReducer,
    alerts: alertsReducer,
    account: accountReducer,
    dashboard: dashboardReducer,
    ui: uiReducer,
    router: connectRouter(history),
    form: formReducer,
    deployments: deploymentsReducer,
    shipments: shipmentsReducer,
    reports: reportsReducer,
    ota: otaReducer,
    inventory: inventoryReducer,
    escalationPolicies: escalationPoliciesReducer,
    assetLocations: assetLocationsReducer,
    comments: commentsReducer,
    actionReports: actionReportsReducer,
    workflows: workflowsReducer,
    developer: developerReducer,
    communications: communicationsReducer,
    leads: leadsReducer,
    routerLocationHistory: routerLocationHistoryReducer,
    socket: socketReducer,
    ble: bleReducer,
    networks: networksReducer,
    summaries: alertSummariesReducer,
    locationGroups: locationGroupsReducer,
  })

export default createRootReducer
