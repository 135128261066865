import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllSIMCardsAction,
  getAllAvailableSIMCardsAction,
  getSIMCardAction,
} from '../actions/simCardsActions'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllSIMCardsAction.SUCCESS:
        payload.items.forEach(simCard => {
          draft[simCard.id] = simCard
        })
        break
    }
  })

const availableById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAvailableSIMCardsAction.SUCCESS:
        payload.forEach(simCard => {
          draft[simCard.id] = simCard
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllSIMCardsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(simCard => simCard.id)
        )
        break
    }
  })

const availableVisibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailableSIMCardsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(simCard => simCard.id)
        )
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllSIMCardsAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const current = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getSIMCardAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const simCardsReducer = combineReducers({
  byId,
  visibleIds,
  availableById,
  availableVisibleIds,
  current,
  meta,
})

const getSIMCard = (state, id) => state.byId[id]
const getAvailableSIMCard = (state, id) => state.availableById[id]
const getCurrentSIMCard = state => state.current
const getVisibleSIMCards = state =>
  state.visibleIds.map(id => getSIMCard(state, id))
const getSIMCardsMeta = state => state.meta
const getAvailableVisibleSIMCards = state =>
  state.availableVisibleIds.map(id => getAvailableSIMCard(state, id))

export {
  simCardsReducer as default,
  getCurrentSIMCard,
  getVisibleSIMCards,
  getAvailableVisibleSIMCards,
  getSIMCardsMeta,
}
