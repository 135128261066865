import { connect } from 'react-redux'
import Chat from '../components/Chat'
import {
  getConversationAction,
  clearConversationAction,
} from '../actions/communicationsActions'
import { getAccountProfile } from '../reducers/accountReducer'
import {
  getCurrentConversation,
  getCurrentConversationParticipant,
  getCurrentConversationSlug,
} from '../reducers/communicationsReducer'

const mapStateToProps = ({ communications, account }) => ({
  currentConversation: getCurrentConversation(communications),
  chatroomSlug: getCurrentConversationSlug(communications),
  accountProfile: getAccountProfile(account),
  participant: getCurrentConversationParticipant(communications),
})

const mapDispatchToProps = dispatch => ({
  clearConversation: () => dispatch(clearConversationAction()),
  getConversation: (slug, qs) =>
    dispatch(getConversationAction.request({ slug, qs })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)
