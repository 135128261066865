import React, { Component } from 'react'
import sortBy from 'sort-by'
import PropTypes from 'prop-types'
import ThresholdUserMappingsCSVDownload from './ThresholdUserMappingsCSVDownload'
import { Table, Column } from './common/Ant'
import { H4 } from './common/Headers'
import Checkbox from './common/Checkbox'
import { Anchor } from './common/Anchor'
import { toTitleCase } from '../utils/textFormatters'
import Divider from './common/Divider'

class AggregateThresholdUserMappings extends Component {
  static propTypes = {
    getMappings: PropTypes.func.isRequired,
    updateMapping: PropTypes.func.isRequired,
    mappings: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteSlug: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    hideTable: PropTypes.string.isRequired,
    selectedThresholdId: PropTypes.string.isRequired,
    updateBuildingThresholdUserMapping: PropTypes.func.isRequired,
    updateFloorThresholdUserMapping: PropTypes.func.isRequired,
    updateLocationThresholdUserMapping: PropTypes.func.isRequired,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  static defaultProps = {
    selectedThresholdId: '',
    buildings: [],
    mappings: [],
  }

  componentDidMount() {
    const { getMappings } = this.props
    getMappings()
  }

  state = {
    sortedInfo: {},
  }

  handleChange = mappingId => value => {
    const { updateMapping } = this.props

    updateMapping({
      mappingId,
      smsNotifications: value,
      emailNotifications: value,
    })
  }

  handleSortChange = (pagination, filters, sorter) =>
    this.setState({ sortedInfo: sorter })

  handleFilterChange = (keyName, keyValue) => value => {
    const {
      updateMapping,
      updateBuildingThresholdUserMapping,
      updateFloorThresholdUserMapping,
      updateLocationThresholdUserMapping,
    } = this.props
    const mappingId =
      keyName === 'site' ? keyValue : keyValue.userThresholdMappingId

    if (keyName === 'site') {
      updateMapping({
        mappingId,
        smsNotifications: value,
        emailNotifications: value,
      })
    } else if (keyName === 'building') {
      const buildingId = keyValue.id
      updateBuildingThresholdUserMapping({
        mappingId,
        buildingId,
        smsNotifications: value,
        emailNotifications: value,
      })
    } else if (keyName === 'floor') {
      const floorId = keyValue.id
      updateFloorThresholdUserMapping({
        mappingId,
        floorId,
        smsNotifications: value,
        emailNotifications: value,
      })
    } else if (keyName === 'location') {
      const locationId = keyValue.id
      const floorId = keyValue.floorId
      updateLocationThresholdUserMapping({
        mappingId,
        floorId,
        locationId,
        smsNotifications: value,
        emailNotifications: value,
      })
    }
  }

  render() {
    const {
      mappings,
      selectedThresholdId,
      siteName,
      siteSlug,
      buildings,
    } = this.props
    const { sortedInfo } = this.state

    let userThreshold = []
    mappings.forEach(user => {
      let activeBuilding = false
      user.buildings = buildings.map(building => {
        const floorData = []
        let activeFloor = false
        building.floors.forEach(floor => {
          const locationData = []
          let activeLocation = false
          floor.locations.forEach(location => {
            userThreshold = user.thresholds
              ? user.thresholds.filter(
                  x =>
                    x.thresholdId === selectedThresholdId &&
                    x.resourceType === 'LOCATION' &&
                    x.resourceId === location.id
                )
              : []

            if (floor.id === location.floorId) {
              if (userThreshold.length && userThreshold[0].active) {
                activeLocation = true
                activeFloor = true
                activeBuilding = true
              }
              locationData.push({
                id: location.id,
                name: location.name,
                floorId: location.floorId,
                active: userThreshold.length
                  ? userThreshold[0].active
                  : undefined,
                userThresholdMappingId: userThreshold.length
                  ? userThreshold[0].mappingId
                  : '',
              })
            }
          })

          userThreshold = user.thresholds
            ? user.thresholds.filter(
                x =>
                  x.thresholdId === selectedThresholdId &&
                  x.resourceType === 'FLOOR' &&
                  x.resourceId === floor.id
              )
            : []

          if (building.id === floor.buildingId) {
            if (userThreshold.length && userThreshold[0].active) {
              activeFloor = true
              activeBuilding = true
            }
            floorData.push({
              id: floor.id,
              name: floor.name,
              active: userThreshold.length
                ? userThreshold[0].active
                : undefined,
              userThresholdMappingId: userThreshold.length
                ? userThreshold[0].mappingId
                : '',
              activeSuccessor: activeLocation,
              locations: locationData,
            })
          }
        })
        userThreshold = user.thresholds
          ? user.thresholds.filter(
              x =>
                x.thresholdId === selectedThresholdId &&
                x.resourceType === 'BUILDING' &&
                x.resourceId === building.id
            )
          : []
        if (userThreshold.length && userThreshold[0].active) {
          activeBuilding = true
        }
        return {
          id: building.id,
          name: building.name,
          active: userThreshold.length ? userThreshold[0].active : undefined,
          userThresholdMappingId: userThreshold.length
            ? userThreshold[0].mappingId
            : '',
          activeSuccessor: activeFloor,
          floors: floorData,
        }
      })
      user.activeSuccessor = activeBuilding
    })

    const expandedLocationRowRender = floor => (
      <div className="mt1">
        <Table
          dataSource={floor.locations}
          rowKey="id"
          bordered
          pagination={false}
          tableLayout="auto"
        >
          <Column
            title="Location Name"
            width={200}
            dataIndex="name"
            render={(text, record) =>
              text ? (
                <Anchor
                  to={`/sites/${siteSlug}/floors/${floor.id}/locations/${record.id}`}
                >
                  {text}
                </Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Mapping user to location"
            width={200}
            dataIndex="userThreshold"
            render={(text, record) => {
              if (record.active === undefined) {
                return <span className="moon-gray b">N/A</span>
              } else {
                return (
                  <Checkbox
                    type="checkbox"
                    input={{
                      checked: record.active,
                      onChange: this.handleFilterChange('location', record),
                    }}
                    noMargin
                  />
                )
              }
            }}
          />
        </Table>
      </div>
    )

    const expandedFloorRowRender = building => (
      <div className="mt1">
        <Table
          className="nested-table"
          expandedRowRender={expandedLocationRowRender}
          dataSource={building.floors}
          rowKey="id"
          bordered
          pagination={false}
          tableLayout="auto"
        >
          <Column
            title="Floor Name"
            width={200}
            dataIndex="name"
            render={(text, record) =>
              text ? (
                <Anchor to={`/sites/${siteSlug}/floors/${record.id}`}>
                  {text}
                </Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Mapping user to floor"
            width={200}
            dataIndex="userThreshold"
            render={(text, record) => {
              if (record.active === undefined) {
                return (
                  <span className="moon-gray b">
                    {record.activeSuccessor ? 'N/A**' : 'N/A'}
                  </span>
                )
              } else {
                return (
                  <Checkbox
                    type="checkbox"
                    input={{
                      checked: record.active,
                      onChange: this.handleFilterChange('floor', record),
                    }}
                    noMargin
                  />
                )
              }
            }}
          />
        </Table>
      </div>
    )

    const expandedRowRender = user => (
      <div className="mt1">
        <Table
          className="nested-table"
          expandedRowRender={expandedFloorRowRender}
          dataSource={user.buildings}
          rowKey="id"
          bordered
          pagination={false}
          tableLayout="auto"
        >
          <Column
            title="Building Name"
            width={200}
            dataIndex="name"
            render={(text, record) =>
              text ? (
                <Anchor to={`/sites/${siteSlug}/buildings/${record.id}`}>
                  {text}
                </Anchor>
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Mapping user to building"
            width={200}
            dataIndex="userThreshold"
            render={(text, record) => {
              if (record.active === undefined) {
                return (
                  <span className="moon-gray b">
                    {record.activeSuccessor ? 'N/A**' : 'N/A'}
                  </span>
                )
              } else {
                return (
                  <Checkbox
                    type="checkbox"
                    input={{
                      checked: record.active,
                      onChange: this.handleFilterChange('building', record),
                    }}
                    noMargin
                  />
                )
              }
            }}
          />
        </Table>
      </div>
    )
    if (this.props.hideTable) {
      return (
        <div>
          <Divider />
        </div>
      )
    } else if (mappings.length) {
      return (
        <div className="w-100-ns mb4">
          <Table
            className="nested-table"
            dataSource={mappings}
            onChange={this.handleSortChange}
            rowKey="slug"
            expandedRowRender={expandedRowRender}
            pagination={false}
            tableLayout="auto"
            bordered
          >
            <Column
              title="User"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              render={(text, record) => (
                <Anchor to={`/users/${record.slug}`}>{text}</Anchor>
              )}
              width={200}
            />
            {mappings[0].thresholds
              .filter(
                x =>
                  x.thresholdId === selectedThresholdId &&
                  x.resourceType === 'SITE'
              )
              .map(x => {
                return (
                  <Column
                    key={x.thresholdSlug}
                    title="Mapping user to site"
                    dataIndex={x.thresholdSlug}
                    width={200}
                    render={(text, record) => {
                      const index = record.thresholds
                        .map(y => y.id)
                        .indexOf(x.id)
                      const { active, mappingId } = record.thresholds[index]

                      if (active === undefined) {
                        return (
                          <span className="moon-gray b">
                            {record.activeSuccessor ? 'N/A**' : 'N/A'}
                          </span>
                        )
                      } else {
                        return (
                          <Checkbox
                            type="checkbox"
                            input={{
                              checked: active,
                              onChange: this.handleFilterChange(
                                'site',
                                mappingId
                              ),
                            }}
                            noMargin
                          />
                        )
                      }
                    }}
                  />
                )
              })}
            <Column
              title="Role"
              dataIndex="role"
              sorter={sortBy('role')}
              sortOrder={sortedInfo.field === 'role' && sortedInfo.order}
              render={text => toTitleCase(text)}
              width={200}
            />
          </Table>
          <ThresholdUserMappingsCSVDownload
            mappings={mappings}
            siteName={siteName}
          />
        </div>
      )
    } else {
      return <H4 className="tc">No Mappings Available</H4>
    }
  }
}

export default AggregateThresholdUserMappings
