import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import CommunicationsPage from '../components/pages/CommunicationsPage'
import {
  getAllUsersAction,
  getUserAction,
  clearUserAction,
} from '../actions/usersActions'
import {
  getAllSupportPhoneNumbersAction,
  getSupportPhoneNumberAction,
  deleteSupportPhoneNumberAction,
  getAllConversationsAction,
  getConversationAction,
  createConversationAction,
  clearConversationAction,
  createBridgedPhoneCallAction,
  closeConversationAction,
} from '../actions/communicationsActions'
import {
  getAllCommentsAction,
  getAllTagsAction,
  deleteCommentAction,
  createCommentAction,
  updateCommentAction,
} from '../actions/commentsActions'
import {
  getVisiblePhoneNumbers,
  getPhoneNumbersMeta,
  getCurrentPhoneNumber,
  getVisibleConversations,
  getConversationsMeta,
  getCurrentConversation,
  getCurrentConversationParticipant,
  getCurrentConversationPillarParticipant,
} from '../reducers/communicationsReducer'
import { getVisibleUsers, getCurrentUser } from '../reducers/usersReducer'
import { getAccountProfile } from '../reducers/accountReducer'
import { getVisibleComments, getVisibleTags } from '../reducers/commentsReducer'
import { isCommunicationLoading } from '../reducers/uiReducer'
import { createQueryString } from '../utils/queryParams'
import { ROLES } from '../constants'

const mapStateToProps = ({ communications, users, account, comments, ui }) => ({
  phoneNumbers: getVisiblePhoneNumbers(communications),
  currentPhoneNumber: getCurrentPhoneNumber(communications),
  users: getVisibleUsers(users),
  phoneNumbersMeta: getPhoneNumbersMeta(communications),
  conversations: getVisibleConversations(communications),
  conversationMeta: getConversationsMeta(communications),
  accountProfile: getAccountProfile(account),
  isCommunicationLoading: isCommunicationLoading(ui),
  currentConversation: getCurrentConversation(communications),
  participant: getCurrentConversationParticipant(communications),
  pillarParticipant: getCurrentConversationPillarParticipant(communications),
  user: getCurrentUser(users),

  comments: getVisibleComments(comments),
  tags: getVisibleTags(comments),
})

const mapDispatchToProps = dispatch => ({
  updateQueryParams: location => dispatch(replace(location)),
  getAllSupportPhoneNumbers: qs =>
    dispatch(getAllSupportPhoneNumbersAction.request(qs)),
  getSupportPhoneNumber: slug =>
    dispatch(getSupportPhoneNumberAction.request(slug)),
  deleteSupportPhoneNumber: slug =>
    dispatch(deleteSupportPhoneNumberAction.request(slug)),
  // TODO
  // open this up to more user roles
  getUsers: filter =>
    dispatch(
      getAllUsersAction.request(
        createQueryString({
          page: 'all',
          role: [ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN],
          filter,
        })
      )
    ),
  getUser: userSlug => dispatch(getUserAction.request(userSlug)),
  getAllConversations: query =>
    dispatch(getAllConversationsAction.request(createQueryString(query))),
  getConversation: (slug, qs) => {
    dispatch(clearConversationAction())
    dispatch(getConversationAction.request({ slug, qs }))
  },
  createConversation: (userSlug, message) =>
    dispatch(createConversationAction.request({ userSlug, message })),
  createBridgedPhoneCall: (userPhoneNumber, userSlug) =>
    dispatch(
      createBridgedPhoneCallAction.request({ userPhoneNumber, userSlug })
    ),
  closeConversation: conversationSlug =>
    dispatch(closeConversationAction.request(conversationSlug)),
  clearConversation: () => dispatch(clearConversationAction()),
  clearUser: () => dispatch(clearUserAction()),

  getAllTags: qs => dispatch(getAllTagsAction.request(qs)),
  getAllComments: qs => dispatch(getAllCommentsAction.request(qs)),
  deleteComment: comment => dispatch(deleteCommentAction.request(comment)),
  createComment: comment => dispatch(createCommentAction.request(comment)),
  editComment: comment => dispatch(updateCommentAction.request(comment)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationsPage)
