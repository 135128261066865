import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { logger } from 'redux-logger'
import createRootReducer from '../reducers/createRootReducer'

const history = createBrowserHistory()

const configureStore = initialState => {
  const composeEnhancers =
    global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const sagaMiddleware = createSagaMiddleware()
  const historyMiddleware = routerMiddleware(history)

  const composedMiddlewares = composeEnhancers(
    applyMiddleware(sagaMiddleware, historyMiddleware, logger)
  )

  return {
    ...createStore(
      createRootReducer(history),
      initialState,
      composedMiddlewares
    ),
    runSaga: sagaMiddleware.run,
  }
}

const store = configureStore()

export { store as default, history }
