import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Dropdown, Menu, Avatar, Icon, Badge } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import Checkbox from './common/Checkbox'
import PillarLogo from './common/PillarLogo'
import { flagEnabled } from '../utils/config'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { USER_PERMISSIONS, ROLES } from '../constants'

const isCommunicationsEnabled = flagEnabled('REACT_APP_ENABLE_COMMUNICATIONS')

const TopNav = props => {
  const {
    isAuthorized,
    accountProfile,
    handleLogOut,
    isFullWidth,
    toggleFullWidth,
  } = props
  const { firstName, lastName } = accountProfile
  const initials =
    firstName && lastName
      ? firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
      : ''

  const menu = (
    <Menu>
      {isCommunicationsEnabled &&
        hasPermission(USER_PERMISSIONS.ENABLE_COMMS_HUB) &&
        accountProfile.unreadChats > 0 && (
          <Menu.Item>
            <Anchor to="/communications">
              <span className="b black-80">
                <Icon type="message" /> New Messages
              </span>
            </Anchor>
          </Menu.Item>
        )}
      <Menu.Item>
        <Anchor to="/account">
          <Icon type="user" /> Profile
        </Anchor>
      </Menu.Item>
      <Menu.Item>
        <Anchor to="/account/settings">
          <Icon type="setting" /> Settings
        </Anchor>
      </Menu.Item>
      {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
        <Menu.Item>
          <Checkbox
            type="checkbox"
            label="Full Width"
            noMargin
            input={{
              checked: isFullWidth,
              onChange: value => toggleFullWidth(value),
            }}
          />
        </Menu.Item>
      )}
      <Menu.Item>
        <Anchor to="/native-apps">
          <Icon type="appstore" /> <span className="b">Pillar On-Site App</span>
        </Anchor>
      </Menu.Item>
      <Menu.Item>
        <ActionAnchor onClick={handleLogOut}>
          <Icon type="logout" /> Log Out
        </ActionAnchor>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout className="TopNav flex-column fixed w-100 items-center items-start-l ph2 ph3-ns pv3 bg-white bb b--light-gray z-999">
      <Anchor to={isAuthorized ? '/dashboard' : '/'}>
        <PillarLogo />
      </Anchor>
      {isAuthorized && (
        <div className="flex fixed top-1 right-1 items-center">
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            {isCommunicationsEnabled &&
            hasPermission(USER_PERMISSIONS.ENABLE_COMMS_HUB) &&
            accountProfile.unreadChats > 0 ? (
              <Badge count={accountProfile.unreadChats}>
                <Avatar className="bg-dark-blue pointer">{initials}</Avatar>
              </Badge>
            ) : (
              <Avatar className="bg-dark-blue pointer">{initials}</Avatar>
            )}
          </Dropdown>
        </div>
      )}
    </Layout>
  )
}

TopNav.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  accountProfile: PropTypes.object.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  toggleFullWidth: PropTypes.func.isRequired,
}

export default TopNav
