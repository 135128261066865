import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditWaterValveForm from './forms/EditWaterValveForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateWaterValveAction } from '../actions/flowMonitorsActions'

class EditWaterValve extends Component {
  static propTypes = {
    waterValve: PropTypes.object.isRequired,
    getWaterValve: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getWaterValve, match } = this.props

    getWaterValve(match.params.id)
  }

  render() {
    const { waterValve } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/water-valves">Water Valves</Anchor>,
      'Edit',
      `${
        waterValve && waterValve.valveSerialId ? waterValve.valveSerialId : ''
      }`,
    ]

    return (
      <section className="EditWaterValve">
        <FormHeader crumbs={crumbs} />
        <EditWaterValveForm
          submitAction={updateWaterValveAction}
          initialValues={waterValve}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditWaterValve
