import produce from 'immer'
import { combineReducers } from 'redux'
import {
  createReportAction,
  getAllReportsAction,
  getPublicReportAction,
  CLEAR_REPORT,
  getAllReportConfigsAction,
} from '../actions/reportsActions'

const INITIAL_REPORT_STATE = {
  siteInfo: {},
  columns: [],
  pages: [],
  isExpired: false,
}

const current = (state = INITIAL_REPORT_STATE, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case createReportAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case getPublicReportAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case getPublicReportAction.FAILURE:
        if (payload.apiError.message === 'report_token_expired') {
          Object.assign(draft, { isExpired: true })
        }

        break
      case CLEAR_REPORT:
        return INITIAL_REPORT_STATE
    }
  })

const bySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllReportsAction.SUCCESS:
        payload.items.forEach(x => {
          draft[x.slug] = x
        })
        break
    }
  })

const visibleSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllReportsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const meta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllReportsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const configsBySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllReportConfigsAction.SUCCESS:
        payload.items.forEach(x => {
          draft[x.slug] = x
        })
        break
    }
  })

const visibleConfigSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllReportConfigsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const reportsReducer = combineReducers({
  current,
  bySlug,
  visibleSlugs,
  meta,
  configsBySlug,
  visibleConfigSlugs,
})

const getCurrentReport = ({ current }) => current
const getReport = (state, slug) => state.bySlug[slug]
const getVisibleReports = state =>
  state.visibleSlugs.map(slug => getReport(state, slug))
const getReportsMeta = state => state.meta
const getReportConfig = (state, slug) => state.configsBySlug[slug]
const getVisibleReportConfigs = state =>
  state.visibleConfigSlugs.map(slug => getReportConfig(state, slug))

export {
  reportsReducer as default,
  getCurrentReport,
  getVisibleReports,
  getReportsMeta,
  getVisibleReportConfigs,
}
