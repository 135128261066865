import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table, Column, Menu, Dropdown, Tooltip, Icon } from './common/Ant'
import { ActionAnchor } from './common/Anchor'
import { toTitleCase } from '../utils/textFormatters'

const SiteUsers = ({ users, getAllUsersBySite, deleteUserFromSite }) => {
  useEffect(() => {
    getAllUsersBySite()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Table
      dataSource={users}
      rowKey="slug"
      pagination={false}
      bordered
      tableLayout="auto"
    >
      <Column title="User" dataIndex="fullName" />
      <Column title="Company" dataIndex="company" />
      <Column
        title="Role"
        dataIndex="role"
        width={200}
        render={text => toTitleCase(text)}
      />
      <Column
        width={100}
        title="Notifications"
        render={(text, record) => {
          const smsNotifications = record.smsNotifications ? (
            <Tooltip title="SMS Notifications Enabled">
              <Icon type="phone" className="green" />
            </Tooltip>
          ) : (
            <Tooltip title="SMS Notifications Disabled">
              <Icon type="phone" className="light-gray" />
            </Tooltip>
          )

          const emailNotifications = record.emailNotifications ? (
            <Tooltip title="Email Notifications Enabled">
              <Icon type="mail" className="green" />
            </Tooltip>
          ) : (
            <Tooltip title="Email Notifications Disabled">
              <Icon type="mail" className="light-gray" />
            </Tooltip>
          )

          const unconfirmed =
            record.confirmedAt === null ? (
              <Tooltip title="Unconfirmed User">
                <Icon type="exclamation-circle-o" className="red" />
              </Tooltip>
            ) : (
              <Tooltip title="Confirmed User">
                <Icon type="check-circle-o" className="green" />
              </Tooltip>
            )

          const offlineNotifications =
            record.systemNotifications && record.gatewaySystemNotifications ? (
              <Tooltip title="Offline Smart Pod and Network Gateway Notifications Active">
                <Icon type="check-circle-o" className="green" />
              </Tooltip>
            ) : record.systemNotifications ||
              record.gatewaySystemNotifications ? (
              <Tooltip
                title={
                  !record.systemNotifications
                    ? 'Offline Smart Pod Notifications Muted'
                    : 'Offline Network Gateway Notifications Muted'
                }
              >
                <Icon type="warning" className="yellow" />
              </Tooltip>
            ) : (
              <Tooltip title="Offline Smart Pod and Network Gateway Notifications Muted">
                <Icon type="warning" className="orange" />
              </Tooltip>
            )

          return (
            <div className="flex justify-between">
              <span style={{ width: 20 }} className="dib">
                {smsNotifications}
              </span>
              <span style={{ width: 20 }} className="dib">
                {emailNotifications}
              </span>
              <span style={{ width: 20 }} className="dib">
                {offlineNotifications}
              </span>
              <span style={{ width: 20 }} className="dib">
                {unconfirmed}
              </span>
            </div>
          )
        }}
      />
      {!!deleteUserFromSite && (
        <Column
          width={100}
          render={(text, record) => {
            const menu = (
              <Menu>
                <Menu.Item key="delete-user">
                  <ActionAnchor onClick={() => deleteUserFromSite(record.id)}>
                    Remove User from Site
                  </ActionAnchor>
                </Menu.Item>
              </Menu>
            )

            return (
              <Dropdown key={record.id} overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link">
                  Actions <Icon type="down" />
                </a>
              </Dropdown>
            )
          }}
        />
      )}
    </Table>
  )
}

SiteUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllUsersBySite: PropTypes.func.isRequired,
  deleteUserFromSite: PropTypes.func,
}

export default SiteUsers
