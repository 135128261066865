import { SOCKET_CONNECT, SOCKET_DISCONNECT } from '../actions/socketActions'

const socketReducer = (state = false, { type }) => {
  switch (type) {
    case SOCKET_CONNECT:
      return true
    case SOCKET_DISCONNECT:
      return false
    default:
      return state
  }
}

const isSocketConnected = state => state

export { socketReducer as default, isSocketConnected }
