import React from 'react'
import PropTypes from 'prop-types'
import SideNavContainer from '../../containers/SideNavContainer'
import TopNavContainer from '../../containers/TopNavContainer'
import { Layout, Content } from '../common/Ant'

const MainContent = ({ children }) => {
  return (
    <Layout>
      <TopNavContainer />
      <Layout style={{ marginTop: 65 }}>
        <SideNavContainer />
        <Layout>
          <Content className="ph2 ph5-ns pt2 pt3-ns pb7 bg-white MainContent min-vh-100">
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

MainContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainContent
