import { fork, takeLatest, put, all, call } from 'redux-saga/effects'
import {
  getAllSiteImuNanoIotAction,
  getImuDataAction,
  getAdminDashboardAction,
  getUserDashboardAction,
} from '../actions/dashboardActions'
import {
  getAllAlertsAction,
  getAllGatewayAlertsAction,
} from '../actions/alertsActions'
import { getSiteAction, getAllSitesAction } from '../actions/sitesActions'
import { getAllBuildingsAction } from '../actions/buildingsActions'
import { getAllFloorsAction } from '../actions/floorsActions'
import { getAllDeploymentsAction } from '../actions/deploymentsActions'
import api from '../services/api'
import { createQueryString } from '../utils/queryParams'
import { hasRole, hasPermission } from '../utils/hasPermission'
import {
  ALERT_TYPES,
  ALERT_STATUS,
  SITE_STATUS,
  DEPLOYMENT_STATUS,
  USER_PERMISSIONS,
  ROLES,
  THRESHOLD_ALERT_TYPES,
} from '../constants'
import { unauthorizedAction } from '../actions/authActions'
import { showErrorMessageAction } from '../actions/uiActions'
import { pickErrorMessage } from '../utils/helpers'

// selectors
const getAccount = state => state.account.profile

// handlers

function* handleGetAdminDashboardSaga() {
  const counts = yield call(api.getDashboardCounts)

  yield put(getAdminDashboardAction.success(counts))

  yield put(
    getAllAlertsAction.request(
      createQueryString({
        perPage: counts.activeAlerts,
        status: ALERT_STATUS.ACTIVE,
        type: ALERT_TYPES.THRESHOLD,
        assetType: hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR)
          ? 'all'
          : 'POD',
        thresholdAlertType: THRESHOLD_ALERT_TYPES.ACTIONABLE,
      })
    )
  )

  const canViewGatewayAlerts = yield call(
    hasPermission,
    USER_PERMISSIONS.VIEW_GATEWAY_ALERTS
  )

  if (canViewGatewayAlerts) {
    yield put(
      getAllGatewayAlertsAction.request(
        createQueryString({
          status: ALERT_STATUS.ACTIVE,
          deployed: 't',
        })
      )
    )
  }

  yield put(
    getAllSitesAction.request(
      createQueryString({
        status: SITE_STATUS.ACTIVE,
        full: 't',
        perPage: 'all',
      })
    )
  )

  const isPillarAdmin = yield call(
    hasRole,
    ROLES.PILLAR_ADMIN,
    ROLES.SUPER_ADMIN
  )

  if (isPillarAdmin) {
    yield put(
      getAllDeploymentsAction.request(
        createQueryString({
          status: [
            DEPLOYMENT_STATUS.CREATED,
            DEPLOYMENT_STATUS.PACKING,
            DEPLOYMENT_STATUS.PACKED,
            DEPLOYMENT_STATUS.SHIPPED,
          ],
          perPage: 'all',
        })
      )
    )
  }
}

function* handleGetUserDashboardSaga({ payload }) {
  yield put(getSiteAction.request({ siteSlug: payload }))
  yield put(getAllBuildingsAction.request({ siteSlug: payload }))
  yield put(getAllFloorsAction.request({ siteSlug: payload }))
}

function* handleGetSiteAllImuNanoIotsSaga({ payload }) {
  try {
    const result = yield call(api.getAllImuNanoIots, payload)
    yield put(getAllSiteImuNanoIotAction.success(result))
  } catch (error) {
    yield put(getAllSiteImuNanoIotAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetImuDataSaga({ payload }) {
  try {
    const result = yield call(api.getImuData, payload)
    yield put(getImuDataAction.success(result))
  } catch (error) {
    yield put(getImuDataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAdminDashboardSaga() {
  yield takeLatest(getAdminDashboardAction.REQUEST, handleGetAdminDashboardSaga)
}

function* watchGetUserDashboardSaga() {
  yield takeLatest(getUserDashboardAction.REQUEST, handleGetUserDashboardSaga)
}

function* watchGetSiteAllImuNanoIotsSaga() {
  yield takeLatest(
    getAllSiteImuNanoIotAction.REQUEST,
    handleGetSiteAllImuNanoIotsSaga
  )
}

function* watchGetImuDataSaga() {
  yield takeLatest(getImuDataAction.REQUEST, handleGetImuDataSaga)
}

function* dashboardSaga() {
  yield all([
    fork(watchGetAdminDashboardSaga),
    fork(watchGetUserDashboardSaga),
    fork(watchGetSiteAllImuNanoIotsSaga),
    fork(watchGetImuDataSaga),
  ])
}

export {
  dashboardSaga as default,
  watchGetAdminDashboardSaga,
  watchGetUserDashboardSaga,
  watchGetSiteAllImuNanoIotsSaga,
  watchGetImuDataSaga,
  handleGetAdminDashboardSaga,
  handleGetUserDashboardSaga,
  handleGetSiteAllImuNanoIotsSaga,
  handleGetImuDataSaga,
  getAccount,
}
