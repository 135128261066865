import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import DeploymentsPage from '../components/pages/DeploymentsPage'
import {
  getAllCommentsAction,
  getAllTagsAction,
  deleteCommentAction,
  createCommentAction,
  updateCommentAction,
} from '../actions/commentsActions'
import { getAllDeploymentsAction } from '../actions/deploymentsActions'
import { getAllSitesAction } from '../actions/sitesActions'
import {
  getVisibleDeployments,
  getDeploymentsMeta,
} from '../reducers/deploymentsReducer'
import { getVisibleSites } from '../reducers/sitesReducer'
import { isDeploymentLoading, isCommentLoading } from '../reducers/uiReducer'
import { getVisibleComments, getVisibleTags } from '../reducers/commentsReducer'
import { getAccountProfile } from '../reducers/accountReducer'

const mapStateToProps = ({ deployments, sites, ui, account, comments }) => ({
  deployments: getVisibleDeployments(deployments),
  sites: getVisibleSites(sites),
  meta: getDeploymentsMeta(deployments),
  isDeploymentLoading: isDeploymentLoading(ui),
  isCommentLoading: isCommentLoading(ui),
  comments: getVisibleComments(comments),
  tags: getVisibleTags(comments),
  accountProfile: getAccountProfile(account),
})

const mapDispatchToProps = dispatch => ({
  getAllDeployments: qs => dispatch(getAllDeploymentsAction.request(qs)),
  getAllSites: qs => dispatch(getAllSitesAction.request(qs)),
  updateQueryParams: location => dispatch(replace(location)),
  getAllTags: qs => dispatch(getAllTagsAction.request(qs)),
  getAllComments: qs => dispatch(getAllCommentsAction.request(qs)),
  deleteComment: comment => dispatch(deleteCommentAction.request(comment)),
  createComment: comment => dispatch(createCommentAction.request(comment)),
  editComment: comment => dispatch(updateCommentAction.request(comment)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentsPage)
