import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import { normalizeDate, formatDateUTC } from '../common/DateSelector'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import { Spin, Steps, Step, Table, Column } from '../common/Ant'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { toTitleCase } from '../../utils/textFormatters'
import { PURCHASE_STATUS, SIZES } from '../../constants'
import { required, isNumeric, isAlphaNumeric } from '../../utils/validators'
import { dateFormatter } from '../../utils/date'
import { H3, H4 } from '../common/Headers'
import Block from '../common/Block'
import { ActionAnchor } from '../common/Anchor'
import actions from 'redux-form/lib/actions'
import { connect } from 'react-redux'

const { change } = actions
let allAssetLocations = []
let allParts = []
let allManufacturers = []
let allVendors = []

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, value) =>
    dispatch(change('EditPurchaseForm', field, value)),
})

class AssetLocation extends Component {
  static propTypes = {
    changeFormField: PropTypes.func.isRequired,
    assetLocation: PropTypes.string.isRequired,
  }

  renderFields = assetLocation => {
    return (
      <div className="flex-ns">
        <Field
          name={`${assetLocation}.pillarId`}
          type="text"
          component={SelectContainer}
          label={`Asset Location Name`}
          validate={[required, isAlphaNumeric]}
          className="mr3-ns w-50-ns"
          filterable
        >
          {allAssetLocations.map(x => (
            <Option value={x.pillarId} key={x.pillarId}>
              {x.pillarId}
            </Option>
          ))}
        </Field>
        <Field
          name={`${assetLocation}.partQuantityAdded`}
          type="number"
          component={InputContainer}
          label="Part Quantity Added"
          validate={[required, isNumeric]}
          className="w-50-ns"
        />
      </div>
    )
  }

  render() {
    const { assetLocation } = this.props

    return <div>{this.renderFields(assetLocation)}</div>
  }
}

const ConnectedAssetLocation = connect(mapDispatchToProps)(AssetLocation)

const AssetLocations = ({ fields, meta: { error } }) => (
  <div className="mb3">
    <H3>Asset Locations </H3>
    {fields.map((assetLocation, index) => {
      return (
        <div key={index}>
          {index > 0 ? <Divider /> : null}
          <H4 inline>Asset Location {index + 1}</H4>
          <ActionAnchor onClick={e => fields.remove(index)}>
            Remove
          </ActionAnchor>
          <ConnectedAssetLocation assetLocation={assetLocation} />
        </div>
      )
    })}
    <ActionAnchor onClick={e => fields.push()} button>
      Add Asset Location
    </ActionAnchor>
  </div>
)

AssetLocations.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object,
}

class BomPart extends Component {
  static propTypes = {
    changeFormField: PropTypes.func.isRequired,
    bomPart: PropTypes.string.isRequired,
  }

  renderFields = bomPart => {
    return (
      <div className="ba b--light-gray">
        <div className="flex-ns mh3 mv3">
          <Field
            name={`${bomPart}.pillarId`}
            component={SelectContainer}
            label="Pillar P/N"
            placeholder="Select pillar p/n"
            validate={required}
            className="mr3-ns w-40-ns"
            filterable
          >
            {[{ pillarId: '- Not Available -' }, ...allParts].map(x => (
              <Option value={x.pillarId} key={x.pillarId}>
                {x.pillarId}
              </Option>
            ))}
          </Field>
          <Field
            name={`${bomPart}.manufacturerPn`}
            component={SelectContainer}
            label="Manufacturer P/N"
            placeholder="Select manufacturer p/n"
            validate={required}
            className="mr3-ns w-30-ns"
            filterable
          >
            {[{ manufacturerPn: '- Not Available -' }, ...allManufacturers].map(
              x => (
                <Option value={x.manufacturerPn} key={x.manufacturerPn}>
                  {x.manufacturerPn}
                </Option>
              )
            )}
          </Field>
          <Field
            name={`${bomPart}.vendorPn`}
            component={SelectContainer}
            label="Vendor P/N"
            placeholder="Select vendor p/n"
            validate={required}
            className="w-30-ns"
            filterable
          >
            {[{ vendorPn: '- Not Available -' }, ...allVendors].map(x => (
              <Option value={x.vendorPn} key={x.vendorPn}>
                {x.vendorPn}
              </Option>
            ))}
          </Field>
        </div>
        <div className="flex-ns mh3 mv3">
          <Field
            name={`${bomPart}.cost`}
            type="number"
            component={InputContainer}
            label="Parts Cost"
            validate={[required, isNumeric]}
            className="mr3-ns w-40-ns"
          />
          <Field
            name={`${bomPart}.quantityRequested`}
            type="number"
            component={InputContainer}
            label="Quantity Requested"
            validate={[required, isNumeric]}
            className="mr3-ns w-30-ns"
          />
          <Field
            name={`${bomPart}.quantityDelivered`}
            type="number"
            component={InputContainer}
            label="Quantity Delivered"
            validate={isNumeric}
            className="w-30-ns"
          />
        </div>
        <Divider />
        <div className="ml3 mr3">
          <FieldArray
            name={`${bomPart}.assetLocations`}
            component={AssetLocations}
          />
        </div>
      </div>
    )
  }

  render() {
    const { bomPart } = this.props
    return <div>{this.renderFields(bomPart)}</div>
  }
}

const ConnectedBomPart = connect(mapDispatchToProps)(BomPart)

const BomParts = ({ fields, meta: { error } }) => (
  <div className="mb3">
    <H3>BOM Parts </H3>
    {fields.map((bomPart, index) => {
      return (
        <div key={index} className="mt3">
          <H4 inline>BOM Part {index + 1}</H4>
          <ActionAnchor onClick={e => fields.remove(index)}>
            Remove
          </ActionAnchor>
          <ConnectedBomPart bomPart={bomPart} />
        </div>
      )
    })}
    <ActionAnchor className="mt3" onClick={e => fields.push()} button>
      Add BOM Part
    </ActionAnchor>
  </div>
)

BomParts.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object,
}

class EditPurchaseForm extends Component {
  static propTypes = {
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    parts: PropTypes.arrayOf(PropTypes.object).isRequired,
    submitAction: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    isPurchaseLoading: PropTypes.bool,
    touch: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    anyTouched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      current: 0,
      reviewInputs: {},
    }

    this.inputs = {}
  }

  handleNext = event => {
    const { valid, anyTouched, touch } = this.props
    const { current } = this.state

    if (anyTouched && valid) {
      let reviewInputs = {}
      Object.keys(this.inputs).map(
        key => (reviewInputs[key] = this.inputs[key].value)
      )
      this.setState({ reviewInputs })
      const cur = current + 1
      this.setState({ current: cur })
    } else {
      const fieldNames = Object.keys(this.inputs).map(
        x => this.inputs[x].props.name
      )
      touch(...fieldNames)
    }

    event.preventDefault()
  }

  handlePrev = event => {
    const current = this.state.current - 1
    this.setState({ current })

    event.preventDefault()
  }

  render() {
    const {
      parts,
      assetLocations,
      submitAction,
      handleSubmit,
      submitting,
      isPurchaseLoading,
      error,
    } = this.props

    const { current, reviewInputs } = this.state

    if (parts && parts.length > 0 && allParts.length !== parts.length) {
      allParts = parts

      for (let index = 0; index < allParts.length; index++) {
        // add all manufacturers
        const manfs = allParts[index].manufacturers
        for (let manfIndex = 0; manfIndex < manfs.length; manfIndex++) {
          allManufacturers.push(manfs[manfIndex])
        }

        // add all vendors
        const vends = allParts[index].vendors
        for (let vendIndex = 0; vendIndex < manfs.length; vendIndex++) {
          allVendors.push(vends[vendIndex])
        }
      }
    }
    if (assetLocations && allAssetLocations.length !== assetLocations.length) {
      allAssetLocations = assetLocations.length > 0 ? assetLocations : []
    }

    const submit = handleSubmit(submitAction)

    let steps = [
      {
        title: 'Add Purchase Details',
        content: (
          <div>
            <H3>Purchase Info </H3>
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="name"
                type="text"
                component={InputContainer}
                label="Purchase ID"
                validate={required}
                className="mr3-ns w-40-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="status"
                component={SelectContainer}
                label="Status"
                placeholder="Select purchase status"
                validate={required}
                className="mr3-ns w-40-ns"
                filterable
              >
                {Object.keys(PURCHASE_STATUS).map(x => (
                  <Option value={PURCHASE_STATUS[x]} key={x}>
                    {toTitleCase(PURCHASE_STATUS[x])}
                  </Option>
                ))}
              </Field>
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="cost"
                type="number"
                component={InputContainer}
                label="Total Cost"
                validate={[required, isNumeric]}
                className="w-30-ns"
              />
            </div>
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="description"
                type="text"
                component={InputContainer}
                label="Description"
                className="mr3-ns w-60-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="dateOrdered"
                type="date"
                component={DateSelectorContainer}
                label="Date Ordered"
                validate={required}
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-20-ns mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="dateDelivered"
                type="date"
                component={DateSelectorContainer}
                label="Date Delivered"
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-20-ns"
              />
            </div>
            <Divider />
            <FieldArray
              ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
              name="bomParts"
              component={BomParts}
            />
          </div>
        ),
      },
    ]

    steps.push({
      title: `Review and Submit`,
      content: reviewInputs ? (
        <div>
          <div className="flex flex-wrap">
            <Block label="Purchase ID">{reviewInputs['name']}</Block>
            <Block label="Purchase Status">
              {reviewInputs['status'] && toTitleCase(reviewInputs['status'])}
            </Block>
            <Block label="Number of BOMs">
              {reviewInputs['bomParts'] ? reviewInputs['bomParts'].length : 0}
            </Block>
            <Block label="Description">{reviewInputs['description']}</Block>
            <Block label="Purchase Cost">{reviewInputs['cost']}</Block>
            <Block label="Date Ordered">
              {reviewInputs['dateOrdered'] &&
                dateFormatter(reviewInputs['dateOrdered'])}
            </Block>
            <Block label="Date Delivered">
              {reviewInputs['dateDelivered'] &&
                dateFormatter(reviewInputs['dateDelivered'])}
            </Block>
          </div>
          <Divider />
          <H3>BOM Parts</H3>
          {reviewInputs['bomParts'] && reviewInputs['bomParts'].length
            ? reviewInputs['bomParts'].map((bomPart, index) => (
                <div key={index} className="ba b--light-gray">
                  <div className="mh3 mv3">
                    <H4>BOM Part {index + 1}</H4>
                    <div className="flex flex-wrap">
                      <Block label="Pillar P/N">{bomPart['pillarId']}</Block>
                      <Block label="Manufacturer P/N">
                        {bomPart['manufacturerPn']}
                      </Block>
                      <Block label="Vendor P/N">{bomPart['vendorPn']}</Block>
                      <Block label="Pillar P/N">{bomPart['pillarId']}</Block>
                      <Block label="Part cost">{bomPart['cost']}</Block>
                      <Block label="Quantity Requested">
                        {bomPart['quantityRequested']}
                      </Block>
                      <Block label="Quantity Delivered">
                        {bomPart['quantityDelivered']}
                      </Block>
                    </div>
                    <H4>Asset Locations</H4>
                    {bomPart['assetLocations'] &&
                    bomPart['assetLocations'].length ? (
                      <Table
                        dataSource={bomPart['assetLocations']}
                        rowKey="pillarId"
                        scroll={{ x: 600 }}
                        bordered
                        tableLayout="auto"
                        pagination={false}
                      >
                        <Column
                          title="Asset Location Name"
                          dataIndex="pillarId"
                          width={300}
                          render={text => (text ? text : '--')}
                        />
                        <Column
                          title="Part Quantity Added"
                          dataIndex="partQuantityAdded"
                          width={300}
                          render={text => (text ? text : '--')}
                        />
                      </Table>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              ))
            : '--'}
        </div>
      ) : (
        'No inputs yet'
      ),
    })

    return isPurchaseLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EditPurchaseForm">
        <Form onSubmit={submit}>
          <Steps current={current} className="mb3">
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
            {steps[current].content}
          </div>
          <div className="mb3 flex justify-between">
            <Button
              disabled={current === 0}
              size={SIZES.SMALL}
              onClick={this.handlePrev}
              text="Previous"
            />
            <Button
              disabled={current >= steps.length - 1}
              size={SIZES.SMALL}
              onClick={this.handleNext}
              text="Next"
            />
          </div>
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/inventory/all/purchases" />
            <Button
              disabled={current !== steps.length - 1 || !this.props.valid}
              text="Submit"
              type="submit"
              submitting={submitting}
            />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditPurchaseForm' })(EditPurchaseForm)
