import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AllSites from './AllSites'
import AllUsers from './AllUsers'
import Logo from './common/Logo'
import { Spin } from './common/Ant'
import Divider from './common/Divider'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import Block from './common/Block'
import { toTitleCase } from '../utils/textFormatters'

class Company extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    getCompany: PropTypes.func.isRequired,
    isCompanyLoading: PropTypes.bool.isRequired,
    windowWidth: PropTypes.number.isRequired,
    accountProfile: PropTypes.object.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    this.props.getCompany(this.props.match.params.id)
  }

  render() {
    const {
      company,
      match,
      isCompanyLoading,
      windowWidth,
      accountProfile,
    } = this.props

    return isCompanyLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Company">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/companies">Companies</Anchor>
              </Crumb>
              <Crumb>{company.name}</Crumb>
            </Bread>
          </div>
          <div className="flex justify-center mb3">
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          </div>
          <div className="flex justify-center">
            <Logo
              companyName={company.name}
              companyLogo={company.logoFileName}
            />
          </div>
          <div className="flex flex-wrap">
            <Block label="Company Type">
              {company.companyType && toTitleCase(company.companyType)}
            </Block>
          </div>
        </div>
        <Divider />
        <AllSites
          sites={company.sites}
          accountProfile={accountProfile}
          windowWidth={windowWidth}
          isRenderingDashboardView
        />
        <AllUsers
          users={company.users}
          windowWidth={windowWidth}
          isRenderingDashboardView
        />
      </section>
    )
  }
}

export default Company
