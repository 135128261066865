import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import AllPodAlerts from './AllPodAlerts'
import AllGatewayAlerts from './AllGatewayAlerts'
import AllFlowAlerts from './AllFlowAlerts'
import AllActionReports from './AllActionReports'
import { Bread, Crumb } from './common/Bread'
import { Tabs, TabPane } from './common/Ant'
import { getPathSnippets } from '../utils/helpers'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { USER_PERMISSIONS, ROLES } from '../constants'

class AllAlerts extends Component {
  static propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getLocationAlertHistory: PropTypes.func,
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    floorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    locationSiteSlug: PropTypes.string,
    hideAlert: PropTypes.func,
    acknowledgeAlert: PropTypes.func,
    resolveAlert: PropTypes.func,
    unhideAlert: PropTypes.func,
    closeAlert: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    meta: PropTypes.object,
    gatewayAlertsMeta: PropTypes.object,
    getAllAlerts: PropTypes.func,
    isRenderingDashboardView: PropTypes.bool,
    isLocationAlertHistory: PropTypes.bool,
    sectionTitle: PropTypes.string,
    getAllSites: PropTypes.func,
    getAllThresholds: PropTypes.func,
    updateQueryParams: PropTypes.func,
    sites: PropTypes.arrayOf(PropTypes.object),
    thresholds: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    gatewayAlerts: PropTypes.arrayOf(PropTypes.object).isRequired,
    gatewaysMeta: PropTypes.object,
    getAllGatewayAlerts: PropTypes.func.isRequired,
    closeGatewayAlert: PropTypes.func.isRequired,
    isGatewayAlertLoading: PropTypes.bool,
    getAllCompanies: PropTypes.func.isRequired,
    getAllActionReports: PropTypes.func.isRequired,
    actionReports: PropTypes.arrayOf(PropTypes.object).isRequired,
    actionReportsMeta: PropTypes.object.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    generateAlertExport: PropTypes.func.isRequired,
    batchUpdateAlerts: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object),
  }

  state = {
    activeTab: undefined,
  }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.handleTabSelection(lastSnippet)
  }

  handleTabSelection = entity => {
    const {
      updateQueryParams,
      match: { url },
    } = this.props
    const { activeTab } = this.state

    this.setState({ activeTab: entity })
    updateQueryParams({
      pathname: `${url}/${entity}`,
      search: !activeTab || entity === activeTab ? global.location.search : '',
    })
  }

  render() {
    const {
      accountProfile,
      windowWidth,
      match: { url },
      alerts,
      getAllAlerts,
      hideAlert,
      unhideAlert,
      closeAlert,
      acknowledgeAlert,
      resolveAlert,
      meta,
      gatewayAlertsMeta,
      isLoading,
      getAllSites,
      sites,
      getAllThresholds,
      thresholds,
      gatewayAlerts,
      getAllGatewayAlerts,
      updateQueryParams,
      closeGatewayAlert,
      getAllActionReports,
      getAllCompanies,
      actionReports,
      actionReportsMeta,
      companies,
      generateAlertExport,
      batchUpdateAlerts,
      getAllTags,
      tags,
    } = this.props

    return (
      <div className="AllAlerts">
        <Helmet>
          <title>Alerts</title>
        </Helmet>
        <div className="mb3">
          <Bread>
            <Crumb>Alerts</Crumb>
          </Bread>
        </div>
        <Switch>
          <Route
            path={`${url}/`}
            exact
            render={props => <Redirect to={`${url}/pods`} />}
          />
          <Tabs
            defaultActiveKey="pods"
            activeKey={this.state.activeTab}
            onTabClick={this.handleTabSelection}
            type="card"
          >
            {hasPermission(USER_PERMISSIONS.VIEW_ALERTS) && (
              <TabPane tab="Smart Pods" key="pods">
                <Route
                  path={`${url}/pods`}
                  exact
                  render={props => (
                    <AllPodAlerts
                      alerts={alerts}
                      getAllAlerts={getAllAlerts}
                      hideAlert={hideAlert}
                      unhideAlert={unhideAlert}
                      closeAlert={closeAlert}
                      acknowledgeAlert={acknowledgeAlert}
                      resolveAlert={resolveAlert}
                      meta={meta}
                      isLoading={isLoading}
                      accountProfile={accountProfile}
                      windowWidth={windowWidth}
                      getAllSites={getAllSites}
                      sites={sites}
                      getAllThresholds={getAllThresholds}
                      thresholds={thresholds}
                      updateQueryParams={updateQueryParams}
                      generateAlertExport={generateAlertExport}
                      batchUpdateAlerts={batchUpdateAlerts}
                      getAllTags={getAllTags}
                      tags={tags}
                      url={url}
                      {...props}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.VIEW_GATEWAY_ALERTS) && (
              <TabPane tab="Network Gateways" key="gateways">
                <Route
                  path={`${url}/gateways`}
                  exact
                  render={props => (
                    <AllGatewayAlerts
                      gatewayAlerts={gatewayAlerts}
                      meta={gatewayAlertsMeta}
                      isGatewayAlertLoading={isLoading}
                      closeGatewayAlert={closeGatewayAlert}
                      getAllGatewayAlerts={getAllGatewayAlerts}
                      windowWidth={windowWidth}
                      updateQueryParams={updateQueryParams}
                      getAllSites={getAllSites}
                      sites={sites}
                      shouldFetchAllGatewayAlerts={true}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
              <TabPane tab="Water Monitors" key="flow-monitors">
                <Route
                  path={`${url}/flow-monitors`}
                  exact
                  render={props => (
                    <AllFlowAlerts
                      alerts={alerts}
                      getAllAlerts={getAllAlerts}
                      hideAlert={hideAlert}
                      unhideAlert={unhideAlert}
                      closeAlert={closeAlert}
                      acknowledgeAlert={acknowledgeAlert}
                      resolveAlert={resolveAlert}
                      meta={meta}
                      isLoading={isLoading}
                      accountProfile={accountProfile}
                      windowWidth={windowWidth}
                      getAllSites={getAllSites}
                      sites={sites}
                      getAllThresholds={getAllThresholds}
                      thresholds={thresholds}
                      updateQueryParams={updateQueryParams}
                      getAllTags={getAllTags}
                      tags={tags}
                      url={url}
                      {...props}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
              <TabPane tab="Action Reports" key="action-reports">
                <Route
                  path={`${url}/action-reports`}
                  exact
                  render={props => (
                    <AllActionReports
                      actionReports={actionReports}
                      sites={sites}
                      companies={companies}
                      getAllActionReports={getAllActionReports}
                      getAllSites={getAllSites}
                      getAllCompanies={getAllCompanies}
                      meta={actionReportsMeta}
                      isLoading={isLoading}
                      windowWidth={windowWidth}
                      updateQueryParams={updateQueryParams}
                      {...props}
                    />
                  )}
                />
              </TabPane>
            )}
          </Tabs>
        </Switch>
      </div>
    )
  }
}

export default AllAlerts
