import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditThresholdForm from '../components/forms/EditThresholdForm'
import { H2 } from './common/Headers'
import { updateThresholdAction } from '../actions/thresholdsActions'
import { createQueryString } from '../utils/queryParams'

class EditThreshold extends Component {
  static propTypes = {
    threshold: PropTypes.object.isRequired,
    getThreshold: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllSites: PropTypes.func.isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getThreshold, getAllSites, getAllCompanies } = this.props
    const qs = createQueryString({ perPage: 'all' })

    getThreshold(this.props.match.params.id)
    getAllSites(qs)
    getAllCompanies(qs)
  }

  render() {
    const { threshold, companies, sites } = this.props

    if (!threshold.visibleToUserRoles) {
      threshold.visibleToUserRoles = []
    }

    return (
      <section className="EditThreshold">
        <H2>Edit Threshold</H2>
        <EditThresholdForm
          submitAction={updateThresholdAction}
          initialValues={threshold}
          companies={companies}
          sites={sites}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditThreshold
