import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { dateTimeFormatter } from '../utils/date'
import { getSensorReading } from '../utils/sensorData'
import { toTitleCase } from '../utils/textFormatters'
import { SMART_SENSOR_TYPES, VISIBLE_READINGS } from '../constants'
import iconCarbonMonoxide from '../assets/icon-carbon-dark.svg'
import iconHumidity from '../assets/icon-humidity-dark.svg'
import iconLight from '../assets/icon-light-dark.svg'
import iconDust from '../assets/icon-particulates-dark.svg'
import iconPressure from '../assets/icon-pressure-dark.svg'
import iconSignal from '../assets/icon-signal-dark.svg'
import iconTemperature from '../assets/icon-temperature-dark.svg'
import iconVOC from '../assets/icon-voc-dark.svg'

const FloorSummary = ({ summary }) => {
  const hasSummary = !!summary && summary.time
  const prefix = cx('flex justify-center b tc', { mb2: hasSummary })

  return (
    <div className="FloorSummary br bl bb b--gray bg-near-white br2 br--bottom pa2">
      <div className={prefix}>
        Mean Floor Readings &mdash;{' '}
        {hasSummary
          ? dateTimeFormatter(summary.time, true)
          : 'No readings in past hour'}
      </div>
      {hasSummary && (
        <ul className="pl0 mb0 list flex-l justify-between-l">
          {VISIBLE_READINGS.map((reading, i) => {
            const keyName = SMART_SENSOR_TYPES[reading]
            let iconSvg

            switch (keyName) {
              case 'carbonMonoxide':
                iconSvg = iconCarbonMonoxide
                break
              case 'hum':
                iconSvg = iconHumidity
                break
              case 'ambientLight':
              case 'ambientLightFc':
                iconSvg = iconLight
                break
              case 'dust':
                iconSvg = iconDust
                break
              case 'pres':
                iconSvg = iconPressure
                break
              case 'signalRssi':
                iconSvg = iconSignal
                break
              case 'temp':
                iconSvg = iconTemperature
                break
              case 'voc':
                iconSvg = iconVOC
                break
              default:
                break
            }

            const avg = summary[keyName]
            const { name, unit, value } = getSensorReading(keyName, avg)

            return (
              <li className="flex justify-between mb1 mb0-l" key={reading}>
                <div className="flex items-center">
                  {iconSvg && (
                    <img src={iconSvg} alt={reading} className="w1 h1 mr2" />
                  )}
                  <div className="b mr2">{`${toTitleCase(name)}:`}</div>
                </div>
                {avg ? `${value}${unit}` : '--'}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

FloorSummary.propTypes = {
  summary: PropTypes.object,
}

export default FloorSummary
