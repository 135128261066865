import React from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './Bread'
import Divider from './Divider'

const FormHeader = ({ crumbs }) => {
  return (
    <div className="FormHeader">
      <div className="mb3">
        <div className="mb2">
          <Bread>{crumbs.map((x, i) => <Crumb key={i}>{x}</Crumb>)}</Bread>
        </div>
      </div>
      <Divider />
    </div>
  )
}

FormHeader.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default FormHeader
