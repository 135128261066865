import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import DeveloperAccessToken from '../DeveloperAccessToken'
import PageWidth from '../PageWidth'
import { hasRole } from '../../utils/hasPermission'
import { ROLES } from '../../constants'

class DeveloperPage extends Component {
  static propTypes = {
    getAccessToken: PropTypes.func.isRequired,
    generateAccessToken: PropTypes.func.isRequired,
    accessToken: PropTypes.object.isRequired,
    userSlug: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  }

  render() {
    const {
      getAccessToken,
      generateAccessToken,
      accessToken,
      userSlug,
      isLoading,
      match: { url },
    } = this.props

    return (
      <MainContent>
        <div className="DeveloperPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Developer</title>
                    </Helmet>
                    <DeveloperAccessToken
                      getAccessToken={getAccessToken}
                      generateAccessToken={generateAccessToken}
                      accessToken={accessToken}
                      userSlug={userSlug}
                      isLoading={isLoading}
                      {...props}
                    />
                    {!hasRole(ROLES.HILTI_USER) &&
                      !hasRole(ROLES.HARTFORD_FLOW_USER) && (
                        <a
                          href="/docs"
                          target="_self"
                          className="link dark-blue"
                        >
                          API Documentation
                        </a>
                      )}
                  </Fragment>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default DeveloperPage
