import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllAlertsAction,
  getAllGatewayAlertsAction,
  getAlertAction,
  hideAlertAction,
  unhideAlertAction,
  closeAlertAction,
  acknowledgeAlertAction,
  resolveAlertAction,
  getAlertAuditLogAction,
  getGatewayAlertAction,
  getLocationAlertHistoryAction,
  getLocationUptimeDataAction,
  getAllActionReportsAction,
  batchUpdateAlertsAction,
  CLEAR_ALERT,
} from '../actions/alertsActions'
import { ALERT_TYPES, ALERT_STATES } from '../constants'
import { unique } from '../utils/helpers'
import { currentUptimeData } from './utils'

const byId = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getLocationAlertHistoryAction.SUCCESS:
      case getAllAlertsAction.SUCCESS:
        payload.items.forEach(alert => {
          draft[alert.id] = alert
        })
        break
      case hideAlertAction.SUCCESS:
      case unhideAlertAction.SUCCESS:
      case closeAlertAction.SUCCESS:
        draft[payload.id] = payload
        break
      case batchUpdateAlertsAction.SUCCESS:
        payload.forEach(alert => {
          draft[alert.id] = alert
        })
        break
    }
  })

const byGatewayAlertId = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllGatewayAlertsAction.SUCCESS:
        payload.items.forEach(gatewayAlert => {
          draft[gatewayAlert.id] = gatewayAlert
        })
        break
    }
  })

const visibleIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllAlertsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(alert => alert.id))
        break
    }
  })

const visibleIdsByLocation = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getLocationAlertHistoryAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(alert => alert.id))
        break
    }
  })

const visibleThresholdAlertIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllAlertsAction.SUCCESS:
        const nextDraft = [
          ...draft,
          ...payload.items
            .filter(alert => alert.alertType === ALERT_TYPES.THRESHOLD)
            .map(alert => alert.id),
        ]

        return nextDraft.filter(unique)
    }
  })

const visibleSystemAlertIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllAlertsAction.SUCCESS:
        const nextDraft = [
          ...draft,
          ...payload.items
            .filter(alert => alert.alertType === ALERT_TYPES.SYSTEM)
            .map(alert => alert.id),
        ]

        return nextDraft.filter(unique)
    }
  })

const visibleGatewayAlertIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllGatewayAlertsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...payload.items.map(gatewayAlert => gatewayAlert.id)
        )
        break
    }
  })

const meta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllAlertsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
      case getLocationAlertHistoryAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const gatewayAlertsMeta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllGatewayAlertsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const currentGatewayAlert = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getGatewayAlertAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const CURRENT_INITIAL_STATE = { threshold: { rulesJson: [] } }

const current = (state = CURRENT_INITIAL_STATE, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAlertAction.SUCCESS:
      case hideAlertAction.SUCCESS:
      case unhideAlertAction.SUCCESS:
      case closeAlertAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case CLEAR_ALERT:
        return CURRENT_INITIAL_STATE
      case acknowledgeAlertAction.SUCCESS:
        draft.alertState = ALERT_STATES.ACKNOWLEDGED
        break
      case resolveAlertAction.SUCCESS:
        draft.alertState = ALERT_STATES.RESOLVED
        break
    }
  })

const alertAuditLog = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAlertAuditLogAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload)
        break
    }
  })

const currentLocationUptimeData = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getLocationUptimeDataAction.SUCCESS:
        const chartData = currentUptimeData(action)
        Object.assign(draft, chartData)
        break
    }
  })

const visibleActionReportSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllActionReportsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const byActionReportSlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllActionReportsAction.SUCCESS:
        payload.items.forEach(x => {
          const assignedUser =
            x.assignedUsers.length > 0 ? x.assignedUsers[0] : {}
          const responsibleUser = x.users.length > 0 ? x.users[0] : {}

          draft[x.slug] = { ...x, assignedUser, responsibleUser }
        })
        break
    }
  })

const actionReportsMeta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllActionReportsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const alertsReducer = combineReducers({
  byId,
  byGatewayAlertId,
  visibleIds,
  visibleThresholdAlertIds,
  visibleSystemAlertIds,
  visibleGatewayAlertIds,
  currentGatewayAlert,
  currentLocationUptimeData,
  current,
  gatewayAlertsMeta,
  alertAuditLog,
  visibleIdsByLocation,
  meta,
  visibleActionReportSlugs,
  byActionReportSlug,
  actionReportsMeta,
})

const getAlertById = (state, id) => state.byId[id]
const getGatewayAlertById = (state, id) => state.byGatewayAlertId[id]
const getCurrentGatewayAlert = state => state.currentGatewayAlert
const getCurrentAlert = state => state.current
const getCurrentAlertAuditLog = state => state.alertAuditLog
const getVisibleAlerts = state =>
  state.visibleIds.map(id => getAlertById(state, id))
const getVisibleAlertsByLocation = state =>
  state.visibleIdsByLocation.map(id => getAlertById(state, id))
const getAlertsMeta = state => state.meta
const getGatewayAlertsMeta = state => state.gatewayAlertsMeta
const getVisibleThresholdAlertsById = state =>
  state.visibleThresholdAlertIds.map(id => getAlertById(state, id))
const getVisibleSystemAlertsById = state =>
  state.visibleSystemAlertIds.map(id => getAlertById(state, id))
const getVisibleGatewayAlertsById = state =>
  state.visibleGatewayAlertIds.map(id => getGatewayAlertById(state, id))
const hasActiveAlerts = state =>
  getVisibleAlerts(state)
    .map(x => x.alertActive)
    .indexOf(true) !== -1
const getCurrentLocationUptimeData = state => state.currentLocationUptimeData
const getVisibleActionReports = state =>
  state.visibleActionReportSlugs.map(slug => getActionReportBySlug(state, slug))
const getActionReportBySlug = (state, slug) => state.byActionReportSlug[slug]
const getActionReportsMeta = state => state.actionReportsMeta

export {
  alertsReducer as default,
  getCurrentAlert,
  getVisibleAlerts,
  getGatewayAlertsMeta,
  getAlertsMeta,
  getVisibleThresholdAlertsById,
  getVisibleSystemAlertsById,
  getVisibleGatewayAlertsById,
  hasActiveAlerts,
  getCurrentAlertAuditLog,
  getCurrentGatewayAlert,
  getCurrentLocationUptimeData,
  getVisibleAlertsByLocation,
  getVisibleActionReports,
  getActionReportsMeta,
}
