import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Spin } from './common/Ant'
import { Anchor } from './common/Anchor'
import Select, { Option } from './common/Select'
import { dateTimeFormatter } from '../utils/date'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { configurePagination } from '../utils/helpers'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'

const AllActionReports = ({
  actionReports,
  sites,
  companies,
  getAllActionReports,
  getAllSites,
  getAllCompanies,
  meta,
  isLoading,
  windowWidth,
  updateQueryParams,
}) => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [sortedInfo, setSortedInfo] = useState({})
  const [companySlug, setCompanySlug] = useState(undefined)
  const [siteSlug, setSiteSlug] = useState(undefined)

  useEffect(() => {
    const { page, perPage, siteSlug, companySlug } = parseQueryParams(
      global.location
    )

    const nextPage = page ? parseInt(page, 10) : DEFAULT_PAGE
    const nextPerPage = perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE

    getAllActionReports(
      createQueryString({
        page: nextPage,
        perPage: nextPerPage,
        siteSlug,
        companySlug,
      })
    )
    getAllSites(createQueryString({ perPage: 'all' }))
    getAllCompanies(createQueryString({ perPage: 'all' }))

    setPage(nextPage)
    setPerPage(nextPerPage)
    setSiteSlug(siteSlug)
    setCompanySlug(companySlug)
  }, [getAllActionReports, getAllSites, getAllCompanies])

  useEffect(() => {
    setData(actionReports)
  }, [data, actionReports])

  const handleChange = (pagination, filters, sorter) => {
    if (page !== pagination.current) {
      const query = { page: pagination.current, perPage }
      const qs = createQueryString(query)

      getAllActionReports(qs)
      updateQueryParams({
        search: qs,
      })
    }

    setPage(pagination.current)
    setSortedInfo(sorter)
  }

  const handleFilterChange = keyName => value => {
    const query = { page, perPage, siteSlug, companySlug }

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      setPage(DEFAULT_PAGE)
    }

    const qs = createQueryString({ ...query, [keyName]: value })

    getAllActionReports(qs)
    updateQueryParams({
      search: qs,
    })

    switch (keyName) {
      case 'perPage':
        setPerPage(value)
        break
      case 'companySlug':
        setCompanySlug(value)
        break
      case 'siteSlug':
        setSiteSlug(value)
        break
    }
  }

  return (
    <section className="mb5 AllActionReports">
      <div className="flex-l items-center">
        <Select
          className="w-100 w-50-l mr3-l"
          placeholder="Company"
          mode="multiple"
          input={{
            value: companySlug,
            onChange: handleFilterChange('companySlug'),
          }}
          filterable
        >
          {companies.map(x => (
            <Option value={x.slug} key={x.slug}>
              {x.name}
            </Option>
          ))}
        </Select>
        <Select
          className="w-100 w-50-l"
          placeholder="Site"
          mode="multiple"
          input={{
            value: siteSlug,
            onChange: handleFilterChange('siteSlug'),
          }}
          filterable
        >
          {sites.map(x => (
            <Option value={x.slug} key={x.slug}>
              {x.name}
            </Option>
          ))}
        </Select>
      </div>
      {isLoading ? (
        <Spin size="large" className="w-100 center mv5" />
      ) : (
        <Table
          dataSource={data}
          onChange={handleChange}
          rowKey="id"
          bordered
          pagination={configurePagination({
            perPage,
            onShowSizeChange: (page, perPage) =>
              this.handleFilterChange('perPage')(perPage),
            ...meta,
          })}
          tableLayout="auto"
          scroll={{ x: 850 }}
        >
          <Column
            title="Site"
            dataIndex="siteName"
            sorter={sortBy('siteName')}
            sortOrder={sortedInfo.field === 'siteName' && sortedInfo.order}
            width={200}
            render={(text, record) => (
              <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
            )}
          />
          <Column
            title="Alert"
            dataIndex="alertThresholdName"
            sorter={sortBy('alertThresholdName')}
            sortOrder={
              sortedInfo.field === 'alertThresholdName' && sortedInfo.order
            }
            width={200}
            render={(text, record) => (
              <Anchor to={`/alerts/pods/${record.alertSlug}`}>{text}</Anchor>
            )}
          />
          <Column
            title="Assigned"
            dataIndex="assignedUser"
            sorter={sortBy('assignedUser')}
            sortOrder={sortedInfo.field === 'assignedUser' && sortedInfo.order}
            width={200}
            render={text =>
              text.firstName ? `${text.firstName} ${text.lastName}` : '--'
            }
          />
          <Column
            title="Responsible"
            dataIndex="responsibleUser"
            sorter={sortBy('responsibleUser')}
            sortOrder={
              sortedInfo.field === 'responsibleUser' && sortedInfo.order
            }
            width={200}
            render={text =>
              text.firstName ? `${text.firstName} ${text.lastName}` : '--'
            }
          />
          <Column
            title="Last Updated"
            dataIndex="updatedAt"
            sorter={sortBy('updatedAt')}
            sortOrder={sortedInfo.field === 'updatedAt' && sortedInfo.order}
            width={200}
            render={text => (text ? dateTimeFormatter(text) : '--')}
          />
        </Table>
      )}
    </section>
  )
}

AllActionReports.propTypes = {
  actionReports: PropTypes.arrayOf(PropTypes.object).isRequired,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllActionReports: PropTypes.func.isRequired,
  getAllSites: PropTypes.func.isRequired,
  getAllCompanies: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateQueryParams: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
}

export default AllActionReports
