import { fork, call, take, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllPurchasesAction,
  // getAllAvailablePurchasesAction,
  getPurchaseAction,
  createPurchaseAction,
  updatePurchaseAction,
  deletePurchaseAction,
} from '../actions/purchasesActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllPurchasesSaga(qs) {
  try {
    const payload = yield call(api.getAllPurchases, qs)
    yield put(getAllPurchasesAction.success(payload))
  } catch (error) {
    yield put(getAllPurchasesAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// function* handleGetAllAvailablePurchasesSaga() {
//   try {
//     const payload = yield call(api.getAllAvailablePurchases)
//     yield put(getAllAvailablePurchasesAction.success(payload))
//   } catch (error) {
//     yield put(getAllAvailablePurchasesAction.failure())
//     yield put(showErrorMessageAction(pickErrorMessage(error)))

//     if (error.status === 401) {
//       yield put(unauthorizedAction())
//     }
//   }
// }

function* handleGetPurchaseSaga(id) {
  try {
    const payload = yield call(api.getPurchase, id)
    yield put(getPurchaseAction.success(payload))
  } catch (error) {
    yield put(getPurchaseAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreatePurchaseSaga(purchase) {
  try {
    let purchases = {}
    let purchasesJson = []
    purchasesJson.push({
      name: purchase['name'],
      status: purchase['status'],
      cost: purchase['cost'],
      dateOrdered: purchase['dateOrdered'],
      dateDelivered: purchase['dateDelivered'] ? purchase['dateDelivered'] : '',
      description: purchase['description'] ? purchase['description'] : '',
      bomParts: purchase['bomParts'] ? purchase['bomParts'] : [],
    })
    purchases['purchasesJson'] = purchasesJson

    const payload = yield call(api.createPurchase, purchases)

    yield put(createPurchaseAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/inventory/purchases/${payload[0].id}`))
  } catch (error) {
    yield put(createPurchaseAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdatePurchaseSaga(purchase) {
  try {
    let purchases = {}
    let purchasesJson = []
    purchasesJson.push({
      name: purchase['name'],
      status: purchase['status'],
      cost: purchase['cost'],
      dateOrdered: purchase['dateOrdered'],
      dateDelivered: purchase['dateDelivered'] ? purchase['dateDelivered'] : '',
      description: purchase['description'] ? purchase['description'] : '',
      bomParts: purchase['bomParts'] ? purchase['bomParts'] : [],
    })
    purchases['purchasesJson'] = purchasesJson

    const payload = yield call(api.updatePurchase, purchases, purchase['id'])
    yield put(updatePurchaseAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/purchases/${payload[0].id}`))
  } catch (error) {
    yield put(updatePurchaseAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeletePurchaseSaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deletePurchase, id)
      yield fork(handleGetAllPurchasesSaga)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deletePurchaseAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllPurchasesSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllPurchasesAction.REQUEST)
    yield fork(handleGetAllPurchasesSaga, qs)
  }
}

// function* watchGetAllAvailablePurchasesSaga() {
//   while (true) {
//     yield take(getAllAvailablePurchasesAction.REQUEST)
//     yield fork(handleGetAllAvailablePurchasesSaga)
//   }
// }

function* watchGetPurchaseSaga() {
  while (true) {
    const { payload } = yield take(getPurchaseAction.REQUEST)
    yield fork(handleGetPurchaseSaga, payload)
  }
}

function* watchCreatePurchaseSaga() {
  while (true) {
    const { payload } = yield take(createPurchaseAction.REQUEST)
    yield fork(handleCreatePurchaseSaga, payload)
  }
}

function* watchUpdatePurchaseSaga() {
  while (true) {
    const { payload } = yield take(updatePurchaseAction.REQUEST)
    yield fork(handleUpdatePurchaseSaga, payload)
  }
}

function* watchDeletePurchaseSaga() {
  while (true) {
    const { payload } = yield take(deletePurchaseAction.REQUEST)
    yield fork(handleDeletePurchaseSaga, payload)
  }
}

function* purchasesSaga() {
  yield all([
    fork(watchGetAllPurchasesSaga),
    // fork(watchGetAllAvailablePurchasesSaga),
    fork(watchGetPurchaseSaga),
    fork(watchCreatePurchaseSaga),
    fork(watchUpdatePurchaseSaga),
    fork(watchDeletePurchaseSaga),
  ])
}

export {
  purchasesSaga as default,
  watchGetAllPurchasesSaga,
  // watchGetAllAvailablePurchasesSaga,
  watchGetPurchaseSaga,
  watchCreatePurchaseSaga,
  watchUpdatePurchaseSaga,
  watchDeletePurchaseSaga,
  handleGetAllPurchasesSaga,
  // handleGetAllAvailablePurchasesSaga,
  handleGetPurchaseSaga,
  handleCreatePurchaseSaga,
  handleUpdatePurchaseSaga,
  handleDeletePurchaseSaga,
}
