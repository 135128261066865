const currencyFormatter = val =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(val)

const normalizeCurrency = (val, prevVal) => {
  // TODO `val` is set to `undefined` on blur, which resets the
  // input after a user entrs a value. right now, we're using
  // the `prevVal` to keep things working, but this does not
  // allow the user to clear the form entirely. need to figure
  // this out later...
  return val
    ? parseInt(
        val
          .toString()
          .replace(/,/g, '')
          .replace(/\$/g, '')
      )
    : prevVal
}

export { currencyFormatter, normalizeCurrency }
