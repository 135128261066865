import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import { Spin } from './common/Ant'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import Divider from './common/Divider'
import { dateTimeFormatter } from '../utils/date'
import {
  SUMMARY_INTERVAL_OPTIONS,
  SUMMARY_REPEAT_FREQUENCY_OPTIONS,
} from '../constants'

class AlertSummary extends Component {
  static propTypes = {
    getAlertSummaryBySlug: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    alertSummary: PropTypes.object.isRequired,
    isAlertSummaryLoading: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    this.props.getAlertSummaryBySlug(this.props.match.params.slug)
  }

  renderFrequencyLabel = value => {
    const index = SUMMARY_REPEAT_FREQUENCY_OPTIONS.map(
      ({ value }) => value
    ).indexOf(parseInt(value, 10))

    return index > -1 && SUMMARY_REPEAT_FREQUENCY_OPTIONS[index].label
  }

  renderIntervalLabel = value => {
    const index = SUMMARY_INTERVAL_OPTIONS.map(({ value }) => value).indexOf(
      parseInt(value, 10)
    )

    return index > -1 && SUMMARY_INTERVAL_OPTIONS[index].label
  }

  render() {
    const {
      alertSummary: {
        slug,
        description,
        active,
        siteSlug,
        siteName,
        nextScheduledRuntime,
        lastRunTime,
        repeatFrequency,
        daysToSummarize,
        repeatAfter,
        userWhitelist,
        thresholdWhitelist,
        users = [],
        thresholds = [],
      },
      isAlertSummaryLoading,
    } = this.props

    return isAlertSummaryLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="AlertSummary">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/reports">Reports</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to="/reports/alert-summaries">Alert Summaries</Anchor>
              </Crumb>
              <Crumb>{description}</Crumb>
            </Bread>
          </div>
          <div className="flex justify-center">
            <Anchor to={`/reports/alert-summaries/${slug}/edit`}>Edit</Anchor>
          </div>
        </div>
        <Divider />
        <div className="flex flex-wrap">
          <Block label="Active">{active ? 'True' : 'False'}</Block>
          {siteSlug && (
            <Block label="Site">
              <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
            </Block>
          )}
          <Block label="Next Run Date">
            {nextScheduledRuntime && dateTimeFormatter(nextScheduledRuntime)}
          </Block>
          <Block label="Last Run Date">
            {lastRunTime && dateTimeFormatter(lastRunTime)}
          </Block>
          <Block label="Repeat Frequency">
            {repeatFrequency &&
              repeatFrequency.toString() &&
              this.renderFrequencyLabel(repeatFrequency)}
          </Block>
          <Block label="Days to Summarize">
            {daysToSummarize && this.renderIntervalLabel(daysToSummarize)}
          </Block>
          <Block label="Repeat Interval">
            {repeatAfter && this.renderIntervalLabel(repeatAfter)}
          </Block>
          <Block
            label={`${userWhitelist ? 'Whitelisted' : 'Blacklisted'} Users`}
          >
            {users.length && (
              <ul className="pl4 mb0">
                {users.map(x => (
                  <li key={x.id}>
                    <Anchor to={`/users/${x.userSlug}`}>{x.userName}</Anchor>
                  </li>
                ))}
              </ul>
            )}
          </Block>
          <Block
            label={`${
              thresholdWhitelist ? 'Whitelisted' : 'Blacklisted'
            } Thresholds`}
          >
            {thresholds.length && (
              <ul className="pl4 mb0">
                {thresholds.map(x => (
                  <li key={x.id}>
                    <Anchor to={`/rules/thresholds/${x.id}`}>{x.name}</Anchor>
                  </li>
                ))}
              </ul>
            )}
          </Block>
        </div>
      </section>
    )
  }
}

export default AlertSummary
