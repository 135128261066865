import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import AllWorkflows from '../AllWorkflows'
import CreateWorkflow from '../CreateWorkflow'
import Workflow from '../Workflow'
import PageWidth from '../PageWidth'

class WorkflowPage extends Component {
  static propTypes = {
    getAllWorkflows: PropTypes.func.isRequired,
    getWorkflow: PropTypes.func.isRequired,
    setWorkflowType: PropTypes.func.isRequired,
    workflows: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentWorkflow: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    windowWidth: PropTypes.number.isRequired,
  }

  render() {
    const {
      getAllWorkflows,
      getWorkflow,
      setWorkflowType,
      workflows,
      currentWorkflow,
      match: { url },
      windowWidth,
    } = this.props

    return (
      <MainContent>
        <div className="WorkflowPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Workflows</title>
                    </Helmet>
                    <AllWorkflows
                      getAllWorkflows={getAllWorkflows}
                      workflows={workflows}
                      windowWidth={windowWidth}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/create`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Workflow</title>
                    </Helmet>
                    <CreateWorkflow
                      setWorkflowType={setWorkflowType}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:workflowType`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentWorkflow ? currentWorkflow.name : 'Loading...'}
                      </title>
                    </Helmet>
                    <Workflow
                      getWorkflow={getWorkflow}
                      workflow={currentWorkflow}
                      {...props}
                    />
                  </Fragment>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default WorkflowPage
