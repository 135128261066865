import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from './common/Ant'
import { CHAT_MESSAGE_TYPES } from '../constants'

const MessageType = ({ messageType }) => {
  let iconType
  let title

  switch (messageType) {
    case CHAT_MESSAGE_TYPES.EMAIL:
      iconType = 'mail'
      title = 'Email'
      break
    case CHAT_MESSAGE_TYPES.CALL:
      iconType = 'phone'
      title = 'Phone Call'
      break
    case CHAT_MESSAGE_TYPES.TEXT:
      iconType = 'message'
      title = 'SMS'
      break
  }
  return (
    <div className="MessageType ml1" title={title}>
      <Icon type={iconType} />
    </div>
  )
}

MessageType.propTypes = {
  messageType: PropTypes.string.isRequired,
}

export default MessageType
