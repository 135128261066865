import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Table, Column, Spin } from './common/Ant'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import { H3, H4 } from './common/Headers'
import { dateTimeFormatter } from '../utils/date'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { ROLES, SMOOTH_INTERVAL } from '../constants'
import moment from 'moment'
import DateRange from './common/DateRange'
import { hasRole } from '../utils/hasPermission'
import { getSensorReading } from '../utils/sensorData'
import { toTitleCase } from '../utils/textFormatters'
import Select, { Option } from './common/Select'
import ImuSensorChart from './charts/ImuSensorChart'
import ImuRotationChart from './charts/ImuRotationChart'
import hiltiLogo from '../assets/hilti-logo.png'
import logoSm from '../assets/logo-sm.png'
import logoMd from '../assets/logo-md.png'
import logoLg from '../assets/logo-lg.png'

class ImuNanoIot extends Component {
  static propTypes = {
    updateQueryParams: PropTypes.func.isRequired,
    imuData: PropTypes.arrayOf(PropTypes.object).isRequired,
    getImuData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
  }
  now = moment()

  constructor(props) {
    super(props)

    this.state = {
      now: this.now,
      endValue: this.now,
      startValue: moment(this.now).subtract(1, 'h'),
      smoothInterval: SMOOTH_INTERVAL.FIVE_MINUTES,
      readingNames: {
        time: 'Time',
        accelX: 'Rotation angle',
        accelY: 'Distance 1',
        accelZ: 'Distance 2',
        gyroX: 'Distance 3',
        gyroY: 'Distance 4',
        rotationAngle: 'Penetration',
        gyroZ: 'Closure Status',
      },
    }
  }

  static defaultProps = {
    imuData: [],
  }

  componentDidMount() {
    const { getImuData } = this.props
    const { macAddress } = this.props.match.params
    const { startValue, endValue } = this.state

    const searchParams = parseQueryParams(global.location)

    let query
    if (searchParams.startTime && searchParams.endTime) {
      query = {
        startTime: searchParams.startTime,
        endTime: searchParams.endTime,
      }
      this.setState({
        startValue: moment(searchParams.startTime * 1000),
        endValue: moment(searchParams.endTime * 1000),
      })
    } else {
      query = {
        startTime: startValue.unix(),
        endTime: endValue.unix(),
      }
    }

    if (searchParams.smoothInterval || searchParams.smooth) {
      query = {
        ...query,
        smoothInterval: searchParams.smoothInterval,
        smooth: searchParams.smooth,
      }

      this.setState({
        smoothInterval: searchParams.smoothInterval
          ? searchParams.smoothInterval
          : !searchParams.smooth || searchParams.smooth === 't'
          ? SMOOTH_INTERVAL.FIVE_MINUTES
          : SMOOTH_INTERVAL.RAW,
      })
    }

    const qs = createQueryString(query)

    getImuData(macAddress, qs)
  }

  onCloseDateRange = (startValue, endValue) => {
    const { getImuData, updateQueryParams } = this.props
    const { macAddress } = this.props.match.params
    const { smoothInterval } = this.state

    this.setState({ startValue, endValue })

    const qs = createQueryString({
      startTime: startValue.unix(),
      endTime: endValue.unix(),
      smooth: smoothInterval === SMOOTH_INTERVAL.RAW ? 'f' : 't',
      smoothInterval:
        smoothInterval !== SMOOTH_INTERVAL.RAW ? smoothInterval : undefined,
    })

    updateQueryParams({
      pathname: global.location.pathname,
      search: qs,
    })
    getImuData(macAddress, qs)
  }

  handleFilterChange = smoothInterval => {
    const { getImuData, updateQueryParams, match } = this.props
    const { macAddress } = match.params
    const { startValue, endValue } = this.state

    this.setState({ smoothInterval })

    const qs = createQueryString({
      smooth: smoothInterval === SMOOTH_INTERVAL.RAW ? 'f' : 't',
      smoothInterval:
        smoothInterval !== SMOOTH_INTERVAL.RAW ? smoothInterval : undefined,
      startTime: startValue.unix(),
      endTime: endValue.unix(),
    })

    updateQueryParams({
      pathname: global.location.pathname,
      search: qs,
    })

    getImuData(macAddress, qs)
  }

  render() {
    const { isLoading, imuData, updateQueryParams, match } = this.props
    const { readingNames, startValue, endValue, smoothInterval } = this.state
    const { macAddress } = match.params

    const tableData =
      imuData && imuData.lastData
        ? Object.keys(readingNames).map(key => {
            const readingData = getSensorReading(key)
            let dataValue = ''
            key === 'gyroZ'
              ? imuData.lastData['rotationAngle'] > imuData.lastData[key]
                ? (dataValue = 'Firestop Open')
                : (dataValue = 'Firestop Closed')
              : (dataValue = imuData.lastData[key]
                  ? key !== 'time'
                    ? parseFloat(imuData.lastData[key]).toFixed(2) +
                      readingData.unit
                    : dateTimeFormatter(imuData.lastData[key])
                  : '--')
            return { name: readingNames[key], value: dataValue }
          })
        : []

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : (
      <Fragment>
        <div className="flex items-center">
          <img
            src={hiltiLogo}
            srcSet={`${hiltiLogo} 1000w, ${hiltiLogo} 2000w`}
            alt="Hilti"
            height={56}
            width={238}
          />
          <div className="flex items-center">
            <div className="mh3">powered by</div>
            <div>
              <img
                src={logoSm}
                srcSet={`${logoMd} 1000w, ${logoLg} 2000w`}
                alt="Pillar Technologies"
                height={24}
                width={100}
              />
            </div>
          </div>
        </div>
        <div className="mb2">
          <Bread>
            <Crumb>
              <Anchor to="/hilti">All Devices</Anchor>
            </Crumb>
            <Crumb>
              {macAddress.length > 17
                ? `${macAddress.substring(0, 17)}...`
                : macAddress}
            </Crumb>
          </Bread>
        </div>
        <div className="mb3" />
        <H3>Last Reading</H3>
        {tableData.length ? (
          <div className="flex-ns">
            <div className="w-40-ns mr5-ns">
              <ImuRotationChart
                cableOccupancy={
                  imuData && imuData.lastData && imuData.lastData.gyroZ
                    ? imuData.lastData.gyroZ.toString()
                    : '0.0'
                }
              />
            </div>
            <div className="w-60-ns mt4-ns">
              <Table
                dataSource={tableData}
                rowKey="name"
                scroll={{ x: 400 }}
                pagination={false}
                tableLayout="auto"
                rowClassName={record =>
                  record.name === 'Closure Status'
                    ? record.value === 'Firestop Open'
                      ? 'bg-light-red'
                      : 'bg-light-green'
                    : ''
                }
                bordered
              >
                <Column
                  title="Name"
                  width={200}
                  dataIndex="name"
                  className={'bg-white'}
                  render={(text, record) => (text ? text : '--')}
                />
                <Column
                  title="Value"
                  width={200}
                  dataIndex="value"
                  render={(text, record) => (text ? text : '--')}
                />
              </Table>
            </div>
          </div>
        ) : (
          <H4 className="tc">No Data Available for this sensor</H4>
        )}
        <H3>Historical Data</H3>
        <div className="charts">
          <div className="mb2 flex-ns">
            <DateRange
              label="Select date range to view IMU readings"
              startValue={startValue}
              endValue={endValue}
              onClose={this.onCloseDateRange}
              shouldLimitRange={!hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN)}
            />
            <Select
              className="ml4-ns"
              label="Smoothing Interval"
              input={{
                value: smoothInterval,
                onChange: x => this.handleFilterChange(x),
              }}
            >
              {Object.keys(SMOOTH_INTERVAL).map(x => (
                <Option value={SMOOTH_INTERVAL[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Select>
          </div>
          {imuData.data && imuData.data.length ? (
            <ImuSensorChart
              data={imuData.data}
              columns={imuData.columns}
              updateQueryParams={updateQueryParams}
            />
          ) : (
            <H4 className="tc">No Data Available for Selected Date Range</H4>
          )}
        </div>
      </Fragment>
    )
  }
}

export default ImuNanoIot
