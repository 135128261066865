import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Bread, Crumb } from './common/Bread'
import { Anchor, ActionAnchor } from './common/Anchor'
import SearchFilter from './common/SearchFilter'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'
import { toTitleCase } from '../utils/textFormatters'

class AllCompanies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.companies,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }
  }

  static propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    deleteCompany: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    isCompanyLoading: PropTypes.bool.isRequired,
    windowWidth: PropTypes.number.isRequired,
    updateQueryParams: PropTypes.func,
    url: PropTypes.string,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.companies })
  }

  componentDidMount() {
    const { page, perPage } = parseQueryParams(global.location)

    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
    }

    this.setState(query)

    this.props.getAllCompanies(createQueryString({ ...query, full: 't' }))
  }

  handleChange = (pagination, filters, sorter) => {
    const { url, getAllCompanies, updateQueryParams } = this.props
    const { perPage } = this.state

    const query = { page: pagination.current, perPage, full: 't' }
    const qs = createQueryString(query)

    getAllCompanies(qs)

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })

    updateQueryParams &&
      updateQueryParams({
        pathname: url,
        search: qs,
      })
  }

  handleFilterChange = keyName => value => {
    const { getAllCompanies, updateQueryParams } = this.props
    const { page, perPage } = this.state

    const query = Object.assign(
      { page, perPage, full: 't' },
      { [keyName]: value }
    )

    this.setState({ [keyName]: value })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllCompanies(qs)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch() {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState({
      filtered: !!searchText,
      data: this.props.companies
        .map(record => {
          const match =
            record.name.match(regex) || record.id.toString().match(regex)

          return match ? record : null
        })
        .filter(record => !!record),
    })
  }

  setSearchText = searchText => this.setState({ searchText })

  render() {
    const {
      deleteCompany,
      meta,
      isCompanyLoading,
      getAllCompanies,
      windowWidth,
    } = this.props
    const { data, sortedInfo, searchText, page, perPage } = this.state

    const query = { page, perPage }

    const isFixedColumn = windowWidth < 1100

    return (
      <section className="AllCompanies">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>Companies</Crumb>
            </Bread>
          </div>
          <div className="flex justify-center">
            <Anchor to="/companies/create">Create New</Anchor>
          </div>
        </div>
        <SearchFilter
          searchText={searchText}
          setSearchText={this.setSearchText}
          getItems={getAllCompanies}
          query={query}
        />
        {isCompanyLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            bordered
            dataSource={data}
            onChange={this.handleChange}
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            rowKey="id"
            scroll={{ x: 820 }}
            tableLayout="auto"
          >
            <Column
              title="Name"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              width={500}
              render={(text, record) => (
                <Anchor to={`/companies/${record.id}`}>{text}</Anchor>
              )}
            />
            <Column
              title="Type"
              dataIndex="companyType"
              sorter={sortBy('companyType')}
              sortOrder={sortedInfo.field === 'companyType' && sortedInfo.order}
              width={200}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              width={120}
              fixed={isFixedColumn ? 'right' : false}
              render={(text, record) => {
                const menu = (
                  <Menu>
                    <Menu.Item key="edit-company">
                      <Anchor to={`/companies/${record.id}/edit`}>Edit</Anchor>
                    </Menu.Item>
                    <Menu.Item key="delete-company">
                      <ActionAnchor onClick={() => deleteCompany(record.id)}>
                        Delete
                      </ActionAnchor>
                    </Menu.Item>
                  </Menu>
                )

                return (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link">
                      Actions <Icon type="down" />
                    </a>
                  </Dropdown>
                )
              }}
            />
          </Table>
        )}
      </section>
    )
  }
}

export default AllCompanies
