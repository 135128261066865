import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from './common/Button'
import Form from './common/Form'
import Label from './common/Label'
import { Modal } from './common/Ant'
import { SIZES } from '../constants'

class LogoUpload extends Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    getCompany: PropTypes.func.isRequired,
    uploadLogo: PropTypes.func.isRequired,
  }

  state = {
    modalOpen: false,
    src: null,
    formData: null,
  }

  componentWillUnmount() {
    if (this.state.modalOpen) {
      this.handleClose()
    }
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  handleFileSelection = event => {
    const files = Array.from(event.target.files)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('name', file.name)
      formData.append('type', file.type)
      formData.append('files[]', file)

      const src = global.URL.createObjectURL(file)
      this.setState({ src, formData })
    })
  }

  handleSubmit = () => {
    const { uploadLogo, companyId } = this.props
    const { formData } = this.state

    uploadLogo(companyId, formData)
    this.handleClose()
  }

  render() {
    const { modalOpen } = this.state

    return (
      <div className="LogoUpload mb3">
        <Button
          onClick={this.handleOpen}
          text="Upload Logo"
          size={SIZES.SMALL}
        />
        <Modal
          visible={modalOpen}
          className="Modal"
          title="Upload Logo"
          onCancel={this.handleClose}
          footer={null}
        >
          <Form onSubmit={this.handleSubmit}>
            <Label>Select an image to upload</Label>
            <input
              type="file"
              accept="image/*"
              onChange={this.handleFileSelection}
              className="mb3 w-100"
            />
            <Button onClick={this.handleSubmit} />
          </Form>
        </Modal>
      </div>
    )
  }
}

export default LogoUpload
