import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import AllFloorsBlock from './AllFloorsBlock'
import { Anchor, ActionAnchor } from './common/Anchor'
import { H4 } from './common/Headers'
import { Menu, Dropdown, Icon } from './common/Ant'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'

const getSortOrder = arr =>
  arr.reduce((mem, x, i) => {
    return { ...mem, [x.slug]: i }
  }, {})

const SortableBuilding = ({
  id,
  name,
  floors,
  siteSlug,
  deleteFloor,
  deleteBuilding,
  handleModalOpen,
  isFloorLoading,
  viewType,
  updateSortOrder,
}) => {
  const [isSorting, setIsSorting] = useState(false)
  const [sortedFloors, setSortedFloors] = useState(floors)

  const moveFloor = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = sortedFloors[dragIndex]

      const result = update(sortedFloors, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })

      setSortedFloors(result)

      updateSortOrder(siteSlug, getSortOrder(result))
    },
    [sortedFloors, siteSlug, updateSortOrder]
  )

  useEffect(() => {
    if (floors.length > 0) {
      setSortedFloors(floors)
    }
  }, [floors])

  return (
    <div key={id} className="bg-near-white pa3 br2 ba b--light-gray mb3">
      <div className="flex">
        <H4 className="pr4">
          {hasPermission(USER_PERMISSIONS.VIEW_BUILDING) ? (
            <Anchor to={`/sites/${siteSlug}/buildings/${id}`}>{name}</Anchor>
          ) : (
            name
          )}
        </H4>
        {hasPermission(USER_PERMISSIONS.EDIT_BUILDING) && (
          <div className="flex">
            <Dropdown
              overlay={
                <Menu>
                  {hasPermission(USER_PERMISSIONS.EDIT_FLOOR) &&
                    sortedFloors.length > 0 && (
                      <Menu.Item key="sort-floors">
                        <ActionAnchor onClick={() => setIsSorting(true)}>
                          Sort Floors
                        </ActionAnchor>
                      </Menu.Item>
                    )}
                  {hasPermission(USER_PERMISSIONS.ADD_FLOOR) && (
                    <Menu.Item key="create-floor">
                      <Anchor to={`/sites/${siteSlug}/floors/create`}>
                        Create Floor
                      </Anchor>
                    </Menu.Item>
                  )}
                  <Menu.Item key="edit-building">
                    <Anchor to={`/sites/${siteSlug}/buildings/${id}/edit`}>
                      Edit
                    </Anchor>
                  </Menu.Item>
                  {hasPermission(USER_PERMISSIONS.DELETE_BUILDING) && (
                    <Menu.Item key="delete-building">
                      <ActionAnchor
                        onClick={() => deleteBuilding(siteSlug, id)}
                      >
                        Delete
                      </ActionAnchor>
                    </Menu.Item>
                  )}
                  {hasPermission(USER_PERMISSIONS.BUILDING_EVACUATION) && (
                    <Menu.Item key="evacuate-building">
                      <Anchor to={`/sites/${siteSlug}/buildings/${id}/evacuate`}>
                        Evacuate Building
                      </Anchor>
                    </Menu.Item>
                  )}
                  {hasPermission(USER_PERMISSIONS.BUILDING_EVACUATION) && (
                    <Menu.Item key="evacuate-building-disable">
                      <Anchor to={`/sites/${siteSlug}/buildings/${id}/disable-evac`}>
                        Turn Off Evacuation
                      </Anchor>
                    </Menu.Item>
                  )}
                </Menu>
              }
              trigger={['click']}
            >
              <a className="ant-dropdown-link">
                Actions <Icon type="down" />
              </a>
            </Dropdown>
            {isSorting && (
              <div className="ml3">
                <ActionAnchor button onClick={() => setIsSorting(false)}>
                  Done Sorting
                </ActionAnchor>
              </div>
            )}
          </div>
        )}
        {hasPermission(USER_PERMISSIONS.BUILDING_EVACUATION) && (
            <div className='flex' style={{marginLeft: 20, justifyContent: 'flex-end'}} >
                <Anchor
                    to={`/sites/${siteSlug}/buildings/${id}/evacuate`}
                    className="mb3 mr3"
                    buttonVariant="danger"
                    button
                >
                Evacuate Building
                </Anchor>
            </div>
        )}
        {hasPermission(USER_PERMISSIONS.BUILDING_EVACUATION) && (
            <div className='flex' style={{marginLeft: 20, justifyContent: 'flex-end'}} >
                <Anchor
                    to={`/sites/${siteSlug}/buildings/${id}/disable-evac`}
                    className="mb3 mr3"
                    button
                >
                Turn Off Evacuation
                </Anchor>
            </div>
        )}
      </div>
      <AllFloorsBlock
        floors={sortedFloors}
        buildingId={id}
        siteSlug={siteSlug}
        deleteFloor={deleteFloor}
        handleModalOpen={handleModalOpen}
        isFloorLoading={isFloorLoading}
        viewType={viewType}
        moveFloor={moveFloor}
        isSorting={isSorting}
      />
    </div>
  )
}

SortableBuilding.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteSlug: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  deleteBuilding: PropTypes.func.isRequired,
  deleteFloor: PropTypes.func.isRequired,
  isFloorLoading: PropTypes.bool.isRequired,
  viewType: PropTypes.string.isRequired,
  updateSortOrder: PropTypes.func.isRequired,
}

export default SortableBuilding
