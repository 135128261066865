import React from 'react'
import PropTypes from 'prop-types'
import { ActionAnchor } from './common/Anchor'
import Divider from './common/Divider'
import { dateFormatter } from '../utils/date'

const SiteCSVDownload = ({
  site: {
    name,
    address,
    city,
    state,
    zipCode,
    contractStartDate,
    contractEndDate,
    deploymentDate,
    completionDate,
    primaryContactUser = {},
    secondaryContactUser = {},
  },
  percentComplete,
}) => {
  const handleDownload = () => {
    // TODO https://github.com/react-csv/react-csv
    let csv =
      'Name,Address,City,State,Zipcode,Contract Start Date,Contract End Date,Deployment Date,Completion Date,Completion Percentage,Primary Contact Name,Primary Contact Email, Primary Contact Phone, Secondary Contact Name, Secondary Contact Email, Secondary Contact Phone\n'

    csv += `${name ? name.replace(/##/g, '') : ''},${
      address ? address.replace(',', ' ') : ''
    },${city},${state},${zipCode},${dateFormatter(
      contractStartDate
    )},${dateFormatter(contractEndDate)},${dateFormatter(
      deploymentDate
    )},${dateFormatter(completionDate)},${percentComplete},${
      primaryContactUser.firstName
    } ${primaryContactUser.lastName},${primaryContactUser.email},${
      primaryContactUser.phoneNumber
    },${secondaryContactUser.firstName} ${secondaryContactUser.lastName},${
      secondaryContactUser.email
    },${secondaryContactUser.phoneNumber}`

    let fileName = name.split(' ').join('-')
    fileName = fileName.split('---').join('-') + '--'
    fileName += dateFormatter(Date.now())
      .split('/')
      .join('-')

    const encodedCsv = global.encodeURI(csv)

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodedCsv
    hiddenElement.target = '_blank'
    hiddenElement.download = `${fileName}.csv`
    hiddenElement.click()
  }

  return (
    <section className="SiteCSVDownload">
      <Divider vertical />
      <span>
        <ActionAnchor onClick={handleDownload}>Download Site Data</ActionAnchor>
      </span>
    </section>
  )
}

SiteCSVDownload.propTypes = {
  site: PropTypes.object.isRequired,
  percentComplete: PropTypes.number.isRequired,
}

export default SiteCSVDownload
