import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { camelize } from 'humps'
import ReportChart from './charts/ReportChart'
import ReportCSVDownload from './ReportCSVDownload'
import { H2, H3 } from './common/Headers'
import Divider from './common/Divider'
import { ActionAnchor } from './common/Anchor'
import { Spin } from './common/Ant'
import generatePDF from '../services/reportGenerator'
import { renderReportType } from '../utils/textFormatters'
import { dateFormatter } from '../utils/date'
import { parseQueryParams } from '../utils/queryParams'
import { toTitleCase } from '../utils/textFormatters'
import { LOCATION_TYPES } from '../constants'

const Report = ({
  siteInfo,
  columns,
  pages,
  reportType,
  toTimestamp,
  fromTimestamp,
}) => {
  const { name: siteName, address, city, state, zipCode } = siteInfo

  const startMoment = moment(fromTimestamp * 1000)
  const endMoment = moment(toTimestamp * 1000)

  const [omittedLocations, setOmittedLocations] = useState([])
  const [isGenerating, setIsGenerating] = useState(false)

  useEffect(() => {
    const { save } = parseQueryParams(global.location)

    const asyncGeneratePDF = async () =>
      await generatePDF({
        columns,
        pages,
        siteInfo,
        reportType,
        fromTimestamp,
        toTimestamp,
        omittedLocations,
      })

    if (save === 't' && siteName) {
      asyncGeneratePDF()
    }
  }, [
    siteName,
    columns,
    pages,
    reportType,
    siteInfo,
    fromTimestamp,
    toTimestamp,
    omittedLocations,
  ])

  if (isGenerating) {
    return (
      <>
        <Spin size="large" className="w-100 center mv5" />
        <p className="tc">Generating Report PDF. Please be patient.</p>
      </>
    )
  }

  return siteName ? (
    <div className="Report">
      <div>
        <div className="tc mb3">
          <div className="mb3">
            <H2>{siteName}</H2>
            {address && <div>{address}</div>}
            {city && (
              <div>
                {city}, {state} {zipCode}
              </div>
            )}
          </div>
          <H3>
            {dateFormatter(startMoment, false, true)} &mdash;{' '}
            {dateFormatter(endMoment, false, true)}
          </H3>
          <div className="mb3">
            <span className="b">Report Type:</span>{' '}
            {renderReportType(reportType)}
          </div>
          <div className="flex justify-center">
            <ActionAnchor
              onClick={async () => {
                setIsGenerating(true)

                // NOTE I don't love it but it works
                setTimeout(async () => {
                  await generatePDF({
                    columns,
                    pages,
                    siteInfo,
                    reportType,
                    fromTimestamp,
                    toTimestamp,
                    omittedLocations,
                  })

                  setIsGenerating(false)
                }, 100)
              }}
            >
              Download PDF
            </ActionAnchor>

            <ReportCSVDownload
              siteName={siteName}
              columns={columns}
              pages={pages}
              reportType={reportType}
            />
          </div>
        </div>
        <Divider />
        <div>
          {pages.map(({ slug, name, buildingName, locationType, items }) => (
            <div key={slug} className="mb5">
              <div className="mb5">
                <div className="flex justify-center f4">
                  <div>
                    {buildingName ? `${buildingName} – ` : ''}
                    {name}
                    {locationType
                      ? ` (${toTitleCase(locationType)}${
                          items.length > 1 ? 's' : ''
                        })`
                      : ''}
                  </div>
                </div>
                <div>
                  {locationType === LOCATION_TYPES.FLOW_MONITOR ? (
                    <ReportChart
                      locations={items}
                      siteName={siteName}
                      reading="flowValue"
                      startValue={startMoment}
                      endValue={endMoment}
                      omittedLocations={omittedLocations}
                      setOmittedLocations={setOmittedLocations}
                      isReport
                    />
                  ) : (
                    columns.map(y => (
                      <ReportChart
                        key={y}
                        locations={items}
                        siteName={siteName}
                        reading={camelize(y)}
                        startValue={startMoment}
                        endValue={endMoment}
                        omittedLocations={omittedLocations}
                        setOmittedLocations={setOmittedLocations}
                        isReport
                      />
                    ))
                  )}
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null
}

Report.propTypes = {
  siteInfo: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportType: PropTypes.string,
  toTimestamp: PropTypes.number,
  fromTimestamp: PropTypes.number,
  expiresAt: PropTypes.number,
}

export default Report
