import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllThresholdsAction,
  getThresholdAction,
  updateThresholdAction,
  getThresholdAssignmentsAction,
  getThresholdEditHistoryAction,
  getAllThresholdGroupsAction,
} from '../actions/thresholdsActions'
import { pickErrorMessage } from '../utils/helpers'
import { showErrorMessageAction } from '../actions/uiActions'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllThresholdsAction.SUCCESS:
        payload.items.forEach(threshold => {
          draft[threshold.id] = threshold
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllThresholdsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(threshold => threshold.id)
        )
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllThresholdsAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const current = (
  state = { visibleToUserRoles: [], rulesJson: [], schedules: [] },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case getThresholdAction.SUCCESS:
      case updateThresholdAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const assignments = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getThresholdAssignmentsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload)
        break
    }
  })

const editHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getThresholdEditHistoryAction.SUCCESS:
        const editHistory = action.payload.items
        let newEditHistory = []

        if (editHistory.length) {
          editHistory.forEach((row, i) => {
            const { updatedBy, date, changedFields } = row
            let changes
            try {
              changes = JSON.parse(changedFields)
            } catch (error) {
              showErrorMessageAction(pickErrorMessage(error))
            }
            if (changes) {
              Object.keys(changes).forEach((field, j) => {
                const { to, from } = changes[field]
                const tableId = i.toString() + j.toString()
                newEditHistory.push({
                  updatedBy,
                  date,
                  to,
                  from,
                  tableId,
                  field,
                })
              })
            }
          })
        }
        draft.splice(0, draft.length, ...newEditHistory)
        break
    }
  })

const thresholdGroupsBySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllThresholdGroupsAction.SUCCESS:
        payload.items.forEach(x => {
          draft[x.slug] = x
        })
        break
    }
  })

const thresholdGroupVisibleSlugs = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllThresholdGroupsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.items.map(x => x.slug))
        break
    }
  })

const thresholdsReducer = combineReducers({
  byId,
  visibleIds,
  current,
  meta,
  assignments,
  editHistory,
  thresholdGroupsBySlug,
  thresholdGroupVisibleSlugs,
})

const getThreshold = (state, id) => state.byId[id]
const getCurrentThreshold = state => state.current
const getThresholdAssignments = state => state.assignments
const getVisibleThresholdEditHistory = state => state.editHistory
const getVisibleThresholds = state =>
  state.visibleIds.map(id => getThreshold(state, id))
const getThresholdsMeta = state => state.meta

const getThresholdGroup = (state, slug) => state.thresholdGroupsBySlug[slug]
const getVisibleThresholdGroups = state =>
  state.thresholdGroupVisibleSlugs.map(slug => getThresholdGroup(state, slug))

export {
  thresholdsReducer as default,
  getCurrentThreshold,
  getVisibleThresholds,
  getThresholdsMeta,
  getThresholdAssignments,
  getVisibleThresholdEditHistory,
  getVisibleThresholdGroups,
}
