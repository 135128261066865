import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import {
  getAllLocationGroupsAction,
  deleteLocationGroupAction,
} from '../actions/locationGroupActions'
import { getSiteAction } from '../actions/sitesActions'
import { getFloorAction } from '../actions/floorsActions'
import { getAllLocationsByFloorAction } from '../actions/locationsActions'

import { getVisibleLocationGroups } from '../reducers/locationGroupsReducer'
import { getCurrentSite } from '../reducers/sitesReducer'
import { getCurrentFloor } from '../reducers/floorsReducer'
import { getVisibleLocations } from '../reducers/locationsReducer'

import EditLocationGroup from './EditLocationGroup'
import { Table, Column, Menu, Dropdown, Icon, Modal } from './common/Ant'
import Divider from './common/Divider'
import { Bread, Crumb } from './common/Bread'
import { Anchor, ActionAnchor } from './common/Anchor'
import { createQueryString } from '../utils/queryParams'
import { ALL } from '../constants'

const mapStateToProps = ({ sites, floors, locations, locationGroups }) => ({
  locationGroups: getVisibleLocationGroups(locationGroups),
  site: getCurrentSite(sites),
  floor: getCurrentFloor(floors),
  locations: getVisibleLocations(locations),
})

const mapDispatchToProps = dispatch => ({
  getAllLocationGroups: payload =>
    dispatch(getAllLocationGroupsAction.request(payload)),
  getSite: siteSlug =>
    dispatch(
      getSiteAction.request({
        siteSlug,
        shouldFetchBuildings: false,
        shouldFetchAvailableUsers: false,
      })
    ),
  getFloor: (siteSlug, floorId) =>
    dispatch(getFloorAction.request({ siteSlug, floorId })),
  getAllLocationsByFloor: (siteSlug, floorId) =>
    dispatch(
      getAllLocationsByFloorAction.request({
        siteSlug,
        floorId,
        shouldFetchSnapshots: false,
      })
    ),
  deleteLocationGroup: payload =>
    dispatch(deleteLocationGroupAction.request(payload)),
})

const LocationGroups = ({
  getAllLocationGroups,
  getSite,
  getFloor,
  getAllLocationsByFloor,
  site: { name: siteName },
  floor: { buildingName, buildingId, name: floorName },
  locations,
  locationGroups,
  deleteLocationGroup,
  match: { url, params },
}) => {
  const history = useHistory()
  const lastLocation = useLastLocation()
  const [locationGroupsOnFloor, setLocationGroupsOnFloor] = useState([])

  const { siteSlug, floorId } = params
  useEffect(() => {
    const groups = locationGroups.filter(
      x => !!x.locations.length && x.locations[0].floorId === parseInt(floorId)
    )
    setLocationGroupsOnFloor(groups)
  }, [locationGroups, floorId])

  useEffect(() => {
    getSite(siteSlug)
  }, [getSite, siteSlug])

  useEffect(() => {
    getFloor(siteSlug, floorId)
  }, [getFloor, siteSlug, floorId])

  useEffect(() => {
    getAllLocationsByFloor(siteSlug, floorId)
  }, [getAllLocationsByFloor, siteSlug, floorId])

  useEffect(() => {
    getAllLocationGroups({
      siteSlug,
      qs: createQueryString({ perPage: ALL }),
    })
  }, [getAllLocationGroups, siteSlug])

  const handleModalCancel = () => {
    const re = /groups\/.*/
    if (re.test(lastLocation.pathname)) {
      history.push(`/sites/${siteSlug}/floors/${floorId}/groups`)
    } else {
      history.goBack()
    }
  }

  return (
    <div className="LocationGroups">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>
              <Anchor to="/sites">Sites</Anchor>
            </Crumb>
            {siteName && (
              <Crumb>
                <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
              </Crumb>
            )}
            {buildingName && (
              <Crumb>
                <Anchor to={`/sites/${siteSlug}/buildings/${buildingId}`}>
                  {buildingName}
                </Anchor>
              </Crumb>
            )}
            {floorName && (
              <Crumb>
                <Anchor to={`/sites/${siteSlug}/floors/${floorId}`}>
                  {floorName}
                </Anchor>
              </Crumb>
            )}
            <Crumb>Rooms</Crumb>
          </Bread>
        </div>
        <div className="flex justify-center">
          <Anchor to={`${url}/create`}>Create Room</Anchor>
        </div>
      </div>
      <Divider />

      <Table
        dataSource={locationGroupsOnFloor}
        rowKey="slug"
        pagination={false}
        size="small"
        tableLayout="auto"
      >
        <Column title="Name" dataIndex="name" />
        <Column
          title="Locations"
          dataIndex="locations"
          render={(text, record) =>
            record.locations.map((x, i) => {
              const sep = record.locations.length - 1 > i ? ', ' : ''

              return `${x.name}${sep}`
            })
          }
        />
        <Column
          render={(text, record) => {
            const menu = (
              <Menu>
                <Menu.Item key="edit-network">
                  <Anchor to={`${url}/${record.slug}/edit`}>Edit</Anchor>
                </Menu.Item>
                <Menu.Item key="delete-network">
                  <ActionAnchor
                    onClick={() =>
                      deleteLocationGroup({ siteSlug, groupSlug: record.slug })
                    }
                  >
                    Delete
                  </ActionAnchor>
                </Menu.Item>
              </Menu>
            )

            return (
              <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link">
                  Actions <Icon type="down" />
                </a>
              </Dropdown>
            )
          }}
        />
      </Table>

      <Route
        path={[`${url}/create`, `${url}/:slug/edit`, `${url}/:slug/view`]}
        exact
        render={props => (
          <>
            <Modal
              className="Modal"
              visible
              footer={null}
              onCancel={handleModalCancel}
              destroyOnClose
            >
              <EditLocationGroup
                siteSlug={siteSlug}
                locations={locations}
                handleModalCancel={handleModalCancel}
                {...props}
              />
            </Modal>
          </>
        )}
      />
    </div>
  )
}

LocationGroups.propTypes = {
  getAllLocationGroups: PropTypes.func.isRequired,
  getSite: PropTypes.func.isRequired,
  getFloor: PropTypes.func.isRequired,
  getAllLocationsByFloor: PropTypes.func.isRequired,
  deleteLocationGroup: PropTypes.func.isRequired,
  locationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  site: PropTypes.object.isRequired,
  floor: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationGroups)
