import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spin } from './common/Ant'
import { Bread, Crumb } from './common/Bread'
import { ActionAnchor } from './common/Anchor'
import Divider from './common/Divider'
import { dateTimeFormatter } from '../utils/date'

class DeveloperAccessToken extends Component {
  static propTypes = {
    getAccessToken: PropTypes.func.isRequired,
    generateAccessToken: PropTypes.func.isRequired,
    accessToken: PropTypes.object.isRequired,
    userSlug: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  }

  componentDidMount() {
    const { userSlug, getAccessToken } = this.props

    !!userSlug && getAccessToken(userSlug)
  }

  componentDidUpdate(prevProps) {
    const { userSlug, getAccessToken } = this.props

    if (!prevProps.userSlug && userSlug) {
      getAccessToken(userSlug)
    }
  }

  render() {
    const { accessToken, userSlug, generateAccessToken, isLoading } = this.props

    return (
      <section className="DeveloperAccessToken">
        <div className="mb3">
          <Bread>
            <Crumb>Developer</Crumb>
            <Crumb>Access Token</Crumb>
          </Bread>
        </div>
        <Divider />
        {isLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <div className="mb3">
            {accessToken.token ? (
              <div>
                <div className="mb2">
                  <div>
                    Token: <span className="b">{accessToken.token}</span>
                  </div>
                  <div>
                    Generated: {dateTimeFormatter(accessToken.createdAt)}
                  </div>
                </div>
                <div className="bg-washed-yellow ba b--yellow pa3 br2 flex-ns justify-between-ns items-center-ns">
                  <div className="mb2 mb0-ns mr3-ns">
                    If you've lost or forgotten this token, you can regenerate
                    it, but be aware that any scripts or applications using this
                    token will need to be updated.
                  </div>
                  <div style={{ minWidth: '165px' }}>
                    <ActionAnchor
                      onClick={() => generateAccessToken(userSlug)}
                      button
                    >
                      Regenerate Access Token
                    </ActionAnchor>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-washed-red ba b--light-red pa3 br2 flex-ns justify-between-ns items-center-ns">
                <div className="mb2 mb0-ns">
                  Access token not generated. Please create a token to use in
                  your scripts or applications.
                </div>
                <ActionAnchor
                  onClick={() => generateAccessToken(userSlug)}
                  button
                >
                  Generate Access Token
                </ActionAnchor>
              </div>
            )}
          </div>
        )}
        <Divider />
      </section>
    )
  }
}

export default DeveloperAccessToken
