import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Menu, Dropdown, Icon, Table, Column, Spin } from './common/Ant'
import InputContainer from '../containers/common/InputContainer'
import { Anchor, ActionAnchor } from './common/Anchor'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'

class AllActionReportCauses extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.actionReportCauses,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }
  }

  static propTypes = {
    actionReportCauses: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllActionReportCauses: PropTypes.func.isRequired,
    deleteActionReportCause: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    updateQueryParams: PropTypes.func,
    url: PropTypes.string,
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  componentDidMount() {
    const { page, perPage } = parseQueryParams(global.location)
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
    }

    this.setState(query)

    this.props.getAllActionReportCauses(createQueryString(query))
  }

  handleChange = (pagination, filters, sorter) => {
    const { url, getAllActionReportCauses, updateQueryParams } = this.props
    const { page, perPage } = this.state

    if (page !== pagination.current) {
      const query = { page: pagination.current, perPage }

      const qs = createQueryString(query)
      getAllActionReportCauses(qs)

      updateQueryParams && updateQueryParams({ pathname: url, search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllActionReportCauses, updateQueryParams } = this.props
    const { page, perPage } = this.state

    const query = Object.assign(
      { page, perPage, full: 't' },
      { [keyName]: value }
    )

    this.setState({ [keyName]: value })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllActionReportCauses(qs)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.actionReportCauses
        .map(record => {
          const match =
            record.cause.match(regex) ||
            (record.addedByUserName && record.addedByUserName.match(regex))

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  render() {
    const { windowWidth, meta, isLoading, deleteActionReportCause } = this.props
    const { data, sortedInfo, searchText, perPage } = this.state
    const isFixedColumn = windowWidth < 750

    return (
      <section className="mb5 AllActionReportCauses">
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: searchText,
              onChange: this.handleInputChange,
            }}
          />
        </div>
        {isLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{ x: 450 }}
            bordered
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            tableLayout="auto"
          >
            <Column
              title="Cause"
              dataIndex="cause"
              sorter={sortBy('cause')}
              sortOrder={sortedInfo.field === 'cause' && sortedInfo.order}
              width={200}
            />
            <Column
              title="Company"
              dataIndex="companyName"
              sorter={sortBy('companyName')}
              sortOrder={sortedInfo.field === 'companyName' && sortedInfo.order}
              width={100}
            />
            <Column
              title="Created By"
              dataIndex="addedByUserName"
              sorter={sortBy('addedByUserName')}
              sortOrder={
                sortedInfo.field === 'addedByUserName' && sortedInfo.order
              }
              render={(text, { addedByUserName }) =>
                addedByUserName ? addedByUserName : '--'
              }
              width={100}
            />
            <Column
              fixed={isFixedColumn ? 'right' : false}
              width={100}
              render={(text, record) => {
                const menu = (
                  <Menu>
                    <Menu.Item key="edit-tag">
                      <Anchor
                        to={`/metadata/action-report-causes/${record.slug}/edit`}
                      >
                        Edit
                      </Anchor>
                    </Menu.Item>
                    <Menu.Item key="delete-tag">
                      <ActionAnchor
                        onClick={() => deleteActionReportCause(record.slug)}
                      >
                        Delete
                      </ActionAnchor>
                    </Menu.Item>
                  </Menu>
                )

                return (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link">
                      Actions <Icon type="down" />
                    </a>
                  </Dropdown>
                )
              }}
            />
          </Table>
        )}
      </section>
    )
  }
}

export default AllActionReportCauses
