import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import cx from 'classnames'
import { Table, Column, Spin, Menu, Dropdown, Icon } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import { dateTimeFormatter } from '../utils/date'
import { configurePagination } from '../utils/helpers'
import { createQueryString } from '../utils/queryParams'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  SUMMARY_INTERVAL_OPTIONS,
  SUMMARY_REPEAT_FREQUENCY_OPTIONS,
} from '../constants'

class AllAlertSummaries extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.alertSummaries,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }
  }

  static propTypes = {
    alertSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.object.isRequired,
    getAllAlertSummaries: PropTypes.func.isRequired,
    deleteAlertSummary: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  }

  componentDidMount() {
    const { getAllAlertSummaries } = this.props

    getAllAlertSummaries()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.alertSummaries })
  }

  handleChange = (pagination, filters, sorter) => {
    const { getAllAlertSummaries } = this.props
    const { perPage, page } = this.state

    if (page !== pagination.current) {
      getAllAlertSummaries(
        createQueryString({
          page: pagination.current,
          perPage,
        })
      )
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllAlertSummaries } = this.props
    const { perPage, page } = this.state

    const query = Object.assign(
      {
        page,
        perPage,
      },
      { [keyName]: value }
    )

    this.setState({ [keyName]: value })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllAlertSummaries(qs)
  }

  render() {
    const { isLoading, meta, deleteAlertSummary } = this.props
    const { data, sortedInfo, perPage } = this.state

    return (
      <section className="mb5 AllAlertSummaries">
        {isLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="slug"
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            scroll={{ x: 850 }}
            tableLayout="auto"
            bordered
          >
            <Column
              title="Description"
              dataIndex="description"
              sorter={sortBy('description')}
              sortOrder={sortedInfo.field === 'description' && sortedInfo.order}
              width={200}
              render={(text, record) => {
                const prefix = cx('mr2 br-100', {
                  'bg-dark-blue': record.active,
                  'bg-light-gray': !record.active,
                })

                return (
                  <div className="flex items-center">
                    <div
                      className={prefix}
                      style={{
                        height: 8,
                        width: 8,
                      }}
                    />
                    <Anchor to={`/reports/alert-summaries/${record.slug}`}>
                      {text}
                    </Anchor>
                  </div>
                )
              }}
            />
            <Column
              title="Site"
              dataIndex="siteName"
              sorter={sortBy('siteName')}
              sortOrder={sortedInfo.field === 'siteName' && sortedInfo.order}
              width={200}
              render={(text, record) => (
                <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
              )}
            />
            <Column
              title="Next Run Date"
              dataIndex="nextScheduledRuntime"
              sorter={sortBy('nextScheduledRuntime')}
              sortOrder={
                sortedInfo.field === 'nextScheduledRuntime' && sortedInfo.order
              }
              width={200}
              render={text => (text ? dateTimeFormatter(text) : '--')}
            />
            <Column
              title="Last Run Date"
              dataIndex="lastRunTime"
              sorter={sortBy('lastRunTime')}
              sortOrder={sortedInfo.field === 'lastRunTime' && sortedInfo.order}
              width={200}
              render={text => (text ? dateTimeFormatter(text) : '--')}
            />
            <Column
              title="Repeat Frequency"
              dataIndex="repeatFrequency"
              sorter={sortBy('repeatFrequency')}
              sortOrder={
                sortedInfo.field === 'repeatFrequency' && sortedInfo.order
              }
              width={200}
              render={text => {
                const index = SUMMARY_REPEAT_FREQUENCY_OPTIONS.map(
                  ({ value }) => value
                ).indexOf(parseInt(text, 10))

                return index > -1
                  ? SUMMARY_REPEAT_FREQUENCY_OPTIONS[index].label
                  : '--'
              }}
            />
            <Column
              title="Days to Summarize"
              dataIndex="daysToSummarize"
              sorter={sortBy('daysToSummarize')}
              sortOrder={
                sortedInfo.field === 'daysToSummarize' && sortedInfo.order
              }
              width={200}
              render={text => {
                const index = SUMMARY_INTERVAL_OPTIONS.map(
                  ({ value }) => value
                ).indexOf(parseInt(text, 10))

                return index > -1 ? SUMMARY_INTERVAL_OPTIONS[index].label : '--'
              }}
            />
            <Column
              title="Repeat Interval"
              dataIndex="repeatAfter"
              sorter={sortBy('repeatAfter')}
              sortOrder={sortedInfo.field === 'repeatAfter' && sortedInfo.order}
              width={200}
              render={text => {
                const index = SUMMARY_INTERVAL_OPTIONS.map(
                  ({ value }) => value
                ).indexOf(parseInt(text, 10))

                return index > -1 ? SUMMARY_INTERVAL_OPTIONS[index].label : '--'
              }}
            />
            <Column
              width={100}
              render={(text, record) => {
                const menu = (
                  <Menu>
                    <Menu.Item key="edit-summary">
                      <Anchor
                        to={`/reports/alert-summaries/${record.slug}/edit`}
                      >
                        Edit
                      </Anchor>
                    </Menu.Item>
                    <Menu.Item key="delete-summary">
                      <ActionAnchor
                        onClick={() => deleteAlertSummary(record.slug)}
                      >
                        Delete
                      </ActionAnchor>
                    </Menu.Item>
                  </Menu>
                )

                return (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link">
                      Actions <Icon type="down" />
                    </a>
                  </Dropdown>
                )
              }}
            />
          </Table>
        )}
      </section>
    )
  }
}

export default AllAlertSummaries
