import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
// TODO why using `extract` here?
import { extract } from 'query-string'
import { Tabs, TabPane, RadioButton, RadioGroup } from './common/Ant'
import BuildingHeader from './BuildingHeader'
import AllFloorsBlock from './AllFloorsBlock'
import RiMetrics from './RiMetrics'
import { getPathSnippets } from '../utils/helpers'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { BUILDING_VIEW_TYPES, USER_PERMISSIONS, ROLES } from '../constants'

class Building extends Component {
  static propTypes = {
    site: PropTypes.object.isRequired,
    building: PropTypes.object.isRequired,
    floors: PropTypes.arrayOf(PropTypes.object).isRequired,
    riMetrics: PropTypes.arrayOf(PropTypes.object).isRequired,
    getSite: PropTypes.func.isRequired,
    getBuilding: PropTypes.func.isRequired,
    getAllFloorsByBuilding: PropTypes.func.isRequired,
    getRiMetrics: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    deleteFloor: PropTypes.func.isRequired,
    isFloorLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
    getBuildingThresholdUserMappings: PropTypes.func.isRequired,
    handleModalOpen: PropTypes.func.isRequired,
  }

  state = {
    viewType: BUILDING_VIEW_TYPES.LOCATIONS,
    activeTab: undefined,
  }

  componentDidMount() {
    const {
      getSite,
      getBuilding,
      getAllFloorsByBuilding,
      getBuildingThresholdUserMappings,
      match: {
        params: { id, siteSlug },
      },
    } = this.props

    getSite(siteSlug, false)
    getBuilding(siteSlug, id)
    getAllFloorsByBuilding(siteSlug, id)

    if (hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD)) {
      getBuildingThresholdUserMappings(siteSlug, id)
    }

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    const searchParams = extract(global.location.search) === 'summaries'
    if (searchParams) {
      this.setState({ activeTab: 'floors', viewType: 'summaries' })
    } else if (lastSnippet === 'floors') {
      this.setState({ activeTab: 'floors', viewType: 'locations' })
    } else {
      this.handleTabSelection(lastSnippet)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      accountProfile,
      getBuildingThresholdUserMappings,
      match: {
        params: { id, siteSlug },
      },
    } = this.props

    // TODO how to do this w/o passing accountProfile into everything?
    if (
      !accountProfile.role &&
      nextProps.accountProfile.role &&
      hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD)
    ) {
      getBuildingThresholdUserMappings(siteSlug, id)
    }
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    const searchParams = extract(global.location.search) === 'summaries'
    if (searchParams) {
      this.setState({ activeTab: 'floors', viewType: 'summaries' })
    } else if (lastSnippet === 'floors') {
      this.setState({ activeTab: 'floors', viewType: 'locations' })
    } else {
      this.setState({ activeTab: lastSnippet })
    }
  }

  handleTabSelection = entity => {
    const { updateQueryParams, match } = this.props
    const { activeTab } = this.state

    if (entity !== match.params.id) {
      this.setState({ activeTab: entity })
      updateQueryParams({
        pathname: `${match.url}/${entity}`,
        search: entity === activeTab ? global.location.search : '',
      })
    } else {
      this.setState({ activeTab: 'floors' })
    }
  }

  handleViewTypeChange = ({ target: { value } }) => {
    this.setState({ viewType: value })

    const { updateQueryParams, match } = this.props
    updateQueryParams({
      pathname: `${match.url}/floors`,
      search: value === 'summaries' ? value : '',
    })
  }

  render() {
    const {
      site,
      building,
      floors,
      riMetrics,
      deleteFloor,
      getRiMetrics,
      isFloorLoading,
      isHistoryLoading,
      accountProfile,
      match,
      updateQueryParams,
      handleModalOpen,
    } = this.props
    const { viewType } = this.state
    const { siteSlug, id: buildingId } = match.params

    return (
      <div className="Building">
        <Switch>
          <Route
            path={`${match.url}/`}
            exact
            render={props => <Redirect to={`${match.url}/floors`} />}
          />
          <Fragment>
            <BuildingHeader
              site={site}
              building={building}
              accountProfile={accountProfile}
              match={match}
            />
            <Tabs
              type="card"
              defaultActiveKey="floors"
              activeKey={this.state.activeTab}
              onTabClick={this.handleTabSelection}
            >
              <TabPane tab="Floors" key="floors">
                <Route
                  path={`${match.url}/floors`}
                  render={props => (
                    <div>
                      <div className="flex-ns items-center-ns justify-between-ns mb3">
                        <div />
                        <RadioGroup
                          onChange={this.handleViewTypeChange}
                          value={viewType}
                          size="small"
                        >
                          <RadioButton value={BUILDING_VIEW_TYPES.LOCATIONS}>
                            Locations
                          </RadioButton>
                          <RadioButton value={BUILDING_VIEW_TYPES.SUMMARIES}>
                            Summaries
                          </RadioButton>
                        </RadioGroup>
                      </div>
                      <div className="bg-near-white pa3 br2 ba b--light-gray">
                        <AllFloorsBlock
                          floors={floors}
                          siteSlug={siteSlug}
                          deleteFloor={deleteFloor}
                          isFloorLoading={isFloorLoading}
                          viewType={viewType}
                          handleModalOpen={handleModalOpen}
                        />
                      </div>
                    </div>
                  )}
                />
              </TabPane>
              {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
                <TabPane tab="Reliability Index Metrics" key="ri-metrics">
                  <Route
                    path={`${match.url}/ri-metrics`}
                    exact
                    render={props => (
                      <RiMetrics
                        updateQueryParams={updateQueryParams}
                        getRiMetrics={getRiMetrics}
                        riMetrics={riMetrics}
                        siteSlug={siteSlug}
                        buildingId={buildingId}
                        isLoading={isHistoryLoading}
                      />
                    )}
                  />
                </TabPane>
              )}
            </Tabs>
          </Fragment>
        </Switch>
      </div>
    )
  }
}

export default Building
