import React from 'react'
import PropTypes from 'prop-types'

const InlineSelect = ({ value, setValue, label, options }) => {
  const onChange = event => {
    setValue(event.target.value)
  }

  return (
    <select
      className="pa1 mh2 tac ba bw2 b--dashed b--light-gray pointer hover-bg-light-gray"
      aria-label={label}
      value={value}
      placeholder={label}
      onChange={onChange}
      style={{
        WebkitAppearance: 'none',
        MozAppearance: 'none',
      }}
    >
      {options.map(x => (
        <option key={x.value} value={x.value}>
          {x.label}
        </option>
      ))}
    </select>
  )
}

InlineSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
}

export default InlineSelect
