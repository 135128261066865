import { createFormAction } from 'redux-form-saga'

const getInventoryAction = createFormAction('GET_INVENTORY_DASHBOARD')
const getInventoryProjectionsAction = createFormAction(
  'GET_INVENTORY_PROJECTIONS'
)

const createInventoryProposalAction = createFormAction(
  'CREATE_INVENTORY_PROPOSAL'
)
const updateInventoryProposalAction = createFormAction(
  'UPDATE_INVENTORY_PROPOSAL'
)
const getInventoryProposalAction = createFormAction('GET_INVENTORY_PROPOSAL')
const deleteInventoryProposalAction = createFormAction(
  'DELETE_INVENTORY_PROPOSAL'
)
const convertInventoryProposalAction = createFormAction(
  'CONVERT_INVENTORY_PROPOSAL'
)

export {
  getInventoryAction,
  getInventoryProjectionsAction,
  createInventoryProposalAction,
  updateInventoryProposalAction,
  getInventoryProposalAction,
  deleteInventoryProposalAction,
  convertInventoryProposalAction,
}
