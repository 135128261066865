import { connect } from 'react-redux'
import DeveloperPage from '../components/pages/DeveloperPage'
import { isDeveloperLoading } from '../reducers/uiReducer'
import {
  getAccessTokenAction,
  generateAccessTokenAction,
} from '../actions/developerActions'
import { getAccessToken } from '../reducers/developerReducer'
import { getAccountProfileSlug } from '../reducers/accountReducer'

const mapStateToProps = ({ developer, account, ui }) => ({
  accessToken: getAccessToken(developer),
  userSlug: getAccountProfileSlug(account),
  isLoading: isDeveloperLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getAccessToken: payload => dispatch(getAccessTokenAction.request(payload)),
  generateAccessToken: payload =>
    dispatch(generateAccessTokenAction.request(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperPage)
