import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import Divider from '../common/Divider'
import InputContainer from '../../containers/common/InputContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import FormError from '../common/FormError'
import { updateAccountProfileAction } from '../../actions/accountActions'
import { required, isPhoneNumber } from '../../utils/validators'
import { normalizePhoneNumber, formatPhoneNumber } from '../../utils/forms'

const EditAccountProfileForm = props => {
  const { handleSubmit, submitting, error } = props

  const submit = handleSubmit(updateAccountProfileAction)

  return (
    <section className="EditAccountProfileForm">
      <Form onSubmit={submit}>
        <Divider />
        <div className="flex-ns">
          <Field
            name="firstName"
            type="text"
            component={InputContainer}
            label="First Name"
            validate={[required]}
            className="w-50-ns mr3-ns"
          />
          <Field
            name="lastName"
            type="text"
            component={InputContainer}
            label="Last Name"
            validate={[required]}
            className="w-50-ns"
          />
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="phoneNumber"
            type="tel"
            component={InputContainer}
            label="Phone Number"
            className="w-50-ns mr3-ns"
            validate={[required, isPhoneNumber]}
            normalize={normalizePhoneNumber}
            format={formatPhoneNumber}
          />
          <Field
            name="title"
            type="text"
            component={InputContainer}
            label="Job Title"
            className="w-50-ns"
          />
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton defaultLocation="/dashboard" />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditAccountProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditAccountForm' })(EditAccountProfileForm)
