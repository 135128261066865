import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MainContent from '../common/MainContent'
import { Bread, Crumb } from '../common/Bread'
import { Table, Column, Spin } from '../common/Ant'
import { dateTimeFormatter } from '../../utils/date'
import { BLE_COMPANIES } from '../../constants'

const BLEPage = ({ data, getAllBLEData, isLoading }) => {
  useEffect(() => {
    // FIXME
    // hardcoded company name
    getAllBLEData(BLE_COMPANIES.MILWAUKEE)
  }, [getAllBLEData])

  return (
    <MainContent>
      <section className="BLEPage with-side-nav">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>BLE</Crumb>
            </Bread>
          </div>
        </div>
        {isLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            bordered
            dataSource={data}
            pagination={false}
            scroll={{ x: 850 }}
            rowKey="localId"
            tableLayout="auto"
          >
            <Column
              title="Received At"
              dataIndex="time"
              width={200}
              render={text => dateTimeFormatter(text, true)}
            />
            <Column title="Client ID" dataIndex="clientid" width={200} />
            <Column title="UUID" dataIndex="uuid" width={200} />
            <Column title="Data" dataIndex="bleData" width={200} />
            <Column title="Latitude" dataIndex="latitude" width={200} />
            <Column title="Longitude" dataIndex="longitude" width={200} />
            <Column
              title="RSSI"
              dataIndex="rssi"
              width={200}
              render={text => `${text} dBm`}
            />
            <Column
              title="Accuracy"
              dataIndex="accuracy"
              width={200}
              render={text => `${text} m`}
            />
          </Table>
        )}
      </section>
    </MainContent>
  )
}

BLEPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllBLEData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default BLEPage
