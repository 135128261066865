import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from './common/Ant'
import Form from './common/Form'
import Divider from './common/Divider'
import Input from './common/Input'
import Select, { Option } from './common/Select'
import Button from './common/Button'
import FormError from './common/FormError'
import { H3 } from './common/Headers'

const CreateConversationModal = ({
  getUsers,
  users,
  isModalVisible,
  handleModalCancel,
  createConversation,
}) => {
  const [userSlug, setUserSlug] = useState()
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  const handleCancel = () => {
    handleModalCancel()
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!userSlug || !message) {
      setError('User and Message are required.')

      return
    }

    setError('')

    createConversation(userSlug, message)
    setUserSlug(null)
    setMessage('')
    handleCancel()
  }

  const handleSearch = value => {
    value && getUsers(value)
  }

  return (
    <Modal
      className="Modal"
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
    >
      <H3>Create Conversation</H3>
      <Form onSubmit={handleSubmit}>
        <Select
          label="Search for a user to message"
          placeholder="Type to search for a user"
          onSearch={handleSearch}
          input={{
            value: userSlug,
            onChange: value => setUserSlug(value),
          }}
          filterable
          isRequired
        >
          {users.map(x => (
            <Option value={x.slug} key={x.slug}>
              {x.firstName} {x.lastName}
            </Option>
          ))}
        </Select>
        <Input
          type="text"
          label="Message"
          input={{
            placeholder: 'Enter a message to send',
            value: message,
            onChange: ev => setMessage(ev.currentTarget.value),
          }}
          isRequired
        />
        <Divider />
        <div className="mb3 i">
          <span className="b">Reminder:</span> If the user you are messaging is
          outside of the Pillar organization, this will send an SMS message.
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <Button text="Submit" type="submit" />
        </div>
      </Form>
    </Modal>
  )
}

CreateConversationModal.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  handleModalCancel: PropTypes.func.isRequired,
  createConversation: PropTypes.func.isRequired,
}

export default CreateConversationModal
