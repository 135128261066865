import { fork, call, take, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllAssetLocationsAction,
  getAssetLocationAction,
  createAssetLocationAction,
  deleteAssetLocationAction,
  updateAssetLocationAction,
} from '../actions/assetLocationsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllAssetLocationsSaga(qs) {
  try {
    const result = yield call(api.getAllAssetLocations, qs)
    yield put(getAllAssetLocationsAction.success(result))
  } catch (error) {
    yield put(getAllAssetLocationsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAssetLocationSaga(id) {
  try {
    const payload = yield call(api.getAssetLocation, id)
    yield put(getAssetLocationAction.success(payload))
  } catch (error) {
    yield put(getAssetLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateAssetLocationSaga(assetLocation) {
  try {
    const result = yield call(api.createAssetLocation, assetLocation)

    yield put(createAssetLocationAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield fork(handleGetAllAssetLocationsSaga)
    yield put(push('/inventory/all/asset-locations'))
  } catch (error) {
    yield put(createAssetLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateAssetLocationSaga(assetLocation) {
  try {
    const payload = yield call(api.updateAssetLocation, assetLocation)
    yield put(updateAssetLocationAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield fork(handleGetAllAssetLocationsSaga)
    yield put(push('/inventory/all/asset-locations'))
  } catch (error) {
    yield put(updateAssetLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteAssetLocationSaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteAssetLocation, id)
      yield fork(handleGetAllAssetLocationsSaga)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteAssetLocationAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllAssetLocationsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllAssetLocationsAction.REQUEST)
    yield fork(handleGetAllAssetLocationsSaga, qs)
  }
}

function* watchGetAssetLocationSaga() {
  while (true) {
    const { payload } = yield take(getAssetLocationAction.REQUEST)
    yield fork(handleGetAssetLocationSaga, payload)
  }
}

function* watchCreateAssetLocationSaga() {
  while (true) {
    const { payload } = yield take(createAssetLocationAction.REQUEST)
    yield fork(handleCreateAssetLocationSaga, payload)
  }
}

function* watchUpdateAssetLocationSaga() {
  while (true) {
    const { payload } = yield take(updateAssetLocationAction.REQUEST)
    yield fork(handleUpdateAssetLocationSaga, payload)
  }
}

function* watchDeleteAssetLocationSaga() {
  while (true) {
    const { payload } = yield take(deleteAssetLocationAction.REQUEST)
    yield fork(handleDeleteAssetLocationSaga, payload)
  }
}

function* assetLocationsSaga() {
  yield all([
    fork(watchGetAllAssetLocationsSaga),
    fork(watchUpdateAssetLocationSaga),
    fork(watchCreateAssetLocationSaga),
    fork(watchDeleteAssetLocationSaga),
    fork(watchGetAssetLocationSaga),
  ])
}

export {
  assetLocationsSaga as default,
  watchGetAllAssetLocationsSaga,
  watchCreateAssetLocationSaga,
  watchUpdateAssetLocationSaga,
  watchDeleteAssetLocationSaga,
  watchGetAssetLocationSaga,
  handleGetAllAssetLocationsSaga,
  handleCreateAssetLocationSaga,
  handleUpdateAssetLocationSaga,
  handleDeleteAssetLocationSaga,
  handleGetAssetLocationSaga,
}
