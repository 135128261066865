import { fork, call, take, takeLatest, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllGatewaysAction,
  getAllAvailableGatewaysAction,
  getGatewayAlertsUptimeDataAction,
  getGatewayConnectedPodsAction,
  getGatewayAction,
  createGatewayAction,
  updateGatewayAction,
  deleteGatewayAction,
  restartGatewayAction,
  getSiteGatewaysAction,
  getGatewayAssignmentHistoryAction,
  getGatewayRestartHistoryAction,
} from '../actions/gatewaysActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllGatewaysSaga(qs) {
  try {
    const payload = yield call(api.getAllGateways, qs)
    yield put(getAllGatewaysAction.success(payload))
  } catch (error) {
    yield put(getAllGatewaysAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSiteGatewaysSaga({ payload }) {
  try {
    const result = yield call(api.getAllGatewaysBySite, payload)
    yield put(getSiteGatewaysAction.success(result))
  } catch (error) {
    yield put(getSiteGatewaysAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllAvailableGatewaysSaga({ payload }) {
  try {
    const result = yield call(api.getAllAvailableGateways, payload)
    yield put(getAllAvailableGatewaysAction.success(result))
  } catch (error) {
    yield put(getAllAvailableGatewaysAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetGatewaySaga(pillarId) {
  try {
    const payload = yield call(api.getGatewayById, pillarId)
    yield put(getGatewayAction.success(payload))
  } catch (error) {
    yield put(getGatewayAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetGatewayAlertsChartDataSaga({ id, qs }) {
  try {
    const payload = yield call(api.getGatewayUptimeData, id, qs)
    yield put(getGatewayAlertsUptimeDataAction.success(payload))
  } catch (error) {
    yield put(getGatewayAlertsUptimeDataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetGatewayConnectedPodsSaga(id) {
  try {
    const payload = yield call(api.getGatewayConnectedPods, id)
    yield put(getGatewayConnectedPodsAction.success(payload))
  } catch (error) {
    yield put(getGatewayConnectedPodsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateGatewaySaga(gateway) {
  try {
    const payload = yield call(api.createGateway, gateway)

    yield put(createGatewayAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/inventory/gateways/${payload.gatewayPillarId}`))
  } catch (error) {
    yield put(createGatewayAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateGatewaySaga(gateway) {
  try {
    const payload = yield call(api.updateGateway, gateway)
    yield put(updateGatewayAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/gateways/${payload.gatewayPillarId}`))
  } catch (error) {
    yield put(updateGatewayAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteGatewaySaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteGateway, id)
      yield fork(handleGetAllGatewaysSaga)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteGatewayAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

/* TODO: Add tests */
function* handleRestartGatewaySaga(id) {
  try {
    const confirmDeletion = yield call(
      global.confirm,
      MESSAGES.CONFIRM_GATEWAY_RESTART
    )

    if (confirmDeletion) {
      const payload = yield call(api.restartGatewayById, id)

      // This is done here because the API doesn't return an
      // abort message for this endpoint.
      const apiError = { message: 'gateway_restart_failed' }

      // "This will utilize LinkLabs to physically reset the gateway, are you sure you want to do this?"
      if (payload.success) {
        yield put(restartGatewayAction.success(payload))
        yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_RESTARTED))
      } else {
        yield put(restartGatewayAction.failure({ apiError }))
        yield put(showErrorMessageAction(pickErrorMessage({ apiError })))
      }
    }
  } catch (error) {
    yield put(restartGatewayAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetGatewayAssignmentHistorySaga({ payload: id }) {
  try {
    const result = yield call(api.getGatewayAssignmentHistory, id)
    yield put(getGatewayAssignmentHistoryAction.success(result))
  } catch (error) {
    yield put(getGatewayAssignmentHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetGatewayRestartHistorySaga({ payload }) {
  try {
    const result = yield call(api.getGatewayRestartHistoryByPillarId, payload)
    yield put(getGatewayRestartHistoryAction.success(result))
  } catch (error) {
    yield put(getGatewayRestartHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetGatewayAssignmentHistorySaga() {
  yield takeLatest(
    getGatewayAssignmentHistoryAction.REQUEST,
    handleGetGatewayAssignmentHistorySaga
  )
}

function* watchGetGatewayRestartHistorySaga() {
  yield takeLatest(
    getGatewayRestartHistoryAction.REQUEST,
    handleGetGatewayRestartHistorySaga
  )
}

function* watchGetAllGatewaysSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllGatewaysAction.REQUEST)
    yield fork(handleGetAllGatewaysSaga, qs)
  }
}

function* watchGetSiteGatewaysSaga() {
  yield takeLatest(getSiteGatewaysAction.REQUEST, handleGetSiteGatewaysSaga)
}

function* watchGetAllAvailableGatewaysSaga() {
  yield takeLatest(
    getAllAvailableGatewaysAction.REQUEST,
    handleGetAllAvailableGatewaysSaga
  )
}

function* watchGetGatewaySaga() {
  while (true) {
    const { payload } = yield take(getGatewayAction.REQUEST)
    yield fork(handleGetGatewaySaga, payload)
  }
}

function* watchGetGatewayAlertsChartDataSaga() {
  while (true) {
    const { payload } = yield take(getGatewayAlertsUptimeDataAction.REQUEST)
    yield fork(handleGetGatewayAlertsChartDataSaga, payload)
  }
}

function* watchGetGatewayConnectedPodsSaga() {
  while (true) {
    const { payload } = yield take(getGatewayConnectedPodsAction.REQUEST)
    yield fork(handleGetGatewayConnectedPodsSaga, payload)
  }
}

function* watchCreateGatewaySaga() {
  while (true) {
    const { payload } = yield take(createGatewayAction.REQUEST)
    yield fork(handleCreateGatewaySaga, payload)
  }
}

function* watchRestartGatewaySaga() {
  while (true) {
    const { payload } = yield take(restartGatewayAction.REQUEST)
    yield fork(handleRestartGatewaySaga, payload)
  }
}

function* watchUpdateGatewaySaga() {
  while (true) {
    const { payload } = yield take(updateGatewayAction.REQUEST)
    yield fork(handleUpdateGatewaySaga, payload)
  }
}

function* watchDeleteGatewaySaga() {
  while (true) {
    const { payload } = yield take(deleteGatewayAction.REQUEST)
    yield fork(handleDeleteGatewaySaga, payload)
  }
}

function* gatewaysSaga() {
  yield all([
    fork(watchGetAllGatewaysSaga),
    fork(watchGetAllAvailableGatewaysSaga),
    fork(watchGetGatewaySaga),
    fork(watchCreateGatewaySaga),
    fork(watchUpdateGatewaySaga),
    fork(watchDeleteGatewaySaga),
    fork(watchGetGatewayAlertsChartDataSaga),
    fork(watchRestartGatewaySaga),
    fork(watchGetSiteGatewaysSaga),
    fork(watchGetGatewayAssignmentHistorySaga),
    fork(watchGetGatewayRestartHistorySaga),
    fork(watchGetGatewayConnectedPodsSaga),
  ])
}

export {
  gatewaysSaga as default,
  watchGetAllGatewaysSaga,
  watchGetAllAvailableGatewaysSaga,
  watchGetGatewaySaga,
  watchCreateGatewaySaga,
  watchUpdateGatewaySaga,
  watchDeleteGatewaySaga,
  watchGetGatewayAlertsChartDataSaga,
  watchRestartGatewaySaga,
  watchGetSiteGatewaysSaga,
  watchGetGatewayAssignmentHistorySaga,
  watchGetGatewayRestartHistorySaga,
  watchGetGatewayConnectedPodsSaga,
  handleGetGatewayAssignmentHistorySaga,
  handleRestartGatewaySaga,
  handleGetAllGatewaysSaga,
  handleGetAllAvailableGatewaysSaga,
  handleGetGatewaySaga,
  handleCreateGatewaySaga,
  handleUpdateGatewaySaga,
  handleDeleteGatewaySaga,
  handleGetGatewayAlertsChartDataSaga,
  handleGetSiteGatewaysSaga,
  handleGetGatewayRestartHistorySaga,
  handleGetGatewayConnectedPodsSaga,
}
