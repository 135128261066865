import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import SearchFilter from './common/SearchFilter'
import Select, { Option } from './common/Select'
import { hasPermission } from '../utils/hasPermission'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  USER_PERMISSIONS,
  VIEW_ALL,
  FLOW_MONITOR_STATUS,
  WATER_VALVE_STATUS,
  WATER_METER_TYPES,
  SIM_CARRIERS,
} from '../constants'
import { toTitleCase, formatSIMCarrier } from '../utils/textFormatters'
import { configurePagination } from '../utils/helpers'
import LastSnapshot from './LastSnapshot'

class AllFlowMonitors extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.flowMonitors,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      status: undefined,
      siteSlug: undefined,
      assetLocationId: undefined,
      meterType: undefined,
      simType: undefined,
    }
  }

  static propTypes = {
    isSnapshotLoading: PropTypes.bool.isRequired,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    getAllFlowMonitors: PropTypes.func.isRequired,
    flowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    isFlowMonitorLoading: PropTypes.bool.isRequired,
    deleteFlowMonitor: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object),
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    updateQueryParams: PropTypes.func,
    meta: PropTypes.object,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.flowMonitors })
  }

  componentDidMount() {
    const { getAllFlowMonitors, getAllSites, getAllAssetLocations } = this.props

    const {
      page,
      perPage,
      status,
      siteSlug,
      assetLocationId,
      meterType,
      simType,
    } = parseQueryParams(global.location)
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      status,
      siteSlug,
      assetLocationId,
      meterType,
      simType,
    }

    this.setState(query)

    const allQs = createQueryString({ perPage: 'all' })

    getAllFlowMonitors(createQueryString(query))
    getAllSites(allQs)
    getAllAssetLocations(allQs)
  }

  handleChange = (pagination, filters, sorter) => {
    const { getAllFlowMonitors, updateQueryParams } = this.props
    const {
      page,
      perPage,
      status,
      siteSlug,
      assetLocationId,
      meterType,
      simType,
    } = this.state

    if (page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
        status,
        siteSlug,
        assetLocationId,
        meterType,
        simType,
      }
      const qs = createQueryString(query)

      getAllFlowMonitors(qs)
      updateQueryParams({ search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllFlowMonitors, updateQueryParams } = this.props
    const {
      page,
      perPage,
      status,
      siteSlug,
      assetLocationId,
      meterType,
      simType,
    } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    const query = Object.assign(
      {
        page,
        perPage,
        status,
        siteSlug,
        assetLocationId,
        meterType,
        simType,
      },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    const qs = createQueryString(query)
    getAllFlowMonitors(qs)
    updateQueryParams({
      search: qs,
    })
  }

  setSearchText = searchText => this.setState({ searchText })

  render() {
    const {
      isSnapshotLoading,
      snapshotData,
      getLocationSnapshot,
      deleteFlowMonitor,
      windowWidth,
      isFlowMonitorLoading,
      meta,
      sites,
      assetLocations,
      getAllFlowMonitors,
    } = this.props
    const {
      data,
      sortedInfo,
      searchText,
      page,
      perPage,
      status,
      siteSlug,
      assetLocationId,
      meterType,
      simType,
    } = this.state

    const isFixedColumn = windowWidth < 750

    const expandedRowRender = record => (
      <div className="mt2">
        {record.locationId ? (
          <LastSnapshot
            isFlowMonitor
            siteSlug={record.siteSlug}
            floorId={record.floorId}
            locationId={record.locationId}
            isSnapshotLoading={isSnapshotLoading}
            snapshotData={snapshotData}
            getLocationSnapshot={getLocationSnapshot}
          />
        ) : (
          <p className="tc">No location available.</p>
        )}
      </div>
    )

    const query = {
      page,
      perPage,
      status,
      siteSlug,
      assetLocationId,
      meterType,
      simType,
    }

    return (
      <section className="mb5 AllFlowMonitors">
        {hasPermission(USER_PERMISSIONS.ADD_FLOW_MONITOR) && (
          <Anchor
            to="/inventory/flow-monitors/create"
            button
            className="mr3 mb3 dib"
          >
            Create New
          </Anchor>
        )}
        <div className="flex-l items-center-l">
          <Select
            className="w-20-l mr3-l"
            placeholder="Site"
            mode="multiple"
            input={{
              value: siteSlug,
              onChange: this.handleFilterChange('siteSlug'),
            }}
            filterable
          >
            {[{ slug: 'all', name: VIEW_ALL }, ...sites].map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </Select>
          <Select
            className="w-20-l mr3-l"
            placeholder="Asset Location"
            mode="multiple"
            input={{
              value: assetLocationId,
              onChange: this.handleFilterChange('assetLocationId'),
            }}
            filterable
          >
            {[{ id: 'all', pillarId: VIEW_ALL }, ...assetLocations].map(x => (
              <Option value={x.id} key={x.id}>
                {x !== 'all' || x !== null ? x.pillarId : VIEW_ALL}
              </Option>
            ))}
          </Select>
          <Select
            className="w-20-l mr3-l"
            placeholder="Status"
            mode="multiple"
            input={{
              value: status,
              onChange: this.handleFilterChange('status'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...Object.keys(FLOW_MONITOR_STATUS)].map(x => (
              <Option value={x} key={x}>
                {toTitleCase(FLOW_MONITOR_STATUS[x]) || VIEW_ALL}
              </Option>
            ))}
          </Select>
          <Select
            className="w-20-l mr3-l"
            placeholder="Meter Type"
            mode="multiple"
            input={{
              value: meterType,
              onChange: this.handleFilterChange('meterType'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...Object.keys(WATER_METER_TYPES)].map(x => (
              <Option value={x} key={x}>
                {WATER_METER_TYPES[x] || VIEW_ALL}
              </Option>
            ))}
          </Select>
          <Select
            className="w-20-l"
            placeholder="Sim Type"
            mode="multiple"
            input={{
              value: simType,
              onChange: this.handleFilterChange('simType'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...Object.keys(SIM_CARRIERS)].map(x => (
              <Option value={x} key={x}>
                {formatSIMCarrier(SIM_CARRIERS[x]) || VIEW_ALL}
              </Option>
            ))}
          </Select>
        </div>
        <SearchFilter
          searchText={searchText}
          setSearchText={this.setSearchText}
          getItems={getAllFlowMonitors}
          query={query}
        />
        {isFlowMonitorLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            className="nested-table"
            dataSource={data}
            onChange={this.handleChange}
            expandedRowRender={expandedRowRender}
            rowKey="id"
            scroll={{ x: 650 }}
            bordered
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            tableLayout="auto"
            rowClassName={record =>
              record.valveId
                ? record.valveStatus === WATER_VALVE_STATUS.OPEN
                  ? 'bg-light-green'
                  : 'bg-light-red'
                : ''
            }
          >
            <Column
              fixed={'left'}
              title="Pillar ID"
              dataIndex="pillarId"
              sorter={sortBy('pillarId')}
              sortOrder={sortedInfo.field === 'pillarId' && sortedInfo.order}
              width={150}
              render={(text, { id }) =>
                hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
                  <Anchor to={`/inventory/flow-monitors/${id}`}>{text}</Anchor>
                ) : (
                  text
                )
              }
            />
            <Column
              fixed={'left'}
              title="Smart Pod"
              dataIndex="podPillarId"
              sorter={sortBy('podPillarId')}
              sortOrder={sortedInfo.field === 'podPillarId' && sortedInfo.order}
              width={150}
              render={text =>
                text ? (
                  <Anchor to={`/inventory/pods/${text}`}>{text}</Anchor>
                ) : (
                  '--'
                )
              }
            />
            <Column
              title="Site"
              dataIndex="siteName"
              sorter={sortBy('siteName')}
              sortOrder={sortedInfo.field === 'siteName' && sortedInfo.order}
              width={200}
              render={(text, { siteSlug }) =>
                siteSlug ? (
                  <Anchor to={`/sites/${siteSlug}`}>{text}</Anchor>
                ) : (
                  text
                )
              }
            />
            <Column
              title="Location"
              dataIndex="locationName"
              sorter={sortBy('locationName')}
              sortOrder={
                sortedInfo.field === 'locationName' && sortedInfo.order
              }
              width={150}
              render={(text, r) =>
                hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
                r.siteSlug &&
                r.floorId &&
                r.locationId ? (
                  <Anchor
                    to={`/sites/${r.siteSlug}/floors/${r.floorId}/locations/${r.locationId}/flow-monitor-chart`}
                  >
                    {text}
                  </Anchor>
                ) : (
                  text
                )
              }
            />
            <Column
              title="Status"
              dataIndex="status"
              sorter={sortBy('status')}
              sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
              width={150}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Pipe OD"
              dataIndex="pipeOd"
              sorter={sortBy('pipeOd')}
              sortOrder={sortedInfo.field === 'pipeOd' && sortedInfo.order}
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Pipe Max Flow"
              dataIndex="pipeMaxFlow"
              sorter={sortBy('pipeMaxFlow')}
              sortOrder={sortedInfo.field === 'pipeMaxFlow' && sortedInfo.order}
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Meter Type"
              dataIndex="meterType"
              sorter={sortBy('meterType')}
              sortOrder={sortedInfo.field === 'meterType' && sortedInfo.order}
              width={150}
              render={text => (text ? WATER_METER_TYPES[text] : '--')}
            />
            <Column
              title="Meter Serial Id"
              dataIndex="meterSerialId"
              sorter={sortBy('meterSerialId')}
              sortOrder={
                sortedInfo.field === 'meterSerialId' && sortedInfo.order
              }
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="RasPi Serial Id"
              dataIndex="piSerialId"
              sorter={sortBy('piSerialId')}
              sortOrder={sortedInfo.field === 'piSerialId' && sortedInfo.order}
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Valve Serial Id"
              dataIndex="valveSerialId"
              sorter={sortBy('valveSerialId')}
              sortOrder={
                sortedInfo.field === 'valveSerialId' && sortedInfo.order
              }
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Expected Valve Status"
              dataIndex="valveStatus"
              sorter={sortBy('valveStatus')}
              sortOrder={sortedInfo.field === 'valveStatus' && sortedInfo.order}
              width={150}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Sim Type"
              dataIndex="simType"
              sorter={sortBy('simType')}
              sortOrder={sortedInfo.field === 'simType' && sortedInfo.order}
              width={150}
              render={text =>
                text ? formatSIMCarrier(SIM_CARRIERS[text]) : '--'
              }
            />
            {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={50}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-flow-monitor">
                        <Anchor
                          to={`/inventory/flow-monitors/${record.id}/edit`}
                        >
                          Edit
                        </Anchor>
                      </Menu.Item>
                      <Menu.Item key="delete-flow-monitor">
                        <ActionAnchor
                          onClick={() => deleteFlowMonitor(record.id)}
                        >
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )
                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllFlowMonitors
