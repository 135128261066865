import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Menu, Dropdown, Icon, Table, Column, Spin } from './common/Ant'
import InputContainer from '../containers/common/InputContainer'
import { Anchor, ActionAnchor } from './common/Anchor'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'

class AllTags extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.tags,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }
  }

  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    accountProfile: PropTypes.object.isRequired,
    meta: PropTypes.object,
    getAllTags: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
    deleteTag: PropTypes.func.isRequired,
    isTagLoading: PropTypes.bool,
    updateQueryParams: PropTypes.func,
    url: PropTypes.string,
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  componentDidMount() {
    const { page, perPage } = parseQueryParams(global.location)
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
    }

    this.setState(query)

    this.props.getAllTags(createQueryString(query))
  }

  handleChange = (pagination, filters, sorter) => {
    const { url, getAllTags, updateQueryParams } = this.props
    const { page, perPage } = this.state

    if (page !== pagination.current) {
      const query = { page: pagination.current, perPage }

      const qs = createQueryString(query)
      getAllTags && getAllTags(qs)

      updateQueryParams && updateQueryParams({ pathname: url, search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllTags, updateQueryParams } = this.props
    const { page, perPage } = this.state

    const query = Object.assign(
      { page, perPage, full: 't' },
      { [keyName]: value }
    )

    this.setState({ [keyName]: value })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllTags(qs)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.tags
        .map(record => {
          const match =
            record.name.match(regex) ||
            (record.createdBy && record.createdBy.match(regex))

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  render() {
    const { windowWidth, meta, isTagLoading, deleteTag } = this.props
    const { data, sortedInfo, searchText, perPage } = this.state
    const isFixedColumn = windowWidth < 750

    return (
      <section className="mb5 AllTags">
        <div className="table-filter">
          <InputContainer
            input={{
              placeholder: 'Filter',
              value: searchText,
              onChange: this.handleInputChange,
            }}
          />
        </div>
        {isTagLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{ x: 450 }}
            bordered
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            tableLayout="auto"
          >
            <Column
              title="Name"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              width={250}
            />
            <Column
              title="Created By"
              dataIndex="createdBy"
              sorter={sortBy('createdBy')}
              sortOrder={sortedInfo.field === 'createdBy' && sortedInfo.order}
              render={(text, { createdBy }) => (createdBy ? createdBy : '--')}
              width={200}
            />
            {(hasPermission(USER_PERMISSIONS.EDIT_TAG) ||
              hasPermission(USER_PERMISSIONS.DELETE_TAG)) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={100}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      {hasPermission(USER_PERMISSIONS.EDIT_TAG) && (
                        <Menu.Item key="edit-tag">
                          <Anchor to={`/metadata/tags/${record.id}/edit`}>
                            Edit
                          </Anchor>
                        </Menu.Item>
                      )}
                      {hasPermission(USER_PERMISSIONS.DELETE_TAG) && (
                        <Menu.Item key="delete-tag">
                          <ActionAnchor onClick={() => deleteTag(record.id)}>
                            Delete
                          </ActionAnchor>
                        </Menu.Item>
                      )}
                    </Menu>
                  )

                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllTags
