import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { parse } from 'query-string'
import { decamelize } from 'humps'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import DateRange from './common/DateRange'
import Button from './common/Button'
import { Anchor } from './common/Anchor'
import Select, { Option } from './common/Select'
import ReportChart from './charts/ReportChart'
import { hasRole } from '../utils/hasPermission'
import { createQueryString } from '../utils/queryParams'
import { toTitleCase } from '../utils/textFormatters'
import { SMART_SENSOR_TYPES, ROLES } from '../constants'
import { getMultiplePodsDataAction } from '../actions/podsActions'
import { getVisibleTimeSeriesData } from '../reducers/podsReducer'

const readingOptions = Object.keys(SMART_SENSOR_TYPES).filter(
  x =>
    SMART_SENSOR_TYPES[x] !== SMART_SENSOR_TYPES.SIGNAL &&
    SMART_SENSOR_TYPES[x] !== SMART_SENSOR_TYPES.CARBON__MONOXIDE &&
    SMART_SENSOR_TYPES[x] !== SMART_SENSOR_TYPES.AIR_QUALITY_INDEX
)

const mapStateToProps = ({ pods }) => ({
  pods: getVisibleTimeSeriesData(pods),
})

const mapDispatchToProps = dispatch => ({
  getMultiplePodsData: qs =>
    dispatch(getMultiplePodsDataAction.request({ qs })),
})

class MultiplePods extends Component {
  constructor() {
    super()

    this.now = moment()

    this.state = {
      now: this.now,
      endValue: this.now,
      startValue: moment(this.now).subtract(1, 'd'),
      pillarIds: [],
      reading: SMART_SENSOR_TYPES.TEMPERATURE,
    }
  }

  static propTypes = {
    getMultiplePodsData: PropTypes.func.isRequired,
    pods: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getMultiplePodsData } = this.props
    const { startValue, endValue } = this.state

    const searchParams = parse(global.location.search)

    if (searchParams.pillar_ids) {
      const pillarIds = searchParams.pillar_ids
        ? searchParams.pillar_ids.split(',')
        : []
      const columns = searchParams.columns
        ? searchParams.columns.split(',')
        : []
      const query = {
        pillar_ids: pillarIds.join(','),
        columns: decamelize(columns[0]),
      }

      this.setState({
        pillarIds,
        reading: columns[0], // currently support selecting single reading only!
      })

      if (searchParams.from && searchParams.to) {
        query.start_time = searchParams.from
        query.end_time = searchParams.to

        this.setState({
          startValue: moment(searchParams.from * 1000),
          endValue: moment(searchParams.to * 1000),
        })
      } else {
        query.start_time = startValue.unix()
        query.end_time = endValue.unix()
      }

      getMultiplePodsData(createQueryString(query))
    }
  }

  handleCloseDateRange = (startValue, endValue) =>
    this.setState({ startValue, endValue })

  handleReadingChange = reading => this.setState({ reading })

  handleSubmit = () => {
    const { getMultiplePodsData, updateQueryParams, match } = this.props
    const { startValue, endValue, pillarIds, reading } = this.state

    const query = {
      start_time: startValue.unix(),
      end_time: endValue.unix(),
      columns: decamelize(reading),
      pillar_ids: pillarIds.join(','),
    }
    const qs = createQueryString(query)

    updateQueryParams({
      pathname: match.url,
      search: createQueryString({
        from: startValue.unix(),
        to: endValue.unix(),
        columns: reading,
        pillar_ids: pillarIds.join(','),
      }),
    })

    getMultiplePodsData(qs)
  }

  render() {
    const { pods, isChartDataLoading } = this.props
    const { startValue, endValue, reading } = this.state

    return (
      <section className="MultiplePods">
        <div className="mb3">
          <Bread>
            <Crumb>
              <Anchor to="/inventory/all">Inventory</Anchor>
            </Crumb>
            <Crumb>
              <Anchor to={`/inventory/all/pods`}>Smart Pods</Anchor>
            </Crumb>
            <Crumb>Compare</Crumb>
          </Bread>
        </div>
        <Divider />
        <div className="flex-ns justify-between-ns">
          <Select
            className="w-40-ns mr3-ns"
            label="Select sensor reading to plot"
            placeholder="Sensor reading"
            input={{
              value: reading,
              onChange: this.handleReadingChange,
            }}
            filterable
          >
            {readingOptions.map(x => (
              <Option value={SMART_SENSOR_TYPES[x]} key={SMART_SENSOR_TYPES[x]}>
                {toTitleCase(x)}
              </Option>
            ))}
          </Select>
          <div>
            <DateRange
              label="Select date range"
              startValue={startValue}
              endValue={endValue}
              onClose={this.handleCloseDateRange}
              shouldLimitRange={!hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN)}
            />
          </div>
        </div>
        <div className="flex-ns justify-end-ns mb3">
          <Button onClick={this.handleSubmit} text="Submit" />
        </div>
        <ReportChart
          locations={pods}
          reading={reading}
          startValue={startValue}
          endValue={endValue}
          isChartDataLoading={isChartDataLoading}
        />
      </section>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiplePods)
