import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Anchor } from './common/Anchor'
import { Table, Column, Spin, Tooltip, Icon } from './common/Ant'
import Divider from './common/Divider'
import Select, { Option } from './common/Select'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import moment from 'moment'
import cx from 'classnames'
import { H4 } from './common/Headers'
import Checkbox from './common/Checkbox'
import { dateFormatter, dateTimeFormatter } from '../utils/date'
import DateRange from './common/DateRange'
import Input from './common/Input'
import { RI_METRICS_TYPES, COLORS } from '../constants'
import { toTitleCase } from '../utils/textFormatters'

const DATE_RANGE_OPTIONS = {
  LAST_HOUR: {
    label: 'Last Hour',
    value: 'LAST_HOUR',
  },
  LAST_DAY: {
    label: 'Last Day',
    value: 'LAST_DAY',
  },
  LAST_WEEK: {
    label: 'Last Week',
    value: 'LAST_WEEK',
  },
  LAST_MONTH: {
    label: 'Last Month',
    value: 'LAST_MONTH',
  },
  CUSTOM: {
    label: 'Custom',
    value: 'CUSTOM',
  },
}

const RiMetricsChartToolip = ({ active, payload, label }) => {
  return active ? (
    <div className="pa2 bg-white chart-tooltip ba b--moon-gray br1 shadow-4">
      <div className="f7 b mb2">{dateTimeFormatter(label, true)}</div>
      {payload && payload.length && (
        <ul className="pl0 mb0 list">
          {payload.map(x => (
            <li key={x.name} className="flex items-center">
              <div
                className="mr2"
                style={{ height: 8, width: 8, backgroundColor: x.stroke }}
              />
              <div className="flex w-100 justify-between">
                <div className="pr2">{x.name}</div>
                <div>{x.value ? parseFloat(x.value).toFixed(2) : 0}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null
}

RiMetricsChartToolip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  payload: PropTypes.array,
  active: PropTypes.bool,
}

class RiMetrics extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isRolling: false,
      dateRange: 'LAST_HOUR',
      startValue: moment().subtract(1, 'hour'),
      endValue: moment(),
      timePeriod: 120,
      timeSlack: 80,
      sortedInfo: {},
      data: this.props.riMetrics,
      reading: 'reliabilityMetrics.successRate',
    }
  }

  static propTypes = {
    riMetrics: PropTypes.arrayOf(PropTypes.object).isRequired,
    getRiMetrics: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    siteSlug: PropTypes.string.isRequired,
    buildingId: PropTypes.string,
    floorId: PropTypes.string,
    updateQueryParams: PropTypes.func.isRequired,
    locationId: PropTypes.string,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.riMetrics })
  }

  componentDidMount() {
    const {
      getRiMetrics,
      siteSlug,
      buildingId,
      floorId,
      updateQueryParams,
      locationId,
    } = this.props

    let { startValue, endValue, isRolling } = this.state

    const { dateRange, from, to, timeSlack, timePeriod } = parseQueryParams(
      global.location
    )

    startValue = from ? from : parseInt(startValue.valueOf() / 1000, 10)
    endValue = to ? to : parseInt(endValue.valueOf() / 1000, 10)
    const nextTimePeriod = timePeriod ? timePeriod : this.state.timePeriod
    const nextTimeSlack = timeSlack ? timeSlack : this.state.timeSlack

    this.setState({
      dateRange: dateRange ? dateRange : this.state.dateRange,
      startValue: moment(startValue * 1000),
      endValue: moment(endValue * 1000),
      timePeriod: nextTimePeriod,
      timeSlack: nextTimeSlack,
    })

    const qs = createQueryString({
      dateRange,
      from: startValue,
      to: endValue,
      id: locationId,
      buildingId,
      floorId,
      rolling: isRolling,
      timePeriod: nextTimePeriod,
      timeSlack: nextTimeSlack,
    })
    getRiMetrics(siteSlug, qs)
    updateQueryParams({
      search: createQueryString({
        from: startValue,
        to: endValue,
        dateRange: dateRange ? dateRange : this.state.dateRange,
        timePeriod: nextTimePeriod,
        timeSlack: nextTimeSlack,
      }),
    })
  }

  handleChange = keyName => value => {
    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value
    this.setState({ [keyName]: newValue })

    const {
      updateQueryParams,
      getRiMetrics,
      siteSlug,
      buildingId,
      floorId,
      locationId,
    } = this.props

    let {
      dateRange,
      startValue,
      endValue,
      isRolling,
      timePeriod,
      timeSlack,
    } = this.state

    if (keyName === 'dateRange') {
      switch (value) {
        case DATE_RANGE_OPTIONS.LAST_HOUR.value:
          startValue = moment().subtract(1, 'hour')
          endValue = moment()
          break
        case DATE_RANGE_OPTIONS.LAST_DAY.value:
          startValue = moment().subtract(1, 'day')
          endValue = moment()
          break
        case DATE_RANGE_OPTIONS.LAST_WEEK.value:
          startValue = moment().subtract(1, 'week')
          endValue = moment()
          break
        case DATE_RANGE_OPTIONS.LAST_MONTH.value:
          startValue = moment().subtract(1, 'month')
          endValue = moment()
          break
      }

      this.setState({
        startValue,
        endValue,
      })
    }

    const query = Object.assign(
      {
        from: parseInt(startValue.valueOf() / 1000, 10),
        to: parseInt(endValue.valueOf() / 1000, 10),
        id: locationId,
        buildingId,
        floorId,
        rolling: isRolling,
        dateRange,
        timeSlack,
        timePeriod,
      },
      { [keyName]: newValue }
    )
    const qs = createQueryString(query)
    getRiMetrics(siteSlug, qs)

    const updateQuery = Object.assign(
      {
        from: parseInt(startValue.valueOf() / 1000, 10),
        to: parseInt(endValue.valueOf() / 1000, 10),
        dateRange,
        timePeriod,
        timeSlack,
      },
      { [keyName]: newValue }
    )
    updateQueryParams({
      search: createQueryString(updateQuery),
    })
  }

  handleReadingChange = reading => this.setState({ reading })

  handleCloseDateRange = (startValue, endValue) => {
    const {
      updateQueryParams,
      getRiMetrics,
      siteSlug,
      buildingId,
      floorId,
      locationId,
    } = this.props
    const { dateRange, isRolling } = this.state

    this.setState({ startValue, endValue })

    const qs = createQueryString({
      from: parseInt(startValue.valueOf() / 1000, 10),
      to: parseInt(endValue.valueOf() / 1000, 10),
      id: locationId,
      buildingId,
      floorId,
      rolling: isRolling,
      dateRange,
    })

    getRiMetrics(siteSlug, qs)
    updateQueryParams({
      search: createQueryString({
        from: parseInt(startValue.valueOf() / 1000, 10),
        to: parseInt(endValue.valueOf() / 1000, 10),
        dateRange,
      }),
    })
  }

  handleSortChange = (pagination, filter, sorter) =>
    this.setState({ sortedInfo: sorter })

  handleRollingChange = value => {
    this.setState({ isRolling: value })

    const {
      getRiMetrics,
      siteSlug,
      buildingId,
      floorId,
      updateQueryParams,
      locationId,
    } = this.props

    let { startValue, endValue } = this.state

    const { dateRange, from, to, timeSlack, timePeriod } = parseQueryParams(
      global.location
    )

    startValue = from ? from : parseInt(startValue.valueOf() / 1000, 10)
    endValue = to ? to : parseInt(endValue.valueOf() / 1000, 10)
    const nextTimePeriod = timePeriod ? timePeriod : this.state.timePeriod
    const nextTimeSlack = timeSlack ? timeSlack : this.state.timeSlack

    this.setState({
      dateRange: dateRange ? dateRange : this.state.dateRange,
      startValue: moment(startValue * 1000),
      endValue: moment(endValue * 1000),
      timePeriod: nextTimePeriod,
      timeSlack: nextTimeSlack,
    })

    const qs = createQueryString({
      dateRange,
      from: startValue,
      to: endValue,
      id: locationId,
      buildingId,
      floorId,
      rolling: value,
      timePeriod: nextTimePeriod,
      timeSlack: nextTimeSlack,
    })
    getRiMetrics(siteSlug, qs)
    updateQueryParams({
      search: createQueryString({
        from: startValue,
        to: endValue,
        dateRange,
        timePeriod: nextTimePeriod,
        timeSlack: nextTimeSlack,
      }),
    })
  }

  render() {
    const { isLoading } = this.props

    const {
      startValue,
      endValue,
      dateRange,
      data,
      sortedInfo,
      isRolling,
      reading,
      timePeriod,
      timeSlack,
    } = this.state

    const isCustom = dateRange === DATE_RANGE_OPTIONS['CUSTOM'].value

    const chartOptions = {
      credits: { enabled: false },
      chart: {
        style: {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
        },
      },
      plotOptions: {
        series: {
          type: 'line',
        },
        line: {
          animation: false,
        },
      },
      title: {
        text: null,
      },
      yAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        labels: {
          style: {
            color: COLORS.BLACK_80,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        type: 'datetime',
        labels: {
          style: {
            color: COLORS.BLACK_80,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      tooltip: {
        valueDecimals: 2,
        headerFormat:
          "<span style='font-size: 0.875rem; margin-bottom: 0.875rem'>{point.key}</span><br />",
        style: {
          fontSize: '0.875rem',
          color: COLORS.BLACK_80,
        },
      },
      legend: {
        verticalAlign: 'top',
        itemStyle: {
          fontSize: '0.875rem',
          color: COLORS.BLACK_80,
        },
        itemHoverStyle: {
          color: COLORS.MOON_GRAY,
        },
      },
      series: data.map(x => {
        return {
          name: x.locationInfo,
          data: x.data.map(y => {
            return [
              new Date(y.time).valueOf(),
              y.reliabilityMetrics[reading.split('.')[1]],
            ]
          }),
        }
      }),
    }

    return (
      <div className="RiMetrics">
        <div className="flex flex-wrap flex-nowrap-ns">
          <Select
            label="Select date range"
            placeholder="Select date range"
            input={{
              value: dateRange,
              onChange: value => this.handleChange('dateRange')(value),
            }}
            className={cx('w-100', { 'w-30-ns mr3-ns': isCustom })}
            filterable
          >
            {Object.keys(DATE_RANGE_OPTIONS).map(keyName => (
              <Option
                value={DATE_RANGE_OPTIONS[keyName].value}
                key={DATE_RANGE_OPTIONS[keyName].value}
              >
                {DATE_RANGE_OPTIONS[keyName].label}
              </Option>
            ))}
          </Select>
          {isCustom && (
            <DateRange
              className="w-100 w-50-ns"
              label="Custom date range"
              startValue={startValue}
              endValue={endValue}
              onClose={this.handleCloseDateRange}
            />
          )}
        </div>
        <div className="flex flex-wrap flex-nowrap-ns">
          <div className="mr3">
            <Input
              type="number"
              input={{
                value: timeSlack,
                onChange: value => this.handleChange('timeSlack')(value),
              }}
              label="Time Slack"
            />
          </div>
          <div>
            <Input
              type="number"
              label="Time Period"
              input={{
                value: timePeriod,
                onChange: value => this.handleChange('timePeriod')(value),
              }}
            />
          </div>
        </div>
        <Divider />
        <H4 className="flex-ns justify-between">
          {dateFormatter(startValue.toISOString())} &mdash;{' '}
          {dateFormatter(endValue.toISOString())}{' '}
          {isRolling && (
            <Select
              className="w-40-ns mr3-ns"
              label="Select a metric to plot"
              placeholder="Sensor reading"
              input={{
                value: reading,
                onChange: this.handleReadingChange,
              }}
              filterable
            >
              {Object.keys(RI_METRICS_TYPES).map(x => (
                <Option value={RI_METRICS_TYPES[x]} key={RI_METRICS_TYPES[x]}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Select>
          )}
          <span>
            <Checkbox
              type="checkbox"
              label="Rolling"
              input={{
                checked: isRolling,
                onChange: this.handleRollingChange,
              }}
              noMargin
            />
          </span>
        </H4>
        {isLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : data.length > 0 && !isRolling ? (
          <Fragment>
            <div>
              <Table
                dataSource={data}
                onChange={this.handleSortChange}
                rowKey="locationId"
                scroll={{ x: 850 }}
                bordered
                tableLayout="auto"
                pagination={false}
              >
                <Column
                  title="Location"
                  width={60}
                  dataIndex="locationName"
                  fixed={'left'}
                  sorter={sortBy('locationName')}
                  sortOrder={
                    sortedInfo.field === 'locationName' && sortedInfo.order
                  }
                  render={(text, record) =>
                    text ? (
                      <Anchor
                        to={`/sites/${record.siteSlug}/floors/${record.floorId}/locations/${record.locationId}`}
                      >
                        {text}
                      </Anchor>
                    ) : (
                      '--'
                    )
                  }
                />
                <Column
                  title="Smart Pod"
                  width={60}
                  dataIndex="pillarId"
                  fixed={'left'}
                  sorter={sortBy('pillarId')}
                  sortOrder={
                    sortedInfo.field === 'pillarId' && sortedInfo.order
                  }
                  render={(text, record) =>
                    text ? (
                      <Anchor to={`/inventory/pods/${text}`}>{text}</Anchor>
                    ) : (
                      '--'
                    )
                  }
                />
                <Column
                  title={
                    <span>
                      Success Rate{' '}
                      <Tooltip title="Probabilty of signal arriving in valid timeframe">
                        <Icon type="info-circle-o" />
                      </Tooltip>
                    </span>
                  }
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', 'successRate']}
                  fixed={'left'}
                  sorter={sortBy('data[0].reliabilityMetrics.successRate')}
                  sortOrder={
                    sortedInfo.field ===
                      'data[0].reliabilityMetrics.successRate' &&
                    sortedInfo.order
                  }
                  render={(text, record) =>
                    text ? parseFloat(text).toFixed(2) : '--'
                  }
                />
                <Column
                  title="Total Readings"
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', 'totalSignals']}
                  sorter={sortBy('data[0].reliabilityMetrics.totalSignals')}
                  sortOrder={
                    sortedInfo.field ===
                      'data[0].reliabilityMetrics.totalSignals' &&
                    sortedInfo.order
                  }
                  render={(text, record) => (text ? text : '--')}
                />
                <Column
                  title="Delayed Readings"
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', 'unmetSignals']}
                  render={(text, record) => (text ? text : '--')}
                />
                <Column
                  title={
                    <span>
                      On-Time Rate{' '}
                      <Tooltip title="Percentage of readings arriving on-time (100 - (100 * Delayed) / Total Readings)">
                        <Icon type="info-circle-o" />
                      </Tooltip>
                    </span>
                  }
                  sorter={sortBy('data[0].reliabilityMetrics.%SignalsUnmet')}
                  sortOrder={
                    sortedInfo.field ===
                      'data[0].reliabilityMetrics.%SignalsUnmet' &&
                    sortedInfo.order
                  }
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', '%SignalsUnmet']}
                  render={(text, record) =>
                    text ? parseFloat(text).toFixed(2) : '--'
                  }
                />
                <Column
                  title={
                    <span>
                      Grouped Mode Rate{' '}
                      <Tooltip title="Percentage of readings arriving in grouped mode">
                        <Icon type="info-circle-o" />
                      </Tooltip>
                    </span>
                  }
                  sorter={sortBy(
                    'data[0].reliabilityMetrics.%TotalGroupModeSignals'
                  )}
                  sortOrder={
                    sortedInfo.field ===
                      'data[0].reliabilityMetrics.%TotalGroupModeSignals' &&
                    sortedInfo.order
                  }
                  width={100}
                  dataIndex={[
                    'data',
                    0,
                    'reliabilityMetrics',
                    '%TotalGroupModeSignals',
                  ]}
                  render={(text, record) =>
                    text ? parseFloat(text).toFixed(2) : '--'
                  }
                />
                <Column
                  title={
                    <span>
                      Delay (s){' '}
                      <Tooltip title="Delay in seconds (Sum of delay per reading above 200 seconds)">
                        <Icon type="info-circle-o" />
                      </Tooltip>
                    </span>
                  }
                  width={100}
                  dataIndex={[
                    'data',
                    0,
                    'reliabilityMetrics',
                    'timeDelayed(s)',
                  ]}
                  render={(text, record) => (text ? text : '--')}
                />
                <Column
                  title={
                    <span>
                      Uptime{' '}
                      <Tooltip title="Percentage of time with no delays (100 - (100 * Total Delay) / Total Time)">
                        <Icon type="info-circle-o" />
                      </Tooltip>
                    </span>
                  }
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', '%TimeDelayed']}
                  render={(text, record) =>
                    text ? parseFloat(text).toFixed(2) : '--'
                  }
                />
                <Column
                  title="Max Delay (s)"
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', 'maxDelay']}
                  render={(text, record) => (text ? text : '--')}
                />
                <Column
                  title="Mean Delay (s)"
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', 'meanDelay']}
                  render={(text, record) =>
                    text ? parseFloat(text).toFixed(2) : '--'
                  }
                />
                <Column
                  title="Median Delay (s)"
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', 'medianDelay']}
                  render={(text, record) => (text ? text : '--')}
                />
                <Column
                  title={
                    <span>
                      Delay Standard Deviation (s){' '}
                      <Tooltip title="Standard deviation of delay in seconds">
                        <Icon type="info-circle-o" />
                      </Tooltip>
                    </span>
                  }
                  width={100}
                  dataIndex={['data', 0, 'reliabilityMetrics', 'stdDelay']}
                  render={(text, record) =>
                    text ? parseFloat(text).toFixed(2) : '--'
                  }
                />
              </Table>
            </div>
          </Fragment>
        ) : data.length > 0 && isRolling ? (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        ) : (
          <div className="flex items-center justify-center h5">
            No data for selected time range or query too large.
          </div>
        )}
      </div>
    )
  }
}

export default RiMetrics
