import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ActionAnchor } from './common/Anchor'
import Divider from './common/Divider'
import { toTitleCase, renderReportType } from '../utils/textFormatters'

const ReportCSVDownload = ({ siteName, pages, columns, reportType }) => {
  const handleDownload = () => {
    const reportTypeFormatted = renderReportType(reportType)
    let csv = `Site,Report Type,Page Name,Location,Time,${columns.map(x =>
      x.toString()
    )}\n`
    pages.forEach(page => {
      const pageContainsSensorData =
        page.items.filter(x => x.data.length > 0).length > 0

      if (pageContainsSensorData) {
        const pageName = `${
          page.buildingName ? `${page.buildingName} - ` : ''
        }${page.name}${
          page.locationType
            ? ` (${toTitleCase(page.locationType)}${
                page.items.length > 1 ? 's' : ''
              })`
            : ''
        }`

        page.items.forEach(x => {
          x.data.forEach(y => {
            csv += `${siteName},${reportTypeFormatted},${pageName},${x.label},${
              y.time
            },${columns.map(z => y[z])}\n`
          })
        })
      }
    })

    let fileName = `${siteName} — ${reportTypeFormatted} — ${moment().toISOString()}.pdf`
    const encodedCsv = global.encodeURI(csv)

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodedCsv
    hiddenElement.target = '_blank'
    hiddenElement.download = `${fileName}.csv`
    hiddenElement.click()
  }

  return (
    <section className="ReportCSVDownload">
      <Divider vertical />
      <span>
        <ActionAnchor onClick={handleDownload}>Download CSV</ActionAnchor>
      </span>
    </section>
  )
}

ReportCSVDownload.propTypes = {
  siteName: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ReportCSVDownload
