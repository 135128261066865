import React from 'react'
import EditAccountPasswordForm from './forms/EditAccountPasswordForm'
import Divider from './common/Divider'
import { H2 } from './common/Headers'
import { Anchor } from './common/Anchor'

const EditAccountPassword = props => (
  <section className="EditAccount">
    <H2>
      Change Password
      <small>
        <Divider vertical />
        <Anchor to="/account/profile">Edit Profile</Anchor>
        <Divider vertical />
        <Anchor to="/account/settings">Edit Settings</Anchor>
      </small>
    </H2>
    <EditAccountPasswordForm />
  </section>
)

export default EditAccountPassword
