import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SiteHeader from './SiteHeader'
import Buildings from './Buildings'
import FloorPlan from './FloorPlan'
import AllThresholds from './AllThresholds'
import { Bread, Crumb } from './common/Bread'
import { Modal, Icon } from './common/Ant'
import { ActionAnchor } from './common/Anchor'
import { unique } from '../utils/helpers'
import { KEYCODES } from '../constants'

class UserDashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      floorSlugs: this.props.floors.map(x => x.slug),
      currentFloorIndex: 0,
      currentFloor: this.props.floors[0] || {},
      isModalVisible: false,
    }
  }

  static propTypes = {
    getDashboard: PropTypes.func.isRequired,
    getAllLocationsByFloor: PropTypes.func.isRequired,
    getAllFloorSummaries: PropTypes.func.isRequired,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    getAllGatewaysBySite: PropTypes.func.isRequired,
    defaultSiteSlug: PropTypes.string.isRequired,
    currentSite: PropTypes.object.isRequired,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    floors: PropTypes.arrayOf(PropTypes.object).isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteGateways: PropTypes.arrayOf(PropTypes.object),
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    isFloorLoading: PropTypes.bool.isRequired,
    isThresholdLoading: PropTypes.bool.isRequired,
    isLocationLoading: PropTypes.bool.isRequired,
    pushToPath: PropTypes.func.isRequired,
    match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  }

  componentDidMount() {
    const { defaultSiteSlug, getDashboard, getAllFloorSummaries } = this.props
    getAllFloorSummaries(defaultSiteSlug)
    getDashboard(defaultSiteSlug)

    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.floors.length && nextProps.floors.length) {
      this.setState({
        currentFloor: nextProps.floors[0],
        floorSlugs: nextProps.floors.map(x => x.slug),
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.floors.length &&
      this.props.match.params.floorSlug &&
      !this.state.isModalVisible
    ) {
      this.handleModalOpen(this.props.match.params.floorSlug)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleModalOpen = slug => {
    const index = this.state.floorSlugs.indexOf(slug)
    const currentFloor = this.props.floors[index]

    this.props.pushToPath(currentFloor.slug)

    if (currentFloor) {
      this.setState({
        isModalVisible: true,
        currentFloorIndex: index,
        currentFloor,
      })
    }
  }

  handleModalCancel = () => {
    this.props.pushToPath()
    this.setState({
      isModalVisible: false,
    })
  }

  handlePrevFloor = () => {
    const index = this.state.currentFloorIndex - 1

    if (index >= 0) {
      const currentFloor = this.props.floors[index]
      this.props.pushToPath(currentFloor.slug)
      this.setState({
        currentFloor,
        currentFloorIndex: index,
      })
    }
  }

  handleNextFloor = () => {
    const index = this.state.currentFloorIndex + 1

    if (index < this.props.floors.length) {
      const currentFloor = this.props.floors[index]
      this.props.pushToPath(currentFloor.slug)
      this.setState({
        currentFloor,
        currentFloorIndex: index,
      })
    }
  }

  handleKeyDown = ({ keyCode }) => {
    if (keyCode === KEYCODES.ARROW_RIGHT) {
      this.handleNextFloor()
    } else if (keyCode === KEYCODES.ARROW_LEFT) {
      this.handlePrevFloor()
    }
  }

  render() {
    const {
      defaultSiteSlug,
      currentSite,
      buildings,
      floors,
      locations,
      siteGateways,
      accountProfile,
      getAllLocationsByFloor,
      snapshotData,
      getLocationSnapshot,
      getAllGatewaysBySite,
      windowWidth,
      isFloorLoading,
      isThresholdLoading,
      isLocationLoading,
      match,
    } = this.props
    const { currentFloor, isModalVisible } = this.state

    const hasBuildings =
      floors
        .map(({ buildingId }) => buildingId)
        .filter(x => x)
        .filter(unique).length > 0

    return (
      <section className="UserDashboard">
        <SiteHeader site={currentSite} floors={floors} />
        <Buildings
          buildings={buildings}
          floors={floors}
          siteSlug={defaultSiteSlug}
          handleModalOpen={this.handleModalOpen}
          isFloorLoading={isFloorLoading}
          hasBuildings={hasBuildings}
          match={match}
        />
        <AllThresholds
          thresholds={currentSite.thresholds}
          windowWidth={windowWidth}
          isThresholdLoading={isThresholdLoading}
          isRenderingDashboardView
        />
        <Modal
          className="Modal Modal--dark"
          visible={isModalVisible}
          footer={null}
          width={windowWidth - 32}
          onCancel={this.handleModalCancel}
          style={{ top: 16 }}
          destroyOnClose
        >
          <div className="Modal--dark-header mb3">
            <Bread>
              <Crumb>{currentSite.name}</Crumb>
              <Crumb>
                {currentFloor.name} ({currentFloor.floor})
              </Crumb>
            </Bread>
          </div>
          <FloorPlan
            siteSlug={defaultSiteSlug}
            floorId={currentFloor.id}
            getAllLocationsByFloor={getAllLocationsByFloor}
            getAllGatewaysBySite={getAllGatewaysBySite}
            snapshotData={snapshotData}
            getLocationSnapshot={getLocationSnapshot}
            currentFloor={currentFloor}
            locations={locations}
            siteGateways={siteGateways}
            isLocationLoading={isLocationLoading}
            accountProfile={accountProfile}
          />
          <div className="paginate flex justify-between absolute">
            <div className="z-9999">
              {this.state.currentFloorIndex > 0 && (
                <ActionAnchor onClick={this.handlePrevFloor}>
                  <Icon type="left" className="f1 light-gray hover-dark-blue" />
                </ActionAnchor>
              )}
            </div>
            <div className="z-9999">
              {this.state.currentFloorIndex < this.props.floors.length - 1 && (
                <ActionAnchor onClick={this.handleNextFloor}>
                  <Icon
                    type="right"
                    className="f1 light-gray hover-dark-blue"
                  />
                </ActionAnchor>
              )}
            </div>
          </div>
        </Modal>
      </section>
    )
  }
}

export default UserDashboard
