import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { H2, H3 } from './common/Headers'
import { ActionAnchor, Anchor } from './common/Anchor'
import { Table, Column } from './common/Ant'
import OTAFirmwareUpdateForm from './forms/OTAFirmwareUpdateForm'
import { dateTimeFormatter } from '../utils/date'

class OTA extends Component {
  static propTypes = {
    otaLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    firmwareVersions: PropTypes.arrayOf(PropTypes.string).isRequired,
    getAllPods: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    getAllFirmwareVersions: PropTypes.func.isRequired,
    getAllOTALogs: PropTypes.func.isRequired,
    setOTAPodIds: PropTypes.func.isRequired,
    otaFormValues: PropTypes.object,
  }

  state = {
    isFormCollapsed: true,
    isDetailCollapsed: true,
  }

  componentDidMount() {
    this.props.getAllOTALogs()
    this.props.getAllFirmwareVersions()
    this.props.getAllSites()
  }

  handleToggleForm = () => {
    this.setState({
      isFormCollapsed: !this.state.isFormCollapsed,
      isDetailCollapsed: true,
    })
  }

  renderForm = () => {
    const {
      getAllPods,
      setOTAPodIds,
      firmwareVersions,
      sites,
      otaFormValues,
    } = this.props
    const { isFormCollapsed } = this.state

    return (
      !isFormCollapsed && (
        <div className="ba br2 b--light-gray pt3 ph3 mb3">
          <H3>Update Smart Pod Firmware</H3>
          <OTAFirmwareUpdateForm
            sites={sites}
            getAllPods={getAllPods}
            setOTAPodIds={setOTAPodIds}
            firmwareVersions={firmwareVersions}
            initialValues={{ podIds: [] }}
            handleCancel={this.handleToggleForm}
            formValues={otaFormValues}
          />
        </div>
      )
    )
  }

  render() {
    const { otaLogs } = this.props

    return (
      <section className="OTA mb5">
        <div>
          <H2 inline>OTA Update Log</H2>
          <ActionAnchor onClick={this.handleToggleForm} button className="mr3">
            Perform OTA Update
          </ActionAnchor>
        </div>
        {this.renderForm()}
        <Table
          dataSource={otaLogs}
          rowKey="slug"
          scroll={{ x: 650 }}
          bordered
          tableLayout="auto"
        >
          <Column
            title="Slug"
            width={100}
            dataIndex="slug"
            render={(text, record) => (
              <Anchor to={`/inventory/ota/${record.slug}`} className="pl2">
                {text}
              </Anchor>
            )}
          />
          <Column
            title="Status"
            width={100}
            dataIndex="status"
            render={(text, record) => {
              if (record.active) {
                return <span className="orange">Active</span>
              } else if (record.successful) {
                return <span className="green">Successful</span>
              }

              return <span className="red b">Failed</span>
            }}
          />
          <Column
            title="Start Time"
            dataIndex="startTime"
            width={100}
            render={text => dateTimeFormatter(text)}
          />
          <Column
            title="End Time"
            dataIndex="endTime"
            width={100}
            render={text => dateTimeFormatter(text)}
          />
          <Column
            title="Firmware Version"
            dataIndex="firmwareVersion"
            width={100}
          />
          <Column
            title="Triggered By"
            dataIndex="userFirstName"
            width={100}
            render={(text, record) =>
              `${record.userFirstName} ${record.userLastName}`
            }
          />
        </Table>
      </section>
    )
  }
}

export default OTA
