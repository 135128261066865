import produce from 'immer'
import { combineReducers } from 'redux'
import moment from 'moment'
import {
  getAllAlertSummariesAction,
  getAlertSummaryAction,
} from '../actions/alertSummariesActions'

const bySlug = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAlertSummariesAction.SUCCESS:
        payload.items.forEach(x => (draft[x.slug] = x))
        break
      case getAlertSummaryAction.SUCCESS:
        draft[payload.slug] = {
          ...payload,
          scheduledTime: moment(payload.scheduledTime, 'hh:mm:ssZ'),
        }
        break
    }
  })

const visibleSlugs = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAlertSummariesAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.items.map(x => x.slug))
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAlertSummariesAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const alertSummariesReducer = combineReducers({
  bySlug,
  visibleSlugs,
  meta,
})

const getAlertSummary = (state, slug) => state.bySlug[slug] || {}
const getVisibleAlertSummaries = state =>
  state.visibleSlugs.map(slug => getAlertSummary(state, slug))
const getAlertSummariesMeta = state => state.meta

export {
  alertSummariesReducer as default,
  getVisibleAlertSummaries,
  getAlertSummary,
  getAlertSummariesMeta,
}
