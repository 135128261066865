import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from './Button'
import { getPreviousRouterLocation } from '../../reducers/routerLocationHistoryReducer'

const mapStateToProps = ({ routerLocationHistory }) => ({
  prevLocation: getPreviousRouterLocation(routerLocationHistory),
})

const CancelButton = ({ text, defaultLocation, prevLocation }) => {
  const to = prevLocation ? prevLocation.location.pathname : defaultLocation

  return <Button invert text={text} to={to} />
}

CancelButton.propTypes = {
  defaultLocation: PropTypes.string.isRequired,
  text: PropTypes.string,
  prevLocation: PropTypes.object,
}

CancelButton.defaultProps = {
  text: 'Cancel',
}

export default connect(mapStateToProps)(CancelButton)
