import { fork, call, takeEvery, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllAlertSummariesAction,
  getAlertSummaryAction,
  createAlertSummaryAction,
  updateAlertSummaryAction,
  deleteAlertSummaryAction,
} from '../actions/alertSummariesActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllAlertSummariesSaga({ payload }) {
  try {
    const result = yield call(api.getAllAlertSummaries, payload)
    yield put(getAllAlertSummariesAction.success(result))
  } catch (error) {
    yield put(getAllAlertSummariesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAlertSummaryBySlugSaga({ payload }) {
  try {
    const result = yield call(api.getAlertSummaryBySlug, payload)
    yield put(getAlertSummaryAction.success(result))
  } catch (error) {
    yield put(getAlertSummaryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateAlertSummarySaga({ payload }) {
  try {
    const result = yield call(api.createAlertSummary, payload)

    yield put(createAlertSummaryAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/reports/alert-summaries`))
  } catch (error) {
    yield put(createAlertSummaryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateAlertSummarySaga({ payload }) {
  try {
    yield call(api.updateAlertSummary, payload)
    yield put(updateAlertSummaryAction.success())
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/reports/alert-summaries/${payload.slug}`))
  } catch (error) {
    yield put(updateAlertSummaryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteAlertSummarySaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteAlertSummary, payload)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))

      yield put(getAllAlertSummariesAction.request())
    }
  } catch (error) {
    yield put(deleteAlertSummaryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllAlertSummariesSaga() {
  yield takeEvery(
    getAllAlertSummariesAction.REQUEST,
    handleGetAllAlertSummariesSaga
  )
}

function* watchGetAlertSummaryBySlugSaga() {
  yield takeEvery(
    getAlertSummaryAction.REQUEST,
    handleGetAlertSummaryBySlugSaga
  )
}

function* watchCreateAlertSummarySaga() {
  yield takeEvery(
    createAlertSummaryAction.REQUEST,
    handleCreateAlertSummarySaga
  )
}

function* watchUpdateAlertSummarySaga() {
  yield takeEvery(
    updateAlertSummaryAction.REQUEST,
    handleUpdateAlertSummarySaga
  )
}

function* watchDeleteAlertSummarySaga() {
  yield takeEvery(
    deleteAlertSummaryAction.REQUEST,
    handleDeleteAlertSummarySaga
  )
}

function* alertSummariesSaga() {
  yield all([
    fork(watchGetAllAlertSummariesSaga),
    fork(watchGetAlertSummaryBySlugSaga),
    fork(watchCreateAlertSummarySaga),
    fork(watchUpdateAlertSummarySaga),
    fork(watchDeleteAlertSummarySaga),
  ])
}

export {
  alertSummariesSaga as default,
  watchGetAllAlertSummariesSaga,
  watchCreateAlertSummarySaga,
  watchUpdateAlertSummarySaga,
  watchDeleteAlertSummarySaga,
  handleGetAllAlertSummariesSaga,
  handleCreateAlertSummarySaga,
  handleUpdateAlertSummarySaga,
  handleDeleteAlertSummarySaga,
}
