import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '../../constants'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more.js'
import solidGauge from 'highcharts/modules/solid-gauge.js'
import HighchartsReact from 'highcharts-react-official'
// init the module
highchartsMore(Highcharts)
solidGauge(Highcharts)

const ImuRotationChart = ({ cableOccupancy }) => {
  const gaugeOptions = {
    credits: { enabled: false },
    exporting: { enabled: false },
    chart: {
      type: 'solidgauge',
      style: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
      },
    },

    pane: {
      center: ['50%', '50%'],
      size: '270px',
      startAngle: 0,
      endAngle: 360,
      background: {
        backgroundColor: COLORS.LIGHT_GRAY,
        innerRadius: '60%',
        outerRadius: '100%',
        borderWidth: 0,
      },
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      minorTickInterval: 10,
      tickInterval: 20,
      tickWidth: 0,
    },

    plotOptions: {
      solidgauge: {
        innerRadius: '60%',
      },
    },
    title: {
      text: `Cable<br>occupancy<br>${parseFloat(cableOccupancy).toFixed(2)} %`,
      align: 'center',
      verticalAlign: 'middle',
      style: {
        color: COLORS.BLUE,
        fontSize: '1.3rem',
        fontWeight: 'bold',
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        data: [parseFloat(cableOccupancy)],
        size: '100%',
        innerSize: '60%',
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  }

  return (
    <div className="ImuRotationChart">
      <HighchartsReact highcharts={Highcharts} options={gaugeOptions} />
    </div>
  )
}

ImuRotationChart.propTypes = {
  cableOccupancy: PropTypes.string.isRequired,
}

export default ImuRotationChart
