import { createFormAction } from 'redux-form-saga'

const createReportAction = createFormAction('CREATE_REPORT')
const getAllReportsAction = createFormAction('GET_ALL_REPORTS')
const getPublicReportAction = createFormAction('GET_REPORT')

const CLEAR_REPORT = 'CLEAR_REPORT'
const clearReportAction = () => ({ type: CLEAR_REPORT })

const getAllReportConfigsAction = createFormAction('GET_ALL_REPORT_CONFIGS')
const createReportConfigAction = createFormAction('CREATE_REPORT_CONFIG')
const updateReportConfigAction = createFormAction('UPDATE_REPORT_CONFIG')
const deleteReportConfigAction = createFormAction('DELETE_REPORT_CONFIG')

export {
  createReportAction,
  getAllReportsAction,
  getPublicReportAction,
  clearReportAction,
  CLEAR_REPORT,
  getAllReportConfigsAction,
  createReportConfigAction,
  updateReportConfigAction,
  deleteReportConfigAction,
}
