import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SideNav from '../components/SideNav'
import { getAccountProfile } from '../reducers/accountReducer'
import { toggleSideNavCollapseAction } from '../actions/uiActions'
import { getWindowWidth, isSideNavCollapsed } from '../reducers/uiReducer'

const mapStateToProps = ({ auth, account, ui }) => ({
  isAuthorized: auth.isAuthorized,
  accountProfile: getAccountProfile(account),
  isSideNavCollapsed: isSideNavCollapsed(ui),
  windowWidth: getWindowWidth(ui),
})

const mapDispatchToProps = dispatch => ({
  toggleSideNavCollapse: () => dispatch(toggleSideNavCollapseAction()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav))
