import { fork, call, takeLatest, put, all } from 'redux-saga/effects'
import { unauthorizedAction } from '../actions/authActions'
import { getAllBLEDataAction } from '../actions/bleActions'
import { showErrorMessageAction } from '../actions/uiActions'
import api from '../services/api'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllBLEDataSaga({ payload }) {
  try {
    const result = yield call(api.getAllBLEDataByCompany, payload)

    yield put(
      getAllBLEDataAction.success(result.map((x, i) => ({ ...x, localId: i })))
    )
  } catch (error) {
    yield put(getAllBLEDataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllBLEDataSaga() {
  yield takeLatest(getAllBLEDataAction.REQUEST, handleGetAllBLEDataSaga)
}

function* bleSaga() {
  yield all([fork(watchGetAllBLEDataSaga)])
}

export { bleSaga as default }
