const setFullstoryUser = ({ id, firstName, lastName, email }) => {
  if (!!global.FS && typeof global.FS.identify === 'function') {
    global.FS.identify(id, {
      displayName: `${firstName} ${lastName}`,
      email,
    })
  }
}

export { setFullstoryUser }
