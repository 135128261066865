import bowser from 'bowser/bundled'

const getTimeZone = () => {
  const DEFAULT_TIME_ZONE = 'UTC' // TODO 'America/New_York' ??
  const region = new Intl.DateTimeFormat('en-US')
  const { timeZone } = region.resolvedOptions()

  return timeZone ? timeZone : DEFAULT_TIME_ZONE
}

const timeZone = getTimeZone()

const formatResult = str => {
  const browser = bowser.getParser(global.navigator.userAgent)
  const { name, version } = browser.getBrowser()
  const isIE11 = name === 'Internet Explorer' && parseInt(version, 10) >= 11

  return isIE11 ? `${str} GMT` : str
}

const dateTimeFormatter = (dateStr, short = false) => {
  if (!dateStr) {
    return
  }

  const date = new Date(dateStr)

  let opts = {
    timeZone,
    timeZoneName: 'short',
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  }

  if (short) {
    Object.assign(opts, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
  } else {
    Object.assign(opts, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      second: '2-digit',
    })
  }

  return formatResult(new Intl.DateTimeFormat('en-US', opts).format(date))
}

// TODO should refactor these params as a single `options` param
// TODO it's annoying that `short` default is different here than in dateTimeFormatter
const dateFormatter = (dateStr, short = true, showWeekDay = false, hideDay) => {
  if (!dateStr) {
    return
  }

  /*
    `Date` gets user's time zone information.
    It's expected in this method that time
    defaults to midnight GMT. If a user's timezone
    is behind GMT, `Date` will convert to the previous
    day, so the UTC date info is taken to preserve
    the correct date. This fix is not applied to the
    other formatter functions because exact time
    information is used there.
  */
  const date = new Date(dateStr)

  const day = date.getUTCDate()
  const month = date.getUTCMonth()
  const year = date.getUTCFullYear()

  const opts = {
    timeZone,
    hourCycle: 'h23',
  }

  if (short) {
    Object.assign(opts, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
  } else if (hideDay) {
    Object.assign(opts, {
      year: 'numeric',
      month: 'short',
    })
  } else {
    Object.assign(opts, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }

  showWeekDay && Object.assign(opts, { weekday: 'short' })

  return formatResult(
    new Intl.DateTimeFormat('en-US', opts).format(new Date(year, month, day))
  )
}

const timeFormatter = (dateStr, showTimeZone = true) => {
  if (!dateStr) {
    return
  }

  const date = new Date(dateStr)
  const opts = {
    timeZone,
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  }

  showTimeZone && Object.assign(opts, { timeZoneName: 'short' })

  return formatResult(new Intl.DateTimeFormat('en-US', opts).format(date))
}

export { dateTimeFormatter, dateFormatter, timeFormatter, getTimeZone }
