import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import TimeSelectorContainer from '../../containers/common/TimeSelectorContainer'
import { normalizeDateTimeISO, formatDate } from '../common/DateSelector'
import { normalizeTime, formatTime } from '../common/TimeSelector'
import Checkbox from '../common/Checkbox'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { createQueryString } from '../../utils/queryParams'
import { required } from '../../utils/validators'
import {
  SUMMARY_INTERVAL_OPTIONS,
  SUMMARY_REPEAT_FREQUENCY_OPTIONS,
} from '../../constants'

class EditAlertSummaryForm extends Component {
  static propTypes = {
    getAllSites: PropTypes.func.isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    getAggregateThresholdsBySite: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    submitAction: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }

  static defaultProps = { formValues: {} }

  componentDidMount() {
    this.props.getAllSites(createQueryString({ perPage: 'all' }))
  }

  componentWillReceiveProps(nextProps, nextState) {
    const {
      getAllUsersBySite,
      getAggregateThresholdsBySite,
      formValues,
    } = this.props

    if (formValues.siteSlug !== nextProps.formValues.siteSlug) {
      getAllUsersBySite(
        nextProps.formValues.siteSlug,
        createQueryString({ perPage: 'all' })
      )
      getAggregateThresholdsBySite(nextProps.formValues.siteSlug)
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      submitAction,
      sites,
      users,
      thresholds,
      formValues,
    } = this.props

    const submit = handleSubmit(submitAction)

    return (
      <section className="EditAlertSummaryForm">
        <Form onSubmit={submit}>
          <Divider />
          <div className="flex-ns justify-between">
            <Field
              name="description"
              type="text"
              component={InputContainer}
              label="Description"
              validate={[required]}
              className="w-80-ns mr3-ns w-100"
            />
            <Field
              name="active"
              component={Checkbox}
              type="checkbox"
              label="Active"
              className="w-20-ns w-100"
            />
          </div>
          <div className="flex-ns">
            <Field
              name="siteSlug"
              component={SelectContainer}
              label="Site"
              validate={[required]}
              placeholder="Select a site"
              className="w-50-ns w-100 mr3-ns"
              filterable
            >
              {sites.map(x => (
                <Option value={x.slug} key={x.slug}>
                  {x.name}
                </Option>
              ))}
            </Field>
            <Field
              name="scheduledTime"
              validate={[required]}
              component={TimeSelectorContainer}
              label="Delivery Time"
              format={formatTime}
              normalize={normalizeTime}
              className="w-25-ns w-100 mr3-ns"
            />
            <Field
              name="scheduledDate"
              validate={[required]}
              component={DateSelectorContainer}
              label="Initial Send Date"
              dateView="MMM D, YYYY"
              format={formatDate}
              normalize={normalizeDateTimeISO}
              className="w-25-ns w-100"
              disableTime
            />
          </div>
          <div className="flex-ns">
            <Field
              name="repeatFrequency"
              component={SelectContainer}
              label="Repeat Frequency"
              validate={[required]}
              placeholder="Select number of times summary should be sent"
              className="w-25-ns w-100 mr3-ns"
              filterable
            >
              {SUMMARY_REPEAT_FREQUENCY_OPTIONS.map(({ label, value }) => (
                <Option value={value} key={value}>
                  {label}
                </Option>
              ))}
            </Field>
            <Field
              name="repeatAfter"
              component={SelectContainer}
              label="Repeat Interval"
              validate={[required]}
              placeholder="Select interval between summaries"
              className="w-25-ns w-100 mr3-ns"
              filterable
            >
              {SUMMARY_INTERVAL_OPTIONS.map(({ label, value }) => (
                <Option value={value} key={value}>
                  {label}
                </Option>
              ))}
            </Field>
            <Field
              name="daysToSummarize"
              component={SelectContainer}
              label="Number of Days to Summarize"
              validate={[required]}
              placeholder="Select number of days to include in summmary"
              className="w-50-ns w-100"
              filterable
            >
              {SUMMARY_INTERVAL_OPTIONS.map(({ label, value }) => (
                <Option value={value} key={value}>
                  {label}
                </Option>
              ))}
            </Field>
          </div>
          <Divider />
          <div className="flex-ns">
            <Field
              name="skipSystemAlerts"
              component={Checkbox}
              type="checkbox"
              label="Ignore Offline Alerts"
              className="w-third-ns w-100 mr3-ns"
            />
            <Field
              name="skipThresholdAlerts"
              component={Checkbox}
              type="checkbox"
              label="Ignore Threshold Alerts"
              className="w-third-ns w-100 mr3-ns"
            />
            <Field
              name="skipTotalUptime"
              component={Checkbox}
              type="checkbox"
              label="Ignore Total Uptime"
              className="w-third-ns w-100"
            />
          </div>
          <Divider />
          <div className="flex-ns">
            <Field
              name="thresholdIds"
              component={SelectContainer}
              mode="multiple"
              label={`Select Thresholds to ${
                formValues.thresholdWhitelist ? 'include in' : 'exclude from'
              } the Email Summary`}
              placeholder="Select thresholds"
              className="w-80-ns mr3-ns w-100"
              disabled={!formValues.siteSlug}
              filterable
            >
              {thresholds.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Field>
            <Field
              name="thresholdWhitelist"
              component={Checkbox}
              type="checkbox"
              label="Whitelist Thresholds"
              className="w-20-ns w-100"
            />
          </div>
          <div className="flex-ns">
            <Field
              name="siteUserIds"
              component={SelectContainer}
              mode="multiple"
              label={` Select Users who should ${
                formValues.userWhitelist ? '' : 'not'
              }  receive the Email Summary`}
              placeholder="Select users"
              className="w-80-ns mr3-ns w-100"
              disabled={!formValues.siteSlug}
              filterable
            >
              {users.map(({ siteUserId, fullName }) => (
                <Option value={siteUserId} key={siteUserId}>
                  {fullName}
                </Option>
              ))}
            </Field>
            <Field
              name="userWhitelist"
              component={Checkbox}
              type="checkbox"
              label="Whitelist Users"
              className="w-20-ns w-100"
            />
          </div>
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/reports/alert-summaries" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditAlertSummaryForm' })(EditAlertSummaryForm)
