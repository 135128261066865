import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray } from 'redux-form'
import actions from 'redux-form/lib/actions'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import { H3, H4 } from '../common/Headers'
import FormError from '../common/FormError'
import { ActionAnchor } from '../common/Anchor'
import { Collapse, Panel } from '../common/Ant'
import { required, isNumeric, isBetween0and1 } from '../../utils/validators'
import { toTitleCase } from '../../utils/textFormatters'
import { hasRole } from '../../utils/hasPermission'
import {
  ROLES,
  THRESHOLD_DATA_SOURCES,
  THRESHOLD_LEVELS,
  THRESHOLD_TYPES_BETA,
  THRESHOLD_TYPES,
  THRESHOLD_ALERT_TYPES,
  THRESHOLD_MESSAGE_KEYWORDS,
} from '../../constants'

const { change } = actions

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, value) =>
    dispatch(change('EditThresholdForm', field, value)),
})

class Rule extends Component {
  static propTypes = {
    changeFormField: PropTypes.func.isRequired,
    rule: PropTypes.string.isRequired,
    type: PropTypes.string,
  }

  state = {
    type: null,
  }

  handleSelection = type => this.setState({ type })

  renderFields = (rule, type) => {
    switch (type) {
      case THRESHOLD_TYPES_BETA.LEVEL:
        return (
          <div className="flex-ns">
            <Field
              name={`${rule}.source`}
              component={SelectContainer}
              label="Source"
              className="w-50-ns mr3-ns"
              filterable
            >
              {Object.keys(THRESHOLD_DATA_SOURCES).map(x => (
                <Option value={THRESHOLD_DATA_SOURCES[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Field>
            <Field
              name={`${rule}.low`}
              type="number"
              component={InputContainer}
              label="Low"
              validate={[isNumeric]}
              className="w-50-ns mr3-ns"
            />
            <Field
              name={`${rule}.high`}
              type="number"
              component={InputContainer}
              label="High"
              validate={[isNumeric]}
              className="w-50-ns"
            />
          </div>
        )
      case THRESHOLD_TYPES_BETA.RATE_OF_CHANGE:
        return (
          <div className="flex-ns">
            <Field
              name={`${rule}.source`}
              component={SelectContainer}
              label="Source"
              className="w-third-ns mr3-ns"
              filterable
            >
              {Object.keys(THRESHOLD_DATA_SOURCES).map(x => (
                <Option value={THRESHOLD_DATA_SOURCES[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Field>
            <Field
              name={`${rule}.duration`}
              type="number"
              component={InputContainer}
              label="Duration (seconds)"
              validate={[isNumeric]}
              className="w-third-ns mr3-ns"
            />
            <Field
              name={`${rule}.change`}
              type="number"
              component={InputContainer}
              label="Rate of Change"
              validate={[isNumeric]}
              className="w-third-ns"
            />
          </div>
        )
      case THRESHOLD_TYPES_BETA.LEVEL_OVER_DURATION:
      case THRESHOLD_TYPES_BETA.STANDARD_DEVIATION:
        const highValidators = [isNumeric]

        if (type === THRESHOLD_TYPES_BETA.STANDARD_DEVIATION) {
          highValidators.push(isBetween0and1)

          this.props.changeFormField(`${rule}.low`, 0)
        }

        return (
          <div className="flex-ns">
            <Field
              name={`${rule}.source`}
              component={SelectContainer}
              label="Source"
              className="w-25-ns mr3-ns"
              filterable
            >
              {Object.keys(THRESHOLD_DATA_SOURCES).map(x => (
                <Option value={THRESHOLD_DATA_SOURCES[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Field>
            <Field
              name={`${rule}.duration`}
              type="number"
              component={InputContainer}
              label="Duration (seconds)"
              validate={[isNumeric]}
              className="w-25-ns mr3-ns"
            />
            <Field
              name={`${rule}.low`}
              type="number"
              component={InputContainer}
              label="Low"
              validate={[isNumeric]}
              className="w-25-ns mr3-ns"
              disabled={type === THRESHOLD_TYPES_BETA.STANDARD_DEVIATION}
            />
            <Field
              name={`${rule}.high`}
              type="number"
              component={InputContainer}
              label="High"
              validate={highValidators}
              className="w-25-ns"
            />
          </div>
        )
      case THRESHOLD_TYPES_BETA.EQUAL:
        return (
          <div className="flex-ns">
            <Field
              name={`${rule}.source`}
              component={SelectContainer}
              label="Source"
              className="w-50-ns mr3-ns"
              filterable
            >
              {Object.keys(THRESHOLD_DATA_SOURCES).map(x => (
                <Option value={THRESHOLD_DATA_SOURCES[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Field>
            <Field
              name={`${rule}.target`}
              type="number"
              component={InputContainer}
              label="Value"
              validate={[isNumeric]}
              className="w-50-ns"
            />
          </div>
        )
      case THRESHOLD_TYPES_BETA.OFFLINE:
        return (
          <div className="flex-ns">
            <Field
              name={`${rule}.duration`}
              type="number"
              component={InputContainer}
              label="Duration (seconds)"
              validate={[isNumeric]}
              className="w-100-ns"
            />
          </div>
        )
      case THRESHOLD_TYPES_BETA.EQUAL_FOR_RANGE:
        return (
          <div className="flex-ns">
            <Field
              name={`${rule}.source`}
              component={SelectContainer}
              label="Source"
              className="mr3-ns w-third-ns"
              filterable
            >
              {Object.keys(THRESHOLD_DATA_SOURCES).map(x => (
                <Option value={THRESHOLD_DATA_SOURCES[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Field>
            <Field
              name={`${rule}.duration`}
              type="number"
              component={InputContainer}
              label="Duration (seconds)"
              validate={[isNumeric]}
              className="mr3-ns w-third-ns"
            />
            <Field
              name={`${rule}.target`}
              type="number"
              component={InputContainer}
              label="Value"
              validate={[isNumeric]}
              className="w-third-ns"
            />
          </div>
        )
    }
  }

  render() {
    const { rule, type } = this.props

    let selectedType = type || this.state.type
    const thresholdTypeOptions = hasRole(ROLES.SUPER_ADMIN)
      ? THRESHOLD_TYPES_BETA
      : THRESHOLD_TYPES

    return (
      <div>
        <Field
          name={`${rule}.thresholdType`}
          component={SelectContainer}
          label="Rule Type"
          className="w-100"
          onSelect={this.handleSelection}
          filterable
        >
          {Object.keys(thresholdTypeOptions).map(x => (
            <Option value={THRESHOLD_TYPES_BETA[x]} key={x}>
              {toTitleCase(x)}
            </Option>
          ))}
        </Field>
        {this.renderFields(rule, selectedType)}
      </div>
    )
  }
}

const ConnectedRule = connect(null, mapDispatchToProps)(Rule)

const ThresholdRules = ({ fields, meta: { error } }) => (
  <div className="mb3">
    <H3>Rules</H3>
    {fields.map((rule, index) => {
      const { thresholdType } = fields.get(index) || {}

      return (
        <div key={index}>
          {index > 0 ? <Divider /> : null}
          <H4 inline>Rule {index + 1}</H4>
          <ActionAnchor onClick={e => fields.remove(index)}>
            Remove
          </ActionAnchor>
          <ConnectedRule rule={rule} type={thresholdType} />
        </div>
      )
    })}
    <ActionAnchor onClick={e => fields.push()} button>
      Create New
    </ActionAnchor>
  </div>
)

ThresholdRules.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object,
}

class EditThresholdForm extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      error,
      submitAction,
      // TODO
      // companies,
      sites,
    } = this.props

    const submit = handleSubmit(submitAction)

    return (
      <section className="EditThresholdForm">
        <Form onSubmit={submit}>
          <Divider />
          <div className="flex-ns">
            <Field
              name="name"
              type="text"
              component={InputContainer}
              label="Name"
              validate={[required]}
              className="w-100 mr3-ns"
            />
          </div>
          <div className="flex-ns">
            <Field
              name="thresholdAlertType"
              component={SelectContainer}
              label="Threshold Type"
              validate={[required]}
              placeholder="Select an alert type"
              className="w-50-ns mr3"
              filterable
            >
              {Object.keys(THRESHOLD_ALERT_TYPES).map(x => (
                <Option value={THRESHOLD_ALERT_TYPES[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Field>
            <Field
              name="level"
              component={SelectContainer}
              label="Level"
              validate={[required]}
              placeholder="Select a level"
              className="w-50-ns"
              filterable
            >
              {Object.keys(THRESHOLD_LEVELS).map(x => (
                <Option value={THRESHOLD_LEVELS[x]} key={x}>
                  {x !== THRESHOLD_LEVELS.WARNING ? toTitleCase(x) : 'Medium'}
                </Option>
              ))}
            </Field>
          </div>
          <div>
            <div className="flex-ns">
              <Field
                name="message"
                type="textArea"
                component={InputContainer}
                label="Message"
                validate={[required]}
                className="w-100"
              />
            </div>
            <div className="mb3">
              <Collapse>
                <Panel header="Available Keywords" key="keywords">
                  <ul className="flex flex-wrap">
                    {THRESHOLD_MESSAGE_KEYWORDS.map(x => (
                      <li key={x} className="w-25-ns w-100">
                        {x}
                      </li>
                    ))}
                  </ul>
                </Panel>
              </Collapse>
            </div>
          </div>
          <Divider />
          <Field
            name="description"
            type="text"
            component={InputContainer}
            label="Description"
          />
          <Field
            name="visibleToUserRoles"
            component={SelectContainer}
            mode="multiple"
            label="Select User Roles with Access to Alerts"
            placeholder="Select user roles"
            filterable
          >
            {Object.keys(ROLES).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(x)}
              </Option>
            ))}
          </Field>
          <Divider />

          <div className="flex-ns">
            {/* <Field */}
            {/*   name="companySlug" */}
            {/*   component={SelectContainer} */}
            {/*   label="Associate Threshold with a Company" */}
            {/*   placeholder="Select a company" */}
            {/*   className="w-50-ns mr3-ns w-100" */}
            {/*   filterable */}
            {/* > */}
            {/*   {companies.map(x => ( */}
            {/*     <Option value={x.slug} key={x.slug}> */}
            {/*       {x.name} */}
            {/*     </Option> */}
            {/*   ))} */}
            {/* </Field> */}

            <Field
              name="siteSlug"
              component={SelectContainer}
              label="Associate Threshold with a Site"
              placeholder="Select a site"
              className="w-100"
              filterable
            >
              {sites.map(x => (
                <Option value={x.slug} key={x.slug}>
                  {x.name}
                </Option>
              ))}
            </Field>
          </div>
          <Divider />
          <FieldArray name="rulesJson" component={ThresholdRules} />
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/rules" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

EditThresholdForm.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({ form: 'EditThresholdForm' })(EditThresholdForm)
