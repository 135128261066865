import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from './common/Ant'
import Form from './common/Form'
import Input from './common/Input'
import Select, { Option } from './common/Select'
import Checkbox from './common/Checkbox'
import Button from './common/Button'
import { H3 } from './common/Headers'
import { unique } from '../utils/helpers'

class BatchUpdateAlertsModal extends Component {
  static propTypes = {
    selectedAlerts: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.arrayOf(PropTypes.object),
    isModalVisible: PropTypes.bool.isRequired,
    batchUpdateAlerts: PropTypes.func.isRequired,
    handleModalCancel: PropTypes.func.isRequired,
  }

  state = {
    tagIds: [],
    commentText: '',
    closeAlert: false,
    hideAlert: false,
    canUpdateHiddenProp: true,
  }

  setTagId = tagId => this.setState({ tagIds: tagId })
  setCommentText = ev => this.setState({ commentText: ev.currentTarget.value })
  setCloseAlert = closeAlert => this.setState({ closeAlert })
  setHideAlert = hideAlert => this.setState({ hideAlert })
  setCanUpdateHiddenProp = canUpdateHiddenProp =>
    this.setState({ canUpdateHiddenProp })

  componentDidUpdate(prevProps) {
    if (prevProps.isModalVisible !== this.props.isModalVisible) {
      const uniqueAlertHiddenStatuses = this.props.selectedAlerts
        .map(x => x.hidden)
        .filter(unique)

      const canUpdateHiddenProp = uniqueAlertHiddenStatuses.length === 1
      this.setCanUpdateHiddenProp(canUpdateHiddenProp)
      if (canUpdateHiddenProp) {
        this.setHideAlert(uniqueAlertHiddenStatuses[0])
      }
    }
  }

  handleCancel = () => {
    this.setState({
      tagIds: [],
      commentText: '',
      closeAlert: false,
    })

    this.props.handleModalCancel()
  }

  handleBatchUpdateSubmit = () => {
    const { selectedAlerts } = this.props
    const {
      tagIds,
      commentText,
      closeAlert,
      hideAlert,
      canUpdateHiddenProp,
    } = this.state

    this.props.batchUpdateAlerts({
      alertSlugs: selectedAlerts.map(x => x.slug),
      commentText,
      tagIds,
      closeAlert,
      hideAlert: canUpdateHiddenProp ? hideAlert : undefined,
    })

    this.handleCancel()
  }

  render() {
    const { selectedAlerts, tags, isModalVisible } = this.props
    const {
      tagIds,
      commentText,
      closeAlert,
      hideAlert,
      canUpdateHiddenProp,
    } = this.state

    return (
      <Modal
        className="Modal BatchUpdateAlertsModal"
        visible={isModalVisible}
        footer={null}
        onCancel={this.handleCancel}
        destroyOnClose
      >
        <H3>Bulk Update ({selectedAlerts.length.toString()} Smart Pods)</H3>
        <Form onSubmit={this.handleBatchUpdateSubmit}>
          <Input
            label="Comment"
            type="textArea"
            className="w-100"
            placeholder="Leave a comment"
            input={{
              value: commentText,
              onChange: this.setCommentText,
            }}
          />
          <Select
            label="Tags"
            mode="multiple"
            className="w-100"
            placeholder="Select Tags"
            input={{
              value: tagIds,
              onChange: this.setTagId,
            }}
            filterable
          >
            {tags.map(tag => (
              <Option value={tag.id} key={tag.id}>
                {tag.name}
              </Option>
            ))}
          </Select>
          <Checkbox
            type="checkbox"
            label="Close Alerts"
            input={{
              checked: closeAlert,
              onChange: this.setCloseAlert,
            }}
          />
          <Checkbox
            type="checkbox"
            label="Hide Alerts"
            input={{
              checked: hideAlert,
              onChange: this.setHideAlert,
              disabled: !canUpdateHiddenProp,
            }}
          />

          <Button onClick={this.handleBatchUpdateSubmit} />
        </Form>
      </Modal>
    )
  }
}

export default BatchUpdateAlertsModal
