import {
  select,
  fork,
  call,
  take,
  takeLatest,
  put,
  cancel,
  all,
  delay,
} from 'redux-saga/effects'
import { SubmissionError } from 'redux-form'
import { push } from 'connected-react-router'
import * as Sentry from '@sentry/browser'
import {
  authAction,
  logOutAction,
  requestPasswordResetAction,
  resetPasswordAction,
  verifyResetPasswordTokenAction,
  verifyTwoFactorAuthTokenAction,
  resendTwoFactorAuthTokenAction,
  requestAccountConfirmationAction,
  confirmAccountAction,
  getUnconfirmedUserAction,
  HAS_AUTH_TOKEN,
  UNAUTHORIZED,
} from '../actions/authActions'
import {
  showPhoneVerificationNotificationAction,
  showSuccessMessageAction,
} from '../actions/uiActions'
import {
  getAccountProfileAction,
  clearAccountProfileAction,
} from '../actions/accountActions'
import api from '../services/api'
import { connect } from '../services/socket'
import {
  setToken,
  clearTokens,
  setRefreshToken,
  setUserSlug,
  clearUserSlug,
  getUserSlug,
} from '../services/storage'
import { setCookie, removeCookie } from '../services/cookies'
import { shutdownIntercom } from '../services/third-party/intercom'
import { flagEnabled } from '../utils/config'
import { hasRole } from '../utils/hasPermission'
import { pickErrorMessage } from '../utils/helpers'
import {
  ROLES,
  ERROR_MESSAGES,
  ERROR_TRIGGERS,
  MESSAGES,
  GENERIC_ERROR_MESSAGE,
  PILLAR_TOKEN,
} from '../constants'

const isCommunicationsEnabled = flagEnabled('REACT_APP_ENABLE_COMMUNICATIONS')

// selectors

const getPasswordResetToken = ({ auth }) => auth.passwordResetToken
const getAccountConfirmationToken = ({ auth }) => auth.accountConfirmationToken
const getInitialPathname = ({ auth }) => auth.initialPathname

// handlers

function* handleAuthSaga(credentials) {
  try {
    const { user, token, refreshToken } = yield call(api.authorize, credentials)

    yield call(Sentry.setUser, {
      id: user.id,
      username: user.email,
      companyId: user.companyId,
      companyName: user.companyName,
    })

    // initiate two factor auth if no token
    if (!token) {
      // save userSlug in location storage
      if (user && user.slug) {
        yield call(setUserSlug, user.slug)
      }
      yield put(push('/two-factor'))
    } else {
      yield put(getAccountProfileAction.success(user))
      yield call(setToken, token)
      yield call(setRefreshToken, refreshToken)
      yield call(setCookie, PILLAR_TOKEN, token, 30)

      if (isCommunicationsEnabled) {
        yield call(connect)
      }

      yield put(authAction.success(user))
      const initialPathname = yield select(getInitialPathname)
      yield put(
        push(
          initialPathname
            ? initialPathname
            : hasRole(ROLES.HILTI_USER)
            ? '/hilti/'
            : '/dashboard/'
        )
      )

      if (
        flagEnabled('REACT_APP_ENABLE_PHONE_VERIFICATION') &&
        user.phoneNumber &&
        !user.verified
      ) {
        yield put(showPhoneVerificationNotificationAction())
      }
    }
  } catch (error) {
    let formError

    if (error.status === ERROR_TRIGGERS.UNAUTHORIZED) {
      formError = new SubmissionError({ _error: ERROR_MESSAGES.CREDENTIALS })
    } else if (error.message === ERROR_TRIGGERS.FAILED_TO_FETCH) {
      formError = new SubmissionError({ _error: ERROR_MESSAGES.SERVER })
    } else {
      formError = new SubmissionError({ _error: pickErrorMessage(error) })
    }

    yield put(authAction.failure(formError))
  }
}

function* handleVerifyTwoFactorAuthTokenSaga({ payload }) {
  try {
    const userSlug = yield call(getUserSlug)
    const { token, user } = yield call(api.verifyTwoFactorAuthToken, {
      ...payload,
      userSlug,
    })

    // set auth token in storage
    yield call(setToken, token)
    // remove userSlug from local storage
    yield call(clearUserSlug)

    if (isCommunicationsEnabled) {
      yield call(connect)
    }

    yield put(getAccountProfileAction.success(user))
    yield put(verifyTwoFactorAuthTokenAction.success())
    const initialPathname = yield select(getInitialPathname)
    yield put(push(initialPathname ? initialPathname : '/dashboard/'))
  } catch (error) {
    const formError = new SubmissionError({
      _error: ERROR_MESSAGES.INVALID_CODE,
    })
    yield put(verifyTwoFactorAuthTokenAction.failure(formError))
  }
}

function* handleResendTwoFactorAuthTokenSaga() {
  try {
    const userSlug = yield call(getUserSlug)
    yield call(api.resendTwoFactorAuthToken, { userSlug })
    yield put(resendTwoFactorAuthTokenAction.success())
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_RESENT_VERIFICATION)
    )
  } catch (error) {
    const formError = new SubmissionError({ _error: GENERIC_ERROR_MESSAGE })
    yield put(resendTwoFactorAuthTokenAction.failure(formError))
  }
}

function* handleRequestResetPasswordEmailSaga(email) {
  try {
    yield call(api.requestResetPasswordEmail, email)
    yield put(requestPasswordResetAction.success())
  } catch (error) {
    const formError = new SubmissionError({ _error: GENERIC_ERROR_MESSAGE })
    yield put(requestPasswordResetAction.failure(formError))
  }
}

function* handleVerifyResetPasswordTokenSaga({ payload }) {
  try {
    yield call(api.verifyResetPasswordToken, payload)
    yield put(verifyResetPasswordTokenAction.success())
  } catch (error) {
    let formError

    if (error.message === ERROR_TRIGGERS.TOKEN_EXPIRED) {
      formError = new SubmissionError({ _error: ERROR_MESSAGES.TOKEN })
    } else {
      formError = new SubmissionError({ _error: GENERIC_ERROR_MESSAGE })
    }

    yield put(verifyResetPasswordTokenAction.failure(formError))
  }
}

function* handleResetPasswordSaga(password) {
  try {
    const token = yield select(getPasswordResetToken)
    yield call(api.resetPassword, token, password)
    yield put(resetPasswordAction.success())
  } catch (error) {
    let formError

    if (error.message === ERROR_TRIGGERS.TOKEN_EXPIRED) {
      formError = new SubmissionError({ _error: ERROR_MESSAGES.TOKEN })
    } else {
      formError = new SubmissionError({ _error: GENERIC_ERROR_MESSAGE })
    }

    yield put(resetPasswordAction.failure(formError))
  }
}

function* handleRequestAccountConfirmationEmailSaga(email) {
  try {
    yield call(api.requestAccountConfirmationEmail, email)
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_RESENT_CONFIRMATION)
    )
    yield put(requestAccountConfirmationAction.success())
  } catch (error) {
    const formError = new SubmissionError({ _error: pickErrorMessage(error) })
    yield put(requestAccountConfirmationAction.failure(formError))
  }
}

function* handleConfirmAccountSaga(user) {
  try {
    const token = yield select(getAccountConfirmationToken)
    yield call(api.confirmAccount, token, user)
    yield put(confirmAccountAction.success())
    yield put(push('/'))
  } catch (error) {
    let formError

    if (error.message === ERROR_TRIGGERS.TOKEN_EXPIRED) {
      formError = new SubmissionError({ _error: ERROR_MESSAGES.TOKEN })
    } else {
      formError = new SubmissionError({ _error: GENERIC_ERROR_MESSAGE })
    }

    yield put(confirmAccountAction.failure(formError))
  }
}

function* handleGetUnconfirmedUserSaga(token) {
  try {
    const payload = yield call(api.getUnconfirmedUser, token)
    yield put(getUnconfirmedUserAction.success(payload))
  } catch (error) {
    let formError

    if (error.message === ERROR_TRIGGERS.TOKEN_EXPIRED) {
      formError = new SubmissionError({ _error: ERROR_MESSAGES.TOKEN })
    } else if (error.apiError.message === ERROR_TRIGGERS.CONFIRMED_USER) {
      formError = new SubmissionError({ _error: ERROR_MESSAGES.CONFIRMED_USER })
      yield put(getUnconfirmedUserAction.failure(formError))
      yield delay(7000)
      yield put(push('/'))
      return
    } else {
      formError = new SubmissionError({ _error: GENERIC_ERROR_MESSAGE })
    }

    yield put(getUnconfirmedUserAction.failure(formError))
  }
}

// watchers

function* watchAuthSaga() {
  while (true) {
    const action = yield take([
      authAction.REQUEST,
      HAS_AUTH_TOKEN,
      verifyTwoFactorAuthTokenAction.REQUEST,
    ])

    let task

    if (action.type === authAction.REQUEST) {
      task = yield fork(handleAuthSaga, action.payload)
    } else if (action.type === HAS_AUTH_TOKEN) {
      const location =
        action.payload.pathname === '/'
          ? { pathname: '/dashboard/' }
          : action.payload
      yield put(getAccountProfileAction.request(location))
    }

    yield take([logOutAction.REQUEST, authAction.FAILURE, UNAUTHORIZED])

    if (task) {
      yield cancel(task)
    }

    yield put(logOutAction.success())
    yield put(clearAccountProfileAction())
    yield call(shutdownIntercom)
    yield call(clearTokens)
    yield call(removeCookie, PILLAR_TOKEN)
    yield put(push('/'))
  }
}

function* watchVerifyTwoFactorAuthTokenSaga() {
  yield takeLatest(
    verifyTwoFactorAuthTokenAction.REQUEST,
    handleVerifyTwoFactorAuthTokenSaga
  )
}

function* watchResendTwoFactorAuthTokenSaga() {
  yield takeLatest(
    resendTwoFactorAuthTokenAction.REQUEST,
    handleResendTwoFactorAuthTokenSaga
  )
}

function* watchRequestResetPasswordEmailSaga() {
  while (true) {
    const { payload } = yield take(requestPasswordResetAction.REQUEST)
    yield fork(handleRequestResetPasswordEmailSaga, payload.email)
  }
}

function* watchVerifyResetPasswordTokenSaga() {
  yield takeLatest(
    verifyResetPasswordTokenAction.REQUEST,
    handleVerifyResetPasswordTokenSaga
  )
}

function* watchResetPasswordSaga() {
  while (true) {
    const { payload } = yield take(resetPasswordAction.REQUEST)
    yield fork(handleResetPasswordSaga, payload.password)
  }
}

function* watchRequestAccountConfirmationEmailSaga() {
  while (true) {
    const { payload } = yield take(requestAccountConfirmationAction.REQUEST)
    yield fork(handleRequestAccountConfirmationEmailSaga, payload.email)
  }
}

function* watchConfirmAccountSaga() {
  while (true) {
    const { payload } = yield take(confirmAccountAction.REQUEST)
    yield fork(handleConfirmAccountSaga, payload)
  }
}

function* watchGetUnconfirmUserSaga() {
  while (true) {
    const { payload } = yield take(getUnconfirmedUserAction.REQUEST)
    yield fork(handleGetUnconfirmedUserSaga, payload)
  }
}

function* authSaga() {
  yield all([
    fork(watchAuthSaga),
    fork(watchRequestResetPasswordEmailSaga),
    fork(watchVerifyResetPasswordTokenSaga),
    fork(watchResetPasswordSaga),
    fork(watchRequestAccountConfirmationEmailSaga),
    fork(watchConfirmAccountSaga),
    fork(watchGetUnconfirmUserSaga),
    fork(watchVerifyTwoFactorAuthTokenSaga),
    fork(watchResendTwoFactorAuthTokenSaga),
  ])
}

export {
  authSaga as default,
  getPasswordResetToken,
  getAccountConfirmationToken,
  getInitialPathname,
  watchAuthSaga,
  handleAuthSaga,
  watchRequestResetPasswordEmailSaga,
  handleRequestResetPasswordEmailSaga,
  watchVerifyResetPasswordTokenSaga,
  watchResetPasswordSaga,
  watchResendTwoFactorAuthTokenSaga,
  handleResendTwoFactorAuthTokenSaga,
  watchVerifyTwoFactorAuthTokenSaga,
  handleVerifyTwoFactorAuthTokenSaga,
  handleResetPasswordSaga,
  watchRequestAccountConfirmationEmailSaga,
  handleRequestAccountConfirmationEmailSaga,
  watchConfirmAccountSaga,
  handleConfirmAccountSaga,
  watchGetUnconfirmUserSaga,
  handleGetUnconfirmedUserSaga,
}
