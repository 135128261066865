import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import { H4 } from './common/Headers'
import { toTitleCase } from '../utils/textFormatters'

class Workflow extends Component {
  static propTypes = {
    getWorkflow: PropTypes.func.isRequired,
    workflow: PropTypes.object.isRequired,
    match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  }

  componentDidMount() {
    this.props.getWorkflow(this.props.match.params.workflowType)
  }

  state = {
    zoom: 1,
  }

  setZoom = increment => () => {
    const { zoom } = this.state

    this.setState({ zoom: increment ? zoom + 0.1 : zoom - 0.1 })
  }

  render() {
    const {
      workflow: { workflowType, rawWorkflow, schemaSvg },
    } = this.props
    const { zoom } = this.state

    return (
      <section className="Workflow">
        <div className="mb3">
          <Bread>
            <Crumb>
              <Anchor to="/workflows">Workflows</Anchor>
            </Crumb>
            <Crumb>{toTitleCase(workflowType)}</Crumb>
          </Bread>
        </div>
        {schemaSvg && (
          <div className="mb3">
            <div className="flex justify-between items-center mb2">
              <H4 noMargin>Workflow</H4>
              <div>
                Zoom:{' '}
                <button className="input-reset" onClick={this.setZoom(false)}>
                  -
                </button>{' '}
                /{' '}
                <button className="input-reset" onClick={this.setZoom(true)}>
                  +
                </button>
              </div>
            </div>
            <div className="vh-75 overflow-auto ba b--light-gray br2">
              <div
                style={{ zoom }}
                dangerouslySetInnerHTML={{ __html: schemaSvg }}
              />
            </div>
          </div>
        )}
        {rawWorkflow && (
          <div>
            <H4>Raw Workflow</H4>
            <pre className="pre vh-50 ba b--light-gray br2 pa2">
              {rawWorkflow}
            </pre>
          </div>
        )}
      </section>
    )
  }
}

export default Workflow
