import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import { TimePicker } from './Ant'
import Label from './Label'

const normalizeTime = val => (val ? val.format('HH:mm') : null)
const formatTime = val => (val ? moment(val, 'hh:mm') : null)

const TimeSelector = ({
  label,
  className = '',
  defaultValue,
  input,
  format,
  onChange,
}) => {
  const timeSelectorClassNames = cx('TimeSelector mb3', className)

  return (
    <div className={timeSelectorClassNames}>
      <Label>{label}</Label>
      <TimePicker
        className="db w-100 input-reset"
        value={input.value}
        defaultValue={defaultValue}
        format={format || 'HH:mm'}
        onChange={input.onChange}
      />
    </div>
  )
}

TimeSelector.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  format: PropTypes.string,
  onChange: PropTypes.func,
}

export { TimeSelector as default, normalizeTime, formatTime }
