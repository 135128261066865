import produce from 'immer'
import { combineReducers } from 'redux'
import sortBy from 'sort-by'
import moment from 'moment'
import { normalizeAmbientLightReading } from '../utils/helpers'
import { camelize } from 'humps'
import {
  REMOVE_POD_FROM_LOCATION,
  REMOVE_FLOW_MONITOR_FROM_LOCATION,
  CLEAR_LOCATION_CHART_DATA,
  CLEAR_VISIBLE_LOCATIONS,
  getAllLocationsBySiteAction,
  getAllLocationsByFloorAction,
  getLocationAction,
  getLocationChartDataAction,
  updateLocationAction,
  createMapLocationAction,
  updateMapLocationCoordinatesAction,
  getAllLocationsSnapshotDataByFloorAction,
  getLocationTimeSeriesDataByLocationIdsAction,
  getAllLocationThresholdUserMappingsAction,
  getPodAssignmentHistoryByLocationAction,
  getLocationSnapshotAction,
} from '../actions/locationsActions'

const convertTime = x => ({
  ...x,
  time: moment(x.time).valueOf(),
})

const byId = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLocationsByFloorAction.SUCCESS:
        const { locations } = payload
        locations.forEach(location => (draft[location.id] = location))
        break
      case getAllLocationsSnapshotDataByFloorAction.SUCCESS:
        const { latestSensorData } = payload
        latestSensorData.forEach(x => (draft[x.locationId].data = x))
        break
      case getAllLocationsBySiteAction.SUCCESS:
        payload.forEach(location => {
          draft[location.id] = location
        })
        break
      case createMapLocationAction.SUCCESS:
      case updateMapLocationCoordinatesAction.SUCCESS:
        draft[payload.id] = payload
        break
    }
  })

const visibleIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLocationsByFloorAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...payload.locations.map(location => location.id)
        )
        break
      case getAllLocationsBySiteAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.map(location => location.id))
        break
      case createMapLocationAction.SUCCESS:
        draft.push(payload.id)
        break
      case CLEAR_VISIBLE_LOCATIONS:
        return []
    }
  })

const current = (
  state = {
    tags: [],
    tagIds: [],
    thresholds: [],
    lastReading: {},
    activeAlerts: [],
  },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getLocationSnapshotAction.SUCCESS:
        Object.assign(draft, { lastReading: payload })
        break
      case getLocationAction.SUCCESS:
      case updateLocationAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case REMOVE_POD_FROM_LOCATION:
        draft.podId = ''
        draft.podPillarId = ''
        break
      case REMOVE_FLOW_MONITOR_FROM_LOCATION:
        draft.flowMonitorId = ''
        draft.flowMonitorPillarId = ''
        break
    }
  })

const CHART_DATA_INITIAL_STATE = []

const chartData = (state = CHART_DATA_INITIAL_STATE, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getLocationChartDataAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...payload.data.map(dataObj =>
            convertTime(normalizeAmbientLightReading(dataObj))
          )
        )
        break
      case CLEAR_LOCATION_CHART_DATA:
        return CHART_DATA_INITIAL_STATE
    }
  })

const chartColumns = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getLocationChartDataAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.columns.map(camelize).sort())
        break
    }
  })

const timeSeriesDataById = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getLocationTimeSeriesDataByLocationIdsAction.SUCCESS:
        payload.locations.forEach(x => (draft[x.info.id] = x))
        break
    }
  })

const timeSeriesDataVisibleIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getLocationTimeSeriesDataByLocationIdsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.locations.map(x => x.info.id))
        break
    }
  })

const thresholdUserMappings = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllLocationThresholdUserMappingsAction.SUCCESS:
        const result = payload.users
          .map(user => {
            const thresholds = payload.userThresholdMapping
              .filter(x => x.user === user.slug)
              .map(x => {
                const index = payload.thresholds
                  .map(y => y.thresholdSlug)
                  .indexOf(x.threshold)

                const active =
                  x.settings.smsNotifications || x.settings.emailNotifications
                const mappingId = x.settings.id

                return { ...payload.thresholds[index], active, mappingId }
              })

            return Object.assign(user, { thresholds })
          })
          .sort(sortBy('role'))

        draft.splice(0, draft.length, ...result)
        break
    }
  })

const locationSnapshotData = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getLocationSnapshotAction.SUCCESS:
        let dataArray = []
        if (payload) {
          dataArray.push(payload)
        }
        Object.assign(draft, { data: dataArray, payload })
        break
    }
  })

const assignmentHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getPodAssignmentHistoryByLocationAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.items)
        break
    }
  })

const locationsReducer = combineReducers({
  byId,
  visibleIds,
  current,
  chartData,
  chartColumns,
  timeSeriesDataById,
  timeSeriesDataVisibleIds,
  thresholdUserMappings,
  locationSnapshotData,
  assignmentHistory,
})

const getLocation = (state, id) => state.byId[id]
const getCurrentLocation = ({ current }) => current
const getCurrentLocationChartData = ({ chartData }) => chartData
const getCurrentLocationChartColumns = ({ chartColumns }) => chartColumns
const getVisibleLocations = state =>
  state.visibleIds.map(id => getLocation(state, id))
const getTimeSeriesData = (state, id) => state.timeSeriesDataById[id]
const getVisibleTimeSeriesData = state =>
  state.timeSeriesDataVisibleIds.map(id => getTimeSeriesData(state, id))
const getLocationThresholdUserMappings = ({ thresholdUserMappings }) =>
  thresholdUserMappings
const getLocationSnapshot = state => state.locationSnapshotData
const getVisiblePodAssignmentByLocationHistory = state =>
  state.assignmentHistory

export {
  locationsReducer as default,
  getCurrentLocation,
  getCurrentLocationChartData,
  getCurrentLocationChartColumns,
  getVisibleLocations,
  getVisibleTimeSeriesData,
  getLocationThresholdUserMappings,
  normalizeAmbientLightReading,
  getLocationSnapshot,
  getVisiblePodAssignmentByLocationHistory,
}
