import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { AntInput, InputNumber } from './Ant'
import Label from './Label'
import Meta from './Meta'

const { TextArea } = AntInput

const Input = ({
  requiredFields,
  isRequired,
  placeholder,
  autosize = false,
  input,
  label,
  type = 'text',
  meta,
  disabled = false,
  noMargin = false,
  isTallTextArea = false,
  isCode = false,
  className = '',
}) => {
  const inputClassNames = cx('Input', className, { mb3: !noMargin })

  return (
    <div className={inputClassNames}>
      {label && (
        <Label>
          {label}
          {((requiredFields && requiredFields.indexOf(input.name) > -1) ||
            isRequired) && <span className="red"> *</span>}
        </Label>
      )}
      {type === 'number' ? (
        <InputNumber
          className="db w-100 input-reset"
          {...input}
          disabled={disabled}
        />
      ) : type === 'textArea' ? (
        <TextArea
          className={cx('db w-100 input-reset', { code: isCode })}
          style={{ height: isTallTextArea ? '16rem' : 'auto' }}
          autosize={autosize}
          placeholder={placeholder}
          disabled={disabled}
          {...input}
        />
      ) : (
        <AntInput
          className="db w-100 input-reset"
          type={type}
          placeholder={placeholder}
          {...input}
          disabled={disabled}
        />
      )}
      <Meta {...meta} />
    </div>
  )
}

Input.propTypes = {
  input: PropTypes.object.isRequired,
  autosize: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  isTallTextArea: PropTypes.bool,
  isCode: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
}

export { Input as default, TextArea }
