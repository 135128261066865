import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ResetPasswordForm from '../components/forms/ResetPasswordForm'
import {
  verifyResetPasswordTokenAction,
  setPasswordResetTokenAction,
} from '../actions/authActions'
import { isValidToken } from '../reducers/authReducer'

const ResetPasswordFormContainer = ({
  setPasswordResetToken,
  verifyResetPasswordToken,
  isValidToken,
  match: {
    params: { token },
  },
}) => (
  <ResetPasswordForm
    setPasswordResetToken={setPasswordResetToken}
    verifyResetPasswordToken={verifyResetPasswordToken}
    isValidToken={isValidToken}
    token={token}
  />
)

ResetPasswordFormContainer.propTypes = {
  setPasswordResetToken: PropTypes.func.isRequired,
  verifyResetPasswordToken: PropTypes.func.isRequired,
  isValidToken: PropTypes.bool.isRequired,
  token: PropTypes.string,
  match: PropTypes.object,
}

const mapStateToProps = ({ auth }) => ({
  isValidToken: isValidToken(auth),
})

const mapDispatchToProps = dispatch => ({
  verifyResetPasswordToken: token =>
    dispatch(verifyResetPasswordTokenAction.request(token)),
  setPasswordResetToken: token => dispatch(setPasswordResetTokenAction(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  ResetPasswordFormContainer
)
