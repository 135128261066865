import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditSIMCardForm from './forms/EditSIMCardForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateSIMCardAction } from '../actions/simCardsActions'
import { createQueryString } from '../utils/queryParams'
import { USER_PERMISSIONS } from '../constants'
import { hasPermission } from '../utils/hasPermission'
import { flagEnabled } from '../utils/config'

const isFlowMonitorEnabled = flagEnabled('REACT_APP_ENABLE_FLOW_MONITOR')

class EditSIMCard extends Component {
  static propTypes = {
    simCard: PropTypes.object.isRequired,
    getSIMCard: PropTypes.func.isRequired,
    gateways: PropTypes.arrayOf(PropTypes.object),
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    getAllGateways: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    getAllFlowMonitors: PropTypes.func.isRequired,
    flowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const {
      getAllGateways,
      getAllAssetLocations,
      getAllFlowMonitors,
      getSIMCard,
      match,
    } = this.props
    const qs = createQueryString({ perPage: 'all' })
    getAllGateways(qs)
    getAllAssetLocations(qs)
    isFlowMonitorEnabled &&
      hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
      getAllFlowMonitors(qs)
    getSIMCard(match.params.id)
  }

  render() {
    const { gateways, flowMonitors, assetLocations, simCard } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/sim-cards">SIM Cards</Anchor>,
      'Edit SIM Card',
    ]

    return (
      <section className="EditSIMCard">
        <FormHeader crumbs={crumbs} />
        <EditSIMCardForm
          submitAction={updateSIMCardAction}
          initialValues={simCard}
          gateways={gateways}
          assetLocations={assetLocations}
          flowMonitors={flowMonitors}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditSIMCard
