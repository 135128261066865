import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllShipmentsAction,
  getShipmentAction,
} from '../actions/deploymentsActions'

// check all values in the checkboxes to default PUT to current selections
const getStringValues = values =>
  values ? values.map(id => id.toString()) : []

const current = (
  state = {
    pods: [],
    gateways: [],
    flowMonitors: [],
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case getShipmentAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action
    switch (type) {
      case getAllShipmentsAction.SUCCESS:
        payload.forEach(shipment => {
          draft[shipment.id] = shipment
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllShipmentsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(shipment => shipment.id)
        )
        break
    }
  })

const shipmentsReducer = combineReducers({
  current,
  visibleIds,
  byId,
})

const getCurrentShipment = state => state.current
const getShipmentById = (state, id) => state.byId[id]
const getVisibleShipments = state =>
  state.visibleIds.map(id => getShipmentById(state, id))

export {
  shipmentsReducer as default,
  getCurrentShipment,
  getStringValues,
  getVisibleShipments,
}
