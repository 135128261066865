const UNSET_SITE_USER_IDS = 'UNSET_SITE_USER_IDS'
const unsetSiteUserIdsAction = () => ({ type: UNSET_SITE_USER_IDS })

const UPDATE_ESCALATION_POLICY_SORT = 'UPDATE_ESCALATION_POLICY_SORT'
const updateEscalationPolicySortAction = payload => ({
  type: UPDATE_ESCALATION_POLICY_SORT,
  payload,
})

export {
  UNSET_SITE_USER_IDS,
  unsetSiteUserIdsAction,
  UPDATE_ESCALATION_POLICY_SORT,
  updateEscalationPolicySortAction,
}
