import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Tooltip,
  Spin,
} from './common/Ant'
import { H3 } from './common/Headers'
import { Anchor, ActionAnchor } from './common/Anchor'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { USER_PERMISSIONS, ROLES } from '../constants'

class AllGatewayLocations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: this.props.gatewayLocations,
      sortedInfo: {},
    }
  }

  static propTypes = {
    deleteGatewayMapLocation: PropTypes.func.isRequired,
    gatewayLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    isGatewayLoading: PropTypes.bool.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.gatewayLocations })
  }

  render() {
    const {
      deleteGatewayMapLocation,
      windowWidth,
      isGatewayLoading,
    } = this.props
    const { data, sortedInfo } = this.state

    const isFixedColumn = windowWidth < 750

    return (
      <section className="AllGatewayLocations">
        <H3 inline>Network Gateway Locations</H3>
        {isGatewayLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            rowKey="gatewayId"
            pagination={false}
            scroll={{
              x: hasPermission(USER_PERMISSIONS.DELETE_LOCATION) ? 850 : 750,
            }}
            tableLayout="auto"
            bordered
          >
            <Column
              title="Network Gateway Name"
              dataIndex="gatewayName"
              sorter={sortBy('gatewayName')}
              sortOrder={sortedInfo.field === 'gatewayName' && sortedInfo.order}
              width={300}
              render={(text, record) => (
                <Anchor to={`/inventory/gateways/${record.gatewayPillarId}`}>
                  {text}
                </Anchor>
              )}
            />
            <Column
              title="Pillar ID"
              dataIndex="gatewayPillarId"
              sorter={sortBy('gatewayPillarId')}
              width={200}
              sortOrder={
                sortedInfo.field === 'gatewayPillarId' && sortedInfo.order
              }
              render={(text, record) =>
                text ? (
                  record.online ? (
                    <Tooltip title="Online">
                      <Icon type="check-circle-o" className="green" />
                      <span className="pl2">{text}</span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Offline">
                      <Icon type="poweroff" className="red" />
                      <span className="pl2">{text}</span>
                    </Tooltip>
                  )
                ) : (
                  '--'
                )
              }
            />
            <Column
              title="Description"
              dataIndex="description"
              width={300}
              render={text => (text ? text : '--')}
            />
            {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
              <Column
                title="Module ID"
                dataIndex="moduleId"
                width={200}
                render={text =>
                  text ? (
                    <Anchor
                      to={`https://conductor.link-labs.com/gateway/${text}`}
                    >
                      {text}
                    </Anchor>
                  ) : (
                    '--'
                  )
                }
              />
            )}
            {hasPermission(USER_PERMISSIONS.DELETE_LOCATION) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={100}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="delete-gateway-map-location">
                        <ActionAnchor
                          onClick={() =>
                            deleteGatewayMapLocation(record.gatewayId)
                          }
                        >
                          Remove
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )

                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllGatewayLocations
