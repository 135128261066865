import {
  fork,
  call,
  take,
  takeEvery,
  takeLatest,
  put,
  all,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllFlowMonitorsAction,
  getAllSiteFlowMonitorAction,
  getFlowMonitorDataAction,
  getFlowMonitorAction,
  updateFlowMonitorAction,
  createFlowMonitorAction,
  deleteFlowMonitorAction,
  getAllAvailableFlowMonitorsBySiteAction,
  getFlowMonitorEditHistoryAction,
  getFlowMonitorAssignmentHistoryAction,
  getValveStatusHistoryAction,
  getAllAvailableWaterValvesAction,
  getAllAvailableWaterMetersAction,
  getAllWaterMetersAction,
  getWaterMeterAction,
  createWaterMeterAction,
  updateWaterMeterAction,
  deleteWaterMeterAction,
  getAllWaterValvesAction,
  getWaterValveAction,
  createWaterValveAction,
  updateWaterValveAction,
  deleteWaterValveAction,
} from '../actions/flowMonitorsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllFlowMonitorsSaga(qs) {
  try {
    const payload = yield call(api.getAllFlowMonitors, qs)
    yield put(getAllFlowMonitorsAction.success(payload))
  } catch (error) {
    yield put(getAllFlowMonitorsAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSiteAllFlowMonitorsDataSaga({ payload }) {
  try {
    const result = yield call(api.getAllFlowMonitorsData, payload)
    yield put(getAllSiteFlowMonitorAction.success(result))
  } catch (error) {
    yield put(getAllSiteFlowMonitorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetFlowMonitorDataSaga({ payload }) {
  try {
    const result = yield call(api.getFlowMonitorData, payload)
    yield put(getFlowMonitorDataAction.success(result))
  } catch (error) {
    yield put(getFlowMonitorDataAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetFlowMonitorSaga(id) {
  try {
    const payload = yield call(api.getFlowMonitor, id)
    yield put(getFlowMonitorAction.success(payload))
  } catch (error) {
    yield put(getFlowMonitorAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateFlowMonitorSaga(flowMonitor) {
  try {
    let flowJson = {}
    flowMonitor['podId'] = flowMonitor['podPillarId']
    flowJson['flowJson'] = flowMonitor

    const payload = yield call(
      api.updateFlowMonitor,
      flowJson,
      flowMonitor['id']
    )
    yield put(getFlowMonitorAction.request(flowMonitor['id']))
    yield put(updateFlowMonitorAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/flow-monitors/${payload.id}`))
  } catch (error) {
    yield put(updateFlowMonitorAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateFlowMonitorSaga(flowMonitor) {
  try {
    let flowJson = {}
    flowMonitor['podId'] = flowMonitor['podPillarId']
    flowJson['flowJson'] = flowMonitor

    const payload = yield call(
      api.createFlowMonitor,
      flowJson,
      flowMonitor['id']
    )
    yield put(createFlowMonitorAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/flow-monitors/${payload.id}`))
  } catch (error) {
    yield put(createFlowMonitorAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteFlowMonitorSaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteFlowMonitor, id)
      yield fork(handleGetAllFlowMonitorsSaga)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteFlowMonitorAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllWaterMetersSaga({ payload }) {
  try {
    const result = yield call(api.getAllWaterMeters, payload)
    yield put(getAllWaterMetersAction.success(result))
  } catch (error) {
    yield put(getAllWaterMetersAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetWaterMeterSaga({ payload }) {
  try {
    const result = yield call(api.getWaterMeter, payload)
    yield put(getWaterMeterAction.success(result))
  } catch (error) {
    yield put(getWaterMeterAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateWaterMeterSaga({ payload }) {
  try {
    let meterJson = {}
    meterJson['meterJson'] = payload

    const result = yield call(api.updateWaterMeter, meterJson, payload['id'])
    yield put(getWaterMeterAction.request(payload['id']))
    yield put(updateWaterMeterAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/all/water-meters`))
  } catch (error) {
    yield put(updateWaterMeterAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateWaterMeterSaga({ payload }) {
  try {
    let meterJson = {}
    meterJson['meterJson'] = payload

    const result = yield call(api.createWaterMeter, meterJson, payload['id'])
    yield put(createWaterMeterAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/all/water-meters`))
  } catch (error) {
    yield put(createWaterMeterAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteWaterMeterSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteWaterMeter, payload)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
      yield put(getAllWaterMetersAction.request())
    }
  } catch (error) {
    yield put(deleteWaterMeterAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllWaterValvesSaga({ payload }) {
  try {
    const result = yield call(api.getAllWaterValves, payload)
    yield put(getAllWaterValvesAction.success(result))
  } catch (error) {
    yield put(getAllWaterValvesAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetWaterValveSaga({ payload }) {
  try {
    const result = yield call(api.getWaterValve, payload)
    yield put(getWaterValveAction.success(result))
  } catch (error) {
    yield put(getWaterValveAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateWaterValveSaga({ payload }) {
  try {
    let valveJson = {}
    valveJson['valveJson'] = payload

    const result = yield call(api.updateWaterValve, valveJson, payload['id'])
    yield put(getWaterValveAction.request(payload['id']))
    yield put(updateWaterValveAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/all/water-valves`))
  } catch (error) {
    yield put(updateWaterValveAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateWaterValveSaga({ payload }) {
  try {
    let valveJson = {}
    valveJson['valveJson'] = payload

    const result = yield call(api.createWaterValve, valveJson, payload['id'])
    yield put(createWaterValveAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/all/water-valves`))
  } catch (error) {
    yield put(createWaterValveAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteWaterValveSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteWaterValve, payload)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
      yield put(getAllWaterValvesAction.request())
    }
  } catch (error) {
    yield put(deleteWaterValveAction.failure())
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllAvailableFlowMonitorsBySiteSaga({ payload }) {
  try {
    const result = yield call(api.getAllAvailableFlowMonitorsBySite, payload)
    yield put(getAllAvailableFlowMonitorsBySiteAction.success(result))
  } catch (error) {
    yield put(getAllAvailableFlowMonitorsBySiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllAvailableWaterValvesSaga() {
  try {
    const result = yield call(api.getAllAvailableWaterValves)
    yield put(getAllAvailableWaterValvesAction.success(result))
  } catch (error) {
    yield put(getAllAvailableWaterValvesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllAvailableWaterMetersSaga() {
  try {
    const result = yield call(api.getAllAvailableWaterMeters)
    yield put(getAllAvailableWaterMetersAction.success(result))
  } catch (error) {
    yield put(getAllAvailableWaterMetersAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetFlowMonitorAssignmentHistorySaga(id) {
  try {
    const result = yield call(api.getFlowMonitorAssignmentHistory, id)
    yield put(getFlowMonitorAssignmentHistoryAction.success(result))
  } catch (error) {
    yield put(getFlowMonitorAssignmentHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetValveStatusHistorySaga(id) {
  try {
    const result = yield call(api.getValveStatusHistory, id)
    yield put(getValveStatusHistoryAction.success(result))
  } catch (error) {
    yield put(getValveStatusHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetFlowMonitorEditHistorySaga(id) {
  try {
    const result = yield call(api.getFlowMonitorEditHistory, id)
    yield put(getFlowMonitorEditHistoryAction.success(result))
  } catch (error) {
    yield put(getFlowMonitorEditHistoryAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllFlowMonitorsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllFlowMonitorsAction.REQUEST)
    yield fork(handleGetAllFlowMonitorsSaga, qs)
  }
}

function* watchGetSiteAllFlowMonitorsDataSaga() {
  yield takeLatest(
    getAllSiteFlowMonitorAction.REQUEST,
    handleGetSiteAllFlowMonitorsDataSaga
  )
}

function* watchGetFlowMonitorDataSaga() {
  yield takeLatest(
    getFlowMonitorDataAction.REQUEST,
    handleGetFlowMonitorDataSaga
  )
}

function* watchGetFlowMonitorSaga() {
  while (true) {
    const { payload } = yield take(getFlowMonitorAction.REQUEST)
    yield fork(handleGetFlowMonitorSaga, payload)
  }
}

function* watchUpdateFlowMonitorSaga() {
  while (true) {
    const { payload } = yield take(updateFlowMonitorAction.REQUEST)
    yield fork(handleUpdateFlowMonitorSaga, payload)
  }
}

function* watchCreateFlowMonitorSaga() {
  while (true) {
    const { payload } = yield take(createFlowMonitorAction.REQUEST)
    yield fork(handleCreateFlowMonitorSaga, payload)
  }
}

function* watchDeleteFlowMonitorSaga() {
  while (true) {
    const { payload } = yield take(deleteFlowMonitorAction.REQUEST)
    yield fork(handleDeleteFlowMonitorSaga, payload)
  }
}

function* watchGetAllWaterMetersSaga() {
  yield takeLatest(getAllWaterMetersAction.REQUEST, handleGetAllWaterMetersSaga)
}

function* watchGetWaterMeterSaga() {
  yield takeLatest(getWaterMeterAction.REQUEST, handleGetWaterMeterSaga)
}

function* watchUpdateWaterMeterSaga() {
  yield takeLatest(updateWaterMeterAction.REQUEST, handleUpdateWaterMeterSaga)
}

function* watchCreateWaterMeterSaga() {
  yield takeLatest(createWaterMeterAction.REQUEST, handleCreateWaterMeterSaga)
}

function* watchDeleteWaterMeterSaga() {
  yield takeLatest(deleteWaterMeterAction.REQUEST, handleDeleteWaterMeterSaga)
}

function* watchGetAllWaterValvesSaga() {
  yield takeLatest(getAllWaterValvesAction.REQUEST, handleGetAllWaterValvesSaga)
}

function* watchGetWaterValveSaga() {
  yield takeLatest(getWaterValveAction.REQUEST, handleGetWaterValveSaga)
}

function* watchUpdateWaterValveSaga() {
  yield takeLatest(updateWaterValveAction.REQUEST, handleUpdateWaterValveSaga)
}

function* watchCreateWaterValveSaga() {
  yield takeLatest(createWaterValveAction.REQUEST, handleCreateWaterValveSaga)
}

function* watchDeleteWaterValveSaga() {
  yield takeLatest(deleteWaterValveAction.REQUEST, handleDeleteWaterValveSaga)
}

function* watchGetAllAvailableFlowMonitorsBySiteSaga() {
  yield takeEvery(
    getAllAvailableFlowMonitorsBySiteAction.REQUEST,
    handleGetAllAvailableFlowMonitorsBySiteSaga
  )
}

function* watchGetAllAvailableWaterValvesSaga() {
  yield takeEvery(
    getAllAvailableWaterValvesAction.REQUEST,
    handleGetAllAvailableWaterValvesSaga
  )
}

function* watchGetAllAvailableWaterMetersSaga() {
  yield takeEvery(
    getAllAvailableWaterMetersAction.REQUEST,
    handleGetAllAvailableWaterMetersSaga
  )
}

function* watchGetFlowMonitorAssignmentHistorySaga() {
  while (true) {
    const { payload } = yield take(
      getFlowMonitorAssignmentHistoryAction.REQUEST
    )
    yield fork(handleGetFlowMonitorAssignmentHistorySaga, payload)
  }
}

function* watchGetValveStatusHistorySaga() {
  while (true) {
    const { payload } = yield take(getValveStatusHistoryAction.REQUEST)
    yield fork(handleGetValveStatusHistorySaga, payload)
  }
}

function* watchGetFlowMonitorEditHistorySaga() {
  while (true) {
    const { payload } = yield take(getFlowMonitorEditHistoryAction.REQUEST)
    yield fork(handleGetFlowMonitorEditHistorySaga, payload)
  }
}

function* flowMonitorsSaga() {
  yield all([
    fork(watchGetAllFlowMonitorsSaga),
    fork(watchGetSiteAllFlowMonitorsDataSaga),
    fork(watchGetFlowMonitorDataSaga),
    fork(watchGetFlowMonitorSaga),
    fork(watchUpdateFlowMonitorSaga),
    fork(watchCreateFlowMonitorSaga),
    fork(watchDeleteFlowMonitorSaga),
    fork(watchGetAllWaterMetersSaga),
    fork(watchGetWaterMeterSaga),
    fork(watchUpdateWaterMeterSaga),
    fork(watchCreateWaterMeterSaga),
    fork(watchDeleteWaterMeterSaga),
    fork(watchGetAllWaterValvesSaga),
    fork(watchGetWaterValveSaga),
    fork(watchUpdateWaterValveSaga),
    fork(watchCreateWaterValveSaga),
    fork(watchDeleteWaterValveSaga),
    fork(watchGetAllAvailableFlowMonitorsBySiteSaga),
    fork(watchGetAllAvailableWaterValvesSaga),
    fork(watchGetAllAvailableWaterMetersSaga),
    fork(watchGetFlowMonitorAssignmentHistorySaga),
    fork(watchGetValveStatusHistorySaga),
    fork(watchGetFlowMonitorEditHistorySaga),
  ])
}

export {
  flowMonitorsSaga as default,
  watchGetAllFlowMonitorsSaga,
  watchGetSiteAllFlowMonitorsDataSaga,
  watchGetFlowMonitorDataSaga,
  watchGetFlowMonitorSaga,
  watchUpdateFlowMonitorSaga,
  watchCreateFlowMonitorSaga,
  watchDeleteFlowMonitorSaga,
  watchGetAllWaterMetersSaga,
  watchGetWaterMeterSaga,
  watchUpdateWaterMeterSaga,
  watchCreateWaterMeterSaga,
  watchDeleteWaterMeterSaga,
  watchGetAllWaterValvesSaga,
  watchGetWaterValveSaga,
  watchUpdateWaterValveSaga,
  watchCreateWaterValveSaga,
  watchDeleteWaterValveSaga,
  watchGetAllAvailableFlowMonitorsBySiteSaga,
  watchGetAllAvailableWaterValvesSaga,
  watchGetAllAvailableWaterMetersSaga,
  watchGetFlowMonitorAssignmentHistorySaga,
  watchGetValveStatusHistorySaga,
  watchGetFlowMonitorEditHistorySaga,
  handleGetAllFlowMonitorsSaga,
  handleGetSiteAllFlowMonitorsDataSaga,
  handleGetFlowMonitorDataSaga,
  handleGetFlowMonitorSaga,
  handleUpdateFlowMonitorSaga,
  handleCreateFlowMonitorSaga,
  handleDeleteFlowMonitorSaga,
  handleGetAllWaterMetersSaga,
  handleGetWaterMeterSaga,
  handleUpdateWaterMeterSaga,
  handleCreateWaterMeterSaga,
  handleDeleteWaterMeterSaga,
  handleGetAllWaterValvesSaga,
  handleGetWaterValveSaga,
  handleUpdateWaterValveSaga,
  handleCreateWaterValveSaga,
  handleDeleteWaterValveSaga,
  handleGetAllAvailableFlowMonitorsBySiteSaga,
  handleGetAllAvailableWaterValvesSaga,
  handleGetAllAvailableWaterMetersSaga,
  handleGetFlowMonitorAssignmentHistorySaga,
  handleGetValveStatusHistorySaga,
  handleGetFlowMonitorEditHistorySaga,
}
