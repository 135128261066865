import { createFormAction } from 'redux-form-saga'

const getAllLocationsBySiteAction = createFormAction(
  'GET_ALL_LOCATIONS_BY_SITE'
)
const getAllLocationsByFloorAction = createFormAction(
  'GET_ALL_LOCATIONS_BY_FLOOR'
)

const getAllLocationsSnapshotDataByFloorAction = createFormAction(
  'GET_ALL_LOCATIONS_SNAPSHOT_DATA_BY_FLOOR'
)

const getLocationAction = createFormAction('GET_LOCATION')
const getLocationChartDataAction = createFormAction('GET_LOCATION_DATA')
const createLocationAction = createFormAction('CREATE_LOCATION')
const updateLocationAction = createFormAction('UPDATE_LOCATION')
const deleteLocationAction = createFormAction('DELETE_LOCATION')
const getPodAssignmentHistoryByLocationAction = createFormAction(
  'GET_POD_ASSIGNMENT_HISTORY_BY_LOCATION'
)
const getAllThresholdsByLocationAction = createFormAction(
  'GET_ALL_THRESHOLDS_BY_LOCATION'
)
const addThresholdToLocationAction = createFormAction(
  'ADD_THRESHOLD_TO_LOCATION'
)
const deleteThresholdFromLocationAction = createFormAction(
  'DELETE_THRESHOLD_FROM_LOCATION'
)
const toggleLocationOptionAction = createFormAction('TOGGLE_LOCATION_OPTION')
const createMapLocationAction = createFormAction('CREATE_MAP_LOCATION')

const createGatewayMapLocationAction = createFormAction(
  'CREATE_GATEWAY_MAP_LOCATION'
)
const updateGatewayMapLocationAction = createFormAction(
  'UPDATE_GATEWAY_MAP_LOCATION'
)
const deleteGatewayMapLocationAction = createFormAction(
  'DELETE_GATEWAY_MAP_LOCATION'
)

// TODO rename updateMapLocationAction
const updateMapLocationCoordinatesAction = createFormAction(
  'UPDATE_MAP_LOCATION_COORDINATES'
)
const deleteMapLocationAction = createFormAction('DELETE_MAP_LOCATION')

const addEscalationPolicytoLocationThresholdAction = createFormAction(
  'ADD_ESCALATION_POLICY_TO_LOCATION_THRESHOLD'
)

const REMOVE_POD_FROM_LOCATION = 'REMOVE_POD_FROM_LOCATION'
const REMOVE_FLOW_MONITOR_FROM_LOCATION = 'REMOVE_FLOW_MONITOR_FROM_LOCATION'
const CLEAR_LOCATION_CHART_DATA = 'CLEAR_LOCATION_CHART_DATA'
const CLEAR_VISIBLE_LOCATIONS = 'CLEAR_VISIBLE_LOCATIONS'
const SET_MAP_LOCATION_COORDINATES = 'SET_MAP_LOCATION_COORDINATES'

const removePodFromLocationAction = () => ({ type: REMOVE_POD_FROM_LOCATION })
const removeFlowMonitorFromLocationAction = () => ({
  type: REMOVE_FLOW_MONITOR_FROM_LOCATION,
})
const clearLocationChartDataAction = () => ({ type: CLEAR_LOCATION_CHART_DATA })
const clearVisibleLocationsAction = () => ({ type: CLEAR_VISIBLE_LOCATIONS })
const setMapLocationCoordinatesAction = payload => ({
  type: SET_MAP_LOCATION_COORDINATES,
  payload,
})

const getLocationTimeSeriesDataByLocationIdsAction = createFormAction(
  'GET_LOCATION_DATA_BY_IDS'
)

const getAllLocationThresholdUserMappingsAction = createFormAction(
  'GET_ALL_LOCATION_THRESHOLD_USER_MAPPINGS'
)
const updateLocationThresholdUserMappingAction = createFormAction(
  'UPDATE_LOCATION_THRESHOLD_USER_MAPPING'
)

const getLocationSnapshotAction = createFormAction('GET_LOCATION_SNAPSHOT')

export {
  REMOVE_POD_FROM_LOCATION,
  REMOVE_FLOW_MONITOR_FROM_LOCATION,
  CLEAR_LOCATION_CHART_DATA,
  removePodFromLocationAction,
  removeFlowMonitorFromLocationAction,
  getAllLocationsBySiteAction,
  getAllLocationsByFloorAction,
  getLocationAction,
  getLocationChartDataAction,
  createLocationAction,
  updateLocationAction,
  deleteLocationAction,
  getAllThresholdsByLocationAction,
  addThresholdToLocationAction,
  deleteThresholdFromLocationAction,
  toggleLocationOptionAction,
  clearLocationChartDataAction,
  createMapLocationAction,
  updateGatewayMapLocationAction,
  createGatewayMapLocationAction,
  deleteGatewayMapLocationAction,
  deleteMapLocationAction,
  setMapLocationCoordinatesAction,
  SET_MAP_LOCATION_COORDINATES,
  updateMapLocationCoordinatesAction,
  CLEAR_VISIBLE_LOCATIONS,
  clearVisibleLocationsAction,
  getAllLocationsSnapshotDataByFloorAction,
  getLocationTimeSeriesDataByLocationIdsAction,
  addEscalationPolicytoLocationThresholdAction,
  getAllLocationThresholdUserMappingsAction,
  updateLocationThresholdUserMappingAction,
  getPodAssignmentHistoryByLocationAction,
  getLocationSnapshotAction,
}
