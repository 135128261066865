const required = value => {
  if (value === 0) {
    return undefined
  }

  return value ? undefined : 'Required'
}

const exactLength = length => value =>
  value && value.length !== length ? `Must be ${length} characters` : undefined

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined

const match = firstValue => secondValue =>
  secondValue && secondValue !== firstValue
    ? `Password does not match`
    : undefined

const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const isEmail = value =>
  value && !emailRegExp.test(value) ? 'Invalid email address' : undefined

const phoneNumberRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

const isPhoneNumber = value =>
  value && !phoneNumberRegExp.test(value) ? 'Invalid phone number' : undefined

const isNumeric = value =>
  value && /[^\-0-9. ]/i.test(value) ? 'Only numbers allowed' : undefined

const isAlphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only letters and numbers allowed'
    : undefined

const isBetween0and1 = value =>
  value && value > 0 && value < 1
    ? undefined
    : 'Value must be between 0 and 1 (exclusive)'

export {
  required,
  maxLength,
  minLength,
  match,
  exactLength,
  isEmail,
  isNumeric,
  isAlphaNumeric,
  isBetween0and1,
  isPhoneNumber,
}
