import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Tooltip,
  Spin,
  RadioButton,
  RadioGroup,
} from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import SearchFilter from './common/SearchFilter'
import Select, { Option } from './common/Select'
import { toTitleCase, formatSIMCarrier } from '../utils/textFormatters'
import { hasPermission } from '../utils/hasPermission'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  GATEWAY_STATUS,
  SIM_CARRIERS,
  GATEWAY_TYPES,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  VIEW_ALL,
  CONNECTIVITY_STATUS,
  USER_PERMISSIONS,
} from '../constants'
import { configurePagination } from '../utils/helpers'

class AllGateways extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.gateways,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      siteSlug: undefined,
      online: undefined,
      status: undefined,
      simType: undefined,
      gatewayType: undefined,
    }
  }

  static propTypes = {
    gateways: PropTypes.arrayOf(PropTypes.object).isRequired,
    sites: PropTypes.arrayOf(PropTypes.object),
    windowWidth: PropTypes.number.isRequired,
    meta: PropTypes.object,
    getAllGateways: PropTypes.func,
    getAllSites: PropTypes.func,
    deleteGateway: PropTypes.func,
    deleteGatewayFromSite: PropTypes.func,
    isRenderingSiteView: PropTypes.bool,
    isGatewayLoading: PropTypes.bool,
    updateQueryParams: PropTypes.func,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.gateways })
  }

  componentDidMount() {
    const {
      page,
      perPage,
      siteSlug,
      online,
      status,
      simType,
      gatewayType,
    } = parseQueryParams(global.location)
    const { getAllGateways, getAllSites } = this.props
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      online,
      status,
      siteSlug,
      simType,
      gatewayType,
    }
    const allQs = createQueryString({ perPage: 'all' })

    this.setState(query)

    getAllGateways && getAllGateways(createQueryString(query))
    getAllSites && getAllSites(allQs)
  }

  handleChange = (pagination, filters, sorter) => {
    const {
      getAllGateways,
      updateQueryParams,
      isRenderingSiteView,
    } = this.props
    const {
      page,
      perPage,
      online,
      status,
      siteSlug,
      simType,
      gatewayType,
    } = this.state

    if (!isRenderingSiteView && page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
        online,
        status,
        siteSlug,
        simType,
        gatewayType,
      }

      const qs = createQueryString(query)
      getAllGateways && getAllGateways(qs)

      updateQueryParams && updateQueryParams({ search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllGateways, updateQueryParams } = this.props
    const {
      page,
      perPage,
      online,
      status,
      siteSlug,
      simType,
      gatewayType,
    } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    const query = Object.assign(
      {
        page,
        perPage,
        online,
        status,
        siteSlug,
        simType,
        gatewayType,
      },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllGateways(qs)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }

  setSearchText = searchText => this.setState({ searchText })

  render() {
    const {
      deleteGateway,
      deleteGatewayFromSite,
      getAllGateways,
      meta,
      windowWidth,
      isGatewayLoading,
      isRenderingSiteView,
      sites,
    } = this.props
    const {
      data,
      sortedInfo,
      searchText,
      page,
      perPage,
      online,
      status,
      siteSlug,
      simType,
      gatewayType,
    } = this.state

    const isFixedColumn = windowWidth < 750

    const isFiltering = !!(
      (online && online.length) ||
      (status && status.length) ||
      (siteSlug && siteSlug.length) ||
      (simType && simType.length) ||
      (gatewayType && gatewayType.length)
    )

    const query = {
      page,
      perPage,
      online,
      status,
      siteSlug,
      simType,
      gatewayType,
    }

    return (
      <section className="mb5 AllGateways">
        {!isRenderingSiteView && hasPermission(USER_PERMISSIONS.ADD_GATEWAY) && (
          <div>
            <Anchor to="/inventory/gateways/create" className="mb3" button>
              Create New
            </Anchor>
            <div className="dib red">
              {isFiltering && (
                <p className="pl3">
                  Found {meta.total} gateways matching your criteria
                </p>
              )}
            </div>
          </div>
        )}
        {!isRenderingSiteView && (
          <Fragment>
            <div className="flex-l items-center-l">
              <RadioGroup
                onChange={({ target: { value } }) =>
                  this.handleFilterChange('online')(value)
                }
                value={online}
                className="w-25-l mb3 mr3 mr3-l"
                size="small"
              >
                <RadioButton value={undefined}>All</RadioButton>
                <RadioButton value={CONNECTIVITY_STATUS.ONLINE}>
                  Online
                </RadioButton>
                <RadioButton value={CONNECTIVITY_STATUS.OFFLINE}>
                  Offline
                </RadioButton>
              </RadioGroup>
              <Select
                className="w-third-l mr3-l"
                placeholder="Status"
                mode="multiple"
                input={{
                  value: status,
                  onChange: this.handleFilterChange('status'),
                }}
                filterable
              >
                {['all', ...Object.keys(GATEWAY_STATUS)].map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(GATEWAY_STATUS[x]) || VIEW_ALL}
                  </Option>
                ))}
              </Select>
              <Select
                className="w-third-l mr3-l"
                placeholder="SIM Type"
                mode="multiple"
                input={{
                  value: simType,
                  onChange: this.handleFilterChange('simType'),
                }}
                filterable
              >
                {['all', ...Object.keys(SIM_CARRIERS)].map(x => (
                  <Option value={x} key={x}>
                    {formatSIMCarrier(SIM_CARRIERS[x]) || VIEW_ALL}
                  </Option>
                ))}
              </Select>
              <Select
                className="w-third-l mr3-l"
                placeholder="Network Gateway Type"
                mode="multiple"
                input={{
                  value: gatewayType,
                  onChange: this.handleFilterChange('gatewayType'),
                }}
                filterable
              >
                {['all', ...Object.keys(GATEWAY_TYPES)].map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(GATEWAY_TYPES[x]) || VIEW_ALL}
                  </Option>
                ))}
              </Select>
              <Select
                className="w-third-l"
                placeholder="Site"
                mode="multiple"
                input={{
                  value: siteSlug,
                  onChange: this.handleFilterChange('siteSlug'),
                }}
                filterable
              >
                {[{ slug: 'all', name: VIEW_ALL }, ...sites].map(
                  ({ slug, name }) => (
                    <Option value={slug} key={slug}>
                      {name}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <SearchFilter
              searchText={searchText}
              setSearchText={this.setSearchText}
              getItems={getAllGateways}
              query={query}
            />
          </Fragment>
        )}
        {isGatewayLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{ x: 650 }}
            bordered
            pagination={
              !isRenderingSiteView &&
              configurePagination({
                perPage,
                onShowSizeChange: (page, perPage) =>
                  this.handleFilterChange('perPage')(perPage),
                ...meta,
              })
            }
            tableLayout="auto"
          >
            <Column
              title="Pillar ID"
              dataIndex="gatewayPillarId"
              sorter={sortBy('gatewayPillarId')}
              sortOrder={
                sortedInfo.field === 'gatewayPillarId' && sortedInfo.order
              }
              width={150}
              render={(text, record) => {
                const id = <span className="pl2">{text}</span>

                return record.online ? (
                  <span className="flex items-center">
                    <Tooltip title="Online">
                      <Icon type="check-circle-o" className="green" />{' '}
                      <span className="b">{id}</span>
                    </Tooltip>
                  </span>
                ) : (
                  <span className="flex items-center">
                    <Tooltip title="Offline">
                      <Icon type="poweroff" className="red" /> {id}
                    </Tooltip>
                  </span>
                )
              }}
            />
            <Column
              title="Name"
              dataIndex="gatewayName"
              sorter={sortBy('gatewayName')}
              sortOrder={sortedInfo.field === 'gatewayName' && sortedInfo.order}
              width={isRenderingSiteView ? 350 : 200}
              render={(text, record) =>
                hasPermission(USER_PERMISSIONS.VIEW_GATEWAY) ? (
                  <Anchor to={`/inventory/gateways/${record.gatewayPillarId}`}>
                    {text}
                  </Anchor>
                ) : (
                  text
                )
              }
            />
            <Column
              title="Monitoring"
              dataIndex="monitor"
              width={150}
              sorter={sortBy('monitor')}
              sortOrder={sortedInfo.field === 'monitor' && sortedInfo.order}
              render={text =>
                text ? <span className="b">Active</span> : 'Inactive'
              }
            />
            {!isRenderingSiteView && (
              <Column
                title="Site"
                dataIndex="siteName"
                width={150}
                render={(text, record) =>
                  text ? (
                    <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
                  ) : (
                    '--'
                  )
                }
              />
            )}
            {!isRenderingSiteView && (
              <Column
                title="Asset Location"
                dataIndex="assetLocationPillarId"
                sorter={sortBy('assetLocationPillarId')}
                sortOrder={
                  sortedInfo.field === 'assetLocationPillarId' &&
                  sortedInfo.order
                }
                width={100}
                render={(text, record) =>
                  text ? (
                    <Anchor
                      to={`/inventory/asset-locations/${record.assetLocationPillarId}`}
                    >
                      {text}
                    </Anchor>
                  ) : (
                    '--'
                  )
                }
              />
            )}
            {!isRenderingSiteView && (
              <Column
                title="Status"
                dataIndex="status"
                sorter={sortBy('status')}
                sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
                width={100}
                render={text => toTitleCase(text)}
              />
            )}
            <Column
              title="Type"
              dataIndex="gatewayType"
              sorter={sortBy('gatewayType')}
              sortOrder={sortedInfo.field === 'gatewayType' && sortedInfo.order}
              width={100}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="SIM Type"
              dataIndex="simType"
              sorter={sortBy('simType')}
              sortOrder={sortedInfo.field === 'simType' && sortedInfo.order}
              width={100}
              render={text =>
                text ? formatSIMCarrier(SIM_CARRIERS[text]) : '--'
              }
            />
            {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY) &&
              (isRenderingSiteView ? (
                <Column
                  fixed={isFixedColumn ? 'right' : false}
                  width={100}
                  render={(text, record) => {
                    const menu = (
                      <Menu>
                        <Menu.Item key="edit-gateway">
                          <Anchor
                            to={`/inventory/gateways/${record.gatewayPillarId}/edit`}
                          >
                            Edit
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="delete-gateway">
                          <ActionAnchor
                            onClick={() => deleteGatewayFromSite(record.id)}
                          >
                            Remove
                          </ActionAnchor>
                        </Menu.Item>
                      </Menu>
                    )

                    return (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  }}
                />
              ) : (
                <Column
                  fixed={isFixedColumn ? 'right' : false}
                  width={100}
                  render={(text, record) => {
                    const menu = (
                      <Menu>
                        <Menu.Item key="link-labs-gateway">
                          <Anchor
                            to={`https://conductor.link-labs.com/gateway/${record.moduleId}`}
                          >
                            View in Link Labs
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="edit-gateway">
                          <Anchor
                            to={`/inventory/gateways/${record.gatewayPillarId}/edit`}
                          >
                            Edit
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="delete-gateway">
                          <ActionAnchor
                            onClick={() => deleteGateway(record.id)}
                          >
                            Delete
                          </ActionAnchor>
                        </Menu.Item>
                      </Menu>
                    )

                    return (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  }}
                />
              ))}
          </Table>
        )}
      </section>
    )
  }
}

export default AllGateways
