import { createFormAction } from 'redux-form-saga'

const getAllFlowMonitorsAction = createFormAction('GET_ALL_FLOW_MONITORS')
const getAllSiteFlowMonitorAction = createFormAction(
  'GET_ALL_SITE_FLOW_MONITOR'
)
const getFlowMonitorDataAction = createFormAction('GET_FLOW_MONITOR_DATA')
const getFlowMonitorAction = createFormAction('GET_FLOW_MONITOR')
const createFlowMonitorAction = createFormAction('CREATE_FLOW_MONITOR')
const updateFlowMonitorAction = createFormAction('UPDATE_FLOW_MONITOR')
const deleteFlowMonitorAction = createFormAction('DELETE_FLOW_MONITOR')
const getAllAvailableFlowMonitorsBySiteAction = createFormAction(
  'GET_ALL_AVAILABLE_FLOW_MONITORS_BY_SITE'
)

const getFlowMonitorAssignmentHistoryAction = createFormAction(
  'GET_FLOW_MONITOR_ASSIGNMENT_HISTORY'
)
const getValveStatusHistoryAction = createFormAction('GET_VALVE_STATUS_HISTORY')
const getFlowMonitorEditHistoryAction = createFormAction(
  'GET_FLOW_MONITOR_EDIT_HISTORY'
)

const SET_WATER_METER_FIELDS = 'SET_WATER_METER_FIELDS'
const setWaterMeterFieldsAction = payload => ({
  type: SET_WATER_METER_FIELDS,
  payload,
})
const SET_WATER_VALVE_FIELDS = 'SET_WATER_VALVE_FIELDS'
const setWaterValveFieldsAction = payload => ({
  type: SET_WATER_VALVE_FIELDS,
  payload,
})

const getAllAvailableWaterValvesAction = createFormAction(
  'GET_ALL_AVAILABLE_WATER_VALVES'
)
const getAllAvailableWaterMetersAction = createFormAction(
  'GET_ALL_AVAILABLE_WATER_METERS'
)

const getAllWaterMetersAction = createFormAction('GET_ALL_WATER_METERS')
const getWaterMeterAction = createFormAction('GET_WATER_METER')
const createWaterMeterAction = createFormAction('CREATE_WATER_METER')
const updateWaterMeterAction = createFormAction('UPDATE_WATER_METER')
const deleteWaterMeterAction = createFormAction('DELETE_WATER_METER')
const getAllWaterValvesAction = createFormAction('GET_ALL_WATER_VALVES')
const getWaterValveAction = createFormAction('GET_WATER_VALVE')
const createWaterValveAction = createFormAction('CREATE_WATER_VALVE')
const updateWaterValveAction = createFormAction('UPDATE_WATER_VALVE')
const deleteWaterValveAction = createFormAction('DELETE_WATER_VALVE')

export {
  getAllFlowMonitorsAction,
  getAllSiteFlowMonitorAction,
  getFlowMonitorDataAction,
  getFlowMonitorAction,
  createFlowMonitorAction,
  updateFlowMonitorAction,
  deleteFlowMonitorAction,
  getAllAvailableFlowMonitorsBySiteAction,
  getFlowMonitorAssignmentHistoryAction,
  getValveStatusHistoryAction,
  getFlowMonitorEditHistoryAction,
  SET_WATER_VALVE_FIELDS,
  setWaterValveFieldsAction,
  SET_WATER_METER_FIELDS,
  setWaterMeterFieldsAction,
  getAllAvailableWaterValvesAction,
  getAllAvailableWaterMetersAction,
  getAllWaterMetersAction,
  getWaterMeterAction,
  createWaterMeterAction,
  updateWaterMeterAction,
  deleteWaterMeterAction,
  getAllWaterValvesAction,
  getWaterValveAction,
  createWaterValveAction,
  updateWaterValveAction,
  deleteWaterValveAction,
}
