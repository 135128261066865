import { fork, call, take, all } from 'redux-saga/effects'
import { updateIntercom } from '../services/third-party/intercom'

function* handleUpdateIntercomSaga() {
  try {
    yield call(updateIntercom)
  } catch (error) {
    yield call(console.error('Error updating Intercom: ', error))
  }
}

function* watchRouterLocationChangeSaga() {
  while (true) {
    yield take('@@router/LOCATION_CHANGE')
    yield fork(handleUpdateIntercomSaga)
  }
}

function* thirdPartySaga() {
  yield all([fork(watchRouterLocationChangeSaga)])
}

export {
  thirdPartySaga as default,
  watchRouterLocationChangeSaga,
  handleUpdateIntercomSaga,
}
