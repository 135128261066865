import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers/createRootReducer'

const history = createBrowserHistory()

const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware()
  const historyMiddleware = routerMiddleware(history)

  return {
    ...createStore(
      createRootReducer(history),
      initialState,
      applyMiddleware(sagaMiddleware, historyMiddleware)
    ),
    runSaga: sagaMiddleware.run,
  }
}

const store = configureStore()

export { store as default, history }
