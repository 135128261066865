import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CommentContainer from '../containers/CommentContainer'
import EditCommentFormContainer from '../containers/EditCommentFormContainer'
import Divider from './common/Divider'
import { Spin } from './common/Ant'
import { createQueryString } from '../utils/queryParams'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'

class AllComments extends Component {
  static propTypes = {
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllComments: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    setCommentTime: PropTypes.func,
    editComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    resourceType: PropTypes.string.isRequired,
    resourceSlug: PropTypes.string,
    startTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isCommentLoading: PropTypes.bool,
    showDateFields: PropTypes.bool,
  }

  state = { currentComment: null }

  handleEditComment = comment => {
    this.setState({ currentComment: comment })

    // TODO: Convert this to use `ref` instead of vanilla JS
    // OR try using `ScrollToTop` wrapper component
    const element = document.getElementsByClassName('EditCommentForm')
    if (element.length) {
      element[0].scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleResetComment = () => this.setState({ currentComment: null })

  handleDeleteComment = comment => {
    const { deleteComment, resourceSlug } = this.props
    !!resourceSlug &&
      deleteComment({
        resourceSlug,
        commentSlug: comment.slug,
        resourceType: comment.resourceType,
      })
  }

  componentDidMount() {
    const {
      getAllTags,
      getAllComments,
      resourceType,
      resourceSlug,
    } = this.props

    const query = {
      resourceType,
      resourceSlug,
    }

    const qs = createQueryString(query)
    !!resourceSlug && getAllComments(qs)

    getAllTags(
      createQueryString({
        perPage: 'all',
      })
    )
  }

  render() {
    const {
      resourceType,
      tags,
      comments,
      startTime,
      endTime,
      createComment,
      editComment,
      resourceSlug,
      isCommentLoading,
      showDateFields,
      setCommentTime,
    } = this.props
    const { currentComment } = this.state
    return isCommentLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="AllComments">
        {hasPermission(USER_PERMISSIONS.ADD_COMMENT) ? (
          currentComment ? (
            <EditCommentFormContainer
              resourceSlug={resourceSlug}
              resourceType={resourceType}
              startTime={startTime}
              endTime={endTime}
              submitFn={editComment}
              tags={tags}
              comment={currentComment}
              isEditing
              showDateFields={showDateFields}
              resetCommentState={this.handleResetComment}
              setCommentTime={setCommentTime}
            />
          ) : (
            <EditCommentFormContainer
              resourceSlug={resourceSlug}
              resourceType={resourceType}
              startTime={startTime}
              endTime={endTime}
              submitFn={createComment}
              tags={tags}
              showDateFields={showDateFields}
              setCommentTime={setCommentTime}
            />
          )
        ) : null}
        {comments.length > 0 && (
          <div className="comments">
            <Divider />
            {comments.map(comment => (
              <CommentContainer
                key={comment.id}
                comment={comment}
                handleEditComment={this.handleEditComment}
                handleDeleteComment={this.handleDeleteComment}
              />
            ))}
          </div>
        )}
      </section>
    )
  }
}

export default AllComments
