import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Label = ({ children, className = '', noMargin }) => {
  const prefix = cx('Label db f6 fw6 lh-copy', { mb1: !noMargin }, className)

  return <label className={prefix}>{children}</label>
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noMargin: PropTypes.bool.isRequired,
}

Label.defaultProps = { noMargin: false }

export default Label
