import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spin, Tooltip, Icon } from './common/Ant'
import { ActionAnchor, Anchor } from './common/Anchor'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import Block from './common/Block'
import AllComments from './AllComments'
import { dateTimeFormatter } from '../utils/date'
import { toTitleCase, formatSIMCarrier } from '../utils/textFormatters'
import { hasPermission } from '../utils/hasPermission'
import { RESOURCE_TYPES, SIZES, USER_PERMISSIONS } from '../constants'

class GatewayAlert extends Component {
  static propTypes = {
    gatewayAlert: PropTypes.object.isRequired,
    getGatewayAlert: PropTypes.func.isRequired,
    restartGateway: PropTypes.func.isRequired,
    closeGatewayAlert: PropTypes.func,
    isGatewayAlertLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
    getAllComments: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  componentDidMount() {
    const { getGatewayAlert } = this.props
    getGatewayAlert(this.props.match.params.slug)
  }

  render() {
    const {
      gatewayAlert,
      isGatewayAlertLoading,
      comments,
      getAllComments,
      deleteComment,
      createComment,
      editComment,
      getAllTags,
      tags,
      restartGateway,
      closeGatewayAlert,
    } = this.props

    const {
      slug,
      siteName,
      siteSlug,
      startTime,
      endTime,
      gatewaySim,
      gatewayType,
      gatewayName,
      gatewayPillarId,
      active,
    } = gatewayAlert

    return isGatewayAlertLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="GatewayAlert mb3">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/alerts/all/gateways">Alerts</Anchor>
              </Crumb>
              <Crumb>
                Network Gateway Offline{' '}
                <Tooltip title="Offline">
                  <Icon type="poweroff" className="pl2 red" />
                </Tooltip>
              </Crumb>
            </Bread>
            <Bread size={SIZES.SMALL}>
              {siteName && (
                <Crumb>
                  <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
                </Crumb>
              )}
              <Crumb>
                {hasPermission(USER_PERMISSIONS.VIEW_GATEWAY) ? (
                  <Anchor to={`/inventory/gateways/${gatewayPillarId}`}>
                    Network Gateway {gatewayName}
                  </Anchor>
                ) : (
                  <span>Network Gateway {gatewayName}</span>
                )}
              </Crumb>
            </Bread>
          </div>
          {active && hasPermission(USER_PERMISSIONS.EDIT_GATEWAY_ALERT) && (
            <div className="flex justify-center mb3">
              <ActionAnchor onClick={() => closeGatewayAlert(slug)}>
                Close
              </ActionAnchor>
              <span>
                <Divider vertical />
                <ActionAnchor onClick={() => restartGateway(gatewayPillarId)}>
                  Restart Network Gateway
                </ActionAnchor>
              </span>
            </div>
          )}
        </div>
        <Divider />
        <div className="flex flex-wrap">
          <Block label="Start Time">
            {dateTimeFormatter(startTime)} <br />
          </Block>
          <Block label="End Time">
            {endTime ? dateTimeFormatter(endTime) : 'Ongoing'}
          </Block>
          <Block label="Network Gateway Type">{toTitleCase(gatewayType)}</Block>
          <Block label="Network Gateway SIM">
            {formatSIMCarrier(gatewaySim)}
          </Block>
        </div>
        <Divider />
        {hasPermission(USER_PERMISSIONS.VIEW_COMMENT) && (
          <AllComments
            resourceType={RESOURCE_TYPES.GATEWAY_ALERT}
            resourceSlug={slug}
            getAllComments={getAllComments}
            deleteComment={deleteComment}
            createComment={createComment}
            editComment={editComment}
            getAllTags={getAllTags}
            tags={tags}
            comments={comments}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </section>
    )
  }
}

export default GatewayAlert
