import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Input from '../../components/common/Input'
import { getRequiredFields } from '../../reducers/formReducer'

const InputContainer = ({ requiredFields, ...rest }) => (
  <Input requiredFields={requiredFields} {...rest} />
)

InputContainer.propTypes = {
  requiredFields: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = ({ form }) => ({
  requiredFields: getRequiredFields(form),
})

export default connect(mapStateToProps)(InputContainer)
