import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllAssetLocationsAction,
  getAssetLocationAction,
} from '../actions/assetLocationsActions'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAssetLocationsAction.SUCCESS:
        payload.items.forEach(assetLocation => {
          draft[assetLocation.id] = assetLocation
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAssetLocationsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(assetLocation => assetLocation.id)
        )
        break
    }
  })

const current = (
  state = { pods: [], gateways: [], flowMonitors: [] },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case getAssetLocationAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAssetLocationsAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const assetLocationsReducer = combineReducers({
  byId,
  visibleIds,
  current,
  meta,
})

const getAssetLocation = (state, id) => state.byId[id]
const getVisibleAssetLocations = state =>
  state.visibleIds.map(id => getAssetLocation(state, id))
const getCurrentAssetLocation = state => state.current

const getAssetLocationsMeta = state => state.meta

export {
  assetLocationsReducer as default,
  getVisibleAssetLocations,
  getCurrentAssetLocation,
  getAssetLocationsMeta,
}
