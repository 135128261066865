import store from '../store/configureStore'
import { ROLES } from '../constants'

const hasPermission = permission => {
  const {
    account: {
      profile: { permissions: userPermissions },
    },
  } = store.getState()

  return userPermissions.indexOf(permission) !== -1
}

const hasRole = (...args) => {
  const {
    account: {
      profile: { role: userRole },
    },
  } = store.getState()

  return args.indexOf(userRole) !== -1
}

const isDemoEmail = email => /.*\+demo@pillar.tech/.test(email)

const hasDemoModeAccess = user =>
  hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && isDemoEmail(user.email)

export { hasPermission, hasRole, hasDemoModeAccess }
