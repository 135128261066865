import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import AllUsers from '../AllUsers'
import AllLeads from '../AllLeads'
import CreateUser from '../CreateUser'
import User from '../User'
import EditUser from '../EditUser'
import PageWidth from '../PageWidth'
import { createQueryString } from '../../utils/queryParams'

class UsersPage extends Component {
  static propTypes = {
    getAllUsers: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    logMeta: PropTypes.object.isRequired,
    userCommunicationLog: PropTypes.arrayOf(PropTypes.object).isRequired,
    getUserCommunicationLog: PropTypes.func.isRequired,
    getUserPermissions: PropTypes.func.isRequired,
    updateUserPermissions: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    toggleUserOption: PropTypes.func.isRequired,
    revokeApiToken: PropTypes.func.isRequired,
    getAccessTokenHistory: PropTypes.func.isRequired,
    setDefaultSite: PropTypes.func.isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object),
    currentUser: PropTypes.object.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    isUserLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    leads: PropTypes.arrayOf(PropTypes.object),
    getAllLeads: PropTypes.func.isRequired,
    leadsMeta: PropTypes.object.isRequired,
    getAllSites: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    resendConfirmation: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const qs = createQueryString({ perPage: 'all' })

    this.props.getAllCompanies(qs)
  }

  render() {
    const {
      match: { url },
      users,
      currentUser,
      companies,
      getAllUsers,
      getUser,
      logMeta,
      userCommunicationLog,
      getUserCommunicationLog,
      getUserPermissions,
      updateUserPermissions,
      deleteUser,
      meta,
      toggleUserOption,
      setDefaultSite,
      windowWidth,
      isUserLoading,
      isHistoryLoading,
      updateQueryParams,
      revokeApiToken,
      getAccessTokenHistory,
      getAllLeads,
      leads,
      leadsMeta,
      getAllSites,
      sites,
      resendConfirmation,
    } = this.props

    return (
      <MainContent>
        <div className="UsersPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Users</title>
                    </Helmet>
                    <AllUsers
                      users={users}
                      getAllUsers={getAllUsers}
                      deleteUser={deleteUser}
                      updateQueryParams={updateQueryParams}
                      meta={meta}
                      windowWidth={windowWidth}
                      isUserLoading={isUserLoading}
                      companies={companies}
                      getAllSites={getAllSites}
                      sites={sites}
                      url={url}
                      resendConfirmation={resendConfirmation}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/leads`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Leads</title>
                    </Helmet>
                    <AllLeads
                      leads={leads}
                      getAllLeads={getAllLeads}
                      updateQueryParams={updateQueryParams}
                      meta={leadsMeta}
                      isUserLoading={isUserLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create User</title>
                    </Helmet>
                    <CreateUser companies={companies} {...props} />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentUser.fullName
                          ? `Edit ${currentUser.fullName}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditUser
                      user={currentUser}
                      companies={companies}
                      getUser={getUser}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:slug`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentUser.fullName || 'Loading...'}</title>
                    </Helmet>
                    <User
                      user={currentUser}
                      getUser={getUser}
                      meta={logMeta}
                      isHistoryLoading={isHistoryLoading}
                      userCommunicationLog={userCommunicationLog}
                      getUserCommunicationLog={getUserCommunicationLog}
                      getUserPermissions={getUserPermissions}
                      updateUserPermissions={updateUserPermissions}
                      toggleUserOption={toggleUserOption}
                      setDefaultSite={setDefaultSite}
                      updateQueryParams={updateQueryParams}
                      isUserLoading={isUserLoading}
                      revokeApiToken={revokeApiToken}
                      getAccessTokenHistory={getAccessTokenHistory}
                      {...props}
                    />
                  </Fragment>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default UsersPage
