import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditPurchaseForm from './forms/EditPurchaseForm'
import { H2 } from './common/Headers'
import { updatePurchaseAction } from '../actions/purchasesActions'
import { createQueryString } from '../utils/queryParams'

class EditPurchase extends Component {
  static propTypes = {
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    parts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    getAllParts: PropTypes.func.isRequired,
    purchase: PropTypes.object.isRequired,
    getPurchase: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getPurchase, match, getAllParts, getAllAssetLocations } = this.props
    getPurchase(match.params.id)

    const allQs = createQueryString({ perPage: 'all' })
    getAllParts(allQs)
    getAllAssetLocations(allQs)
  }

  render() {
    const { purchase, parts, assetLocations } = this.props
    return (
      <section className="EditPurchase">
        <H2>Edit Purchase</H2>
        <EditPurchaseForm
          submitAction={updatePurchaseAction}
          parts={parts}
          assetLocations={assetLocations}
          initialValues={purchase}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditPurchase
