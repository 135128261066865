import { fork, call, take, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllSIMCardsAction,
  getAllAvailableSIMCardsAction,
  getSIMCardAction,
  createSIMCardAction,
  updateSIMCardAction,
  deleteSIMCardAction,
} from '../actions/simCardsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllSIMCardsSaga(qs) {
  try {
    const payload = yield call(api.getAllSIMCards, qs)
    yield put(getAllSIMCardsAction.success(payload))
  } catch (error) {
    yield put(getAllSIMCardsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllAvailableSIMCardsSaga() {
  try {
    const payload = yield call(api.getAllAvailableSIMCards)
    yield put(getAllAvailableSIMCardsAction.success(payload))
  } catch (error) {
    yield put(getAllAvailableSIMCardsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSIMCardSaga(id) {
  try {
    const payload = yield call(api.getSIMCard, id)
    yield put(getSIMCardAction.success(payload))
  } catch (error) {
    yield put(getSIMCardAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateSIMCardSaga(simCard) {
  try {
    const payload = yield call(api.createSIMCard, simCard)

    yield put(createSIMCardAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/inventory/sim-cards/${payload.id}`))
  } catch (error) {
    yield put(createSIMCardAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateSIMCardSaga(simCard) {
  try {
    const payload = yield call(api.updateSIMCard, simCard)
    yield put(updateSIMCardAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/inventory/sim-cards/${payload.id}`))
  } catch (error) {
    yield put(updateSIMCardAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteSIMCardSaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteSIMCard, id)
      yield fork(handleGetAllSIMCardsSaga)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteSIMCardAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

// watchers

function* watchGetAllSIMCardsSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllSIMCardsAction.REQUEST)
    yield fork(handleGetAllSIMCardsSaga, qs)
  }
}

function* watchGetAllAvailableSIMCardsSaga() {
  while (true) {
    yield take(getAllAvailableSIMCardsAction.REQUEST)
    yield fork(handleGetAllAvailableSIMCardsSaga)
  }
}

function* watchGetSIMCardSaga() {
  while (true) {
    const { payload } = yield take(getSIMCardAction.REQUEST)
    yield fork(handleGetSIMCardSaga, payload)
  }
}

function* watchCreateSIMCardSaga() {
  while (true) {
    const { payload } = yield take(createSIMCardAction.REQUEST)
    yield fork(handleCreateSIMCardSaga, payload)
  }
}

function* watchUpdateSIMCardSaga() {
  while (true) {
    const { payload } = yield take(updateSIMCardAction.REQUEST)
    yield fork(handleUpdateSIMCardSaga, payload)
  }
}

function* watchDeleteSIMCardSaga() {
  while (true) {
    const { payload } = yield take(deleteSIMCardAction.REQUEST)
    yield fork(handleDeleteSIMCardSaga, payload)
  }
}

function* simCardsSaga() {
  yield all([
    fork(watchGetAllSIMCardsSaga),
    fork(watchGetAllAvailableSIMCardsSaga),
    fork(watchGetSIMCardSaga),
    fork(watchCreateSIMCardSaga),
    fork(watchUpdateSIMCardSaga),
    fork(watchDeleteSIMCardSaga),
  ])
}

export {
  simCardsSaga as default,
  watchGetAllSIMCardsSaga,
  watchGetAllAvailableSIMCardsSaga,
  watchGetSIMCardSaga,
  watchCreateSIMCardSaga,
  watchUpdateSIMCardSaga,
  watchDeleteSIMCardSaga,
  handleGetAllSIMCardsSaga,
  handleGetAllAvailableSIMCardsSaga,
  handleGetSIMCardSaga,
  handleCreateSIMCardSaga,
  handleUpdateSIMCardSaga,
  handleDeleteSIMCardSaga,
}
