import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Switch, Icon } from './Ant'
import Meta from './Meta'
import Label from './Label'

const Checkbox = ({
  input,
  label,
  meta,
  noMargin,
  className = '',
  disabled,
}) => {
  const checkboxClassNames = cx(
    'Checkbox flex items-center',
    {
      mb3: !noMargin,
    },
    className
  )

  return (
    <div className={checkboxClassNames}>
      <Switch
        className="mr2"
        checkedChildren={<Icon type="check" />}
        unCheckedChildren={<Icon type="close" />}
        disabled={disabled}
        {...input}
      />
      {label && <Label noMargin>{label}</Label>}
      <Meta {...meta} />
    </div>
  )
}

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node,
  meta: PropTypes.object,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
}

Checkbox.defaultProps = {
  noMargin: false,
  disabled: false,
}

export default Checkbox
