import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import EditThresholdForm from '../components/forms/EditThresholdForm'
import { H2 } from './common/Headers'
import { createThresholdAction } from '../actions/thresholdsActions'
import { createQueryString } from '../utils/queryParams'

const CreateThreshold = ({
  sites,
  companies,
  getAllSites,
  getAllCompanies,
}) => {
  useEffect(() => {
    const qs = createQueryString({ perPage: 'all' })
    getAllSites(qs)
    getAllCompanies(qs)
  }, [getAllSites, getAllCompanies])

  return (
    <section className="CreateThreshold">
      <H2>Create Threshold</H2>
      <EditThresholdForm
        initialValues={{
          visibleToUserRoles: [],
          rulesJson: [],
        }}
        submitAction={createThresholdAction}
        companies={companies}
        sites={sites}
      />
    </section>
  )
}

CreateThreshold.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllSites: PropTypes.func.isRequired,
  getAllCompanies: PropTypes.func.isRequired,
}

export default CreateThreshold
