import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Icon } from './Ant'
import { SIZES } from '../../constants'

const Block = ({
  label,
  children,
  level = 'default',
  className = '',
  iconType,
  size = SIZES.MEDIUM,
}) => {
  const sizeClassName = cx({
    'w-25-l w-50-m': size === SIZES.SMALL,
    'w-third-ns': size === SIZES.MEDIUM,
    'w-50-ns': size === SIZES.LARGE,
    'w-100': size === SIZES.XL,
  })
  const levelClassNames = cx('pa2 br2', className, {
    'bg-dark-red white': level === 'alert',
    'bg-orange white': level === 'warning',
    'bg-near-white': level === 'default' || !className,
  })

  return children ? (
    <div className={`w-100 ph1 mb3 ${sizeClassName}`}>
      <div className={levelClassNames}>
        {iconType ? (
          <div className="flex items-center">
            <Icon type={iconType} className="pr3 f2" />
            <div>
              <div className="f6 f5-ns b">{label}</div>
              <div>{children}</div>
            </div>
          </div>
        ) : (
          <div>
            <div className="f6 f5-ns b">{label}</div>
            <div>{children}</div>
          </div>
        )}
      </div>
    </div>
  ) : null
}

Block.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  level: PropTypes.string,
  className: PropTypes.string,
  iconType: PropTypes.string,
  size: PropTypes.string,
}

export default Block
