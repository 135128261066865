import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Divider from '../common/Divider'
import Form from '../common/Form'
import FormError from '../common/FormError'
import Block from '../common/Block'
import SelectContainer from '../../containers/common/SelectContainer'
import Select, { Option } from '../common/Select'
import { Spin, Steps, Step, RadioGroup, Radio } from '../common/Ant'
import AntSelect from 'antd/lib/select'
import Button from '../common/Button'
import Label from '../common/Label'
import { required } from '../../utils/validators'
import { SIZES } from '../../constants'
import { updatePodFirmwareAction } from '../../actions/podsActions'
import { createQueryString } from '../../utils/queryParams'
import api from '../../services/api'

const SELECT_ALL = 'Select All'

class OTAFirmwareUpdateForm extends Component {
  constructor() {
    super()

    this.state = {
      current: 0,
      pods: [],
      value: [],
      isFetching: false,
      siteSlug: undefined,
      visibleSites: 'all',
    }
    this.inputs = {}
  }

  static propTypes = {
    error: PropTypes.string,
    getAllPods: PropTypes.func.isRequired,
    firmwareVersions: PropTypes.arrayOf(PropTypes.string).isRequired,
    setOTAPodIds: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object),
    handleSubmit: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    anyTouched: PropTypes.bool.isRequired,
    formValues: PropTypes.object,
  }

  static defaultProps = {
    formValues: {},
  }

  handleSearch = async value => {
    const { siteSlug } = this.state
    const query = siteSlug
      ? {
          productVersion: '2.3.1',
          siteSlug,
          perPage: 'all',
        }
      : {
          productVersion: '2.3.1',
          pillarId: value,
        }
    this.setState({ isFetching: true })
    const qs = createQueryString(query)
    const { items } = await api.getAllPods(qs)
    this.setState({ pods: items, isFetching: false })
  }

  handleSiteChange = value => this.setState({ siteSlug: value })

  handleSiteFilterChange = ({ target: { value } }) => {
    if (value === 'some') {
      this.setState({ visibleSites: value })
    } else {
      this.setState({
        visibleSites: value,
        siteSlug: undefined,
      })
    }
  }

  handleChange = value => {
    /*
      Input: `value`: selected item from pod select dropdown
      Function: If pod selection by site was chosen,
        `value` is checked for all pod selection, in which case all pods
        in the site are set in the OTA update form. Else, selected
        pods are set in the form individually.
    */
    let podIds
    const allItem = value.filter(item => item.key === 'all')
    if (this.state.siteSlug && allItem.length) {
      const { pods } = this.state
      this.setState({ value: allItem })
      podIds = pods.map(pod => pod.id)
    } else {
      this.setState({
        value,
        isFetching: false,
      })
      podIds = value.map(x => x.key)
    }

    this.props.setOTAPodIds(podIds)
  }

  handleNext = event => {
    const { valid, anyTouched, touch } = this.props

    if (!this.state.current || (anyTouched && valid)) {
      if (!this.state.current) {
        this.handleSearch()
      }
      const current = this.state.current + 1
      this.setState({ current })
    } else {
      const fieldNames = Object.keys(this.inputs).map(
        x => this.inputs[x].props.name
      )
      touch(...fieldNames)
    }

    event.preventDefault()
  }

  handlePrev = event => {
    const current = this.state.current - 1
    this.setState({ current })

    event.preventDefault()
  }

  render() {
    const {
      handleSubmit,
      submitting,
      sites,
      formValues,
      error,
      firmwareVersions,
      handleCancel,
    } = this.props
    const {
      current,
      pods,
      value,
      isFetching,
      visibleSites,
      siteSlug,
    } = this.state
    const submit = handleSubmit(updatePodFirmwareAction)

    const firmwareOptions = firmwareVersions.map(x => (
      <Option value={x} key={x}>
        {x}
      </Option>
    ))

    const allPodsOption = [
      <Option value={'all'} key={'all'}>
        {SELECT_ALL}
      </Option>,
    ]

    const restPodsOptions = pods.map(x => (
      <Option value={x.id} key={x.id}>
        {x.pillarId}
      </Option>
    ))

    const podOptions = siteSlug
      ? allPodsOption.concat(restPodsOptions)
      : restPodsOptions

    const steps = [
      {
        title: 'Choose Pod Filter Options',
        content: (
          <div className="flex-ns mt3 justify-center">
            <RadioGroup
              onChange={this.handleSiteFilterChange}
              defaultValue={visibleSites}
              className="w-50 f6 fw6 lh-copy"
              size="small"
            >
              <Radio value={'all'}>Select By Pillar ID</Radio>
              <Radio value={'some'}>Select By Sites</Radio>
            </RadioGroup>
            <Select
              className="w-50 mw5"
              placeholder="Select Sites"
              mode="multiple"
              disabled={visibleSites === 'all'}
              input={{
                value: siteSlug,
                onChange: this.handleSiteChange,
              }}
              filterable
            >
              {sites.map(x => (
                <Option value={x.slug} key={x.slug}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
      },
      {
        title: 'Choose Firmware Version and Smart Pods',
        content: (
          <div className="flex-l justify-start mt3 ml5">
            <Field
              name="firmware"
              component={SelectContainer}
              label="Firmware Version"
              className="w-50-ns mr3"
              validate={[required]}
              placeholder="Select a Firmware Version"
              filterable
            >
              {firmwareOptions}
            </Field>

            <div className="w-50-ns">
              <Label>
                Smart Pods <span className="red"> *</span>
              </Label>
              <AntSelect
                className="input-reset w5"
                mode="multiple"
                labelInValue
                value={value}
                placeholder="Select Smart Pods to Update"
                notFoundContent={isFetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
              >
                {podOptions}
              </AntSelect>
            </div>
            <FormError error={error} />
          </div>
        ),
      },
      {
        title: 'Review and Submit',
        content: (
          <div>
            <div className="flex flex-wrap">
              <Block size={SIZES.LARGE} label="Firmware Version">
                {formValues.firmware}
              </Block>
              <Block size={SIZES.LARGE} label="Smart Pods">
                {formValues.podIds &&
                  siteSlug &&
                  pods
                    .filter(pod => formValues.podIds.indexOf(pod.id) > -1)
                    .map(pod => pod.pillarId)
                    .join(', ')}
              </Block>
            </div>
            <div className="flex-ns justify-between">
              <Button invert text="Cancel" onClick={handleCancel} />
              <Button
                text="Submit Update"
                type="submit"
                submitting={submitting}
                className="w5"
              />
            </div>
          </div>
        ),
      },
    ]

    // TODO need to set Steps to vertical for everything but large screens
    return (
      <section className="OTAFirmwareUpdateForm">
        <Divider />
        <Form onSubmit={submit}>
          <Steps current={current} className="mb3">
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="mb3 ba b--light-gray br2 pa2">
            {steps[this.state.current].content}
          </div>
          <div className="flex justify-between">
            <Button
              disabled={current === 0}
              onClick={this.handlePrev}
              icon="arrow-left"
              overrideText
            />
            <Button
              disabled={current >= steps.length - 1}
              onClick={this.handleNext}
              icon="arrow-right"
              overrideText
            />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'OTAFirmwareUpdateForm' })(
  OTAFirmwareUpdateForm
)
