import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createQueryString } from '../utils/queryParams'
import EditFlowMonitorForm from '../components/forms/EditFlowMonitorForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createFlowMonitorAction } from '../actions/flowMonitorsActions'

class CreateFlowMonitor extends Component {
  static propTypes = {
    setWaterValveFields: PropTypes.func.isRequired,
    setWaterMeterFields: PropTypes.func.isRequired,
    allAvailableWaterValves: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableWaterMeters: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAvailableWaterValves: PropTypes.func.isRequired,
    getAllAvailableWaterMeters: PropTypes.func.isRequired,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object).isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    getAllAvailablePods: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      getAllAvailablePods,
      getAllAssetLocations,
      getAllAvailableWaterValves,
      getAllAvailableWaterMeters,
    } = this.props
    const qs = createQueryString({ perPage: 'all' })

    getAllAvailableWaterMeters(qs)
    getAllAvailableWaterValves(qs)
    getAllAvailablePods(qs)
    getAllAssetLocations(qs)
  }

  render() {
    const {
      allAvailablePods,
      assetLocations,
      setWaterValveFields,
      setWaterMeterFields,
      allAvailableWaterValves,
      allAvailableWaterMeters,
    } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/flow-monitors">Water Monitors</Anchor>,
      'Create Water Monitor',
    ]
    return (
      <section className="CreateFlowMonitor">
        <FormHeader crumbs={crumbs} />
        <EditFlowMonitorForm
          allAvailableWaterMeters={allAvailableWaterMeters}
          allAvailableWaterValves={allAvailableWaterValves}
          setWaterValveFields={setWaterValveFields}
          setWaterMeterFields={setWaterMeterFields}
          submitAction={createFlowMonitorAction}
          allAvailablePods={allAvailablePods}
          assetLocations={assetLocations}
        />
      </section>
    )
  }
}

export default CreateFlowMonitor
