import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AllChats from '../components/AllChats'
import {
  receiveNewMessageAction,
  updateInboxAction,
} from '../actions/communicationsActions'
// import { getAccountProfile } from '../reducers/accountReducer'
// import {
//   getCurrentConversation,
//   getCurrentConversationParticipant,
//   getCurrentConversationSlug
// } from '../reducers/communicationsReducer'

// TODO
const mapStateToProps = ({ communications, account }) => ({
  chats: [],
  // currentConversation: getCurrentConversation(communications),
  // chatroomSlug: getCurrentConversationSlug(communications),
  // accountProfile: getAccountProfile(account),
  // participant: getCurrentConversationParticipant(communications)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      receiveNewMessage: receiveNewMessageAction,
      updateInbox: updateInboxAction,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllChats)
