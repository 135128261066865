import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { reduxForm, Field } from 'redux-form'
import MainContent from '../common/MainContent'
import { H2 } from '../common/Headers'
import InputContainer from '../../containers/common/InputContainer'
import Button from '../common/Button'
import { ActionAnchor } from '../common/Anchor'
import Form from '../common/Form'
import FormError from '../common/FormError'
import { verifyTwoFactorAuthTokenAction } from '../../actions/authActions'
import { required, exactLength, isNumeric } from '../../utils/validators'

const exactLength6 = exactLength(6)

const TwoFactorAuthVerificationForm = props => {
  const { handleSubmit, error, resendTwoFactorAuthToken } = props
  const submit = handleSubmit(verifyTwoFactorAuthTokenAction)
  return (
    <MainContent>
      <section className="TwoFactorAuthVerificationForm">
        <Helmet>
          <title>Two-Factor Authentication</title>
        </Helmet>
        <Form className="measure-narrow center mb2" onSubmit={submit}>
          <H2>Two Factor Authentication</H2>
          <Field
            name="token"
            type="text"
            component={InputContainer}
            label="Enter Verification Code"
            validate={[required, exactLength6, isNumeric]}
          />
          <Button
            text="Submit Code"
            type="submit"
            className="w-100 mb2"
            submitting={props.submitting}
          />
          <div className="lh-copy tc mb2">
            <ActionAnchor className="f6" onClick={resendTwoFactorAuthToken}>
              Resend Code
            </ActionAnchor>
          </div>
          <FormError error={error} />
        </Form>
      </section>
    </MainContent>
  )
}

TwoFactorAuthVerificationForm.propTypes = {
  resendTwoFactorAuthToken: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
}

export default reduxForm({ form: 'TwoFactorAuthVerificationForm' })(
  TwoFactorAuthVerificationForm
)
