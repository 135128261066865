import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { getFormValues } from 'redux-form'
import InventoryPage from '../components/pages/InventoryPage'
import {
  getAllGatewaysAction,
  getGatewayAction,
  deleteGatewayAction,
  getGatewayAlertsUptimeDataAction,
  getGatewayConnectedPodsAction,
  getGatewayAssignmentHistoryAction,
  restartGatewayAction,
  getGatewayRestartHistoryAction,
} from '../actions/gatewaysActions'
import {
  getAllSIMCardsAction,
  getSIMCardAction,
  deleteSIMCardAction,
} from '../actions/simCardsActions'
import {
  getAllPartsAction,
  getPartAction,
  deletePartAction,
} from '../actions/partsActions'
import {
  getAllPurchasesAction,
  getPurchaseAction,
  deletePurchaseAction,
} from '../actions/purchasesActions'
import {
  getAllFlowMonitorsAction,
  getFlowMonitorDataAction,
  getFlowMonitorAction,
  deleteFlowMonitorAction,
  getFlowMonitorEditHistoryAction,
  getFlowMonitorAssignmentHistoryAction,
  getValveStatusHistoryAction,
  updateFlowMonitorAction,
  setWaterValveFieldsAction,
  setWaterMeterFieldsAction,
  getAllAvailableWaterValvesAction,
  getAllAvailableWaterMetersAction,
  getAllWaterMetersAction,
  getWaterMeterAction,
  updateWaterMeterAction,
  deleteWaterMeterAction,
  getAllWaterValvesAction,
  getWaterValveAction,
  updateWaterValveAction,
  deleteWaterValveAction,
} from '../actions/flowMonitorsActions'
import {
  getAllGatewaySuppliesAction,
  getGatewaySupplyAction,
  deleteGatewaySupplyAction,
} from '../actions/gatewaySuppliesActions'
import {
  getAllPodsAction,
  getPodAction,
  getAllAvailablePodsAction,
  getPodChartDataAction,
  deletePodAction,
  getPodMetadataAction,
  getPodAssignmentHistoryAction,
  getPodEditHistoryAction,
  getAllPodFirmwareVersionsAction,
  batchUpdatePodsAction,
  uploadPodsCSVAction,
  uploadCalibrationCSVAction,
  getPodAssetsAction,
  getPodWorkflowResponsesAction,
  getPodWorkflowResponseAction,
  getPodCalibrationHistoryAction,
} from '../actions/podsActions'
import { getLocationSnapshotAction } from '../actions/locationsActions'
import {
  getAllAssetLocationsAction,
  deleteAssetLocationAction,
  getAssetLocationAction,
} from '../actions/assetLocationsActions'
import {
  getAllOTALogsAction,
  getOTALogAction,
  setOTAPodIdsAction,
} from '../actions/otaActions'
import {
  getAllCommentsAction,
  getAllTagsAction,
  deleteCommentAction,
  createCommentAction,
  updateCommentAction,
} from '../actions/commentsActions'
import { getAllSitesAction } from '../actions/sitesActions'
import {
  getInventoryProjectionsAction,
  createInventoryProposalAction,
  updateInventoryProposalAction,
  getInventoryProposalAction,
  deleteInventoryProposalAction,
  convertInventoryProposalAction,
} from '../actions/inventoryActions'
import { getAllDeploymentsAction } from '../actions/deploymentsActions'
import { getVisibleComments, getVisibleTags } from '../reducers/commentsReducer'
import { getVisibleSites } from '../reducers/sitesReducer'
import {
  getVisibleGateways,
  getCurrentGateway,
  getGatewaysMeta,
  getCurrentGatewayUptimeData,
  getCurrentGatewayConnectedPods,
  getVisibleGatewayAssignmentHistory,
  getGatewayRestartHistory,
} from '../reducers/gatewaysReducer'
import {
  getVisibleSIMCards,
  getCurrentSIMCard,
  getSIMCardsMeta,
} from '../reducers/simCardsReducer'
import {
  getVisibleParts,
  getCurrentPart,
  getPartsMeta,
} from '../reducers/partsReducer'
import {
  getVisiblePurchases,
  getCurrentPurchase,
  getPurchasesMeta,
} from '../reducers/purchasesReducer'
import {
  getVisibleFlowMonitors,
  getCurrentFlowMonitor,
  getFlowMonitorsMeta,
  getVisibleFlowMonitorData,
  getVisibleFlowMonitorEditHistory,
  getVisibleFlowMonitorAssignmentHistory,
  getVisibleValveStatusHistory,
  getAvailableVisibleWaterValves,
  getAvailableVisibleWaterMeters,
  getVisibleWaterMeters,
  getCurrentWaterMeter,
  getVisibleWaterValves,
  getCurrentWaterValve,
} from '../reducers/flowMonitorsReducer'
import {
  getVisibleGatewaySupplies,
  getCurrentGatewaySupply,
} from '../reducers/gatewaySuppliesReducer'
import {
  getVisibleAssetLocations,
  getCurrentAssetLocation,
  getAssetLocationsMeta,
} from '../reducers/assetLocationsReducer'
import {
  getVisiblePods,
  getCurrentPod,
  getAvailableVisiblePods,
  getPodsMeta,
  getCurrentPodChartData,
  getCurrentPodChartColumns,
  getPodMetadata,
  getPodFirmwareVersions,
  getVisiblePodEditHistory,
  getVisiblePodAssignmentHistory,
  getCSVUploadResults,
  getPodImages,
  getPodWorkflowResponses,
  getPodWorkflowResponse,
  getVisiblePodCalibrationHistory,
} from '../reducers/podsReducer'
import { getLocationSnapshot } from '../reducers/locationsReducer'
import {
  getWindowWidth,
  isChartDataLoading,
  isGatewayLoading,
  isPodLoading,
  isSnapshotLoading,
  isAssetLocationLoading,
  isCommentLoading,
  isHistoryLoading,
  isSIMCardLoading,
  isPartLoading,
  isPurchaseLoading,
  isFlowMonitorLoading,
  isGatewaySupplyLoading,
  isCalibrationHistoryLoading,
  isDeploymentLoading,
} from '../reducers/uiReducer'
import { getVisibleOTALogs, getCurrentOTALog } from '../reducers/otaReducer'
import { getDeploymentCounts } from '../reducers/deploymentsReducer'
import {
  getInventoryProjections,
  getProposal,
} from '../reducers/inventoryReducer'

const mapStateToProps = ({
  simCards,
  parts,
  purchases,
  flowMonitors,
  gatewaySupplies,
  sites,
  gateways,
  pods,
  locations,
  assetLocations,
  deployments,
  inventory,
  ui,
  ota,
  comments,
  form,
}) => ({
  windowWidth: getWindowWidth(ui),
  sites: getVisibleSites(sites),
  comments: getVisibleComments(comments),
  tags: getVisibleTags(comments),
  assetLocations: getVisibleAssetLocations(assetLocations),
  currentAssetLocation: getCurrentAssetLocation(assetLocations),
  assetLocationsMeta: getAssetLocationsMeta(assetLocations),
  gateways: getVisibleGateways(gateways),
  simCards: getVisibleSIMCards(simCards),
  parts: getVisibleParts(parts),
  purchases: getVisiblePurchases(purchases),
  flowMonitors: getVisibleFlowMonitors(flowMonitors),
  gatewaySupplies: getVisibleGatewaySupplies(gatewaySupplies),
  currentGatewayUptimeData: getCurrentGatewayUptimeData(gateways),
  currentGatewayConnectedPods: getCurrentGatewayConnectedPods(gateways),
  currentGateway: getCurrentGateway(gateways),
  currentSIMCard: getCurrentSIMCard(simCards),
  currentPart: getCurrentPart(parts),
  currentPurchase: getCurrentPurchase(purchases),
  currentFlowMonitor: getCurrentFlowMonitor(flowMonitors),
  allAvailablePods: getAvailableVisiblePods(pods),
  flowMonitorData: getVisibleFlowMonitorData(flowMonitors),
  allAvailableWaterValves: getAvailableVisibleWaterValves(flowMonitors),
  allAvailableWaterMeters: getAvailableVisibleWaterMeters(flowMonitors),
  waterMeters: getVisibleWaterMeters(flowMonitors),
  currentWaterMeter: getCurrentWaterMeter(flowMonitors),
  waterValves: getVisibleWaterValves(flowMonitors),
  currentWaterValve: getCurrentWaterValve(flowMonitors),
  currentGatewaySupply: getCurrentGatewaySupply(gatewaySupplies),
  pods: getVisiblePods(pods),
  currentPod: getCurrentPod(pods),
  currentPodChartData: getCurrentPodChartData(pods),
  currentPodChartColumns: getCurrentPodChartColumns(pods),
  currentPodEditHistory: getVisiblePodEditHistory(pods),
  snapshotData: getLocationSnapshot(locations),
  currentPodAssignmentHistory: getVisiblePodAssignmentHistory(pods),
  currentFlowMonitorEditHistory: getVisibleFlowMonitorEditHistory(flowMonitors),
  currentFlowMonitorAssignmentHistory: getVisibleFlowMonitorAssignmentHistory(
    flowMonitors
  ),
  currentValveStatusHistory: getVisibleValveStatusHistory(flowMonitors),
  currentGatewayAssignmentHistory: getVisibleGatewayAssignmentHistory(gateways),
  gatewaysMeta: getGatewaysMeta(gateways),
  simCardsMeta: getSIMCardsMeta(simCards),
  partsMeta: getPartsMeta(parts),
  purchasesMeta: getPurchasesMeta(purchases),
  flowMonitorsMeta: getFlowMonitorsMeta(flowMonitors),
  podsMeta: getPodsMeta(pods),
  isGatewayLoading: isGatewayLoading(ui),
  isPodLoading: isPodLoading(ui),
  isSnapshotLoading: isSnapshotLoading(ui),
  isAssetLocationLoading: isAssetLocationLoading(ui),
  isSIMCardLoading: isSIMCardLoading(ui),
  isPartLoading: isPartLoading(ui),
  isPurchaseLoading: isPurchaseLoading(ui),
  isFlowMonitorLoading: isFlowMonitorLoading(ui),
  isGatewaySupplyLoading: isGatewaySupplyLoading(ui),
  isHistoryLoading: isHistoryLoading(ui),
  isChartDataLoading: isChartDataLoading(ui),
  isCommentLoading: isCommentLoading(ui),
  podMetadata: getPodMetadata(pods),
  firmwareVersions: getPodFirmwareVersions(pods),
  otaLogs: getVisibleOTALogs(ota),
  currentOtaLog: getCurrentOTALog(ota),
  otaFormValues: getFormValues('OTAFirmwareUpdateForm')({ form }),
  csvUploadResults: getCSVUploadResults(pods),
  gatewayRestartHistory: getGatewayRestartHistory(gateways),
  podImages: getPodImages(pods),
  workflowResponses: getPodWorkflowResponses(pods),
  currentWorkflowResponse: getPodWorkflowResponse(pods),
  calibrationHistory: getVisiblePodCalibrationHistory(pods),
  isCalibrationHistoryLoading: isCalibrationHistoryLoading(ui),
  counts: getDeploymentCounts(deployments),
  projections: getInventoryProjections(inventory),
  isDeploymentLoading: isDeploymentLoading(ui),
  proposal: getProposal(inventory),
})

const mapDispatchToProps = dispatch => ({
  getAllSites: qs => dispatch(getAllSitesAction.request(qs)),
  getAllAssetLocations: qs => dispatch(getAllAssetLocationsAction.request(qs)),
  deleteAssetLocation: id => dispatch(deleteAssetLocationAction.request(id)),
  getAssetLocation: id => dispatch(getAssetLocationAction.request(id)),
  getGatewayUptimeData: (id, qs) =>
    dispatch(getGatewayAlertsUptimeDataAction.request({ id, qs })),
  getGatewayConnectedPods: id =>
    dispatch(getGatewayConnectedPodsAction.request(id)),
  getAllGateways: qs => dispatch(getAllGatewaysAction.request(qs)),
  getGateway: pillarId => dispatch(getGatewayAction.request(pillarId)),
  deleteGateway: id => dispatch(deleteGatewayAction.request(id)),
  getAllSIMCards: qs => dispatch(getAllSIMCardsAction.request(qs)),
  getSIMCard: id => dispatch(getSIMCardAction.request(id)),
  deleteSIMCard: id => dispatch(deleteSIMCardAction.request(id)),
  getAllParts: qs => dispatch(getAllPartsAction.request(qs)),
  getPart: id => dispatch(getPartAction.request(id)),
  deletePart: id => dispatch(deletePartAction.request(id)),
  getAllPurchases: qs => dispatch(getAllPurchasesAction.request(qs)),
  getPurchase: id => dispatch(getPurchaseAction.request(id)),
  deletePurchase: id => dispatch(deletePurchaseAction.request(id)),
  getAllFlowMonitors: qs => dispatch(getAllFlowMonitorsAction.request(qs)),
  getFlowMonitor: id => dispatch(getFlowMonitorAction.request(id)),
  deleteFlowMonitor: id => dispatch(deleteFlowMonitorAction.request(id)),
  updateFlowMonitor: payload =>
    dispatch(updateFlowMonitorAction.request(payload)),
  getAllAvailablePods: () => dispatch(getAllAvailablePodsAction.request()),
  getFlowMonitorData: (pillarId, qs) =>
    dispatch(getFlowMonitorDataAction.request({ pillarId, qs })),
  getAllAvailableWaterValves: () =>
    dispatch(getAllAvailableWaterValvesAction.request()),
  getAllAvailableWaterMeters: () =>
    dispatch(getAllAvailableWaterMetersAction.request()),
  getAllWaterMeters: qs => dispatch(getAllWaterMetersAction.request(qs)),
  getWaterMeter: id => dispatch(getWaterMeterAction.request(id)),
  deleteWaterMeter: id => dispatch(deleteWaterMeterAction.request(id)),
  updateWaterMeter: payload =>
    dispatch(updateWaterMeterAction.request(payload)),
  getAllWaterValves: qs => dispatch(getAllWaterValvesAction.request(qs)),
  getWaterValve: id => dispatch(getWaterValveAction.request(id)),
  deleteWaterValve: id => dispatch(deleteWaterValveAction.request(id)),
  updateWaterValve: payload =>
    dispatch(updateWaterValveAction.request(payload)),
  getAllGatewaySupplies: qs =>
    dispatch(getAllGatewaySuppliesAction.request(qs)),
  getGatewaySupply: id => dispatch(getGatewaySupplyAction.request(id)),
  deleteGatewaySupply: id => dispatch(deleteGatewaySupplyAction.request(id)),
  getLocationSnapshot: (siteSlug, floorId, locationId) =>
    dispatch(
      getLocationSnapshotAction.request({
        siteSlug,
        floorId,
        locationId,
      })
    ),
  getAllPods: qs => dispatch(getAllPodsAction.request(qs)),
  getPod: id => dispatch(getPodAction.request(id)),
  batchUpdatePods: payload => dispatch(batchUpdatePodsAction.request(payload)),
  getPodChartData: ((id, qs) => {
    console.info("GET POD CHART DATA");
    console.info("id");
    console.info(id);
    console.info("qs");
    console.info(qs);
    dispatch(getPodChartDataAction.request({ id, qs }));
  }),
  getPodAssignmentHistory: id =>
    dispatch(getPodAssignmentHistoryAction.request(id)),
  getGatewayAssignmentHistory: id =>
    dispatch(getGatewayAssignmentHistoryAction.request(id)),
  getPodEditHistory: id => dispatch(getPodEditHistoryAction.request(id)),
  getFlowMonitorAssignmentHistory: id =>
    dispatch(getFlowMonitorAssignmentHistoryAction.request(id)),
  getValveStatusHistory: id =>
    dispatch(getValveStatusHistoryAction.request(id)),
  getFlowMonitorEditHistory: id =>
    dispatch(getFlowMonitorEditHistoryAction.request(id)),
  deletePod: id => dispatch(deletePodAction.request(id)),
  getPodMetadata: () => dispatch(getPodMetadataAction.request()),
  getAllFirmwareVersions: () =>
    dispatch(getAllPodFirmwareVersionsAction.request()),
  getAllOTALogs: () => dispatch(getAllOTALogsAction.request()),
  getOTALog: slug => dispatch(getOTALogAction.request(slug)),
  setOTAPodIds: podIds => dispatch(setOTAPodIdsAction(podIds)),
  setWaterValveFields: waterValveFields =>
    dispatch(setWaterValveFieldsAction(waterValveFields)),
  setWaterMeterFields: waterMeterFields =>
    dispatch(setWaterMeterFieldsAction(waterMeterFields)),
  getAllTags: qs => dispatch(getAllTagsAction.request(qs)),
  getAllComments: qs => dispatch(getAllCommentsAction.request(qs)),
  deleteComment: comment => dispatch(deleteCommentAction.request(comment)),
  createComment: comment => dispatch(createCommentAction.request(comment)),
  editComment: comment => dispatch(updateCommentAction.request(comment)),
  restartGateway: id => dispatch(restartGatewayAction.request(id)),
  updateQueryParams: location => dispatch(replace(location)),
  uploadPodsCSV: formData =>
    dispatch(uploadPodsCSVAction.request({ formData })),
  uploadCalibrationCSV: formData =>
    dispatch(uploadCalibrationCSVAction.request({ formData })),
  getGatewayRestartHistory: pillarId =>
    dispatch(getGatewayRestartHistoryAction.request(pillarId)),
  getPodAssets: pillarId => dispatch(getPodAssetsAction.request(pillarId)),
  getPodWorkflowResponses: slug =>
    dispatch(getPodWorkflowResponsesAction.request(slug)),
  getPodWorkflowResponse: (podSlug, workflowResponseSlug) =>
    dispatch(
      getPodWorkflowResponseAction.request({ podSlug, workflowResponseSlug })
    ),
  getCalibrationHistory: (podPillarId, qs) =>
    dispatch(getPodCalibrationHistoryAction.request({ podPillarId, qs })),
  getAllDeployments: qs => dispatch(getAllDeploymentsAction.request(qs)),
  getInventoryProjections: payload =>
    dispatch(getInventoryProjectionsAction.request(payload)),
  createInventoryProposal: payload =>
    dispatch(createInventoryProposalAction.request(payload)),
  updateInventoryProposal: payload =>
    dispatch(updateInventoryProposalAction.request(payload)),
  getInventoryProposal: payload =>
    dispatch(getInventoryProposalAction.request(payload)),
  deleteInventoryProposal: (slug, qs) =>
    dispatch(deleteInventoryProposalAction.request({ slug, qs })),
  convertInventoryProposal: payload =>
    dispatch(convertInventoryProposalAction.request(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPage)
