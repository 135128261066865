import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditTagForm from './forms/EditTagForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateTagAction } from '../actions/commentsActions'

class EditTag extends Component {
  static propTypes = {
    tag: PropTypes.object.isRequired,
    getTag: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { getTag, match } = this.props
    getTag(match.params.id)
  }

  render() {
    const { tag } = this.props
    const crumbs = [
      <Anchor to="/metadata/all">Metadata</Anchor>,
      <Anchor to="/metadata/all/tags">Tags</Anchor>,
      'Edit Tag',
    ]

    return (
      <section className="EditTag">
        <FormHeader crumbs={crumbs} />
        <EditTagForm
          submitAction={updateTagAction}
          initialValues={tag}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditTag
