import React, { useEffect, useCallback, createRef } from 'react'
import PropTypes from 'prop-types'
import ChatMessage from './ChatMessage'
import UserStats from './common/UserStats'
import AllComments from './AllComments'
import { Bread, Crumb } from './common/Bread'
import { H3 } from './common/Headers'
import { Spin } from './common/Ant'
import { Anchor } from './common/Anchor'
import { createQueryString } from '../utils/queryParams'
import { formatPhoneNumber } from '../utils/forms'
import { usePrevious } from '../hooks'
import { RESOURCE_TYPES } from '../constants'

const Conversation = ({
  getConversation,
  getUser,
  clearUser,
  clearConversation,
  conversation,
  user,
  participant,
  pillarParticipant,
  accountProfile,
  match,

  isCommentLoading,
  getAllComments,
  deleteComment,
  createComment,
  editComment,
  getAllTags,
  tags,
  comments,
}) => {
  const { chatroomSlug } = match.params
  const messageListRef = createRef()

  useEffect(() => {
    getConversation(chatroomSlug, createQueryString({ limit: 100 }))

    return () => {
      clearUser()
      clearConversation()
    }
  }, [getConversation, clearUser, clearConversation, chatroomSlug])

  const prevParticipantSlug = usePrevious(participant.slug)

  useEffect(() => {
    if (!prevParticipantSlug && !!participant.slug) {
      getUser(participant.slug)
    }
  }, [getUser, participant.slug, prevParticipantSlug])

  // TODO fetch previous messages
  // const handleGetPreviousMessages = () => {
  //   const earliestMessageSlug = conversation.messages[0].slug

  //   getConversation(
  //     chatroomSlug,
  //     // TODO
  //     // change this on API as well - it's confusing
  //     createQueryString({ lastMessageSlug: earliestMessageSlug })
  //   )
  // }

  const handleScrollToBottom = useCallback(() => {
    messageListRef.current &&
      (messageListRef.current.scrollTop = messageListRef.current.scrollHeight)
  }, [messageListRef])

  const prevConversationLength = usePrevious(conversation.length)

  useEffect(() => {
    if (prevConversationLength !== conversation.length) {
      handleScrollToBottom()
    }
  }, [conversation.length, prevConversationLength, handleScrollToBottom])

  return (
    <div className="Conversation">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>
              <Anchor to="/communications/inbox">Inbox</Anchor>
            </Crumb>
            <Crumb>Conversation</Crumb>
            <Crumb>
              {participant.slug
                ? `${participant.firstName} ${participant.lastName}`
                : formatPhoneNumber(participant.phoneNumber)}
            </Crumb>
          </Bread>
        </div>
      </div>
      <div className="mb3 flex-ns">
        <div className="w-100 w-third-ns ba br2 b--light-gray pa2 mr2">
          {!!user.slug ? (
            <UserStats user={user} />
          ) : !!participant.slug ? (
            <span>
              <H3>
                {participant.firstName} {participant.lastName}
              </H3>
            </span>
          ) : !!participant.phoneNumber ? (
            <H3>{formatPhoneNumber(participant.phoneNumber)}</H3>
          ) : (
            <Spin size="large" className="w-100 center mv5" />
          )}
        </div>
        <div className="w-100 w-two-thirds-ns ba br2 b--light-gray ml2">
          {!!conversation.length ? (
            <ul
              className="list mb0 pa2 overflow-y-scroll vh-75"
              ref={messageListRef}
            >
              {conversation.map(x => (
                <ChatMessage
                  key={x.slug}
                  message={x}
                  accountProfile={accountProfile}
                  pillarParticipant={pillarParticipant}
                />
              ))}
            </ul>
          ) : (
            <div className="tc">No Conversation History</div>
          )}
        </div>
      </div>
      {!!user.slug && (
        <div>
          <H3>User Notes</H3>
          <AllComments
            resourceType={RESOURCE_TYPES.USER}
            resourceSlug={user.slug}
            getAllComments={getAllComments}
            deleteComment={deleteComment}
            createComment={createComment}
            editComment={editComment}
            getAllTags={getAllTags}
            tags={tags}
            comments={comments}
            isCommentLoading={isCommentLoading}
            showDateFields={false}
          />
        </div>
      )}
    </div>
  )
}

Conversation.propTypes = {
  conversation: PropTypes.arrayOf(PropTypes.object).isRequired,
  getConversation: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
  clearConversation: PropTypes.func.isRequired,
  participant: PropTypes.object,
  pillarParticipant: PropTypes.object,
  user: PropTypes.object.isRequired,
  accountProfile: PropTypes.object.isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,

  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllComments: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  getAllTags: PropTypes.func.isRequired,
  isCommentLoading: PropTypes.bool.isRequired,
}

export default Conversation
