import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { decamelize } from 'humps'
import { H3 } from './common/Headers'
import { Bread, Crumb } from './common/Bread'
import { Tabs, TabPane, Tooltip, Icon } from './common/Ant'
import Divider from './common/Divider'
import Block from './common/Block'
import { ActionAnchor, Anchor } from './common/Anchor'
import { Spin } from './common/Ant'
import UptimeChart from './charts/UptimeChart'
import AssignmentHistory from './AssignmentHistory'
import GatewayRestartHistory from './GatewayRestartHistory'
import AllComments from './AllComments'
import ConnectedPodsList from './ConnectedPodsList'
import { toTitleCase, formatSIMCarrier } from '../utils/textFormatters'
import { formatPhoneNumber } from '../utils/forms'
import { dateTimeFormatter } from '../utils/date'
import {
  GATEWAY_NETWORK,
  GATEWAY_STATUS,
  GATEWAY_TYPES,
  SIM_CARRIERS,
  RESOURCE_TYPES,
  LORA_CABLE_SIZE,
  UPS_BATTERY,
  USER_PERMISSIONS,
} from '../constants'
import { hasPermission } from '../utils/hasPermission'
import { getPathSnippets } from '../utils/helpers'

class Gateway extends Component {
  static propTypes = {
    gateway: PropTypes.object.isRequired,
    getGateway: PropTypes.func.isRequired,
    restartGateway: PropTypes.func.isRequired,
    isGatewayLoading: PropTypes.bool.isRequired,
    getAllComments: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    gatewayAssignmentHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
    getGatewayAssignmentHistory: PropTypes.func.isRequired,
    gatewayRestartHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
    getGatewayRestartHistory: PropTypes.func.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    isCommentLoading: PropTypes.bool,
    gatewayUptimeData: PropTypes.object.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    getGatewayUptimeData: PropTypes.func.isRequired,
    gatewayConnectedPods: PropTypes.arrayOf(PropTypes.object).isRequired,
    getGatewayConnectedPods: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object,
  }

  state = { activeTab: undefined }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    this.props.getGateway(this.props.match.params.pillarId)

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet)
  }

  handleTabSelection = entity => {
    const { updateQueryParams, match } = this.props

    if (entity !== match.params.pillarId) {
      this.setState({ activeTab: entity })
      updateQueryParams({
        pathname: `${match.url}/${entity}`,
        search: entity === 'uptime-chart' ? global.location.search : '',
      })
    } else {
      this.setState({ activeTab: 'uptime-chart' })
    }
  }

  render() {
    const {
      gateway,
      isGatewayLoading,
      isCommentLoading,
      isHistoryLoading,
      isChartDataLoading,
      restartGateway,
      match,
      getAllComments,
      deleteComment,
      createComment,
      editComment,
      getAllTags,
      tags,
      comments,
      gatewayUptimeData,
      updateQueryParams,
      getGatewayUptimeData,
      gatewayConnectedPods,
      getGatewayConnectedPods,
      gatewayAssignmentHistory,
      getGatewayAssignmentHistory,
      gatewayRestartHistory,
      getGatewayRestartHistory,
    } = this.props

    const {
      status,
      gatewayType,
      moduleId,
      gatewayPillarId,
      network,
      simType,
      phoneNumber,
      simCardNumber,
      simCardId,
      signalInfo,
      alert,
      monitor,
      offlineNotificationDelayMinutes,
      online,
      siteSlug,
      siteName,
      gatewayStackType,
      slug,
      gatewayName,
      firmwareVersion,
      deploymentInstallType,
      deploymentSlug,
      deploymentSiteName,
      deploymentSiteSlug,
      deploymentDescription,
      deploymentCreatedAt,
      assetLocationPillarId,
      loraAntennaType,
      cellAntennaType,
      loraCableSize,
      solarPanel,
      upsBattery,
      networkName,
      networkSlug,
    } = gateway

    const offlineTime = alert && dateTimeFormatter(alert.startTime)
    const deploymentTitle = deploymentDescription
      ? deploymentDescription
      : dateTimeFormatter(deploymentCreatedAt, true)

    return isGatewayLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Gateway mb3">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/inventory/all">Inventory</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to={`/inventory/all/gateways`}>Network Gateways</Anchor>
              </Crumb>
              <Crumb>
                {online ? (
                  <Tooltip title="Online">
                    {gatewayName}
                    <Icon type="check-circle-o" className="pl2 green" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Offline">
                    {gatewayName}
                    <Icon type="poweroff" className="pl2 red" />
                  </Tooltip>
                )}
              </Crumb>
            </Bread>
          </div>
          <div className="flex justify-center mb3">
            <span>
              <Anchor to={`${match.url}/edit`}>Edit</Anchor>
              <Divider vertical />
            </span>
            {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY) && (
              <ActionAnchor onClick={() => restartGateway(gatewayPillarId)}>
                Restart Network Gateway
              </ActionAnchor>
            )}
          </div>
          <Divider />
        </div>
        <Switch>
          <Route
            path={`${match.url}/`}
            exact
            render={props => <Redirect to={`${match.url}/uptime-chart`} />}
          />
          <Tabs
            defaultActiveKey="uptime-chart"
            type="card"
            activeKey={this.state.activeTab}
            onTabClick={this.handleTabSelection}
          >
            <TabPane tab="Uptime" key="uptime-chart">
              <Route
                path={`${match.url}/uptime-chart`}
                exact
                render={props => (
                  <UptimeChart
                    uptimeData={gatewayUptimeData}
                    getGatewayUptimeData={getGatewayUptimeData}
                    updateQueryParams={updateQueryParams}
                    isChartDataLoading={isChartDataLoading}
                    {...this.props}
                  />
                )}
              />
            </TabPane>
            <TabPane tab="Details" key="details">
              <Route
                path={`${match.url}/details`}
                exact
                render={props => (
                  <div className="flex flex-wrap">
                    <Block label="Status">
                      {toTitleCase(GATEWAY_STATUS[status])}
                    </Block>
                    <Block label="Network">
                      {networkSlug && (
                        <Anchor to={`/networks/${networkSlug}/view`}>
                          {networkName}
                        </Anchor>
                      )}
                    </Block>
                    <Block label="Monitoring">
                      {monitor ? <span className="b">Active</span> : 'Inactive'}
                    </Block>
                    <Block label="Offline Notification Delay (minutes)">
                      {offlineNotificationDelayMinutes}
                    </Block>
                    <Block label="Type">
                      {toTitleCase(GATEWAY_TYPES[gatewayType])}
                    </Block>
                    <Block label="Stack Type">
                      {toTitleCase(gatewayStackType)}
                    </Block>
                    <Block label="Pillar ID">{gatewayPillarId}</Block>
                    <Block label="Module Id">
                      {moduleId && (
                        <Anchor
                          to={`https://conductor.link-labs.com/gateway/${moduleId}`}
                        >
                          {moduleId}
                        </Anchor>
                      )}
                    </Block>
                    <Block label="Network">
                      {toTitleCase(GATEWAY_NETWORK[network])}
                    </Block>
                    <Block label="Phone">
                      {formatPhoneNumber(phoneNumber)}
                    </Block>
                    <Block label="SIM Type">
                      {formatSIMCarrier(SIM_CARRIERS[simType])}
                    </Block>
                    <Block label="SIM Card Number">
                      <Anchor to={`/inventory/sim-cards/${simCardId}`}>
                        {simCardNumber}
                      </Anchor>
                    </Block>
                    <Block label="Firmware Version">{firmwareVersion}</Block>
                    <Block label="Last Date Offline">
                      {dateTimeFormatter(offlineTime)}
                    </Block>
                    <Block label="Cellular Antenna Type">
                      {toTitleCase(cellAntennaType)}
                    </Block>
                    <Block label="LoRa Antenna Type">
                      {toTitleCase(loraAntennaType)}
                    </Block>
                    <Block label="LoRa Cable Size">
                      {toTitleCase(LORA_CABLE_SIZE[loraCableSize])}
                    </Block>
                    <Block label="Solar Panel">{toTitleCase(solarPanel)}</Block>
                    <Block label="UPS Battery">
                      {toTitleCase(UPS_BATTERY[upsBattery])}
                    </Block>
                    {siteName && (
                      <Block label="Site">
                        <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
                      </Block>
                    )}
                    {assetLocationPillarId && (
                      <Block label="Asset Location">
                        <Anchor
                          to={`/inventory/asset-locations/${assetLocationPillarId}`}
                        >
                          {assetLocationPillarId}
                        </Anchor>
                      </Block>
                    )}
                    <Block label="Tags">
                      {!!gateway.tags.length && (
                        <ul className="pl4 mb0">
                          {gateway.tags.map(tag => (
                            <li key={tag.id}>{tag.name}</li>
                          ))}
                        </ul>
                      )}
                    </Block>
                    {deploymentSlug &&
                      deploymentSiteSlug &&
                      deploymentSiteName && (
                        <Fragment>
                          <Block label="Deployment">
                            <Anchor
                              to={`/sites/${deploymentSiteSlug}/deployments/${deploymentSlug}`}
                            >
                              {deploymentTitle}
                            </Anchor>
                          </Block>
                          <Block label="Deployment Install Type">
                            {toTitleCase(deploymentInstallType)}
                          </Block>
                          <Block label="Deployment Site">
                            <Anchor to={`/sites/${deploymentSiteSlug}`}>
                              {deploymentSiteName}
                            </Anchor>
                          </Block>
                        </Fragment>
                      )}
                  </div>
                )}
              />
              </TabPane>
            {signalInfo && Object.keys(signalInfo).length > 0 && (
              <TabPane tab="Link Labs" key="link-labs">
                <Route
                  path={`${match.url}/link-labs`}
                  exact
                  render={props => (
                    <div>
                      {!!Object.keys(signalInfo).length && (
                        <div>
                          <Divider />
                          <H3>Link Labs Signal Information</H3>
                          <div className="flex flex-wrap">
                            {Object.keys(signalInfo).map((key, index) => {
                              switch (key) {
                                case 'timestamp':
                                  return (
                                    <Block key={key} label="Reading Time">
                                      {dateTimeFormatter(signalInfo[key])}
                                    </Block>
                                  )
                                case 'isBackfilling':
                                  return (
                                    <Block key={key} label="Backfill">
                                      {signalInfo.isBackfilling
                                        ? 'Active'
                                        : 'Inactive'}
                                    </Block>
                                  )
                                default:
                                  return (
                                    <Block
                                      key={key}
                                      label={toTitleCase(decamelize(key))}
                                    >
                                      {signalInfo[key]}
                                    </Block>
                                  )
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.VIEW_COMMENT) && (
              <TabPane tab="Comments" key="comments">
                <Route
                  path={`${match.url}/comments`}
                  exact
                  render={props => (
                    <AllComments
                      resourceType={RESOURCE_TYPES.GATEWAY}
                      resourceSlug={slug}
                      isCommentLoading={isCommentLoading}
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      tags={tags}
                      comments={comments}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY) && (
              <TabPane tab="Assignment History" key="assignment-history">
                <Route
                  path={`${match.url}/assignment-history`}
                  exact
                  render={props => (
                    <AssignmentHistory
                      assignmentHistory={gatewayAssignmentHistory}
                      getAssignmentHistory={getGatewayAssignmentHistory}
                      resourceId={gatewayPillarId}
                      isLoading={isHistoryLoading}
                      isByGateway={true}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY) && (
              <TabPane tab="Restart History" key="restart-history">
                <Route
                  path={`${match.url}/restart-history`}
                  exact
                  render={props => (
                    <GatewayRestartHistory
                      gatewayRestartHistory={gatewayRestartHistory}
                      getGatewayRestartHistory={getGatewayRestartHistory}
                      pillarId={match.params.pillarId}
                      isLoading={isHistoryLoading}
                    />
                  )}
                />
              </TabPane>
            )}
            {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY) && (
              <TabPane tab="Connected Pods" key="connected-pods">
                <Route
                  path={`${match.url}/connected-pods`}
                  exact
                  render={props => (
                    <ConnectedPodsList
                      gatewayConnectedPods={gatewayConnectedPods}
                      getGatewayConnectedPods={getGatewayConnectedPods}
                      gatewayId={gatewayPillarId}
                      {...this.props}
                    />
                  )}
                />
              </TabPane>
            )}
          </Tabs>
        </Switch>
      </section>
    )
  }
}

export default Gateway
