import {
  fork,
  call,
  take,
  takeLatest,
  takeEvery,
  put,
  all,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllCompaniesAction,
  getCompanyAction,
  createCompanyAction,
  updateCompanyAction,
  deleteCompanyAction,
  getAllUsersByCompanyIdAction,
  getAllEscalationPoliciesByCompanyIdAction,
  uploadLogoAction,
} from '../actions/companiesActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import { createQueryString } from '../utils/queryParams'
import { MESSAGES } from '../constants'
import { pickErrorMessage } from '../utils/helpers'

// handlers

function* handleGetAllCompaniesSaga(qs) {
  try {
    const payload = yield call(api.getAllCompanies, qs)
    yield put(getAllCompaniesAction.success(payload))
  } catch (error) {
    yield put(getAllCompaniesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetCompanySaga(id) {
  try {
    const qs = createQueryString({ perPage: 'all' })
    const [item, { items: users = [] }, { items: sites = [] }] = yield all([
      call(api.getCompanyById, id),
      call(api.getAllUsersByCompany, id, qs),
      call(api.getAllSitesByCompany, id, qs),
    ])

    yield put(getCompanyAction.success({ ...item, users, sites }))
  } catch (error) {
    yield put(getCompanyAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateCompanySaga(company) {
  try {
    const payload = yield call(api.createCompany, company)

    yield put(createCompanyAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/companies/${payload.id}`))
  } catch (error) {
    yield put(createCompanyAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateCompanySaga(company) {
  try {
    const payload = yield call(api.updateCompany, company)
    yield put(updateCompanyAction.success(payload))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/companies/${payload.id}`))
  } catch (error) {
    yield put(updateCompanyAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteCompanySaga(id) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteCompany, id)
      yield fork(handleGetAllCompaniesSaga)
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteCompanyAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllUsersByCompanyIdSaga({ payload }) {
  try {
    const { id, qs } = payload
    const result = yield call(api.getAllUsersByCompany, id, qs)
    yield put(getAllUsersByCompanyIdAction.success(result))
  } catch (error) {
    yield put(getAllUsersByCompanyIdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllEscalationPoliciesByCompanyIdSaga({ payload }) {
  try {
    const { companyId, qs } = payload
    const result = yield call(
      api.getAllEscalationPoliciesByCompany,
      companyId,
      qs
    )
    yield put(getAllEscalationPoliciesByCompanyIdAction.success(result))
  } catch (error) {
    yield put(getAllEscalationPoliciesByCompanyIdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUploadLogoSaga({ payload }) {
  try {
    const { companyId, formData } = payload

    const result = yield call(api.uploadLogo, companyId, formData)
    yield put(uploadLogoAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield put(push(`/companies/${companyId}`))
  } catch (error) {
    yield put(uploadLogoAction.failure(error))

    if (error.status === 413) {
      const apiError = { message: 'file_too_large' }
      yield put(showErrorMessageAction(pickErrorMessage({ apiError })))
    } else if (error.status === 401) {
      yield put(unauthorizedAction())
    } else {
      yield put(showErrorMessageAction(pickErrorMessage(error)))
    }
  }
}

// watchers

function* watchUploadLogoSaga() {
  yield takeLatest(uploadLogoAction.REQUEST, handleUploadLogoSaga)
}

function* watchGetAllCompaniesSaga() {
  while (true) {
    const { payload: qs } = yield take(getAllCompaniesAction.REQUEST)
    yield fork(handleGetAllCompaniesSaga, qs)
  }
}

function* watchGetCompanySaga() {
  while (true) {
    const { payload } = yield take(getCompanyAction.REQUEST)
    yield fork(handleGetCompanySaga, payload)
  }
}

function* watchCreateCompanySaga() {
  while (true) {
    const { payload } = yield take(createCompanyAction.REQUEST)
    yield fork(handleCreateCompanySaga, payload)
  }
}

function* watchUpdateCompanySaga() {
  while (true) {
    const { payload } = yield take(updateCompanyAction.REQUEST)
    yield fork(handleUpdateCompanySaga, payload)
  }
}

function* watchDeleteCompanySaga() {
  while (true) {
    const { payload } = yield take(deleteCompanyAction.REQUEST)
    yield fork(handleDeleteCompanySaga, payload)
  }
}

function* watchGetAllUsersByCompanyIdSaga() {
  yield takeEvery(
    getAllUsersByCompanyIdAction.REQUEST,
    handleGetAllUsersByCompanyIdSaga
  )
}

function* watchGetAllEscalationPoliciesByCompanyIdSaga() {
  yield takeEvery(
    getAllEscalationPoliciesByCompanyIdAction.REQUEST,
    handleGetAllEscalationPoliciesByCompanyIdSaga
  )
}

function* companiesSaga() {
  yield all([
    fork(watchGetAllCompaniesSaga),
    fork(watchGetCompanySaga),
    fork(watchCreateCompanySaga),
    fork(watchUpdateCompanySaga),
    fork(watchDeleteCompanySaga),
    fork(watchGetAllUsersByCompanyIdSaga),
    fork(watchGetAllEscalationPoliciesByCompanyIdSaga),
    fork(watchUploadLogoSaga),
  ])
}

export {
  companiesSaga as default,
  watchGetAllCompaniesSaga,
  watchGetCompanySaga,
  watchCreateCompanySaga,
  watchUpdateCompanySaga,
  watchDeleteCompanySaga,
  watchGetAllUsersByCompanyIdSaga,
  watchGetAllEscalationPoliciesByCompanyIdSaga,
  watchUploadLogoSaga,
  handleGetAllCompaniesSaga,
  handleGetCompanySaga,
  handleCreateCompanySaga,
  handleUpdateCompanySaga,
  handleDeleteCompanySaga,
  handleUploadLogoSaga,
}
