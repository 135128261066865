import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { ConnectedRouter } from 'connected-react-router'
import { Switch, Route, Redirect } from 'react-router-dom'
import bowser from 'bowser/bundled'
import { LastLocationProvider } from 'react-router-last-location'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { history } from '../store/configureStore'
import PrivateRoute from './PrivateRoute'
import { Modal } from './common/Ant'
import ErrorBoundary from './ErrorBoundary'
import AuthFormContainer from '../containers/AuthFormContainer'
import PhoneVerificationForm from './forms/PhoneVerificationForm'
import CompaniesPageContainer from '../containers/CompaniesPageContainer'
import UsersPageContainer from '../containers/UsersPageContainer'
import SitesPageContainer from '../containers/SitesPageContainer'
import DeploymentsPageContainer from '../containers/DeploymentsPageContainer'
import InventoryPageContainer from '../containers/InventoryPageContainer'
import RulesPageContainer from '../containers/RulesPageContainer'
import ReportsPageContainer from '../containers/ReportsPageContainer'
import AlertsPageContainer from '../containers/AlertsPageContainer'
import AccountPageContainer from '../containers/AccountPageContainer'
import MetadataPageContainer from '../containers/MetadataPageContainer'
import PrintPageContainer from '../containers/PrintPageContainer'
import RequestResetPasswordForm from './forms/RequestResetPasswordForm'
import ResetPasswordFormContainer from '../containers/ResetPasswordFormContainer'
import TwoFactorAuthVerificationFormContainer from '../containers/TwoFactorAuthVerificationFormContainer'
import RequestAccountConfirmationEmailForm from './forms/RequestAccountConfirmationEmailForm'
import ConfirmAccountFormContainer from '../containers/ConfirmAccountFormContainer'
import DashboardPageContainer from '../containers/DashboardPageContainer'
import WorkflowPageContainer from '../containers/WorkflowPageContainer'
import DeveloperPageContainer from '../containers/DeveloperPageContainer'
import NetworksPagesContainer from '../containers/NetworksPageContainer'
import CommunicationsPageContainer from '../containers/CommunicationsPageContainer'
import AllChatsContainer from '../containers/AllChatsContainer'
import PublicReportContainer from '../containers/PublicReportContainer'
import BLEContainer from '../containers/BLEContainer'
import NativeApps from './NativeApps'
import ScrollToTop from './ScrollToTop'
import { Layout } from './common/Ant'
import { getToken } from '../services/storage'
import { bootIntercom } from '../services/third-party/intercom'
import { verifySocketConnection, disconnectSocket } from '../services/socket'
import { Z_INDEX, USER_PERMISSIONS } from '../constants'
import { hasPermission } from '../utils/hasPermission'
import { flagEnabled } from '../utils/config'
import '../less/root.less'

const isCommunicationsEnabled = flagEnabled('REACT_APP_ENABLE_COMMUNICATIONS')

class Root extends Component {
  constructor(props) {
    super(props)

    const token = getToken()

    const { pathname, search } = global.location

    if (token) {
      this.props.hasAuthToken({ pathname, search })
    } else {
      // TODO pass `search` into this as well
      this.props.setInitialPathname(pathname)
      this.props.unauthorized()
    }

    bootIntercom()

    const browser = bowser.getParser(global.navigator.userAgent)
    const { name, version } = browser.getBrowser()
    const isIE11 = name === 'Internet Explorer' && parseInt(version, 10) >= 11

    this.state = { isIE11 }
  }

  static propTypes = {
    isAuthorized: PropTypes.bool.isRequired,
    hasAuthToken: PropTypes.func.isRequired,
    unauthorized: PropTypes.func.isRequired,
    updateWindowWidth: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    requestPhoneVerificationToken: PropTypes.func.isRequired,
    setInitialPathname: PropTypes.func.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    isSocketConnected: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    this.props.updateWindowWidth(global.innerWidth)

    global.addEventListener('resize', () => {
      this.props.updateWindowWidth(global.innerWidth)
    })

    if (this.state.isIE11) {
      document.getElementById('ie-message').style['display'] = 'none'
    }

    global.addEventListener('focus', verifySocketConnection)

    global.addEventListener('blur', () => {
      setTimeout(() => {
        if (!document.hasFocus()) {
          disconnectSocket()
        }
      }, 120000) // two min
    })
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.props.updateWindowWidth(0))
  }

  handleModalCancel = () => {
    this.props.hideModal()
  }

  render() {
    const {
      isAuthorized,
      isModalVisible,
      isSocketConnected,
      requestPhoneVerificationToken,
    } = this.props

    return (
      <ConnectedRouter history={history}>
        <LastLocationProvider>
          <DndProvider backend={HTML5Backend}>
            <ScrollToTop>
              <Helmet
                titleTemplate="%s | Pillar Technologies"
                defaultTitle="Dashboard | Pillar Technologies"
              />
              <Layout className="sans-serif black-80 Root">
                <ErrorBoundary>
                  <Switch>
                    <Route
                      path="/two-factor"
                      exact
                      component={TwoFactorAuthVerificationFormContainer}
                    />
                    <Route
                      path="/reset-password"
                      exact
                      component={RequestResetPasswordForm}
                    />
                    <Route
                      path="/reset-password/:token"
                      exact
                      component={ResetPasswordFormContainer}
                    />
                    <Route
                      path="/confirm"
                      exact
                      component={RequestAccountConfirmationEmailForm}
                    />
                    <Route
                      path="/confirm/:token"
                      exact
                      component={ConfirmAccountFormContainer}
                    />
                    <Route path="/native-apps" exact component={NativeApps} />
                    <Route
                      path="/reports/:reportSlug/view"
                      exact
                      component={PublicReportContainer}
                    />
                    <PrivateRoute
                      path="/account"
                      component={AccountPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/dashboard/:floorSlug?"
                      component={DashboardPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/companies"
                      component={CompaniesPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/inventory"
                      component={InventoryPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/sites"
                      component={SitesPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/deployments"
                      component={DeploymentsPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/users"
                      component={UsersPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/alerts"
                      component={AlertsPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/rules"
                      component={RulesPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/reports"
                      component={ReportsPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/metadata"
                      component={MetadataPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/workflows"
                      component={WorkflowPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/hilti"
                      component={DashboardPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/developer"
                      component={DeveloperPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/communications"
                      component={CommunicationsPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/ble"
                      component={BLEContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/networks"
                      component={NetworksPagesContainer}
                      isAuthorized={isAuthorized}
                    />
                    <PrivateRoute
                      path="/print"
                      component={PrintPageContainer}
                      isAuthorized={isAuthorized}
                    />
                    <Route path="/" exact component={AuthFormContainer} />
                    <Route render={props => <Redirect to="/dashboard" />} />
                  </Switch>
                  <Modal
                    visible={isModalVisible}
                    footer={null}
                    onCancel={this.handleModalCancel}
                    zIndex={Z_INDEX.HUGE}
                    style={{ top: 16 }}
                    destroyOnClose
                  >
                    <PhoneVerificationForm
                      requestPhoneVerificationToken={
                        requestPhoneVerificationToken
                      }
                    />
                  </Modal>
                  {isCommunicationsEnabled &&
                    isSocketConnected &&
                    hasPermission(USER_PERMISSIONS.ENABLE_COMMS_HUB) && (
                      <AllChatsContainer />
                    )}
                </ErrorBoundary>
                {this.state.isIE11 && (
                  <div className="bg-orange fixed bottom-0 w-100 z-max bt b--light-gray pv3 ph6 white tc f5">
                    You are using an outdated browser, which may cause some
                    features of Pillar's website to work incorrectly. Please
                    download a modern browser that is fully compatible with our
                    website, such as{' '}
                    <a
                      className="white b underline"
                      href="https://www.mozilla.org/en-US/firefox/new/"
                    >
                      Mozilla Firefox
                    </a>{' '}
                    or{' '}
                    <a
                      className="white b underline"
                      href="https://www.google.com/chrome/"
                    >
                      Google Chrome
                    </a>
                    .
                  </div>
                )}
              </Layout>
            </ScrollToTop>
          </DndProvider>
        </LastLocationProvider>
      </ConnectedRouter>
    )
  }
}

export default Root
