import { connect } from 'react-redux'
import NetworksPage from '../components/pages/NetworksPage'
import {
  createNetworkAction,
  updateNetworkAction,
  deleteNetworkAction,
  getAllNetworksAction,
  getAllNetworkAuthoritiesAction,
  createNetworkAuthorityAction,
  updateNetworkAuthorityAction,
  deleteNetworkAuthorityAction,
  clearNetworkAuthorityTokenAction,
  regenerateNetworkAuthorityTokenAction,
} from '../actions/networksActions'
import {
  getVisibleNetworks,
  getVisibleNetworkAuthorities,
  getNetworkAuthorityToken,
} from '../reducers/networksReducer'
import { isNetworkLoading } from '../reducers/uiReducer'

const mapStateToProps = ({ networks, ui }) => ({
  networkAuthorities: getVisibleNetworkAuthorities(networks),
  networks: getVisibleNetworks(networks),
  token: getNetworkAuthorityToken(networks),
  isLoading: isNetworkLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllNetworks: payload => dispatch(getAllNetworksAction.request(payload)),
  createNetwork: payload => dispatch(createNetworkAction.request(payload)),
  updateNetwork: payload => dispatch(updateNetworkAction.request(payload)),
  deleteNetwork: payload => dispatch(deleteNetworkAction.request(payload)),
  getAllNetworkAuthorities: payload =>
    dispatch(getAllNetworkAuthoritiesAction.request(payload)),
  createNetworkAuthority: payload =>
    dispatch(createNetworkAuthorityAction.request(payload)),
  updateNetworkAuthority: payload =>
    dispatch(updateNetworkAuthorityAction.request(payload)),
  deleteNetworkAuthority: payload =>
    dispatch(deleteNetworkAuthorityAction.request(payload)),
  clearToken: () => dispatch(clearNetworkAuthorityTokenAction()),
  regenerateToken: payload =>
    dispatch(regenerateNetworkAuthorityTokenAction.request(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NetworksPage)
