import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Icon } from './Ant'

const PillarTag = ({ iconType, className = '', label, children }) => {
  const containerClassNames = cx(
    'dib mh1 ba b--moon-gray br2 flex bg-near-white'
  )

  const labelClassNames = cx('black-60 ph2')
  const textClassNames = cx(
    'ph2 br2 br--right bl b--light-gray t-shadow-1',
    className,
    {
      white: className,
      'black-60': !className,
    }
  )

  return (
    <div className={containerClassNames}>
      {iconType ? (
        <div className={labelClassNames}>
          <Icon type={iconType} className="pr2" />
          {label}
        </div>
      ) : (
        <div className={labelClassNames}>{label}</div>
      )}
      <div
        className={textClassNames}
        style={{
          margin: '-1px',
        }}
      >
        {children}
      </div>
    </div>
  )
}

PillarTag.propTypes = {
  label: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default PillarTag
