import { createFormAction } from 'redux-form-saga'

const CLEAR_ALERT = 'CLEAR_ALERT'

const getAllAlertsAction = createFormAction('GET_ALL_ALERTS')
const getAllGatewayAlertsAction = createFormAction('GET_ALL_GATEWAY_ALERTS')
const getAlertAction = createFormAction('GET_ALERT')
const getGatewayAlertAction = createFormAction('GET_GATEWAY_ALERT')
const hideAlertAction = createFormAction('HIDE_ALERT')
const unhideAlertAction = createFormAction('UNHIDE_ALERT')
const closeAlertAction = createFormAction('CLOSE_ALERT')
const closeGatewayAlertAction = createFormAction('CLOSE_GATEWAY_ALERT')
const clearAlertAction = () => ({ type: CLEAR_ALERT })
const acknowledgeAlertAction = createFormAction('ACKNOWLEDGE_ALERT')
const resolveAlertAction = createFormAction('RESOLVE_ALERT')
const getAlertAuditLogAction = createFormAction('GET_ALERT_AUDIT_LOG')
const getLocationAlertHistoryAction = createFormAction(
  'GET_LOCATION_ALERT_HISTORY'
)
const getLocationUptimeDataAction = createFormAction('GET_LOCATION_UPTIME_DATA')
const getAllActionReportsAction = createFormAction('GET_ALL_ACTION_REPORTS')
const generateAlertExportAction = createFormAction('GENERATE_ALERT_EXPORT')
const batchUpdateAlertsAction = createFormAction('BATCH_UPDATE_ALERTS')

export {
  getAllAlertsAction,
  getAllGatewayAlertsAction,
  getAlertAction,
  getGatewayAlertAction,
  hideAlertAction,
  unhideAlertAction,
  closeAlertAction,
  clearAlertAction,
  acknowledgeAlertAction,
  resolveAlertAction,
  getAlertAuditLogAction,
  getLocationAlertHistoryAction,
  getLocationUptimeDataAction,
  closeGatewayAlertAction,
  getAllActionReportsAction,
  CLEAR_ALERT,
  generateAlertExportAction,
  batchUpdateAlertsAction,
}
