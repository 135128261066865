import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import UsersPage from '../components/pages/UsersPage'
import {
  getAllUsersAction,
  getUserAction,
  getUserCommunicationLogAction,
  getUserPermissionsAction,
  updateUserPermissionsAction,
  deleteUserAction,
  toggleUserOptionAction,
  updateUserDefaultSiteAction,
  revokeApiTokenAction,
  getDeveloperAccessTokenHistoryAction,
} from '../actions/usersActions'
import { requestAccountConfirmationAction } from '../actions/authActions'
import { getAllSitesAction } from '../actions/sitesActions'
import { getVisibleSites } from '../reducers/sitesReducer'
import { getAllLeadsAction } from '../actions/leadsActions'
import { getAllCompaniesAction } from '../actions/companiesActions'
import {
  getVisibleUsers,
  getVisibleUserCommunicationLog,
  getCurrentUser,
  getUsersMeta,
  getUserCommunicationLogMeta,
} from '../reducers/usersReducer'
import { getVisibleLeads, getLeadsMeta } from '../reducers/leadsReducer'
import { getVisibleCompanies } from '../reducers/companiesReducer'
import {
  getWindowWidth,
  isUserLoading,
  isHistoryLoading,
} from '../reducers/uiReducer'

const mapStateToProps = ({ users, companies, account, ui, leads, sites }) => ({
  users: getVisibleUsers(users),
  currentUser: getCurrentUser(users),
  userCommunicationLog: getVisibleUserCommunicationLog(users),
  companies: getVisibleCompanies(companies),
  sites: getVisibleSites(sites),
  meta: getUsersMeta(users),
  logMeta: getUserCommunicationLogMeta(users),
  leads: getVisibleLeads(leads),
  leadsMeta: getLeadsMeta(leads),
  windowWidth: getWindowWidth(ui),
  isUserLoading: isUserLoading(ui),
  isHistoryLoading: isHistoryLoading(ui),
})

const mapDispatchToProps = dispatch => ({
  getAllUsers: qs => dispatch(getAllUsersAction.request(qs)),
  deleteUser: slug => dispatch(deleteUserAction.request(slug)),
  getUser: slug => dispatch(getUserAction.request(slug)),
  getUserPermissions: slug => dispatch(getUserPermissionsAction.request(slug)),
  getUserCommunicationLog: (slug, qs) =>
    dispatch(getUserCommunicationLogAction.request({ slug, qs })),
  updateUserPermissions: (slug, permissions) =>
    dispatch(updateUserPermissionsAction.request({ slug, permissions })),
  getAllCompanies: qs => dispatch(getAllCompaniesAction.request(qs)),
  getAllSites: qs => dispatch(getAllSitesAction.request(qs)),
  toggleUserOption: user => dispatch(toggleUserOptionAction.request(user)),
  setDefaultSite: payload =>
    dispatch(updateUserDefaultSiteAction.request(payload)),
  updateQueryParams: location => dispatch(replace(location)),
  revokeApiToken: userSlug => dispatch(revokeApiTokenAction.request(userSlug)),
  getAccessTokenHistory: userSlug =>
    dispatch(getDeveloperAccessTokenHistoryAction.request(userSlug)),
  getAllLeads: qs => dispatch(getAllLeadsAction.request(qs)),
  resendConfirmation: email =>
    dispatch(requestAccountConfirmationAction.request({ email })),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)
