import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditCompanyForm from './forms/EditCompanyForm'
import LogoUpload from './LogoUpload'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateCompanyAction } from '../actions/companiesActions'

class EditCompany extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    getCompany: PropTypes.func.isRequired,
    uploadLogo: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    this.props.getCompany(this.props.match.params.id)
  }

  render() {
    const { uploadLogo, company, getCompany } = this.props
    const crumbs = [<Anchor to="/companies">Companies</Anchor>, 'Edit Company']

    return (
      <section className="EditCompany">
        <FormHeader crumbs={crumbs} />
        {company.id && (
          <LogoUpload
            companyId={company.id}
            getCompany={getCompany}
            uploadLogo={uploadLogo}
          />
        )}
        <EditCompanyForm
          submitAction={updateCompanyAction}
          initialValues={company}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditCompany
