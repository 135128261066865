import { combineReducers } from 'redux'
import {
  TOGGLE_SIDE_NAV_COLLAPSE,
  UPDATE_WINDOW_WIDTH,
  SHOW_PHONE_VERIFICATION_MODAL,
  HIDE_PHONE_VERIFICATION_MODAL,
  TOGGLE_FULL_WIDTH,
} from '../actions/uiActions'
import { getAccountProfileAction } from '../actions/accountActions'
import { HAS_AUTH_TOKEN } from '../actions/authActions'
import {
  getAllSitesAction,
  getSiteAction,
  getPodAssignmentHistoryBySiteAction,
  getAdminSiteAction,
  getActivityLogAction,
  getFloorPlanSnapsAction,
  uploadFloorPlanPdfAction,
  getRiMetricsAction,
  getAllDocumentsAction,
} from '../actions/sitesActions'
import {
  getAllFloorsAction,
  getFloorAction,
  createFloorAction,
  submitSiteFloorsAction,
  updateFloorAction,
  getFloorSummaryAction,
  getAllFloorSummariesAction,
  getAllFloorsByBuildingAction,
} from '../actions/floorsActions'
import {
  getAllLocationsByFloorAction,
  getAllLocationsBySiteAction,
  getLocationAction,
  updateLocationAction,
  getAllLocationsSnapshotDataByFloorAction,
  getLocationTimeSeriesDataByLocationIdsAction,
  getPodAssignmentHistoryByLocationAction,
  getLocationSnapshotAction,
} from '../actions/locationsActions'
import {
  getAllAlertsAction,
  getAlertAction,
  getGatewayAlertAction,
  getLocationAlertHistoryAction,
  getLocationUptimeDataAction,
  getAllGatewayAlertsAction,
  getAllActionReportsAction,
} from '../actions/alertsActions'
import {
  getAllGatewaysAction,
  getGatewayAction,
  updateGatewayAction,
  getGatewayAlertsUptimeDataAction,
  getGatewayConnectedPodsAction,
  getSiteGatewaysAction,
  getGatewayAssignmentHistoryAction,
  getGatewayRestartHistoryAction,
} from '../actions/gatewaysActions'
import {
  getAllSIMCardsAction,
  getSIMCardAction,
  updateSIMCardAction,
} from '../actions/simCardsActions'
import {
  getAllPartsAction,
  getPartAction,
  updatePartAction,
} from '../actions/partsActions'
import {
  getAllPurchasesAction,
  getPurchaseAction,
  updatePurchaseAction,
} from '../actions/purchasesActions'
import {
  getAllFlowMonitorsAction,
  getAllSiteFlowMonitorAction,
  getFlowMonitorDataAction,
  getFlowMonitorAction,
  updateFlowMonitorAction,
  getAllAvailableFlowMonitorsBySiteAction,
  getFlowMonitorAssignmentHistoryAction,
  getValveStatusHistoryAction,
  getFlowMonitorEditHistoryAction,
  getAllWaterMetersAction,
  getWaterMeterAction,
  updateWaterMeterAction,
  getAllWaterValvesAction,
  getWaterValveAction,
  updateWaterValveAction,
} from '../actions/flowMonitorsActions'
import {
  getAllGatewaySuppliesAction,
  getGatewaySupplyAction,
  updateGatewaySupplyAction,
} from '../actions/gatewaySuppliesActions'
import {
  getAllPodsAction,
  getPodAction,
  updatePodAction,
  getPodMetadataAction,
  getPodAssignmentHistoryAction,
  getPodEditHistoryAction,
  getPodCalibrationHistoryAction,
  getMultiplePodsDataAction,
} from '../actions/podsActions'
import { getAllAssetLocationsAction } from '../actions/assetLocationsActions'
import {
  getAllThresholdsAction,
  getThresholdAction,
  updateThresholdAction,
  getThresholdEditHistoryAction,
  getAllThresholdGroupsAction,
} from '../actions/thresholdsActions'
import {
  getAllEscalationPoliciesAction,
  getEscalationPolicyAction,
  updateEscalationPolicyAction,
} from '../actions/escalationPoliciesActions'
import {
  getAllCompaniesAction,
  getCompanyAction,
  updateCompanyAction,
} from '../actions/companiesActions'
import {
  getUserCommunicationLogAction,
  getAllUsersAction,
  getUserAction,
  updateUserAction,
} from '../actions/usersActions'
import {
  getAllCommentsAction,
  updateCommentAction,
  getAllTagsAction,
} from '../actions/commentsActions'
import {
  getAllActionReportCausesAction,
  getActionReportCauseAction,
  updateActionReportCauseAction,
} from '../actions/actionReportsActions'
import {
  getAllDeploymentsBySiteAction,
  getAllDeploymentsAction,
  getDeploymentAction,
  updateDeploymentAction,
  getShipmentAction,
  updateShipmentAction,
  getAllShipmentsAction,
  getDeploymentEditHistoryAction,
} from '../actions/deploymentsActions'
import { getPodChartDataAction } from '../actions/podsActions'
import { getLocationChartDataAction } from '../actions/locationsActions'
import {
  createReportAction,
  getAllReportsAction,
  getPublicReportAction,
} from '../actions/reportsActions'
import {
  getAllWorkflowsAction,
  getWorkflowAction,
} from '../actions/workflowsActions'
import {
  getAccessTokenAction,
  generateAccessTokenAction,
} from '../actions/developerActions'
import { getAllLeadsAction } from '../actions/leadsActions'
import {
  getAllSupportPhoneNumbersAction,
  getSupportPhoneNumberAction,
  getAllConversationsAction,
  // getConversationAction
} from '../actions/communicationsActions'
import {
  getAllSiteImuNanoIotAction,
  getImuDataAction,
} from '../actions/dashboardActions'
import { getAllBLEDataAction } from '../actions/bleActions'
import {
  createNetworkAuthorityAction,
  updateNetworkAuthorityAction,
  getAllNetworkAuthoritiesAction,
} from '../actions/networksActions'
import {
  getAllAlertSummariesAction,
  getAlertSummaryAction,
} from '../actions/alertSummariesActions'
import { getFullWidth, setFullWidth } from '../services/storage'

const windowWidthReducer = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_WINDOW_WIDTH:
      return payload
    default:
      return state
  }
}

const sideNavReducer = (state = true, { type }) => {
  switch (type) {
    case TOGGLE_SIDE_NAV_COLLAPSE:
      return !state
    default:
      return state
  }
}

const accountReducer = (state = false, { type }) => {
  switch (type) {
    case HAS_AUTH_TOKEN:
    case getAccountProfileAction.REQUEST:
      return true
    case getAccountProfileAction.SUCCESS:
    case getAccountProfileAction.FAILURE:
      return false
    default:
      return state
  }
}

const siteReducer = (state = true, { type }) => {
  switch (type) {
    case getAllSitesAction.REQUEST:
    case getSiteAction.REQUEST:
    case getAdminSiteAction.REQUEST:
    case uploadFloorPlanPdfAction.REQUEST:
    case createFloorAction.REQUEST:
    case submitSiteFloorsAction.REQUEST:
      return true
    case getAllSitesAction.SUCCESS:
    case getSiteAction.SUCCESS:
    case getAdminSiteAction.SUCCESS:
    case uploadFloorPlanPdfAction.SUCCESS:
    case submitSiteFloorsAction.SUCCESS:
    case createFloorAction.SUCCESS:
    case getAllSitesAction.FAILURE:
    case getSiteAction.FAILURE:
    case getAdminSiteAction.FAILURE:
    case uploadFloorPlanPdfAction.FAILURE:
    case submitSiteFloorsAction.FAILURE:
    case createFloorAction.FAILURE:
      return false
    default:
      return state
  }
}

const deploymentReducer = (state = true, { type }) => {
  switch (type) {
    case getAllDeploymentsBySiteAction.REQUEST:
    case getAllDeploymentsAction.REQUEST:
    case getDeploymentAction.REQUEST:
    case updateDeploymentAction.REQUEST:
      return true
    case getAllDeploymentsBySiteAction.SUCCESS:
    case getAllDeploymentsAction.SUCCESS:
    case getDeploymentAction.SUCCESS:
    case updateDeploymentAction.SUCCESS:
    case getAllDeploymentsAction.FAILURE:
    case getAllDeploymentsBySiteAction.FAILURE:
    case getDeploymentAction.FAILURE:
    case updateDeploymentAction.FAILURE:
      return false
    default:
      return state
  }
}

const shipmentReducer = (state = true, { type }) => {
  switch (type) {
    case getShipmentAction.REQUEST:
    case updateShipmentAction.REQUEST:
    case getAllShipmentsAction.REQUEST:
      return true
    case getShipmentAction.SUCCESS:
    case updateShipmentAction.SUCCESS:
    case getAllShipmentsAction.SUCCESS:
    case getShipmentAction.FAILURE:
    case updateShipmentAction.FAILURE:
    case getAllShipmentsAction.FAILURE:
      return false
    default:
      return state
  }
}

const floorReducer = (state = true, { type }) => {
  switch (type) {
    case getAllFloorsAction.REQUEST:
    case getFloorAction.REQUEST:
    case updateFloorAction.REQUEST:
    case getAllFloorsByBuildingAction.REQUEST:
      return true
    case getAllFloorsAction.SUCCESS:
    case getFloorAction.SUCCESS:
    case updateFloorAction.SUCCESS:
    case getAllFloorsByBuildingAction.SUCCESS:
    case getAllFloorsAction.FAILURE:
    case getFloorAction.FAILURE:
    case updateFloorAction.FAILURE:
    case getAllFloorsByBuildingAction.FAILURE:
      return false
    default:
      return state
  }
}

const locationReducer = (state = true, { type }) => {
  switch (type) {
    case getAllLocationsByFloorAction.REQUEST:
    case getAllLocationsBySiteAction.REQUEST:
    case getLocationAction.REQUEST:
    case updateLocationAction.REQUEST:
      return true
    case getAllLocationsByFloorAction.SUCCESS:
    case getAllLocationsBySiteAction.SUCCESS:
    case getLocationAction.SUCCESS:
    case updateLocationAction.SUCCESS:
    case getAllLocationsBySiteAction.FAILURE:
    case getAllLocationsByFloorAction.FAILURE:
    case getLocationAction.FAILURE:
    case updateLocationAction.FAILURE:
      return false
    default:
      return state
  }
}

const alertReducer = (state = true, { type }) => {
  switch (type) {
    case getAllAlertsAction.REQUEST:
    case getAlertAction.REQUEST:
    case getGatewayAlertAction.REQUEST:
    case getAllGatewayAlertsAction.REQUEST:
    case getLocationUptimeDataAction.REQUEST:
    case getAllActionReportsAction.REQUEST:
      return true
    case getAllAlertsAction.SUCCESS:
    case getAlertAction.SUCCESS:
    case getGatewayAlertAction.SUCCESS:
    case getAllGatewayAlertsAction.SUCCESS:
    case getLocationUptimeDataAction.SUCCESS:
    case getAllActionReportsAction.SUCCESS:
    case getAllAlertsAction.FAILURE:
    case getAlertAction.FAILURE:
    case getGatewayAlertAction.FAILURE:
    case getAllGatewayAlertsAction.FAILURE:
    case getLocationUptimeDataAction.FAILURE:
    case getAllActionReportsAction.FAILURE:
      return false
    default:
      return state
  }
}

const gatewayReducer = (state = true, { type }) => {
  switch (type) {
    case getAllGatewaysAction.REQUEST:
    case getGatewayAction.REQUEST:
    case updateGatewayAction.REQUEST:
    case getSiteGatewaysAction.REQUEST:
      return true
    case getAllGatewaysAction.SUCCESS:
    case getGatewayAction.SUCCESS:
    case updateGatewayAction.SUCCESS:
    case getSiteGatewaysAction.SUCCESS:
    case getAllGatewaysAction.FAILURE:
    case getGatewayAction.FAILURE:
    case updateGatewayAction.FAILURE:
    case getSiteGatewaysAction.FAILURE:
      return false
    default:
      return state
  }
}

const simCardReducer = (state = true, { type }) => {
  switch (type) {
    case getAllSIMCardsAction.REQUEST:
    case getSIMCardAction.REQUEST:
    case updateSIMCardAction.REQUEST:
      return true
    case getAllSIMCardsAction.SUCCESS:
    case getSIMCardAction.SUCCESS:
    case updateSIMCardAction.SUCCESS:
    case getAllSIMCardsAction.FAILURE:
    case getSIMCardAction.FAILURE:
    case updateSIMCardAction.FAILURE:
      return false
    default:
      return state
  }
}

const partReducer = (state = true, { type }) => {
  switch (type) {
    case getAllPartsAction.REQUEST:
    case getPartAction.REQUEST:
    case updatePartAction.REQUEST:
      return true
    case getAllPartsAction.SUCCESS:
    case getPartAction.SUCCESS:
    case updatePartAction.SUCCESS:
    case getAllPartsAction.FAILURE:
    case getPartAction.FAILURE:
    case updatePartAction.FAILURE:
      return false
    default:
      return state
  }
}

const purchaseReducer = (state = true, { type }) => {
  switch (type) {
    case getAllPurchasesAction.REQUEST:
    case getPurchaseAction.REQUEST:
    case updatePurchaseAction.REQUEST:
      return true
    case getAllPurchasesAction.SUCCESS:
    case getPurchaseAction.SUCCESS:
    case updatePurchaseAction.SUCCESS:
    case getAllPurchasesAction.FAILURE:
    case getPurchaseAction.FAILURE:
    case updatePurchaseAction.FAILURE:
      return false
    default:
      return state
  }
}

const flowMonitorReducer = (state = true, { type }) => {
  switch (type) {
    case getAllFlowMonitorsAction.REQUEST:
    case getFlowMonitorAction.REQUEST:
    case updateFlowMonitorAction.REQUEST:
    case getAllWaterMetersAction.REQUEST:
    case getWaterMeterAction.REQUEST:
    case updateWaterMeterAction.REQUEST:
    case getAllWaterValvesAction.REQUEST:
    case getWaterValveAction.REQUEST:
    case updateWaterValveAction.REQUEST:
      return true
    case getAllFlowMonitorsAction.SUCCESS:
    case getAllAvailableFlowMonitorsBySiteAction.SUCCESS:
    case getFlowMonitorAction.SUCCESS:
    case updateFlowMonitorAction.SUCCESS:
    case getAllWaterMetersAction.SUCCESS:
    case getWaterMeterAction.SUCCESS:
    case updateWaterMeterAction.SUCCESS:
    case getAllWaterValvesAction.SUCCESS:
    case getWaterValveAction.SUCCESS:
    case updateWaterValveAction.SUCCESS:
    case getAllFlowMonitorsAction.FAILURE:
    case getAllAvailableFlowMonitorsBySiteAction.FAILURE:
    case getFlowMonitorAction.FAILURE:
    case updateFlowMonitorAction.FAILURE:
    case getAllWaterMetersAction.FAILURE:
    case getWaterMeterAction.FAILURE:
    case updateWaterMeterAction.FAILURE:
    case getAllWaterValvesAction.FAILURE:
    case getWaterValveAction.FAILURE:
    case updateWaterValveAction.FAILURE:
      return false
    default:
      return state
  }
}

const gatewaySupplyReducer = (state = true, { type }) => {
  switch (type) {
    case getAllGatewaySuppliesAction.REQUEST:
    case getGatewaySupplyAction.REQUEST:
    case updateGatewaySupplyAction.REQUEST:
      return true
    case getAllGatewaySuppliesAction.SUCCESS:
    case getGatewaySupplyAction.SUCCESS:
    case updateGatewaySupplyAction.SUCCESS:
    case getAllGatewaySuppliesAction.FAILURE:
    case getGatewaySupplyAction.FAILURE:
    case updateGatewaySupplyAction.FAILURE:
      return false
    default:
      return state
  }
}

const podReducer = (state = true, { type }) => {
  switch (type) {
    case getAllPodsAction.REQUEST:
    case getPodAction.REQUEST:
    case updatePodAction.REQUEST:
    case getPodMetadataAction.REQUEST:
      return true
    case getAllPodsAction.SUCCESS:
    case getPodAction.SUCCESS:
    case updatePodAction.SUCCESS:
    case getPodMetadataAction.SUCCESS:
    case getAllPodsAction.FAILURE:
    case getPodAction.FAILURE:
    case updatePodAction.FAILURE:
    case getPodMetadataAction.FAILURE:
      return false
    default:
      return state
  }
}

const assetLocationReducer = (state = true, { type }) => {
  switch (type) {
    case getAllAssetLocationsAction.REQUEST:
      return true
    case getAllAssetLocationsAction.SUCCESS:
    case getAllAssetLocationsAction.FAILURE:
      return false
    default:
      return state
  }
}

const historyReducer = (state = true, { type }) => {
  switch (type) {
    case getActivityLogAction.REQUEST:
    case getAllSiteImuNanoIotAction.REQUEST:
    case getRiMetricsAction.REQUEST:
    case getPodEditHistoryAction.REQUEST:
    case getAllDocumentsAction.REQUEST:
    case getPodAssignmentHistoryAction.REQUEST:
    case getFlowMonitorEditHistoryAction.REQUEST:
    case getFlowMonitorAssignmentHistoryAction.REQUEST:
    case getValveStatusHistoryAction.REQUEST:
    case getGatewayAssignmentHistoryAction.REQUEST:
    case getThresholdEditHistoryAction.REQUEST:
    case getLocationAlertHistoryAction.REQUEST:
    case getPodAssignmentHistoryByLocationAction.REQUEST:
    case getGatewayRestartHistoryAction.REQUEST:
    case getPodAssignmentHistoryBySiteAction.REQUEST:
    case getDeploymentEditHistoryAction.REQUEST:
    case getUserCommunicationLogAction.REQUEST:
    case getFloorPlanSnapsAction.REQUEST:
      return true
    case getActivityLogAction.SUCCESS:
    case getAllDocumentsAction.SUCCESS:
    case getAllSiteImuNanoIotAction.SUCCESS:
    case getAllSiteFlowMonitorAction.SUCCESS:
    case getRiMetricsAction.SUCCESS:
    case getThresholdEditHistoryAction.SUCCESS:
    case getPodEditHistoryAction.SUCCESS:
    case getPodAssignmentHistoryAction.SUCCESS:
    case getFlowMonitorEditHistoryAction.SUCCESS:
    case getFlowMonitorAssignmentHistoryAction.SUCCESS:
    case getValveStatusHistoryAction.SUCCESS:
    case getLocationAlertHistoryAction.SUCCESS:
    case getPodAssignmentHistoryByLocationAction.SUCCESS:
    case getPodAssignmentHistoryBySiteAction.SUCCESS:
    case getGatewayAssignmentHistoryAction.SUCCESS:
    case getGatewayRestartHistoryAction.SUCCESS:
    case getDeploymentEditHistoryAction.SUCCESS:
    case getUserCommunicationLogAction.SUCCESS:
    case getFloorPlanSnapsAction.SUCCESS:
    case getFloorPlanSnapsAction.FAILURE:
    case getActivityLogAction.FAILURE:
    case getAllSiteImuNanoIotAction.FAILURE:
    case getAllDocumentsAction.FAILURE:
    case getAllSiteFlowMonitorAction.FAILURE:
    case getRiMetricsAction.FAILURE:
    case getGatewayAssignmentHistoryAction.FAILURE:
    case getThresholdEditHistoryAction.FAILURE:
    case getPodEditHistoryAction.FAILURE:
    case getPodAssignmentHistoryAction.FAILURE:
    case getFlowMonitorEditHistoryAction.FAILURE:
    case getFlowMonitorAssignmentHistoryAction.FAILURE:
    case getValveStatusHistoryAction.FAILURE:
    case getLocationAlertHistoryAction.FAILURE:
    case getPodAssignmentHistoryByLocationAction.FAILURE:
    case getPodAssignmentHistoryBySiteAction.FAILURE:
    case getGatewayRestartHistoryAction.FAILURE:
    case getDeploymentEditHistoryAction.FAILURE:
    case getUserCommunicationLogAction.FAILURE:
      return false
    default:
      return state
  }
}

const thresholdReducer = (state = true, { type }) => {
  switch (type) {
    case getAllThresholdsAction.REQUEST:
    case getThresholdAction.REQUEST:
    case updateThresholdAction.REQUEST:
      return true
    case getAllThresholdsAction.SUCCESS:
    case getThresholdAction.SUCCESS:
    case updateThresholdAction.SUCCESS:
    case getAllThresholdsAction.FAILURE:
    case getThresholdAction.FAILURE:
    case updateThresholdAction.FAILURE:
      return false
    default:
      return state
  }
}

const thresholdGroupReducer = (state = true, { type }) => {
  switch (type) {
    case getAllThresholdGroupsAction.REQUEST:
      return true
    case getAllThresholdGroupsAction.SUCCESS:
    case getAllThresholdGroupsAction.FAILURE:
      return false
    default:
      return state
  }
}

const escalationPolicyReducer = (state = true, { type }) => {
  switch (type) {
    case getAllEscalationPoliciesAction.REQUEST:
    case getEscalationPolicyAction.REQUEST:
    case updateEscalationPolicyAction.REQUEST:
      return true
    case getAllEscalationPoliciesAction.SUCCESS:
    case getEscalationPolicyAction.SUCCESS:
    case updateEscalationPolicyAction.SUCCESS:
    case getAllEscalationPoliciesAction.FAILURE:
    case getEscalationPolicyAction.FAILURE:
    case updateEscalationPolicyAction.FAILURE:
      return false
    default:
      return state
  }
}

const companyReducer = (state = true, { type }) => {
  switch (type) {
    case getAllCompaniesAction.REQUEST:
    case getCompanyAction.REQUEST:
    case updateCompanyAction.REQUEST:
      return true
    case getAllCompaniesAction.SUCCESS:
    case getCompanyAction.SUCCESS:
    case updateCompanyAction.SUCCESS:
    case getAllCompaniesAction.FAILURE:
    case getCompanyAction.FAILURE:
    case updateCompanyAction.FAILURE:
      return false
    default:
      return state
  }
}

const userReducer = (state = true, { type }) => {
  switch (type) {
    case getAllUsersAction.REQUEST:
    case getUserAction.REQUEST:
    case updateUserAction.REQUEST:
    case getAllLeadsAction.REQUEST:
      return true
    case getAllUsersAction.SUCCESS:
    case getUserAction.SUCCESS:
    case updateUserAction.SUCCESS:
    case getAllLeadsAction.SUCCESS:
    case getAllUsersAction.FAILURE:
    case getUserAction.FAILURE:
    case updateUserAction.FAILURE:
    case getAllLeadsAction.FAILURE:
      return false
    default:
      return state
  }
}

const commentsReducer = (state = true, { type }) => {
  switch (type) {
    case getAllCommentsAction.REQUEST:
    case updateCommentAction.REQUEST:
    case getAllTagsAction.REQUEST:
      return true
    case getAllCommentsAction.SUCCESS:
    case updateCommentAction.SUCCESS:
    case getAllTagsAction.SUCCESS:
    case getAllCommentsAction.FAILURE:
    case updateCommentAction.FAILURE:
    case getAllTagsAction.FAILURE:
      return false
    default:
      return state
  }
}

const actionReportsReducer = (state = true, { type }) => {
  switch (type) {
    case getAllActionReportCausesAction.REQUEST:
    case getActionReportCauseAction.REQUEST:
    case updateActionReportCauseAction.REQUEST:
      return true
    case getAllActionReportCausesAction.SUCCESS:
    case getActionReportCauseAction.SUCCESS:
    case updateActionReportCauseAction.SUCCESS:
    case getAllActionReportCausesAction.FAILURE:
    case getActionReportCauseAction.FAILURE:
    case updateActionReportCauseAction.FAILURE:
      return false
    default:
      return state
  }
}

const snapshotReducer = (state = true, { type }) => {
  switch (type) {
    case getLocationSnapshotAction.REQUEST:
      return true
    case getLocationSnapshotAction.SUCCESS:
    case getLocationSnapshotAction.FAILURE:
      return false
    default:
      return state
  }
}

const chartDataReducer = (state = true, { type }) => {
  switch (type) {
    case getPodChartDataAction.REQUEST:
    case getLocationChartDataAction.REQUEST:
    case getImuDataAction.REQUEST:
    case getFlowMonitorDataAction.REQUEST:
    case getGatewayAlertsUptimeDataAction.REQUEST:
    case getGatewayConnectedPodsAction.REQUEST:
    case getAllLocationsSnapshotDataByFloorAction.REQUEST:
    case getFloorSummaryAction.REQUEST:
    case getAllFloorSummariesAction.REQUEST:
    case getLocationTimeSeriesDataByLocationIdsAction.REQUEST:
    case getMultiplePodsDataAction.REQUEST:
      return true
    case getPodChartDataAction.SUCCESS:
    case getGatewayAlertsUptimeDataAction.SUCCESS:
    case getGatewayConnectedPodsAction.SUCCESS:
    case getLocationChartDataAction.SUCCESS:
    case getImuDataAction.SUCCESS:
    case getFlowMonitorDataAction.SUCCESS:
    case getAllLocationsSnapshotDataByFloorAction.SUCCESS:
    case getFloorSummaryAction.SUCCESS:
    case getAllFloorSummariesAction.SUCCESS:
    case getLocationTimeSeriesDataByLocationIdsAction.SUCCESS:
    case getMultiplePodsDataAction.SUCCESS:
    case getPodChartDataAction.FAILURE:
    case getGatewayAlertsUptimeDataAction.FAILURE:
    case getGatewayConnectedPodsAction.FAILURE:
    case getLocationChartDataAction.FAILURE:
    case getImuDataAction.FAILURE:
    case getFlowMonitorDataAction.FAILURE:
    case getFloorSummaryAction.FAILURE:
    case getAllFloorSummariesAction.FAILURE:
    case getLocationTimeSeriesDataByLocationIdsAction.FAILURE:
    case getMultiplePodsDataAction.FAILURE:
      return false
    default:
      return state
  }
}

const reportsReducer = (state = false, { type }) => {
  switch (type) {
    case createReportAction.REQUEST:
    case getAllReportsAction.REQUEST:
    case getPublicReportAction.REQUEST:
      return true
    case createReportAction.SUCCESS:
    case createReportAction.FAILURE:
    case getAllReportsAction.SUCCESS:
    case getAllReportsAction.FAILURE:
    case getPublicReportAction.SUCCESS:
    case getPublicReportAction.FAILURE:
      return false
    default:
      return state
  }
}

const networksReducer = (state = true, { type }) => {
  switch (type) {
    case createNetworkAuthorityAction.REQUEST:
    case updateNetworkAuthorityAction.REQUEST:
    case getAllNetworkAuthoritiesAction.REQUEST:
      return true
    case createNetworkAuthorityAction.SUCCESS:
    case createNetworkAuthorityAction.FAILURE:
    case updateNetworkAuthorityAction.SUCCESS:
    case updateNetworkAuthorityAction.FAILURE:
    case getAllNetworkAuthoritiesAction.SUCCESS:
    case getAllNetworkAuthoritiesAction.FAILURE:
      return false
    default:
      return state
  }
}

const isPhoneVerificationModalVisibleReducer = (state = false, { type }) => {
  switch (type) {
    case SHOW_PHONE_VERIFICATION_MODAL:
      return true
    case HIDE_PHONE_VERIFICATION_MODAL:
      return false
    default:
      return state
  }
}

const workflowsReducer = (state = true, { type }) => {
  switch (type) {
    case getAllWorkflowsAction.REQUEST:
    case getWorkflowAction.REQUEST:
      return true
    case getAllWorkflowsAction.SUCCESS:
    case getAllWorkflowsAction.FAILURE:
    case getWorkflowAction.SUCCESS:
    case getWorkflowAction.FAILURE:
      return false
    default:
      return state
  }
}

const developerReducer = (state = true, { type }) => {
  switch (type) {
    case getAccessTokenAction.REQUEST:
    case generateAccessTokenAction.REQUEST:
      return true
    case getAccessTokenAction.SUCCESS:
    case getAccessTokenAction.FAILURE:
    case generateAccessTokenAction.SUCCESS:
    case generateAccessTokenAction.FAILURE:
      return false
    default:
      return state
  }
}

const communicationsReducer = (state = true, { type }) => {
  switch (type) {
    case getAllSupportPhoneNumbersAction.REQUEST:
    case getSupportPhoneNumberAction.REQUEST:
    case getAllConversationsAction.REQUEST:
      // case getConversationAction.REQUEST:
      return true
    case getAllSupportPhoneNumbersAction.SUCCESS:
    case getAllSupportPhoneNumbersAction.FAILURE:
    case getSupportPhoneNumberAction.SUCCESS:
    case getSupportPhoneNumberAction.FAILURE:
    case getAllConversationsAction.SUCCESS:
    case getAllConversationsAction.FAILURE:
      // case getConversationAction.SUCCESS:
      // case getConversationAction.FAILURE:
      return false
    default:
      return state
  }
}

const bleReducer = (state = true, { type }) => {
  switch (type) {
    case getAllBLEDataAction.REQUEST:
      return true
    case getAllBLEDataAction.SUCCESS:
    case getAllBLEDataAction.FAILURE:
      return false
    default:
      return state
  }
}

const calibrationHistoryReducer = (state = true, { type }) => {
  switch (type) {
    case getPodCalibrationHistoryAction.REQUEST:
      return true
    case getPodCalibrationHistoryAction.SUCCESS:
    case getPodCalibrationHistoryAction.FAILURE:
      return false
    default:
      return state
  }
}

const alertSummariesReducer = (state = true, { type }) => {
  switch (type) {
    case getAllAlertSummariesAction.REQUEST:
    case getAlertSummaryAction.REQUEST:
      return true
    case getAllAlertSummariesAction.SUCCESS:
    case getAllAlertSummariesAction.FAILURE:
    case getAlertSummaryAction.SUCCESS:
    case getAlertSummaryAction.FAILURE:
      return false
    default:
      return state
  }
}

const fullWidthReducer = (state = getFullWidth(), { type, payload }) => {
  switch (type) {
    case TOGGLE_FULL_WIDTH:
      setFullWidth(payload)
      return payload
    default:
      return state
  }
}

const uiReducer = combineReducers({
  windowWidth: windowWidthReducer,
  sideNav: sideNavReducer,
  account: accountReducer,
  site: siteReducer,
  floor: floorReducer,
  location: locationReducer,
  alert: alertReducer,
  gateway: gatewayReducer,
  simCard: simCardReducer,
  part: partReducer,
  purchase: purchaseReducer,
  flowMonitor: flowMonitorReducer,
  gatewaySupply: gatewaySupplyReducer,
  threshold: thresholdReducer,
  escalationPolicy: escalationPolicyReducer,
  company: companyReducer,
  user: userReducer,
  pod: podReducer,
  assetLocation: assetLocationReducer,
  chartData: chartDataReducer,
  deployment: deploymentReducer,
  shipment: shipmentReducer,
  report: reportsReducer,
  comments: commentsReducer,
  isPhoneVerificationModalVisible: isPhoneVerificationModalVisibleReducer,
  history: historyReducer,
  snapshot: snapshotReducer,
  actionReports: actionReportsReducer,
  workflows: workflowsReducer,
  developer: developerReducer,
  communications: communicationsReducer,
  ble: bleReducer,
  calibrationHistory: calibrationHistoryReducer,
  network: networksReducer,
  thresholdGroup: thresholdGroupReducer,
  summaries: alertSummariesReducer,
  fullWidth: fullWidthReducer,
})

const getWindowWidth = state => state.windowWidth
const isSideNavCollapsed = state => state.sideNav
const isPhoneVerificationModalVisible = state =>
  state.isPhoneVerificationModalVisible

const isAccountLoading = state => state.account
const isSiteLoading = state => state.site
const isFloorLoading = state => state.floor
const isLocationLoading = state => state.location
const isAlertLoading = state => state.alert
const isGatewayLoading = state => state.gateway
const isSIMCardLoading = state => state.simCard
const isPartLoading = state => state.part
const isPurchaseLoading = state => state.purchase
const isFlowMonitorLoading = state => state.flowMonitor
const isGatewaySupplyLoading = state => state.gatewaySupply
const isPodLoading = state => state.pod
const isAssetLocationLoading = state => state.assetLocation
const isThresholdLoading = state => state.threshold
const isEscalationPolicyLoading = state => state.escalationPolicy
const isCompanyLoading = state => state.company
const isUserLoading = state => state.user
const isChartDataLoading = state => state.chartData
const isDeploymentLoading = state => state.deployment
const isShipmentLoading = state => state.shipment
const isReportLoading = state => state.report
const isCommentLoading = state => state.comments
const isActionReportLoading = state => state.actionReports
const isHistoryLoading = state => state.history
const isSnapshotLoading = state => state.snapshot
const isDashboardLoading = state => state.dashboard
const isWorkflowLoading = state => state.workflows
const isCalibrationHistoryLoading = state => state.calibrationHistory
const isDeveloperLoading = state => state.developer
const isCommunicationLoading = state => state.communications
const isBLELoading = state => state.ble
const isNetworkLoading = state => state.network
const isThresholdGroupLoading = state => state.thresholdGroup
const isAlertSummaryLoading = state => state.summaries
const isFullWidth = state => state.fullWidth

export {
  uiReducer as default,
  windowWidthReducer,
  sideNavReducer,
  accountReducer,
  siteReducer,
  floorReducer,
  locationReducer,
  alertReducer,
  gatewayReducer,
  podReducer,
  thresholdReducer,
  companyReducer,
  userReducer,
  chartDataReducer,
  commentsReducer,
  snapshotReducer,
  getWindowWidth,
  isSideNavCollapsed,
  isAccountLoading,
  isSiteLoading,
  isFloorLoading,
  isLocationLoading,
  isAlertLoading,
  isGatewayLoading,
  isSIMCardLoading,
  isPartLoading,
  isPurchaseLoading,
  isFlowMonitorLoading,
  isGatewaySupplyLoading,
  isPodLoading,
  isAssetLocationLoading,
  isThresholdLoading,
  isEscalationPolicyLoading,
  isCompanyLoading,
  isUserLoading,
  isChartDataLoading,
  isDeploymentLoading,
  isReportLoading,
  isPhoneVerificationModalVisible,
  isShipmentLoading,
  isCommentLoading,
  isActionReportLoading,
  isHistoryLoading,
  isSnapshotLoading,
  isDashboardLoading,
  isWorkflowLoading,
  isDeveloperLoading,
  isCommunicationLoading,
  isBLELoading,
  isCalibrationHistoryLoading,
  isNetworkLoading,
  isThresholdGroupLoading,
  isAlertSummaryLoading,
  isFullWidth,
}
