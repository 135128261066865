import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllCompaniesAction,
  getCompanyAction,
  getAllUsersByCompanyIdAction,
} from '../actions/companiesActions'

const byIdReducer = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllCompaniesAction.SUCCESS:
        payload.items.forEach(company => {
          draft[company.id] = company
        })
        break
    }
  })

const visibleIdsReducer = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllCompaniesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...payload.items.map(company => company.id)
        )
        break
    }
  })

const metaReducer = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllCompaniesAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const currentReducer = (state = { sites: [], users: [] }, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getCompanyAction.SUCCESS:
        payload.users.forEach(
          user => (user.fullName = `${user.firstName} ${user.lastName}`)
        )
        Object.assign(draft, payload)
        break
    }
  })

const companyUsersReducer = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllUsersByCompanyIdAction.SUCCESS:
        payload.items.forEach(
          user => (user.fullName = `${user.firstName} ${user.lastName}`)
        )
        draft.splice(0, draft.length, ...payload.items)
        break
    }
  })

const companiesReducer = combineReducers({
  byId: byIdReducer,
  visibleIds: visibleIdsReducer,
  meta: metaReducer,
  current: currentReducer,
  companyUsers: companyUsersReducer,
})

const getCompany = (state, id) => state.byId[id]
const getCurrentCompany = state => state.current
const getVisibleCompanies = state =>
  state.visibleIds.map(id => getCompany(state, id))
const getCompaniesMeta = state => state.meta
const getCompanyUsers = ({ companyUsers }) => companyUsers

export {
  companiesReducer as default,
  byIdReducer,
  visibleIdsReducer,
  metaReducer,
  currentReducer,
  getCurrentCompany,
  getVisibleCompanies,
  getCompaniesMeta,
  getCompanyUsers,
}
