import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Tooltip, Icon } from './common/Ant'
import { Anchor } from './common/Anchor'
import { H3 } from './common/Headers'
import { dateTimeFormatter } from '../utils/date'
import { THRESHOLD_ALERT_TYPES } from '../constants'

const normalizeAlerts = (assetAlerts, gatewayAlerts) => {
  const assets = assetAlerts.map(p => {
    const isThresholdAlert = !!p.thresholdName
    return {
      ...p,
      name: isThresholdAlert
        ? p.thresholdName
        : p.assetType === 'FLOW_MONITOR'
        ? 'Water Monitor Offline'
        : 'Smart Pod Offline',
      isGatewayAlert: false,
      isThresholdAlert,
    }
  })

  const gateways = gatewayAlerts.map(g => {
    return {
      ...g,
      name: g.gatewayName,
      isGatewayAlert: true,
    }
  })

  return [...assets, ...gateways].sort(sortBy('-startTime'))
}

const ActiveAlerts = ({ assetAlerts, gatewayAlerts }) => {
  const allActiveAlerts = normalizeAlerts(assetAlerts, gatewayAlerts)

  return (
    <div className="ActiveAlerts">
      <H3>Active Alerts</H3>
      <Table
        dataSource={allActiveAlerts}
        rowKey="slug"
        pagination={false}
        size="small"
        tableLayout="auto"
        scroll={{ x: 500 }}
      >
        <Column
          title="Name"
          dataIndex="name"
          render={(
            text,
            { slug, assetType, isGatewayAlert, isThresholdAlert }
          ) => (
            <Anchor
              to={
                isGatewayAlert
                  ? `/alerts/gateways/${slug}`
                  : assetType === 'FLOW_MONITOR'
                  ? `/alerts/flow-monitors/${slug}`
                  : `/alerts/pods/${slug}`
              }
            >
              <div className="flex items-center">
                <div className="mr2">
                  {isGatewayAlert ? (
                    <Icon type="wifi" className="orange" />
                  ) : isThresholdAlert ? (
                    <Icon type="exclamation-circle-o" className="red" />
                  ) : (
                    <Icon type="poweroff" className="orange" />
                  )}
                </div>
                <div>{text}</div>
              </div>
            </Anchor>
          )}
        />
        <Column
          title="Type"
          dataIndex={['threshold', 'thresholdAlertType']}
          width={100}
          render={(text, record) => (
            <div>
              {!!record.threshold ? (
                record.threshold.thresholdAlertType ===
                THRESHOLD_ALERT_TYPES.ACTIONABLE ? (
                  <Tooltip title="Actionable Alerts indicate suboptimal conditions on a Site and require immediate remedial action.">
                    <span className="br2 bg-dark-red white t-shadow-1 pa1 f7">
                      Actionable
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Informational Alerts provide insights into current conditions on a Site but do not require any immediate action.">
                    <span className="br2 bg-gold white t-shadow-1 pa1 f7">
                      Informational
                    </span>
                  </Tooltip>
                )
              ) : (
                '--'
              )}
            </div>
          )}
        />
        <Column
          title="Site"
          dataIndex="siteSlug"
          render={(text, { siteSlug, siteName }) =>
            text ? <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor> : '--'
          }
        />
        <Column
          title="Floor"
          dataIndex="floorNum"
          render={(text, { siteSlug, floorId }) =>
            text ? (
              <Anchor to={`/sites/${siteSlug}/floors/${floorId}`}>
                {text.toString()}
              </Anchor>
            ) : (
              '--'
            )
          }
        />
        <Column
          title="Location"
          dataIndex="locationName"
          render={(text, { assetType, siteSlug, floorId, locationId }) =>
            text ? (
              <Anchor
                to={
                  assetType === 'FLOW_MONITOR'
                    ? `/sites/${siteSlug}/floors/${floorId}/locations/${locationId}/flow-monitor-chart`
                    : `/sites/${siteSlug}/floors/${floorId}/locations/${locationId}`
                }
              >
                {text}
              </Anchor>
            ) : (
              '--'
            )
          }
        />
        <Column
          title="Start Time"
          dataIndex="startTime"
          render={text => (
            <Tooltip title={dateTimeFormatter(text)}>
              {dateTimeFormatter(text, true)}
            </Tooltip>
          )}
        />
      </Table>
    </div>
  )
}

ActiveAlerts.propTypes = {
  assetAlerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  gatewayAlerts: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ActiveAlerts
