import { createFormAction } from 'redux-form-saga'

const getAllPurchasesAction = createFormAction('GET_ALL_PURCHASES')

/* For the future if we'd like to see all Purchases we can assign. */
const getAllAvailablePurchasesAction = createFormAction(
  'GET_ALL_AVAILABLE_PURCHASES'
)
const getPurchaseAction = createFormAction('GET_PURCHASE')
const createPurchaseAction = createFormAction('CREATE_PURCHASE')
const updatePurchaseAction = createFormAction('UPDATE_PURCHASE')
const deletePurchaseAction = createFormAction('DELETE_PURCHASE')

export {
  getAllPurchasesAction,
  getAllAvailablePurchasesAction,
  getPurchaseAction,
  createPurchaseAction,
  updatePurchaseAction,
  deletePurchaseAction,
}
