import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import Networks from '../Networks'
import PageWidth from '../PageWidth'

const NetworksPage = ({
  getAllNetworks,
  createNetwork,
  updateNetwork,
  deleteNetwork,
  getAllNetworkAuthorities,
  createNetworkAuthority,
  updateNetworkAuthority,
  deleteNetworkAuthority,
  clearToken,
  regenerateToken,
  token,
  networkAuthorities,
  networks,
  isLoading,
  match: { url },
}) => {
  return (
    <MainContent>
      <div className="NetworksPage with-side-nav">
        <PageWidth>
          <Switch>
            <Route
              path={`${url}/`}
              render={props => (
                <>
                  <Helmet>
                    <title>Networks</title>
                  </Helmet>
                  <Networks
                    getAllNetworks={getAllNetworks}
                    createNetwork={createNetwork}
                    updateNetwork={updateNetwork}
                    deleteNetwork={deleteNetwork}
                    getAllNetworkAuthorities={getAllNetworkAuthorities}
                    createNetworkAuthority={createNetworkAuthority}
                    updateNetworkAuthority={updateNetworkAuthority}
                    deleteNetworkAuthority={deleteNetworkAuthority}
                    clearToken={clearToken}
                    regenerateToken={regenerateToken}
                    token={token}
                    networkAuthorities={networkAuthorities}
                    networks={networks}
                    isLoading={isLoading}
                    {...props}
                  />
                </>
              )}
            />
          </Switch>
        </PageWidth>
      </div>
    </MainContent>
  )
}

NetworksPage.propTypes = {
  getAllNetworks: PropTypes.func.isRequired,
  createNetwork: PropTypes.func.isRequired,
  updateNetwork: PropTypes.func.isRequired,
  deleteNetwork: PropTypes.func.isRequired,
  getAllNetworkAuthorities: PropTypes.func.isRequired,
  createNetworkAuthority: PropTypes.func.isRequired,
  updateNetworkAuthority: PropTypes.func.isRequired,
  deleteNetworkAuthority: PropTypes.func.isRequired,
  clearToken: PropTypes.func.isRequired,
  regenerateToken: PropTypes.func.isRequired,
  networkAuthorities: PropTypes.arrayOf(PropTypes.object).isRequired,
  networks: PropTypes.arrayOf(PropTypes.object).isRequired,
  token: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
}

export default NetworksPage
