import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'
import { Menu, Sider, Icon, Layout } from './common/Ant'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { flagEnabled } from '../utils/config'
import { USER_PERMISSIONS, ROLES } from '../constants'
import { version } from '../../package.json'

const isCommunicationsEnabled = flagEnabled('REACT_APP_ENABLE_COMMUNICATIONS')

class SideNav extends Component {
  static propTypes = {
    isAuthorized: PropTypes.bool.isRequired,
    isSideNavCollapsed: PropTypes.bool.isRequired,
    toggleSideNavCollapse: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
  }

  render() {
    const {
      isAuthorized,
      isSideNavCollapsed,
      toggleSideNavCollapse,
      windowWidth,
    } = this.props

    const canToggleMenu = windowWidth < 960
    const sideNavTriggerClassNames = cx('pointer fixed f3 z-9999', {
      dn: !canToggleMenu || !isSideNavCollapsed,
    })

    const widthCheckedCanToggleMenu = !isSideNavCollapsed
      ? toggleSideNavCollapse
      : () => null

    return (
      isAuthorized && (
        <Layout>
          <Sider
            width={175}
            className="fixed top-0 pt2 pt0-l SideNav min-vh-100 z-1000"
            style={!canToggleMenu ? { top: 65 } : null}
            trigger={null}
            breakpoint="lg"
            collapsed={canToggleMenu && isSideNavCollapsed}
            collapsedWidth="0"
          >
            <Menu className="Menu" mode="vertical">
              {!hasRole(ROLES.HILTI_USER) && (
                <Menu.Item key="dashboard">
                  <NavLink
                    to="/dashboard"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="dashboard" /> Dashboard
                  </NavLink>
                </Menu.Item>
              )}
              {(hasPermission(USER_PERMISSIONS.VIEW_ALERTS) ||
                hasPermission(USER_PERMISSIONS.VIEW_GATEWAY_ALERTS)) && (
                <Menu.Item key="alerts">
                  <NavLink
                    to="/alerts"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="alert" /> Alerts
                  </NavLink>
                </Menu.Item>
              )}
              {hasRole(
                ROLES.JOBSITE_ADMIN,
                ROLES.ORGANIZATION_ADMIN,
                ROLES.PILLAR_CONTRACTOR,
                ROLES.PILLAR_ADMIN,
                ROLES.SUPER_ADMIN
              ) && (
                <Menu.Item key="sites">
                  <NavLink
                    to="/sites"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="environment-o" /> Sites
                  </NavLink>
                </Menu.Item>
              )}
              {hasPermission(USER_PERMISSIONS.VIEW_ALL_DEPLOYMENTS) && (
                <Menu.Item key="deployments">
                  <NavLink
                    to="/deployments"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="gift" /> Deployments
                  </NavLink>
                </Menu.Item>
              )}
              {hasPermission(USER_PERMISSIONS.VIEW_REPORT) && (
                <Menu.Item key="reports">
                  <NavLink
                    to="/reports"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="line-chart" /> Reports
                  </NavLink>
                </Menu.Item>
              )}
              {hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) && (
                <Menu.Item key="rules">
                  <NavLink
                    to="/rules"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="exception" /> Thresholds
                  </NavLink>
                </Menu.Item>
              )}
              {hasPermission(USER_PERMISSIONS.VIEW_POD) && (
                <Menu.Item key="inventory">
                  <NavLink
                    to="/inventory/all/pods"
                    activeClassName="fw7"
                    isActive={() =>
                      /.*\/inventory\/.*/.test(global.location.pathname)
                    }
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="qrcode" /> Inventory
                  </NavLink>
                </Menu.Item>
              )}
              {hasPermission(USER_PERMISSIONS.VIEW_COMPANY) && (
                <Menu.Item key="companies">
                  <NavLink
                    to="/companies"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="solution" /> Companies
                  </NavLink>
                </Menu.Item>
              )}
              {hasPermission(USER_PERMISSIONS.VIEW_USER) && (
                <Menu.Item key="users">
                  <NavLink
                    to="/users"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="team" /> Users
                  </NavLink>
                </Menu.Item>
              )}
              {isCommunicationsEnabled &&
                hasPermission(USER_PERMISSIONS.ENABLE_COMMS_HUB) && (
                  <Menu.Item key="communications">
                    <NavLink
                      to="/communications"
                      activeClassName="fw7"
                      onClick={widthCheckedCanToggleMenu}
                    >
                      <Icon type="message" /> Communications
                    </NavLink>
                  </Menu.Item>
                )}
              {(hasPermission(USER_PERMISSIONS.VIEW_TAG) ||
                hasPermission(USER_PERMISSIONS.VIEW_ACTION_REPORT_CAUSES)) && (
                <Menu.Item key="metadata">
                  <NavLink
                    to="/metadata/all"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                    isActive={() =>
                      /.*\/metadata\/.*/.test(global.location.pathname)
                    }
                  >
                    <Icon type="tag-o" /> Metadata
                  </NavLink>
                </Menu.Item>
              )}
              {hasPermission(USER_PERMISSIONS.VIEW_WORKFLOW) && (
                <Menu.Item key="workflows">
                  <NavLink
                    to="/workflows"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="audit" /> Workflows
                  </NavLink>
                </Menu.Item>
              )}
              {hasRole(
                ROLES.SUPER_ADMIN,
                ROLES.PILLAR_ADMIN,
                ROLES.HILTI_USER
              ) &&
                hasPermission(USER_PERMISSIONS.ENABLE_EROS) && (
                  <Menu.Item key="hilti-dashboard">
                    <NavLink
                      to="/hilti"
                      activeClassName="fw7"
                      onClick={widthCheckedCanToggleMenu}
                    >
                      <Icon type="bars" /> Hilti Dashboard
                    </NavLink>
                  </Menu.Item>
                )}
              {hasPermission(USER_PERMISSIONS.ENABLE_API) && (
                <Menu.Item key="developer">
                  <NavLink
                    to="/developer"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="api" /> Developer
                  </NavLink>
                </Menu.Item>
              )}
              {hasPermission(USER_PERMISSIONS.MANAGE_NETWORK) && (
                <Menu.Item key="networks">
                  <NavLink
                    to="/networks"
                    activeClassName="fw7"
                    onClick={widthCheckedCanToggleMenu}
                  >
                    <Icon type="gateway" /> Networks
                  </NavLink>
                </Menu.Item>
              )}
              <div className="fixed bottom-0 left-0 moon-gray pl3 pb3 f7">
                v{version}{' '}
                {process.env.REACT_APP_SHORT_HASH
                  ? `(${process.env.REACT_APP_SHORT_HASH})`
                  : ''}
              </div>
            </Menu>
          </Sider>
          <Icon
            className={sideNavTriggerClassNames}
            style={{ top: 20, left: 13 }}
            type={isSideNavCollapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={toggleSideNavCollapse}
          />
          {!isSideNavCollapsed && (
            <div
              className="fixed top-0 right-0 bottom-0 left-0 bg-black-60 z-999 overflow-auto outline-0"
              onClick={toggleSideNavCollapse}
            />
          )}
        </Layout>
      )
    )
  }
}

export default SideNav
