import { createFormAction } from 'redux-form-saga'

const getAdminDashboardAction = createFormAction('GET_ADMIN_DASHBOARD')
const getUserDashboardAction = createFormAction('GET_USER_DASHBOARD')
const getAllSiteImuNanoIotAction = createFormAction('GET_ALL_SITE_IMU_NANO_IOT')
const getImuDataAction = createFormAction('GET_IMU_DATA')

export {
  getAdminDashboardAction,
  getUserDashboardAction,
  getAllSiteImuNanoIotAction,
  getImuDataAction,
}
