import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { dateTimeFormatter } from '../utils/date'
import { Table, Column, Spin } from './common/Ant'
import { WATER_VALVE_STATUS } from '../constants'
import { toTitleCase } from '../utils/textFormatters'

class ValveStatusHistory extends Component {
  componentDidMount() {
    const { valveId, getValveStatusHistory } = this.props
    getValveStatusHistory(valveId)
  }

  render() {
    const { valveStatusHistory, isLoading } = this.props

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : valveStatusHistory.length ? (
      <Table
        dataSource={valveStatusHistory}
        rowKey="id"
        scroll={{ x: 350 }}
        pagination={false}
        bordered
        tableLayout="auto"
      >
        <Column
          title="Pillar ID"
          width={100}
          dataIndex="pillarId"
          render={(text, record) => (text ? text : '--')}
        />
        <Column
          title="Valve Serial"
          width={100}
          dataIndex="valveSerialId"
          render={(text, record) => (text ? text : '--')}
        />
        <Column
          title="Valve Status"
          width={100}
          dataIndex="valveStatus"
          render={(text, record) =>
            text ? toTitleCase(WATER_VALVE_STATUS[text]) : '--'
          }
        />
        <Column
          title="Date Updated"
          width={100}
          dataIndex="updatedAt"
          render={(text, record) => (text ? dateTimeFormatter(text) : '--')}
        />
        <Column
          title="Updated By"
          width={100}
          dataIndex="updatedBy"
          render={(text, record) => (text ? text : '--')}
        />
      </Table>
    ) : (
      'No Valve Status History'
    )
  }
}

ValveStatusHistory.propTypes = {
  valveStatusHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  getValveStatusHistory: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  valveId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default ValveStatusHistory
