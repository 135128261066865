import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import ReportsPage from '../components/pages/ReportsPage'
import {
  getAllSitesAction,
  getAllUsersBySiteAction,
  getAllAggregateThresholdsBySiteAction,
} from '../actions/sitesActions'
import { getAllFloorsAction } from '../actions/floorsActions'
import { getAllBuildingsAction } from '../actions/buildingsActions'
import { getAllLocationGroupsAction } from '../actions/locationGroupActions'
import { getAllLocationsBySiteAction } from '../actions/locationsActions'
import {
  createReportAction,
  getAllReportsAction,
  getAllReportConfigsAction,
  createReportConfigAction,
  updateReportConfigAction,
  deleteReportConfigAction,
  clearReportAction,
} from '../actions/reportsActions'
import { getVisibleSites } from '../reducers/sitesReducer'
import { getVisibleFloors } from '../reducers/floorsReducer'
import { getVisibleLocationGroups } from '../reducers/locationGroupsReducer'
import { getVisibleLocations } from '../reducers/locationsReducer'
import {
  getVisibleSiteUsers,
  getVisibleAggregateThresholds,
} from '../reducers/sitesReducer'
import { getVisibleBuildings } from '../reducers/buildingsReducer'
import {
  getCurrentReport,
  getVisibleReports,
  getReportsMeta,
  getVisibleReportConfigs,
} from '../reducers/reportsReducer'
import { isReportLoading, isAlertSummaryLoading } from '../reducers/uiReducer'
import { createQueryString } from '../utils/queryParams'
import { ALL } from '../constants'
import {
  getAllAlertSummariesAction,
  getAlertSummaryAction,
  deleteAlertSummaryAction,
} from '../actions/alertSummariesActions'
import {
  getAlertSummary,
  getVisibleAlertSummaries,
  getAlertSummariesMeta,
} from '../reducers/alertSummariesReducer'
import { getPathSnippetAtIndex } from '../utils/helpers'

const getSlugFromRouterLocation = location =>
  getPathSnippetAtIndex(location.pathname, 2)

const mapStateToProps = ({
  reports,
  sites,
  buildings,
  floors,
  locations,
  ui,
  locationGroups,
  summaries,
  form,
  router,
}) => ({
  report: getCurrentReport(reports),
  reports: getVisibleReports(reports),
  reportsMeta: getReportsMeta(reports),
  reportConfigs: getVisibleReportConfigs(reports),
  sites: getVisibleSites(sites),
  floors: getVisibleFloors(floors),
  buildings: getVisibleBuildings(buildings),
  locations: getVisibleLocations(locations),
  isReportLoading: isReportLoading(ui),
  siteUsers: getVisibleSiteUsers(sites),
  alertSummaries: getVisibleAlertSummaries(summaries),
  alertSummary: getAlertSummary(
    summaries,
    getSlugFromRouterLocation(router.location)
  ),
  alertSummariesMeta: getAlertSummariesMeta(summaries),
  isAlertSummaryLoading: isAlertSummaryLoading(ui),
  alertSummaryFormValues: getFormValues('EditAlertSummaryForm')({ form }),
  thresholds: getVisibleAggregateThresholds(sites),
  locationGroups: getVisibleLocationGroups(locationGroups),
})

const mapDispatchToProps = dispatch => ({
  getAllSites: qs => dispatch(getAllSitesAction.request(qs)),
  getAllFloors: siteSlug => dispatch(getAllFloorsAction.request({ siteSlug })),
  getAllBuildings: (siteSlug, qs) =>
    dispatch(getAllBuildingsAction.request({ siteSlug, qs })),
  getAllReports: qs => dispatch(getAllReportsAction.request(qs)),
  createReport: payload => dispatch(createReportAction.request(payload)),
  clearReport: () => dispatch(clearReportAction()),
  getAllUsersBySite: siteSlug =>
    dispatch(getAllUsersBySiteAction.request({ siteSlug })),
  getAllReportConfigs: qs => dispatch(getAllReportConfigsAction.request(qs)),
  createReportConfig: payload =>
    dispatch(createReportConfigAction.request(payload)),
  updateReportConfig: payload =>
    dispatch(updateReportConfigAction.request(payload)),
  deleteReportConfig: payload =>
    dispatch(deleteReportConfigAction.request(payload)),
  getAllLocationsBySite: siteSlug =>
    dispatch(
      getAllLocationsBySiteAction.request({
        siteSlug,
        qs: createQueryString({ perPage: ALL }),
      })
    ),

  getAllAlertSummaries: qs => dispatch(getAllAlertSummariesAction.request(qs)),
  getAlertSummaryBySlug: slug => dispatch(getAlertSummaryAction.request(slug)),
  deleteAlertSummary: slug => dispatch(deleteAlertSummaryAction.request(slug)),
  getAggregateThresholdsBySite: siteSlug =>
    dispatch(
      getAllAggregateThresholdsBySiteAction.request({
        siteSlug,
        qs: createQueryString({ full: 'f' }),
      })
    ),
  getAllLocationGroups: payload =>
    dispatch(getAllLocationGroupsAction.request(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage)
