import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { Spin } from '../common/Ant'
import { H4 } from '../common/Headers'
import { createQueryString } from '../../utils/queryParams'
import { getSensorReading } from '../../utils/sensorData'
import {
  UNITS,
  COLORS,
  SMART_SENSOR_TYPES,
  FLOW_SENSOR_READING_TYPES,
} from '../../constants'

const visibleReadings = [
  SMART_SENSOR_TYPES.TEMPERATURE,
  SMART_SENSOR_TYPES.HUMIDITY,
  SMART_SENSOR_TYPES.DUST,
  SMART_SENSOR_TYPES.CARBON_MONOXIDE,
  SMART_SENSOR_TYPES.PRESSURE,
]

const visibleFlowMonitorReadings = [
  FLOW_SENSOR_READING_TYPES.FLOW_VALUE,
  FLOW_SENSOR_READING_TYPES.PIPE_TEMPERATURE,
]

const toFixed = x => (x ? parseFloat(x).toFixed(2) : 0)

const getFlowSensorReading = (type, value = 0) => {
  switch (type) {
    case FLOW_SENSOR_READING_TYPES.FLOW_VALUE:
      return {
        color: COLORS.BLUE,
        name: 'Water Flow',
        unit: UNITS.FLOW_VALUE,
        value: toFixed(value),
      }
    case FLOW_SENSOR_READING_TYPES.PIPE_TEMPERATURE:
      return {
        color: COLORS.NAVY,
        name: 'Pipe Temperature',
        unit: UNITS.TEMPERATURE,
        value: toFixed(value),
      }
  }
}

class TinySensorChart extends Component {
  constructor(props) {
    super(props)

    this.now = moment()
    this.startValue = moment(this.now).subtract(1, 'd')
  }

  static propTypes = {
    getLocationChartData: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    siteSlug: PropTypes.string.isRequired,
    floorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locationId: PropTypes.number.isRequired,
    sidebarWidth: PropTypes.number.isRequired,
  }

  componentDidMount() {
    const { siteSlug, floorId, locationId, getLocationChartData } = this.props

    const query = {
      startTime: this.startValue.unix(),
      endTime: this.now.unix(),
    }
    const qs = createQueryString(query)

    getLocationChartData(siteSlug, floorId, locationId, qs)
  }

  componentDidUpdate(prevProps) {
    const { siteSlug, floorId, locationId, getLocationChartData } = this.props

    const query = {
      startTime: this.startValue.unix(),
      endTime: this.now.unix(),
    }
    const qs = createQueryString(query)

    if (prevProps.locationId !== locationId) {
      getLocationChartData(siteSlug, floorId, locationId, qs)
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.data.length !== nextProps.data.length ||
      this.props.isChartDataLoading !== nextProps.isChartDataLoading ||
      this.props.locationId !== nextProps.locationId
    )
  }

  render() {
    const { data, isChartDataLoading, sidebarWidth } = this.props

    const chartOptions = {
      credits: { enabled: false },
      exporting: { enabled: false },
      chart: {
        width: sidebarWidth,
        height: 300,
        style: {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
        },
      },
      plotOptions: {
        series: {
          type: 'line',
        },
        line: {
          animation: false,
        },
      },
      title: {
        text: null,
      },
      yAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        labels: {
          style: {
            color: COLORS.BLACK_80,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        type: 'datetime',
        labels: {
          style: {
            color: COLORS.BLACK_80,
            fontSize: '0.75rem',
          },
        },
        title: {
          text: null,
        },
      },
      tooltip: {
        valueDecimals: 2,
        headerFormat:
          "<span style='font-size: 0.75rem; margin-bottom: 0.75rem'>{point.key}</span><br />",
        style: {
          fontSize: '0.75rem',
          color: COLORS.BLACK_80,
        },
      },
      legend: {
        verticalAlign: 'bottom',
        itemStyle: {
          fontSize: '0.75rem',
          color: COLORS.BLACK_80,
        },
        itemHoverStyle: {
          color: COLORS.MOON_GRAY,
        },
      },
      series:
        data && data[0] && 'flowValue' in data[0]
          ? visibleFlowMonitorReadings.map(x => {
              const readingData = getFlowSensorReading(x)

              return {
                name: readingData.name,
                data: data.map(y => [y.time, y[x]]),
                color: readingData.color,
                tooltip: {
                  valueSuffix: readingData.unit,
                },
              }
            })
          : visibleReadings.map(x => {
              const readingData = getSensorReading(x)

              return {
                name: readingData.name,
                data: data.map(y => [y.time, y[x]]),
                color: readingData.color,
                tooltip: {
                  valueSuffix: readingData.unit,
                },
              }
            }),
    }

    return (
      <div className="TinySensorChart">
        {isChartDataLoading ? (
          <Spin size="large" className="w-100 center mv6" />
        ) : data.length ? (
          <div className="mb3">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
        ) : (
          <H4 className="tc">No Data Available</H4>
        )}
      </div>
    )
  }
}

export default TinySensorChart
