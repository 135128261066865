import { reducer } from 'redux-form'
import update from 'immutability-helper'
import {
  SET_WATER_VALVE_FIELDS,
  SET_WATER_METER_FIELDS,
} from '../actions/flowMonitorsActions'
import { SET_MAP_LOCATION_COORDINATES } from '../actions/locationsActions'
import { SET_WORKFLOW_TYPE } from '../actions/workflowsActions'
import { updateDeploymentAction } from '../actions/deploymentsActions'
import { SET_OTA_POD_IDS } from '../actions/otaActions'
import {
  UNSET_SITE_USER_IDS,
  UPDATE_ESCALATION_POLICY_SORT,
} from '../actions/formActions'

const SHIPMENT_INITIAL_STATE = { values: { shipment: {} } }

const formReducer = reducer.plugin({
  EditFlowMonitorForm: (state = {}, { type, payload }) => {
    switch (type) {
      case SET_WATER_VALVE_FIELDS:
        return {
          ...state,
          values: {
            ...state.values,
            valveSerialId: payload.length ? payload[0].valveSerialId : '',
            valveType: payload.length ? payload[0].valveType : '',
            valveStatus: payload.length ? payload[0].valveStatus : '',
            valvePipeId: payload.length ? payload[0].pipeId : '',
            valvePipeOd: payload.length ? payload[0].pipeOd : '',
            valveDescription: payload.length ? payload[0].valveDescription : '',
          },
          registeredFields: {
            ...state.registeredFields,
            valveSerialId: payload.length ? payload[0].valveSerialId : '',
            valveType: payload.length ? payload[0].valveType : '',
            valveStatus: payload.length ? payload[0].valveStatus : '',
            valvePipeId: payload.length ? payload[0].pipeId : '',
            valvePipeOd: payload.length ? payload[0].pipeOd : '',
            valveDescription: payload.length ? payload[0].valveDescription : '',
          },
        }
      case SET_WATER_METER_FIELDS:
        return {
          ...state,
          values: {
            ...state.values,
            meterSerialId: payload.length ? payload[0].meterSerialId : '',
            meterType: payload.length ? payload[0].meterType : '',
            pipeId: payload.length ? payload[0].pipeId : '',
            pipeOd: payload.length ? payload[0].pipeOd : '',
            pipeMaxFlow: payload.length ? payload[0].pipeMaxFlow : '',
            pipeMaxTemp: payload.length ? payload[0].pipeMaxTemp : '',
            pipeMinFlow: payload.length ? payload[0].pipeMinFlow : '',
            pipeMinTemp: payload.length ? payload[0].pipeMinTemp : '',
            meterDescription: payload.length ? payload[0].meterDescription : '',
            flowOffset: payload.length ? payload[0].flowOffset : '',
          },
          registeredFields: {
            ...state.registeredFields,
            meterSerialId: payload.length ? payload[0].meterSerialId : '',
            meterType: payload.length ? payload[0].meterType : '',
            pipeId: payload.length ? payload[0].pipeId : '',
            pipeOd: payload.length ? payload[0].pipeOd : '',
            pipeMaxFlow: payload.length ? payload[0].pipeMaxFlow : '',
            pipeMaxTemp: payload.length ? payload[0].pipeMaxTemp : '',
            pipeMinFlow: payload.length ? payload[0].pipeMinFlow : '',
            pipeMinTemp: payload.length ? payload[0].pipeMinTemp : '',
            meterDescription: payload.length ? payload[0].meterDescription : '',
            flowOffset: payload.length ? payload[0].flowOffset : '',
          },
        }
      default:
        return state
    }
  },

  EditWorkflowForm: (state = {}, { type, payload }) => {
    switch (type) {
      case SET_WORKFLOW_TYPE:
        return {
          ...state,
          values: {
            ...state.values,
            workflowType: payload,
          },
          registeredFields: {
            ...state.registeredFields,
            workflowType: payload,
          },
        }
      default:
        return state
    }
  },

  EditLocationForm: (state = {}, { type, payload }) => {
    switch (type) {
      case SET_MAP_LOCATION_COORDINATES:
        return {
          ...state,
          values: {
            ...state.values,
            coordinates: payload,
          },
          registeredFields: {
            ...state.registeredFields,
            coordinates: payload,
          },
        }
      default:
        return state
    }
  },
  ShipmentForm: (state = SHIPMENT_INITIAL_STATE, { type, payload }) => {
    switch (type) {
      case updateDeploymentAction.SUCCESS:
        return SHIPMENT_INITIAL_STATE
      default:
        return state
    }
  },
  OTAFirmwareUpdateForm: (state = {}, { type, payload }) => {
    switch (type) {
      case SET_OTA_POD_IDS:
        return {
          ...state,
          values: {
            ...state.values,
            podIds: payload,
          },
          registeredFields: {
            ...state.registeredFields,
            podIds: payload,
          },
        }
      default:
        return state
    }
  },
  EditEscalationPolicyForm: (state = {}, { type, payload }) => {
    switch (type) {
      case UNSET_SITE_USER_IDS:
        return {
          ...state,
          values: {
            ...state.values,
            policyGroups: state.values.policyGroups.map(x => ({
              ...x,
              userIds: [],
            })),
          },
        }
      case UPDATE_ESCALATION_POLICY_SORT:
        const { dragIndex, hoverIndex } = payload
        const dragCard = state.values.policyGroups[dragIndex]

        return {
          ...state,
          values: {
            ...state.values,
            policyGroups: update(state.values.policyGroups, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
              ],
            }).map((x, i) => ({ ...x, step: i + 1 })),
          },
        }

      default:
        return state
    }
  },
})
const getNestedRequiredFields = arrays => {
  let arraysName = []
  let arrayObjects = []
  let arrayLength = []
  let nestedArrays = []
  let arrayElements = []
  let nArraysName = []
  let nArrayObjects = []
  let nArrayLength = []

  for (let out = 0; out < arrays.length; out++) {
    const array = arrays[out]
    arraysName.push(array[0] ? array[0] : '')
    arrayLength.push(array[1] ? array[1].length : 0)

    if (array[1] && array[1].length) {
      for (let index = 0; index < array[1].length; index++) {
        const value = array[1][index]
        if (value) {
          arrayObjects.push(
            Object.entries(value).filter(field => field[1] === 'Required')
          )
          nestedArrays.push(
            Object.entries(value).filter(field => Array.isArray(field[1]))
          )
        }
      }
    }
  }

  for (let out = 0; out < arraysName.length; out++) {
    const name = arraysName[out]
    for (let arrayIndex = 0; arrayIndex < arrayLength[out]; arrayIndex++) {
      for (let index = 0; index < arrayObjects[out].length; index++) {
        const element = arrayObjects[out][index][0]
        arrayElements.push([`${name}[${arrayIndex}].${element}`])
      }

      // repeat same as above for nested arrays
      nArraysName = []
      nArrayObjects = []
      nArrayLength = []
      const arrays = nestedArrays[arrayIndex]

      if (arrays && arrays.length) {
        for (let out = 0; out < arrays.length; out++) {
          const array = arrays[out]
          nArraysName.push(array[0] ? array[0] : '')
          nArrayLength.push(array[1] ? array[1].length : 0)

          if (array[1] && array[1].length) {
            for (let index = 0; index < array[1].length; index++) {
              const value = array[1][index]
              if (value) {
                nArrayObjects.push(
                  Object.entries(value).filter(field => field[1] === 'Required')
                )
              }
            }
          }
        }
      }

      for (let nOut = 0; nOut < nArraysName.length; nOut++) {
        const nName = nArraysName[nOut]
        for (
          let nArrayIndex = 0;
          nArrayIndex < nArrayLength[nOut];
          nArrayIndex++
        ) {
          for (let index = 0; index < nArrayObjects[nOut].length; index++) {
            const element = nArrayObjects[nOut][index][0]
            arrayElements.push([
              `${name}[${arrayIndex}].${nName}[${nArrayIndex}].${element}`,
            ])
          }
        }
      }
    }
  }

  return arrayElements
}

/* TODO: Simplify this. It is ugly. */
const getRequiredFields = form => {
  // need a way to get the form name.
  const definedNames = Object.keys(form)
    .map(formName => {
      // get only the form with fields, and filter syncErrors for required
      if (Object.keys(form[formName]).length && form[formName].syncErrors) {
        const singleElements = Object.entries(form[formName].syncErrors).filter(
          field => field[1] === 'Required'
        )
        const arrays = Object.entries(form[formName].syncErrors).filter(field =>
          Array.isArray(field[1])
        )
        let allElements = singleElements

        if (arrays && arrays.length) {
          const arrayElements = getNestedRequiredFields(arrays)
          allElements = allElements.concat(arrayElements)
        }

        return allElements
      } else return null
    })
    .filter(item => item !== null)

  // if there are required fields, we get their names here
  // there should be only one
  if (definedNames.length) {
    return definedNames[0].map(field => field[0])
  }
  return []
}

export { formReducer as default, getRequiredFields }
