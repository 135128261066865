import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllPurchasesAction,
  getAllAvailablePurchasesAction,
  getPurchaseAction,
} from '../actions/purchasesActions'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllPurchasesAction.SUCCESS:
        payload.items.forEach(purchase => {
          draft[purchase.id] = purchase
        })
        break
    }
  })

const availableById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAvailablePurchasesAction.SUCCESS:
        payload.forEach(purchase => {
          draft[purchase.id] = purchase
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllPurchasesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(purchase => purchase.id)
        )
        break
    }
  })

const availableVisibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailablePurchasesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(purchase => purchase.id)
        )
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllPurchasesAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const current = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getPurchaseAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const purchasesReducer = combineReducers({
  byId,
  visibleIds,
  availableById,
  availableVisibleIds,
  current,
  meta,
})

const getPurchase = (state, id) => state.byId[id]
const getAvailablePurchase = (state, id) => state.availableById[id]
const getCurrentPurchase = state => state.current
const getVisiblePurchases = state =>
  state.visibleIds.map(id => getPurchase(state, id))
const getPurchasesMeta = state => state.meta
const getAvailableVisiblePurchases = state =>
  state.availableVisibleIds.map(id => getAvailablePurchase(state, id))

export {
  purchasesReducer as default,
  getCurrentPurchase,
  getVisiblePurchases,
  getAvailableVisiblePurchases,
  getPurchasesMeta,
}
