import { createFormAction } from 'redux-form-saga'

const getAllUsersAction = createFormAction('GET_ALL_USERS')
const getUserAction = createFormAction('GET_USER')
const getUserCommunicationLogAction = createFormAction(
  'GET_USER_COMMUNICATION_LOG'
)
const getUserPermissionsAction = createFormAction('GET_USER_PERMISSIONS')
const updateUserPermissionsAction = createFormAction('UPDATE_USER_PERMISSIONS')
const createUserAction = createFormAction('CREATE_USER')
const updateUserAction = createFormAction('UPDATE_USER')
const deleteUserAction = createFormAction('DELETE_USER')
const toggleUserOptionAction = createFormAction('TOGGLE_USER_OPTION')
const updateUserDefaultSiteAction = createFormAction('UPDATE_USER_DEFAULT_SITE')
const revokeApiTokenAction = createFormAction('REVOKE_API_TOKEN')
const getDeveloperAccessTokenHistoryAction = createFormAction(
  'GET_DEVELOPER_TOKEN_HISTORY'
)

const CLEAR_USER = 'CLEAR_USER'
const clearUserAction = () => ({
  type: CLEAR_USER,
})

export {
  getAllUsersAction,
  getUserAction,
  getUserCommunicationLogAction,
  getUserPermissionsAction,
  updateUserPermissionsAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  toggleUserOptionAction,
  updateUserDefaultSiteAction,
  revokeApiTokenAction,
  getDeveloperAccessTokenHistoryAction,
  clearUserAction,
  CLEAR_USER,
}
