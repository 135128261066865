import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Spin } from './common/Ant'
import { H2, H3, H4 } from './common/Headers'
import Divider from './common/Divider'
import Block from './common/Block'
import { SIZES, COMMUNICATION_MODES } from '../constants'

class EscalationPolicy extends Component {
  static propTypes = {
    getEscalationPolicy: PropTypes.func.isRequired,
    escalationPolicy: PropTypes.object.isRequired,
    isEscalationPolicyLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const { match, getEscalationPolicy } = this.props

    getEscalationPolicy(match.params.slug)
  }

  render() {
    const {
      escalationPolicy: { name, companyName, ackWaitTime, policyGroups },
      isEscalationPolicyLoading,
    } = this.props

    return isEscalationPolicyLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EscalationPolicy">
        <H2>{name}</H2>
        <Divider />
        <div className="flex flex-wrap">
          <Block label="Company">{companyName}</Block>
          <Block label="Acknowledgement Wait Time">{ackWaitTime} min</Block>
        </div>
        <Divider />
        <H3>Policy Groups</H3>
        {policyGroups.map(x => (
          <div key={x.slug}>
            <H4>Step: {x.step}</H4>
            <div className="flex flex-wrap">
              <Block label="Repeat Count" size={SIZES.SMALL}>
                {x.repeatCount}
              </Block>
              <Block label="Wait Time" size={SIZES.SMALL}>
                {x.waitTime} min
              </Block>
              <Block label="Communication Modes" size={SIZES.SMALL}>
                <ul className="flex list pl0 mb0">
                  {x.communicationModes.map((y, i) => (
                    <li className="pr1" key={y}>
                      {COMMUNICATION_MODES.filter(m => m.value === y)[0].label}
                      {x.communicationModes.length - 1 > i ? ', ' : ''}
                    </li>
                  ))}
                </ul>
              </Block>
              <Block label="Users" size={SIZES.SMALL}>
                <ul className="list pl0 mb0">
                  {x.usersInfo.map((y, i) => {
                    const prefix = cx({ mb1: x.usersInfo.length - 1 > i })

                    return (
                      <li className={prefix} key={y.slug}>
                        {y.firstName} {y.lastName}
                      </li>
                    )
                  })}
                </ul>
              </Block>
            </div>
          </div>
        ))}
      </section>
    )
  }
}

export default EscalationPolicy
