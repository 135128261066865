import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Bread, Crumb } from './common/Bread'
import Divider from './common/Divider'
import { Anchor } from './common/Anchor'
import { dateTimeFormatter } from '../utils/date'

const replaceWorkflowNotationInQuestion = str => str.replace(/\/ text$/, '')

class PodRefurbResponse extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    getPodWorkflowResponse: PropTypes.func.isRequired,
    workflowResponse: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const {
      match: {
        params: { podPillarId, workflowResponseSlug },
      },
      getPodWorkflowResponse,
    } = this.props

    getPodWorkflowResponse(podPillarId, workflowResponseSlug)
  }

  renderResponse = (type, response) => {
    if (type === 'multi') {
      return (
        <ul className="pl4">
          {response.map((x, i) => {
            const keyName = Object.keys(x)[0]
            const prefix = cx({
              b: x[keyName],
              'i moon-gray': !x[keyName],
            })

            return (
              <li key={keyName} className={prefix}>
                {x.label}
              </li>
            )
          })}
        </ul>
      )
    } else if (type === 'confirm') {
      return <div className="b">Completed</div>
    } else {
      return <div className="b">{response}</div>
    }
  }

  render() {
    const {
      match: {
        params: { podPillarId },
      },
      workflowResponse,
    } = this.props

    return (
      <section className="PodRefurbResponse">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/inventory/all">Inventory</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to="/inventory/all/pods">Smart Pods</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to={`/inventory/pods/${podPillarId}`}>
                  {podPillarId}
                </Anchor>
              </Crumb>
              <Crumb>
                {dateTimeFormatter(workflowResponse.addedAt, true)} Refurb
              </Crumb>
            </Bread>
          </div>
          <div className="flex justify-center">
            Completed by {workflowResponse.addedBy}
          </div>
        </div>
        <Divider />
        <ol className="m-auto measure-wide center">
          {workflowResponse.userResponse.map(
            ({ currentState, question, response, type }) => (
              <li key={currentState} className="mb2 bb b--light-gray pb2">
                <div className="i mb1">
                  {replaceWorkflowNotationInQuestion(question)}
                </div>
                {this.renderResponse(type, response)}
              </li>
            )
          )}
        </ol>
      </section>
    )
  }
}

export default PodRefurbResponse
