import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isFullWidth } from '../reducers/uiReducer'

const mapStateToProps = ({ ui }) => ({
  isFullWidth: isFullWidth(ui),
})

const PageWidth = ({ children, isFullWidth }) => {
  const prefix = cx({ 'mw8 center': !isFullWidth })

  return <div className={prefix}>{children}</div>
}

PageWidth.propTypes = {
  children: PropTypes.node.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(PageWidth)
